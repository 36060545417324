import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography";


const list = []

function MultipleTags({
    type,
    name,
    multiline,
    rows,
    disabled,
    freeTextHanlder,
    placeholder,
    valList, 
    height
}) {
    return (
        <SuiBox>
            <Autocomplete
                disabled={disabled}
                multiple
                id={`tags-filled${placeholder}`}
                sx={{
                    display: 'flex',
                    borderBottom: 'none',
                    '& input': {
                        width: 200,
                        bgcolor: 'background.paper',
                         borderBottom: 'none'
                    },


                }}
                options={list.map((option) => option)}
                value={ valList?[...valList]: []}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip 
                            variant="outlined" 
                            label={option} {...getTagProps({ index })} 
                            style={{
                                background: '#17AD37'
                            }}/>
                    ))
                }
                onChange={(e, value)=>{
                    freeTextHanlder(value)
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& .MuiAutocomplete-inputRoot': {
                            overflowX: 'auto',
                            display: 'flex !important',
                            alignItems: 'flex-start !important',
                            flexWrap: 'wrap !important',
                            height: height? height:'120px !important',
                           
                            '&::after': {
                                borderBottom: 'none !important'
                            },
                            '&::before': {
                                borderBottom: 'none !important'
                            },
                            '&:hover': {
                                // borderBottom: 'none !important',
                                background: 'red'
                            }
                        },
                    }}
                    readOnly={true}
                    variant="filled"
                    placeholder={placeholder}
                />
                )}
            />
            <SuiTypography variant="caption" style={{ opacity: 0.5}}>Press enter after typing tags</SuiTypography>
        </SuiBox>
    )
}

export default MultipleTags;