import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import { validateEmail, validatePhone, getAge } from "utils/common";
import Grid from "@mui/material/Grid";
import SCreateable from "containers/SelectCreateable";
import CustomButton from "containers/CustomButton";
import { EditClient } from "services/clientdetails";

function EditProfileModal({
  open,
  families,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientName: null,
      phoneNumber: null,
      email: null,
      age: null,
      gender: null,
      familyName: null,
      dob: null,
      anniversary: null,
      leadFrom: null,
      preference: null,
      referredBy: null,
      occupation: null,
      address: null,
      notes: null,
      status: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      phoneNumber: false,
      email: false,
      age: false,
      gender: false,
      dob: false,
      anniversary: false,
      leadFrom: false,
      referredBy: false,
      preference: false,
      occupation: false,
      address: false,
      notes: false,
      status: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for submitting data
  const [loader, setLoader] = useState(false);
  const submitHandler = async (e) => {
    if (!formdata.clientName) {
      toast.error("Please enter a client name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (formdata.phoneNumber) {
      if (!validatePhone(formdata.phoneNumber)) {
        toast.info("Enter a valid phone number");
        setErrorMessage({
          ...errorMessage,
          phoneNumber: true,
        });
        return;
      }
    } else {
      toast.info("Plz enter phone number");
      setErrorMessage({
        ...errorMessage,
        phoneNumber: true,
      });
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Plz enter email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (!formdata.gender) {
      toast.error("Please fill Gender field");
      setErrorMessage({
        ...errorMessage,
        gender: true,
      });
      return;
    }

    if (!formdata.preference) {
      toast.error("Please fill Food Preference field");
      setErrorMessage({
        ...errorMessage,
        gender: true,
      });
      return;
    }

    if (!formdata.dob) {
      toast.error("Please fill DOB field");
      setErrorMessage({
        ...errorMessage,
        dob: true,
      });
      return;
    }

    if (formdata.age === null) {
      toast.error("Please fill Age field");
      setErrorMessage({
        ...errorMessage,
        age: true,
      });
      return;
    }

    if (!formdata.leadFrom) {
      toast.error("Please select Lead From field");
      setErrorMessage({
        ...errorMessage,
        leadFrom: true,
      });
      return;
    }

    if (!formdata.referredBy) {
      toast.error("Please fill referred by field");
      setErrorMessage({
        ...errorMessage,
        referredBy: true,
      });
      return;
    }

    if (!formdata.occupation) {
      toast.error("Please fill Occupation field");
      setErrorMessage({
        ...errorMessage,
        occupation: true,
      });
      return;
    }

    if (!formdata.address) {
      toast.error("Please fill Address field");
      setErrorMessage({
        ...errorMessage,
        address: true,
      });
      return;
    }

    if (!formdata.notes) {
      toast.error("Please fill Notes field");
      setErrorMessage({
        ...errorMessage,
        notes: true,
      });
      return;
    }

    if (!formdata.status) {
      toast.error("Please fill Status field");
      setErrorMessage({
        ...errorMessage,
        status: true,
      });
      return;
    }
    try {
      setLoader(true);
      const data = await EditClient({ formdata });
      setSelectedClient((e) => {
        e = {
          ...e,
          ...data,
        };
        return e;
      });
      toast.success("Successfully updated client");
      handleCloseModal();

      setTimeout(() => {
        setSelectedItem(null);
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 500);
    } catch (e) {
      toast.error(e?.message ?? "Something went wrong, retry");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (open && selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        clientName: selectedItem.name,
        familyName: {
          label: selectedItem?.familyName,
          value: selectedItem?.familyName,
        },
        gender: { label: selectedItem.gender, value: selectedItem.gender },
        leadFrom: {
          label: selectedItem.leadFrom,
          value: selectedItem.leadFrom,
        },
        status: { label: selectedItem.status, value: selectedItem.status },
        preference: {
          label: selectedItem.preference,
          value: selectedItem.preference,
        },
      });
    }
  }, [selectedItem, defaultHandler, open]);

  const [statusOptions] = useState([
    { label: "Active", value: "Active", key: 1 },
    { label: "Inactive", value: "Inactive", key: 2 },
  ]);

  const [genderOptions] = useState([
    { value: "Male", label: "Male", key: 1 },
    { value: "Female", label: "Female", key: 2 },
    { value: "Other", label: "Other", key: 3 },
  ]);

  const [preferenceOptions] = useState([
    { value: "Veg", label: "Veg", key: 1 },
    { value: "Non-veg", label: "Non-veg", key: 2 },
    { value: "Eggetarian", label: "Eggetarian", key: 3 },
  ]);

  const [leadOptions] = useState([
    { value: "Facebook", label: "Facebook", key: 1 },
    { value: "Instagram", label: "Instagram", key: 2 },
    { value: "Twitter", label: "Twitter", key: 3 },
    { value: "Friend", label: "Friend", key: 4 },
    { label: "Walk In", value: "Walk In", key: 5 },
    { label: "Google Ads", value: "Google Ads", key: 6 },
    { label: "BNI", value: "BNI", key: 7 },
    { label: "News Paper", value: "News Paper", key: 8 },
    { label: "FM", value: "FM", key: 9 },
    { label: "Events", value: "Events", key: 10 },
    { label: "Referral", value: "Referral", key: 11 },
    { label: "Whatsapp", value: "Whatsapp", key: 12 },
    { label: "Board", value: "Board", key: 13 },
    { label: "Pamphlet", value: "Pamphlet", key: 14 },
  ]);

  //for updating date in modal
  useEffect(() => {
    if (formdata.dob) {
      let age = getAge(formdata.dob);
      setFormdata((e) => {
        return {
          ...e,
          age: age,
        };
      });
    }
  }, [formdata.dob]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="62%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            Edit {selectedItem?.name}'s Information
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            You can edit client details below.
          </SuiTypography>
        </SuiBox>

        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => {
            handleCloseModal();
            setTimeout(() => {
              setSelectedItem(null);
              setFormdata({
                ...defaultHandler,
              });
              setErrorMessage({
                ...errorDefault,
              });
            }, 500);
          }}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Name
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="clientName"
                  readOnly={mode === "view"}
                  error={errorMessage?.clientName}
                  value={formdata.clientName ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Phone Number
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="phoneNumber"
                  readOnly={mode === "view"}
                  error={errorMessage?.phoneNumber}
                  value={formdata.phoneNumber ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (val.length > 10) {
                      toast.info("Invalid phone number");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Email
                </SuiTypography>
                <SuiInput
                  type="email"
                  readOnly={mode === "view"}
                  name="email"
                  error={errorMessage?.email}
                  value={formdata.email ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Gender
                </SuiTypography>
                <Createable
                  options={genderOptions}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      gender: e,
                    });
                  }}
                  value={formdata.gender}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={2}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  DOB
                </SuiTypography>
                <SuiInput
                  type="date"
                  readOnly={mode === "view"}
                  name="dob"
                  error={errorMessage?.dob}
                  value={formdata.dob ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={2}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Anniversary
                </SuiTypography>
                <SuiInput
                  type="date"
                  name="anniversary"
                  readOnly={mode === "view"}
                  error={errorMessage?.anniversary}
                  value={formdata.anniversary ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container>
                <Grid item xs={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Age (Yrs)
                    </SuiTypography>
                    <SuiInput
                      type="number"
                      name="age"
                      readOnly={mode === "view"}
                      error={errorMessage?.age}
                      value={formdata.age ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={6}>
                  <SuiBox lineHeight={1} ml={1}>
                    <SuiTypography mb={1} variant="h6">
                      Food preference
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={preferenceOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          preference: e,
                        });
                      }}
                      value={formdata.preference}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Lead From
                </SuiTypography>
                <Createable
                  options={leadOptions}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      leadFrom: e,
                    });
                  }}
                  value={formdata.leadFrom}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Referred By
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="referredBy"
                  readOnly={mode === "view"}
                  error={errorMessage?.referredBy}
                  value={formdata.referredBy ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Occupation
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="occupation"
                  readOnly={mode === "view"}
                  error={errorMessage?.occupation}
                  value={formdata.occupation ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Address
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="address"
                  readOnly={mode === "view"}
                  multiline
                  rows={6}
                  error={errorMessage?.address}
                  value={formdata.address ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Notes
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="notes"
                  multiline
                  rows={6}
                  readOnly={mode === "view"}
                  error={errorMessage?.notes}
                  value={formdata.notes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Client Status
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={statusOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          status: e,
                        });
                      }}
                      value={formdata.status}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Family Name
                    </SuiTypography>
                    <SCreateable
                      disabled={mode === "view"}
                      options={families ?? []}
                      error={errorMessage?.familyName}
                      value={formdata?.familyName}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.800rem",
                        borderRadius: "0.5rem",
                      }}
                      placeholder="Choose/edit family"
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          familyName: false,
                        });

                        setFormdata({
                          ...formdata,
                          familyName: e,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={"Edit Client"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default EditProfileModal;
