import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadInvoicePdf } from "firebaseConfig";
import { firestore } from "firebaseConfig";

export const storeInvoicePdf = async (
  clientId,
  serviceId,
  invoicePdf,
  name,
  term
) => {
  try {
    const clientRef = doc(firestore, "client", clientId);
    const clientSnapshot = await getDoc(clientRef);
    if (clientSnapshot.exists()) {
      const clientData = clientSnapshot.data();
      const { services } = clientData;

      const uploadedInvoice = await uploadInvoicePdf(invoicePdf, name);

      let servicesData;

      if (Array.isArray(services)) {
        servicesData = services;
      } else {
        servicesData = [];
      }

      const updatedServices = servicesData?.map((service) => {
        if (service.id === serviceId) {
          const invoiceArray = [
            ...(service.invoicePdf || []),
            {
              date: new Date(),
              invoice: uploadedInvoice,
              term,
            },
          ];
          return {
            ...service,
            invoicePdf: invoiceArray,
          };
        }
        return service;
      });

      await updateDoc(doc(firestore, "client", clientId), {
        ...clientData,
        services: updatedServices,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const storeProductInvoicePdf = async (
  clientId,
  productId,
  invoicePdf,
  name,
  term
) => {
  try {
    const clientRef = doc(firestore, "client", clientId);
    const clientSnapshot = await getDoc(clientRef);
    if (clientSnapshot.exists()) {
      const clientData = clientSnapshot.data();
      const { products } = clientData;

      const uploadedInvoice = await uploadInvoicePdf(invoicePdf, name);

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          const invoiceArray = [
            ...(product.invoicePdf || []),
            {
              date: new Date(),
              invoice: uploadedInvoice,
              term,
            },
          ];
          return {
            ...product,
            invoicePdf: invoiceArray,
          };
        }
        return product;
      });

      await updateDoc(doc(firestore, "client", clientId), {
        ...clientData,
        products: updatedProducts,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const storeConsultationInvoicePdf = async (
  clientId,
  Id,
  invoicePdf,
  name,
  term
) => {
  try {
    const clientRef = doc(firestore, "client", clientId);
    const clientSnapshot = await getDoc(clientRef);

    if (clientSnapshot.exists()) {
      const clientData = clientSnapshot.data();
      const { consultation } = clientData;

      const uploadedInvoice = await uploadInvoicePdf(invoicePdf, name);

      const updatedConsultation = consultation.map((item) => {
        if (item.id === Id) {
          const invoiceArray = [
            ...(item.invoicePdf || []),
            {
              date: new Date(),
              invoice: uploadedInvoice,
              term,
            },
          ];
          return {
            ...item,
            invoicePdf: invoiceArray,
          };
        }
        return item;
      });

      await updateDoc(doc(firestore, "client", clientId), {
        ...clientData,
        consultation: updatedConsultation,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const getSelectedClientInvoices = async (clientId, id, type) => {
  try {
    const ref = doc(firestore, "client", clientId);

    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      const data = snapshot.data();
      const { services, products, consultation } = data;

      if (type === "services" && services && services.length > 0) {
        const selectedService = services.find((item) => item.id === id);

        if (selectedService) {
          return selectedService;
        }
      }

      if (type === "products" && products && products.length > 0) {
        const selectedProduct = products.find((item) => item.id === id);
        if (selectedProduct) {
          return selectedProduct;
        }
      }

      if (type === "consultation" && consultation && consultation.length > 0) {
        const selectedConsultation = consultation.find(
          (item) => item.id === id
        );
        if (selectedConsultation) {
          return selectedConsultation;
        }
      }

      return [];
    } else {
      throw new Error("Client not found!!!");
    }
  } catch (error) {
    throw error;
  }
};
