import { Button, Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import CustomButton from "containers/CustomButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userSelector } from "store/features/authSlice";
import { saveGSTNumber, clearState } from "store/features/authSlice";

const GSTNumber = ({ uid }) => {
  const dispatch = useDispatch();
  const [gstNumber, setGstNumber] = useState(null);
  const [loader, setLoader] = useState(false);
  const [saveIsClicked, setSaveIsClicked] = useState(false);
  const {
    isSuccess,
    isFetching,
    message,
    isError,
    gstNumber: updatedGst,
  } = useSelector(userSelector);

  useEffect(() => {
    setGstNumber(updatedGst);
  }, [updatedGst]);

  useEffect(() => {
    const handleSuccess = () => {
      toast.success(message);
      setLoader(false);
      dispatch(clearState());
    };

    const handleError = () => {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    };

    const handleFetching = () => {
      setLoader(true);
    };
    if (saveIsClicked) {
      if (isSuccess) {
        handleSuccess();
      } else if (isError) {
        handleError();
      } else if (isFetching) {
        handleFetching();
      } else {
        setLoader(false);
      }
    }
  }, [isSuccess, isError, isFetching, message, saveIsClicked]);

  const onSaveHandler = () => {
    const numberRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const match_ = gstNumber?.match(numberRegex);
    if (!gstNumber?.length || gstNumber === undefined || gstNumber === null) {
      return toast.error("Enter GST number!!");
    } else if (!match_) {
      return toast.error("Invalid GST number!!");
    }

    setLoader(true);
    setSaveIsClicked(true);
    const submitData = {
      setSaveIsClicked: setSaveIsClicked,
      uid,
      gstNumber,
    };
    dispatch(saveGSTNumber(submitData));
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        justifyItems="end"
        alignItems="center"
        padding={2}
        gap={1}
        height="100%"
        paddingBottom={0}
      >
        <SuiTypography mb={1} variant="h6">
          Enter/Edit Your GST Number{" "}
        </SuiTypography>
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        justifyItems="end"
        alignItems="center"
        padding={2}
        paddingTop={0}
        gap={1}
        height="100%"
      >
        <SuiTypography mb={1} variant="p" fontSize={14}>
          In certain countries, businesses are required to have a Goods and
          Services Tax (GST) number for tax purposes. Please enter your GST
          number below:
        </SuiTypography>
        <SuiBox lineHeight={1} mt={0} mb={1} style={{ width: "50%" }}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              GST Number
            </SuiTypography>
            <SuiInput
              type="text"
              name="gst"
              value={gstNumber}
              onChange={(e) => {
                setGstNumber(e.target.value);
              }}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox selfAlign={"end"}>
          <CustomButton
            color="dark"
            variant="gradient"
            onClick={onSaveHandler}
            fullWidth
            width={180}
            title={"Save"}
            loader={loader}
          />
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

export default GSTNumber;
