import SuiTypography from "components/SuiTypography";
import { IconButton } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";
import { unixTimeConverter } from "utils/helper";

export const appointmentRows = (
  appointmentArray,
  viewHandler,
  updateHandler
) => {
  const rows = appointmentArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Added)": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.createdAt)}
        </SuiTypography>
      ),

      Name: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.clientName}
        </SuiTypography>
      ),
      "Ph. No.": (
        <>
          <SuiTypography variant="button" fontWeight="medium">
            {item?.phoneNumber}
          </SuiTypography>
          <IconButton
            size="small"
            style={{ padding: "3px", marginLeft: "5px" }}
            onClick={() => {
              window.open(`https://wa.me/${item?.phoneNumber}`, "_blank");
            }}
          >
            <WhatsApp style={{ color: "#075e54" }} />
          </IconButton>
        </>
      ),

      Email: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      //   "Customer Notes": (
      //     <Tooltip title={item?.customerNotes} placement="top">
      //       <SuiTypography variant="button" fontWeight="medium">
      //         {item?.customerNotes?.length > 20
      //           ? `${item?.customerNotes?.slice(0, 20)}...`
      //           : item?.customerNotes}
      //       </SuiTypography>
      //     </Tooltip>
      //   ),

      //   "Consultant Notes (Latest)": (
      //     <Tooltip
      //       title={
      //         item?.consultantNotes?.length > 0
      //           ? item?.consultantNotes[item?.consultantNotes?.length - 1]
      //               ?.consultantNote
      //           : "N/A"
      //       }
      //       placement="top"
      //     >
      //       <SuiTypography variant="button" fontWeight="medium">
      //         {item?.consultantNotes?.length > 0
      //           ? item?.consultantNotes[item?.consultantNotes?.length - 1]
      //               .consultantNote?.length > 20
      //             ? `${item?.consultantNotes[
      //                 item?.consultantNotes?.length - 1
      //               ].consultantNote?.slice(0, 20)}...`
      //             : item?.consultantNotes[item?.consultantNotes?.length - 1]
      //                 .consultantNote
      //           : "N/A"}
      //       </SuiTypography>
      //     </Tooltip>
      //   ),

      "Appointment Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {unixTimeConverter(item?.appointmentDate)}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => updateHandler(item)}
          viewHandler={() => viewHandler(item)}
        />
      ),
    };
  });
  return rows;
};
