import React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Global } from "@emotion/react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Divider, Grid, Icon } from "@mui/material";

const drawerBleeding = -220;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

function SwipableModal({
  open,
  setOpenModal,
  title,
  subtitle,
  handleCloseModal,
  children,
  mode,
  setMode,
}) {
  const toggleDrawer = (newOpen) => () => {
    setOpenModal(newOpen);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
            width: "100%",
            margin: 0,
            background: "white",
          },
        }}
      />
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        backgroundColor="red"
        pt={1}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h6">{title}</SuiTypography>
          <SuiTypography variant="body2" color="text">
            {subtitle}
          </SuiTypography>
        </SuiBox>

        <SuiBox
          style={{ whiteSpace: "normal !important" }}
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={3}
        >
          <Grid item>
            {mode === "view" && (
              <Icon
                sx={({
                  typography: { size, fontWeightBold },
                  palette: { dark },
                }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                  mt: 2,
                  mr: 2,
                })}
                onClick={() => {
                  setMode("edit");
                }}
              >
                edit
              </Icon>
            )}
            {mode === "edit" && (
              <Icon
                sx={({
                  typography: { size, fontWeightBold },
                  palette: { dark },
                }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                  mt: 2,
                  mr: 2,
                })}
                onClick={() => {
                  setMode("view");
                }}
              >
                visibility
              </Icon>
            )}
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
              })}
              onClick={handleCloseModal}
            >
              close
            </Icon>
          </Grid>
        </SuiBox>

        {/* <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseModal}>
                    close
                </Icon> */}
      </SuiBox>
      <Divider />
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </StyledBox>
    </SwipeableDrawer>
  );
}

export default SwipableModal;
