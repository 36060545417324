import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import {
  validateEmail,
  validatePhone,
  calcPriceWT,
  getAge,
} from "utils/common";
import Grid from "@mui/material/Grid";
import SCreateable from "containers/SelectCreateable";
import CustomButton from "containers/CustomButton";
import { SellConsultation } from "store/features/clients";
import { updateFirstLetter } from "utils/helper";
import GST from "components/GST/GST";
import { getDiscountedPrice } from "utils/common";

function Modal({
  open,
  handleCloseModal,
  services,
  uid,
  clients,
  isSuccess,
  isError,
  message,
  familyArray,
  employees,
  intlPhoneNumberArray,
}) {
  //for service options list
  const [serviceOptions, setServiceOptions] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);

  useEffect(() => {
    let serviceOptionsArray = services.map((item) => {
      return {
        label: item.serviceName,
        value: item.id,
        key: item.id,
        totalAmount: calcPriceWT(item.sellingPriceWOT, item.gst),
        hsnCode: item?.hsnCode ? item?.hsnCode : "N/A",
      };
    });
    setServiceOptions(serviceOptionsArray);
  }, [services]);

  useEffect(() => {
    const employeeArray_ = employees?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: updateFirstLetter(item?.name),
        employeeName: item?.name,
        employeePhoneNumber: item?.phoneNumber,
        employeeEmail: item?.email,
      };
    });

    setEmployeeArray(employeeArray_);
  }, [employees]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientId: null,
      clientName: null,
      familyName: null,
      phoneNumber: null,
      email: null,
      countryCode: {
        label: "(+91)     India",
        value: "+91",
        key: "IN",
      },

      address: null,

      gender: null,
      dob: null,
      anniversary: null,
      preference: null,
      occupation: null,

      age: null,
      leadFrom: null,

      referredBy: null,
      package: null,

      discount: 0,
      totalAmount: null,
      amountPaid: 0,
      paymentMode: null,
      assignDietitian: null,
      packageAmount: null,
      checkGst: null,
      gstType: { label: "CGST & SGST", value: "CGST & SGST" },
      gst: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      gstComment: null,
      gstNumber: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      familyName: false,
      phoneNumber: false,
      email: false,

      address: false,

      gender: false,
      dob: false,
      anniversary: false,
      occupation: false,

      age: false,
      leadFrom: false,
      preference: false,
      referredBy: false,
      package: false,

      discount: false,
      amountPaid: false,

      paymentMode: false,
      // datetime: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for submitting data
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (formdata.checkGst) {
      const updatedFormdata = { ...formdata };
      const updatedGst =
        formdata.gstType.value === "IGST"
          ? formdata.igst
          : formdata.sgst + formdata.cgst;
      updatedFormdata.gst = updatedGst;

      setFormdata(updatedFormdata);
    }
  }, [
    formdata.checkGst,
    formdata.gstType.value,
    formdata.igst,
    formdata.sgst,
    formdata.cgst,
    formdata.package?.totalAmount,
  ]);

  useEffect(() => {
    const discountedPrice = getDiscountedPrice(
      formdata.packageAmount,
      formdata.discount
    );

    let gstAmount = discountedPrice * (formdata.gst / 100);

    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        package: {
          ...formdata?.package,
          totalAmount: (discountedPrice + gstAmount).toFixed(2),
        },
      });
    } else {
      setFormdata({
        ...formdata,
        package: {
          ...formdata?.package,
          totalAmount: discountedPrice?.toFixed(2),
        },
      });
    }
  }, [
    formdata?.gst,
    formdata.checkGst,
    formdata?.packageAmount,
    formdata?.discount,
  ]);

  const submitHandler = async (e) => {
    const gstNumberRegex =
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const gstNumberMatch = formdata?.gstNumber?.match(gstNumberRegex);

    if (!formdata.clientName) {
      toast.error("Please enter a Client Name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (!formdata.email) {
      setFormdata({ ...formdata, email: "N/A" });
    } else if (!validateEmail(formdata.email) && formdata.email !== "N/A") {
      toast.info("Enter a valid Email");
      setErrorMessage({ 
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (!formdata.address) {
      setFormdata({ ...formdata, address: "N/A" });
    }

    if (!formdata.gender.label) {
      toast.error("Please fill Gender field");
      setErrorMessage({
        ...errorMessage,
        gender: true,
      });
      return;
    }

    if (!formdata.dob) {
      toast.error("Please fill DOB field");
      setErrorMessage({
        ...errorMessage,
        dob: true,
      });
      return;
    }

    if (formdata.age === null) {
      toast.error("Please fill Age field");
      setErrorMessage({
        ...errorMessage,
        age: true,
      });
      return;
    }

    if (formdata.preference === null) {
      toast.error("Please fill Preference field");
      setErrorMessage({
        ...errorMessage,
        preference: true,
      });
      return;
    }

    if (!formdata.leadFrom) {
      setFormdata({ ...formdata, leadFrom: "N/A" });
    }

    if (!formdata.occupation) {
      setFormdata({ ...formdata, occupation: "N/A" });
    }

    if (!formdata.referredBy) {
      setFormdata({ ...formdata, referredBy: "N/A" });
    }

    if (!formdata.package) {
      toast.error("Please select Choose Package field");
      setErrorMessage({
        ...errorMessage,
        package: true,
      });
      return;
    }

    if (formdata.discount === null || formdata.discount === "") {
      toast.error("Please fill Discount field");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata.discount < 0) {
      toast.info("Discount value can't be negative");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata.amountPaid === null || formdata.amountPaid === "") {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (formdata.amountPaid < 0) {
      toast.info("Amount Paid value can't be negative");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (!formdata.paymentMode) {
      toast.error("Please select Payment Mode field");
      setErrorMessage({
        ...errorMessage,
        paymentMode: true,
      });
      return;
    }

    if (formdata?.checkGst) {
      if (!formdata.gstTreatment) {
        toast.error("Please fill GST treatment field");
        setErrorMessage({
          ...errorMessage,
          cgst: true,
        });
        return;
      }
      if (
        !formdata?.gstNumber?.length ||
        formdata?.gstNumber === undefined ||
        formdata?.gstNumber === null
      ) {
        return toast.error("Please fill GST number!!");
      } else if (!gstNumberMatch) {
        return toast.error("Invalid GST number!!");
      }

      if (formdata?.gstType.value === "IGST") {
        if (!formdata.igst) {
          toast.error("Please fill IGST field");
          setErrorMessage({
            ...errorMessage,
            igst: true,
          });
          return;
        }
      } else {
        if (!formdata.sgst) {
          toast.error("Please fill SGST field");
          setErrorMessage({
            ...errorMessage,
            sgst: true,
          });
          return;
        }

        if (!formdata.cgst) {
          toast.error("Please fill CGST field");
          setErrorMessage({
            ...errorMessage,
            cgst: true,
          });
          return;
        }
      }
    }

    const balance = calculateBalance();
    if (balance < 0) {
      return toast.error("Balance can't be negative!");
    }

    setFormdata((prevFormdata) => {
      const updatedFormdata = { ...prevFormdata, balance };
      return updatedFormdata;
    });

    setLoader(true);
    setTimeout(() => {
      dispatch(SellConsultation({ formdata: { ...formdata, balance }, uid }));
    }, 0);
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setSelectedClient(null);
      setTimeout(() => {
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setFormdataCheck({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    defaultHandler,
    message,
    setLoader,
  ]);

  const [genderOptions] = useState([
    { value: "Male", label: "Male", key: 1 },
    { value: "Female", label: "Female", key: 2 },
    { value: "Other", label: "Other", key: 3 },
  ]);

  const [preferenceOptions] = useState([
    { value: "Veg", label: "Veg", key: 1 },
    { value: "Non-veg", label: "Non-veg", key: 2 },
    { value: "Eggetarian", label: "Eggetarian", key: 3 },
  ]);

  const [paymentOptions] = useState([
    { value: "Cash", label: "Cash", key: 1 },
    { value: "Online", label: "Online", key: 2 },
  ]);

  const [leadOptions] = useState([
    { value: "Facebook", label: "Facebook", key: 1 },
    { value: "Instagram", label: "Instagram", key: 2 },
    { value: "Twitter", label: "Twitter", key: 3 },
    { value: "Friend", label: "Friend", key: 4 },
    { label: "Walk In", value: "Walk In", key: 5 },
    { label: "Google Ads", value: "Google Ads", key: 6 },
    { label: "BNI", value: "BNI", key: 7 },
    { label: "News Paper", value: "News Paper", key: 8 },
    { label: "FM", value: "FM", key: 9 },
    { label: "Events", value: "Events", key: 10 },
    { label: "Referral", value: "Referral", key: 11 },
    { label: "Whatsapp", value: "Whatsapp", key: 12 },
    { label: "Board", value: "Board", key: 13 },
    { label: "Pamphlet", value: "Pamphlet", key: 14 },
  ]);

  //for searching client details
  const [clientArray, setClientArray] = useState([]);
  useEffect(() => {
    const clientArray_ = clients.map((item, index) => {
      return {
        key: index,
        value: item.name,
        label:
          item.name +
          `${
            item.countryCode
              ? ` (${item.countryCode.value} ${item.phoneNumber})`
              : ` (+91 ${item.phoneNumber})`
          }`,
        clientId: item?.id,
        clientName: item?.name,
        familyName: item?.familyName,
        countryCode: item.countryCode ?? {
          label: "(+91) India",
          value: "+91",
          key: "IN",
        },
        phoneNumber: item?.phoneNumber,
        email: item?.email,
        address: item?.address ?? null,
        gender: { value: item?.gender ?? null, label: item?.gender ?? null },
        dob: item?.dob ?? null,
        anniversary: item?.anniversary ?? null,
        occupation: item?.occupation ?? null,
        age: item?.age ?? null,
        preference: {
          value: item?.preference ?? null,
          label: item?.preference ?? null,
        },
        leadFrom: {
          value: item?.leadFrom ?? null,
          label: item?.leadFrom ?? null,
        },
        referredBy: item?.referredBy ?? null,
      };
    });
    setClientArray(clientArray_);
  }, [clients]);

  const [formdataCheck, setFormdataCheck] = useState(defaultHandler);
  const [selectedClient, setSelectedClient] = useState();
  const onChangeTextName = (e) => {
    setSelectedClient(e);

    if (e.__isNew__) {
      setFormdata({
        ...formdata,
        clientId: null,
        clientName: e.clientName ?? e.value,
        familyName: { value: null, label: null },
        phoneNumber: null,
        email: null,
        address: null,
        gender: { value: null, label: null },
        dob: null,
        occupation: null,
        anniversary: null,
        age: null,
        preference: { value: null, label: null },
        leadFrom: { value: null, label: null },
        referredBy: null,
      });

      setFormdataCheck({
        ...formdata,
        clientId: null,
        clientName: e.clientName ?? e.value,
        familyName: { value: null, label: null },
        phoneNumber: null,
        email: null,
        address: null,
        gender: { value: null, label: null },
        dob: null,
        anniversary: null,
        occupation: null,
        age: null,
        preference: { value: null, label: null },
        leadFrom: { value: null, label: null },
        referredBy: null,
      });
    } else {
      setFormdata({
        ...formdata,
        countryCode: e?.countryCode ?? {
          label: "(+91)     India",
          value: "+91",
          key: "IN",
        },
        clientId: e?.clientId,
        clientName: e?.clientName,
        familyName: e?.familyName
          ? { label: e.familyName, value: e.familyName }
          : { value: null, label: null },
        phoneNumber: e?.phoneNumber,
        email: e?.email,
        address: e?.address ?? null,
        gender: e.gender ? e?.gender : null,
        dob: e?.dob ?? null,
        anniversary: e?.anniversary ?? null,
        preference: e?.preference ? e?.preference : null,
        occupation: e?.occupation ?? null,
        age: e?.age ?? null,
        leadFrom: e?.leadFrom ? e.leadFrom : null,
        referredBy: e?.referredBy ?? null,
      });

      setFormdataCheck({
        ...formdata,
        clientId: e?.id,
        clientName: e?.clientName,
        familyName: e?.familyName
          ? { label: e.familyName, value: e.familyName }
          : { value: null, label: null },
        phoneNumber: e?.phoneNumber,
        email: e?.email,
        address: e?.address ?? null,
        gender: e.gender ? e?.gender : null,
        preference: e?.preference ? e?.preference : null,
        dob: e?.dob ?? null,
        anniversary: e?.anniversary ?? null,
        occupation: e?.occupation ?? null,
        age: e?.age ?? null,
        leadFrom: e?.leadFrom ? e.leadFrom : null,
        referredBy: e?.referredBy ?? null,
      });
    }
  };

  const calculateBalance = () => {
    if (formdata.package?.totalAmount) {
      return (
        parseFloat(formdata.package?.totalAmount) -
        parseFloat(formdata.amountPaid)
      ).toFixed(2);
    }
  };

  //for updating date in modal
  useEffect(() => {
    if (formdata.dob) {
      let age = getAge(formdata.dob);
      setFormdata((e) => {
        return {
          ...e,
          age: age,
        };
      });
    }
  }, [formdata.dob]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="75%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            Add Client (For Consultation)
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            Provide client details & add
          </SuiTypography>
        </SuiBox>

        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => {
            handleCloseModal();
            setTimeout(() => {
              setSelectedClient(null);
              setFormdata({
                ...defaultHandler,
              });
              setFormdataCheck({
                ...defaultHandler,
              });
              setErrorMessage({
                ...errorDefault,
              });
            }, 500);
          }}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Name
                </SuiTypography>
                <SCreateable
                  options={clientArray ?? []}
                  error={errorMessage?.clientName}
                  value={selectedClient}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose client"
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      clientName: false,
                    });

                    onChangeTextName(e);
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Country Code
                </SuiTypography>
                <SCreateable
                  options={intlPhoneNumberArray ?? []}
                  disabled={formdata?.clientId !== null}
                  error={errorMessage?.countryCode}
                  value={formdata?.countryCode}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose client"
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      countryCode: false,
                    });
                    setFormdata({
                      ...formdata,
                      phoneNumber: null,
                      countryCode: e,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Phone Number
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="phoneNumber"
                  readOnly={formdata?.clientId !== null}
                  error={errorMessage?.phoneNumber}
                  value={formdata.phoneNumber ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    if (
                      formdata.countryCode.key === "IN"
                        ? val.length > 10
                        : val.length > formdata.countryCode.phoneLength
                    ) {
                      toast.info("Invalid phone number");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Email
                </SuiTypography>
                <SuiInput
                  type="email"
                  name="email"
                  readOnly={formdataCheck?.clientId !== null}
                  error={errorMessage?.email}
                  value={formdata.email ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length > 40) return false;
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1} mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Address
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="address"
                  readOnly={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.address !== null
                  }
                  multiline
                  rows={6}
                  error={errorMessage?.address}
                  value={formdata.address ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Gender
                    </SuiTypography>
                    <Createable
                      options={genderOptions}
                      disabled={
                        formdataCheck?.clientId !== null &&
                        formdataCheck?.gender?.value !== null
                      }
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          gender: e,
                        });
                      }}
                      value={formdata?.gender}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      DOB
                    </SuiTypography>
                    <SuiInput
                      type="date"
                      name="dob"
                      readOnly={
                        formdataCheck?.clientId !== null &&
                        formdataCheck.dob !== null
                      }
                      error={errorMessage?.dob}
                      value={formdata.dob ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={6}>
                  <SuiBox mt={1}>
                    <SuiTypography mb={1} variant="h6">
                      Occupation
                    </SuiTypography>
                    <SuiInput
                      type="text"
                      name="occupation"
                      readOnly={
                        formdataCheck?.clientId !== null &&
                        formdataCheck.occupation !== null
                      }
                      error={errorMessage?.occupation}
                      value={formdata.occupation ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={6}>
                  <SuiBox mt={1}>
                    <SuiTypography mb={1} variant="h6">
                      Anniversary
                    </SuiTypography>
                    <SuiInput
                      type="date"
                      name="anniversary"
                      readOnly={
                        formdataCheck?.clientId !== null &&
                        formdataCheck.anniversary !== null
                      }
                      error={errorMessage?.anniversary}
                      value={formdata.anniversary ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container>
                <Grid item xs={6}>
                  <SuiBox lineHeight={1} mt={3}>
                    <SuiTypography mb={1} variant="h6">
                      Age (Yrs)
                    </SuiTypography>
                    <SuiInput
                      type="number"
                      name="age"
                      readOnly={true}
                      error={errorMessage?.age}
                      value={formdata.age ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={6}>
                  <SuiBox lineHeight={1} mt={3} ml={1}>
                    <SuiTypography mb={1} variant="h6">
                      Food preference
                    </SuiTypography>
                    <Createable
                      options={preferenceOptions}
                      disabled={
                        formdataCheck?.clientId !== null &&
                        formdataCheck.preference?.value !== null
                      }
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          preference: e,
                        });
                      }}
                      value={formdata.preference}
                    />
                  </SuiBox>
                </Grid>
              </Grid>

              <SuiBox lineHeight={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <SuiBox lineHeight={1} mt={3}>
                      <SuiTypography mb={1} variant="h6">
                        Lead From
                      </SuiTypography>
                      <Createable
                        options={leadOptions}
                        disabled={
                          formdataCheck?.clientId !== null &&
                          formdataCheck.leadFrom?.value !== null
                        }
                        style={{ borderRadius: "0.5rem", fontSize: 14 }}
                        inputStyle={{
                          width: 190,
                          height: "1.850rem",
                          borderRadius: "0.5rem",
                        }}
                        onChange={(e) => {
                          setFormdata({
                            ...formdata,
                            leadFrom: e,
                          });
                        }}
                        value={formdata.leadFrom}
                      />
                    </SuiBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SuiBox lineHeight={1} mt={3} ml={1}>
                      <SuiTypography mb={1} variant="h6">
                        Family Name
                      </SuiTypography>
                      <SCreateable
                        options={familyArray ?? []}
                        disabled={
                          formdataCheck?.clientId !== null &&
                          formdataCheck?.familyName.value !== null
                        }
                        error={errorMessage?.familyName}
                        value={formdata?.familyName}
                        style={{ borderRadius: "0.5rem", fontSize: 14 }}
                        inputStyle={{
                          width: 190,
                          height: "1.800rem",
                          borderRadius: "0.5rem",
                        }}
                        placeholder="Choose/add family"
                        onChange={(e) => {
                          setErrorMessage({
                            ...errorMessage,
                            familyName: false,
                          });

                          setFormdata({
                            ...formdata,
                            familyName: e,
                          });
                        }}
                      />
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Referred By
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="referredBy"
                  readOnly={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.referredBy !== null
                  }
                  error={errorMessage?.referredBy}
                  value={formdata.referredBy ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Choose Consultation Package
                </SuiTypography>
                <Createable
                  options={serviceOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      package: e,
                      hsnCode: e.hsnCode,
                      packageAmount: parseFloat(e.totalAmount),
                    });
                  }}
                  value={formdata.package}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Total Amount
                    </SuiTypography>
                    <SuiTypography mb={1} variant="h7">
                      INR {formdata?.package?.totalAmount ?? "-"}
                    </SuiTypography>
                  </SuiBox>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SuiBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formdata?.checkGst}
                          onChange={() => {
                            setFormdata({
                              ...formdata,
                              checkGst: !formdata?.checkGst,
                              gstTreatment: null,
                              igst: 0,
                              sgst: 0,
                              cgst: 0,
                            });
                          }}
                        />
                      }
                      label="GST"
                      sx={{ marginLeft: 0, width: "fit-content" }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              {formdata?.checkGst && (
                <SuiBox mt={3}>
                  <GST
                    formdata={formdata}
                    setFormdata={setFormdata}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    gstTreatment
                  />
                </SuiBox>
              )}
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Discount Amount
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="discount"
                  error={errorMessage?.discount}
                  value={formdata.discount ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    if (formdata.package === null) {
                      toast.info("Select package");
                      return;
                    }

                    if (e.target.value > parseFloat(formdata.totalAmount)) {
                      toast.info("Can' exceed total amount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="amountPaid"
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    if (formdata.package === null) {
                      toast.info("Select package");
                      return;
                    }

                    if (
                      e.target.value >
                      parseFloat(formdata.totalAmount) -
                        parseFloat(formdata.discount ?? 0)
                    ) {
                      toast.info("Can' exceed total amount after discount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Balance
                </SuiTypography>
                <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                  INR{" "}
                  {isNaN(calculateBalance()) ? "-" : calculateBalance() ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Payment Mode
                </SuiTypography>
                <Createable
                  options={paymentOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      paymentMode: e,
                    });
                  }}
                  value={formdata.paymentMode}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Assign Dietitian
                </SuiTypography>
                <Createable
                  value={formdata?.assignDietitian}
                  options={employeeArray ?? []}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    zIndex: 999,
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose Employee Access"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      setFormdata({
                        ...formdata,
                        assignDietitian: options.map((opt) => {
                          return { value: opt.value, label: opt.label };
                        }),
                      });
                    }
                  }}
                />
              </SuiBox>
            </Grid>

            {/* <Grid item xs={12} lg={4}>
                        <SuiBox lineHeight={1}>
                            <SuiTypography mb={1} variant="h6">Date/Time</SuiTypography>
                            <SuiInput 
                                type="datetime-local" 
                                name="datetime"
                                value={formdata.datetime ?moment(formdata.datetime).format("YYYY-MM-DDTkk:mm"):moment().format("YYYY-MM-DDTkk:mm")}
                                error={errorMessage?.datetime}
                                onChange={(e)=>{
                                    setErrorMessage({
                                        ...errorMessage,
                                        [e.target.name]: false
                                    })
                                    setFormdata({
                                        ...formdata,
                                        [e.target.name]: e.target.value
                                    })
                                }} />
                        </SuiBox>
                    </Grid> */}
          </Grid>
        </SuiBox>

        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={"Add Client"}
              loader={loader}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
