// BMRCalculator.js
import React, { useState } from "react";
import { Container, Paper, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Createable from "containers/Select";
import { genderOptions } from "constant";
import { toast } from "react-toastify";

const BMRCalculator = () => {
  const [weight, setWeight] = useState("50");
  const [height, setHeight] = useState("170");
  const [age, setAge] = useState("25");
  const [gender, setGender] = useState("");
  const [bmr, setBmr] = useState(null);

  const calculateBmr = () => {
    if (weight && height && age && gender) {
      const numericAge = parseInt(age);
      const numericWeight = parseFloat(weight);
      const numericHeight = parseFloat(height);

      if (isNaN(numericAge) || isNaN(numericWeight) || isNaN(numericHeight)) {
        console.error(
          "Invalid input. Please enter numeric values for age, weight, and height."
        );
        return;
      }
      let bmr;
      if (gender.value === "Male" || gender.value === "Other") {
        bmr =
          88.362 +
          13.397 * numericWeight +
          4.799 * numericHeight -
          5.677 * numericAge;
      } else if (gender.value === "Female") {
        bmr =
          447.593 +
          9.247 * numericWeight +
          3.098 * numericHeight -
          4.33 * numericAge;
      }

      setBmr(bmr.toFixed(2));
    } else {
      setBmr(null);
      toast.error("Please fill all the fields");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={2} style={{ padding: "20px", marginTop: "20px" }}>
        <SuiTypography variant="h4" mb={2}>
          {" "}
          BMR Calculator{" "}
        </SuiTypography>
        <form>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Weight (in Kgs)
              </SuiTypography>
              <SuiInput
                type="number"
                name="weight"
                placeholder="Enter weight"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Height (in cm)
              </SuiTypography>
              <SuiInput
                type="number"
                name="height"
                placeholder="Enter height"
                value={height}
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Age
              </SuiTypography>
              <SuiInput
                type="number"
                name="age"
                placeholder="Enter age"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Gender
              </SuiTypography>
              <Createable
                options={genderOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setGender(e);
                }}
                value={gender}
              />
            </Grid>
          </Grid>
          <SuiButton
            fullWidth
            variant="contained"
            color="dark"
            onClick={calculateBmr}
            style={{ padding: "10px 0" }}
          >
            Calculate BMR
          </SuiButton>
        </form>
        {bmr !== null && (
          <SuiTypography variant="h6" style={{ marginTop: "20px" }}>
            Estimated BMR: {bmr} Calories/day
          </SuiTypography>
        )}
      </Paper>
    </Container>
  );
};

export default BMRCalculator;
