import React, { useState } from 'react'
import SuiBox from 'components/SuiBox';
import Navbar from '../navbar';
import Sidebar from '../sidebar';
import { styled, useTheme } from '@mui/material/styles';
import { clientRoutes } from 'routes';
import Customer from 'pages/customer';


export const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        overflow: "hidden",
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);


export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


function Layout({ children }) {

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [ componentIndex, setComponentIndex ] = useState(0)

    return (
        <SuiBox sx={{ display: 'flex'}}>
            <Navbar 
                open={open} 
                handleDrawerOpen={handleDrawerOpen}/>
            <Sidebar
                open={open}
                handleDrawerClose={handleDrawerClose}
                theme={theme}
                routes={clientRoutes??[]}
                componentIndex={componentIndex}
                setComponentIndex={setComponentIndex} />

            <Main open={open}  overflow="hidden">
                <DrawerHeader />
                <Customer componentkey={componentIndex}/>
            </Main>
        </SuiBox>
    )
}

export default Layout