import SuiTypography from "components/SuiTypography";
import { globaleDateTimeFormatter } from "utils/helper";

export const RenewablesRow = (renewables, navigate) => {
  const rows = renewables.map((item) => {
    return {
      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
            paddingLeft: 14,
          }}
          onClick={() => {
            navigate(`/clients/${item.id}`);
          }}
        >
          {item?.clientName}
        </SuiTypography>
      ),

      "Service Name": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.serviceName}
        </SuiTypography>
      ),

      "Start Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.startDate, "date")}
        </SuiTypography>
      ),

      "Completion Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.completionDate, "date")}
        </SuiTypography>
      ),
    };
  });

  return rows;
};
