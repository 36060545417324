import React, { useState, useEffect, memo } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import Createable from "containers/Select";
import SCreateable from "containers/SelectCreateable";
import CreatableSingle from "containers/SelectCreateable";
import MultipleTags from "containers/Tags";
import { Autocomplete, Chip, Icon, TextField } from "@mui/material";
import moment from "moment/moment";
import "../../../../../index.css";
import { convertTo24HourFormat } from "utils/common";
import NutritionModal from "components/NutritionValueModal";

function RecipeInput({
  recipeList,
  chartId,
  selectedItem,
  setDietPlan,
  index,
  clearRecipe,
  defaultHandler,
  recipeLoader,
  readOnly,
}) {
  const [UnitState] = useState([
    { label: "Litre", value: "Litre", key: 0 },
    { label: "Gms", value: "Gms", key: 1 },
    { label: "Ml", value: "Ml", key: 2 },
    { label: "Table Spoon", value: "Table Spoon", key: 3 },
    { label: "Tablet", value: "Tablet", key: 4 },
    { label: "Softgel", value: "Softgel", key: 4 },
    { label: "Katori", value: "Katori", key: 4 },
    { label: "Tea Cup", value: "Tea Cup", key: 4 },
    { label: "Tea Spoon", value: "Tea Spoon", key: 4 },
    { label: "Kernel", value: "Kernel", key: 4 },
    { label: "Bowl", value: "Bowl", key: 4 },
    { label: "Scoop", value: "Scoop", key: 4 },
    { label: "Piece", value: "Piece", key: 4 },
    { label: "Nuts", value: "Nuts", key: 4 },
    { label: "Servings", value: "Servings", key: 4 },
    { label: "Glass", value: "Glass", key: 4 },
    { label: "Small", value: "Small", key: 4 },
    { label: "Big", value: "Big", key: 4 },
    { label: "Medium", value: "Medium", key: 4 },
  ]);

  const [foodCategory] = useState([
    { label: "Vegetables", value: "Vegetables", key: 1 },
    { label: "Fruits", value: "Fruits", key: 2 },
    { label: "Grains", value: "Grains", key: 3 },
    { label: "Protein Foods", value: "Protein Foods", key: 4 },
    { label: "Dairy", value: "Dairy", key: 5 },
    { label: "Oils & Solid Fats", value: "Oils & Solid Fats", key: 6 },
    { label: "Added Sugars", value: "Added Sugars", key: 7 },
    { label: "Beverages", value: "Beverages", key: 8 },
    { label: "Egg", value: "Egg", key: 9 },
    { label: "Non Veg", value: "Non Veg", key: 10 },
    { label: "Sea Food", value: "Sea Food", key: 11 },
    { label: "Dietary Fiber", value: "Dietary Fiber", key: 12 },
    { label: "Vitamins Rich", value: "Vitamins Rich", key: 13 },
    { label: "Minerals Rich", value: "Minerals Rich", key: 14 },
    { label: "Carbs Rich", value: "Carbs Rich", key: 15 },
    { label: "Others", value: "Others", key: 16 },
  ]);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [caloriesPerUnit, setCaloriesPerUnit] = useState(null);
  const [editCalorie, setEditCalorie] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [constQuantity, setConstQuantity] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [constantSelectedItem, setConstantSelectedItem] = useState(null);
  const [message, setMessage] = useState(null);

  const onChangeTextName = (e, index) => {
    let valueObj = e;
    setMessage(null);

    if (!valueObj.id) {
      setDietPlan((ee) => {
        let arrVal = {
          ...defaultHandler,
          description: "",
          preparation: "",
          calories: "",
          howToTake: "",
          weight: "",
          qty: "",
          unit: "",
          name: { label: valueObj.value, value: valueObj.value },
        };

        let arr = [...ee[chartId]];
        arr[index] = arrVal;

        return { ...ee, [chartId]: arr };
      });

      setSelectedOptions([]);
    } else {
      setDietPlan((ee) => {
        const arrVal = {
          id: valueObj?.id,
          name: valueObj?.name,
          description: valueObj?.description,
          preparation: valueObj?.preparation,
          calories: valueObj?.calories ? valueObj?.calories : "",
          foodType: valueObj?.foodType,
          ingredients: valueObj?.ingredients,
          nutritions: valueObj?.nutritions,
          howToTake: valueObj?.howToTake,
          weight: valueObj?.weight ? valueObj?.weight : "",
          foodCategory: valueObj?.foodCategory,
          qty: valueObj?.qty,
          unit: valueObj?.unit,
          time: ee[chartId][index]?.time ?? null,
        };

        let arr = [...ee[chartId]];
        arr[index] = arrVal;

        return { ...ee, [chartId]: arr };
      });
      setSelectedOptions(valueObj?.nutritions ?? []);
    }
  };

  const calculateCalories = (qty) => {
    const cal = caloriesPerUnit * qty;
    return parseFloat(cal.toFixed(2));
  };

  const calculateNutritionValue = (qty) => {
    return selectedOptions
      .map((item, index) => {
        const calc = defaultOptions[index].grams * (qty / constQuantity);
        if (calc > 0) {
          return {
            ...item,
            grams: parseFloat(calc.toFixed(2)),
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);
  };

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...selectedItem,
        time: convertTo24HourFormat(selectedItem?.time),
        name: selectedItem.name
          ? selectedItem.name?.label
            ? selectedItem.name
            : { label: selectedItem.name, value: selectedItem.name }
          : null,
        foodCategory: selectedItem.foodCategory
          ? selectedItem.foodCategory?.label
            ? selectedItem.foodCategory
            : {
                label: selectedItem.foodCategory,
                value: selectedItem.foodCategory,
              }
          : null,
        unit: selectedItem.unit
          ? selectedItem.unit?.label
            ? selectedItem.unit
            : { label: selectedItem.unit, value: selectedItem.unit }
          : null,
      });
      setSelectedOptions(
        selectedOptions.length
          ? selectedOptions
          : selectedItem?.nutritions ?? []
      );
    }

    if (selectedItem?.id && constantSelectedItem?.id !== selectedItem?.id) {
      setConstantSelectedItem(selectedItem);
      const caloriePerQty =
        parseFloat(selectedItem?.calories) / parseFloat(selectedItem?.qty);
      setCaloriesPerUnit(caloriePerQty);
      setConstQuantity(selectedItem?.qty);
      setEditCalorie(false);
      setDefaultOptions(selectedItem?.nutritions ?? []);
    }
  }, [selectedItem, setFormdata, selectedOptions]);

  //for handling any change in input tags
  const onChangeHandler = (e) => {
    setFormdata((ee) => {
      ee[e.target.name] = e.target.value;
      return ee;
    });

    setDietPlan((ee) => {
      const arrVal = {
        ...ee[chartId][index],
        [e.target.name]: e.target.value,
      };

      let arr = [...ee[chartId]];
      arr[index] = arrVal;
      return { ...ee, [chartId]: arr };
    });

    if (e.target.name === "calories" && selectedItem?.id) {
      setEditCalorie(true);
      setMessage(
        "You have changed the calories, please change qty and nutrition value manually."
      );
    }
    if (e.target.name === "unit" && selectedItem?.id) {
      setEditCalorie(true);
      setMessage(
        "You have changed the unit, please change calories, qty and nutrition value manually."
      );
    }
    if (e.target.name !== "calories" && e.target.name !== "unit") {
      setMessage(null);
    }
    if (
      e.target.name === "qty" &&
      editCalorie === false &&
      selectedItem?.id !== null
    ) {
      const updatedCalories = calculateCalories(e.target.value);
      const updateNutritionValue = calculateNutritionValue(e.target.value);
      setSelectedOptions(updateNutritionValue);
      setFormdata((prevData) => ({
        ...prevData,
        calories: updatedCalories,
      }));
      setDietPlan((ee) => {
        const arrVal = {
          ...ee[chartId][index],
          calories: updatedCalories,
        };

        let arr = [...ee[chartId]];
        arr[index] = arrVal;
        return { ...ee, [chartId]: arr };
      });
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessage(null);
  };

  const handleAddNutrition = (grams) => {
    const existingIndex = selectedOptions.findIndex(
      (item) => item.type === selectedType
    );

    if (existingIndex !== -1) {
      const updatedOptions = [...selectedOptions];
      updatedOptions[existingIndex].grams = grams;
      setSelectedOptions(updatedOptions);
      setFormdata(selectedOptions);
    } else {
      setSelectedOptions([...selectedOptions, { type: selectedType, grams }]);
      setDefaultOptions([...defaultOptions, { type: selectedType, grams }]);
      setFormdata((prevData) => ({
        ...prevData,
        nutritions: [...selectedOptions, { type: selectedType, grams }],
      }));
      setDietPlan((ee) => {
        const arrVal = {
          ...ee[chartId][index],
          nutritions: [...selectedOptions, { type: selectedType, grams }],
        };

        let arr = [...ee[chartId]];
        arr[index] = arrVal;
        return { ...ee, [chartId]: arr };
      });
    }

    setModalOpen(false);
  };

  const handleRemoveNutrition = (type) => {
    const updatedOptions = selectedOptions.filter((item) => item.type !== type);
    setSelectedOptions(updatedOptions);
    setDefaultOptions(defaultOptions.filter((item) => item.type !== type));
    setFormdata((prevData) => ({ ...prevData, nutritions: updatedOptions }));
    setDietPlan((ee) => {
      const arrVal = {
        ...ee[chartId][index],
        nutritions: updatedOptions,
      };

      let arr = [...ee[chartId]];
      arr[index] = arrVal;
      return { ...ee, [chartId]: arr };
    });
  };

  return (
    <div>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={2}>
          <Grid container>
            <Grid item xs={12}>
              <SuiBox lineHeight={1} mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Recipe Name
                </SuiTypography>

                <SCreateable
                  options={recipeList ?? []}
                  disabled={readOnly}
                  loading={recipeLoader}
                  value={formdata.name}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Recipe"
                  onChange={(e) => {
                    onChangeTextName(e, index);
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Recipe Description
            </SuiTypography>
            <SuiInput
              disabled={readOnly}
              type="text"
              name="description"
              readOnly={readOnly}
              rows={3}
              multiline
              placeholder="Recipe description"
              value={formdata.description}
              onChange={onChangeHandler}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              How to Prepare
            </SuiTypography>
            <SuiInput
              disabled={readOnly}
              type="text"
              name="preparation"
              readOnly={readOnly}
              rows={3}
              multiline
              placeholder="How to prepare this recipe"
              value={formdata.preparation}
              onChange={onChangeHandler}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              How to Take
            </SuiTypography>
            <SuiInput
              disabled={readOnly}
              type="text"
              name="howToTake"
              rows={3}
              readOnly={readOnly}
              multiline
              placeholder="How to take this recipe"
              value={formdata?.howToTake}
              onChange={onChangeHandler}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={2.5} mt={3}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Nutrition Value
            </SuiTypography>
            <Autocomplete
              multiple
              clearIcon={null}
              id="nutrition-input"
              sx={{
                display: "flex",
                borderBottom: "none",
                "& input": {
                  width: 200,
                  bgcolor: "background.paper",
                  borderBottom: "none",
                },
              }}
              options={["Protein", "Fat", "Carbohydrate", "Sugar"]}
              getOptionLabel={(option) => option}
              value={selectedOptions.map((option) => option.type)}
              onChange={(event, values) => {
                setModalOpen(true);
                setSelectedType(values[values.length - 1]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nutrition"
                  autoFocus
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      overflowX: "auto",
                      display: "flex !important",
                      alignItems: "flex-start !important",
                      flexWrap: "wrap !important",
                      height: "80px !important",

                      "&::after": {
                        borderBottom: "none !important",
                      },
                      "&::before": {
                        borderBottom: "none !important",
                      },
                      "&:hover": {
                        // borderBottom: 'none !important',
                        background: "red",
                      },
                    },
                  }}
                  variant="filled"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    label={`${option}: ${selectedOptions[index]?.grams}`}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      handleRemoveNutrition(option);
                    }}
                  />
                ))
              }
            />

            <NutritionModal
              open={modalOpen}
              onClose={handleCloseModal}
              onAddNutrition={handleAddNutrition}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={2} mt={3}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Ingredients
            </SuiTypography>
            <MultipleTags
              placeholder="ingredients"
              valList={formdata["ingredients"]}
              disabled={readOnly}
              height={"80px !important"}
              freeTextHanlder={(val) => {
                let eObj = { target: { value: val, name: "ingredients" } };
                onChangeHandler(eObj);
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={2} mt={3}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Food Type
            </SuiTypography>
            <MultipleTags
              placeholder="Food type"
              valList={formdata["foodType"]}
              disabled={readOnly}
              height={"80px !important"}
              freeTextHanlder={(val) => {
                let eObj = { target: { value: val, name: "foodType" } };
                onChangeHandler(eObj);
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={1.5}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Calories
                </SuiTypography>
                <SuiInput
                  disabled={readOnly}
                  type="number"
                  name="calories"
                  readOnly={readOnly}
                  value={formdata.calories}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,5}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    onChangeHandler(e);
                  }}
                />
              </SuiBox>
            </Grid>

            {/* <Grid item xs={12} md={2}>
                            <SuiBox lineHeight={1}>
                                <SuiTypography mb={1} variant="h6">Weight (gms)</SuiTypography>
                                <SuiInput                   disabled={readOnly}

                                    type="number"
                                    name="weight"
                                    readOnly={readOnly}
                                    value={formdata.weight}
                                    onChange={(e) => {
                                        let numberRegex = /^[0-9][0-9]{0,5}?(\.[0-9]{1,2})?$/;
                                        let val = e.target.value
                                        let match_ = val.match(numberRegex)
                                        if (!match_ && val) {
                                            return false
                                        }
                                        onChangeHandler(e);
                                    }} />
                            </SuiBox>
                        </Grid> */}

            <Grid item xs={12} md={1.5}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">{`Qty (${
                  formdata.unit?.value === "Litre"
                    ? "litre"
                    : formdata.unit?.value === "Ml"
                    ? "ml"
                    : "gms"
                })`}</SuiTypography>
                <SuiInput
                  disabled={readOnly}
                  type="number"
                  name="qty"
                  readOnly={readOnly}
                  value={formdata.qty}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,5}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    onChangeHandler(e);
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={2}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Unit
                </SuiTypography>
                <Createable
                  options={UnitState ?? []}
                  disabled={readOnly}
                  value={formdata.unit ?? ""}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose unit"
                  onChange={(e) => {
                    let eObj = { target: { value: e.value, name: "unit" } };
                    onChangeHandler(eObj);
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={2.5}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Food Category
                </SuiTypography>
                <Createable
                  disabled={readOnly}
                  options={foodCategory ?? []}
                  value={formdata?.foodCategory}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose food category"
                  onChange={(e) => {
                    let eObj = {
                      target: { value: e.value, name: "foodCategory" },
                    };
                    onChangeHandler(eObj);
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={2}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Time
                </SuiTypography>
                <input
                  type="time"
                  className="timePicker"
                  readOnly={readOnly}
                  style={{
                    color: "#495057",
                    width: 106,
                    paddingLeft: "0.4rem",
                  }}
                  onChange={(val) => {
                    let convertedDate = moment(val.target.value, "h:mm").format(
                      "hh:mm A"
                    );
                    let eObj = {
                      target: { value: convertedDate, name: "time" },
                    };
                    onChangeHandler(eObj);
                  }}
                  defaultValue={formdata?.time ? formdata.time : ""}
                ></input>
              </SuiBox>
            </Grid>
            {!readOnly && (
              <Grid item xs={12} md={0.1} mt={3.5}>
                <Icon
                  sx={({
                    typography: { size, fontWeightBold },
                    palette: { dark },
                  }) => ({
                    fontSize: `${size.lg} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                    mt: 2,
                  })}
                  onClick={() => clearRecipe(index)}
                >
                  close
                </Icon>
              </Grid>
            )}
          </Grid>
          {message?.length > 0 && (
            <SuiTypography variant="caption" color="error">
              {message}
            </SuiTypography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(RecipeInput);
