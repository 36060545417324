import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import React from "react";

function ModalHeader({ mode, title, subtitle }) {
  return (
    <SuiBox>
      <SuiTypography variant="h5">
        {mode === "view" ? "View" : mode === "edit" ? "Edit" : "Add New"}{" "}
        {title}
      </SuiTypography>
      <SuiTypography variant="body2" color="text">
        {mode === "view"
          ? subtitle
          : `Provide ${title} detail & ${mode === "edit" ? "edit" : "add"}`}
      </SuiTypography>
    </SuiBox>
  );
}

export default ModalHeader;
