import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components"; //x

export default function VideoPlayer({ url }) {
  return (
    <ReactPlayer
      url={url}
      playing={false}
      controls={true}
      width="100%"
      height="100%"
    />
  );
}

const PlayerContiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  position: absolute;
  background-color: grey;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1000;
  width: 20vw;
  height: 20vw;
  font-size: 20px;
  outline: none;
`;

const StyledPlayer = styled((props) => <ReactPlayer {...props} />)``;
