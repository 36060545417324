
import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

import { toast } from 'react-toastify'
import Createable from 'containers/Select'
import Grid from "@mui/material/Grid";
import SCreateable from 'containers/SelectCreateable'
import CustomButton from 'containers/CustomButton'
import SwipableModal from 'containers/Customer/modal';
import { updateProfileClientData } from "services/customer";
import { getAge } from "utils/common";


function EditProfileModal({ open, clientId, setOpenModal, setStepVal,
    stepVal, handleCloseModal, setClientData, familyArray = [] }) {

    //default modal fields value
    const defaultHandler = useMemo(() => {
        return {
            familyName: null,
            age: null,
            gender: null,
            dob: null,
            anniversary: null,
            leadFrom: null,
            referredBy: null,
            occupation: null,
            address: null,
            notes: null,
            status: null
        }
    }, []);

    //error handler
    const errorDefault = useMemo(() => {
        return {
            familyName: false,
            age: false,
            gender: false,
            dob: false,
            anniversary: false,
            leadFrom: false,
            referredBy: false,
            occupation: false,
            address: false,
            notes: false,
            status: false
        }
    }, []);


    const [formdata, setFormdata] = useState(defaultHandler)

    const [errorMessage, setErrorMessage] = useState(errorDefault)

    //for submitting data
    const [loader, setLoader] = useState(false)

    const submitHandler = async (e) => {

        if (!formdata.address) {
            toast.error("Please fill Address field")
            setErrorMessage({
                ...errorMessage,
                address: true
            })
            return;
        }

        if (!formdata.gender) {
            toast.error("Please fill Gender field")
            setErrorMessage({
                ...errorMessage,
                gender: true
            })
            return;
        }

        if (!formdata.preference) {
            toast.error("Please fill Food Preference field")
            setErrorMessage({
                ...errorMessage,
                gender: true
            })
            return;
        }

        if (!formdata.dob) {
            toast.error("Please fill DOB field")
            setErrorMessage({
                ...errorMessage,
                dob: true
            })
            return;
        }

        if (formdata.age === null) {
            toast.error("Please fill Age field")
            setErrorMessage({
                ...errorMessage,
                age: true
            })
            return;
        }


        if (!formdata.leadFrom) {
            toast.error("Please select Lead From field")
            setErrorMessage({
                ...errorMessage,
                leadFrom: true
            })
            return;
        }

        if (!formdata.occupation) {
            toast.error("Please fill Occupation field")
            setErrorMessage({
                ...errorMessage,
                occupation: true
            })
            return;
        }

        if (!formdata.referredBy) {
            toast.error("Please fill referred by field")
            setErrorMessage({
                ...errorMessage,
                referredBy: true
            })
            return;
        }

        try {
            setLoader(true)
            const data = await updateProfileClientData(clientId, formdata, stepVal, 1)
            setClientData((e) => { return { ...e, ...data } })
            toast.success("Successfully updated")
            setStepVal((e) => {
                e[1] = 1;
                return e;
            })
            handleCloseModal()
            setLoader(false)
        } catch (error) {
            console.log(error)
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    const [genderOptions,] = useState([
        { value: 'Male', label: 'Male', key: 1 },
        { value: 'Female', label: 'Female', key: 2 },
        { value: 'Other', label: 'Other', key: 3 }
    ])

    const [preferenceOptions,] = useState([
        { value: 'Veg', label: 'Veg', key: 1 },
        { value: 'Non-veg', label: 'Non-veg', key: 2 },
        { value: 'Eggetarian', label: 'Eggetarian', key: 3 }
    ])

    const [leadOptions,] = useState([
        { value: "Facebook", label: "Facebook", key: 1 },
        { value: "Instagram", label: "Instagram", key: 2 },
        { value: "Twitter", label: "Twitter", key: 3 },
        { value: "Friend", label: "Friend", key: 4 },
        { label: 'Walk In', value: 'Walk In', key: 5 },
        { label: 'Google Ads', value: 'Google Ads', key: 6 },
        { label: 'BNI', value: 'BNI', key: 7 },
        { label: 'News Paper', value: 'News Paper', key: 8 },
        { label: 'FM', value: 'FM', key: 9 },
        { label: 'Events', value: 'Events', key: 10 },
        { label: 'Referral', value: 'Referral', key: 11 },
        { label: 'Whatsapp', value: 'Whatsapp', key: 12 },
        { label: 'Board', value: 'Board', key: 13 },
        { label: 'Pamphlet', value: 'Pamphlet', key: 14 }
    ])

    //for updating date in modal
    useEffect(() => {
        if (formdata.dob) {
            let age = getAge(formdata.dob)
            setFormdata((e) => {
                return {
                    ...e,
                    'age': age
                }
            })
        }
    }, [formdata.dob])



    return (
        <SwipableModal
            open={open}
            setOpenModal={setOpenModal}
            handleCloseModal={handleCloseModal}
            title="Update profile"
            subtitle="You can add your details below.">

            <SuiBox pb={3} px={3}>
                <SuiBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <SuiBox lineHeight={1}>
                                <SuiTypography mb={1} variant="h6">Address</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="address"
                                    multiline
                                    rows={6}
                                    error={errorMessage?.address}
                                    value={formdata.address ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SuiBox lineHeight={1}>
                                        <SuiTypography mb={1} variant="h6">Gender</SuiTypography>
                                        <Createable
                                            options={genderOptions}
                                            style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                            inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                            onChange={(e) => {
                                                setFormdata({
                                                    ...formdata,
                                                    'gender': e
                                                })
                                            }}
                                            value={formdata?.gender} />
                                    </SuiBox>
                                </Grid>

                                <Grid item xs={6}>
                                    <SuiBox lineHeight={1}>
                                        <SuiTypography mb={1} variant="h6">DOB</SuiTypography>
                                        <SuiInput
                                            type="date"
                                            name="dob"
                                            error={errorMessage?.dob}
                                            value={formdata.dob ?? ''}
                                            onChange={(e) => {
                                                setErrorMessage({
                                                    ...errorMessage,
                                                    [e.target.name]: false
                                                })
                                                setFormdata({
                                                    ...formdata,
                                                    [e.target.name]: e.target.value
                                                })
                                            }} />
                                    </SuiBox>
                                </Grid>

                                <Grid item xs={6}>
                                    <SuiBox lineHeight={1} mt={1}>
                                        <SuiTypography mb={1} variant="h6">Age (Yrs)</SuiTypography>
                                        <SuiInput
                                            type="number"
                                            name="age"
                                            readOnly={true}
                                            error={errorMessage?.age}
                                            value={formdata.age ?? ''}
                                            onChange={(e) => {
                                                setErrorMessage({
                                                    ...errorMessage,
                                                    [e.target.name]: false
                                                })
                                                setFormdata({
                                                    ...formdata,
                                                    [e.target.name]: e.target.value
                                                })
                                            }} />
                                    </SuiBox>
                                </Grid>

                                <Grid item xs={6}>
                                    <SuiBox mt={1}>
                                        <SuiTypography mb={1} variant="h6">Anniversary</SuiTypography>
                                        <SuiInput
                                            type="date"
                                            name="anniversary"
                                            error={errorMessage?.anniversary}
                                            value={formdata.anniversary ?? ''}
                                            onChange={(e) => {
                                                setErrorMessage({
                                                    ...errorMessage,
                                                    [e.target.name]: false
                                                })
                                                setFormdata({
                                                    ...formdata,
                                                    [e.target.name]: e.target.value
                                                })
                                            }} />
                                    </SuiBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SuiBox >
                                        <SuiTypography mb={1} variant="h6">Occupation</SuiTypography>
                                        <SuiInput
                                            type="text"
                                            name="occupation"
                                            error={errorMessage?.occupation}
                                            value={formdata.occupation ?? ''}
                                            onChange={(e) => {
                                                setErrorMessage({
                                                    ...errorMessage,
                                                    [e.target.name]: false
                                                })
                                                setFormdata({
                                                    ...formdata,
                                                    [e.target.name]: e.target.value
                                                })
                                            }} />
                                    </SuiBox>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SuiBox lineHeight={1}>
                                        <SuiTypography mb={1} variant="h6">Food preference</SuiTypography>
                                        <Createable
                                            options={preferenceOptions}
                                            style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                            inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                            onChange={(e) => {
                                                setFormdata({
                                                    ...formdata,
                                                    'preference': e
                                                })
                                            }}
                                            value={formdata.preference} />
                                    </SuiBox>
                                </Grid>
                            </Grid>

                            <SuiBox lineHeight={1} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SuiBox lineHeight={1} mt={1}>
                                            <SuiTypography mb={1} variant="h6">Lead From</SuiTypography>
                                            <Createable
                                                options={leadOptions}
                                                style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                                inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                                onChange={(e) => {
                                                    setFormdata({
                                                        ...formdata,
                                                        'leadFrom': e
                                                    })
                                                }}
                                                value={formdata.leadFrom} />
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SuiBox lineHeight={1} mt={1}>
                                            <SuiTypography mb={1} variant="h6">Family Name</SuiTypography>
                                            <SCreateable
                                                options={familyArray ?? []}
                                                error={errorMessage?.familyName}
                                                value={formdata?.familyName}
                                                style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                                inputStyle={{ width: 190, height: '1.800rem', borderRadius: '0.5rem' }}
                                                placeholder="Choose/add family"
                                                onChange={(e) => {
                                                    setErrorMessage({
                                                        ...errorMessage,
                                                        'familyName': false
                                                    })

                                                    setFormdata({
                                                        ...formdata,
                                                        'familyName': e
                                                    })
                                                }} />
                                        </SuiBox>
                                    </Grid>
                                </Grid>
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>


                <SuiBox>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <SuiBox lineHeight={1}>
                                <SuiTypography mb={1} variant="h6">Referred By</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="referredBy"
                                    error={errorMessage?.referredBy}
                                    value={formdata.referredBy ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>


                <SuiBox mt={3} mb={2} display="flex" justifyContent="center">

                    <CustomButton
                        color="dark"
                        variant="gradient"
                        onClick={submitHandler}
                        fullWidth
                        disabled={loader || !open}
                        title={'Edit Client'}
                        loader={loader}
                    />
                </SuiBox>
            </SuiBox>
        </SwipableModal>
    )
}

export default EditProfileModal;