import React, { memo } from 'react'


function Checkbox({ selectHandler, getDietRecipes, removeDietRecipes, item, index }) {

    return (
        <input 
            type="checkbox" 
            style={{
                height: 20,
                width: 20,
                marginLeft: 20,
            }}
            checked={item.selected??false}
            onChange={(e)=>{
                if(e.target.checked){
                    selectHandler(item, e.target.checked, index)
                    getDietRecipes(item)
                } 
                else {
                    selectHandler(item, e.target.checked, index)
                    removeDietRecipes(item)
                }
                
            }}/>
    )
}

export default memo(Checkbox);