import SuiTypography from "components/SuiTypography";
import { CustomWidthTooltip } from "containers/Tooltip";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

export const AttendanceHistoryRow = (
  attendanceArray,
  toggleHandler,
  toggleOptions,
  size
) => {
  const rows = attendanceArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.date}
        </SuiTypography>
      ),

      "Date/Time (Marked)": (
        <SuiTypography variant="button" fontWeight="medium">
          {Array.isArray(item?.slots)
            ? item?.slots?.[0]?.updatedAt
              ? item?.slots?.[0]?.updatedAt
              : "Not Marked"
            : item?.slots?.updatedAt
            ? item?.slots?.updatedAt
            : "Not Marked"}
        </SuiTypography>
      ),

      "Job Role": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ textTransform: "capitalize" }}
        >
          {item?.slots?.[0]?.role === "part" ? "Part Time" : "Full Time"}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          toggleHandler={(option, date, slotIndex) =>
            toggleHandler(option, date, slotIndex)
          }
          options={toggleOptions}
          size={size}
          value={item?.slots?.presence}
          defaultValues={item?.slots?.presence}
          toggleValues={item}
          index={index}
          attendanceHistory
        />
      ),
    };
  });
  return rows;
};
