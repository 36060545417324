import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
    collection, 
    getDocs, 
    doc, 
    getDoc, 
    updateDoc, 
    query, 
    addDoc,
    where,
} from "firebase/firestore";
import { firestore } from 'firebaseConfig'
import moment from 'moment'
import { getClientDietChart } from "services/dietchart";
import { sortArrayByName } from 'utils/common'


//for getting client diet chart details
export const getClient = createAsyncThunk(
    "Client/getClient",
    async ({ id }, thunkAPI) => {
        try {

            const ref = doc(firestore, "client", id)
            let data;
            
            const dietcharts = await getClientDietChart(id)

            const snapshot = await getDoc(ref);
            if(snapshot.exists()){
                data = snapshot.data()

                let updatedAt;
                let createdAt;
                if(snapshot.data().createdAt){
                    createdAt = snapshot.data().createdAt
                    createdAt = createdAt?.nanoseconds/1000000 + createdAt?.seconds * 1000
                    createdAt = moment.utc(createdAt)
                }

                if(snapshot.data().updatedAt){
                    updatedAt = snapshot.data().updatedAt
                    updatedAt = updatedAt?.nanoseconds/1000000 + updatedAt?.seconds * 1000
                    updatedAt = moment.utc(updatedAt)
                }


                return { ...data, dietPlan: dietcharts, updatedAt, createdAt, id: id }
            } else {
                return thunkAPI.rejectWithValue({ message: 'Client not found!!!'});
            }
            
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


//for getting client list
export const getClientList = createAsyncThunk(
    "Client/getClientList",
    async ({ uid }, thunkAPI) => {
        try {
            let data=[];
            const q = query(collection(firestore, "client"), where('adminId', "==", uid));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc, index) => {
                let dates = []

                if(doc.data().dietPlan){

                    for(let j=0; j< doc.data().dietPlan.length; j++){
                        dates.push(new Date(doc.data().dietPlan[j]?.date))                        
                    }
                   
                }
                data.push(
                    { 
                        name: doc.data().name, 
                        phoneNumber: doc.data().phoneNumber,
                        label: doc.data().name + ` (+91 ${doc.data().phoneNumber})`,
                        value: doc.id,
                        key: doc.id,
                        dietDates: dates,
                    })
            });
            
            sortArrayByName(data);

            return data
            
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


//Add diet chart of client
export const AddDiet = createAsyncThunk(
    "DietChart/AddDiet",
    async ({ formdata, dietPlan, uid }, thunkAPI) => {
        try {

            // let id = formdata.clientName.value
            let clientDietPlan = {
                date : dietPlan.date,
                notes : dietPlan.notes,
                weekPlan : dietPlan.weekPlan.value,
                chartType : dietPlan.chartType.value,
                createdAt: new Date()
            }

            let keys = Object.keys(dietPlan)

            for(let i=0; i < keys.length; i++){

                if(keys[i] !== 'date' 
                    && keys[i] !== 'notes' && keys[i] !== 'clientName' 
                    && keys[i] !== 'dietChartId' && keys[i] !== 'weekPlan' 
                    && keys[i] !== 'chartType'
                    && keys[i] !== 'createdAt'
                    && keys[i] !== 'dietChartId' ){

                    for(let j=0; j<dietPlan[keys[i]].length; j++){
                        let recipe = dietPlan[keys[i]]

                        if(!dietPlan[keys[i]][j]['id'] && dietPlan[keys[i]][j]['name']){
                            const q = query(collection(firestore, "recipe"), where("name", "==", recipe[j].name.value), where("adminId", "==", uid));
                            const querySnapshotv1 = await getDocs(q);
                            if(!querySnapshotv1.empty){
                                return thunkAPI.rejectWithValue({ message: `Recipe with this name ${recipe[j].name.value} already exists, choose different name`});
                            }
                        }

                    }
                }
            }

            for(let i=0; i < keys.length; i++){
                let dietArray = []

                if(keys[i] !== 'date' && keys[i] !== 'notes' 
                    && keys[i] !== 'clientName'  && keys[i] !== 'dietChartId'
                    && keys[i] !== 'weekPlan' && keys[i] !== 'chartType' &&
                    keys[i] !== 'createdAt'
                    && keys[i] !== 'dietChartId' ){

                    for(let j=0; j < dietPlan[keys[i]].length; j++){
                        let recipe = dietPlan[keys[i]]

                        let data = {
                            name: recipe[j].name?.label? recipe[j].name?.value : recipe[j].name,                            
                            ingredients: recipe[j].ingredients,
                            nutritions: recipe[j].nutritions??[],
                            description: recipe[j].description ??"N/A",                               
                            preparation: recipe[j].preparation,
                            howToTake: recipe[j].howToTake,
                            foodCategory: recipe[j].foodCategory.value?? recipe[j].foodCategory,
                            calories: parseFloat(recipe[j].calories),
                            weight: parseFloat(recipe[j].weight),
                            foodType: recipe[j].foodType??[], 
                            unit: recipe[j].unit.value?? recipe[j].unit,
                            qty: recipe[j].qty,
                        }

                        if(!recipe[j]['id'] && dietPlan[keys[i]][j]['name']){

                            if(isNaN(data.calories) || data.calories ==="" || data.calories === null){
                                delete data.calories
                            }
                
                            if(isNaN(data.calories) || data.weight === "" || data.weight === null){
                                delete data.weight
                            } 

                            const recipeRef = await addDoc(collection(firestore, "recipe"), { 
                                ...data,
                                createdAt: new Date(),
                                adminId: uid,
                            });

                            dietArray.push({
                                id: recipeRef.id,
                                ...data,
                                time: recipe[j].time
                            })
                        } else {
                            if(dietPlan[keys[i]][j]['name']){
                                dietArray.push({
                                    id: recipe[j].id,
                                    ...data,
                                    time: recipe[j].time      
                                })
                            }
                        }
                    }

                    if(dietArray.length > 0){
                        clientDietPlan = {
                            ...clientDietPlan,
                            [keys[i]]: dietArray
                        }
                    }

                }
            }
                    
            await addDoc(collection(firestore, "dietplan"), { ...clientDietPlan })
            return { ...clientDietPlan, name: formdata.clientName, createdAt: new Date() };

        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


//Edit diet chart of client
export const EditDietChart = createAsyncThunk(
    "DietChart/EditDietChart",
    async ({ formdata, dietPlan, uid, dietId }, thunkAPI) => {
        try {

            // let id = formdata.clientName.value

            const ref = doc(firestore, "dietplan", dietId)
            let clientDietPlan = {
                date : dietPlan.date,
                notes : dietPlan.notes,
                weekPlan : dietPlan.weekPlan.value,
                chartType : dietPlan.chartType.value,
                createdAt : dietPlan.createdAt ?? new Date()
            }

            let keys = Object.keys(dietPlan)

            for(let i=0; i < keys.length; i++){

                if(keys[i] !== 'date' && keys[i] !== 'notes' && keys[i] !== 'clientName'  && keys[i] !== 'createdAt'
                    && keys[i] !== 'dietChartId' && keys[i] !== 'weekPlan' && keys[i] !== 'chartType'){

                    for(let j=0; j < dietPlan[keys[i]].length; j++){
                        let recipe = dietPlan[keys[i]]

                        if(!dietPlan[keys[i]][j]['id'] && dietPlan[keys[i]][j]['name']){
                            const q = query(collection(firestore, "recipe"), where("name", "==", recipe[j].name.value), where("adminId", "==", uid));
                            const querySnapshotv1 = await getDocs(q);
                            if(!querySnapshotv1.empty){
                                return thunkAPI.rejectWithValue({ message: `Recipe with this name ${recipe[j].name.value} already exists, choose different name`});
                            }
                        }

                    }
                }
            }

            for(let i=0; i < keys.length; i++){
                let dietArray = []

                if(keys[i] !== 'date' && keys[i] !== 'notes' 
                    && keys[i] !== 'clientName'  && keys[i] !== 'dietChartId'
                    && keys[i] !== 'weekPlan' && keys[i] !== 'chartType' && keys[i] !== 'createdAt'){

                    for(let j=0; j < dietPlan[keys[i]].length; j++){
                        let recipe = dietPlan[keys[i]]

                        let data = {
                            name: recipe[j].name?.label? recipe[j].name?.value : recipe[j].name,                            
                            ingredients: recipe[j].ingredients,
                            nutritions: recipe[j].nutritions??[],
                            description: recipe[j].description ??"N/A",                               
                            preparation: recipe[j].preparation,
                            howToTake: recipe[j].howToTake,
                            foodCategory: recipe[j].foodCategory.value?? recipe[j].foodCategory,
                            calories: parseFloat(recipe[j].calories),
                            weight: parseFloat(recipe[j].weight),
                            foodType: recipe[j].foodType??[], 
                            unit: recipe[j].unit.value?? recipe[j].unit,
                            qty: recipe[j].qty,
                        }

                        if(!recipe[j]['id'] && dietPlan[keys[i]][j]['name']){

                            if(isNaN(data.calories) || data.calories ==="" || data.calories === null){
                                delete data.calories
                            }
                
                            if(isNaN(data.calories) || data.weight === "" || data.weight === null){
                                delete data.weight
                            } 

                            const recipeRef = await addDoc(collection(firestore, "recipe"), {
                                ...data, 
                                createdAt: new Date(),
                                adminId: uid, 
                            });

                            dietArray.push({
                                id: recipeRef.id,
                                ...data,
                                time: recipe[j].time
                            })
                        } else {
                            if(dietPlan[keys[i]][j]['name']){
                                dietArray.push({
                                    id: recipe[j].id,
                                    ...data,
                                    time: recipe[j].time
                                })
                            }
                        }
                    }

                    if(dietArray.length > 0){
                        clientDietPlan = {          
                            ...clientDietPlan,
                            dietChartId: dietPlan.dietChartId,
                            [keys[i]]: dietArray
                        }
                    }

                }
            }

            await updateDoc(ref, {
                ...clientDietPlan
            });
            return { ...clientDietPlan, dietId };

        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


//Remove diet of client
export const RemoveDiet = createAsyncThunk(
    "DietChart/RemoveDiet",
    async ({ dietChartId,dietId, id }, thunkAPI) => {
        try {

            // await deleteDoc(doc(firestore, "dietplan", dietId));


            const ref = doc(firestore, "client", id)
            const snapshot = await getDoc(ref);

            if(snapshot.exists()){
                let data = snapshot.data()
                let dietPlan = data.dietPlan
                let dietPlanArray = dietPlan.filter((item)=>{

                    if(item.dietChartId !== dietChartId){
                        return {
                            ...item
                        }
                    }
                    return null
                })
                
                await updateDoc(ref, {
                    dietPlan: dietPlanArray
                });

                return { dietPlanArray };
                
            } else {
                return thunkAPI.rejectWithValue({ message: "Something went wrong, retry"});
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


//Edit payment of client
export const EditPayment = createAsyncThunk(
    "Client/EditPayment",
    async ({ formdata, id }, thunkAPI) => {
        try {

            const ref = doc(firestore, "client", id)

            let paymentData = {
                serviceId: formdata.service.value,
                serviceName: formdata.service.label,
                totalAmount: parseFloat(formdata.totalAmount),
                purchasedAt: new Date(),
                startDate: formdata.startDate,
                completionDate: formdata.completionDate,
                serviceInstance: formdata.serviceInstance.value, 
                amountPaid: parseFloat(formdata.amountPaid),
                discount: parseFloat(formdata.discount??0),   
            }
            const snapshot = await getDoc(ref);
            let data = snapshot.data()

            let serviceArray = data?.services?.map((item)=>{
                if(formdata.id === item.id){
                    return { ...item,...paymentData, updatedAt: new Date() }
                }
                return item;
            })

            await updateDoc(ref, {
                services: serviceArray
            });

            return { data: serviceArray, id };

        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);



export const DietChartSlice = createSlice({
    name: "DietChart",
    initialState: {
        client: null,
        clients: [],
        dietchart: [],
        isFetching: false,
        isSuccess: false,
        isError: false,
        message: null,
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.message = null;
            return state;
        },

        clearClientState: (state) => {
            state.client = null
        },

        clearDietState: (state) => {
            state.client = null
        }

    },
    extraReducers: {


        [getClient.fulfilled]: (state, { payload }) => {
            state.client = payload
            state.isSuccess = true
            state.isFetching = false;
        },
        [getClient.pending]: (state) => {
            state.isFetching = true;
        },
        [getClient.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },

        [getClientList.fulfilled]: (state, { payload }) => {
            state.clients = payload
            state.isSuccess = true
            state.isFetching = false;
        },
        [getClientList.pending]: (state) => {
            state.isFetching = true;
        },
        [getClientList.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },


        [AddDiet.fulfilled]: (state, { payload }) => {
            
            if(state?.client?.name === payload?.name){
                if(state?.client?.dietPlan){

                    state.client = {
                        ...state.client,
                        dietPlan: [ payload, ...state?.client?.dietPlan ]
                    }
                } else {
                    state.client = {
                        ...state.client,
                        dietPlan: [ payload ]
                    }
                } 
            }

            state.isSuccess = true
            state.isFetching = false;
            state.message = 'Diet plan added successfully!!'
        },
        [AddDiet.pending]: (state) => {
            state.isFetching = true;
        },
        [AddDiet.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },

        [EditDietChart.fulfilled]: (state, { payload }) => {
            state.client = {
                ...state.client,
                dietPlan: payload.dietPlanArray
            }
            state.isSuccess = true;
            state.isFetching = false;
            state.message = 'Successfully updated!!'
        },
        [EditDietChart.pending]: (state) => {
            state.isFetching = true;
        },
        [EditDietChart.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },

        [RemoveDiet.fulfilled]: (state, { payload }) => {
            state.client = {
                ...state.client,
                dietPlan: payload.dietPlanArray
            }
            state.isSuccess = true;
            state.isFetching = false;
            state.message = 'Successfully deleted!!'
        },
        [RemoveDiet.pending]: (state) => {
            state.isFetching = true;
        },
        [RemoveDiet.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },

        [EditPayment.fulfilled]: (state, { payload }) => {

            if(state.client !== null){
                state.client = {
                    ...state.client,
                    services: payload.data
                }
            }
            state.isSuccess = true;
            state.isFetching = false;
            state.message = 'Successfully updated!!'
        },
        [EditPayment.pending]: (state) => {
            state.isFetching = true;
        },
        [EditPayment.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.message = payload?.message || "Server Error";
        },

    },
});

export const { clearState, clearDietState, clearClientState } = DietChartSlice.actions;

export const dietChartSelector = (state) => state.dietchartList;
