import { Icon, IconButton, Popover, Typography } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import ToggleButtons from "containers/ToggleButton";
import React, { useState } from "react";
import { copyToClipboard } from "utils/common";
import Grid from "@mui/material/Grid";
import SuiTypography from "components/SuiTypography";

function TableAction({
  style,
  iconStyle,
  updateHandler,
  deleteHandler,
  viewHandler,
  mailLink,
  toggleHandler,
  ...toggleButtonProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    attendanceHistory,
    index,
    toggleValues,
    value,
    ...otherToggleButtonProps
  } = toggleButtonProps;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <SuiBox style={style}>
      {!attendanceHistory ? (
        toggleHandler && !toggleValues?.[index]?.length ? (
          <ToggleButtons
            {...otherToggleButtonProps}
            handleChange={(option) => toggleHandler(option, index, "")}
            key={index}
            value={toggleValues[index]?.presence}
            index={index}
          />
        ) : (
          toggleHandler && (
            <Grid container>
              {toggleValues[index]?.map((item, e) => (
                <Grid item xs={10} md={6} lg={6} mb={1}>
                  <SuiTypography
                    fontSize={9}
                    style={{ minWidth: "max-content", marginRight: 5 }}
                  >
                    {item?.slotTiming}
                  </SuiTypography>
                  <ToggleButtons
                    {...otherToggleButtonProps}
                    handleChange={(option, index, slotIndex) =>
                      toggleHandler(option, index, slotIndex)
                    }
                    slotNumber={e}
                    value={toggleValues[index]?.[e]?.presence}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          )
        )
      ) : toggleHandler && !toggleValues?.slots?.length ? (
        <ToggleButtons
          {...otherToggleButtonProps}
          handleChange={(option) =>
            toggleHandler(option, toggleValues?.date, "")
          }
          key={index}
          value={toggleValues?.slots?.presence}
          index={index}
        />
      ) : (
        toggleHandler && (
          <Grid container>
            {toggleValues?.slots?.map((item, e) => (
              <Grid item xs={10} md={6} lg={6} mb={1}>
                <SuiTypography
                  fontSize={9}
                  style={{ minWidth: "max-content", marginRight: 5 }}
                >
                  {item?.slotTiming}
                </SuiTypography>
                <ToggleButtons
                  {...otherToggleButtonProps}
                  handleChange={(option, _, slotIndex) =>
                    toggleHandler(option, toggleValues?.date, slotIndex)
                  }
                  slotNumber={e}
                  value={item?.presence}
                  index={index}
                />
              </Grid>
            ))}
          </Grid>
        )
      )}

      {viewHandler && (
        <IconButton size="small" color="inherit" onClick={viewHandler}>
          <Icon style={iconStyle} className={"text-white"}>
            {"visibility"}
          </Icon>
        </IconButton>
      )}

      {updateHandler && (
        <IconButton size="small" color="inherit" onClick={updateHandler}>
          <Icon style={iconStyle} className={"text-white"}>
            {"edit"}
          </Icon>
        </IconButton>
      )}

      {mailLink && (
        <>
          <IconButton
            size="small"
            color="inherit"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <Icon style={iconStyle} className={"text-white"}>
              {"link"}
            </Icon>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{ p: 1 }}
          >
            <SuiBox sx={{ mt: 1, background: "white" }}>
              <Typography
                fontSize={"small"}
                fontWeight={600}
                sx={{ p: 0, mb: 1 }}
              >
                Copy the link for the customer
              </Typography>
              <SuiBox
                sx={{ mt: 1, cursor: "pointer", background: "white" }}
                onClick={() =>
                  copyToClipboard(
                    mailLink,
                    "Link copied to clipboard",
                    "Unable to copy to clipboard"
                  )
                }
              >
                <SuiInput
                  disabled
                  value={
                    mailLink?.length > 25
                      ? `${mailLink?.slice(0, 25)}...`
                      : mailLink
                  }
                />
              </SuiBox>
            </SuiBox>
          </Popover>
        </>
      )}

      {deleteHandler && (
        <IconButton size="small" color="inherit" onClick={deleteHandler}>
          <Icon style={iconStyle} className={"text-white"}>
            {"delete"}
          </Icon>
        </IconButton>
      )}
    </SuiBox>
  );
}

export default TableAction;
