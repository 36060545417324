import React from 'react'
import { style } from './style.js'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";


function NoData({ color, message }) {
    return (
        <SuiBox style={{ ...style.box }}>
            <SuiTypography variant="caption" fontWeight="medium" style={{ color: color }}>
                {message?message:"No data available"}
            </SuiTypography>
        </SuiBox>
    )
}

export default NoData