import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import ExpenseTable from "./sub-components/expense-table";
import ExpenseModel from "./sub-components/models/ExpenseModel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userSelector } from "store/features/authSlice";
import { getExpenses } from "services/expense";
import DeleteModal from "containers/DeleteModal";
import { deleteExpense } from "services/expense";

function Expense() {
  const { uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expenseArray, setExpenseArray] = useState([]);
  const [newExpenseModal, setNewExpenseModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const expenseData = await getExpenses(uid);
        setExpenseArray(expenseData);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoader(false);
      }
    }
    fetchData();
  }, [uid]);

  const updateExpenseRow = (data, id) => {
    if (id) {
      const updatedExpenses = expenseArray.map((item) => {
        if (item.id === id) {
          return { ...item, ...data };
        }
        return item;
      });
      setExpenseArray(updatedExpenses);
    } else {
      const updatedExpenses = [data, ...expenseArray];
      setExpenseArray(updatedExpenses);
    }
  };

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = async () => {
    try {
      setDeleteLoader(true);
      await deleteExpense(selectedItem.id);
      const updatedExpenses = expenseArray.filter(
        (item) => item.id !== selectedItem.id
      );
      setExpenseArray(updatedExpenses);
      toast.success("Expense deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setDeleteLoader(false);
      setDeleteModal(false);
      setSelectedItem(null);
    }
  };

  const [mode, setMode] = useState("view"); //create or edit [create, edit, view
  const onViewHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("view");
      setNewExpenseModal(true);
    },
    [setMode, setNewExpenseModal, setSelectedItem]
  );

  const onUpdateHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("edit");
      setNewExpenseModal(true);
    },
    [setMode, setNewExpenseModal, setSelectedItem]
  );

  const onAddHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewExpenseModal(true);
  }, [setMode, setNewExpenseModal, setSelectedItem]);

  return (
    <>
      <SuiBox mb={3}>
        <Grid item xs={12}>
          <ExpenseTable
            expenses={expenseArray}
            title="Expense List"
            loader={loader}
            viewHandler={onViewHandler}
            editHandler={onUpdateHandler}
            addHandler={onAddHandler}
            deleteHandler={(id, name) => {
              setSelectedItem({ id, name });
              setDeleteModal(true);
            }}
          />
        </Grid>
      </SuiBox>

      <ExpenseModel
        mode={mode}
        open={newExpenseModal}
        updateExpenseRow={updateExpenseRow}
        setOpenModal={setNewExpenseModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        adminId={uid}
        viewHandler={onViewHandler}
        editHandler={onUpdateHandler}
      />

      {deleteModal && (
        <DeleteModal
          isFetching={deleteLoader}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </>
  );
}

export default Expense;
