import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import ProductTable from "./sub-components/product";
import ServiceTable from "./sub-components/service";

import AddProduct from "./sub-components/models/AddProduct";
import AddService from "./sub-components/models/AddService";
import SellModal from "./sub-components/models/SellModal";
import AddConsultation from "./sub-components/models/AddConsultation";

import { useDispatch, useSelector } from "react-redux";
import {
  DashboardSelector,
  getProductInventory,
  getServiceInventory,
  deleteDocument,
  clearState,
  getConsultationServices,
} from "store/features/dashboard";

import { toast } from "react-toastify";

import { getUpDownStat } from "utils/common";
import {
  StatSelector,
  statisticState,
  updateProductSellCount,
  updateNewClientCount,
} from "store/features/statistics.js";
import { monthWiseClientsCount } from "firebaseConfig";
import { userSelector } from "store/features/authSlice";
import DeleteModal from "containers/DeleteModal";
import {
  ClientSelector,
  updateFilterState,
  getClients,
  clearState as clientClearState,
} from "store/features/clients";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@mui/material";
import ConsultationTable from "./sub-components/consultation";
import moment from "moment";

const calculateServiceBalance = (services) =>
  services.reduce((acc, service) => {
    const totalAmount = parseFloat(service.totalAmount ?? 0);
    const discount = parseFloat(service.discount ?? 0);
    const amountPaid = parseFloat(service.amountPaid ?? 0);
    return acc + totalAmount - discount - amountPaid;
  }, 0);

const calculateProductBalance = (products) =>
  products.reduce((acc, product) => {
    const quantity = parseInt(product.quantity);
    const sellingPriceWOT = parseFloat(product?.sellingPriceWOT ?? 0);
    const discount = parseFloat(product?.discount ?? 0);
    const gst = parseFloat(product?.gst ?? 0);
    const amountPaid = parseFloat(product.amountPaid ?? 0);

    const productTotal =
      quantity * (sellingPriceWOT - discount + (sellingPriceWOT * gst) / 100);

    return acc + productTotal - amountPaid;
  }, 0);

const calculateClientStats = (clients) => {
  let balance = 0;
  let serviceClientCount = 0;

  const today = moment();

  clients.forEach((client) => {
    let active = false;

    if (client.services) {
      client.services.forEach((service) => {
        const isBetweenDates = today.isBetween(
          service.startDate,
          service.completionDate,
          null,
          "[]"
        );
        if (isBetweenDates) active = true;
        balance += calculateServiceBalance([service]);
      });
    }

    if (client.consultation) {
      balance += calculateServiceBalance(client.consultation);
    }

    if (client.products?.length > 0) {
      balance += calculateProductBalance(client.products);
    }

    if (active) serviceClientCount++;
  });

  return { balance, serviceClientCount };
};

function Offering() {
  const { uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const [MiniCardData, setMiniCardData] = useState([]);
  const {
    products,
    services,
    consultation,
    isError,
    isSuccess,
    message,
    isFetching,
  } = useSelector(DashboardSelector);
  const {
    clients,
    isSuccess: isSuccess_,
    isError: isError_,
    message: message_,
    isFetching: isFetching_,
  } = useSelector(ClientSelector);

  const {
    lastMonthClients,
    currentMonthClients,
    clientCount,
    lastMonthServiceSaleCount,
    currentMonthServiceSaleCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    balance,
  } = useSelector(StatSelector); //navbar stats

  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation

  const dispatch = useDispatch();

  //for clearing client state
  useEffect(() => {
    if (isSuccess_) {
      dispatch(clientClearState());
    }
    if (isError_) {
      toast.error(message_);
      dispatch(clientClearState());
    }
  }, [isSuccess_, message_, dispatch, isError_]);

  //gets inventory and client lists
  const fetchInventory = useCallback(async () => {
    try {
      setLoader(true);
      await dispatch(getProductInventory({ uid }));
      await dispatch(getServiceInventory({ uid }));
      await dispatch(getConsultationServices({ uid }));
      await dispatch(getClients({ uid }));
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  }, [uid, dispatch]);
  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  const getClientLists = () => {
    dispatch(getClients({ uid }));
  };

  // gets stats of topbar
  useEffect(() => {
    (async () => {
      const { balance, serviceClientCount } = calculateClientStats(clients);

      let {
        lastMonthClients,
        currentMonthClients,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
      } = await monthWiseClientsCount(uid);

      let payload = {
        lastMonthClients,
        currentMonthClients,
        clientCount: serviceClientCount,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
        balance,
      };
      dispatch(statisticState(payload));
    })();
  }, [dispatch, uid, clients]);

  const updateSellCount = (status, count) => {
    dispatch(updateProductSellCount({ qty: count }));
    if (!status) {
      dispatch(updateNewClientCount());
    }
  };

  //for deleting product
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = (id, persona) => {
    dispatch(deleteDocument({ persona, id }));
    setSelectedItem(null);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(clearState());
      setDeleteModal(false);
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
  }, [isSuccess, message, dispatch, isError]);

  let navigate = useNavigate(); //for navigation

  useEffect(() => {
    let MiniCardData_ = [
      {
        title: "Total Active Clients",
        count: clientCount,
        percent: {
          ...getUpDownStat(lastMonthClients, currentMonthClients),
        },
        icon: {
          color: "info",
          component: "help",
          para: "These are your total active service clients",
        },
      },
      {
        title: "Service Sales This Month",
        count: currentMonthServiceSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthServiceSaleCount,
            currentMonthServiceSaleCount
          ),
        },
        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "SERVICE", redirect: true }));
          navigate(`/clients`);
        },
        icon: {
          color: "info",
          component: "help",
          para: "This is your sales from services for the current month ",
        },
      },
      {
        title: "Product Sales This Month",
        count: currentMonthProductSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthProductSaleCount,
            currentMonthProductSaleCount
          ),
        },

        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "PRODUCT", redirect: true }));
          navigate(`/clients`);
        },
        icon: {
          color: "info",
          component: "help",
          para: "This is your product sales for the current month",
        },
      },
      {
        title: "Balance To Be Collected",
        count: <span style={{ color: "red" }}>{balance?.toFixed(2)}</span>,

        icon: {
          color: "info",
          component: "help",
          para: "Overall balance to be collected from Services & Product sales",
        },
      },
    ];
    setMiniCardData(MiniCardData_);
  }, [
    lastMonthClients,
    currentMonthClients,
    clientCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    currentMonthServiceSaleCount,
    lastMonthServiceSaleCount,
    balance,
    dispatch,
    navigate,
  ]);

  const [newProductModal, setNewProductModal] = useState(false);
  const [newServiceModal, setNewServiceModal] = useState(false);
  const [newSellModal, setNewSellModal] = useState(false);
  const [newConsultationModal, setNewConsultationModal] = useState(false);
  const [mode, setMode] = useState("create");

  const onViewHandler = useCallback((item, type) => {
    setSelectedItem(item);
    setMode("view");
    if (type === "service") {
      setNewServiceModal(true);
    } else if (type === "product") {
      setNewProductModal(true);
    } else if (type === "consultation") {
      setNewConsultationModal(true);
    }
  }, []);

  const onUpdateHandler = useCallback((item, type) => {
    setSelectedItem(item);
    setMode("edit");
    if (type === "service") {
      setNewServiceModal(true);
    } else if (type === "product") {
      setNewProductModal(true);
    } else if (type === "consultation") {
      setNewConsultationModal(true);
    }
  }, []);

  const onAddHandler = useCallback((type) => {
    setSelectedItem(null);
    setMode("create");
    if (type === "service") {
      setNewServiceModal(true);
    } else if (type === "product") {
      setNewProductModal(true);
    } else if (type === "consultation") {
      setNewConsultationModal(true);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            {MiniCardData.map((item, index) => (
              <Grid item xs={12} sm={6} xl={3} key={index}>
                <MiniStatisticsCard
                  title={{ text: item?.title }}
                  count={loader ? "" : item?.count}
                  onClickHandler={
                    item.onTileClickHandler
                      ? item.onTileClickHandler
                      : () => null
                  }
                  percentage={
                    loader
                      ? {}
                      : {
                          color: item?.percent?.color,
                          text: item?.percent?.text,
                        }
                  }
                  icon={{
                    color: item?.icon?.color,
                    component: item?.icon?.component,
                    para: item?.icon?.para,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </SuiBox>

        <Tabs>
          <TabList>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Product Inventory</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "List of your product inventory in hand, you can sell products from  here"
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">help</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Services</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"List of your services that you offer to clients "}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={2} color="auto">
                <span>Consultation</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "List of your consultation services that you offer to clients "
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
          </TabList>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProductTable
                    loader={loader}
                    adminId={uid}
                    products={products}
                    addHandler={() => onAddHandler("product")}
                    editHandler={onUpdateHandler}
                    viewHandler={onViewHandler}
                    deleteHandler={(id, name) => {
                      setSelectedItem({ id, name, persona: "product" });
                      setDeleteModal(true);
                    }}
                    onSellHandler={(e) => {
                      if (e?.availableQuantity === 0) {
                        toast.info("Can't buy product, not available");
                        return;
                      }
                      setNewSellModal(true);
                      setSelectedItem(e);
                    }}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ServiceTable
                    loader={loader}
                    adminId={uid}
                    services={services}
                    addHandler={() => onAddHandler("service")}
                    editHandler={onUpdateHandler}
                    viewhandler={onViewHandler}
                    deleteHandler={(id, name) => {
                      setSelectedItem({ id, name, persona: "service" });
                      setDeleteModal(true);
                    }}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ConsultationTable
                    loader={loader}
                    services={consultation}
                    addHandler={() => onAddHandler("consultation")}
                    editHandler={onUpdateHandler}
                    viewHandler={onViewHandler}
                    deleteHandler={(id, name) => {
                      setSelectedItem({ id, name, persona: "consultation" });
                      setDeleteModal(true);
                    }}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
        </Tabs>
      </SuiBox>

      <AddProduct
        open={newProductModal}
        setOpenModal={setNewProductModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        message={message}
        uid={uid}
        handleCloseModal={() => {
          setNewProductModal(false);
        }}
        mode={mode}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        editHandler={onUpdateHandler}
        viewHandler={onViewHandler}
      />

      <AddService
        open={newServiceModal}
        setOpenModal={setNewServiceModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        uid={uid}
        handleCloseModal={() => {
          setNewServiceModal(false);
        }}
        mode={mode}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        editHandler={onUpdateHandler}
        viewHandler={onViewHandler}
      />

      <AddConsultation
        open={newConsultationModal}
        setOpenModal={setNewConsultationModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        uid={uid}
        handleCloseModal={() => {
          setNewConsultationModal(false);
        }}
        mode={mode}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        editHandler={onUpdateHandler}
        viewHandler={onViewHandler}
      />

      <SellModal
        open={newSellModal}
        getClientLists={getClientLists}
        setOpenModal={setNewSellModal}
        isSuccess={isSuccess_}
        isError={isError_}
        isFetching={isFetching_}
        loader={loader}
        setLoader={setLoader}
        message={message_}
        uid={uid}
        clients={clients}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setNewSellModal(false);
        }}
        selectedItem={selectedItem}
        updateSellCount={updateSellCount}
      />

      {deleteModal && (
        <DeleteModal
          isFetching={isFetching}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </DashboardLayout>
  );
}

export default Offering;
