import SuiTypography from "components/SuiTypography";
import SuiBox from 'components/SuiBox'
import { CustomWidthTooltip } from "containers/Tooltip"
import TagItem from "containers/TagItem"
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";


export const recipeRow= (recipeArray, editHandler, deleteHandler, viewHandler) => {
    const rows = recipeArray.map((item, index)=> {
        return {

                "S.no": 
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium" 
                        style={{paddingLeft: 14}}>
                        {index+1}
                    </SuiTypography>),

                "Date/Time (Added)": 
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {globaleDateTimeFormatter(item?.createdAt)}
                    </SuiTypography>),

                "Recipe Name":  
                (
                    <CustomWidthTooltip title={item?.name??""} placement="bottom">
                    <SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.name && item?.name?.length>20?
                            `${item?.name?.slice(0, 20)}...`: 
                            item?.name}
                    </SuiTypography>
                </CustomWidthTooltip>
                ),

                "Recipe Description":  
                    (
                        <CustomWidthTooltip title={item?.description??""} placement="bottom">
                            <SuiTypography 
                                variant="button" 
                                fontWeight="medium">
                                    {item?.description?.length>40?
                                        `${item?.description?.slice(0, 40)}...`: 
                                        item?.description} 

                            </SuiTypography>
                        </CustomWidthTooltip>
                    ),

                "How To Prepare":  
                    (
                        <CustomWidthTooltip title={item?.preparation??""} placement="bottom">
                            <SuiTypography 
                                variant="button" 
                                fontWeight="medium">
                                    {item?.preparation?.length>40?
                                        `${item?.preparation?.slice(0, 40)}...`: 
                                        item?.preparation} 
                            </SuiTypography>
                        </CustomWidthTooltip>
                    ),

                "Food Type":  
                    (<SuiBox style={{ display: 'flex', width: '300px', flexWrap: 'wrap'}}>
                        {item?.foodType?.map((val, key)=>(
                            <TagItem key={key} val={val}/>
                        ))} 
                    </SuiBox>),
                
                "Ingredients":  
                    (<SuiBox style={{ display: 'flex', width: '300px', flexWrap: 'wrap'}}>
                        {Array.isArray(item?.ingredients) && item?.ingredients?.map((val, key)=>(
                            <TagItem key={key} val={val}/>
                        ))} 
                    </SuiBox>),

                "Calories":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.calories && !isNaN(item?.calories) ? item?.calories: "N/A"}                  
                    </SuiTypography>),

                "Weight (in gms)":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.weight && !isNaN(item?.weight)? item?.weight:'N/A'}                  
                    </SuiTypography>),

                "Nutrition Values":  
                    (<SuiBox style={{ display: 'flex', width: '300px', flexWrap: 'wrap'}}>
                        {Array.isArray(item?.nutritions) && item?.nutritions.length>0 ? item?.nutritions?.map((val, key)=>(
                            <TagItem key={key} val={val}/>
                        )): "N/A" } 
                    </SuiBox>),

                "How To Take":  
                    (
                        <CustomWidthTooltip title={item?.howToTake??""} placement="bottom">
                            <SuiTypography 
                                variant="button" 
                                fontWeight="medium">
                                    {item?.howToTake?.length>40?
                                        `${item?.howToTake?.slice(0, 40)}...`: 
                                        item?.howToTake} 
                            </SuiTypography>
                        </CustomWidthTooltip>
                    ),

                "Category":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.foodCategory}
                    </SuiTypography>),

                "Qty.":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.qty ??'N/A'}
                    </SuiTypography>),

                "Unit":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.unit ?? 'N/A'}
                    </SuiTypography>),
                
                "Action": 
                (
                    <TableAction
                    style={{ padding: "3px", marginLeft: "5px" }}
                    updateHandler={() => editHandler(item)}
                    viewHandler={() => viewHandler(item)}
                    deleteHandler={() => deleteHandler(item.id, item.name)}
                  />
                ),

            }
        })
    return rows
}