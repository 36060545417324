import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the Modal
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { EditDailyIntake, AddDailyIntake } from "services/clientdetails";
import RecallChart from "./chartBox";
import Createable from "containers/Select";
import SuiInput from "components/SuiInput";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { submitDataHandler } from "../utils";
import CustomButton from "containers/CustomButton";
import { updateRow } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import { daySegments } from "constant";
import { frequencyOfEatingOptions } from "constant";
import { dietTypeOptions } from "constant";

function DailyIntakeModal({
  open,
  id,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      timestamp: new Date(),
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);

  const defaultErrorv1 = {
    time: false,
    clientAte: false,
  };

  const [chartError, setChartError] = useState({
    earlyMorning: defaultErrorv1,
    breakfast: defaultErrorv1,
    midMorning: defaultErrorv1,
    brunch: defaultErrorv1,
    midMeal: defaultErrorv1,
    lunch: defaultErrorv1,
    postLunch: defaultErrorv1,
    evening: defaultErrorv1,
    beforeDinner: defaultErrorv1,
    dinner: defaultErrorv1,
    postDinner: defaultErrorv1,
    lateNight: defaultErrorv1,
  });

  //for submitting data
  const [loader, setLoader] = useState(false);
  const submitHandler = async (e) => {
    let keys = Object.keys(formdata);

    if (!formdata.dietType && !formdata.dietType?.value) {
      toast.error(`Please select Follows special diet field`);

      setErrorMessage({
        ...errorMessage,
        dietType: true,
      });
      return;
    }

    if (!formdata.frequencyOfEating && !formdata.frequencyOfEating?.value) {
      toast.error(`Please select Frequency Of Eating`);

      setErrorMessage({
        ...errorMessage,
        frequencyOfEating: true,
      });
      return;
    }

    if (!formdata.waterIntake) {
      toast.error(`Please fill Water Intake field`);

      setErrorMessage({
        ...errorMessage,
        waterIntake: true,
      });
      return;
    }

    if (!formdata.timestamp) {
      toast.error("Please fill Date/Time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    for (let i = 0; i < keys.length; i++) {
      if (
        formdata[keys[i]] &&
        keys[i] !== "dailyIntakeId" &&
        keys[i] !== "createdAt"
      ) {
        let recipe = formdata[keys[i]];
        let recipeId = keys[i];

        if (recipe.time || recipe.clientAte) {
          if (!recipe.time) {
            toast.error(`Please enter a Time for ${recipeId}`);
            setChartError({
              ...chartError,
              [recipeId]: {
                time: true,
              },
            });
            return;
          }

          if (!recipe.clientAte) {
            toast.error(`Please enter a What client ate field for ${recipeId}`);
            setChartError({
              ...chartError,
              [recipeId]: {
                clientAte: true,
              },
            });
            return;
          }
        }
      }
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () => await EditDailyIntake({ formdata, id }),
        addSubmitHandler: async () => await AddDailyIntake({ formdata, id }),
        setSelectedClient,
        key: "dailyIntake",
        handleCloseModal,
        setSelectedItem,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault: {
          dietType: false,
          waterIntake: false,
          frequencyOfEating: false,
          timestamp: false,
        },
      });
    } else if (persona === "client") {
      const docId = uuidv4();

      const spreadData = {
        ...formdata,
        frequencyOfEating: formdata.frequencyOfEating?.value,
        dietType: formdata.dietType?.value,
      };

      setMainData((data) => {
        if (selectedItem) {
          const updatedData = updateRow(selectedItem.id, spreadData, data);
          data = updatedData;
        } else {
          data = [{ id: docId, ...spreadData, createdAt: new Date() }, ...data];
        }
        return data;
      });
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (selectedItem !== null && open) {
      setFormdata({
        ...selectedItem,
        frequencyOfEating: {
          value: selectedItem.frequencyOfEating,
          label: selectedItem.frequencyOfEating,
        },
        dietType: {
          value: selectedItem.dietType,
          label: selectedItem.dietType,
        },
        timestamp: selectedItem?.timestamp?.seconds
          ? timestampConversion(selectedItem?.timestamp)._d
          : selectedItem?.timestamp,
      });
    }
  }, [selectedItem, open]);

  const [errorMessage, setErrorMessage] = useState({
    dietType: false,
    waterIntake: false,
    frequencyOfEating: false,
    timestamp: false,
  });

  return (
    <SuiBox>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={6} md={3}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Follows special diet?
            </SuiTypography>
            <Createable
              options={dietTypeOptions ?? []}
              error={errorMessage?.dietType}
              disabled={mode === "view"}
              value={formdata?.dietType ?? ""}
              style={{ borderRadius: "0.5rem", fontSize: 14 }}
              inputStyle={{
                width: 190,
                height: "1.800rem",
                borderRadius: "0.5rem",
              }}
              placeholder="Choose diet type"
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  dietType: e,
                });

                setErrorMessage({
                  ...errorMessage,
                  dietType: false,
                });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={6} md={3}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Frequency Of Eating
            </SuiTypography>
            <Createable
              options={frequencyOfEatingOptions ?? []}
              disabled={mode === "view"}
              error={errorMessage?.frequencyOfEating}
              value={formdata.frequencyOfEating ?? ""}
              style={{ borderRadius: "0.5rem", fontSize: 14 }}
              inputStyle={{
                width: 190,
                height: "1.800rem",
                borderRadius: "0.5rem",
              }}
              placeholder="Frequency Of Eating"
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  frequencyOfEating: e,
                });

                setErrorMessage({
                  ...errorMessage,
                  weekPlan: false,
                });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={6} md={3}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Water Intake (lts)
            </SuiTypography>
            <SuiInput
              disabled={mode === "view"}
              type="text"
              name="waterIntake"
              readOnly={mode === "view"}
              error={errorMessage?.waterIntake}
              value={formdata.waterIntake ?? ""}
              onChange={(e) => {
                let numberRegex = /^[0-9][0-9]{0,5}?(\.[0-9]{1,2})?$/;
                let val = e.target.value;
                let match_ = val.match(numberRegex);
                if (!match_ && val) {
                  return false;
                }
                setFormdata({
                  ...formdata,
                  [e.target.name]: e.target.value,
                });
                setErrorMessage({
                  ...errorMessage,
                  [e.target.name]: false,
                });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Date/Time
            </SuiTypography>
            <SuiInput
              disabled={mode === "view"}
              type="datetime-local"
              name="timestamp"
              placeholder=""
              readOnly={mode === "view"}
              error={errorMessage?.timestamp}
              style={{ paddingRight: "1px" }}
              value={
                formdata.timestamp
                  ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                  : moment().format("YYYY-MM-DDTkk:mm")
              }
              onChange={(e) => {
                setErrorMessage({
                  ...errorMessage,
                  [e.target.name]: false,
                });
                setFormdata({
                  ...formdata,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </SuiBox>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {daySegments.map((item, index) => (
          <Grid item xs={12} lg={12} key={index}>
            <RecallChart
              title={item.title}
              chartId={item.id}
              mode={mode}
              chartError={chartError}
              setChartError={setChartError}
              setFormdata={setFormdata}
              formdata={formdata}
              open={open}
            />
          </Grid>
        ))}

        {mode !== "view" && (
          <Grid item xs={12} lg={4}>
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={selectedItem ? "Edit Daily Intake " : "Add Daily Intake"}
              loader={loader}
            />
          </Grid>
        )}
      </Grid>
    </SuiBox>
  );
}

export default DailyIntakeModal;
