import React, { memo } from 'react'

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DrawerHeader, drawerWidth } from '../layout';

// @mui material components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import { useNavigate } from 'react-router-dom';
import SuiBox from 'components/SuiBox';


function Sidebar({ theme, open, handleDrawerClose, routes, setComponentIndex, componentIndex }) {

    const navigate = useNavigate();

    const updateQueryParam = (paramValue) => {
        const updatedParams = {
            'component': paramValue,
        };

        navigate({ search: new URLSearchParams(updatedParams).toString() });
    };

    const renderRoutes = routes.map(({ name,
        key, }, index) => {

        return (
            <SuiBox
                key={key}
                onClick={() => {
                    setComponentIndex(index)
                    updateQueryParam(index)
                    handleDrawerClose()
                }}>
                <SuiTypography
                    key={key}
                    style={{
                        color: componentIndex === index ? '#17C1E8' : '#344767',
                        cursor: 'pointer',
                        padding: '10px 0px 10px 20px'
                    }}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    fontSize={14}
                    textTransform="capitalize"
                    opacity={0.6}
                    ml={1}
                >
                    {name}
                </SuiTypography>
            </SuiBox>
        )
    });


    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                borderRadius: 0,
                minHeight: '100vh',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    minHeight: '100vh',
                    borderRadius: 0,
                    margin: 0
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>{renderRoutes}</List>
        </Drawer>
    )
}

export default memo(Sidebar)