import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import React from "react";
import { Button, Icon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function PersonalDetails({ client, onHandler, stepVal }) {
  return (
    <SuiBox my={2}>
      <SuiBox
        style={{
          border: "1px solid #D2D6DA",
          borderRadius: 5,
          position: "relative",
        }}
        p={1.5}
      >
        {stepVal && stepVal[1] === 0 && (
          <SuiTypography
            component={Button}
            onClick={onHandler}
            variant="body2"
            color="secondary"
            style={{ position: "absolute", right: 0, top: 10 }}
          >
            <Tooltip title={"update personal info"} placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </SuiTypography>
        )}

        <SuiTypography variant="h5">Personal details</SuiTypography>

        <SuiBox mt={3}>
          <SuiBox display="flex" justifyContent="space-between">
            <SuiTypography variant="subtitle3">Address</SuiTypography>
            <SuiTypography variant="body3">
              {client?.address ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Gender</SuiTypography>
            <SuiTypography variant="body3">
              {client?.gender ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">DOB</SuiTypography>
            <SuiTypography variant="body3">
              {client?.dob ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Age (Yrs)</SuiTypography>
            <SuiTypography variant="body3">
              {client?.age ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Food Preference</SuiTypography>
            <SuiTypography variant="body3">
              {client?.preference ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Occupation</SuiTypography>
            <SuiTypography variant="body3">
              {client?.occupation ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Anniversary</SuiTypography>
            <SuiTypography variant="body3">
              {!client?.anniversary || client?.anniversary === ""
                ? "N/A"
                : client?.anniversary}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Heard From</SuiTypography>
            <SuiTypography variant="body3">
              {client?.leadFrom ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Family Name</SuiTypography>
            <SuiTypography variant="body3">
              {client?.familyName ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Referred By</SuiTypography>
            <SuiTypography variant="body3">
              {client?.referredBy ?? "N/A"}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {(!stepVal || stepVal[1] === 1) && (
          <SuiTypography
            variant="caption"
            style={{ display: "block" }}
            mt={1.5}
          >
            Please contact to your fellow nutrition/dietitians to change your
            personal details
          </SuiTypography>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default PersonalDetails;
