import React, { useState, useEffect, useCallback } from "react";
import { biochemicalHealthRecordsRows } from "containers/clientDetails/sub-components/common/biochemicalHealthRecordsRows";
import { biochemicalTableColumns } from "utils/tablesHeaderContent";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard";
import BiochemicalModal from "containers/clientDetails/sub-components/modal/biochemical";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { headerDesc } from "utils/helper";
import moment from "moment";
import { timestampConversion } from "utils/common";

function BiochemicalHealthRecords({
  id,
  biochemicals,
  title,
  setSelectedClient,
  deleteHandler,
  isSuccess,
  isError,
  isFetching,
  loader,
  setLoader,
  message,
}) {
  const [rows, setRows] = useState([]);
  const [newBiochemicalModal, setNewBiochemicalModal] = useState(false);
  const columns = [...biochemicalTableColumns];
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  const addHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewBiochemicalModal(true);
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("edit");
    setNewBiochemicalModal(true);
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("view");
    setNewBiochemicalModal(true);
  }, []);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let biochemicalArray = biochemicals.slice();

    biochemicalArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.timestamp);
      const b_timestamp = convertTimestamp(b?.timestamp);
      return b_timestamp - a_timestamp;
    });

    setRows(
      biochemicalHealthRecordsRows(
        biochemicalArray,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [biochemicals, editHandler, viewHandler]);

  return (
    <>
      <AdminClientInfoCard
        title={title}
        onClickBtnHandler={addHandler}
        columns={columns}
        rows={rows}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
      />

      <DefaultModal
        open={newBiochemicalModal}
        {...headerDesc(mode, "Dietary, Lifestyle & Biochemical", selectedItem)}
        handleCloseModal={() => {
          setNewBiochemicalModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <BiochemicalModal
          id={id}
          persona="admin"
          mode={mode}
          open={newBiochemicalModal}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setSelectedClient={setSelectedClient}
          handleCloseModal={() => {
            setNewBiochemicalModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default BiochemicalHealthRecords;
