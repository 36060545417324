import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";

export const fetchFaqs = createAsyncThunk(
  "faq/fetchFaqs",
  async (_, thunkAPI) => {
    try {
      const data = [];
      const q = query(collection(firestore, "faq"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      return data;
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faqData: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },
  },

  extraReducers: {
    [fetchFaqs.fulfilled]: (state, { payload }) => {
      state.faqData = payload ?? [];
      state.isSuccess = true;
      state.isFetching = false;
    },
    [fetchFaqs.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchFaqs.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const { clearState } = faqSlice.actions;

export const faqSelector = (state) => state.faq;
