import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import { profileGridItem } from "containers/clientDetails/admin/profile";
import React from "react";
import { updateFirstLetter } from "utils/helper";

const EmployeeInfo = ({ employeeDetails }) => {
  const { name, phoneNumber, current_role, accessModules, email, timing } =
    employeeDetails;
  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox>
          <SuiBox>
            {profileGridItem("Name", updateFirstLetter(name))}
            {profileGridItem("Ph. No", updateFirstLetter(phoneNumber))}
            {profileGridItem("Email", updateFirstLetter(email))}
            {profileGridItem(
              "Job Role",
              current_role === "part" ? "Part Time" : "Full Time"
            )}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

export default EmployeeInfo;

const styles = {
  display: {
    display: "flex",
  },

  main: {
    display: "flex",
    justifyContent: "space-between",
  },

  container: {
    display: "flex",
    alignItems: "center",
  },
};
