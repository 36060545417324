import React, { useState, useEffect } from "react";
import { physicalActivityTrackerRows } from "containers/clientDetails/sub-components/common/physicalActivityTrackerRows";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard";
import { physicalActivityTableColumns } from "utils/tablesHeaderContent";
import PhysicalModal from "containers/clientDetails/sub-components/modal/physical";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { useCallback } from "react";
import { headerDesc } from "utils/helper";
import { sortingWithTimestamp } from "utils/common";
import moment from "moment";
import { timestampConversion } from "utils/common";

function PhysicalActivityTracker({
  id,
  physicalActs,
  title,
  setSelectedClient,
  deleteHandler,
}) {
  const [rows, setRows] = useState([]);
  const columns = [...physicalActivityTableColumns];
  const [newPhysicalModal, setNewPhysicalModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  const addHandler = useCallback(() => {
    setNewPhysicalModal(true);
    setSelectedItem(null);
    setMode("create");
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("edit");
    setNewPhysicalModal(true);
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("view");
    setNewPhysicalModal(true);
  }, []);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let physicalActArray = physicalActs.slice();

    physicalActArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.timestamp);
      const b_timestamp = convertTimestamp(b?.timestamp);
      return b_timestamp - a_timestamp;
    });

    setRows(
      physicalActivityTrackerRows(
        physicalActArray,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [physicalActs, editHandler, viewHandler]);

  useEffect(() => {});

  return (
    <>
      <AdminClientInfoCard
        title={title}
        onClickBtnHandler={addHandler}
        columns={columns}
        rows={rows}
      />

      <DefaultModal
        open={newPhysicalModal}
        {...headerDesc(mode, "Physical details", selectedItem)}
        handleCloseModal={() => {
          setNewPhysicalModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <PhysicalModal
          id={id}
          persona="admin"
          mode={mode}
          open={newPhysicalModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewPhysicalModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default PhysicalActivityTracker;
