import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Custom styles for the Modal
import { toast } from "react-toastify";
import Createable from "containers/Select";
import Grid from "@mui/material/Grid";
import { timestampConversion } from "utils/common";
import moment from "moment";
import { submitDataHandler } from "../utils";
import { EditPhysical } from "services/clientdetails";
import { AddPhysical } from "services/clientdetails";
import CustomButton from "containers/CustomButton";
import { v4 as uuidv4 } from "uuid";
import { updateRow } from "utils/common";

function PhysicalModal({
  open,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      drinkAlcohol: null,
      chewTobacco: null,
      smokeCigarettes: null,
      lifestyle: null,
      physicalActivities: null,
      awakeLateNight: null,
      snackAtLateNight: null,
      gymRoutine: null,
      hourOfSleep: false,
      dailyActivity: null,
      weightLossType: null,
      difficultyInWeightLoss: null,
      timestamp: new Date(),
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      drinkAlcohol: false,
      chewTobacco: false,
      smokeCigarettes: false,
      lifestyle: false,
      physicalActivities: false,
      awakeLateNight: false,
      snackAtLateNight: false,
      gymRoutine: false,
      hourOfSleep: false,
      timestamp: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorDefault);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        drinkAlcohol: {
          label: selectedItem.drinkAlcohol,
          value: selectedItem.drinkAlcohol,
        },
        chewTobacco: {
          label: selectedItem.chewTobacco,
          value: selectedItem.chewTobacco,
        },
        lifestyle: {
          label: selectedItem.lifestyle,
          value: selectedItem.lifestyle,
        },
        smokeCigarettes: {
          label: selectedItem.smokeCigarettes,
          value: selectedItem.smokeCigarettes,
        },
        weightLossType: {
          label: selectedItem.weightLossType,
          value: selectedItem.weightLossType,
        },
        difficultyInWeightLoss: {
          label: selectedItem.difficultyInWeightLoss,
          value: selectedItem.difficultyInWeightLoss,
        },
        dailyActivity: {
          label: selectedItem.dailyActivity,
          value: selectedItem.dailyActivity,
        },

        timestamp: selectedItem?.timestamp?.seconds
          ? timestampConversion(selectedItem?.timestamp)._d
          : selectedItem?.timestamp,
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.timestamp) {
      toast.error("Please fill date/time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () => await EditPhysical({ formdata, id }),
        addSubmitHandler: async () => await AddPhysical({ formdata, id }),
        setSelectedClient,
        key: "physical",
        handleCloseModal,
        setSelectedItem,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault,
      });
    } else if (persona === "client") {
      const docId = uuidv4();

      const spreadData = {
        ...formdata,
        drinkAlcohol: formdata?.drinkAlcohol?.value ?? "N/A",
        chewTobacco: formdata?.chewTobacco?.value ?? "N/A",
        lifestyle: formdata?.lifestyle?.value ?? "N/A",
        smokeCigarettes: formdata?.smokeCigarettes?.value ?? "N/A",
        weightLossType: formdata?.weightLossType?.value ?? "N/A",
        difficultyInWeightLoss:
          formdata?.difficultyInWeightLoss?.value ?? "N/A",
        dailyActivity: formdata?.dailyActivity?.value ?? "N/A",
      };

      setMainData((data) => {
        if (selectedItem) {
          const updatedData = updateRow(selectedItem.id, spreadData, data);
          data = updatedData;
        } else {
          data = [{ id: docId, ...spreadData, createdAt: new Date() }, ...data];
        }
        return data;
      });
      handleCloseModal();
    }
  };

  const [lifestyleOptions] = useState([
    { value: "Sedentary", key: 1, label: "Sedentary" },
    { value: "Moderate", key: 2, label: "Moderate" },
    { value: "Heavy", key: 3, label: "Heavy" },
    { value: "Overeating", key: 4, label: "Overeating" },
    { value: "Wrong kind of eating", key: 4, label: "Wrong kind of eating" },
    { value: "Heredity", key: 5, label: "Heredity" },
    { value: "Surgery", key: 6, label: "Surgery" },
    { value: "Drugs", key: 7, label: "Drugs" },
  ]);
  const [smokeCigarettesOptions] = useState([
    { value: "Yes", label: "Yes", key: 1 },
    { value: "No", label: "No", key: 2 },
  ]);
  const [weightLossTypeOptions] = useState([
    { value: "Proportional", label: "Proportional", key: 1 },
    { value: "Disproportional", label: "Disproportional", key: 2 },
  ]);
  const [difficultWeightLossOptions] = useState([
    { value: "Abdomen", label: "Abdomen", key: 1 },
    { value: "Hips", label: "Hips", key: 2 },
    { value: "Thigs", label: "Thigs", key: 3 },
    { value: "Legs", label: "Legs", key: 4 },
    { value: "Waist", label: "Waist", key: 6 },
    { value: "Bust", label: "Bust", key: 7 },
    { value: "Arms", label: "Arms", key: 8 },
    { value: "Other", label: "Other", key: 9 },
  ]);
  const [chewTobaccoOptions] = useState([
    { value: "Yes", label: "Yes", key: 1 },
    { value: "No", label: "No", key: 2 },
  ]);
  const [drinkAlcoholOptions] = useState([
    { value: "Yes", label: "Yes", key: 1 },
    { value: "No", label: "No", key: 2 },
  ]);
  const [dailyActivitiesTypeOptions] = useState([
    { value: "Normal Walk", label: "Normal Walk", key: 1 },
    { value: "Brisk Walk", label: "Brisk Walk", key: 2 },
    { value: "Yoga", label: "Yoga", key: 3 },
    { value: "Excercise", label: "Excercise", key: 4 },
    { value: "Swimming", label: "Swimming", key: 5 },
    { value: "Outdoor Activity", label: "Outdoor Activity", key: 6 },
    { value: "Any Other", label: "Any Other", key: 7 },
    { value: "None Of These", label: "None Of These", key: 8 },
  ]);

  return (
    <SuiBox pb={3} px={3}>
      <SuiBox pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Daily Activities Type
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={dailyActivitiesTypeOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          dailyActivity: e,
                        });
                      }}
                      value={formdata.dailyActivity}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Current Lifestyle
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={lifestyleOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          lifestyle: e,
                        });
                      }}
                      value={formdata.lifestyle}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Weight Loss Type
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={weightLossTypeOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          weightLossType: e,
                        });
                      }}
                      value={formdata.weightLossType}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Areas of Difficult Weight Loss
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={difficultWeightLossOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          difficultyInWeightLoss: e,
                        });
                      }}
                      value={formdata.difficultyInWeightLoss}
                    />
                  </SuiBox>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1} mt={3.3}>
                    <SuiTypography mb={1} variant="h6">
                      Smoke Cigarettes?
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={smokeCigarettesOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          smokeCigarettes: e,
                        });
                      }}
                      value={formdata.smokeCigarettes}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SuiBox lineHeight={1} mt={3.3}>
                    <SuiTypography mb={1} variant="h6">
                      Chew Tobacco?
                    </SuiTypography>
                    <Createable
                      disabled={mode === "view"}
                      options={chewTobaccoOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.850rem",
                        borderRadius: "0.5rem",
                      }}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          chewTobacco: e,
                        });
                      }}
                      value={formdata.chewTobacco}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Physical Activities
                </SuiTypography>
                <SuiInput
                  type="text"
                  disabled={mode === "view"}
                  readOnly={mode === "view"}
                  name="physicalActivities"
                  placeholder="physical activities ..."
                  rows={6}
                  multiline
                  error={errorMessage?.physicalActivities}
                  value={formdata.physicalActivities ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>

              <SuiBox lineHeight={1} mt={3.3}>
                <SuiTypography mb={1} variant="h6">
                  Drink Alcohol?
                </SuiTypography>
                <Createable
                  disabled={mode === "view"}
                  options={drinkAlcoholOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      drinkAlcohol: e,
                    });
                  }}
                  value={formdata.drinkAlcohol}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Awake in Late Night
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  readOnly={mode === "view"}
                  name="awakeLateNight"
                  placeholder="awake in late night ..."
                  rows={6}
                  multiline
                  error={errorMessage?.awakeLateNight}
                  value={formdata.awakeLateNight ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Snack Eat Late Night
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  readOnly={mode === "view"}
                  name="snackAtLateNight"
                  placeholder="snack eat late night ..."
                  rows={6}
                  multiline
                  error={errorMessage?.snackAtLateNight}
                  value={formdata.snackAtLateNight ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Gym Routine
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="gymRoutine"
                  readOnly={mode === "view"}
                  placeholder="gym routine ..."
                  rows={6}
                  multiline
                  error={errorMessage?.gymRoutine}
                  value={formdata.gymRoutine ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Hours of Sleep
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="hourOfSleep"
                  readOnly={mode === "view"}
                  placeholder=""
                  error={errorMessage?.hourOfSleep}
                  value={formdata.hourOfSleep ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,5}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Date/time
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="datetime-local"
                  name="timestamp"
                  readOnly={mode === "view"}
                  placeholder=""
                  error={errorMessage?.timestamp}
                  style={{ paddingRight: "1px" }}
                  value={
                    formdata.timestamp
                      ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                      : moment().format("YYYY-MM-DDTkk:mm")
                  }
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default PhysicalModal;
