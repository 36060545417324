import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import moment from "moment";
import AppointmentSearch from "./appointmentSearchbar";
import NoData from "containers/DataNotAvailBox";
import { appointmentRows } from "./rows.js";
import * as XLSX from "xlsx";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort";
import { globaleDateTimeFormatter } from "utils/helper";
import { globaleDateTimeFormatterAppointments } from "utils/helper";

function AppointmentTable({
  appointments,
  title,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  updateHandler,
  viewHandler,
}) {
  const [rows, setRows] = useState([]); // rows for appointment table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [appointmentArray, setAppointmentArray] = useState([]); //

  // for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    {
      name: "Date/Time (Added)",
      align: "left",
      sort: true,
      sortKey: "createdAt",
    },
    {
      name: "Name",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "clientName",
    },
    {
      name: "Ph. No.",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "phoneNumber",
    },
    {
      name: "Email",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "email",
    },
    // { name: "Customer Notes", align: "left", minWidth: '190px' },
    // { name: "Consultant Notes (Latest)", align: "left", minWidth: '230px' },
    {
      name: "Appointment Date",
      align: "left",
      minWidth: "140px",
      sort: true,
      sortKey: "appointmentDate",
    },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(appointmentArray, sortConfig);
    setRows(appointmentRows(sortedRows, viewHandler, updateHandler));
  }, [appointmentArray, viewHandler, updateHandler, sortConfig]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    fromDate: null,
    toDate: null,
  });

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = appointments.filter((item) => {
      if (filterQuery.fromDate) {
        timestamp = item["appointmentDate"];
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(item["appointmentDate"]).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item["appointmentDate"];
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });

    setAppointmentArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      toDate: null,
      fromDate: null,
    });
    setAppointmentArray(appointments);
  }, [appointments]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setAppointmentArray(appointments);
    } else {
      resetHandler();
      let filteredlead = appointments.filter((s) => {
        let name = s?.clientName;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setAppointmentArray((e) => filteredlead);
    }
  }, [searchVal, isSuccess, isFetching, appointments, resetHandler]);

  const excelDownload = () => {
    let updatedData = appointments.map((item, index) => {
      let consultantNote =
        item?.consultantNotes !== undefined && item?.consultantNotes.length
          ? item?.consultantNotes[item?.consultantNotes.length - 1]
              .consultantNote
          : "N/A";

      return [
        index + 1,
        globaleDateTimeFormatter(item.createdAt),
        item.clientName,
        item.phoneNumber,
        item.email,
        item.customerNotes,
        consultantNote,
        globaleDateTimeFormatterAppointments(item.appointmentDate),
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME (ADDED)",
        "NAME",
        "PH. NO",
        "EMAIL",
        "CUSTOMER NOTES",
        "CONSULTANT NOTES (LATEST)",
        "APPOINTMENT DATE",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "appointments.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={1}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6">{title}</SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={"List of appointments, you can follow up"}
                placement="top"
                style={{ color: "white", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiButton
              component={Button}
              onClick={addHandler}
              color="dark"
              variant="gradient"
              width={180}
              style={{
                marginRight: 25,
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add new appointment
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiTypography
          variant="button"
          fontWeight="regular"
          color={"dark"}
          sx={{ ml: 3 }}
        >
          Below are the appointments sheduled for you.
        </SuiTypography>

        <AppointmentSearch
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
        />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default AppointmentTable;
