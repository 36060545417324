import React from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput"
import Icon from "@mui/material/Icon";
import { style } from './style.js'


function Searchbar({ placeholder, searchHandler, clearHandler, searchVal, style_, type}) {
    return (
        <SuiBox ml={0.5} width={200} style={{ ...style.searchbar, ...style_, width: 230}}>
            <SuiInput 
                type={type??"text"} 
                value={searchVal}
                placeholder={placeholder}
                onChange={(e)=>{
                    searchHandler(e.target.value)
               
                }} />
            
            <SuiTypography variant="h4" color="secondary" style={style.close} onClick={clearHandler} >
                <Icon sx={{ fontWeight: "bold" }}>{searchVal === ""? 'search': 'close'}</Icon>
            </SuiTypography>
        </SuiBox>
    )
}

export default Searchbar