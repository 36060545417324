import React, { useState, useEffect, memo } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import RecipeInput from "./recipeInput";

function RecipeChart({
  title,
  recipeList,
  chartId,
  defaultHandler,
  dietChart,
  setDietPlan,
  selectedItem,
  open,
  recipeLoader,
  readOnly,
  setScrollToTop,
}) {
  //for rendering add more data list
  const [indexing, setIndexing] = useState([]);

  useEffect(() => {
    if (selectedItem !== null) {
      if (selectedItem[chartId] && selectedItem[chartId].length > 0) {
        setIndexing((e) => {
          e = selectedItem[chartId].map((_, index) => index + 1);
          return e;
        });
      } else {
        setIndexing([]);
      }
    }
  }, [selectedItem, chartId, setIndexing]);

  useEffect(() => {
    if (setScrollToTop) {
      setScrollToTop((prev) => prev + 1);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem === null) {
      setIndexing([]);
    }
  }, [selectedItem]);

  //add more recipes
  const addMoreRecipe = () => {
    setIndexing((e) => {
      const val = e.length > 0 ? e[e.length - 1] : 1;
      e = [...e, val + 1];
      return e;
    });

    setDietPlan((ee) => {
      ee[chartId] = [...ee[chartId], defaultHandler];
      return { ...ee };
    });
  };

  //for calculating total calories
  const [totalCalorie, setTotalCalorie] = useState(0);
  useEffect(() => {
    let calories = 0;
    dietChart?.map((item) => {
      calories +=
        item.calories === "" || isNaN(item.calories) || item.calories == null
          ? 0
          : parseFloat(item.calories);
      return item;
    });
    setTotalCalorie(calories);
  }, [dietChart]);

  const clearRecipe = (index) => {
    setIndexing((e) => {
      e = e.filter((item, i) => {
        if (i !== index) {
          return item;
        } else {
          return null;
        }
      });
      return e;
    });

    setDietPlan((e) => {
      e[chartId] = e[chartId].filter((item, i) => {
        if (i !== index) {
          return item;
        } else {
          return null;
        }
      });
      return { ...e };
    });
  };

  const clearAllRecipe = () => {
    setIndexing([]);

    setDietPlan((e) => {
      e[chartId] = [];
      return { ...e };
    });
  };

  useEffect(() => {
    if (!open) {
      setIndexing([]);
    }
  }, [open, setIndexing]);

  return (
    <SuiBox
      p={2}
      style={{
        border: "1px solid #D2D6DA",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
        borderRadius: 8,
      }}
    >
      <SuiBox
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <SuiTypography
          style={{ textDecorationLine: "underline" }}
          mb={1}
          variant="h6"
        >
          {title}{" "}
        </SuiTypography>
      </SuiBox>

      {indexing?.map((val, index) => (
        <RecipeInput
          key={val}
          readOnly={readOnly}
          recipeLoader={recipeLoader}
          defaultHandler={defaultHandler}
          recipeList={recipeList}
          chartId={chartId}
          selectedItem={dietChart[index]}
          setDietPlan={setDietPlan}
          index={index}
          clearRecipe={clearRecipe}
          open={open}
        />
      ))}

      {!readOnly && (
        <SuiBox
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            border: "none",
            flexWrap: "wrap",
          }}
        >
          <SuiTypography variant="overline" ml={1} mr={3}>
            Total calories: {totalCalorie?.toFixed(2)}
          </SuiTypography>
          <SuiBox mr={2} mb={1}>
            <SuiButton
              component={Button}
              color="dark"
              variant="outlined"
              onClick={addMoreRecipe}
            >
              Add recipe
            </SuiButton>
          </SuiBox>

          <SuiBox mr={2}>
            <SuiButton
              component={Button}
              color="dark"
              variant="outlined"
              onClick={clearAllRecipe}
            >
              Clear recipe
            </SuiButton>
          </SuiBox>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default memo(RecipeChart);
