import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { termsAndConditionDefault } from "constant";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore, uploadFile } from "firebaseConfig";
import moment from "moment";
import { referralCodeGenerator } from "utils/common";
import { sendWelcomeEmailToAdmin } from "firebaseConfig";
import { toast } from "react-toastify";
import { get_hsn_sac } from "firebaseConfig";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ auth, email, password }, thunkAPI) => {
    try {
      let status, data, message;
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        data = { ...res?.user };
        status = 200;

        const roleRef = doc(firestore, "profile", data.uid);
        const snapshot = await getDoc(roleRef);

        const hsnCode = await get_hsn_sac();

        const unitsRef = collection(firestore, "units");
        const unitsSnapshot = await getDocs(unitsRef);
        const unitsList = unitsSnapshot.docs.map((doc) => ({
          ...doc.data(),
        }));

        const gstTreatmentsRef = collection(firestore, "gstTreatments");
        const gstTreatmentsSnapshot = await getDocs(gstTreatmentsRef);
        const gstTreatmentsList = gstTreatmentsSnapshot.docs.map((doc) => ({
          ...doc.data(),
        }));


        let q = query(
          collection(firestore, "employees"),
          where("email", "==", data.email)
        );
        let querySnapshot = await getDocs(q);

        // Check if there is at least one document in the result set
        if (!querySnapshot.empty) {
          // Access the data of the first document using the data() method
          let firstDocumentData = querySnapshot.docs[0].data();

          data = {
            ...firstDocumentData,
            uid: firstDocumentData?.adminId,
            accessModules: firstDocumentData?.accessModules,
            isEmployee: true,
          };
        } else {
          data = {
            ...data,
            ...snapshot.data(),
            hsnCode: { ...hsnCode },
            unitList: unitsList[0]?.data,
            gstTreatmentsList: gstTreatmentsList[0]?.data,
          };
        }
      } catch (error) {
        console.log("error", error);
        message = `${email} credentials are incorrect, please retry`;
        if (error.code === "resource-exhausted") {
          message = "Server error";
        }
        status = 400;
      }

      //!DEBUG

      if (status === 200) {
        localStorage.setItem("token", data?.accessToken);
        return { ...data, email: email };
      } else {
        return thunkAPI.rejectWithValue({ ...data, message });
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async ({ formdata, payResponse, referredBy, paymentData }, thunkAPI) => {
    try {
      let data;

      if (referredBy) {
        var profile = {
          name: formdata.name,
          email: formdata.email,
          phoneNumber: formdata.phoneNumber,
          payment: [
            {
              ...payResponse,
              createdAt: new Date(),
              referralAmount: paymentData.referralAmount,
            },
          ],
          activeSubscription: {
            plan: paymentData.plan,
            startDate: new Date(),
            monthOfSubscription: paymentData.months,
            daysLeftFromLastSubscription: 0,
          },
          code: referralCodeGenerator(formdata.name),
          referredBy,
        };
      } else {
        profile = {
          name: formdata.name,
          email: formdata.email,
          phoneNumber: formdata.phoneNumber,
          payment: [{ ...payResponse, createdAt: new Date() }],
          code: referralCodeGenerator(formdata.name),
          activeSubscription: {
            plan: paymentData.plan,
            startDate: new Date(),
            monthOfSubscription: paymentData.months,
            daysLeftFromLastSubscription: 0,
          },
        };
      }

      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formdata.email,
        formdata.password
      );
      let user = userCredential.user;
      const currentDomain = window.location.origin;

      await setDoc(doc(firestore, "profile", user.uid), { ...profile });
      await sendWelcomeEmailToAdmin({
        adminName: formdata.name,
        userEmail: formdata.email,
        url: currentDomain,
      })
        .then(() => {})
        .catch((err) => console.log("Mail sent error:", err));
      return { ...data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const renewSubscription = createAsyncThunk(
  "user/renewSubscription",
  async ({ payResponse, uid, referralList, subscription }, thunkAPI) => {
    try {
      let ref = doc(firestore, "profile", uid);

      const snapshot = await getDoc(ref);
      const data = { ...snapshot.data() };

      const today = moment();
      const otherDate = moment(data.activeSubscription.startDate.toDate())
        .add(data.activeSubscription.monthOfSubscription, "months")
        .add(data.activeSubscription.daysLeftFromLastSubscription, "days");

      const isAfter = today.isAfter(otherDate, "day");

      let activeSubscription_;
      if (isAfter) {
        activeSubscription_ = {
          plan: subscription.plan,
          startDate: new Date(),
          monthOfSubscription: subscription.months,
          daysLeftFromLastSubscription: 0,
        };
        await updateDoc(ref, {
          referrals: referralList,
          payment: arrayUnion({ ...payResponse, createdAt: new Date() }),
          activeSubscription: { ...activeSubscription_ },
        });
      } else {
        const diffInDays = otherDate.diff(today, "days");
        activeSubscription_ = {
          plan: subscription.plan,
          startDate: new Date(),
          monthOfSubscription: subscription.months,
          daysLeftFromLastSubscription: diffInDays + 1,
        };
        await updateDoc(ref, {
          referrals: referralList,
          payment: arrayUnion({ ...payResponse, createdAt: new Date() }),
          activeSubscription: { ...activeSubscription_ },
        });
      }

      return {
        payment: { ...payResponse, createdAt: new Date() },
        referrals: referralList,
        activeSubscription: activeSubscription_,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const forgotPassUser = createAsyncThunk(
  "user/forgotPassUser",
  async ({ formdata }, thunkAPI) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, formdata.email);

      return {};
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (
    { formdata, uid, email, profile_, logo_, qr_, signature_ },
    thunkAPI
  ) => {
    try {
      let logo, profile, qr, signature;

      if (email !== formdata.email) {
        const auth = getAuth();
        await updateEmail(auth.currentUser, formdata.email);
      }
      if (formdata?.profile_location) {
        profile = await uploadFile(formdata.profile, "profile");
      }

      if (formdata?.logo_location) {
        logo = await uploadFile(formdata.logo, "logo");
      }

      if (formdata?.qr_location) {
        qr = await uploadFile(formdata.qr, "qr");
      }

      if (formdata?.signature_location) {
        signature = await uploadFile(formdata.signature, "signature");
      }

      const profileRef = doc(firestore, "profile", uid);

      let data = {
        name: formdata.name,
        email: formdata.email,
        phoneNumber: formdata.phoneNumber,
        address: formdata.address,
        info: formdata.info,
        fb: formdata.fb,
        instagram: formdata.instagram,
        twitter: formdata.twitter,
      };

      if (logo) {
        await updateDoc(profileRef, {
          ...data,
          logo: logo,
        });
      } else {
        await updateDoc(profileRef, {
          ...data,
        });
      }

      if (profile) {
        await updateDoc(profileRef, {
          ...data,
          profile: profile,
        });
      } else {
        await updateDoc(profileRef, {
          ...data,
        });
      }

      if (qr) {
        await updateDoc(profileRef, {
          ...data,
          qr: qr,
        });
      } else {
        await updateDoc(profileRef, {
          ...data,
        });
      }

      if (signature) {
        await updateDoc(profileRef, {
          ...data,
          signature: signature,
        });
      } else {
        await updateDoc(profileRef, {
          ...data,
        });
      }

      return {
        ...formdata,
        logo: logo ?? logo_,
        profile: profile ?? profile_,
        qr: qr ?? qr_,
        signature: signature ?? signature_,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "user/deleteImage",
  async ({ uid, image }, thunkAPI) => {
    try {
      const profileRef = doc(firestore, "profile", uid);
      await updateDoc(profileRef, {
        [image]: "",
      });

      return { image };
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const userDetails = createAsyncThunk(
  "user/userDetails",
  async ({ uid }, thunkAPI) => {
    try {
      let data;

      const roleRef = doc(firestore, "profile", uid);
      const snapshot = await getDoc(roleRef);
      data = { ...snapshot.data() };

      //!DEBUG
      return { ...data };
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getIntlPhoneNumber = createAsyncThunk(
  "user/getIntlPhoneNumber",
  async (_, thunkAPI) => {
    try {
      let data;

      const intlPhoneNumber = doc(
        firestore,
        "intlPhoneNumber",
        "intlPhoneNumber"
      );
      const snapshot = await getDoc(intlPhoneNumber);
      data = { ...snapshot.data() };

      //!DEBUG
      return { ...data };
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPasword",
  async ({ newPassword, oldPassword }, thunkAPI) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      const credential = EmailAuthProvider.credential(user.email, oldPassword);

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      return {};
    } catch (e) {
      if (e.code === "auth/wrong-password") {
        e.message = "Existing password is wrong, retry!!";
      }

      if (e.code === "auth/too-many-requests") {
        e.message = "Something went wrong, retry after some time!!";
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateRedeems = createAsyncThunk(
  "user/updateRedeems",
  async ({ referrals, uid }, thunkAPI) => {
    try {
      const roleRef = doc(firestore, "profile", uid);

      let refList = referrals.map((item, i) => {
        if (item.redeemed === false) {
          return {
            ...item,
            redeemed: true,
            redeemedDate: new Date(),
          };
        }
        return item;
      });

      await updateDoc(roleRef, {
        referrals: refList,
      });

      return { refList };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const sendMessages = createAsyncThunk(
  "user/sendMessages",
  async ({ message, phoneNumber, name, email }, thunkAPI) => {
    try {
      let status, data;

      await fetch(
        // `http://localhost:4006/v1/fitify/contactusmail`,

        process.env.REACT_APP_WARRANTY + `/v1/warranty/contactusmail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message, phoneNumber, name, email }),
        }
      )
        .then((res) => {
          status = res.status;
          return res.json();
        })
        .then((res) => {
          data = res;
        })
        .catch((err) => {
          status = 500;
          data = err;
        });

      if (status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue({ ...data });
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateTermsAndConditions = createAsyncThunk(
  "user/updateTermsAndConditions",
  async ({ newTandC, uid }, thunkAPI) => {
    try {
      const profileRef = doc(firestore, "profile", uid);
      await updateDoc(profileRef, {
        termsAndConditions: newTandC,
      });
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTermsAndConditions = createAsyncThunk(
  "user/getTermsAndConditions",
  async ({ uid }, thunkAPI) => {
    try {
      let data;

      const roleRef = doc(firestore, "profile", uid);
      const snapshot = await getDoc(roleRef);
      data = { ...snapshot.data() };
      return data?.termsAndConditions;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateDietChartSettings = createAsyncThunk(
  "user/updateDietChartSettings",
  async ({ newDietChartSettings, uid }, thunkAPI) => {
    try {
      const profileRef = doc(firestore, "profile", uid);
      await updateDoc(profileRef, {
        dietChartSettings: newDietChartSettings,
      });
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDietChartSettings = createAsyncThunk(
  "user/getDietChartSettings",
  async ({ uid }, thunkAPI) => {
    try {
      let data;

      const roleRef = doc(firestore, "profile", uid);
      const snapshot = await getDoc(roleRef);

      data = { ...snapshot.data() };
      return data?.dietChartSettings;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const saveNewDietChartTemplate = createAsyncThunk(
  "user/saveNewDietChartTemplate",
  async (
    {
      templateName,
      dietPlan,
      chartType,
      weekPlan,
      note,
      uid,
      setSaveIsClicked,
    },
    thunkAPI
  ) => {
    try {
      const profileRef = doc(firestore, "profile", uid);
      const docSnap = await getDoc(profileRef);
      if (docSnap.exists()) {
        const dataArray = docSnap.data().dietChartTemplates || [];
        const isSameNameExist = dataArray.some(
          (template) => template.templateName === templateName
        );
        if (isSameNameExist) {
          toast.error("Enter a different template name");
          setSaveIsClicked(false);
          return;
        }
        dataArray.push({
          templateName: templateName,
          dietPlan: dietPlan,
          chartType: chartType,
          weekPlan: weekPlan,
          note: note,
        });

        await updateDoc(profileRef, {
          dietChartTemplates: dataArray,
        });
        setSaveIsClicked(false);
        return dataArray;
      }
      return null;
    } catch (error) {
      setSaveIsClicked(false);
      thunkAPI.rejectWithValue(error);
    }
  }
);
export const getSavedDietChartTemplates = createAsyncThunk(
  "user/getSavedDietChartTemplates",
  async ({ uid }, thunkAPI) => {
    try {
      let data;

      const roleRef = doc(firestore, "profile", uid);
      const snapshot = await getDoc(roleRef);

      data = { ...snapshot.data() };
      return data?.dietChartTemplates;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const saveGSTNumber = createAsyncThunk(
  "user/saveGSTNumber",
  async ({ uid, gstNumber, setSaveIsClicked }, thunkAPI) => {
    try {
      const profileRef = doc(firestore, "profile", uid);
      const docSnap = await getDoc(profileRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        await updateDoc(profileRef, {
          ...data,
          gstNumber: gstNumber,
        });

        setSaveIsClicked(false);
        return { ...data, gstNumber };
      }
    } catch (error) {
      setSaveIsClicked(false);
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    auth: false, // authentication
    uid: null,
    token: null,

    name: null,
    email: "",
    phoneNumber: null,
    address: null,
    info: null,
    fb: null,
    instagram: null,
    twitter: null,

    profile: null,
    logo: null,
    qr: null,
    signature: null,
    code: null,
    referredBy: null,
    referrals: null,
    activeSubscription: null,
    payment: null,
    termsAndConditions: termsAndConditionDefault,
    dietChartSettings: null,
    dietChartTemplates: null,

    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
    accessModules: null,
    gstNumber: null,
    unitList: null,
    gstTreatmentsList: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },

    logout: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.auth = false;
      state.name = null;
      state.phoneNumber = null;
      state.email = null;
      state.address = null;
      state.info = null;
      state.fb = null;
      state.instagram = null;
      state.twitter = null;
      state.activeSubscription = null;
      state.profile = null;
      state.logo = null;
      state.qr = null;
      state.signature = null;
      state.code = null;
      state.referredBy = null;
      state.payment = null;

      state.uid = null;
      state.token = null;
      state.inventory = null;
      state.referrals = null;
      state.accessModules = null;
      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.auth = true;

      state.uid = payload?.uid;
      state.name = payload?.name;
      state.email = payload?.email;
      state.phoneNumber = payload?.phoneNumber;
      state.address = payload?.address;
      state.info = payload?.info;
      state.fb = payload?.fb;
      state.instagram = payload?.instagram;
      state.twitter = payload?.twitter;
      state.activeSubscription = payload?.activeSubscription;
      state.profile = payload?.profile;
      state.logo = payload?.logo;
      state.qr = payload?.qr;
      state.signature = payload?.signature;
      state.payment = payload?.payment;
      state.code = payload.code;
      state.referredBy = payload.referredBy;
      state.token = payload?.accessToken;
      state.referrals = payload?.referrals;
      state.accessModules = payload?.accessModules;
      state.isEmployee = payload?.isEmployee;
      state.gstNumber = payload?.gstNumber;
      state.hsnCode = payload?.hsnCode;
      state.unitList = payload?.unitList;
      state.gstTreatmentsList = payload?.gstTreatmentsList;

      return state;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Credentials are invalid, retry";
    },

    [sendMessages.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Message send successfully";
      return state;
    },
    [sendMessages.pending]: (state) => {
      state.isFetching = true;
    },
    [sendMessages.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "SERVER ERROR";
    },

    [registerUser.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully registered, login now";
      return state;
    },
    [registerUser.pending]: (state) => {
      state.isFetching = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [renewSubscription.fulfilled]: (state, { payload }) => {
      state.payment = [...state.payment, payload.payment];
      state.activeSubscription = payload.activeSubscription;
      state.referrals = payload.referrals;
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Subscription renewed successfully";
      return state;
    },
    [renewSubscription.pending]: (state) => {
      state.isFetching = true;
    },
    [renewSubscription.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [updateRedeems.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.referrals = payload?.refList;
      state.message = "Redeemeded successfully ";
      return state;
    },

    [updateRedeems.pending]: (state) => {
      state.isFetching = true;
    },

    [updateRedeems.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [forgotPassUser.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message =
        "Email has been sent to your e-mail a/c, plz check for resetting password!!";
      return state;
    },

    [forgotPassUser.pending]: (state) => {
      state.isFetching = true;
    },

    [forgotPassUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [updateProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;

      state.name = payload?.name;
      state.email = payload?.email;
      state.phoneNumber = payload?.phoneNumber;
      state.address = payload.address;
      state.info = payload.info;
      state.fb = payload.fb;
      state.instagram = payload.instagram;
      state.twitter = payload.twitter;
      state.logo = payload.logo;
      state.qr = payload.qr;
      state.profile = payload.profile;
      state.qr = payload.qr;
      state.signature = payload.signature;
      state.message = "Profile updated successfully!!";
      return state;
    },

    [updateProfile.pending]: (state) => {
      state.isFetching = true;
    },

    [updateProfile.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [resetPassword.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Password updated successfully";
      return state;
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [deleteImage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state[payload.image] = "";
      state.message = `${payload.image} image deleted successfully`;
      return state;
    },

    [deleteImage.pending]: (state) => {
      state.isFetching = true;
    },

    [deleteImage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [getIntlPhoneNumber.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.intlPhoneNumber = payload;
      return state;
    },

    [getIntlPhoneNumber.pending]: (state) => {
      state.isFetching = true;
    },

    [getIntlPhoneNumber.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [userDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.name = payload?.name;
      state.email = payload?.email;
      state.phoneNumber = payload?.phoneNumber;
      state.address = payload?.address;
      state.info = payload?.info;
      state.fb = payload?.fb;
      state.qr = payload?.qr;
      state.instagram = payload?.instagram;
      state.twitter = payload?.twitter;

      state.profile = payload?.profile;
      state.logo = payload?.logo;
      state.signature = payload?.signature;
      state.payment = payload?.payment;
      state.code = payload.code;
      state.referredBy = payload.referredBy;
      state.token = payload?.accessToken;
      state.referrals = payload?.referrals;
      return state;
    },
    [userDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [userDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [updateTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "T & C changed successfully ";
      return state;
    },

    [updateTermsAndConditions.pending]: (state) => {
      state.isFetching = true;
    },

    [updateTermsAndConditions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [getTermsAndConditions.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.termsAndConditions = payload;
      return state;
    },

    [getTermsAndConditions.pending]: (state) => {
      state.isFetching = true;
    },

    [getTermsAndConditions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [updateDietChartSettings.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Diet chart settings saved successfully ";
      return state;
    },

    [updateDietChartSettings.pending]: (state) => {
      state.isFetching = true;
    },

    [updateDietChartSettings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [getDietChartSettings.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.dietChartSettings = payload;
      return state;
    },

    [getDietChartSettings.pending]: (state) => {
      state.isFetching = true;
    },

    [getDietChartSettings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [saveNewDietChartTemplate.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.isSuccess = true;
        state.isFetching = false;
        state.message = "Diet chart template saved successfully ";
        return state;
      }
    },

    [saveNewDietChartTemplate.pending]: (state) => {
      state.isFetching = true;
    },

    [saveNewDietChartTemplate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [saveGSTNumber.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.gstNumber = payload.gstNumber;
        state.isSuccess = true;
        state.isFetching = false;
        state.message = "GST number saved successfully!";
        return state;
      }
    },

    [saveGSTNumber.pending]: (state) => {
      state.isFetching = true;
    },

    [saveGSTNumber.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
    [getSavedDietChartTemplates.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.dietChartTemplates = payload;
      return state;
    },

    [getSavedDietChartTemplates.pending]: (state) => {
      state.isFetching = true;
    },

    [getSavedDietChartTemplates.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },
  },
});

export const { clearState, logout } = userSlice.actions;

export const userSelector = (state) => state.user;
