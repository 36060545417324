import { Grid } from "@mui/material";
import React from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

const ExpenseBalanceChart = ({
  data,
  loader,
  setChartYear,
  yearArray,
  chartYear,
  tag,
}) => {
  return (
    <Grid item xs={12}>
      <GradientLineChart
        yearArray={yearArray}
        setChartYear={setChartYear}
        chart={data}
        loader={loader}
        chartYear={chartYear}
        tag={tag}
      />
    </Grid>
  );
};

export default ExpenseBalanceChart;
