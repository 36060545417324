import { ERROR_MESSAGES } from "constant";
import moment from "moment";
import * as XLSX from "xlsx";

export const excelDownloadFile = (data, excelSheet, excelName) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, excelSheet);
  XLSX.writeFile(workbook, excelName);
};

export const parseTimestamp = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  const milliseconds =
    timestamp.nanoseconds / 1000000 + timestamp.seconds * 1000;
  return milliseconds;
};

export const formatTimestamp = (timestamp) => {
  timestamp = moment(timestamp);
  return timestamp ? timestamp.format("DD/MM/YYYY h:mm A") : null;
};

export const calculateTotal = (amount, tax) => {
  const taxPercentage = tax / 100;
  const totalAmount = tax ? amount * (1 + taxPercentage) : amount;
  return Math.round(totalAmount * 100) / 100;
};

export const calculateTotalForSales = (numberArray) => {
  const sum = numberArray.reduce((accumulator, currentNumber) => {
    return accumulator + parseFloat(currentNumber);
  }, 0);

  return Math.round(sum);
};

export const getErrorMessage = (errorType, ...args) => {
  if (ERROR_MESSAGES[errorType]) {
    return ERROR_MESSAGES[errorType](...args);
  }
  return "Unknown error";
};

export const generateErrorResponse = (row, errors) => {
  return `Row ${row}: ${errors.join(", ")}`;
};

export const downloadErrorsAsTxt = (errors, file) => {
  const errorText = errors
    .map(
      (error) =>
        `Row ${error.row}, Field: ${error.field}, Message: ${error.message}`
    )
    .join("\n");
  const blob = new Blob([errorText], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = file;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const globaleDateTimeFormatter = (timestamp, type) => {
  if (!timestamp) {
    return null;
  }
  let dateTime;
  if (timestamp.nanoseconds) {
    const milliseconds =
      timestamp.nanoseconds / 1000000 + timestamp.seconds * 1000;
    dateTime = moment(milliseconds);
  } else {
    dateTime = moment(timestamp);
  }
  if (type === "date") {
    return dateTime ? dateTime.format("DD/MM/YYYY") : null;
  } else if (type === "time") {
    return dateTime ? dateTime.format("h:mm a") : null;
  }
  return dateTime ? dateTime.format("DD/MM/YYYY h:mm a") : null;
};

export const globaleDateTimeFormatterAppointments = (timestamp, type) => {
  if (!timestamp) {
    return null;
  }

  let dateTime;
  if (
    timestamp.nanoseconds !== undefined &&
    timestamp.nanoseconds !== null &&
    timestamp.nanoseconds !== 0
  ) {
    const milliseconds =
      timestamp.nanoseconds / 1000000 + timestamp.seconds * 1000;
    dateTime = moment(milliseconds);
  } else {
    dateTime = moment(timestamp * 1000); // Multiplying by 1000 to convert seconds to milliseconds
  }

  if (type === "date") {
    return dateTime.isValid() ? dateTime.format("DD/MM/YYYY") : null;
  } else if (type === "time") {
    return dateTime.isValid() ? dateTime.format("h:mm a") : null;
  }
  return dateTime.isValid() ? dateTime.format("DD/MM/YYYY h:mm a") : null;
};

export const unixTimeConverter = (date) => {
  let formattedDate;

  if (date.seconds) {
    const milliseconds = date.seconds * 1000;
    formattedDate = moment(milliseconds).format("DD/MM/YYYY h:mm a");
  } else {
    formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").format(
      "DD/MM/YYYY h:mm a"
    );
  }

  return formattedDate;
};

export const updateFirstLetter = (str) => {
  if (str === undefined || str === null || str === "") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const headerDesc = (mode, header, selectedItem) => {
  const title =
    (mode === "view" ? "" : selectedItem ? "Edit " : "Add ") + header;
  const description =
    mode === "view"
      ? "You can view details below."
      : `You can ${selectedItem ? "edit" : "add"} details below.`;
  return { title, description };
};

export const getUserLink = (uid, clientId) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/customer/${uid}/${clientId}`;
};

export const sortByDate = (array) => {
  let sortedArray = [...array];
  sortedArray.sort((a, b) => {
    // Convert createdAt to Date objects
    const dateA = new Date(
      a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000
    );
    const dateB = new Date(
      b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1000000
    );
    // Compare dates
    return dateB - dateA;
  });
  return sortedArray;
};
