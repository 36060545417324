import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch } from "react-redux";
import { setSlotIndex } from "store/features/employee";

export default function ToggleButtons({
  defaultValue,
  options,
  value,
  handleChange,
  style_,
  disabled,
  size,
  slotNumber,
  index,
}) {
  const dispatch = useDispatch();
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      defaultValue={defaultValue}
      exclusive
      onChange={(option) => handleChange(option, index, slotNumber)}
      aria-label="Platform"
      style={{ ...style_ }}
      size={size}
    >
      {options?.map((item, index) => (
        <ToggleButton value={item?.value} key={index}>
          {item?.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
