import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import { validateEmail, validatePhone } from "utils/common";
import { timestampConversion } from "utils/common";
import moment from "moment";
import { AddLead, EditLead } from "store/features/leads";
import CustomButton from "containers/CustomButton";
import SCreateable from "containers/SelectCreateable";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";

function Modal({
  mode,
  open,
  services,
  clients,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  uid,
  updateLeadCountHandler,
  isFetching,
  isSuccess,
  isError,
  message,
  editHandler,
  viewHandler,
  intlPhoneNumberArray,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientName: null,
      phoneNumber: null,
      email: null,
      customerNotes: null,
      consultantNotes: null,
      consultantNotestext: null,
      source: null,
      service: null,
      status: null,
      followUpDate: null,
      countryCode: {
        label: "(+91) India",
        value: "+91",
        key: "IN",
        phoneLength: 10,
      },
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      email: false,
      phoneNumber: false,
      customerNotes: false,
      consultantNotes: false,
      consultantNotestext: false,
      source: false,
      service: false,
      status: false,
      followUpDate: false,
      referredBy: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const [serviceOptions, setserviceOptions] = useState([]); //filtering wrt services

  const [loader, setLoader] = useState(false);

  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item, index) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
        key: index,
      };
    });
    setserviceOptions(serviceArray);
  }, [services]);

  //for date format
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  //for handling selected items
  useEffect(() => {
    if (selectedItem && open) {
      let consultantArray = selectedItem?.consultantNotes?.slice()?.reverse();
      if (selectedItem?.referredBy) {
        setReferredBy({
          value: selectedItem?.referredBy,
          label: selectedItem.referredBy,
          key: 1,
        });
      }
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        source: {
          label: selectedItem.source,
          value: selectedItem.source,
          key: 1,
        },
        service: {
          label: selectedItem.service,
          value: selectedItem.service,
          key: 1,
        },
        status: {
          value: selectedItem.status,
          label: selectedItem.status,
          key: 1,
        },
        consultantNotes: consultantArray ?? [],
      });
    }
  }, [selectedItem, defaultHandler, open]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.clientName) {
      toast.error("Please enter a Client Name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    }
    //  else {
    //     toast.info("Please enter Email")
    //     setErrorMessage({
    //         ...errorMessage,
    //         'email': true
    //     })
    //     return;
    // }

    if (!formdata.source) {
      toast.error("Please select Source field");
      setErrorMessage({
        ...errorMessage,
        source: true,
      });
      return;
    }

    if (!formdata.service) {
      toast.error("Please select Service Interested In field");
      setErrorMessage({
        ...errorMessage,
        service: true,
      });
      return;
    }

    if (!formdata.status) {
      toast.error("Please select Lead Status field");
      setErrorMessage({
        ...errorMessage,
        status: true,
      });
      return;
    }
    if (
      !formdata.customerNotes ||
      formdata.customerNotes === "" ||
      formdata.customerNotes?.length === 0
    ) {
      toast.error("Please fill Customer Notes field");
      setErrorMessage({
        ...errorMessage,
        customerNotes: true,
      });
      return;
    }
    if (
      !formdata.consultantNotestext ||
      formdata.customerNotes === "" ||
      formdata.customerNotes?.length === 0
    ) {
      toast.error("Please fill Consultant Notes field");
      setErrorMessage({
        ...errorMessage,
        consultantNotestext: true,
      });
      return;
    }

    if (!formdata.followUpDate) {
      toast.error("Please select Next Follow Up Date field");
      setErrorMessage({
        ...errorMessage,
        followUpDate: true,
      });
      return;
    }

    let formdata_ = formdata;
    if (formdata.source?.value === "Referral") {
      if (!referredBy) {
        toast.error("Please fill referred by field");
        return;
      } else {
        formdata_ = { ...formdata, referredBy: referredBy.value };
      }
    }

    try {
      setLoader(true);

      if (selectedItem) {
        await dispatch(EditLead({ formdata: formdata_, uid }));
        setLoader(false);
        setReferredBy(null);
        return;
      }

      await dispatch(AddLead({ formdata: formdata_, uid }));
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setTimeout(() => {
        setReferredBy(null);
        if (selectedItem) {
          setSelectedItem(null);
        } else {
          updateLeadCountHandler();
        }
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    setSelectedItem,
    selectedItem,
    updateLeadCountHandler,
    defaultHandler,
    message,
    setLoader,
  ]);

  const [sourceOptions] = useState([
    { value: "Facebook", label: "Facebook", key: 1 },
    { value: "Instagram", label: "Instagram", key: 2 },
    { value: "Twitter", label: "Twitter", key: 3 },
    { value: "Friend", label: "Friend", key: 4 },
    { label: "Walk In", value: "Walk In", key: 5 },
    { label: "Google Ads", value: "Google Ads", key: 6 },
    { label: "BNI", value: "BNI", key: 7 },
    { label: "News Paper", value: "News Paper", key: 8 },
    { label: "FM", value: "FM", key: 9 },
    { label: "Events", value: "Events", key: 10 },
    { label: "Referral", value: "Referral", key: 11 },
    { label: "Whatsapp", value: "Whatsapp", key: 12 },
    { label: "Board", value: "Board", key: 13 },
    { label: "Pamphlet", value: "Pamphlet", key: 14 },
  ]);

  const [statusOptions] = useState([
    { value: "New", label: "New", key: 1 },
    { value: "In Discussion", label: "In Discussion", key: 2 },
    { value: "Converted", label: "Converted", key: 3 },
    { value: "Dropped Off", label: "Dropped Off", key: 4 },
  ]);

  const [referredBy, setReferredBy] = useState();
  const [referredByArray, setReferredByArray] = useState([]);

  useEffect(() => {
    let clientArray = clients.map((item, index) => {
      return {
        value: item?.name,
        label: item?.name,
        key: index,
      };
    });
    setReferredByArray(clientArray);
  }, [clients]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="65%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Lead"
          subtitle={`Lead created for ${updateFirstLetter(
            formdata?.clientName
          )}`}
        />

        <div>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                editHandler(selectedItem);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
                if (selectedItem) {
                  setSelectedItem(null);
                }
              }, 500);
            }}
          >
            close
          </Icon>
        </div>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Name
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="clientName"
                  readOnly={mode === "view"}
                  error={errorMessage?.clientName}
                  value={formdata.clientName ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Country Code
                </SuiTypography>
                <Createable
                  options={intlPhoneNumberArray}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.900rem",
                    borderRadius: "0.5rem",
                  }}
                  disabled={mode === "view"}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      countryCode: e,
                      phoneNumber: null,
                    });
                  }}
                  value={formdata.countryCode}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Phone Number
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="phoneNumber"
                  readOnly={mode === "view"}
                  error={errorMessage?.phoneNumber}
                  value={formdata.phoneNumber ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (
                      formdata.countryCode.key === "IN"
                        ? val.length > 10
                        : val.length > formdata.countryCode.phoneLength
                    ) {
                      toast.info("Invalid phone number");
                      return;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Email
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="email"
                  name="email"
                  readOnly={mode === "view"}
                  error={errorMessage?.email}
                  value={formdata.email ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Interested In
                </SuiTypography>
                <Createable
                  options={serviceOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.900rem",
                    borderRadius: "0.5rem",
                  }}
                  disabled={mode === "view"}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      service: e,
                    });
                  }}
                  value={formdata.service}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Lead Status
                </SuiTypography>
                <Createable
                  options={statusOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.900rem",
                    borderRadius: "0.5rem",
                  }}
                  disabled={mode === "view"}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      status: e,
                    });
                  }}
                  value={formdata.status}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4} height="100%">
              <SuiBox
                mt={3}
                pb={4}
                lineHeight={1}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Grid container>
                  <SuiBox width="100%" mb={2.5}>
                    <SuiTypography mb={1} variant="h6">
                      Next Follow Up Date
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="date"
                      name="followUpDate"
                      error={errorMessage?.followUpDate}
                      value={formdata.followUpDate ?? ""}
                      readOnly={mode === "view"}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>

                  <SuiBox width="100%">
                    <SuiTypography mb={1} variant="h6">
                      Source
                    </SuiTypography>
                    <Createable
                      options={sourceOptions}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.900rem",
                        borderRadius: "0.5rem",
                      }}
                      disabled={mode === "view"}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          source: e,
                        });
                      }}
                      value={formdata.source}
                    />
                  </SuiBox>
                </Grid>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Customer Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="customerNotes"
                  multiline
                  rows={6}
                  readOnly={mode === "view"}
                  error={errorMessage?.customerNotes}
                  value={formdata.customerNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            {mode !== "view" && (
              <Grid item xs={12} lg={4}>
                <SuiBox mt={3} lineHeight={1}>
                  <SuiTypography mb={1} variant="h6">
                    Consultant Notes
                  </SuiTypography>
                  <SuiInput
                    disabled={mode === "view"}
                    type="textbox"
                    name="consultantNotestext"
                    rows={6}
                    multiline
                    error={errorMessage?.consultantNotestext}
                    value={formdata.consultantNotestext ?? ""}
                    onChange={(e) => {
                      setErrorMessage({
                        ...errorMessage,
                        [e.target.name]: false,
                      });
                      setFormdata({
                        ...formdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <SuiTypography
                    mb={1}
                    variant="h6"
                    style={{
                      fontStyle: "italic",
                      color: "#67748E",
                      fontWeight: 400,
                      marginTop: 3,
                      textAlign: "right",
                    }}
                  >
                    {" "}
                    {new Date().toLocaleDateString("en-US", options)}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}

            <Grid item xs={12} lg={4}>
              {formdata.source && formdata.source?.value === "Referral" && (
                <Grid item xs={12}>
                  <SuiBox lineHeight={1} mt={2}>
                    <SuiTypography mb={1} variant="h6">
                      Referred by
                    </SuiTypography>
                    <SCreateable
                      options={referredByArray ?? []}
                      error={errorMessage?.referredBy}
                      value={referredBy}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.800rem",
                        borderRadius: "0.5rem",
                      }}
                      placeholder="Choose client"
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          clientName: false,
                        });
                        setReferredBy(e);
                      }}
                    />
                  </SuiBox>
                </Grid>
              )}
            </Grid>
          </Grid>
        </SuiBox>

        <Divider />
        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={isFetching || !open}
                title={selectedItem ? "Update Lead" : "Add Lead"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}

        {formdata?.consultantNotes?.length > 0 && (
          <SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiBox mt={3}>
                  <SuiTypography mb={2} variant="h6">
                    Consultant Notes (History)
                  </SuiTypography>

                  {formdata?.consultantNotes?.map((item, index) => (
                    <SuiBox
                      key={index}
                      style={{
                        background: "#49528A",
                        whiteSpace: "normal",
                        borderRadius: 10,
                        padding: 15,
                      }}
                      mb={3}
                    >
                      <SuiTypography
                        mb={1}
                        variant="body2"
                        color="white"
                        style={{ fontStyle: "italic" }}
                      >
                        {item?.date?.seconds
                          ? moment(timestampConversion(item?.date))
                              .local()
                              .format("MMMM Do YYYY")
                          : moment(item?.date).local().format("MMMM Do YYYY")}
                      </SuiTypography>

                      <SuiTypography mb={1} variant="body2" color="white">
                        {item?.consultantNote}
                      </SuiTypography>
                    </SuiBox>
                  ))}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
