import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import moment from "moment";
import LeadSearch from "./leadSearchbar";
import NoData from "containers/DataNotAvailBox";
import { leadRows_ } from "./leadRows.js";
import * as XLSX from "xlsx";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort";
import { globaleDateTimeFormatter } from "utils/helper";

function LeadTable({
  leads,
  services,
  title,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  updateHandler,
  viewHandler,
}) {
  const [serviceOptions, setserviceOptions] = useState([]); //filtering wrt services

  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
      };
    });
    setserviceOptions(serviceArray);
  }, [services]);

  const [rows, setRows] = useState([]); // rows for lead table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [leadArray, setLeadArray] = useState([]); //

  // for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  const columns = [
    { name: "S.no", align: "left", width: "30px" },

    {
      name: "Date/Time (Added)",
      align: "left",
      sort: true,
      width: "230px",
      sortKey: "createdAt",
    },
    {
      name: "Name",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "clientName",
    },
    {
      name: "Ph. No.",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "phoneNumber",
    },
    {
      name: "Email",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "email",
    },
    // { name: "Customer Notes", align: "left", minWidth: "190px" },
    // { name: "Consultant Notes (Latest)", align: "left", minWidth: "230px" },
    { name: "Status", align: "left", minWidth: "90px" },

    // { name: "Service Interested In", align: "left", minWidth: "190px" },
    { name: "Next follow up date", align: "left", minWidth: "150px" },
    // { name: "Source", align: "left", minWidth: "150px" },
    // { name: "Referred by", align: "left", minWidth: "150px" },

    { name: "Action", align: "left", minWidth: "90px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(leadArray, sortConfig);

    setRows(leadRows_(sortedRows, viewHandler, updateHandler));
  }, [leadArray, viewHandler, updateHandler, sortConfig]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    fromDate: null,
    toDate: null,
    lead: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;

    let filterData = leads.filter((item) => {
      if (filterQuery.status) {
        if (filterQuery.status === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.status !== item.status) {
          return false;
        }
      }

      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, service: null });
        } else if (filterQuery.service !== item["service"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item["createdAt"];
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item["createdAt"];
        timestamp =
          timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
        timestamp = moment.utc(timestamp);

        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setLeadArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      toDate: null,
      fromDate: null,
      status: null,
      service: null,
    });
    setLeadArray(leads);
  }, [leads]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setLeadArray(leads);
    } else {
      resetHandler();
      let filteredlead = leads.filter((s) => {
        let name = s?.clientName;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setLeadArray((e) => filteredlead);
    }
  }, [searchVal, isSuccess, isFetching, leads, resetHandler]);
  const excelDownload = () => {
    let updatedData = leads.map((item, index) => {
      let consultantNote =
        item?.consultantNotes !== undefined && item?.consultantNotes.length
          ? item?.consultantNotes[item?.consultantNotes.length - 1]
              .consultantNote
          : "N/A";

      return [
        index + 1,
        globaleDateTimeFormatter(item.createdAt),
        item.clientName,
        item.phoneNumber,
        item.email,
        item.customerNotes,
        consultantNote,
        item.status,
        item.service,
        item.followUpDate,
        item.source,
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME (ADDED)",
        "NAME",
        "PH. NO",
        "EMAIL",
        "CUSTOMER NOTES",
        "CONSULTANT NOTES (LATEST)",
        "STATUS",
        "SERVICE INTERESTED IN",
        "NEXT FOLLOW UP DATE",
        "SOURCE",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Leads.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6">{title}</SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={"List of leads, you can follow up"}
                placement="top"
                style={{ height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiButton
              component={Button}
              onClick={addHandler}
              width={180}
              color="dark"
              style={{
                marginRight: 25,
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add new lead
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiTypography variant="button" fontWeight="regular" sx={{ ml: 3 }}>
          Below are the Leads you have generated, you can filter them.
        </SuiTypography>
        <LeadSearch
          serviceOptions={serviceOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
        />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default LeadTable;
