import React from "react";
import SuiBox from "components/SuiBox";
import Select from "react-select";

function Createable({
  options,
  placeholder,
  style,
  inputStyle,
  onChange,
  value,
  disabled,
  loading,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
  components,
  defaultValue,
}) {
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: "0.5rem",
      fontSize: 14,
      ...style,
      border: state.isFocused
        ? "1px solid #35d1f5"
        : state.isDisabled && "0.0625rem solid #d2d6da",
      boxShadow: state.isFocused && "0 0 0 1px #35d1f5",
      backgroundColor: "white",
      "&:hover": {
        borderColor: state.isFocused ? "#35d1f5" : "none",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: 14,
        lineHeight: "15px",
        whiteSpace: "initial !important",
        zIndex: 10000,
      };
    },
    input: (styles) => ({
      ...styles,
      height: "1.800rem",
      ...inputStyle,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem !important",
      paddingBottom: 4,
      color: "#8392ab",
      paddingLeft: 4,
      paddingTop: 4,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: 13,
      color: "#495057!important",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 2,
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 2,
    }),
  };

  return (
    <SuiBox>
      <Select
        className="basic-single"
        classNamePrefix="select"
        onChange={onChange}
        style={{ zIndex: 2 }}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
        isClearable={false}
        isDisabled={disabled}
        options={options}
        isLoading={loading ?? false}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        components={components}
        defaultValue={defaultValue}
      />
    </SuiBox>
  );
}

export default Createable;
