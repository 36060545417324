import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import * as XLSX from "xlsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import NoData from "containers/DataNotAvailBox";
import { recipeRow } from "./rows.js";
import RecipeSearch from "./search";
import { TailSpin } from "react-loader-spinner";
import {
  validateAndExtractCellValues,
  validateExcelHeaders,
} from "validations/recipe.js";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { AddMultipleRecipes } from "store/features/recipes";
import { useDispatch } from "react-redux";
import { downloadFileFromStorage } from "firebaseConfig.js";
import { clearInputValueByDocId } from "utils/common.js";

const InputFile = styled("input")({
  display: "none",
});

function RecipeTable({
  recipes,
  adminId,
  title,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  editHandler,
  deleteHandler,
  viewHandler,
}) {
  const [rows, setRows] = useState([]); // rows for recipe table
  const dispatch = useDispatch();

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [recipeArray, setRecipeArray] = useState([]);

  //filter tags in recipes
  const [searchTags, setSearchTags] = useState([]);
  useEffect(() => {
    let typeList = {};
    recipes.forEach((item) => {
      if (item.foodType && item.foodType.length > 0) {
        item.foodType.map((type, index) => {
          typeList = {
            ...typeList,
            [type]: { value: type, label: type, key: index },
          };
          return type;
        });
      }
    });

    let typeListArray = [];
    for (let [, value] of Object.entries(typeList)) {
      typeListArray.push(value);
    }
    setSearchTags(typeListArray);
  }, [recipes]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },

    { name: "Date/Time (Added)", align: "left" },
    { name: "Recipe Name", align: "left", minWidth: "100px" },
    { name: "Recipe Description", align: "left", minWidth: "120px" },
    // { name: "How To Prepare", align: "left", minWidth: '220px' },

    // { name: "Food Type", align: "left", minWidth: '200px' },
    // { name: "Ingredients", align: "left", minWidth: '120px' },
    { name: "Calories", align: "left", minWidth: "100px" },
    // {
    //     name: "Weight (in gms)", align: "left", minWidth: '120px',
    //     component: (
    //         <SuiBox
    //             style={{
    //                 display: "flex",
    //                 alignItems: 'center',
    //                 position: 'relative',
    //                 opacity: 1
    //             }}>
    //             <p style={{
    //                 color: '#8392ab',
    //                 fontSize: '0.625rem',
    //                 fontWeight: 700,
    //                 cursor: 'not-allowed'
    //             }} >WEIGHT (gms)</p>
    //         </SuiBox>)
    // },

    // { name: "Nutrition Values", align: "left", minWidth: '190px' },
    // { name: "How To Take", align: "left", minWidth: '220px' },

    // { name: "Category", align: "left", minWidth: '120px' },
    // { name: "Qty.", align: "left", minWidth: '90px' },
    // { name: "Unit", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "110px" },
  ];

  useEffect(() => {
    setRows(recipeRow(recipeArray, editHandler, deleteHandler, viewHandler));
  }, [recipeArray, editHandler, deleteHandler, viewHandler]);

  const [filterQuery, setFilterQuery] = useState({
    foodType: null,
    category: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let filterData = recipes.filter((item) => {
      if (filterQuery.foodType) {
        if (filterQuery.foodType) {
          if (filterQuery.foodType === "all") {
            setFilterQuery({ ...filterQuery, foodType: null });
          } else if (!item.foodType?.includes(filterQuery.foodType)) {
            return false;
          }
        }
      }

      if (filterQuery.category) {
        if (filterQuery.category) {
          if (filterQuery.category === "all") {
            setFilterQuery({ ...filterQuery, category: null });
          } else if (item.foodCategory !== filterQuery.category) {
            return false;
          }
        }
      }
      return item;
    });
    setRecipeArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      foodType: null,
      category: null,
    });
    setRecipeArray(recipes);
  }, [recipes]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setRecipeArray(recipes);
    } else {
      resetHandler();
      let filteredRecipe = recipes.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setRecipeArray((e) => filteredRecipe);
    }
  }, [searchVal, isSuccess, isFetching, recipes, resetHandler]);

  const excelDownload = () => {
    let updatedData = recipes.map((item, index) => {
      return [
        index + 1,
        item.createdAt.toString(),
        item.name,
        item.description,
        item.preparation,
        item.foodType.toString(),
        item.ingredients.toString(),
        isNaN(item.calories) ? "N/A" : item.calories,
        isNaN(item.weight) ? "N/A" : item.weight,
        item.nutritions && item.nutritions.length === 0
          ? "N/A"
          : item.nutritions.toString(),
        item.howToTake,
        item.foodCategory,
        item.qty,
        item.unit,
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME (ADDED)",
        "RECIPE NAME",
        "RECIPE DESCRIPTION",
        "HOW TO PREPARE",
        "FOOD TYPE",
        "INGREDIENTS",
        "CALORIES",
        "WEIGHT (gms)",
        "NUTRITION VALUES",
        "HOW TO TAKE",
        "CATEGORY",
        "QTY",
        "	UNIT",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Receipe_List.xlsx");
  };

  const [excelUploadLoader, setExcelUploadLoader] = useState(false);
  const generateErrorResponse = (row, errors) => {
    return `Row ${row}: ${errors.join(", ")}`;
  };

  const handleExcelUpload = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    f && setExcelUploadLoader(true);

    var reader = new FileReader();
    reader.onload = async function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (!validateExcelHeaders(dataParse)) {
        setExcelUploadLoader(false);
        return;
      }

      const { extractedData, rowErrors } = validateAndExtractCellValues(
        dataParse,
        adminId
      );
      if (rowErrors && rowErrors.errorMessages) {
        const { rowNumber, errorMessages } = rowErrors;
        const errorResponse = generateErrorResponse(rowNumber, errorMessages);
        toast.error(errorResponse);
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");
        return;
      }
      await dispatch(AddMultipleRecipes({ recipes: extractedData, adminId }));
      setExcelUploadLoader(false);
      clearInputValueByDocId("recipe-excel-file");
    };
    f && reader?.readAsBinaryString(f);
  };

  const downloadExcelFile = async () => {
    try {
      await downloadFileFromStorage("templates/recipe.xlsx", "recipe.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6">{title}</SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={
                  "Below are the list of recipes you have created, make sure it is updated"
                }
                placement="top"
                style={{ color: "#67748e", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Recipe Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiBox mr={1}>
              <SuiButton
                component={Button}
                color="dark"
                variant="gradient"
                onClick={addHandler}
                width={180}
                style={styles.addRecipeButton}
              >
                Add New Recipe
              </SuiButton>
            </SuiBox>

            <SuiBox mr={1}>
              <label htmlFor="recipe-excel-file" style={{ cursor: "pointer" }}>
                <InputFile
                  id="recipe-excel-file"
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleExcelUpload}
                />
                <SuiButton
                  ml={2}
                  component={SuiTypography}
                  color="dark"
                  variant="gradient"
                  onClick={(e) => e.stopPropagation()}
                >
                  Upload Bulk Recipe
                  {excelUploadLoader && (
                    <TailSpin color="#17c1e8" height={17} width={17} />
                  )}
                </SuiButton>
              </label>
            </SuiBox>

            <SuiBox>
              <SuiButton
                component={Button}
                color="dark"
                variant="gradient"
                onClick={downloadExcelFile}
                width={180}
                style={styles.addRecipeButton}
              >
                Download Template
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <RecipeSearch
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          searchTags={searchTags}
          excelDownload={excelDownload}
        />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default RecipeTable;

/**-----------styles------------- */

const styles = {
  addRecipeButton: {
    marginRight: 25,
    marginBottom: 10,
    height: 40,
    width: 230,
    color: "#3A416F",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
