import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SuiTypography
import SuiTypographyRoot from "components/SuiTypography/SuiTypographyRoot";
import { useMediaQuery, useTheme } from '@mui/material';

const ResponsiveVariant = (variant) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const variantMap = {
    subtitle3: isSmallScreen ? 'subtitle4' : 'subtitle3',
    body3: isSmallScreen ? 'body4' : 'body3',
    caption: isSmallScreen ? 'caption' : 'caption1',
  };

  return variantMap[variant] || variant;
};

const SuiTypography = forwardRef(
  (
    { color, fontWeight, textTransform, verticalAlign, textGradient, opacity, 
      children, variant, ...rest }, ref
  ) => {

    let variant_ = ResponsiveVariant(variant);

    return (
      <SuiTypographyRoot
        {...rest}
        variant={variant_}
        ref={ref}
        ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}
      >
        {children}
      </SuiTypographyRoot>
    )
  }
);

// Setting default values for the props of SuiTypography
SuiTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the SuiTypography
SuiTypography.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  opacity: PropTypes.number,
};

export default SuiTypography;
