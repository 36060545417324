import React from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Table from "examples/Tables/Table";
import NoData from 'containers/DataNotAvailBox'
import { globalStyles } from 'styles';
import Chart from 'containers/Chart'

function DataVisualizer({
    rows,
    columns,
    setChartSelected,
    chartTitle,
    chartData,
    chartDescription,
    chartSelected,
    addHandler,
    title
}) {

    return (
        <SuiBox mb={3}>
            <SuiBox p={3}
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center">
                <SuiTypography variant="h6">{title}</SuiTypography>
                <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center">
                    {rows.length > 0 ?
                        <SuiBox
                            px={1}
                            style={styles.icon}>
                            <IconButton
                                size="medium"
                                color="inherit"
                                onClick={() => {
                                    setChartSelected(false)
                                }}>
                                <Icon className={"text-white"}>
                                    table_rows
                                </Icon>
                            </IconButton>
                            |
                            <IconButton
                                size="medium"
                                color="inherit"
                                onClick={() => {
                                    setChartSelected(true)
                                }}>
                                <Icon className={"text-white"}>
                                    show_chart
                                </Icon>
                            </IconButton>
                        </SuiBox> : null
                    }

                    <SuiBox
                        style={styles.buttonContainer}>
                        <SuiButton
                            component={Button}
                            color="dark"
                            variant="gradient"
                            onClick={addHandler}
                            width={180}
                            style={styles.button}>
                            Add Data
                        </SuiButton>
                    </SuiBox>

                </SuiBox>
            </SuiBox>

            <SuiBox
                sx={globalStyles.tableOverlay}>
                {
                    chartSelected ?
                        (<Chart
                            data={chartData}
                            // title={chartTitle}
                            desc={chartDescription} />)
                        :
                        rows.length ?
                            (<Table
                                columns={columns}
                                rows={rows}
                                maxRows={6} />) :
                            <NoData />
                }
            </SuiBox>
        </SuiBox>
    )
}

export default DataVisualizer;

/**------------------------styling ---------------------- */

const styles = {
    icon: {
        background: '#DBE2F0',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center'
    },

    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: "wrap",
        marginLeft: 5,
        justifyContent: 'flex-end'
    },

    button: {
        marginRight: 25,
        marginBottom: 10,
        height: 40,
        width: 230,
        padding: 10,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'

    }
}