import Shop from "examples/Icons/Shop";
import Customer from "examples/Icons/CustomerSupport";
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import Basket from "examples/Icons/Basket";
import CreditCard from "examples/Icons/CreditCard";
import {
  AccountBalanceWallet,
  Calculate,
  CalendarMonth,
  Dashboard,
  Discount,
  DonutSmall,
  Groups,
  LiveHelp,
  SupervisedUserCircle,
} from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";

const routes = (accessModules, isEmployee) => {
  const dashboard = accessModules?.includes("dashboard")
    ? {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        icon: <Dashboard size="12px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        icon: <Dashboard size="12px" />,
        noCollapse: true,
      }
    : {};

  const offerings = accessModules?.includes("offering")
    ? {
        type: "collapse",
        name: "Offering",
        key: "offering",
        route: "/offering",
        icon: <Discount size="12px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Offering",
        key: "offering",
        route: "/offering",
        icon: <Discount size="12px" />,
        noCollapse: true,
      }
    : {};

  const clients = accessModules?.includes("clients")
    ? {
        type: "collapse",
        name: "Clients",
        key: "clients",
        route: "/clients",
        icon: <Groups size="12px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Clients",
        key: "clients",
        route: "/clients",
        icon: <Groups size="12px" />,
        noCollapse: true,
      }
    : {};

  const recipe = accessModules?.includes("recipe")
    ? {
        type: "collapse",
        name: "Food or Recipe",
        key: "recipe",
        route: "/recipe",
        icon: <Document size="12px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Food or Recipe",
        key: "recipe",
        route: "/recipe",
        icon: <Document size="12px" />,
        noCollapse: true,
      }
    : {};

  const billings = accessModules?.includes("billing")
    ? {
        type: "collapse",
        name: "Billings & Expenses",
        key: "billing",
        route: "/billing",
        icon: <CreditCard size="15px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Billings & Expenses",
        key: "billing",
        route: "/billing",
        icon: <CreditCard size="15px" />,
        noCollapse: true,
      }
    : {};

  const dietchart = accessModules?.includes("dietchart")
    ? {
        type: "collapse",
        name: "Diet Chart",
        key: "dietchart",
        route: "/dietchart",
        icon: <DonutSmall size="15px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Diet Chart",
        key: "dietchart",
        route: "/dietchart",
        icon: <DonutSmall size="15px" />,
        noCollapse: true,
      }
    : {};

  const leads = accessModules?.includes("leads")
    ? {
        type: "collapse",
        name: "Leads & Appointments",
        key: "leads",
        route: "/leads",
        icon: <SupervisedUserCircle size="15px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Leads & Appointments",
        key: "leads",
        route: "/leads",
        icon: <SupervisedUserCircle size="15px" />,
        noCollapse: true,
      }
    : {};

  const employee = !isEmployee
    ? {
        type: "collapse",
        name: "Employee",
        key: "employee",
        route: "/employee",
        icon: <WorkIcon size="15px" />,
        noCollapse: true,
      }
    : {};

  const calculators = accessModules?.includes("calculators")
    ? {
        type: "collapse",
        name: "Calculators",
        key: "calculators",
        route: "/calculators",
        icon: <Calculate size="15px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "Calculators",
        key: "calculators",
        route: "/calculators",
        icon: <Calculate size="15px" />,
        noCollapse: true,
      }
    : {};

  const faq = accessModules?.includes("faq")
    ? {
        type: "collapse",
        name: "Calculators",
        key: "faq",
        route: "/faq",
        icon: <LiveHelp size="15px" />,
        noCollapse: true,
      }
    : !isEmployee
    ? {
        type: "collapse",
        name: "FAQ",
        key: "faq",
        route: "/faq",
        icon: <LiveHelp size="15px" />,
        noCollapse: true,
      }
    : {};

  return [
    dashboard,
    offerings,
    clients,
    recipe,
    billings,
    dietchart,
    leads,
    employee,
    faq,
    calculators,
  ];
};

export default routes;

export const clientRoutes = [
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Dietary & Lifestyle",
  //   key: "dietary&lifestyle",
  //   route: "/dietary&lifestyle",
  //   icon: <Shop size="12px" />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Dietary, Lifestyle & Biochemical",
    key: "biochemical",
    route: "/biochemical",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Physical Activity",
    key: "physical&activity",
    route: "/physical&activity",
    icon: <Cube size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Measurements & Vitals",
    key: "measurements&vitals",
    route: "/measurements&vitals",
    icon: <CreditCard size="15px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dietary Recall",
    key: "dietaryrecall",
    route: "/dietaryrecall",
    icon: <Customer size="15px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Client's Daily Intake",
    key: "clientdailyintake",
    route: "/clientdailyintake",
    icon: <Basket size="15px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Image Now vs Earlier",
    key: "imagenowvsearlier",
    route: "/imagenowvsearlier",
    icon: <Basket size="15px" />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Diet Chart",
    key: "dietchart",
    route: "/dietchart",
    icon: <Basket size="15px" />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Products Purchased",
    key: "productspurchased",
    route: "/productspurchased",
    icon: <Basket size="15px" />,
    noCollapse: true,
  },
];
