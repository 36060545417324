import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

export const vitalRows = (
  vitalArray,
  editHandler,
  viewHandler,
  deleteHandler
) => {
  const rows = vitalArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      Date: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.timestamp)}
        </SuiTypography>
      ),

      "Fat (%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.fat ?? "N/A"}
        </SuiTypography>
      ),

      "VFat (%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.vfat ?? "N/A"}
        </SuiTypography>
      ),

      "Muscle Mass (%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.muscleMass ?? "N/A"}
        </SuiTypography>
      ),

      "Body Age": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.bodyAge ?? "N/A"}
        </SuiTypography>
      ),

      RM: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.rm ?? "N/A"}
        </SuiTypography>
      ),

      BMI: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.bmi ?? "N/A"}
        </SuiTypography>
      ),

      "Consultant Comments": (
        <Tooltip title={item?.consultantionNotes} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.consultantionNotes?.length > 20
              ? `${item?.consultantionNotes?.slice(0, 20)}...`
              : item?.consultantionNotes}
          </SuiTypography>
        </Tooltip>
      ),

      "Client Comments": (
        <Tooltip title={item?.clientNotes} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.clientNotes?.length > 20
              ? `${item?.clientNotes?.slice(0, 20)}...`
              : item?.clientNotes}
          </SuiTypography>
        </Tooltip>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item, index, "vital")}
        />
      ),
    };
  });
  return rows;
};
