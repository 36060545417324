import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import React from 'react'

function NotFound() {
    return (
        <SuiBox
            minHeight="100vh"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column">
            <SuiTypography
                style={{
                    fontSize: 30,
                }}>ERROR 404</SuiTypography>
            <SuiTypography variant="h6"
                style={{
                    fontSize: 20,
                }}>This page does not exist.</SuiTypography>
        </SuiBox>
    )
}

export default NotFound