import React, { useEffect, useState, useCallback } from "react";
import SuiBox from "components/SuiBox";
import Navbar from "containers/Customer/navbar";
import Sidebar from "containers/Customer/sidebar";
import { styled, useTheme } from "@mui/material/styles";
import { clientRoutes } from "routes";
import Client from "./sub-components/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSelectedClient } from "services/clientdetails";
import { getSelectedClientDetails } from "services/clientdetails";
import { getServiceInventory } from "services/clientdetails";
import { TailSpin } from "react-loader-spinner";
import { getAdminLogo } from "services/customer";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument } from "store/features/clients";
import DeleteModal from "containers/DeleteModal";
import { ClientSelector } from "store/features/clients";
import { clearState } from "store/features/authSlice";

export const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function ClientCustomer() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageValue = queryParams.get("component");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isSuccess, message, isFetching } =
    useSelector(ClientSelector);

  useEffect(() => {
    let intPageValue = parseInt(pageValue, 10);
    if (!isNaN(intPageValue)) {
      setComponentIndex(intPageValue);
    } else {
      setComponentIndex(0);
    }
  }, [pageValue]);

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [componentIndex, setComponentIndex] = useState(0);

  let { clientId, adminId } = useParams();
  const [loader, setLoader] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [services, setServices] = useState([]);
  const [client, setClient] = useState();
  const [logo, setLogo] = useState();
  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation

  const clientHandler = useCallback(async () => {
    try {
      setLoader(true);
      const servicesArray = await getServiceInventory({ uid: adminId });
      const data = await getSelectedClient({ id: clientId });
      const clientDetails = await getSelectedClientDetails({ id: clientId });
      const adminLogo = await getAdminLogo({ id: adminId });
      setLogo(adminLogo);
      setClient(data);
      setServices(servicesArray);
      setSelectedClient({ ...clientDetails });
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
      navigate("/notfound");
      toast.error("Something went wrong, retry");
    }
  }, [clientId, adminId, navigate]);

  useEffect(() => {
    if (clientId) {
      clientHandler();
    }
  }, [clientId, clientHandler]);

  //for dietary, physical, bichemical, measurement, vital array list
  const [dietaryArray, setDietaryArray] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [physicalArray, setPhysicalArray] = useState([]);
  const [biochemicalArray, setBiochemicalArray] = useState([]);
  const [measurementArray, setMeasurementArray] = useState([]);
  const [vitalArray, setVitalArray] = useState([]);
  const [dietRecallArray, setDietRecallArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [dailyIntakeArray, setDailyIntakeArray] = useState([]);

  useEffect(() => {
    if (selectedClient) {
      const {
        dietary,
        physical,
        biochemical,
        measurement,
        vital,
        dietRecall,
        imageNowVsEarlier,
        dailyIntake,
      } = selectedClient;

      setDietaryArray(dietary ?? []);
      setPhysicalArray(physical ?? []);
      setBiochemicalArray(biochemical ?? []);
      setMeasurementArray(measurement ?? []);
      setVitalArray(vital ?? []);
      setDietRecallArray(dietRecall ?? []);
      setImageArray(imageNowVsEarlier ?? []);
      setDailyIntakeArray(dailyIntake ?? []);
    }
  }, [
    selectedClient,
    selectedClient?.dietary,
    selectedClient?.dietRecall,
    selectedClient?.physical,
    selectedClient?.biochemical,
    selectedClient?.measurement,
    selectedClient?.vital,
    selectedClient?.dailyIntake,
    selectedClient?.imageNowVsEarlier,
    selectedClient?.customDietPdf,
  ]);

  const deleteHandler = (clientId, persona, id, selectedItemIndex) => {
    dispatch(deleteDocument({ persona, clientId, id, selectedItemIndex }));
  };

  const onDeleteSuccess = useCallback(() => {
    if (deleteModal) {
      setDeleteModal(false);
    }
  }, [deleteModal]);

  useEffect(() => {
    if (isSuccess) {
      clientHandler();
      toast.success(message);
      dispatch(clearState());
      onDeleteSuccess();
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }

    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching]);


  return (
    <SuiBox sx={{ display: "flex" }}>
      <Navbar logo={logo} open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        theme={theme}
        routes={clientRoutes ?? []}
        componentIndex={componentIndex}
        setComponentIndex={setComponentIndex}
      />

      <Main open={open} overflow="hidden">
        <DrawerHeader />
        {loader ? (
          <SuiBox
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
          >
            <TailSpin color="#17c1e8" height={50} width={50} />
          </SuiBox>
        ) : (
          <Client
            client={client}
            logo={logo}
            services={services}
            componentKey={componentIndex}
            dietaryArray={dietaryArray}
            physicalArray={physicalArray}
            biochemicalArray={biochemicalArray}
            measurementArray={measurementArray}
            vitalArray={vitalArray}
            dietRecallArray={dietRecallArray}
            dailyIntakeArray={dailyIntakeArray}
            imageArray={imageArray}
            selectedClient={{ ...client, ...selectedClient }}
            productArray={client?.products ?? []}
            deleteHandler={deleteHandler}
            setDeleteModal={setDeleteModal}
            setSelectedItem={setSelectedItem}
          />
        )}
        {deleteModal && (
          <DeleteModal
            isFetching={isFetching}
            onDeleteHandler={deleteHandler}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
        )}
      </Main>
    </SuiBox>
  );
}

export default ClientCustomer;
