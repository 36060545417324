import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import Table from "examples/Tables/Table";
import ServiceSearch from "./serviceSearchbar";
import NoData from "containers/DataNotAvailBox";
import { serviceRows_ } from "./serviceRows.js";
import { TailSpin } from "react-loader-spinner";
import { validateExcelHeaders } from "validations";
import { excelAndDbServiceFieldsMapping } from "constant";
import { validateAndExtractCellValuesForServices } from "validations/service";
import { downloadErrorsAsTxt } from "utils/helper";
import { toast } from "react-toastify";
import { addMultipleServices } from "services/offering";
import { downloadFileFromStorage } from "firebaseConfig";
import { styled } from "@mui/material/styles";
import { clearInputValueByDocId } from "utils/common";
import { useDispatch } from "react-redux";
import { updateServicesAfterBulkUpload } from "store/features/dashboard";
import moment from "moment";

const InputFile = styled("input")({
  display: "none",
});

function ServiceTable({
  services,
  title,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  editHandler,
  deleteHandler,
  viewhandler,
  adminId,
}) {
  const dispatch = useDispatch();

  const [serviceOptions, setserviceOptions] = useState([]); //filtering wrt services

  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
      };
    });
    setserviceOptions(serviceArray);
  }, [services]);

  const [rows, setRows] = useState([]); // rows for service table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [serviceArray, setserviceArray] = useState([]); //

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time (Added)", align: "left", minWidth: "140px" },
    { name: "Service Name", align: "left", minWidth: "120px" },
    { name: "Service Description", align: "left", minWidth: "180px" },
    { name: "Selling price WT", align: "left", minWidth: "140px" },
    // { name: "Selling price WOT", align: "left", minWidth: '140px' },
    { name: "Tax(%)", align: "left" },
    // { name: "Tax amount", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    setRows(
      serviceRows_(serviceArray, editHandler, deleteHandler, viewhandler)
    );
  }, [serviceArray, editHandler, deleteHandler, viewhandler]);

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    service: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let filterData = services.filter((item) => {
      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.service !== item["serviceName"]) {
          return false;
        }
      }

      return item;
    });
    setserviceArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      status: null,
      service: null,
    });
    setserviceArray(services);
  }, [services]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setserviceArray(services);
    } else {
      resetHandler();
      let filteredservice = services.filter((s) => {
        let name = s?.serviceName;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setserviceArray((e) => filteredservice);
    }
  }, [searchVal, isSuccess, isFetching, services, resetHandler]);
  const excelDownload = () => {
    let updatedData = services.map((item, index) => {
      return [
        index + 1,
        item.serviceName,
        moment(item?.createdAt).local().format("DD/MM/YYYY h:mm a"),
        item.description,
        (item.sellingPriceWOT * item.gst) / 100 + item.sellingPriceWOT,
        item.sellingPriceWOT,
        item.gst,
        (item.sellingPriceWOT * item.gst) / 100,
      ];
    });
    let excelData = [
      [
        "id",
        "SERVICE NAME",
        "DATE/TIME (ADDED)",
        "SERVICE DESCRIPTION",
        "SELLING PRICE WT",
        "SELLING PRICE WOT",
        "TAX(%)",
        "TAX AMOUNT",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Services_Listing.xlsx");
  };

  const [excelUploadLoader, setExcelUploadLoader] = useState(false);

  const handleExcelUpload = (e) => {
    e.preventDefault();
    setExcelUploadLoader(true);
    var files = e.target.files,
      f = files[0];
    f && setExcelUploadLoader(true);
    var reader = new FileReader();
    reader.onload = async function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });
      if (!validateExcelHeaders(dataParse, excelAndDbServiceFieldsMapping)) {
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");
        return;
      }
      const { extractedData, errors } =
        await validateAndExtractCellValuesForServices(dataParse, adminId);
      if (errors && errors.length > 0) {
        downloadErrorsAsTxt(errors, "services_errors.txt");
        toast.error(
          "Please check the errors in the downloaded file and try again!!"
        );
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");

        return;
      }

      try {
        const newServices = await addMultipleServices(extractedData);
        dispatch(updateServicesAfterBulkUpload([...newServices, ...services]));
        setserviceArray((e) => [...newServices, ...e]);
        toast.success("Services added successfully!!");
        clearInputValueByDocId("recipe-excel-file");
      } catch (error) {
        toast.error("Something went wrong, please try again!!");
        clearInputValueByDocId("recipe-excel-file");
      }
      setExcelUploadLoader(false);
    };
    f && reader.readAsBinaryString(f);
  };

  const downloadExcelFile = async () => {
    try {
      await downloadFileFromStorage("templates/services.xlsx", "services.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", xl: "row" }}
          gap={3}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 1 }}
          >
            Below are your services, you can add, delete, update the services.
          </SuiTypography>

          <SuiBox
            className="search-box-div"
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
            justifyContent={{ xs: "center", lg: "flex-end" }}
          >
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addHandler}
              width={180}
              style={styles.button}
            >
              Add new service
            </SuiButton>

            <label htmlFor="recipe-excel-file" style={{ cursor: "pointer" }}>
              <InputFile
                id="recipe-excel-file"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleExcelUpload}
              />
              <SuiButton
                ml={2}
                component={SuiTypography}
                color="dark"
                variant="gradient"
                onClick={(e) => e.stopPropagation()}
                style={styles.button}
              >
                Bulk Upload Services
                {excelUploadLoader && (
                  <TailSpin color="#17c1e8" height={17} width={17} />
                )}
              </SuiButton>
            </label>

            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={downloadExcelFile}
              width={180}
              style={{ ...styles.button, marginLeft: 25 }}
            >
              Download excel template
            </SuiButton>
          </SuiBox>
        </SuiBox>

        <ServiceSearch
          serviceOptions={serviceOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
        />
        {!loader ? (
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {rows.length ? <Table columns={columns} rows={rows} /> : <NoData />}
          </SuiBox>
        ) : (
          <SuiBox style={{ display: "flex", justifyContent: "center" }} mb={4}>
            <TailSpin color="#17c1e8" height={60} width={60} />
          </SuiBox>
        )}
      </Card>
    </SuiBox>
  );
}

export default ServiceTable;

/**-----------styles------------- */

const styles = {
  addProductButton: {
    marginRight: 25,
    marginBottom: 10,
    height: 40,
    width: 230,
    color: "#3A416F",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
