import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { AddVital, EditVital } from "services/clientdetails";
import { timestampConversion } from "utils/common";
import moment from "moment";
import { submitDataHandler } from "../utils";
import CustomButton from "containers/CustomButton";
import { updateRow } from "utils/common";
import { v4 as uuidv4 } from "uuid";

function VitalModal({
  open,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      fat: null,
      vfat: null,
      muscleMass: null,
      bodyAge: null,
      rm: null,
      bmi: null,
      timestamp: new Date(),
      consultantionNotes: null,
      clientNotes: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      fat: false,
      vfat: false,
      muscleMass: false,
      bodyAge: false,
      rm: false,
      bmi: false,
      timestamp: false,
      consultantionNotes: false,
      clientNotes: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        timestamp: selectedItem?.timestamp?.seconds
          ? timestampConversion(selectedItem?.timestamp)._d
          : selectedItem?.timestamp,
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.fat) {
      toast.error("Please fill Fat field");
      setErrorMessage({
        ...errorMessage,
        fat: true,
      });
      return;
    }

    if (!formdata.vfat) {
      toast.error("Please fill VFat field");
      setErrorMessage({
        ...errorMessage,
        vfat: true,
      });
      return;
    }

    if (!formdata.muscleMass) {
      toast.error("Please enter Muscle Mass field");
      setErrorMessage({
        ...errorMessage,
        muscleMass: true,
      });
      return;
    }

    if (!formdata.bodyAge) {
      toast.error("Please fill Body Age field");
      setErrorMessage({
        ...errorMessage,
        bodyAge: true,
      });
      return;
    }

    if (!formdata.rm) {
      toast.error("Please fill RM field");
      setErrorMessage({
        ...errorMessage,
        rm: true,
      });
      return;
    }

    if (!formdata.bmi) {
      toast.error("Please fill BMI field");
      setErrorMessage({
        ...errorMessage,
        bmi: true,
      });
      return;
    }

    if (!formdata.timestamp) {
      toast.error("Please fill date/time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (!formdata.consultantionNotes) {
      toast.error("Please fill Consultation Notes field");
      setErrorMessage({
        ...errorMessage,
        consultantionNotes: true,
      });
      return;
    }

    if (!formdata.clientNotes) {
      toast.error("Please fill Client Notes field");
      setErrorMessage({
        ...errorMessage,
        clientNotes: true,
      });
      return;
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () => await EditVital({ formdata, id }),
        addSubmitHandler: async () => await AddVital({ formdata, id }),
        setSelectedClient,
        key: "vital",
        handleCloseModal,
        setSelectedItem,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault,
      });
    } else if (persona === "client") {
      const docId = uuidv4();

      setMainData((data) => {
        if (selectedItem) {
          const updatedData = updateRow(selectedItem.id, formdata, data);
          data = updatedData;
        } else {
          data = [{ id: docId, ...formdata, createdAt: new Date() }, ...data];
        }
        return data;
      });
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (!open) {
      setFormdata({ ...defaultHandler });
    }
  }, [open, defaultHandler]);

  return (
    <SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Fat (%)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="fat"
                  readOnly={mode === "view"}
                  error={errorMessage?.fat}
                  value={formdata.fat ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (parseFloat(e.target.value) > 100) {
                      toast.info("Value can't be more than 100");
                      return;
                    }

                    if (parseFloat(e.target.value) < 0) {
                      toast.info("Value can't be less than 0");
                      return;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  VFat (%)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="vfat"
                  readOnly={mode === "view"}
                  error={errorMessage?.vfat}
                  value={formdata.vfat ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    if (parseFloat(e.target.value) > 100) {
                      toast.info("Value can't be more than 100");
                      return;
                    }

                    if (parseFloat(e.target.value) < 0) {
                      toast.info("Value can't be less than 0");
                      return;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Muscle Mass (%)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="muscleMass"
                  readOnly={mode === "view"}
                  error={errorMessage?.muscleMass}
                  value={formdata.muscleMass ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (parseFloat(e.target.value) > 100) {
                      toast.info("Value can't be more than 100");
                      return;
                    }

                    if (parseFloat(e.target.value) < 0) {
                      toast.info("Value can't be less than 0");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Body Age
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="bodyAge"
                  readOnly={mode === "view"}
                  error={errorMessage?.bodyAge}
                  value={formdata.bodyAge ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  RM
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="rm"
                  readOnly={mode === "view"}
                  error={errorMessage?.rm}
                  value={formdata.rm ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  BMI
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="bmi"
                  readOnly={mode === "view"}
                  error={errorMessage?.bmi}
                  value={formdata.bmi ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Date/time
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="datetime-local"
                  name="timestamp"
                  readOnly={mode === "view"}
                  placeholder=""
                  error={errorMessage?.timestamp}
                  style={{ paddingRight: "1px" }}
                  value={
                    formdata.timestamp
                      ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                      : moment().format("YYYY-MM-DDTkk:mm")
                  }
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Consultation Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="consultantionNotes"
                  readOnly={mode === "view"}
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  error={errorMessage?.consultantionNotes}
                  value={formdata.consultantionNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="clientNotes"
                  readOnly={mode === "view"}
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  error={errorMessage?.clientNotes}
                  value={formdata.clientNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default VitalModal;
