import { useState, useEffect, useCallback } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import fitifyLogo from "assets/images/fitifyLogo.png";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, clearState, loginUser } from 'store/features/authSlice';
import { toast } from 'react-toastify';
import { validateEmail } from 'utils/common'
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";


function SignIn() {

    const dispatch = useDispatch()
    const { isSuccess, isError, isFetching, message } = useSelector(userSelector);

    useEffect(() => {
        if (isError) {
            toast.error(message)
            dispatch(clearState())
        }

        if (isSuccess) {
            dispatch(clearState())
        }

    }, [isError, message, dispatch, isSuccess])

    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    }, [dispatch])

    const auth = getAuth()
    const [formdata, setFormdata] = useState({
        email: "",
        password: ""
    })

    const errordefault = {
        email: false,
        password: false
    }

    const [errorMessage, setErrorMessage] = useState(errordefault)

    const loginHandler = useCallback(() => {

        if (!validateEmail(formdata.email)) {
            toast.error("Email is Invalid")
            setErrorMessage({
                ...errorMessage,
                email: true
            })
            return;
        }

        dispatch(loginUser({ auth, email: formdata.email, password: formdata.password }))
    }, [auth, dispatch, errorMessage, formdata.email, formdata.password])



    const navigate = useNavigate()

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                // event.preventDefault();
                loginHandler()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [loginHandler]);

    const [showPass, setShowPass] = useState(false)


    return (
        <CoverLayout
            title="Welcome back"
            description="Enter your email and password to sign in"
            image={curved9} fitifyLogo={fitifyLogo}>
            <SuiBox component="form" role="form">
                <SuiBox mb={2}>
                    <SuiBox mb={1} ml={0.5}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Email
                        </SuiTypography>
                    </SuiBox>
                    <SuiInput
                        type="email"
                        error={errorMessage?.email}
                        placeholder="Email"
                        onChange={(e) => {
                            setErrorMessage({
                                ...errorMessage,
                                "email": false
                            })
                            setFormdata({
                                ...formdata,
                                "email": e.target.value
                            })
                        }} />
                </SuiBox>
                <SuiBox mb={2}>
                    <SuiBox mb={1} ml={0.5}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Password
                        </SuiTypography>
                    </SuiBox>
                    <SuiBox style={{ display: 'flex', position: 'relative' }}>
                        <SuiInput
                            type={showPass ? "text" : "password"}
                            placeholder="Password"
                            error={errorMessage?.password}
                            onChange={(e) => {
                                setErrorMessage({
                                    ...errorMessage,
                                    "password": false
                                })
                                setFormdata({
                                    ...formdata,
                                    "password": e.target.value
                                })
                            }} />

                        <Icon
                            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                                fontSize: `${size.lg} !important`,
                                fontWeight: `${fontWeightBold} !important`,
                                strokeWidth: "2px",
                                cursor: "pointer",
                                mt: 2,
                                position: 'absolute',
                                right: 10,
                                top: -3
                            })}
                            onClick={() => {
                                setShowPass((e) => !e)
                            }}>
                            {!showPass ? 'visibility' : 'visibility_off'}
                        </Icon>
                    </SuiBox>
                </SuiBox>
                <SuiBox display="flex" alignItems="center">
                    <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        onClick={() => navigate("/forgot-password")}
                        sx={{ color: "#17C1E8", cursor: "pointer", userSelect: "none" }}
                    >
                        Forgot password
                    </SuiTypography>
                </SuiBox>

                <SuiBox mt={1} display="flex" alignItems="center">
                    <SuiTypography
                        variant="h7"
                        fontWeight="regular"
                        sx={{ cursor: "pointer", userSelect: "none" }}>

                        Don't have an account?
                        <SuiTypography
                            onClick={() => navigate("/register")}
                            style={{ color: "#17C1E8", cursor: 'pointer' }}
                            component="label" variant="button" fontWeight="bold">
                            &nbsp;&nbsp;Sign up
                        </SuiTypography>

                    </SuiTypography>
                </SuiBox>

                <SuiBox
                    mt={4}
                    mb={1}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 16
                    }}>
                    <SuiButton
                        variant="gradient"
                        color="info"
                        disabled={isFetching}
                        onClick={loginHandler}>
                        {isFetching ? 'Loading . . .' : 'sign in'}
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </CoverLayout>
    );
}

export default SignIn;