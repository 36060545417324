export const faqData = {
  dashboard: [
    {
      subtitle: "Overview",
      questions: [
        {
          question: "What is the purpose of the dashboard?",
          answer:
            "The dashboard provides an overview of key metrics and insights into various aspects of the system.",
          url: "https://www.youtube.com/watch?v=gbXxTtEPtNY",
        },
        {
          question: "How often is the dashboard updated?",
          answer:
            "The dashboard is updated in real-time to ensure that you have access to the most current information.",
        },
      ],
    },
    {
      subtitle: "Managing Dashboard Data",
      questions: [
        {
          question: "Can I add or remove data sources from the dashboard?",
          answer:
            "No, you cannot manage dashboard data sources as these sources are based on the activities on your clients and services.",
          url: "https://www.youtube.com/watch?v=pNqfD6fRif8",
        },
      ],
    },

    {
      subtitle: "Navigation",
      questions: [
        {
          question:
            "How do I navigate through different sections of the dashboard?",
          answer:
            "You can navigate using the sidebar menu or by clicking on specific sections within the dashboard.",
        },
        {
          question: "Can I customize the dashboard layout?",
          answer: "no, you cannot customize the dashboard layout.",
        },
      ],
    },
    {
      subtitle: "Data Visualization",
      questions: [
        {
          question:
            "What types of data visualization are available on the dashboard?",
          answer:
            "The dashboard offers various data visualization options such as charts, graphs, tables, and maps to present information in a visually appealing and understandable way.",
        },
        {
          question:
            "Are there interactive features available for the visualizations?",
          answer:
            "Yes, many of the visualizations on the dashboard are interactive, allowing users to hover over data points for more details, filter data based on specific criteria, and zoom in/out for better analysis.",
        },
      ],
    },
  ],
  profile: [
    {
      subtitle: "Profile Overview",
      questions: [
        {
          question: "What is the Profile section for?",
          answer:
            "The Profile section is where you can manage your personal information, preferences, and settings.",
          url: "https://www.youtube.com/watch?v=X24jsy0Y2ug",
        },
        {
          question: "How do I access my profile?",
          answer:
            "You can access your profile by clicking on your username or profile picture in the top right corner of the screen.",
        },
        {
          question: "What information can I manage in my profile?",
          answer:
            "In your profile, you can manage details such as your name, email address, contact information, password, and privacy settings.",
          url: "https://www.youtube.com/watch?v=X24jsy0Y2ug",
        },
        {
          question: "Can I customize my profile?",
          answer:
            "Yes, you can customize your profile by uploading a profile picture, adding additional contact information, and adjusting privacy settings.",
          url: "https://www.youtube.com/watch?v=X24jsy0Y2ug",
        },
        {
          question: "Is my profile information secure?",
          answer:
            "Yes, we take the security of your profile information seriously and employ measures to protect it from unauthorized access.",
        },
      ],
    },

    {
      subtitle: "Subscription",
      questions: [
        {
          question: "How can I view  my subscription information?",
          answer:
            "To view  your subscription information, go to the Subscription Information section in your profile.",
        },
      ],
    },

    {
      subtitle: "Billing Information",
      questions: [
        {
          question: "Can I update my billing information?",
          answer:
            "Yes, you can update your billing information such as credit card details or billing address in the Billing Information section.",
        },
        {
          question: "Are invoices available for my subscription payments?",
          answer:
            "Yes, you can download invoices for your subscription payments from the Subscription information  section.",
        },
      ],
    },
  ],

  offerings: [
    {
      subtitle: "Manage Product Inventory",
      questions: [
        {
          question: "How to add new product?",
          answer:
            "Click on the add new product button under the product inventory tab.",
          url: "https://www.youtube.com/watch?v=xHoVfEIj2gQ",
        },
        {
          question: "How to sell the product?",
          answer: "Click on the sell button of the particular product.",
          url: "https://www.youtube.com/watch?v=xHoVfEIj2gQ",
        },
        {
          question: "How to edit the existing product?",
          answer: "Click on the edit icon for the particular product.",
          url: "https://www.youtube.com/watch?v=v05Cy859360",
        },
        {
          question: "How to delete the existing product?",
          answer: "Click on the delete icon for the particular product.",
          url: "https://www.youtube.com/watch?v=v05Cy859360",
        },
        {
          question: "How to download the excel of all products?",
          answer:
            "Click on the download button under the product inventory section.",
          url: "https://www.youtube.com/watch?v=v05Cy859360",
        },
      ],
    },
    {
      subtitle: "Manage Services",
      questions: [
        {
          question: "How to add new services?",
          answer: "Click on the add new service button under the services tab.",
          url: "https://www.youtube.com/watch?v=qug9qiGfOz8",
        },
        {
          question:
            "How to edit the existing services assigned to particular client?",
          answer: "Click on the edit icon for the particular client.",
          url: "https://www.youtube.com/watch?v=qug9qiGfOz8",
        },
        {
          question:
            "How to view the existing services assigned to particular client?",
          answer:
            "Click on the view icon for the particular client you want to view the services.",
          url: "https://www.youtube.com/watch?v=qug9qiGfOz8",
        },
        {
          question:
            "How to delete the existing services assigned to particular client?",
          answer:
            "Click on the delete icon for the particular client you want to delete the services.",
          url: "https://www.youtube.com/watch?v=qug9qiGfOz8",
        },
      ],
    },

    {
      subtitle: "Manage Consultation",
      questions: [
        {
          question: "How to add new consultation?",
          answer:
            "Click on the add new consultation service button under the consultation tab.",
          url: "https://www.youtube.com/watch?v=DP4QoGcQ4nc",
        },
        {
          question:
            "How to edit the existing consultation assigned to particular client?",
          answer:
            "Click on the edit icon for the particular client you want to edit the consultation.",
          url: "https://www.youtube.com/watch?v=DP4QoGcQ4nc",
        },
        {
          question:
            "How to view the existing consultation assigned to particular client?",
          answer:
            "Click on the view icon for the particular client you want to view the consultation.",
          url: "https://www.youtube.com/watch?v=DP4QoGcQ4nc",
        },
        {
          question:
            "How to delete the existing consultation assigned to particular client?",
          answer:
            "Click on the delete icon for the particular client you want to delete the consultation.",
          url: "https://www.youtube.com/watch?v=DP4QoGcQ4nc",
        },
      ],
    },
  ],
  clients: [
    {
      subtitle: "Client's Management",
      questions: [
        {
          question: "How do I add a new client?",
          answer:
            "You can add a new client by navigating to the Clients section and selecting the 'Add Client' option.",
          url: "https://youtu.be/2n25NgSCCVc",
        },
        {
          question: "How can I edit a client?",
          answer:
            "To edit a client, go to the Clients section, select the client you want to edit, and choose the 'Edit' option.",
          url: "https://youtu.be/2n25NgSCCVc",
        },
        {
          question: "How can I delete a client?",
          answer:
            "To delete a client, go to the Clients section, select the client you want to delete, and choose the 'Delete' option.",
          url: "https://youtu.be/2n25NgSCCVc",
        },
        {
          question:
            "Is there a way to assign dietician's to manage certain clients?",
          answer:
            "Yes, you can assign dietician's to manage clients by editing the client details and assigning dietician's to them.",
        },
      ],
    },

    {
      subtitle: "Customer's unique link Management",
      questions: [
        {
          question: "How to copy the Customer's unique link?",
          answer:
            "To copy the Customer's unique link, go to the particular client's and click on the link button. This will automatically copy the link to your clipboard, allowing you to paste it on the browser new tab.",
          url: "https://youtu.be/2n25NgSCCVc",
        },
        {
          question: "Can customers update their details using the unique link?",
          answer:
            "Yes, customers can update their details using the unique link. Once they access the link, they will be directed to a form where they can make changes or additions to their information.",
          url: "https://youtu.be/WecWSynKfvM",
        },
        {
          question:
            "What information is displayed on the admin side client dashboard regarding the data filled out by the client?",
          answer:
            "The admin side client dashboard displays all the information that the client has added and saved using the customer link. This includes assessment-related data and any other details provided by the client.",
          url: "https://youtu.be/WecWSynKfvM",
        },
        {
          question:
            "Is there a way for clients to save their progress while filling out the assessment-related data using the customer link?",
          answer:
            "Yes, clients can save their progress while filling out the assessment-related data using the customer link. This allows them to continue from where they left off at a later time.",
          url: "https://youtu.be/WecWSynKfvM",
        },
        {
          question:
            "Can clients edit or update the information they have already submitted using the customer link?",
          answer:
            "Yes, clients have the ability to edit or update the information they have already submitted using the customer link. This ensures that their data remains accurate and up-to-date.",
          url: "https://youtu.be/WecWSynKfvM",
        },
      ],
    },

    {
      subtitle: "Client Dashboard Features",
      questions: [
        {
          question: "What features does the client dashboard offer?",
          answer:
            "The client dashboard offers features such as uploading a diet chart, verifying product purchase details, adding data to assessments, checking personal details, and editing services.",
          url: "https://youtu.be/4TiH_mRtbio",
        },
        {
          question: "How can I upload a diet chart?",
          answer:
            "You can upload a diet chart by following the instructions provided in the video.",
          url: "https://youtu.be/4TiH_mRtbio",
        },
        {
          question:
            "What is the process for verifying product purchase details?",
          answer:
            "The video demonstrates how to verify product purchase details from the client dashboard.",
          url: "https://youtu.be/4TiH_mRtbio",
        },
        {
          question: "How do I add data to assessments?",
          answer:
            "Adding data to assessments is explained in the video tutorial.",
          url: "https://youtu.be/4TiH_mRtbio",
        },
        {
          question: "Can I review and edit my personal details?",
          answer:
            "Yes, you can review and edit your personal details through the client dashboard.",
          url: "https://youtu.be/4TiH_mRtbio",
        },
      ],
    },
  ],
  recipe: [
    {
      subtitle: "Food or Recipe Module Overview",
      questions: [
        {
          question: "What does the Food or Recipe module offer?",
          answer:
            "The Food or Recipe module provides a comprehensive overview and details on searching, downloading Excel, uploading in bulk, adding new recipes, examining, amending, and removing recipes.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "How do I search for recipes?",
          answer:
            "The video demonstrates how to effectively search for recipes within the module.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "What options are available for downloading recipes?",
          answer:
            "You can download recipes in Excel format, as shown in the video tutorial.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },

        {
          question: "How can I add new recipes?",
          answer: "Adding new recipes is covered in detail in the video.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
      ],
    },
    {
      subtitle: "Exploring Recipe Management",
      questions: [
        {
          question:
            "What functionalities are provided in the recipe management module?",
          answer:
            "The recipe management module offers features such as searching for recipes, downloading them in Excel format, uploading recipes in bulk, adding new recipes, and examining and amending existing ones.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "How can I efficiently search for recipes?",
          answer:
            "The video tutorial illustrates effective methods for searching within the recipe database.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "In what formats can I download recipes?",
          answer:
            "Recipes can be downloaded in Excel format, allowing for easy organization and management.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
      ],
    },
    {
      subtitle: "Managing and Editing Recipes",
      questions: [
        {
          question: "Is there a way to upload multiple recipes at once?",
          answer:
            "Yes, the video demonstrates the process of bulk uploading recipes.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "What steps are involved in adding new recipes?",
          answer:
            "Adding new recipes is explained thoroughly in the video tutorial.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "How can I examine and modify existing recipes?",
          answer:
            "The video provides clear instructions on examining and amending recipes within the module.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
      ],
    },
    {
      subtitle: "Bulk Recipe Management",
      questions: [
        {
          question: "What options are available for bulk recipe management?",
          answer:
            "Bulk recipe management allows for efficient handling of multiple recipes at once, including uploading, editing, and deleting.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "How can I upload recipes in bulk?",
          answer:
            "Bulk uploading involves selecting multiple recipe files and uploading them simultaneously, as demonstrated in the video tutorial.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
        {
          question: "Are there any limitations to bulk uploading?",
          answer:
            "While bulk uploading is efficient, users should ensure that all files are properly formatted and compatible with the system.",
          url: "https://youtu.be/mSBu5Gb2WMU",
        },
      ],
    },
  ],

  billings: [
    {
      subtitle: "Clients List (Services)",
      questions: [
        {
          question:
            "What information is typically included in the clients list for services?",
          answer:
            "The clients list for services usually includes details such as client names, contact information, services availed, dates of service, and any relevant notes or comments.",
          url: "https://youtu.be/iYmNXx6-awI",
        },
        {
          question: "How can I access the clients list for services?",
          answer:
            "You can access the clients list for services through the respective section or module in the system. Typically, it is found within the dashboard or menu options related to client management.",
          url: "https://youtu.be/iYmNXx6-awI",
        },
        {
          question:
            "Is it possible to edit or update client information in the services list?",
          answer:
            "Yes, most systems allow for editing or updating client information directly within the clients list for services. You can typically click on the client's name or profile to access and modify their details.",
        },
      ],
    },
    {
      subtitle: "Clients List (Products)",
      questions: [
        {
          question:
            "What details are included in the clients list for products?",
          answer:
            "The clients list for products typically includes information such as client names, contact details, products purchased, quantities, purchase dates, and any relevant notes or comments.",
          url: "https://youtu.be/iYmNXx6-awI",
        },
        {
          question: "How do I view the clients list for products?",
          answer:
            "To view the clients list for products, navigate to the relevant section or module in the system. It is usually accessible through the dashboard or menu options related to product management or client transactions.",
          url: "https://youtu.be/iYmNXx6-awI",
        },
        {
          question:
            "Can I track product purchases and client details in the clients list for products?",
          answer:
            "Yes, the clients list for products allows you to track both product purchases and associated client details. It provides a comprehensive overview of client transactions related to products.",
        },
      ],
    },
    {
      subtitle: "Clients List (Consultations)",
      questions: [
        {
          question:
            "What information is typically included in the clients list for consultations?",
          answer:
            "The clients list for consultations typically contains details such as client names, contact information, consultation dates, consultation topics or purposes, and any relevant notes or follow-up actions.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
        {
          question: "How do I access the clients list for consultations?",
          answer:
            "Accessing the clients list for consultations is usually done through the respective section or module in the system. Look for options related to consultation management within the dashboard or menu.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
        {
          question:
            "Is it possible to schedule or update consultations from the clients list?",
          answer:
            "Yes, many systems allow for scheduling or updating consultations directly from the clients list. You can typically click on the client's name or consultation entry to access scheduling options.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
      ],
    },
    {
      subtitle: "Invoice History",
      questions: [
        {
          question: "How can I view my invoice history?",
          answer:
            "To view your invoice history, navigate to the 'Invoices' or 'Billing' section within the system. There should be an option to view past invoices or an invoice history tab where you can access a list of previously generated invoices.",
        },
        {
          question: "Is it possible to download or print past invoices?",
          answer:
            "Yes, you can often download or print past invoices from the invoice history section within the system. Look for options to download invoices as PDF files or print them directly from the system for record-keeping or accounting purposes.",
        },
      ],
    },
  ],
  expenses: [
    {
      subtitle: "Managing Expenses",
      questions: [
        {
          question:
            "What types of expenses are typically tracked in the system?",
          answer:
            "Expenses tracked in the system may include various categories such as office supplies, equipment purchases, utilities, travel expenses, and professional services.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
        {
          question: "How do I add expenses to the system?",
          answer:
            "To add expenses to the system, navigate to the expenses section or module and look for options to add new expenses. You will usually be prompted to enter details such as the expense category, amount, date, and any relevant notes.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
        {
          question: "How can I update/edit existing expenses in the system?",
          answer:
            "To update or edit existing expenses, find the expense entry you want to modify within the system. Look for an option to edit the details, such as amount, category, date, or notes. Make the necessary changes and save the updated expense entry.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
        {
          question: "How do I delete expenses from the system?",
          answer:
            "To delete expenses from the system, locate the expense entry you wish to remove. Look for an option or button to delete the expense. Confirm the deletion when prompted.",
          url: "https://youtu.be/VTd5S-bnbSI",
        },
      ],
    },
    {
      subtitle: "Reports",
      questions: [
        {
          question: "Can I generate expense reports?",
          answer:
            "Yes, you can generate detailed expense reports to analyze your spending habits.",
        },
        {
          question:
            "Is it possible to download expenses data for accounting purposes?",
          answer:
            "Yes, you can download expenses data in various formats for accounting and auditing purposes.",
        },
      ],
    },
  ],
  dietchart: [
    {
      subtitle: "General Information",
      questions: [
        {
          question: "What is a diet chart?",
          answer:
            "A diet chart is a plan that outlines what you should eat and drink to achieve specific health or fitness goals.",
        },
        {
          question: "Why is a diet chart important?",
          answer:
            "A diet chart is important because it helps you track your food intake, ensures you're getting the right nutrients, and can help you achieve your health goals.",
        },
        {
          question: "How do I create a diet chart?",
          answer:
            "To create a diet chart, you can go to the dietchart page by clicking on the diet chart menu and clicking on the add new diet plan button.",
          url: "https://youtu.be/S623h_qNX-w",
        },
      ],
    },
    {
      subtitle: "Using a Diet Chart",
      questions: [
        {
          question: "How do I Create a diet chart?",
          answer:
            "To Create a diet chart, you should create a new diet chart by clicking on the add new diet plan button.",
          url: "https://youtu.be/S623h_qNX-w",
        },
        {
          question: "Can a diet chart help me lose weight?",
          answer:
            "Yes, a well-designed diet chart can help you lose weight by controlling your calorie intake and promoting healthier food choices.",
        },
      ],
    },
  ],
  leads: [
    {
      subtitle: "Managing Leads",
      questions: [
        {
          question: "How do I add a new lead to the system?",
          answer:
            "To add a new lead, navigate to the 'Add New Lead' section and provide the following details: Client Name, Country Code, Phone Number, Email, Service Interested In, Lead Status, Next Follow Up Date, Source, Customer Notes, Consultant Notes. Click 'Add' to save the lead.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question: "Can I edit the information of an existing lead?",
          answer:
            "Yes, you can update details of an existing lead, such as contact information or status.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question:
            "What is the purpose of the leads module in the web application?",
          answer:
            "The leads module helps track and manage potential clients or customers.",
        },
        {
          question: "How do I add a new lead to the system?",
          answer:
            "To add a new lead, enter details such as name, contact information, and status.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question: "Can I edit the information of an existing lead?",
          answer:
            "Yes, you can update details of an existing lead, such as contact information or status.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question:
            "How do I change the status of a lead (e.g., from New to In Discussion)?",
          answer:
            "To change the status of a lead, update it in the system to reflect its current stage in the sales process.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question:
            "Is there a way to categorize leads (e.g., by source or priority)?",
          answer:
            "Yes, you can categorize leads to better organize and prioritize them.",
        },
        {
          question:
            "How can I track the progress of a lead through the sales pipeline?",
          answer:
            "You can monitor the progress of leads from initial contact to conversion or closure.",
        },
        {
          question: "Can I schedule follow-up tasks for leads?",
          answer:
            "Yes, you can schedule and manage follow-up tasks or reminders for leads.",
        },
        {
          question: "Is there a reporting feature to analyze lead data?",
          answer:
            "Yes, there are reporting tools available to analyze lead data, such as conversion rates or lead source effectiveness.",
        },
        {
          question:
            "Can I export lead data for external use (e.g., in a spreadsheet)?",
          answer:
            "Yes, you can export lead data for external use, such as in a spreadsheet.",
        },
      ],
    },
    {
      subtitle: "Searching and Filtering Leads",
      questions: [
        {
          question: "How can I search for a specific lead by name?",
          answer:
            "You can use the search feature to find a lead by entering their name.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
        {
          question: "What filtering options are available for leads?",
          answer:
            "You can filter leads by date, status, or other criteria to narrow down your search.",
          url: "https://youtu.be/6bS_fHR_L5U",
        },
      ],
    },
  ],
  appointments: [
    {
      subtitle: "Overview",
      questions: [
        {
          question: "What is the purpose of the appointments module?",
          answer:
            "The appointments module helps schedule and manage client appointments.",
        },
        {
          question:
            "How does the appointments module streamline the scheduling process?",
          answer:
            "The module provides tools to easily schedule, track, and manage appointments, reducing manual effort and improving efficiency.",
        },
        {
          question:
            "What benefits does the appointments module offer to users?",
          answer:
            "Users can efficiently manage their schedules, reduce appointment conflicts, and improve client communication.",
        },
      ],
    },
    {
      subtitle: "Managing Appointments",
      questions: [
        {
          question:
            "What is the purpose of the appointments module in the web application?",
          answer:
            "The appointments module helps schedule and manage client appointments.",
        },
        {
          question:
            "How do I search for a specific appointment by client name?",
          answer:
            "To search for a specific appointment, use the search feature and enter the client's name.",
          url: "https://youtu.be/A0ORLGg-ICE",
        },
        {
          question: "How do I schedule a new appointment for a client?",
          answer:
            "To schedule a new appointment, select the client, date, and time for the appointment.",
          url: "https://youtu.be/A0ORLGg-ICE",
        },
        {
          question: "Can I edit the details of an existing appointment?",
          answer:
            "Yes, you can edit the details of an existing appointment, such as the date, time, or client information.",
        },
        {
          question: "Is there a way to cancel or reschedule an appointment?",
          answer:
            "Yes, you can cancel or reschedule an appointment and notify the client about the changes.",
        },
        {
          question:
            "How can I view all appointments scheduled within a specific date range?",
          answer:
            "To view appointments within a specific date range, use the date filter options.",
          url: "https://youtu.be/A0ORLGg-ICE",
        },
        {
          question: "Can I set up reminders for upcoming appointments?",
          answer:
            "Yes, you can set up reminders, such as email or SMS notifications, for upcoming appointments.",
          url: "https://youtu.be/A0ORLGg-ICE",
        },
        {
          question:
            "Is there a way to track attendance or no-shows for appointments?",
          answer:
            "Yes, you can track attendance or mark a client as a no-show for an appointment.",
        },
        {
          question: "Are there reporting features to analyze appointment data?",
          answer:
            "Yes, there are reporting features available to analyze appointment data.",
        },
      ],
    },
    {
      subtitle: "Downloading Appointments",
      questions: [
        {
          question: "Can I download appointment data for external use?",
          answer:
            "Yes, you can download appointment data in various formats for external use.",
          url: "https://youtu.be/A0ORLGg-ICE",
        },
      ],
    },
  ],
  employees: [
    {
      subtitle: "Adding Attendance",
      questions: [
        {
          question: "How do I add attendance for an employee?",
          answer:
            "To add attendance for an employee, go to the admin panel and navigate to the attendance section. Click on the 'Add new employee' button and select the employee's name, date, and the time slots they were present for.",
          url: "https://youtu.be/dnucFxotH_Q",
        },
        {
          question: "Can I add attendance for multiple employees at once?",
          answer:
            "Yes, you can add attendance for multiple employees at once. Use the bulk upload feature to select the employees and their attendance details.",
          url: "https://youtu.be/dnucFxotH_Q",
        },
      ],
    },
    {
      subtitle: "Viewing and Editing Attendance",
      questions: [
        {
          question: "How can I view the attendance records for all employees?",
          answer:
            "You can view the attendance records for all employees by going to the admin panel and accessing the attendance section. The system will display a list of all employees and their attendance records.",
          url: "https://youtu.be/dnucFxotH_Q",
        },
        {
          question:
            "Is it possible to edit attendance records after they have been added?",
          answer:
            "Yes, you can edit attendance records after they have been added. Go to the attendance section, find the employee's record you want to edit, and click on the 'Edit' button to make changes.",
          url: "https://youtu.be/dnucFxotH_Q",
        },
      ],
    },
    {
      subtitle: "Attendance Reports and Analysis",
      questions: [
        {
          question:
            "Are there any reports available to analyze attendance data?",
          answer:
            "Yes, there are reports available to analyze attendance data. Go to the reports section in the admin panel to generate attendance reports based on different parameters.",
          url: "https://youtu.be/dnucFxotH_Q",
        },
        {
          question: "Can I export attendance data for external use?",
          answer: "No, you cannot export.",
        },
      ],
    },
    {
      subtitle: "Permissions and Security",
      questions: [
        {
          question: "Who has access to add or edit attendance records?",
          answer:
            "Only admin users have access to add or edit attendance records. Regular employees do not have permission to modify attendance data.",
        },
        {
          question: "Is the attendance data secure and confidential?",
          answer:
            "Yes, the attendance data is secure and confidential. It can only be accessed by authorized admin users.",
        },
      ],
    },
  ],

  calculators: [
    {
      subtitle: "BMI Calculator",
      questions: [
        {
          question: "How accurate is the BMI calculator?",
          answer:
            "The BMI calculator provides a rough estimate of body mass index based on height and weight, but it may not account for individual factors such as muscle mass.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "What are the limitations of the BMI calculator?",
          answer:
            "The BMI calculator may not be suitable for athletes, pregnant women, or individuals with a high muscle mass.",
        },
        {
          question: "Can BMI be used as a definitive measure of health?",
          answer:
            "BMI is a useful tool for population-level assessments but may not provide a complete picture of individual health due to its reliance on height and weight alone.",
        },
        {
          question:
            "How can BMI categories (underweight, normal weight, overweight) affect health?",
          answer:
            "BMI categories can provide general insights into health risks associated with weight, but individual health should be assessed holistically.",
        },
        {
          question: "Is BMI the same for all ages?",
          answer:
            "BMI interpretations may vary for children, adolescents, and older adults due to differences in growth patterns and body composition.",
        },
      ],
    },
    {
      subtitle: "Calorie Counter",
      questions: [
        {
          question: "Can I use the calorie counter to track my daily intake?",
          answer:
            "Yes, the calorie counter allows you to track your daily calorie intake and monitor your nutritional goals.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question:
            "Does the calorie counter account for different serving sizes?",
          answer:
            "Yes, the calorie counter allows you to adjust serving sizes to accurately reflect your food intake.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question:
            "Is the calorie counter suitable for tracking macros (macronutrients)?",
          answer:
            "Yes, in addition to calories, the calorie counter can help you track macros such as protein, carbohydrates, and fats.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "Can the calorie counter be used to set weight loss goals?",
          answer:
            "Yes, many calorie counters allow you to set personalized goals for weight loss, maintenance, or muscle gain based on your calorie intake and expenditure.",
        },
        {
          question:
            "Are there mobile apps that integrate with the calorie counter for convenience?",
          answer:
            "Yes, several mobile apps offer calorie counters with additional features like barcode scanning, meal planning, and syncing with fitness trackers.",
        },
      ],
    },
    {
      subtitle: "Body Fat Calculator",
      questions: [
        {
          question:
            "How does the body fat calculator estimate body fat percentage?",
          answer:
            "The body fat calculator estimates body fat percentage based on measurements like height, weight, and sometimes additional factors such as waist circumference.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "What are the limitations of the body fat calculator?",
          answer:
            "Body fat calculators can vary in accuracy depending on the method used (e.g., calipers, bioelectrical impedance). Factors like hydration levels can also influence results.",
        },
        {
          question: "Can body fat percentage alone determine overall health?",
          answer:
            "Body fat percentage is one indicator of health, but other factors such as muscle mass and distribution also play a role in overall health assessment.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "How does body fat distribution affect health risks?",
          answer:
            "Body fat distribution, such as abdominal fat, can increase the risk of health conditions like heart disease and diabetes compared to fat stored in other areas.",
        },
        {
          question: "Are there methods to improve body fat percentage?",
          answer:
            "Improving body fat percentage often involves a combination of balanced nutrition, regular exercise, and adequate rest to support overall health and fitness goals.",
        },
      ],
    },
    {
      subtitle: "Basal Metabolic Rate (BMR) Calculator",
      questions: [
        {
          question: "What is BMR and why is it important?",
          answer:
            "BMR (Basal Metabolic Rate) is the amount of energy your body needs to maintain basic physiological functions at rest. It's important for understanding daily calorie needs.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "How accurate is the BMR calculator?",
          answer:
            "BMR calculators provide a rough estimate based on age, gender, weight, and height. Individual factors like muscle mass and metabolism may affect accuracy.",
        },
        {
          question: "Can BMR change over time?",
          answer:
            "Yes, factors such as age, changes in weight, and activity levels can affect your BMR over time.",
        },
        {
          question:
            "How can BMR be used to plan calorie intake for weight management?",
          answer:
            "Knowing your BMR helps you estimate how many calories you need to maintain, lose, or gain weight based on your activity level and health goals.",
        },
        {
          question: "What are some factors that influence individual BMR?",
          answer:
            "Factors like genetics, hormonal balance, and dietary habits can influence individual BMR variations among people of similar age, weight, and height.",
        },
      ],
    },
    
    {
      subtitle: "One Rep Max (1RM) Calculator",
      questions: [
        {
          question: "What is the 1RM and how is it calculated?",
          answer:
            "1RM (One Rep Max) is the maximum weight you can lift for a single repetition of a given exercise. It's calculated based on the weight lifted and the number of repetitions performed.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question: "Is the 1RM calculator suitable for beginners?",
          answer:
            "While 1RM calculators can provide guidance, beginners should start with lighter weights and gradually increase intensity under proper supervision to avoid injury.",
        },
        {
          question: "Can the 1RM calculator be used for different exercises?",
          answer:
            "Yes, there are different formulas for calculating 1RM based on the exercise performed (e.g., bench press, squat, deadlift).",
        },
        {
          question: "How often should 1RM testing be performed?",
          answer:
            "1RM testing should be done periodically to track strength progress and adjust training programs. It's essential to prioritize proper form and safety during testing.",
        },
        {
          question:
            "Are there alternative methods to estimate 1RM without testing?",
          answer:
            "Some training programs use submaximal testing or predictive formulas based on repetitions performed with lighter weights to estimate 1RM without maximal effort.",
        },
      ],
    },
    {
      subtitle: "Macro Calculator",
      questions: [
        {
          question: "What are macros and why are they important?",
          answer:
            "Macros (macronutrients) are nutrients required in large amounts by the body, including protein, carbohydrates, and fats. They provide energy and support various bodily functions.",
          url: "https://youtu.be/Xw1JgdjODIU",
        },
        {
          question:
            "How does the macro calculator determine daily macro goals?",
          answer:
            "The macro calculator determines daily macro goals based on factors such as age, gender, weight, activity level, and specific goals (e.g., weight loss, muscle gain).",
        },
        {
          question:
            "Can the macro calculator be customized for different dietary preferences?",
          answer:
            "Yes, many macro calculators allow customization for dietary preferences such as vegan, vegetarian, ketogenic, etc., to adjust macro ratios accordingly.",
        },
        {
          question:
            "What are the recommended macro ratios for different fitness goals?",
          answer:
            "Macro ratios vary based on individual goals; for example, athletes may have higher protein needs, while those focusing on weight loss may benefit from higher fiber intake.",
        },
        {
          question:
            "How does meal timing and macro distribution affect performance?",
          answer:
            "Balancing macros throughout the day, especially around workouts, can support energy levels, recovery, and performance optimization in various physical activities.",
        },
      ]
      ,
    },
  ],
};
