import React, { useState, useEffect, useCallback } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import { generatePDFDocument } from "utils/pdf";
import { TailSpin } from "react-loader-spinner";
import { dietChartRow } from "./DietRow/rows";
import SuiTypography from "components/SuiTypography";
import { getClientDietChart } from "services/dietchart";
import { toast } from "react-toastify";
import Logomain from "assets/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "store/features/authSlice";
import { getDietChartSettings } from "store/features/authSlice";
import { useSearchParams } from "react-router-dom";

function DietChart({ title, clientId, selectedClient, adminLogo }) {
  const dispatch = useDispatch();
  const { dietChartSettings } = useSelector(userSelector);

  const [uid, setUid] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathComponents = url.pathname.split("/");

    const customerId = pathComponents[2];
    setUid(customerId);
  }, []);

  useEffect(() => {
    if (uid) {
      console.log("uid", uid);
      dispatch(getDietChartSettings({ uid }));
    }
  }, [dispatch, uid]);

  const [dietchart, setDietChart] = useState([]);
  const getSelectedClientDietChart = useCallback(async () => {
    try {
      if (clientId) {
        setLoader(true);
        const dietchart_ = await getClientDietChart(clientId);
        setDietChart(dietchart_);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      toast.info("Something went wrong!!!");
    }
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      getSelectedClientDietChart();
    }
  }, [clientId, getSelectedClientDietChart]);

  const [rows, setRows] = useState([]); // rows for recipe table
  const [loader, setLoader] = useState(false);

  //for setting diet chart
  const [dietChartArrayv1, setDietChartArrayv1] = useState([]);
  useEffect(() => {
    let dietChartArrayv2 = dietchart.slice();
    setDietChartArrayv1(dietChartArrayv2);
  }, [dietchart]);

  const logo = adminLogo ?? Logomain;
  const [chartArray, setChartArray] = useState([]);

  const columns = [
    {
      name: "Download",
      align: "left",
      minWidth: "190px",
      component: (
        <SuiBox
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            opacity: 1,
          }}
        >
          {chartArray.length > 0 ? (
            <p
              style={{
                fontSize: "0.625rem",
                fontWeight: 700,
                color: "#17c1e8",
                cursor: "pointer",
              }}
              onClick={() => {
                generatePDFDocument(
                  dietChartSettings,
                  chartArray,
                  selectedClient,
                  logo,
                  setLoader,
                  1
                );
              }}
            >
              DOWNLOAD NOW
            </p>
          ) : (
            <p
              style={{
                color: "#8392ab",
                fontSize: "0.625rem",
                fontWeight: 700,
                cursor: "not-allowed",
                display: "flex",
              }}
            >
              DOWNLOAD NOW
              {loader && <TailSpin color="#17c1e8" height={20} width={20} />}
            </p>
          )}
        </SuiBox>
      ),
    },
    { name: "Date/Time (Added)", align: "left", minWidth: "200px" },
    { name: "Week Plan", align: "left", minWidth: "100px" },
    { name: "Chart Type", align: "left", minWidth: "100px" },

    { name: "Diet Date for Client", align: "left", minWidth: "200px" },
    { name: "Early Morning", align: "left", minWidth: "200px" },
    { name: "Breakfast", align: "left", minWidth: "200px" },
    { name: "Mid Morning", align: "left", minWidth: "200px" },
    { name: "Brunch", align: "left", minWidth: "200px" },
    { name: "Mid Meal", align: "left", minWidth: "200px" },
    { name: "Lunch", align: "left", minWidth: "200px" },
    { name: "Post Lunch", align: "left", minWidth: "200px" },

    { name: "Evening", align: "left", minWidth: "200px" },
    { name: "Before Dinner", align: "left", minWidth: "200px" },
    { name: "Dinner", align: "left", minWidth: "200px" },
    { name: "Post Dinner", align: "left", minWidth: "200px" },
    { name: "Late Night", align: "left", minWidth: "200px" },
    { name: "Notes", align: "left", minWidth: "200px" },
    { name: "Calories", align: "left", minWidth: "120px" },
  ];

  const selectHandler = useCallback(
    (item, value, index) => {
      let chartArr = dietChartArrayv1.slice();
      const chartObj = {
        ...item,
        selected: value,
      };

      chartArr[index] = chartObj;

      setDietChartArrayv1([...chartArr]);
    },
    [dietChartArrayv1]
  );

  useEffect(() => {
    setRows(dietChartRow(dietChartArrayv1 ?? [], setChartArray, selectHandler));
  }, [dietChartArrayv1, selectHandler]);

  return (
    <SuiBox overflow="hidden" minWidth="250px">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <SuiBox>
          <SuiTypography variant="h5" style={{ color: "#17C1E8" }}>
            {title}
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        {rows.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>

      {chartArray.length > 0 ? (
        <div
          style={{
            position: "sticky",
            marginTop: 100,
            width: "100%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "end",
            bottom: "2.6rem",
          }}
        >
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={() => {
              generatePDFDocument(
                dietChartSettings,
                chartArray,
                selectedClient,
                logo,
                setLoader,
                1
              );
            }}
          >
            Download Diet Chart
          </SuiButton>
        </div>
      ) : null}
    </SuiBox>
  );
}

export default DietChart;
