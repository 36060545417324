import React from "react";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Icon } from "@mui/material";
import ModalRoot from "containers/modal/ModalRoot";
import Grid from "@mui/material/Grid";

function DefaultModal({
  title,
  open,
  description,
  handleCloseModal,
  children,
  mode,
  setMode,
}) {
  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="60%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">{title}</SuiTypography>
          <SuiTypography variant="body2" color="text">
            {description}
          </SuiTypography>
        </SuiBox>

        <SuiBox
          style={{ whiteSpace: "normal !important" }}
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0.8}
          px={3}
        >
          <Grid item>
            {mode === "view" && (
              <Icon
                sx={({
                  typography: { size, fontWeightBold },
                  palette: { dark },
                }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                  mt: 2,
                  mr: 2,
                })}
                onClick={() => {
                  setMode("edit");
                }}
              >
                edit
              </Icon>
            )}
            {mode === "edit" && (
              <Icon
                sx={({
                  typography: { size, fontWeightBold },
                  palette: { dark },
                }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                  mt: 2,
                  mr: 2,
                })}
                onClick={() => {
                  setMode("view");
                }}
              >
                visibility
              </Icon>
            )}
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
              })}
              onClick={handleCloseModal}
            >
              close
            </Icon>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Divider />
      {children}
    </ModalRoot>
  );
}

export default DefaultModal;
