import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  arrayUnion,
  addDoc,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import moment from "moment";

//List of leads
export const getLeads = createAsyncThunk(
  "Lead/getLeads",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "lead"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // let timestamp = null;
        let updatedAt = null;
        // if (doc.data().createdAt) {
        //     let createdAt = doc.data().createdAt
        //     timestamp = createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000
        //     timestamp = moment.utc(timestamp)
        // }

        if (doc.data().updatedAt) {
          updatedAt = doc.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        data.push({
          ...doc.data(),
          id: doc.id,
          leadName: doc.id,
          updatedAt,
        });
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting lead
export const AddLead = createAsyncThunk(
  "Lead/AddLead",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "lead"),
        where("clientName", "==", formdata.clientName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message: "Lead with this name already exists, choose different name",
        });
      }

      const p = query(
        collection(firestore, "lead"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", uid)
      );
      const querySnapshot1 = await getDocs(p);
      if (!querySnapshot1.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Lead with this Phone Number already exists, choose different Phone Number",
        });
      }

      const r = query(
        collection(firestore, "lead"),
        where("email", "==", formdata.email),
        where("adminId", "==", uid)
      );
      const querySnapshot2 = await getDocs(r);
      if (!querySnapshot2.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Lead with this Email already exists, choose different Email",
        });
      }

      let leadRef;
      if (formdata.customerNotestext) {
        leadRef = await addDoc(collection(firestore, "lead"), {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          phoneNumber: formdata.phoneNumber,
          email: formdata.email,
          customerNotes: formdata.customerNotes,
          consultantNotes: [
            { date: new Date(), consultantNote: formdata.consultantNotestext },
          ],
          source: formdata.source.value,
          service: formdata.service.value,
          status: formdata.status.value,
          followUpDate: formdata.followUpDate,
          referredBy: formdata.referredBy ?? "",
          createdAt: new Date(),
          adminId: uid,
        });
      } else {
        leadRef = await addDoc(collection(firestore, "lead"), {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          phoneNumber: formdata.phoneNumber,
          email: formdata.email,
          customerNotes: formdata.customerNotes,
          source: formdata.source.value,
          service: formdata.service.value,
          status: formdata.status.value,
          followUpDate: formdata.followUpDate,
          referredBy: formdata.referredBy ?? "",
          createdAt: new Date(),
          adminId: uid,
        });
      }

      return {
        clientName: formdata.clientName,
        countryCode: formdata.countryCode,
        phoneNumber: formdata.phoneNumber,
        email: formdata.email,
        customerNotes: formdata.customerNotes,
        consultantNotes: formdata.consultantNotestext
          ? [{ date: new Date(), consultantNote: formdata.consultantNotestext }]
          : [],
        source: formdata.source.value,
        service: formdata.service.value,
        status: formdata.status.value,
        followUpDate: formdata.followUpDate,
        referredBy: formdata.referredBy ?? "",
        createdAt: new Date(),
        adminId: uid,
        id: leadRef.id,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit lead
export const EditLead = createAsyncThunk(
  "Lead/EditLead",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "lead"),
        where("clientName", "==", formdata.clientName),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot = await getDocs(q);

      let checkName = false;
      const p = query(
        collection(firestore, "lead"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot1 = await getDocs(p);

      let checkName1 = false;
      const r = query(
        collection(firestore, "lead"),
        where("email", "==", formdata.email),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot2 = await getDocs(r);

      let checkName2 = false;

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName = true;
          }
        });
        if (checkName === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Lead with this name already exists, choose different name",
          });
        }
      }
      if (!querySnapshot1.empty) {
        querySnapshot1.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName1 = true;
          }
        });
        if (checkName1 === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Lead with this Phone Number already exists, choose different Phone Number",
          });
        }
      }
      if (!querySnapshot2.empty) {
        querySnapshot2.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName2 = true;
          }
        });
        if (checkName2 === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Lead with this Email already exists, choose different Email",
          });
        }
      }
      const ref = doc(firestore, "lead", formdata.id);

      if (formdata.consultantNotestext) {
        await updateDoc(ref, {
          clientName: formdata.clientName,
          email: formdata.email,
          countryCode: formdata.countryCode,
          phoneNumber: formdata.phoneNumber,
          customerNotes: formdata.customerNotes,
          consultantNotes: arrayUnion({
            date: new Date(),
            consultantNote: formdata.consultantNotestext,
          }),
          source: formdata.source.value,
          service: formdata.service.value,
          status: formdata.status.value,
          followUpDate: formdata.followUpDate,
          referredBy: formdata.referredBy ?? "",
          updatedAt: new Date(),
        });
      } else {
        await updateDoc(ref, {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          email: formdata.email,
          phoneNumber: formdata.phoneNumber,
          customerNotes: formdata.customerNotes,
          source: formdata.source.value,
          service: formdata.service.value,
          status: formdata.status.value,
          followUpDate: formdata.followUpDate,
          referredBy: formdata.referredBy ?? "",
          updatedAt: new Date(),
        });
      }

      return {
        id: formdata.id,
        clientName: formdata.clientName,
        email: formdata.email,
        countryCode: formdata.countryCode,
        phoneNumber: formdata.phoneNumber,
        customerNotes: formdata.customerNotes,
        consultantNotes: formdata.consultantNotestext
          ? { date: new Date(), consultantNote: formdata.consultantNotestext }
          : null,
        source: formdata.source.value,
        service: formdata.service.value,
        status: formdata.status.value,
        followUpDate: formdata.followUpDate,
        referredBy: formdata.referredBy ?? "",
        updatedAt: new Date(),
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//List of appointments
export const getAppointments = createAsyncThunk(
  "Lead/getAppointments",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "appointment"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // let timestamp = null;
        // let updatedAt = null;
        // if (doc.data().createdAt) {
        //     let createdAt = doc.data().createdAt
        //     timestamp = createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000
        //     timestamp = moment.utc(timestamp)
        // }

        // if (doc.data().updatedAt) {
        //     updatedAt = doc.data().updatedAt
        //     updatedAt = updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000
        //     updatedAt = moment.utc(updatedAt)
        // }

        data.push({
          ...doc.data(),
          id: doc.id,
          leadName: doc.id,
        });
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting appointments
export const AddAppointments = createAsyncThunk(
  "Lead/AddAppointments",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      // const q = query(collection(firestore, "appointment"), where("clientName", "==", formdata.clientName), where("adminId", "==", uid));
      // const querySnapshot = await getDocs(q);
      // if (!querySnapshot.empty) {
      //     return thunkAPI.rejectWithValue({ message: "Appointment with this username already exists, choose different name" });
      // }

      // const p = query(collection(firestore, "appointment"), where("phoneNumber", "==", formdata.phoneNumber), where("adminId", "==", uid));
      // const querySnapshot1 = await getDocs(p);
      // if (!querySnapshot1.empty) {
      //     return thunkAPI.rejectWithValue({ message: "Appointment with this Phone Number already exists, choose different Phone Number" });
      // }

      // const r = query(collection(firestore, "appointment"), where("email", "==", formdata.email), where("adminId", "==", uid));
      // const querySnapshot2 = await getDocs(r);
      // if (!querySnapshot2.empty) {
      //     return thunkAPI.rejectWithValue({ message: "Appointment with this Email already exists, choose different Email" });
      // }

      let appointmentRef;
      if (formdata.customerNotestext) {
        appointmentRef = await addDoc(collection(firestore, "appointment"), {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          phoneNumber: formdata.phoneNumber,
          email: formdata.email,
          customerNotes: formdata.customerNotes,
          consultantNotes: [
            { date: new Date(), consultantNote: formdata.consultantNotestext },
          ],
          appointmentDate: new Date(formdata.appointmentDate),
          createdAt: new Date(),
          adminId: uid,
        });
      } else {
        appointmentRef = await addDoc(collection(firestore, "appointment"), {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          phoneNumber: formdata.phoneNumber,
          email: formdata.email,
          customerNotes: formdata.customerNotes,
          appointmentDate: new Date(formdata.appointmentDate),
          createdAt: new Date(),
          adminId: uid,
        });
      }

      return {
        clientName: formdata.clientName,
        countryCode: formdata.countryCode,
        phoneNumber: formdata.phoneNumber,
        email: formdata.email,
        customerNotes: formdata.customerNotes,
        consultantNotes: formdata.consultantNotestext
          ? [
              {
                date: new Date(),
                consultantNote: formdata.consultantNotestext,
              },
            ]
          : [],
        appointmentDate: new Date(formdata.appointmentDate),
        createdAt: new Date(),
        adminId: uid,
        id: appointmentRef.id,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit appointment
export const EditAppointment = createAsyncThunk(
  "Lead/EditAppointment",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      // const q = query(collection(firestore, "appointment"), where("clientName", "==", formdata.clientName), where("adminId", "==", formdata.adminId));
      // const querySnapshot = await getDocs(q);

      const p = query(
        collection(firestore, "appointment"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot1 = await getDocs(p);

      let checkName1 = false;
      const r = query(
        collection(firestore, "appointment"),
        where("email", "==", formdata.email),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot2 = await getDocs(r);

      let checkName2 = false;

      // if (!querySnapshot.empty) {
      //     querySnapshot.forEach((doc) => {
      //         if (doc.id !== formdata.id) {
      //             checkName = true
      //         }
      //     })
      //     if (checkName === true) {
      //         return thunkAPI.rejectWithValue({ message: "Appointment with this name already exists, choose different name" });
      //     }
      // }
      if (!querySnapshot1.empty) {
        querySnapshot1.forEach((doc) => {
          const docData = doc?.data();

          if (doc.id !== formdata.id && docData?.email !== formdata?.email) {
            checkName1 = true;
          }
        });
        if (checkName1 === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Appointment with this Phone Number already exists, choose different Phone Number",
          });
        }
      }
      if (!querySnapshot2.empty) {
        querySnapshot2.forEach((doc) => {
          const docData = doc?.data();
          if (
            doc.id !== formdata.id &&
            docData?.phoneNumber !== formdata?.phoneNumber
          ) {
            checkName2 = true;
          }
        });
        if (checkName2 === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Appointment with this Email already exists, choose different Email",
          });
        }
      }
      const ref = doc(firestore, "appointment", formdata.id);

      if (formdata.consultantNotestext) {
        await updateDoc(ref, {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          email: formdata.email,
          phoneNumber: formdata.phoneNumber,
          customerNotes: formdata.customerNotes,
          consultantNotes: arrayUnion({
            date: new Date(),
            consultantNote: formdata.consultantNotestext,
          }),
          appointmentDate: new Date(formdata.appointmentDate),
          updatedAt: new Date(),
        });
      } else {
        await updateDoc(ref, {
          clientName: formdata.clientName,
          countryCode: formdata.countryCode,
          email: formdata.email,
          phoneNumber: formdata.phoneNumber,
          customerNotes: formdata.customerNotes,
          appointmentDate: new Date(formdata.appointmentDate),
          updatedAt: new Date(),
        });
      }

      return {
        id: formdata.id,
        clientName: formdata.clientName,
        countryCode: formdata.countryCode,
        email: formdata.email,
        phoneNumber: formdata.phoneNumber,
        customerNotes: formdata.customerNotes,
        consultantNotes: formdata.consultantNotestext
          ? { date: new Date(), consultantNote: formdata.consultantNotestext }
          : null,
        appointmentDate: new Date(formdata.appointmentDate),
        updatedAt: new Date(),
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const LeadSlice = createSlice({
  name: "Lead",
  initialState: {
    leads: [],
    appointments: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },
  },
  extraReducers: {
    [getLeads.fulfilled]: (state, { payload }) => {
      state.leads = payload ?? [];
      state.isFetching = false;
    },
    [getLeads.pending]: (state) => {
      state.isFetching = true;
    },
    [getLeads.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditLead.fulfilled]: (state, { payload }) => {
      state.leads = state.leads.map((item) => {
        if (item.id === payload.id) {
          if (payload.consultantNotes) {
            if (item.consultantNotes) {
              item = {
                ...item,
                ...payload,
                consultantNotes: [
                  ...item.consultantNotes,
                  payload.consultantNotes,
                ],
              };
            } else {
              item = {
                ...item,
                ...payload,
                consultantNotes: [
                  item.consultantNotes,
                  payload.consultantNotes,
                ],
              };
            }
          } else {
            item = {
              ...item,
              ...payload,
              consultantNotes: item.consultantNotes,
            };
          }
        }
        return { ...item };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Lead updated successfully";
    },

    [EditLead.pending]: (state) => {
      state.isFetching = false;
    },
    [EditLead.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddLead.fulfilled]: (state, { payload }) => {
      state.leads = [payload, ...state.leads];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Lead added successfully";
    },
    [AddLead.pending]: (state) => {
      state.isFetching = false;
    },
    [AddLead.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [getAppointments.fulfilled]: (state, { payload }) => {
      state.appointments = payload ?? [];
      state.isFetching = false;
    },
    [getAppointments.pending]: (state) => {
      state.isFetching = true;
    },
    [getAppointments.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditAppointment.fulfilled]: (state, { payload }) => {
      state.appointments = state.appointments.map((item) => {
        if (item.id === payload.id) {
          if (payload.consultantNotes) {
            if (item.consultantNotes) {
              item = {
                ...item,
                ...payload,
                consultantNotes: [
                  ...item.consultantNotes,
                  payload.consultantNotes,
                ],
              };
            } else {
              item = {
                ...item,
                ...payload,
                consultantNotes: [
                  item.consultantNotes,
                  payload.consultantNotes,
                ],
              };
            }
          } else {
            item = {
              ...item,
              ...payload,
              consultantNotes: item.consultantNotes,
            };
          }
        }
        return { ...item };
      });
      state.isSuccess = true;
      state.message = "Appointments updated successfully";
    },

    [EditAppointment.pending]: (state) => {
      state.isFetching = false;
    },
    [EditAppointment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddAppointments.fulfilled]: (state, { payload }) => {
      state.appointments = [payload, ...state.appointments];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Appointments added successfully";
    },
    [AddAppointments.pending]: (state) => {
      state.isFetching = false;
    },
    [AddAppointments.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const { clearState } = LeadSlice.actions;

export const LeadSelector = (state) => state.lead;
