import React, { useState, useEffect } from 'react'

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import Table from "examples/Tables/Table";
import NoData from 'containers/DataNotAvailBox'
import { AccountRows } from './AccountRows'
import AccountStat from './AccountStat'


function AccountHistory({ history, title, isSuccess, isFetching, 
    editHandler, viewHandler}) {

    const [ rows, setRows ] = useState([]) // rows for client table

    const columns = [
        { name: "Start Date", align: "left", minWidth: '150px'},
        { name: "Completion Date", align: "left", minWidth: '150px' },
        { name: "Service Taken", align: "left", minWidth: '155px'},
        // { name: "Total Amount", align: "left", minWidth: '160px'},
        // { name: "Amount Paid", align: "left", minWidth: '120px' },
        // { name: "Discount", align: "left",  minWidth: '120px' },
        // { name: "Balance", align: "left"},
        // { name: "Service Instance", align: "left", minWidth: '180px' },
        { name: "Action", align: "left" ,  minWidth: '100px'},
    ]

    useEffect(()=>{
        setRows(AccountRows(history, editHandler, viewHandler))
    },[ history, editHandler, viewHandler])

    return (
            <SuiBox mb={3} style={{ boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.05)'}}>
                <SuiBox className="search-div" display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" px={3} pt={3} pb={1}>
                    <SuiTypography variant="h6">{ title }</SuiTypography>
                    <SuiBox
                        className="search-box-div"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: "wrap",
                            justifyContent: 'flex-end' }}>
                    </SuiBox>

                </SuiBox>
                <AccountStat accounts={history}/>
                <SuiBox
                    sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                    }}>
                    {rows.length?
                        <Table columns={columns} rows={rows} maxRows={2}/>:
                        <NoData/>
                    }
                </SuiBox>
            </SuiBox>
    )
}

export default AccountHistory