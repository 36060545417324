import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";
import { faqData } from "assets/data/faq";
import { IntlPhoneNumber } from "assets/data/intlPhoneNumber";
import { units } from "assets/data/units";
import { gstTreatmentsList } from "assets/data/gstTreatments";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_ID,
};

// Initialize Firebase
let app;
export let firestore;
export let auth;
export let storage;
try {
  app = initializeApp(firebaseConfig);
  firestore = getFirestore(app);
  auth = getAuth(app);
  storage = getStorage(app);
} catch (err) {
  console.log(err);
}

const mealCategories = [
  "earlyMorning",
  "breakfast",
  "midMorning",
  "brunch",
  "meadMeal",
  "lunch",
  "postLunch",
  "evening",
  "beforeDinner",
  "dinner",
  "postDinner",
  "lateNight",
];

const uploadFaqData = () => {
  Object.keys(faqData).forEach(async (section) => {
    try {
      const faqRef = doc(firestore, "faq", section);

      await setDoc(faqRef, { subtitles: faqData[section], title: section });
      console.log("FAQ data's uploaded successfully!");
    } catch (error) {
      console.error("Error uploading FAQ data: ", error);
    }
  });
};

// uploadFaqData();

const uploadintlPhoneNumber = async () => {
  try {
    const intlPhone = doc(firestore, "intlPhoneNumber", "intlPhoneNumber");
    await setDoc(intlPhone, { data: IntlPhoneNumber });

    console.log("International phone number data uploaded successfully!");
  } catch (error) {
    console.error("Error uploading International phone number data: ", error);
  }
};
const uploadUnits = async () => {
  try {
    const unitRef = doc(firestore, "units", "units");
    await setDoc(unitRef, { data: units });

    console.log("units data uploaded successfully!");
  } catch (error) {
    console.error("units uploading error: ", error);
  }
};
// uploadUnits();
const uploadGstTreatments = async () => {
  try {
    const treatmentRef = doc(firestore, "gstTreatments", "gstTreatments");
    await setDoc(treatmentRef, { data: gstTreatmentsList });

    console.log("gstTreatmentList data uploaded successfully!");
  } catch (error) {
    console.error("gstTreatmentList uploading error: ", error);
  }
};
// uploadGstTreatments();

// the nutrition value will be converted into ["carbon-34", 'fat-56' ]
const dietPlanCollectionRef = collection(firestore, "2937234");
const recipeCollectionRef = collection(firestore, "rec234234ipe");

const dietplanNutritionSchemaArray = async (docId) => {
  try {
    const docRef = doc(dietPlanCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      let data = docSnapshot.data();

      // Iterate through meals
      for (const meal of mealCategories) {
        // Check if the meal exists in the data
        if (data[meal]) {
          for (const item of data[meal]) {
            // Check if the current item has 'nutritions' key
            if (item && "nutritions" in item) {
              // Check if 'nutritions' is an array
              if (Array.isArray(item["nutritions"])) {
                // Map the 'nutritions' array to a new structure
                item["nutritions"] = item["nutritions"].map((nutrition) => {
                  return `${nutrition["type"]} - ${nutrition["grams"]}`;
                  // Create a string in the format 'type-grams'

                  // Split using regex to handle variations
                  // const parts = nutrition?.split(/[:\-]+/);

                  // Filter out empty strings from the split
                  // const filteredParts = parts.filter(
                  //   (part) => part.trim() !== ""
                  // );

                  // if (filteredParts.length === 2) {
                  //   const nutrientType = filteredParts[0];
                  //   const grams = filteredParts[1];

                  //   return {
                  //     type: nutrientType,
                  //     grams: grams,
                  //   };
                  // } else if (parts.length === 1) {
                  //   const parts = nutrition?.split(" ");

                  //   const filteredParts = parts.filter(
                  //     (part) => part.trim() !== ""
                  //   );

                  //   const nutrientType = filteredParts[0];
                  //   const grams = filteredParts[1];

                  //   return {
                  //     type: nutrientType,
                  //     grams: grams,
                  //   };
                  // }

                  // else {
                  //   // Handle cases where the format doesn't match
                  //   console.error(`Unable to parse: ${nutrition}`);
                  //   return "Nil";
                  // }
                });
              }
            }
          }
        }
      }
      console.log("prod_data", data);

      // Update the document in Firestore collections
      await updateDoc(docRef, data);
      console.log(`Document ${docId} updated successfully.`);
    }

    console.log(`Document ${docId} updated successfully.`);
  } catch (error) {
    console.error(`Error updating document ${docId}:`, error);
  }
};

const dietplanNutritionSchemaArrayObj = async (docId) => {
  try {
    const docRef = doc(dietPlanCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      console.log(data);

      // Iterate through meals

      for (const meal of mealCategories) {
        // Check if the meal exists in the data
        if (data[meal]) {
          for (const item of data[meal]) {
            // Check if the current item has 'nutritions' key
            if (item && "nutritions" in item) {
              // Check if 'nutritions' is an array
              if (Array.isArray(item["nutritions"])) {
                // Map the 'nutritions' array to a new structure

                item["nutritions"] = item["nutritions"].map((nutrition) => {
                  // Create a string in the format 'type-grams'

                  if (nutrition.includes("-") && nutrition.includes(":")) {
                    // console.log("The string includes both '-' and ':'");
                    const parts = nutrition.split("-");
                    const key = parts[1].split(":")[0].trim();
                    const value = parseFloat(parts[1].split(":")[1].trim());

                    return {
                      type: key,
                      grams: parseFloat(value),
                    };
                  } else {
                    // console.log("singleNutrition", nutrition);
                    // Split using regex to handle variations
                    const parts = nutrition?.split(/[:\-]+/);

                    // Filter out empty strings from the split
                    const filteredParts = parts.filter(
                      (part) => part.trim() !== ""
                    );

                    if (filteredParts.length === 2) {
                      const nutrientType = filteredParts[0];
                      const grams = filteredParts[1];

                      return {
                        type: nutrientType,
                        grams: parseFloat(grams),
                      };
                    } else if (parts.length === 1) {
                      const parts = nutrition?.split(" ");

                      const filteredParts = parts.filter(
                        (part) => part.trim() !== ""
                      );

                      const nutrientType = filteredParts[0];
                      const grams = filteredParts[1];

                      return {
                        type: nutrientType,
                        grams: parseFloat(grams),
                      };
                    } else {
                      // Handle cases where the format doesn't match
                      console.error(
                        `Unable to parse: ${nutrition} docID${docId}`
                      );
                      return "Nil";
                    }
                  }
                });
              }
            }
          }
        }
      }

      await updateDoc(docRef, data);
      console.log("data", data);
      console.log(`Document ${docId} updated successfully.`);
    } else {
      console.log(`Document ${docId} does not exist.`);
    }
  } catch (error) {
    console.error(`Error updating document ${docId}:`, error);
  }
};

const recipeNutritionSchemaChange = async (docId) => {
  try {
    const docRef = doc(recipeCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();

      const updatedNutrition = data?.nutritions?.map((nutrition) => {
        // Create a string in the format 'type-grams'

        const match = /^(\D+)(\d+)$/.exec(nutrition);
        const parts = nutrition?.split(/[:\-]+/);

        if (nutrition.includes("-") || nutrition.includes(":")) {
          // Filter out empty strings from the split
          const filteredParts = parts.filter((part) => part.trim() !== "");

          if (filteredParts.length === 2) {
            const nutrientType = filteredParts[0]?.trim();
            const grams = filteredParts[1];

            return {
              type: nutrientType,
              grams: parseFloat(grams),
            };
          }
        } else if (match) {
          return {
            type: match[1]?.trim(),
            grams: parseFloat(match[2]),
          };
        } else {
          return {
            type: "N/A",
            grams: "N/A",
          };
        }
      });
      const updatedData = {
        ...data,
        nutritions: updatedNutrition,
      };
      console.log("updatedData", updatedData);
      await updateDoc(docRef, updatedData);
    }
  } catch (error) {
    console.error(`Error updating document ${docId}:`, error);
  }
};
// recipeNutritionSchemaChange("31ddSPZcmoQCjTcDaiFH");

// dietplanNutritionSchemaArrayObj("039rmjHRlM0NjlSF2qtr");
// dietplanNutritionSchemaArray("01cYwp8djXK3GDhlHndc");

const updateAllCollection = async () => {
  try {
    const dietplanRef = await getDocs(dietPlanCollectionRef);
    const recipeRef = await getDocs(recipeCollectionRef);

    recipeRef.forEach((doc) => {
      const docId = doc.id;
      console.log("docId", docId);
      // dietplanNutritionSchemaArray(docId);
      // dietplanNutritionSchemaArrayObj(docId);
      recipeNutritionSchemaChange(docId);
    });

    console.log("All documents updated successfully.");
  } catch (error) {
    console.error("Error updating documents:", error);
  }
};

// updateAllCollection();

const functions = getFunctions();
export const sendWishes = httpsCallable(functions, "sendWishes");
export const sendDietPdf = httpsCallable(functions, "sendDietPDF");
export const sendInvoicePdf = httpsCallable(functions, "sendInvoicePdf");
export const contactSupport = httpsCallable(functions, "contactSupport");
export const sendUpdateLinkByEmail = httpsCallable(
  functions,
  "sendUpdateLinkByEmail"
);
export const sendWelcomeEmailToEmployee = httpsCallable(
  functions,
  "sendWelcomeEmailToEmployee"
);
export const sendWelcomeEmailToCustomer = httpsCallable(
  functions,
  "sendWelcomeEmailToCustomer"
);
export const sendWelcomeEmailToAdmin = httpsCallable(
  functions,
  "sendWelcomeEmailToAdmin"
);
export const deleteEmployeeAuth = httpsCallable(
  functions,
  "deleteEmployeeAuth"
);
export const get_hsn_sac = httpsCallable(functions, "get_hsn_sac");

//for uploading files
export const uploadFile = async (file, path, metadata) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  const snapshot = await uploadBytes(storageRef, file, metadata);
  return await getDownloadURL(snapshot.ref);
};

export const uploadInvoicePdf = async (file, name) => {
  const storageRef = ref(storage, `invoices/${name}`);
  const snapshot = await uploadBytes(storageRef, file);
  return await getDownloadURL(snapshot.ref);
};

export const downloadFileFromStorage = (path, fileName) => {
  getDownloadURL(ref(storage, path))
    .then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        const link = document.createElement("a");
        const blobUrl = URL.createObjectURL(xhr.response);
        link.download = fileName;
        link.href = blobUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      };
      xhr.open("GET", url);
      xhr.send();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const downloadFile = (file) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {};
    xhr.open("GET", file);
    xhr.send();
  } catch (err) {}
};

//for stats of last and current month
export const monthWiseClientsCount = async (adminId) => {
  let lastMonthCount = 0;
  let currentMonthCount = 0;
  let lastMonthServiceSaleCount = 0;
  let currentMonthServiceSaleCount = 0;
  let lastMonthProductSaleCount = 0;
  let currentMonthProductSaleCount = 0;

  try {
    let today = new Date();
    let lastMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    let lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);

    let currentMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );

    const lastMonth = query(
      collection(firestore, "client"),
      where("adminId", "==", adminId)
    );

    const lastMonthSnapShots = await getDocs(lastMonth);
    lastMonthSnapShots.forEach((doc) => {
      let data = doc.data();
      if (data.status && data.status === "Active" && data.services) {
        if (data.createdAt) {
          let timestamp = data.createdAt;
          timestamp =
            timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000;
          if (
            lastMonthStartDate <= new Date(timestamp) &&
            lastMonthEndDate >= new Date(timestamp)
          ) {
            lastMonthCount++;
          }
          if (currentMonthStartDate <= new Date(timestamp)) {
            currentMonthCount++;
          }
        }
      }

      if (data?.services) {
        data.services?.map((item) => {
          if (item.startDate) {
            let timestamp = new Date(item.startDate);
            if (
              lastMonthStartDate <= new Date(timestamp) &&
              lastMonthEndDate >= new Date(timestamp)
            ) {
              lastMonthServiceSaleCount++;
            }
            if (currentMonthStartDate < new Date(timestamp)) {
              currentMonthServiceSaleCount++;
            }
          }
          return item;
        });
      }

      if (data?.products) {
        data.products?.map((item, index) => {
          if (item.purchasedAt) {
            let timestamp = item?.purchasedAt.seconds
              ? item?.purchasedAt.nanoseconds / 1000000 +
                item?.purchasedAt.seconds * 1000
              : item?.purchasedAt;

            if (
              lastMonthStartDate <= new Date(timestamp) &&
              lastMonthEndDate >= new Date(timestamp)
            ) {
              lastMonthProductSaleCount += item.quantity;
            }
            if (currentMonthStartDate < new Date(timestamp)) {
              currentMonthProductSaleCount += item.quantity;
            }
          }
          return item;
        });
      }
    });

    return {
      lastMonthClients: lastMonthCount,
      currentMonthClients: currentMonthCount,
      lastMonthServiceSaleCount,
      currentMonthServiceSaleCount,
      currentMonthProductSaleCount,
      lastMonthProductSaleCount,
    };
  } catch (err) {
    return {
      lastMonthClients: 0,
      currentMonthClients: 0,
      lastMonthServiceSaleCount,
      currentMonthServiceSaleCount,
      currentMonthProductSaleCount,
      lastMonthProductSaleCount,
    };
  }
};
