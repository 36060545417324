import { G } from "@react-pdf/renderer";
import { preferenceOptions } from "constant";
import { serviceInstanceOptions } from "constant";
import { excelAndDbClientFieldsMapping } from "constant";
import { paymentOptions } from "constant";
import { leadOptions } from "constant";
import Joi from "joi";
import moment from "moment";
import { getUserLink } from "utils/helper";

function ExcelDateToJSDate(excelDate) {
  if (typeof excelDate === "number") {
    // Convert Excel date to JavaScript Date object
    return new Date(Math.round((excelDate - 25569) * 86400 * 1000));
  } else {
    // Assuming dob is in the format "MM/DD/YYYY"
    const dobParts = excelDate?.split("/");

    // Construct a Date object from the date string in the format "MM/DD/YYYY"
    const dob = new Date(`${dobParts[0]}/${dobParts[1]}/${dobParts[2]}`); // Date format: "MM/DD/YYYY"

    // Unix epoch (January 1, 1970) in "MM/DD/YYYY" format
    const epoch = new Date("01/01/1970"); // Date format: "MM/DD/YYYY"

    // Calculate the difference in days between dob and epoch
    const millisecondsDiff = dob.getTime() - epoch.getTime();
    const daysDiff = millisecondsDiff / (1000 * 60 * 60 * 24);

    // Add daysDiff to the anniversary date (36803 is the number of days between the Unix epoch and the reference anniversary date)
    const anniversaryDate = new Date(epoch);
    anniversaryDate.setDate(epoch.getDate() + daysDiff);

    // Return the anniversaryDate as a Date object
    return anniversaryDate;
  }
}

const mapServicesToObject = (services) => {
  const serviceObject = {};
  services.forEach((item) => {
    serviceObject[item.label.toLowerCase()] = {
      id: item.id,
      name: item.value.toLowerCase(),
      totalAmount: item.totalAmount,
    };
  });
  return serviceObject;
};

const mapClientsToObject = (clients, dbPhoneNumberObject, dbEmailObject) => {
  const clientObject = {};
  clients.forEach((item) => {
    const key = `${item.name}_${item.phoneNumber}`.toLowerCase();
    clientObject[key] = true;
    dbPhoneNumberObject[item.phoneNumber] = true;
    dbEmailObject[item.email] = true;
  });
  return clientObject;
};

const phoneRegex = /^[0-9]{10}$/;

const clientSchema = Joi.object({
  clientName: Joi.string().required().min(2).max(100).label("Client Name"),
  familyName: Joi.string().required().min(2).max(100).label("Family Name"),
  phoneNumber: Joi.number().required().label("Phone Number"),
  email: Joi.string().email({ tlds: false }).label("Email"),
  address: Joi.string().max(255).label("Address"),
  gender: Joi.string()
    .valid("Male", "Female", "Other")
    .label("Gender")
    .insensitive(),
  occupation: Joi.string().max(100).label("Occupation"),
  leadFrom: Joi.string()
    .valid(...leadOptions.map((item) => item.value))
    .label("Lead From")
    .insensitive(),
  preference: Joi.string()
    .valid(...preferenceOptions.map((item) => item.value))
    .label("Preference")
    .insensitive(),
  referredBy: Joi.string().max(100).label("Referred By"),
  discount: Joi.number().integer().min(0).label("Discount"),
  amountPaid: Joi.number().min(0).label("Amount Paid"),
  serviceInstance: Joi.string()
    .valid(...serviceInstanceOptions.map((item) => item.value.toLowerCase()))
    .label("Service Instance"),
  paymentMode: Joi.string()
    .valid(...paymentOptions.map((item) => item.value))
    .label("Payment Mode")
    .insensitive(),

  dob: Joi.date().required().label("Date of Birth"),
  anniversary: Joi.date().required().label("Anniversary"),
  startDate: Joi.date().required().label("Start Date"),
  completionDate: Joi.date().required().label("Completion Date"),
})
  .messages({
    "string.base": "{{#label}} must be a string",
    "string.empty": "{{#label}} cannot be empty",
    "string.min": "{{#label}} should have at least {{#limit}} characters",
    "string.max": "{{#label}} should not exceed {{#limit}} characters",
    "string.email": "{{#label}} must be a valid email address",
    "string.pattern.base": "{{#label}} must be a 10-digit number",
    "number.base": "{{#label}} must be a number",
    "number.integer": "{{#label}} must be an integer",
    "number.min": "{{#label}} should not be less than {{#limit}}",
    "date.format": "{{#label}} must be in the format MM/DD/YYYY",
    "date.base": "{{#label}} must be a valid MM/DD/YYYY format",
    "any.only": "{{#label}} is not a valid value",
  })
  .unknown();

// Define Joi schema for client data validation
export const validateClient = async (
  dataArray,
  clientArray,
  serviceOptions,
  adminId
) => {
  const extractedData = [];
  const errors = [];
  const dbPhoneNumberObject = {};
  const dbEmailObject = {};

  const serviceObject = mapServicesToObject(serviceOptions);
  const clientObject = mapClientsToObject(
    clientArray,
    dbPhoneNumberObject,
    dbEmailObject
  );

  let customerCount = 0;
  clientArray.forEach((doc) => {
    let cusId = doc.customerId;
    if (cusId) {
      if (cusId > customerCount) {
        customerCount = cusId;
      }
    }
  });

  for (let i = 1; i < dataArray.length; i++) {
    const dataRow = dataArray[i];
    const clientData = {
      createdAt: new Date(),
      adminId,
      customerId: customerCount + i,
      step: [1, 1, 1],
    };

    const excelClientObject = {};
    const phoneNumberObject = {};
    const emailObject = {};
    for (const mapping of excelAndDbClientFieldsMapping) {
      const excelHeader = mapping.excelName;
      const columnIndex = dataArray[0].indexOf(excelHeader);
      if (columnIndex !== -1) {
        clientData[mapping.dbField] = dataRow[columnIndex];
      }
    }

    try {
      // Validate client data
      await clientSchema.validateAsync(clientData, {
        abortEarly: false,
        stripUnknown: false,
        convert: true,
      });

      clientData.dob = moment(ExcelDateToJSDate(clientData.dob)).format(
        "MM/DD/YYYY"
      );
      clientData.anniversary = moment(
        ExcelDateToJSDate(clientData.anniversary)
      ).format("MM/DD/YYYY");
      clientData.startDate = moment(
        ExcelDateToJSDate(clientData.startDate)
      ).format("MM/DD/YYYY");
      clientData.completionDate = moment(
        ExcelDateToJSDate(clientData.completionDate)
      ).format("MM/DD/YYYY");

      // dob validation
      const dob = moment(clientData.dob, "MM/DD/YYYY");
      const isDobValid = dob.isValid();
      if (!isDobValid) {
        errors.push({
          row: i + 1,
          field: "Date of Birth",
          message: `Invalid date of birth, ${clientData.dob} is not a valid date.`,
        });
      }
      // dob must be in past
      else {
        const isDobInPast = dob.isBefore(moment());
        if (!isDobInPast) {
          errors.push({
            row: i + 1,
            field: "Date of Birth",
            message: `Invalid date of birth, ${clientData.dob} should be in past.`,
          });
        } else {
          clientData.age = moment().diff(dob, "years");
        }
      }

      // anniversary validation
      const anniversary = moment(clientData.anniversary, "MM/DD/YYYY");
      const isAnniversaryValid = anniversary.isValid();
      if (!isAnniversaryValid) {
        errors.push({
          row: i + 1,
          field: "Anniversary",
          message: `Invalid anniversary, ${clientData.anniversary} is not a valid date.`,
        });
      }

      // start date validation
      const startDate = moment(clientData.startDate, "MM/DD/YYYY");
      const isStartDateValid = startDate.isValid();
      if (!isStartDateValid) {
        errors.push({
          row: i + 1,
          field: "Start Date",
          message: `Invalid start date, ${clientData.startDate} is not a valid date.`,
        });
      }

      // completion date validation
      const completionDate = moment(clientData.completionDate, "MM/DD/YYYY");
      const isCompletionDateValid = completionDate.isValid();
      if (!isCompletionDateValid) {
        errors.push({
          row: i + 1,
          field: "Completion Date",
          message: `Invalid completion date, ${clientData.completionDate} is not a valid date.`,
        });
      }

      // completion date must be greater than start Date
      if (isStartDateValid && isCompletionDateValid) {
        const isCompletionDateGreater = completionDate.isAfter(startDate);
        if (!isCompletionDateGreater) {
          errors.push({
            row: i + 1,
            field: "Completion Date",
            message: `Invalid completion date, ${clientData.completionDate} should be greater than start date.`,
          });
        }
      }

      // Check for duplicate client (name & phone number)
      const isDuplicate =
        excelClientObject[
          `${clientData.clientName}_${clientData.phoneNumber}`?.toLowerCase()
        ];

      if (isDuplicate) {
        errors.push({
          row: i + 1,
          field: "Client Name / Phone Number",
          message: `${clientData.clientName}/${clientData.phoneNumber}, Duplicate client (name & phone number) is not allowed.`,
        });
      } else {
        // Check against existing client array
        const existingClient =
          clientObject[
            `${clientData.clientName}_${clientData.phoneNumber}`?.toLowerCase()
          ];

        if (existingClient) {
          errors.push({
            row: i + 1,
            field: "Client Name / Phone Number",
            message: `${clientData.clientName}/${clientData.phoneNumber}, Already (name & phone number) exists.`,
          });
        }

        const isPackageValid = serviceObject.hasOwnProperty(
          clientData.package.toLowerCase()
        );

        if (!isPackageValid) {
          errors.push({
            row: i + 1,
            field: "Package",
            message: `Invalid package, ${clientData.package} does not exist.`,
          });
        }

        const isPhoneNumberValid = phoneRegex.test(clientData.phoneNumber);

        if (!isPhoneNumberValid) {
          errors.push({
            row: i + 1,
            field: "Phone Number",
            message: `Invalid phone number, ${clientData.phoneNumber} does not exist.`,
          });
        }

        const isPhoneNunberDuplicate =
          phoneNumberObject[clientData.phoneNumber];

        if (isPhoneNunberDuplicate) {
          errors.push({
            row: i + 1,
            field: "Phone Number",
            message: `Duplicate phone number, ${clientData.phoneNumber} already exists in your excel.`,
          });
        }

        const isDbPhoneNumberDuplicate =
          dbPhoneNumberObject[clientData.phoneNumber];

        if (isDbPhoneNumberDuplicate) {
          errors.push({
            row: i + 1,
            field: "Phone Number",
            message: `Duplicate phone number, ${clientData.phoneNumber} already exists in our database.`,
          });
        }

        const isEmailDuplicate = dbEmailObject[clientData.email];

        if (isEmailDuplicate) {
          errors.push({
            row: i + 1,
            field: "Email",
            message: `Duplicate email, ${clientData.email} already exists in our database.`,
          });
        }

        const isEmailDuplicateInExcel = emailObject[clientData.email];

        if (isEmailDuplicateInExcel) {
          errors.push({
            row: i + 1,
            field: "Email",
            message: `Duplicate email, ${clientData.email} already exists in your excel.`,
          });
        }

        if (isPackageValid) {
          const servicePackage =
            serviceObject[clientData.package.toLowerCase()];
          const totalAmount = servicePackage.totalAmount;
          const discount = clientData.discount;
          const amountPaid = clientData.amountPaid;

          const isDiscountValid = discount >= 0 && discount <= totalAmount;

          if (!isDiscountValid) {
            errors.push({
              row: i + 1,
              field: "Discount",
              message: `Invalid discount, ${discount} should be between 0 and ${totalAmount}.`,
            });
          } else {
            const isAmounPaidValid =
              amountPaid >= 0 && amountPaid <= totalAmount - discount;
            if (!isAmounPaidValid) {
              errors.push({
                row: i + 1,
                field: "Amount Paid",
                message: `Invalid amount paid, ${amountPaid} should be between 0 and ${
                  totalAmount - discount
                }.`,
              });
            }
          }
        }

        extractedData.push({
          adminId: clientData.adminId,
          createdAt: clientData.createdAt,
          customerId: clientData.customerId,
          name: clientData.clientName,
          familyName: clientData.familyName,
          phoneNumber: clientData.phoneNumber,
          email: clientData.email,
          dob: clientData.dob,
          gender: clientData.gender,
          occupation: clientData.occupation,
          status: "Active",
          anniversary: clientData.anniversary,
          address: clientData.address,
          leadFrom: clientData.leadFrom,
          preference: clientData.preference,
          referredBy: clientData.referredBy,
          age: clientData?.age,
          mailLink: getUserLink(clientData?.adminId, clientData.customerId),
          services: [
            {
              purchasedAt: clientData.createdAt,
              serviceId: serviceObject[clientData.package.toLowerCase()].id,
              totalAmount:
                serviceObject[clientData.package.toLowerCase()].totalAmount,
              discount: clientData.discount,
              amountPaid: clientData.amountPaid,
              serviceInstance: clientData.serviceInstance,
              serviceName: clientData.package,
              paymentMode: clientData.paymentMode,
              startDate: clientData.startDate,
              completionDate: clientData.completionDate,
              id: 1,
            },
          ],
        });
      }
    } catch (validationError) {
      if (validationError.details) {
        const rowErrors = validationError.details.map((error) => ({
          row: i + 1,
          field: error.context.label,
          message: error.message,
        }));
        errors.push(...rowErrors);
      }
    }
    excelClientObject[
      `${clientData.clientName}_${clientData.phoneNumber}`?.toLowerCase()
    ] = true;

    phoneNumberObject[clientData.phoneNumber] = true;
    emailObject[clientData.email] = true;
  }
  return { extractedData, errors };
};
