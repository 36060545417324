import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePhone,
  timestampConversion,
} from "utils/common";
import moment from "moment";
import CustomButton from "containers/CustomButton";
import { EditAppointment, AddAppointments } from "store/features/leads";
import SCreateable from "containers/SelectCreateable";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";
import Createable from "containers/Select";

function Modal({
  mode,
  open,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  uid,
  isFetching,
  isSuccess,
  isError,
  message,
  appointmentArray,
  editHandler,
  viewHandler,
  intlPhoneNumberArray,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientName: null,
      countryCode: {
        label: "(+91)     India",
        value: "+91",
        key: "IN",
        phoneLength: 10,
      },
      phoneNumber: null,
      email: null,
      customerNotes: null,
      consultantNotes: null,
      consultantNotestext: null,
      appointmentDate: new Date(),
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      email: false,
      phoneNumber: false,
      customerNotes: false,
      consultantNotes: false,
      consultantNotestext: false,
      appointmentDate: false,
    };
  }, []);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const appointments = appointmentArray.map((item, index) => {
      return {
        key: index,
        value: item.clientName,

        label:
          item.clientName +
          `${
            item.countryCode
              ? ` (${item.countryCode.value} ${item.phoneNumber})`
              : ` (+91 ${item.phoneNumber})`
          }`,
        ...item,
      };
    });

    setAppointmentList(appointments);
  }, [appointmentArray]);

  //for date format
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  //for handling selected items
  useEffect(() => {
    if (selectedItem && open) {
      setSelectedClient({
        key: selectedItem?.key,
        value: selectedItem?.clientName,
        label:
          selectedItem.clientName +
          `${
            selectedItem.countryCode
              ? ` (${selectedItem.countryCode.value} ${selectedItem.phoneNumber})`
              : ` (+91 ${selectedItem.phoneNumber})`
          }`,
      });
      let consultantArray = selectedItem?.consultantNotes?.slice()?.reverse();
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        consultantNotes: consultantArray ?? [],
        appointmentDate: selectedItem?.appointmentDate?.seconds
          ? timestampConversion(selectedItem?.appointmentDate)._d
          : selectedItem?.appointmentDate,
        countryCode: selectedItem?.countryCode ?? {
          label: "(+91)     India",
          value: "+91",
          key: "IN",
          phoneLength: 10,
        },
      });
    }
    if (mode === "create") {
      setSelectedClient(null);
    }
  }, [selectedItem, defaultHandler, open]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.clientName) {
      toast.error("Please enter a Client Name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (
      !formdata.customerNotes ||
      formdata.customerNotes === "" ||
      formdata.customerNotes?.length === 0
    ) {
      toast.error("Please fill Customer Notes field");
      setErrorMessage({
        ...errorMessage,
        customerNotes: true,
      });
      return;
    }
    if (
      !formdata.consultantNotestext ||
      formdata.customerNotes === "" ||
      formdata.customerNotes?.length === 0
    ) {
      toast.error("Please fill Consultant Notes field");
      setErrorMessage({
        ...errorMessage,
        consultantNotestext: true,
      });
      return;
    }
    if (!formdata.appointmentDate) {
      toast.error("Please select Next Follow Up Date field");
      setErrorMessage({
        ...errorMessage,
        appointmentDate: true,
      });
      return;
    }

    setLoader(true);
    try {
      if (selectedItem) {
        await dispatch(EditAppointment({ formdata, uid }));
        setLoader(false);
        return;
      }
      await dispatch(AddAppointments({ formdata, uid }));
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setTimeout(() => {
        setSelectedClient(null);
        if (selectedItem) {
          setSelectedItem(null);
        }
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    setSelectedItem,
    selectedItem,
    defaultHandler,
    message,
    setLoader,
  ]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="70%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Appointment"
          subtitle={`Appointments sheduled for ${updateFirstLetter(
            formdata?.clientName
          )}`}
        />
        <Grid item>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                editHandler(selectedItem);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setFormdata(defaultHandler);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />
      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Name
                </SuiTypography>
                <SCreateable
                  options={appointmentList ?? []}
                  error={errorMessage?.clientName}
                  value={selectedClient}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose client"
                  name="clientName"
                  onChange={(e) => {
                    setSelectedClient(e);
                    setErrorMessage({
                      ...errorMessage,
                      [e?.target?.name]: false,
                    });

                    if (e?.__isNew__) {
                      setFormdata({
                        ...formdata,
                        clientName: e?.clientName ?? e?.value,
                        phoneNumber: null,
                        email: null,
                        clientId: null,
                      });
                    } else {
                      setFormdata({
                        ...formdata,
                        clientId: "selected",
                        clientName: e?.clientName,
                        phoneNumber: e?.phoneNumber,
                        email: e?.email,
                        countryCode: e?.countryCode
                          ? e.countryCode
                          : {
                              label: "(+91)     India",
                              value: "+91",
                              key: "IN",
                              phoneLength: 10,
                            },
                      });
                    }
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Country Code
                </SuiTypography>
                <Createable
                  disabled={formdata?.clientId !== null || mode === "view"}
                  options={intlPhoneNumberArray}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.900rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      countryCode: e,
                      phoneNumber: null,
                    });
                  }}
                  value={formdata.countryCode}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Phone Number
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  readOnly={formdata?.clientId !== null || mode === "view"}
                  name="phoneNumber"
                  error={errorMessage?.phoneNumber}
                  value={formdata.phoneNumber ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (
                      formdata.countryCode.key === "IN"
                        ? val.length > 10
                        : val.length > formdata.countryCode.phoneLength
                    ) {
                      toast.info("Invalid phone number");
                      return;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mt={3} mb={1} variant="h6">
                  Email
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="email"
                  name="email"
                  readOnly={formdata?.clientId !== null || mode === "view"}
                  error={errorMessage?.email}
                  value={formdata.email ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Customer Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="customerNotes"
                  multiline
                  rows={6}
                  readOnly={mode === "view"}
                  error={errorMessage?.customerNotes}
                  value={formdata.customerNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            {mode !== "view" && (
              <Grid item xs={12} lg={4}>
                <SuiBox mt={3} lineHeight={1}>
                  <SuiTypography mb={1} variant="h6">
                    Consultant Notes
                  </SuiTypography>
                  <SuiInput
                    disabled={mode === "view"}
                    type="textbox"
                    name="consultantNotestext"
                    rows={6}
                    multiline
                    readOnly={mode === "view"}
                    error={errorMessage?.consultantNotestext}
                    value={formdata.consultantNotestext ?? ""}
                    onChange={(e) => {
                      setErrorMessage({
                        ...errorMessage,
                        [e.target.name]: false,
                      });
                      setFormdata({
                        ...formdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <SuiTypography
                    mb={1}
                    variant="h6"
                    style={{
                      fontStyle: "italic",
                      color: "#67748E",
                      fontWeight: 400,
                      marginTop: 3,
                      textAlign: "right",
                    }}
                  >
                    {" "}
                    {new Date().toLocaleDateString("en-US", options)}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            )}
          </Grid>
        </SuiBox>

        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Appointment Date/Time
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="datetime-local"
                  name="appointmentDate"
                  placeholder=""
                  readOnly={mode === "view"}
                  error={errorMessage?.appointmentDate}
                  style={{ paddingRight: "1px" }}
                  value={
                    formdata.appointmentDate
                      ? moment(formdata.appointmentDate).format(
                          "YYYY-MM-DDTkk:mm"
                        )
                      : moment().format("YYYY-MM-DDTkk:mm")
                  }
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <Divider />

        {mode !== "view" && (
          <SuiBox mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={isFetching || !open}
                title={selectedItem ? "Update Appointment" : "Add Appointment"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}

        {formdata?.consultantNotes?.length > 0 && (
          <SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiBox mt={3}>
                  <SuiTypography mb={2} variant="h6">
                    Consultant Notes (History)
                  </SuiTypography>

                  {formdata?.consultantNotes?.map((item, index) => (
                    <SuiBox
                      key={index}
                      style={{
                        background: "#49528A",
                        whiteSpace: "normal",
                        borderRadius: 10,
                        padding: 15,
                      }}
                      mb={3}
                    >
                      <SuiTypography
                        mb={1}
                        variant="body2"
                        color="white"
                        style={{ fontStyle: "italic" }}
                      >
                        {item?.date?.seconds
                          ? moment(timestampConversion(item?.date))
                              .local()
                              .format("MMMM Do YYYY")
                          : moment(item?.date).local().format("MMMM Do YYYY")}
                      </SuiTypography>

                      <SuiTypography mb={1} variant="body2" color="white">
                        {item?.consultantNote}
                      </SuiTypography>
                    </SuiBox>
                  ))}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
