import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useCallback, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSelectedEmployeeAttendance } from "services/employees";
import { getSelectedEmployeeDetails } from "services/employees";
import Grid from "@mui/material/Grid";
import EmployeeInfo from "./EmployeeInfo";
import AttendanceHistoryTable from "./AttendanceHistoryTable";
import { useSelector } from "react-redux";
import { employeeSelector } from "store/features/employee";

const AttendanceHistory = () => {
  let { employeeId: id } = useParams();
  const { isSuccess, isFetching, message } = useSelector(employeeSelector);

  const [loader, setLoader] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const employeeHandler = useCallback(
    async (isLoader) => {
      try {
        setLoader(true);

        const employeeDetails = await getSelectedEmployeeDetails({ id });
        const data = await getSelectedEmployeeAttendance({ id });

        const attendanceArray = Object.entries(data).reduce(
          (result, [key, value]) => {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(key)) {
              result.push({
                date: key,
                slots: value,
              });
            }
            return result;
          },
          []
        );

        setSelectedEmployee({
          ...(employeeDetails ?? []),
          attendance: attendanceArray ?? [],
        });
        setLoader(false);
        if (isSuccess) {
          toast.success(message);
        }
      } catch (e) {
        setLoader(false);
        toast.error("Something went wrong, retry");
      }
    },
    [id, isSuccess]
  );


  useEffect(() => {
    if (id) {
      employeeHandler();
    }
  }, [id, employeeHandler]);

  return (
    <DashboardLayout>
      <DashboardNavbar
        status="client"
        title={
          selectedEmployee?.name ? selectedEmployee?.name + "'s Dashboard" : ""
        }
      />
      {loader ? (
        <SuiBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <TailSpin color="#17c1e8" height={80} width={80} />
        </SuiBox>
      ) : (
        <SuiBox py={3}>
          <SuiBox mb={3} px={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <SuiBox>
                  <EmployeeInfo employeeDetails={selectedEmployee} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <SuiBox>
                  <AttendanceHistoryTable
                    selectedEmployee={selectedEmployee}
                    id={id}
                  />
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
      )}
    </DashboardLayout>
  );
};

export default AttendanceHistory;
