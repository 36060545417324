import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadFile } from "firebaseConfig";
import { firestore } from "firebaseConfig";
import { v4 as uuidv4 } from "uuid";

export const updateClientData = async (clientId, key, data) => {
  try {
    const fields = [
      "vital",
      "measurement",
      "dailyIntake",
      "dietRecall",
      "dietary",
      "physical",
      "biochemical",
    ];

    if (fields.indexOf(key) !== -1) {
      const ref = doc(firestore, "clientdetail", clientId);
      const snapshot = await getDoc(ref);

      if (snapshot.exists()) {
        await updateDoc(ref, {
          [key]: data ?? [],
        });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const saveImages = async (clientId, imageArrayData) => {
  try {
    const ref = doc(firestore, "clientdetail", clientId);

    let imageArray = [];
    const docId = uuidv4();

    for (let file of imageArrayData) {
      if (file?.createdAt) {
        const data = {
          imageId: docId,
          image: file?.image,
          date: file.date,
          createdAt: new Date(),
        };
        imageArray = [...imageArray, data];
      } else {
        const image = await uploadFile(file.image, "imageNowVsEarlier");
        const data = {
          imageId: docId,
          image: image,
          date: file.date,
          createdAt: new Date(),
        };
        imageArray = [...imageArray, data];
      }
    }

    await updateDoc(ref, {
      imageNowVsEarlier: imageArray,
    });
  } catch (error) {
    throw error;
  }
};

export const updateProfileClientData = async (
  clientId,
  formdata,
  steps,
  index
) => {
  try {
    const ref = doc(firestore, "client", clientId);

    let step = [...steps];
    step[index] = 1;
    const updatedData = {
      updatedAt: new Date(),
      address: formdata.address,
      gender: formdata.gender.value,
      dob: formdata.dob,
      anniversary: formdata.anniversary ?? "",
      occupation: formdata.occupation,
      age: formdata.age,
      leadFrom: formdata.leadFrom.value,
      preference: formdata.preference.value,
      referredBy: formdata.referredBy,
      step: step,
    };

    if (formdata.familyName && formdata.familyName.value) {
      await updateDoc(ref, {
        ...updatedData,
        familyName: formdata.familyName.value,
      });

      return {
        ...updatedData,
        familyName: formdata.familyName.value,
        id: clientId,
      };
    } else {
      await updateDoc(ref, {
        ...updatedData,
      });

      return { ...updatedData, id: clientId };
    }
  } catch (error) {
    throw error;
  }
};

export const updatePlanInfo = async (formdata, clientId, steps, index) => {
  try {
    let step = [...steps];
    step[index] = 1;

    let service = {
      serviceId: formdata.package.value,
      serviceName: formdata.package.label,
      totalAmount: parseFloat(formdata.totalAmount),
      purchasedAt: new Date(),
      startDate: formdata.startDate,
      completionDate: formdata.completionDate,
      serviceInstance: formdata.serviceInstance.value,
      amountPaid: parseFloat(formdata.amountPaid),
      discount: parseFloat(formdata.discount ?? 0),
      paymentMode: formdata.paymentMode.value,
      complementaryService: formdata.complementaryService,
    };

    const ref = doc(firestore, "client", clientId);

    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      const docId = uuidv4();

      await updateDoc(ref, {
        step: step,
        services: arrayUnion({
          id: docId,
          ...service,
        }),
      });

      return { ...service, id: docId };
    }
  } catch (error) {
    throw error;
  }
};

export const getAdminLogo = async ({ id }) => {
  try {
    const roleRef = doc(firestore, "profile", id);
    const snapshot = await getDoc(roleRef);
    let data;
    if (snapshot.exists()) {
      data = { ...snapshot.data() };
    }
    return data?.logo ?? "";
  } catch (error) {
    throw error;
  }
};
