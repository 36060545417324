import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  addDoc,
  deleteDoc,
  orderBy,
  where,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import moment from "moment";
import { sortArrayByName } from "utils/common";
import { CLIENT_NOT_FOUND } from "constant";
import { calcPriceWT } from "utils/common";
import { timestampConversionv1 } from "utils/common";

//for getting client list
export const getClientList = async (uid) => {
  try {
    let data = [];
    const q = query(
      collection(firestore, "client"),
      where("adminId", "==", uid)
    );

    const querySnapshot = await getDocs(q);

    let yesterday = moment().subtract(1, "days");
    yesterday = yesterday.format("YYYY-MM-DD");

    querySnapshot.forEach((doc, index) => {
      let dates = [];
      let note = "";
      if (doc.data().dietPlan) {
        for (let j = 0; j < doc.data().dietPlan.length; j++) {
          dates.push(new Date(doc.data().dietPlan[j]?.date));
          let createdAt = doc.data().dietPlan[j].createdAt;
          if (createdAt.seconds) {
            createdAt = timestampConversionv1(createdAt);
            createdAt = moment(createdAt).format("YYYY-MM-DD");
          }

          if (yesterday === createdAt) {
            note = doc.data().dietPlan[j].notes;
          }
        }
      }
      data.push({
        name: doc.data().name,
        phoneNumber: doc.data().phoneNumber,
        label: doc.data().name + ` (+91 ${doc.data().phoneNumber})`,
        value: doc.id,
        key: doc.id,
        dietDates: dates,
        note: note,
      });
    });

    sortArrayByName(data);
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

//for getting client diet chart details
export const getClient = async (id) => {
  try {
    const ref = doc(firestore, "client", id);
    let data;
    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      data = snapshot.data();

      let updatedAt;
      let createdAt;
      if (snapshot.data().createdAt) {
        createdAt = snapshot.data().createdAt;
        createdAt =
          createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
        createdAt = moment.utc(createdAt);
      }

      if (snapshot.data().updatedAt) {
        updatedAt = snapshot.data().updatedAt;
        updatedAt =
          updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
        updatedAt = moment.utc(updatedAt);
      }

      if (data.dietPlan) {
        data.dietPlan.sort(function (a, b) {
          let createdAt1 = a.createdAt;
          createdAt1 = createdAt1.seconds
            ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
            : createdAt1;

          let createdAt2 = b.createdAt;
          createdAt2 = createdAt2.seconds
            ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
            : createdAt2;
          return new Date(createdAt2) - new Date(createdAt1);
        });

        for (let j = 0; j < data.dietPlan.length; j++) {
          let keys = Object.keys(data.dietPlan[j]);

          for (let i = 0; i < keys.length; i++) {
            if (
              keys[i] !== "date" &&
              keys[i] !== "notes" &&
              keys[i] !== "clientName" &&
              keys[i] !== "createdAt" &&
              keys[i] !== "dietChartId" &&
              keys[i] !== "weekPlan" &&
              keys[i] !== "chartType"
            ) {
              if (!Array.isArray(data.dietPlan[j][keys[i]])) {
                data.dietPlan[j][keys[i]] = [data.dietPlan[j][keys[i]]];
              }
            }
          }
        }
      }

      return { ...data, updatedAt, createdAt, id: id };
    } else {
      return CLIENT_NOT_FOUND;
    }
  } catch (e) {
    throw e;
  }
};

export const getClientDietChart = async (uid) => {
  try {
    const q = query(
      collection(firestore, "dietplan"),
      where("clientId", "==", uid),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);

    let data = [];

    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), dietChartId: doc.id });
    });

    return data;
  } catch (e) {
    console.log(e, "error");
    throw e;
  }
};

//get plan for dates
export const getClientDietChartForDate = async (uid) => {
  try {
    const q = query(
      collection(firestore, "dietplan"),
      where("clientId", "==", uid),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);

    let data = [];
    let note = null;

    querySnapshot.forEach((doc) => {
      if (note === null) {
        note = doc.data().notes;
      }
      data.push({ date: new Date(doc.data().date), id: doc.id });
    });

    return { data, note };
  } catch (e) {
    console.log(e, "error");
    throw e;
  }
};

//for user recipes
export const getUserRecipes = async (uid) => {
  try {
    let data = [];
    const q = query(
      collection(firestore, "recipe"),
      where("adminId", "==", uid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let timestamp = null;
      let updatedAt = null;
      if (doc.data().createdAt) {
        let createdAt = doc.data().createdAt;
        timestamp =
          createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
        timestamp = moment.utc(timestamp);
      }

      if (doc.data().updatedAt) {
        updatedAt = doc.data().updatedAt;
        updatedAt =
          updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
        updatedAt = moment.utc(updatedAt);
      }

      data.push({
        ...doc.data(),
        createdAt: timestamp,
        id: doc.id,
        updatedAt,
      });
    });

    data.sort(function (a, b) {
      let createdAt1 = a.createdAt._d;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.createdAt._d;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    return [...data];
  } catch (e) {
    throw e;
  }
};

//Remove diet of client
export const RemoveDiet = async (dietChartId) => {
  try {
    await deleteDoc(doc(firestore, "dietplan", dietChartId));
  } catch (e) {
    throw new Error("Something went wrong, retry");
  }
};

//get service list
export const getServiceInventory = async (uid) => {
  try {
    let data = [];
    const q = query(
      collection(firestore, "service"),
      where("adminId", "==", uid)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      let dataObj = {
        label: doc.data().serviceName,
        value: doc.data().serviceName,
        totalAmount: parseFloat(
          calcPriceWT(doc.data().sellingPriceWOT, doc.data().gst)
        ),
        key: doc.id,
      };
      data.push(dataObj);
    });

    return [...data];
  } catch (e) {
    throw e;
  }
};

//Add diet chart of client
export const AddDiet = async ({ formdata, dietPlan, uid }) => {
  try {
    let id = formdata.clientName.value;
    let clientDietPlan = {
      clientId: id,
      date: dietPlan.date,
      notes: dietPlan.notes,
      weekPlan: dietPlan.weekPlan.value,
      chartType: dietPlan.chartType.value,
      createdAt: new Date(),
    };

    let keys = Object.keys(dietPlan);

    for (let i = 0; i < keys.length; i++) {
      if (
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "clientId" &&
        keys[i] !== "dietChartId"
      ) {
        for (let j = 0; j < dietPlan[keys[i]].length; j++) {
          let recipe = dietPlan[keys[i]];

          if (!dietPlan[keys[i]][j]["id"] && dietPlan[keys[i]][j]["name"]) {
            const q = query(
              collection(firestore, "recipe"),
              where("name", "==", recipe[j].name.value),
              where("adminId", "==", uid)
            );
            const querySnapshotv1 = await getDocs(q);
            if (!querySnapshotv1.empty) {
              throw new Error({
                message: `Recipe with this name ${recipe[j].name.value} already exists, choose different name`,
              });
            }
          }
        }
      }
    }

    for (let i = 0; i < keys.length; i++) {
      let dietArray = [];

      if (
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "clientId" &&
        keys[i] !== "dietChartId"
      ) {
        for (let j = 0; j < dietPlan[keys[i]].length; j++) {
          let recipe = dietPlan[keys[i]];

          let data = {
            name: recipe[j].name?.label
              ? recipe[j].name?.value
              : recipe[j].name,
            ingredients: recipe[j].ingredients,
            nutritions: recipe[j].nutritions ?? [],
            description: recipe[j].description ?? "N/A",
            preparation: recipe[j].preparation,
            howToTake: recipe[j].howToTake,
            foodCategory:
              recipe[j].foodCategory.value ?? recipe[j].foodCategory,
            calories: parseFloat(recipe[j].calories),
            weight: parseFloat(recipe[j].weight),
            foodType: recipe[j].foodType ?? [],
            unit: recipe[j].unit.value ?? recipe[j].unit,
            qty: recipe[j].qty,
          };

          if (!recipe[j]["id"] && dietPlan[keys[i]][j]["name"]) {
            if (
              isNaN(data.calories) ||
              data.calories === "" ||
              data.calories === null
            ) {
              delete data.calories;
            }

            if (
              isNaN(data.calories) ||
              data.weight === "" ||
              data.weight === null
            ) {
              delete data.weight;
            }

            const recipeRef = await addDoc(collection(firestore, "recipe"), {
              ...data,
              createdAt: new Date(),
              adminId: uid,
            });

            dietArray.push({
              id: recipeRef.id,
              ...data,
              time: recipe[j].time,
            });
          } else {
            if (dietPlan[keys[i]][j]["name"]) {
              dietArray.push({
                id: recipe[j].id,
                ...data,
                time: recipe[j].time,
              });
            }
          }
        }

        if (dietArray.length > 0) {
          clientDietPlan = {
            ...clientDietPlan,
            [keys[i]]: dietArray,
          };
        }
      }
    }
    await addDoc(collection(firestore, "dietplan"), { ...clientDietPlan });
  } catch (e) {
    throw e;
  }
};

//Edit diet chart of client
export const EditDietChart = async ({ formdata, dietPlan, uid }) => {
  try {
    let id = formdata.clientName.value;
    const dietId = dietPlan.dietChartId;

    const ref = doc(firestore, "dietplan", dietId);
    let clientDietPlan = {
      clientId: id,
      date: dietPlan.date,
      notes: dietPlan.notes,
      weekPlan: dietPlan.weekPlan.value,
      chartType: dietPlan.chartType.value,
      createdAt: dietPlan.createdAt ?? new Date(),
    };

    let keys = Object.keys(dietPlan);

    for (let i = 0; i < keys.length; i++) {
      if (
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "clientName" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType" &&
        keys[i] !== "clientId"
      ) {
        for (let j = 0; j < dietPlan[keys[i]].length; j++) {
          let recipe = dietPlan[keys[i]];

          if (!dietPlan[keys[i]][j]["id"] && dietPlan[keys[i]][j]["name"]) {
            const q = query(
              collection(firestore, "recipe"),
              where("name", "==", recipe[j].name.value),
              where("adminId", "==", uid)
            );
            const querySnapshotv1 = await getDocs(q);
            if (!querySnapshotv1.empty) {
              throw new Error({
                message: `Recipe with this name ${recipe[j].name.value} already exists, choose different name`,
              });
            }
          }
        }
      }
    }

    for (let i = 0; i < keys.length; i++) {
      let dietArray = [];

      if (
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "clientId"
      ) {
        for (let j = 0; j < dietPlan[keys[i]].length; j++) {
          let recipe = dietPlan[keys[i]];

          let data = {
            name: recipe[j].name?.label
              ? recipe[j].name?.value
              : recipe[j].name,
            ingredients: recipe[j].ingredients,
            nutritions: recipe[j].nutritions ?? [],
            description: recipe[j].description ?? "N/A",
            preparation: recipe[j].preparation,
            howToTake: recipe[j].howToTake,
            foodCategory:
              recipe[j].foodCategory.value ?? recipe[j].foodCategory,
            calories: parseFloat(recipe[j].calories),
            weight: parseFloat(recipe[j].weight),
            foodType: recipe[j].foodType ?? [],
            unit: recipe[j].unit.value ?? recipe[j].unit,
            qty: recipe[j].qty,
          };

          if (!recipe[j]["id"] && dietPlan[keys[i]][j]["name"]) {
            if (
              isNaN(data.calories) ||
              data.calories === "" ||
              data.calories === null
            ) {
              delete data.calories;
            }

            if (
              isNaN(data.calories) ||
              data.weight === "" ||
              data.weight === null
            ) {
              delete data.weight;
            }

            const recipeRef = await addDoc(collection(firestore, "recipe"), {
              ...data,
              createdAt: new Date(),
              adminId: uid,
            });

            dietArray.push({
              id: recipeRef.id,
              ...data,
              time: recipe[j].time,
            });
          } else {
            if (dietPlan[keys[i]][j]["name"]) {
              dietArray.push({
                id: recipe[j].id,
                ...data,
                time: recipe[j].time,
              });
            }
          }
        }

        if (dietArray.length > 0) {
          clientDietPlan = {
            ...clientDietPlan,
            dietChartId: dietPlan.dietChartId,
            [keys[i]]: dietArray,
          };
        }
      }
    }

    await updateDoc(ref, {
      ...clientDietPlan,
    });
    return { ...clientDietPlan, dietChartId: dietId };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getClientKey = async ({ id }) => {
  try {
    let data = [];

    const ref = doc(firestore, "client", id);

    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      data = snapshot.data();
      data = {
        key: id,
        label: snapshot.data().name,
        name: snapshot.data().name,
        note: "",
        phoneNumber: snapshot.data().phoneNumber,
        value: id,
      };
      return data;
    } else {
      throw new Error({ message: "Client not found!!!" });
    }
  } catch (e) {
    throw e;
  }
};
