import SuiTypography from "components/SuiTypography";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { calculateTotal } from "utils/helper";
import { formatTimestamp } from "utils/helper";
import TableAction from "containers/table-action";

export const expenseRows = (expenseArray, editHandler, deleteHandler, viewHandler) => {
  const rows = expenseArray.map((item, index) => {
    return {
      "S.No": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Added)":
      (<SuiTypography
          variant="button"
          fontWeight="medium">
          {item.createdAt ?? formatTimestamp()}
      </SuiTypography>),

      "Expense Name": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.expenseName}
        </SuiTypography>
      ),

      "Expense Category": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.expenseCategory}
        </SuiTypography>
      ),
      "Date Of Expense": (
        <SuiTypography variant="button" fontWeight="medium">
          {moment(item.dateOfExpense).format("DD/MM/YYYY")}
        </SuiTypography>
      ),
      Description: (
        <Tooltip title={item.description} placement="top">
          <SuiTypography
            variant="button"
            fontWeight="medium"
          >
            {item.description?.length > 20
              ? `${item.description?.slice(0, 20)}...`
              : item.description}
          </SuiTypography>
        </Tooltip>
      ),
      "Expense Amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.expenseAmount}
        </SuiTypography>
      ),
      "Tax (%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.tax}
        </SuiTypography>
      ),

      "Total Amount": (
        <SuiTypography variant="button" fontWeight="medium">
          INR {calculateTotal(item.expenseAmount, item.tax)}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item.id, item.expenseName)}
        />
      ),
    };
  });
  return rows;
};
