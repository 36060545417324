import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import React from 'react'

function BasicDetails({ client }) {
    return (
        <SuiBox my={2}>
            <SuiBox
                style={{
                    border: '1px solid #D2D6DA',
                    borderRadius: 5
                }} p={1.5}>
                <SuiTypography variant="h5">Basic details</SuiTypography>

                <SuiBox mt={3}>
                    <SuiBox display="flex" justifyContent="space-between" wrap>
                        <SuiTypography variant="subtitle3">Your Name</SuiTypography>
                        <SuiTypography variant="body3">{client?.name}</SuiTypography>
                    </SuiBox>

                    <SuiBox display="flex" justifyContent="space-between" mt={1}>
                        <SuiTypography variant="subtitle3">Your Email</SuiTypography>
                        <SuiTypography variant="body3">{client?.email}</SuiTypography>
                    </SuiBox>

                    <SuiBox display="flex" justifyContent="space-between" mt={1}>
                        <SuiTypography variant="subtitle3">Your Number</SuiTypography>
                        <SuiTypography variant="body3">{client?.phoneNumber}</SuiTypography>
                    </SuiBox>
                </SuiBox>

                <SuiTypography variant="caption" style={{ display: "block" }} mt={1.5}>
                    Please contact to your fellow nutrition/dietitians to change your basic details
                </SuiTypography>
            </SuiBox>
        </SuiBox>
    )
}

export default BasicDetails;