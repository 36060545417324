import { Grid, Icon, Tooltip } from "@mui/material";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useCallback, useEffect, useState } from "react";
import EmployeeTable from "./sub-components/employees";
import AddEmployeeModal from "./sub-components/modal/AddEmployee";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/features/authSlice";
import { employeeSelector, clearState } from "store/features/employee";
import { toast } from "react-toastify";
import { getEmployees } from "store/features/employee";
import DeleteModal from "containers/DeleteModal";
import { deleteEmployee } from "store/features/employee";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AttendanceTable from "./sub-components/attendance";
import { getEmployeesAttendance } from "store/features/employee";
import moment from "moment";
import { getIntlPhoneNumber } from "store/features/authSlice";

const Employee = () => {
  const dispatch = useDispatch();
  const { uid, intlPhoneNumber } = useSelector(userSelector);
  const { attendance, employees, isError, isSuccess, message, isFetching } =
    useSelector(employeeSelector);

  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("view"); //create or edit [create, edit, view]
  const [newEmployeeModal, setNewEmployeeModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); //for selecting item
  const [deleteModal, setDeleteModal] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [intlPhoneNumberArray, setIntlPhoneNumberArray] = useState([]);

  useEffect(() => {
    const currentDate = moment().format("DD/MM/YYYY");

    const updatedEmployee = employees.map((employee) => {
      const record = attendance.find((record) => record.id === employee.id);

      if (record) {
        if (employee?.current_role === "full" && !record[currentDate]?.length) {
          return {
            ...employee,
            attendance: record[currentDate],
          };
        } else if (
          employee?.current_role === "part" &&
          record[currentDate]?.length
        ) {
          return { ...employee, attendance: record[currentDate] };
        } else {
          return { ...employee };
        }
      } else {
        return { ...employee };
      }
    });

    setAttendanceList(updatedEmployee);
  }, [attendance, employees]);

  useEffect(() => {
    dispatch(getIntlPhoneNumber());
  }, []);

  useEffect(() => {
    if (intlPhoneNumber) {
      const updateIntlNumber = intlPhoneNumber.data.map((item, index) => {
        return {
          label: `${
            item.dial_code.length === 2
              ? `(${item.dial_code})`.padEnd(10)
              : item.dial_code.length === 3
              ? `(${item.dial_code})`.padEnd(9)
              : item.dial_code.length === 4
              ? `(${item.dial_code})`.padEnd(8)
              : `(${item.dial_code})`
          } ${
            item?.name?.length > 15
              ? `${item?.name?.slice(0, 15)}...`
              : item?.name
          }`,
          value: item.dial_code,
          key: item.code,
          phoneLength: item.phoneLength,
        };
      });

      setIntlPhoneNumberArray(updateIntlNumber);
    }
  }, [intlPhoneNumber]);

  const onUpdateHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("edit");
      setNewEmployeeModal(true);
    },
    [setMode, setNewEmployeeModal, setSelectedItem]
  );
  const onViewHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("view");
      setNewEmployeeModal(true);
    },
    [setMode, setNewEmployeeModal, setSelectedItem]
  );

  const onAddHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewEmployeeModal(true);
  }, [setMode, setNewEmployeeModal, setSelectedItem]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(clearState());
      onDeleteSuccess();
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }

    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching]);

  useEffect(() => {
    dispatch(getEmployees({ uid }));
    dispatch(getEmployeesAttendance({ uid }));
  }, [dispatch, uid]);

  const onDeleteSuccess = useCallback(() => {
    if (deleteModal) {
      setDeleteModal(false);
    }
  }, [deleteModal]);

  const deleteHandler = (id) => {
    dispatch(deleteEmployee({ id }));
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Tabs>
            <TabList>
              <Tab>
                <SuiBox display="flex" alignItems="center" px={1} color="auto">
                  <span>Employees Attendance</span>
                  <span
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        "Below are the attendance list, make sure these are updated"
                      }
                      placement="top"
                      style={{
                        color: "#67748e",
                        height: "16px",
                        width: "14px",
                      }}
                    >
                      <Icon color="inherit">help</Icon>
                    </Tooltip>
                  </span>
                </SuiBox>
              </Tab>
              <Tab>
                <SuiBox display="flex" alignItems="center" px={1} color="auto">
                  <span>Employees List</span>
                  <span
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        "Below are the list of Employees, make sure these are updated"
                      }
                      placement="top"
                      style={{
                        color: "#67748e",
                        height: "16px",
                        width: "14px",
                      }}
                    >
                      <Icon color="inherit">help</Icon>
                    </Tooltip>
                  </span>
                </SuiBox>
              </Tab>
            </TabList>

            <TabPanel>
              <SuiBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <AttendanceTable
                      attendanceList={attendanceList}
                      loader={loader}
                      // employees={employees}
                      // title="Employees List"
                      // viewHandler={onViewHandler}
                      // editHandler={onUpdateHandler}
                      // addHandler={onAddHandler}
                      // deleteHandler={(id, name) => {
                      //   setSelectedItem({ id, name });
                      //   setDeleteModal(true);
                      // }}
                      adminId={uid}
                      // loader={loader}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </TabPanel>

            <TabPanel>
              <SuiBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <EmployeeTable
                      employees={employees}
                      title="Employees List"
                      viewHandler={onViewHandler}
                      editHandler={onUpdateHandler}
                      addHandler={onAddHandler}
                      deleteHandler={(id, name) => {
                        setSelectedItem({ id, name });
                        setDeleteModal(true);
                      }}
                      adminId={uid}
                      loader={loader}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </TabPanel>
          </Tabs>
        </SuiBox>

        <AddEmployeeModal
          mode={mode}
          open={newEmployeeModal}
          setOpenModal={setNewEmployeeModal}
          isSuccess={isSuccess}
          isError={isError}
          isFetching={isFetching}
          loader={loader}
          setLoader={setLoader}
          message={message}
          uid={uid}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          editHandler={onUpdateHandler}
          viewHandler={onViewHandler}
          handleCloseModal={() => {
            setNewEmployeeModal(false);
          }}
          intlPhoneNumberArray={intlPhoneNumberArray}
        />

        {deleteModal && (
          <DeleteModal
            isFetching={isFetching}
            onDeleteHandler={deleteHandler}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export default Employee;
