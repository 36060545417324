import React, { useState, useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import MeasurementChart from "containers/Chart";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { useCallback } from "react";
import { updateClientData } from "services/customer";
import { toast } from "react-toastify";
import CustomButton from "containers/CustomButton";
import { measurementsTableColumns } from "utils/tablesHeaderContent";
import { basicMeasurementKeys } from "utils/tablesHeaderContent";
import { globalStyles } from "styles";
import { measurementRecords } from "containers/clientDetails/sub-components/common/measurementRecords";
import MeasurementModal from "containers/clientDetails/sub-components/modal/measurement";
import SwipableModal from "containers/Customer/modal";
import { headerDesc } from "utils/helper";

function MeasurementDataVisualizer({ clientId, measurements, deleteHandler }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([...measurementsTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [measurementData, setMeasurementData] = useState([]);
  const [mode, setMode] = useState("create");

  useEffect(() => {
    if (measurements.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setMeasurementData(measurements);
  }, [measurements]);

  const [selectedData, setSelectedData] = useState(null);
  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("edit");
    setOpenAddModal(true);
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("view");
    setOpenAddModal(true);
  }, []);

  useEffect(() => {
    setRows(
      measurementRecords(
        measurementData,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [measurementData, editHandler, viewHandler]);

  const [chartSelected, setChartSelected] = useState(false);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    let measurementObj = {};

    const labelArray = [...basicMeasurementKeys];
    labelArray.forEach((item) => {
      measurementObj[item.value] = {
        label: item.value,
        color: item.color,
        data: [],
      };
    });
    let labels = [];
    let measurementArray = measurementData.slice();
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    measurementArray.sort(function (a, b) {
      const createdAt1 = convertTimestamp(a?.timestamp);
      const createdAt2 = convertTimestamp(b?.timestamp);
      return createdAt1 - createdAt2;
    });

    measurementArray.forEach((item) => {
      let timestamp = item.timestamp;
      const formattedTimestamp = moment.utc(timestamp).format("DD/MM/YYYY");
      labels.push(formattedTimestamp);

      labelArray.forEach((label) => {
        if (item[label.value]) {
          measurementObj[label.value]["data"].push(
            parseFloat(item[label.value] ?? 0)
          );
        }
      });
    });

    setChartData({
      labels: labels,
      datasets: Object.keys(measurementObj).map((key) => {
        return {
          label: key,
          color: measurementObj[key]["color"],
          data: measurementObj[key]["data"],
        };
      }),
    });
  }, [measurementData]);

  const addHandler = () => {
    setMode("create");
    setOpenAddModal(true);
  };

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const [loader, setLoader] = useState(false);
  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "measurement";
      await updateClientData(clientId, fieldKey, measurementData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <SuiBox overflow="hidden" minWidth="250px">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        wrap
        mb={2}
      >
        <SuiTypography variant="h5" style={{ color: "#17C1E8" }}>
          Measurement
        </SuiTypography>

        <SuiBox display="flex" wrap={true}>
          {rows.length > 0 ? (
            <SuiBox mr={2} style={styles.iconContainer}>
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => {
                  setChartSelected(false);
                }}
              >
                <Icon className={"text-white"}>table_rows</Icon>
              </IconButton>
              |
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => {
                  setChartSelected(true);
                }}
              >
                <Icon className={"text-white"}>show_chart</Icon>
              </IconButton>
            </SuiBox>
          ) : (
            <div />
          )}
          {
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addHandler}
            >
              Add Data
            </SuiButton>
          }
        </SuiBox>
      </SuiBox>

      <SuiBox sx={globalStyles.taleOverlay}>
        {chartSelected ? (
          <MeasurementChart
            data={chartData}
            title="Measurements"
            desc="Your measurement comparison at every instance"
          />
        ) : rows.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>
      {rows.length > 0 ? (
        <SuiBox style={styles.btn}>
          <CustomButton
            color="dark"
            variant="gradient"
            onClick={submitHandler}
            fullWidth
            disabled={loader}
            loader={loader}
            title={"Save and continue"}
          />
        </SuiBox>
      ) : null}

      <SwipableModal
        open={openAddModal}
        setOpenModal={setOpenAddModal}
        handleCloseModal={handleCloseModal}
        mode={mode}
        setMode={setMode}
        {...headerDesc(mode, "Measurement details", selectedData)}
      >
        <MeasurementModal
          id={clientId}
          persona="client"
          mode={mode}
          open={openAddModal}
          selectedItem={selectedData}
          setMainData={setMeasurementData}
          handleCloseModal={handleCloseModal}
        />
      </SwipableModal>
    </SuiBox>
  );
}

export default MeasurementDataVisualizer;

/**------------------------ styling ----------------------- */

const styles = {
  btn: {
    position: "sticky",
    width: "100%",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "end",
    marginTop: 40,
    bottom: 0,
  },

  iconContainer: {
    background: "#DBE2F0",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
  },
};
