import SuiTypography from "components/SuiTypography";
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import { timestampConversion, formatDateField } from 'utils/common'
import TagItem from "containers/TagItem"
import Checkbox from "./Checkbox";


export const dietChartRow = (dietChartArrayv1, setChartArray, selectHandler) => {

    const countCalories = (item) => {

        let calories = 0;
        item?.earlyMorning?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.breakfast?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.midMorning?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.midMeal?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.brunch?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.lunch?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.postLunch?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.evening?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.beforeDinner?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.dinner?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.postDinner?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })

        item?.lateNight?.map(i => {
            calories += (isNaN(i?.calories) ? 0: i?.calories)
            return i
        })
        
        return calories?.toFixed(2)??0
    }

    const getDietRecipes = (item) => {
        setChartArray((e)=>{
            return [ ...e, { dietChart: item }]
        })
    }

    const removeDietRecipes = (val) => {
        setChartArray((e)=>{
            let recipeArr = e
            recipeArr = recipeArr.filter((item)=> {
                if(item.dietChart.dietChartId !== val.dietChartId){
                    return item
                }
                return null
            })
            return recipeArr
        })
    }

    const rows = dietChartArrayv1.map((item, index)=> {

        return {

                "Download": 
                    (
                        <Checkbox item={item}
                            index={index}
                            selectHandler={selectHandler}
                            getDietRecipes={getDietRecipes}
                            removeDietRecipes={removeDietRecipes}/>
                    ),

                "Date/Time (Added)": 
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.createdAt?.seconds?moment(timestampConversion(item?.createdAt)).local().format('MMMM Do YYYY, h:mm a'):
                            moment(item?.createdAt).local().format('MMMM Do YYYY, h:mm a')} 
                    </SuiTypography>),

                "Diet Date for Client":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        { formatDateField(item?.date) }
                    </SuiTypography>),


                "Week Plan":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        { item.weekPlan }
                    </SuiTypography>),

                "Chart Type":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        { item.chartType }
                    </SuiTypography>),

                "Early Morning": 
                    item?.earlyMorning ?
                        <div>
                            {item?.earlyMorning.map((i, index)=>(
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {  
                                                    i?.description?.length > 40?
                                                        `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                        i?.name +" - " + i?.description
                                                }
                                        </SuiTypography>
                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}
                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),

                "Breakfast":  
                    item?.breakfast ?
                        <div>
                            {item?.breakfast.map((i, index) => (
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>
                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}
                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),
                
                "Mid Morning":  
                    item?.midMorning ?
                        <div>
                            {
                                item?.midMorning.map((i, index) => (
                                    <div key={index}>
                                        <Tooltip title={i?.description??""} placement="top">
                                            <SuiTypography 
                                                variant="button" 
                                                fontWeight="medium">
                                                    {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                        i?.name +" - " + i?.description} 

                                            </SuiTypography>

                                        </Tooltip>
                                        {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                    </div>
                            ))}
                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),

                "Brunch":  
                    item?.brunch ?
                        <div>
                            {item.brunch.map((i, index) => (
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>

                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}
                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),


                "Mid Meal":  
                    item?.midMeal ?
                        <div>
                            { item?.midMeal.map((i, index) => (
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>

                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}


                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),

                "Lunch":  
                    item?.lunch ?
                        <div>
                            { item?.lunch.map((i, index) => (
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>

                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}


                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),

                "Post Lunch":  
                    item?.postLunch ?
                        <div>
                            {
                                item?.postLunch.map((i, index) => (
                                    <div key={index}>
                                        <Tooltip title={i?.description??""} placement="top">
                                            <SuiTypography 
                                                variant="button" 
                                                fontWeight="medium">
                                                    {i?.description?.length>40?
                                                        `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                        i?.name +" - " + i?.description} 

                                            </SuiTypography>

                                        </Tooltip>
                                        {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                    </div>
                                ))
                            }

                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),

                "Evening":  
                    item?.evening ?
                        <div>
                            {item?.evening.map((i, index) => (
                                <div key={index}>
                                    <Tooltip title={i.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>

                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}

                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),


                "Before Dinner":  
                item?.beforeDinner ?
                    <div>
                        { item?.beforeDinner.map((i, index) => (
                            <div key={index}>
                                <Tooltip title={i?.description??""} placement="top">
                                    <SuiTypography 
                                        variant="button" 
                                        fontWeight="medium">
                                            {i?.description?.length>40?
                                                `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                i?.name +" - " + i?.description} 
                                    </SuiTypography>
                                </Tooltip>
                                {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                            </div>
                        ))}

                    </div>:                     
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        N/A
                    </SuiTypography>),

                "Dinner":  
                item?.dinner ?
                    <div>
                        {item.dinner.map((i, index) => (
                            <div key={index}>
                                <Tooltip title={i?.description??""} placement="top">
                                    <SuiTypography 
                                        variant="button" 
                                        fontWeight="medium">
                                            {i.description?.length>40?
                                                `${i.name} - ${i?.description?.slice(0, 40)}...`: 
                                                i?.name +" - " + i?.description} 

                                    </SuiTypography>

                                </Tooltip>
                                {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                            </div>
                        ))}
                    </div>:                     
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        N/A
                    </SuiTypography>),


                "Post Dinner":  
                    item?.postDinner ?
                        <div>
                            { item?.postDinner?.map((i, index)=>(
                                <div key={index}>
                                    <Tooltip title={i?.description??""} placement="top">
                                        <SuiTypography 
                                            variant="button" 
                                            fontWeight="medium">
                                                {i?.description?.length>40?
                                                    `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                    i?.name +" - " + i?.description} 

                                        </SuiTypography>
                                    </Tooltip>
                                    {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                </div>
                            ))}

                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),



                "Late Night":  
                    item?.lateNight ?
                        <div>
                            {
                                item?.lateNight.map((i, index)=>(
                                    <div key={index}>
                                        <Tooltip title={i?.description??""} placement="top">
                                            <SuiTypography 
                                                variant="button" 
                                                fontWeight="medium">
                                                    {i?.description?.length>40?
                                                        `${i?.name} - ${i?.description?.slice(0, 40)}...`: 
                                                        i?.name +" - " + i?.description} 

                                            </SuiTypography>

                                        </Tooltip>
                                        {(!isNaN(i?.calories)) && <TagItem val={i?.calories}/>}
                                    </div>

                                ))
                            }

                        </div>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),


                "Notes":  
                    item?.notes ?
                        <Tooltip title={item?.notes??""} placement="top">
                            <SuiTypography 
                                variant="button" 
                                fontWeight="medium">
                                    {item?.notes?.length>40?
                                        `${item?.notes?.slice(0, 40)}...`: 
                                        item?.notes} 

                            </SuiTypography>
                        </Tooltip>:                     
                        (<SuiTypography 
                            variant="button" 
                            fontWeight="medium">
                            N/A
                        </SuiTypography>),


                "Calories":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        <TagItem val={countCalories(item)}/>
                    </SuiTypography>),
            }
        })
    return rows
}