import React, { useEffect } from 'react'
import SuiInput from "components/SuiInput";


function NoteInput({
    selectedItem,
    setErrorMessage,
    setFormdata,
    errorMessage,
    setDietPlan,
    note,
    setNote,
    readOnly
}) {


    useEffect(() => {
        if (selectedItem) {
            setNote(selectedItem.notes)
        }
    }, [selectedItem, setNote])

    return (
        <SuiInput
            type="text"
            name="notes"
            multiline
            readOnly={readOnly}
            rows={7}
            error={errorMessage?.notes}
            value={note}
            onChange={(e) => {
                setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false
                })

                setDietPlan((ee) => {
                    ee = {
                        ...ee,
                        [e.target.name]: e.target.value
                    }
                    return ee
                })
                setNote(e.target.value)
            }} />
    )
}

export default NoteInput