export const sortData = (data, sortConfig) => {
    const sortedRows = [...data].sort((a, b) => {
        const getValue = (item) => {
            const value = item[sortConfig.sortKey];
            return value === undefined || value === null ? Infinity : value;
        };

        const getTimestamp = (item) => {
            const timestamp = item[sortConfig.sortKey];
            return timestamp
                ? timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6
                : Infinity;
        };

        const compareValues = (valueA, valueB) => {
            const lowerValueA = String(valueA).toLowerCase();
            const lowerValueB = String(valueB).toLowerCase();
            return lowerValueA.localeCompare(lowerValueB);
        };

        if (
            sortConfig.sortKey === "purchasedAt" ||
            sortConfig.sortKey === "createdAt" ||
            sortConfig.sortKey === "appointmentDate"
        ) {
            const timestampA = getTimestamp(a);
            const timestampB = getTimestamp(b);
            return sortConfig.direction === "asc"
                ? timestampA - timestampB
                : timestampB - timestampA;
        } else {
            const valueA = getValue(a);
            const valueB = getValue(b);
            return sortConfig.direction === "asc"
                ? compareValues(valueA, valueB)
                : compareValues(valueB, valueA);
        }
    });

    return sortedRows;
};
