import { formatTimestamp } from "utils/helper";
import { parseTimestamp } from "utils/helper";
import {
    collection,
    getDocs,
    doc,
    updateDoc,
    query,
    where,
    addDoc,
    deleteDoc,
} from "firebase/firestore";
import { firestore } from 'firebaseConfig'

export const getExpenses = async (adminId) => {
  try {
    let expenses = [];
    const firestoreQuery = query(
      collection(firestore, "expense"),
      where("adminId", "==", adminId)
    );
    const querySnapshot = await getDocs(firestoreQuery);
    querySnapshot.forEach((doc) => {
      let createdAt = null;
      let updatedAt = null;
      const data = doc.data();
      if (data.createdAt) {
        createdAt = parseTimestamp(data.createdAt);
        createdAt = formatTimestamp(createdAt);
      }

      if (data.updatedAt) {
        updatedAt = parseTimestamp(data.updatedAt);
        updatedAt = formatTimestamp(updatedAt);
      }

      if (data.dateOfExpense) {
        data.dateOfExpense = parseTimestamp(data.dateOfExpense);
      }

      expenses.push({
        ...data,
        id: doc.id,
        updatedAt,
        createdAt,
      });
    });

    return [...expenses];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const AddExpense = async (formdata, adminId) => {
  try {
    const expenseRef = await addDoc(collection(firestore, "expense"), {
      expenseName: formdata.expenseName,
      expenseCategory: formdata.expenseCategory,
      dateOfExpense: formdata.dateOfExpense,
      description: formdata.description,
      expenseAmount: formdata.expenseAmount,
      tax: formdata.tax,
      adminId,
      createdAt: new Date(),
    });
    return expenseRef.id;
  } catch (error) {
    throw error;
  }
};

export const updateExpense = async (formdata) => {
  try {
    const ref = doc(firestore, "expense", formdata.id);
    await updateDoc(ref, {
      expenseName: formdata.expenseName,
      expenseCategory: formdata.expenseCategory,
      dateOfExpense: formdata.dateOfExpense,
      description: formdata.description,
      expenseAmount: formdata.expenseAmount,
      tax: formdata.tax,
      updatedAt: new Date(),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteExpense = async (id) => {
  try {
    await deleteDoc(doc(firestore, "expense", id));
  } catch (error) {
    throw error;
  }
}