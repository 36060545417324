import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import moment from "moment";

//List of products
export const getProductInventory = createAsyncThunk(
  "Dashboard/getProductInventory",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "product"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let timestamp = null;
        let updatedAt = null;
        if (doc.data().createdAt) {
          let createdAt = doc.data().createdAt;
          timestamp =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          timestamp = moment.utc(timestamp);
        }

        if (doc.data().updatedAt) {
          updatedAt = doc.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        data.push({
          ...doc.data(),
          createdAt: timestamp,
          id: doc.id,
          updatedAt,
        });
      });

      data.sort(function (a, b) {
        let createdAt1 = a.createdAt._d;
        createdAt1 = createdAt1.seconds
          ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
          : createdAt1;

        let createdAt2 = b.createdAt._d;
        createdAt2 = createdAt2.seconds
          ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
          : createdAt2;
        return new Date(createdAt2) - new Date(createdAt1);
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting products
export const AddProduct = createAsyncThunk(
  "Dashboard/AddProduct",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "product"),
        where("productName", "==", formdata.productName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Product with this name already exists, choose different name",
        });
      }

      const productRef = await addDoc(collection(firestore, "product"), {
        ...formdata,
        createdAt: new Date(),
        adminId: uid,
      });

      return {
        ...formdata,
        id: productRef.id,
        createdAt: new Date(),
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit product
export const EditProduct = createAsyncThunk(
  "Dashboard/EditProduct",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "product"),
        where("productName", "==", formdata.productName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      let checkName = false;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName = true;
          }
        });
        if (checkName) {
          return thunkAPI.rejectWithValue({
            message:
              "Product with this name already exists, choose a different name",
          });
        }
      }

      const ref = doc(firestore, "product", formdata.id);

      let productObj = {
        productName: formdata.productName,
        brand: formdata.brand,
        description: formdata.description,
        costPriceWOT: formdata.costPriceWOT,
        gst: formdata.gst,
        mrp: formdata.mrp,
        availableQuantity: formdata.availableQuantity,
        updatedAt: new Date(),
      };

      // If checkGst is true, add GST properties
      if (formdata?.checkGst) {
        productObj.checkGst = formdata.checkGst;
        productObj.gstType = formdata.gstType;
        productObj.gstNumber = formdata.gstNumber;
        productObj.gstComment = formdata.gstComment;

        // Add GST type specific properties
        if (formdata.gstType?.value === "IGST") {
          productObj.igst = formdata.igst;
        } else {
          productObj.sgst = formdata.sgst;
          productObj.cgst = formdata.cgst;
        }

        await updateDoc(ref, { ...productObj });
        return { id: formdata.id, ...productObj };
      } else {
        productObj.checkGst = false;
        productObj.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
        productObj.igst = 0;
        productObj.sgst = 0;
        productObj.cgst = 0;
        productObj.gstComment = null;
        productObj.gstNumber = null;
        await updateDoc(ref, { ...productObj });
        return { id: formdata.id, ...productObj };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET service list
export const getServiceInventory = createAsyncThunk(
  "Dashboard/getServiceInventory",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "service"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let timestamp = null;
        let updatedAt = null;
        if (doc.data().createdAt) {
          let createdAt = doc.data().createdAt;
          timestamp =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          timestamp = moment.utc(timestamp);
        }

        if (doc.data().updatedAt) {
          updatedAt = doc.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        data.push({
          ...doc.data(),
          createdAt: timestamp,
          id: doc.id,
          updatedAt,
        });
      });

      data.sort(function (a, b) {
        let createdAt1 = a.createdAt._d;
        createdAt1 = createdAt1.seconds
          ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
          : createdAt1;

        let createdAt2 = b.createdAt._d;
        createdAt2 = createdAt2.seconds
          ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
          : createdAt2;
        return new Date(createdAt2) - new Date(createdAt1);
      });

      return [...data];
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getConsultationServices = createAsyncThunk(
  "Dashboard/getConsultationServices",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "consultation"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let timestamp = null;
        let updatedAt = null;
        if (doc.data().createdAt) {
          let createdAt = doc.data().createdAt;
          timestamp =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          timestamp = moment.utc(timestamp);
        }

        if (doc.data().updatedAt) {
          updatedAt = doc.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        data.push({
          ...doc.data(),
          createdAt: timestamp,
          id: doc.id,
          updatedAt,
        });
      });

      data.sort(function (a, b) {
        let createdAt1 = a.createdAt._d;
        createdAt1 = createdAt1.seconds
          ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
          : createdAt1;

        let createdAt2 = b.createdAt._d;
        createdAt2 = createdAt2.seconds
          ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
          : createdAt2;
        return new Date(createdAt2) - new Date(createdAt1);
      });

      return [...data];
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting service
export const AddConsultationService = createAsyncThunk(
  "Dashboard/AddConsultationService",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "consultation"),
        where("serviceName", "==", formdata.serviceName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Consultation Service with this name already exists, choose different name",
        });
      }

      const serviceRef = await addDoc(collection(firestore, "consultation"), {
        ...formdata,
        createdAt: new Date(),
        adminId: uid,
      });

      return { ...formdata, id: serviceRef.id, createdAt: new Date() };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit product
export const EditConsultationService = createAsyncThunk(
  "Dashboard/EditConsultationService",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "consultation"),
        where("serviceName", "==", formdata.serviceName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      let checkName = false;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName = true;
          }
        });
        if (checkName === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Consultation Service with this name already exists, choose different name",
          });
        }
      }

      const ref = doc(firestore, "consultation", formdata.id);

      let consultObj = {
        serviceName: formdata.serviceName,
        description: formdata.description,
        sellingPriceWOT: formdata.sellingPriceWOT,
        gst: formdata.gst,
        hsnCode: formdata?.hsnCode ? formdata?.hsnCode : "N/A",
        updatedAt: new Date(),
      };

      if (formdata?.checkGst) {
        consultObj.checkGst = formdata.checkGst;
        consultObj.gstType = formdata.gstType;
        consultObj.gstNumber = formdata.gstNumber;
        consultObj.gstComment = formdata.gstComment;

        // Add GST type specific properties
        if (formdata.gstType?.value === "IGST") {
          consultObj.igst = formdata.igst;
        } else {
          consultObj.sgst = formdata.sgst;
          consultObj.cgst = formdata.cgst;
        }

        await updateDoc(ref, { ...consultObj });
        return { id: formdata.id, ...consultObj };
      } else {
        consultObj.checkGst = false;
        consultObj.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
        consultObj.igst = 0;
        consultObj.sgst = 0;
        consultObj.cgst = 0;
        consultObj.gstComment = null;
        consultObj.gstNumber = null;
        await updateDoc(ref, { ...consultObj });
        return { id: formdata.id, ...consultObj };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting service
export const AddService = createAsyncThunk(
  "Dashboard/AddService",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "service"),
        where("serviceName", "==", formdata.serviceName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Service with this name already exists, choose different name",
        });
      }

      const serviceRef = await addDoc(collection(firestore, "service"), {
        ...formdata,
        createdAt: new Date(),
        adminId: uid,
      });

      return { ...formdata, id: serviceRef.id, createdAt: new Date() };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit product
export const EditService = createAsyncThunk(
  "Dashboard/EditService",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "service"),
        where("serviceName", "==", formdata.serviceName),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      let checkName = false;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkName = true;
          }
        });
        if (checkName === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Service with this name already exists, choose different name",
          });
        }
      }

      const ref = doc(firestore, "service", formdata.id);

      let productObj = {
        serviceName: formdata.serviceName,
        description: formdata.description,
        sellingPriceWOT: formdata.sellingPriceWOT,
        gst: formdata.gst,
        updatedAt: new Date(),
      };

      if (formdata?.checkGst) {
        productObj.checkGst = formdata.checkGst;
        productObj.gstType = formdata.gstType;
        productObj.gstNumber = formdata.gstNumber;
        productObj.gstComment = formdata.gstComment;

        // Add GST type specific properties
        if (formdata.gstType?.value === "IGST") {
          productObj.igst = formdata.igst;
        } else {
          productObj.sgst = formdata.sgst;
          productObj.cgst = formdata.cgst;
        }

        await updateDoc(ref, { ...productObj });
        return { id: formdata.id, ...productObj };
      } else {
        productObj.checkGst = false;
        productObj.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
        productObj.igst = 0;
        productObj.sgst = 0;
        productObj.cgst = 0;
        productObj.gstComment = null;
        productObj.gstNumber = null;
        await updateDoc(ref, { ...productObj });
        return { id: formdata.id, ...productObj };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//delete doc
export const deleteDocument = createAsyncThunk(
  "Dashboard/deleteDocument",
  async ({ persona, id }, thunkAPI) => {
    try {
      if (persona === "service") {
        await deleteDoc(doc(firestore, "service", id));
      }

      if (persona === "product") {
        await deleteDoc(doc(firestore, "product", id));
      }

      if (persona === "consultation") {
        await deleteDoc(doc(firestore, "consultation", id));
      }

      return { id, persona };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    products: [],
    services: [],
    consultation: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },

    updateSellProductStatus: (state, { payload }) => {
      state.products = state.products.map((item) => {
        if (item.id === payload.id) {
          item.availableQuantity -= parseInt(payload.quantity);
        }
        return item;
      });
      return state;
    },
    updateProductsAfterBulkUpload: (state, { payload }) => {
      state.products = payload;
    },
    updateServicesAfterBulkUpload: (state, { payload }) => {
      state.services = payload;
    },
  },
  extraReducers: {
    [getProductInventory.fulfilled]: (state, { payload }) => {
      state.products = payload ?? [];
      state.isFetching = false;
    },
    [getProductInventory.pending]: (state) => {
      state.isFetching = true;
    },
    [getProductInventory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddProduct.fulfilled]: (state, { payload }) => {
      state.products = [payload, ...state.products];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Product added successfully";
    },
    [AddProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [AddProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditProduct.fulfilled]: (state, { payload }) => {
      state.products = state.products.map((item) => {
        if (item.id === payload.id) {
          item.productName = payload.productName;
          item.availableQuantity = payload.availableQuantity;
          item.costPriceWOT = payload.costPriceWOT;
          item.gst = payload.gst;
          item.mrp = payload.mrp;
          item.brand = payload.brand;
          item.description = payload.description;
          item.updatedAt = payload.updatedAt;

          if (payload?.checkGst) {
            item.checkGst = payload.checkGst;
            item.gstType = payload.gstType;
            item.gstNumber = payload.gstNumber;
            item.gstComment = payload.gstComment;

            // Add GST type specific properties
            if (payload.gstType?.value === "IGST") {
              item.igst = payload.igst;
            } else {
              item.sgst = payload.sgst;
              item.cgst = payload.cgst;
            }
          } else {
            item.checkGst = false;
            item.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
            item.igst = 0;
            item.sgst = 0;
            item.cgst = 0;
            item.gstComment = null;
            item.gstNumber = null;
          }
        }
        return { ...item };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Product updated successfully";
    },
    [EditProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [EditProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [getServiceInventory.fulfilled]: (state, { payload }) => {
      state.services = payload ?? [];
      state.isFetching = false;
    },
    [getServiceInventory.pending]: (state) => {
      state.isFetching = true;
    },
    [getServiceInventory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddService.fulfilled]: (state, { payload }) => {
      state.services = [payload, ...state.services];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Service added successfully";
    },
    [AddService.pending]: (state) => {
      state.isFetching = true;
    },
    [AddService.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditService.fulfilled]: (state, { payload }) => {
      state.services = state.services.map((item) => {
        if (item.id === payload.id) {
          item.serviceName = payload.serviceName;
          item.sellingPriceWOT = payload.sellingPriceWOT;
          item.gst = payload.gst;
          item.mrp = payload.mrp;
          item.description = payload.description;

          if (payload?.checkGst) {
            item.checkGst = payload.checkGst;
            item.gstType = payload.gstType;
            item.gstNumber = payload.gstNumber;
            item.gstComment = payload.gstComment;

            // Add GST type specific properties
            if (payload.gstType?.value === "IGST") {
              item.igst = payload.igst;
            } else {
              item.sgst = payload.sgst;
              item.cgst = payload.cgst;
            }
          } else {
            item.checkGst = false;
            item.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
            item.igst = 0;
            item.sgst = 0;
            item.cgst = 0;
            item.gstComment = null;
            item.gstNumber = null;
          }
        }
        return { ...item, updatedAt: payload.updatedAt };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Service updated successfully";
    },
    [EditService.pending]: (state) => {
      state.isFetching = true;
    },
    [EditService.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [getConsultationServices.fulfilled]: (state, { payload }) => {
      state.consultation = payload ?? [];
      state.isFetching = false;
    },
    [getConsultationServices.pending]: (state) => {
      state.isFetching = true;
    },
    [getConsultationServices.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddConsultationService.fulfilled]: (state, { payload }) => {
      state.consultation = [payload, ...state.consultation];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Consultation Service added successfully";
    },
    [AddConsultationService.pending]: (state) => {
      state.isFetching = true;
    },
    [AddConsultationService.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditConsultationService.fulfilled]: (state, { payload }) => {
      state.consultation = state.consultation.map((item) => {
        if (item.id === payload.id) {
          item.serviceName = payload.serviceName;
          item.sellingPriceWOT = payload.sellingPriceWOT;
          item.gst = payload.gst;
          item.mrp = payload.mrp;
          item.description = payload.description;
          item.hsnCode = payload.hsnCode;

          if (payload?.checkGst) {
            item.checkGst = payload.checkGst;
            item.gstType = payload.gstType;
            item.gstNumber = payload.gstNumber;
            item.gstComment = payload.gstComment;

            // Add GST type specific properties
            if (payload.gstType?.value === "IGST") {
              item.igst = payload.igst;
            } else {
              item.sgst = payload.sgst;
              item.cgst = payload.cgst;
            }
          } else {
            item.checkGst = false;
            item.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
            item.igst = 0;
            item.sgst = 0;
            item.cgst = 0;
            item.gstComment = null;
            item.gstNumber = null;
          }
        }
        return { ...item, updatedAt: payload.updatedAt };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Consultation Service updated successfully";
    },
    [EditConsultationService.pending]: (state) => {
      state.isFetching = true;
    },
    [EditConsultationService.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [deleteDocument.fulfilled]: (state, { payload }) => {
      if (payload.persona === "product") {
        state.products = state.products.filter((item) => {
          if (item.id !== payload.id) {
            return { ...item };
          }
          return null;
        });
      }

      if (payload.persona === "service") {
        state.services = state.services.filter((item) => {
          if (item.id !== payload.id) {
            return { ...item };
          }
          return null;
        });
      }

      if (payload.persona === "consultation") {
        state.consultation = state.consultation.filter((item) => {
          if (item.id !== payload.id) {
            return { ...item };
          }
          return null;
        });
      }

      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully deleted!!";
    },
    [deleteDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const {
  clearState,
  clearInventoryState,
  updateSellProductStatus,
  updateProductsAfterBulkUpload,
  updateServicesAfterBulkUpload,
} = DashboardSlice.actions;

export const DashboardSelector = (state) => state.dashboard;
