/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon"

// Soft UI Dashboard React components

import SuiBox from "components/SuiBox";
import { TailSpin } from  'react-loader-spinner'
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React helper functions
import gradientChartLine from "assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "examples/Charts/LineCharts/GradientLineChart/configs";

import Createable from 'containers/Select'


// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
function GradientLineChart({ title, description, height, chart,para, yearArray, tag, setChartYear, chartYear, loader }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options } = chartData;


  useEffect(() => {
    const chartDatasets = (chart.datasets && chartRef?.current)
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0.4,
          pointRadius: 0,
          borderWidth: 3,
          borderColor: colors[dataset.color]
            ? colors[dataset.color || "dark"].main
            : colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef?.current?.children[0],
            colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
          )
        }))
      : [];
    setChartData(configs(chart.labels || [], chartDatasets));
  }, [chart, loader]);

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1} style={{ display: 'flex', justifyContent: "space-between" }}>
              <SuiTypography display="flex"  alignItems="center">
                    <SuiTypography variant="h6">{ title }
                    </SuiTypography>
                    <span style={{paddingLeft:"6px",fontSize:"14px",height:"16px",width:"14px"}}>
                     <Tooltip title={para}  placement="top" style={{color:'#67748e',height:"16px",width:"14px"}}>
                        <Icon color="inherit"  >
                            {"help"}
                        </Icon> 
                    </Tooltip> 
                  </span>
                  </SuiTypography>
                  {
                    tag &&
                      <Createable
                        options={yearArray}
                        style={{ borderRadius: '0.5rem', fontSize: 8 }}
                        inputStyle={{ width: 20, height: '1.0rem', borderRadius: '0.5rem'  }}
                        onChange={(e)=>{
                          setChartYear({
                            ...chartYear,
                            [tag]: e.value
                          })
                        }}
                        value={{ value: chartYear[tag] , label: chartYear[tag] }}/>
                        }
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => {
           return loader ?
            <SuiBox style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200 }} mb={4}>
              <TailSpin color="#17c1e8" height={40} width={40} />
            </SuiBox>:
            <SuiBox ref={chartRef} sx={{ height: '16rem' }}>
              <Line data={ data === undefined ?{ labels: [], datasets: [] }: data} options={data !== undefined && options}/>
            </SuiBox>
        },
        [data, options, loader]
      )}
    </SuiBox>
  );
  return <Card>{renderChart}</Card>
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
  title: "",
  description: "",
  height: "10.125rem",
};

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradientLineChart;
