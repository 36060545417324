export const style = {
    loaderDiv : {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.1)'
    }
}