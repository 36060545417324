import React from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

import Grid from "@mui/material/Grid";

function ChartBox({
  title,
  formdata,
  setFormdata,
  setChartError,
  chartError,
  chartId,
  mode,
}) {
  return (
    <SuiBox
      p={2}
      style={{
        border: "1px solid #D2D6DA",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
        borderRadius: 8,
      }}
    >
      <SuiTypography
        style={{ textDecorationLine: "underline" }}
        mb={1}
        variant="h6"
      >
        {title}{" "}
      </SuiTypography>
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                Time
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="time"
                name="time"
                readOnly={mode === "view"}
                error={chartError[chartId]?.time}
                value={formdata[chartId]?.time ?? ""}
                onChange={(e) => {
                  setChartError({
                    ...chartError,
                    [chartId]: {
                      ...chartError[chartId],
                      [e.target.name]: false,
                    },
                  });

                  setFormdata((ee) => {
                    ee[chartId] = {
                      ...ee[chartId],
                      [e.target.name]: e.target.value,
                    };
                    return ee;
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={8}>
            <SuiBox lineHeight={1} mt={3}>
              <SuiTypography mb={1} variant="h6">
                What client ate?
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="clientAte"
                rows={3}
                readOnly={mode === "view"}
                multiline
                placeholder="Something about what client ate . . ."
                error={chartError[chartId]?.clientAte}
                value={formdata[chartId]?.clientAte ?? ""}
                onChange={(e) => {
                  setChartError({
                    ...chartError,
                    [chartId]: {
                      ...chartError[chartId],
                      [e.target.name]: false,
                    },
                  });

                  setFormdata((ee) => {
                    ee[chartId] = {
                      ...ee[chartId],
                      [e.target.name]: e.target.value,
                    };
                    return ee;
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default ChartBox;
