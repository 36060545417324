import { excelAndDbServiceFieldsMapping } from "constant";
import Joi from "joi";
import { checkServiceExists } from "services/offering";

const serviceSchema = Joi.object({
  serviceName: Joi.string().required().label("Service Name"),
  description: Joi.string().required().label("Description"),
  sellingPriceWOT: Joi.number()
    .required()
    .positive()
    .min(0)
    .label("Selling Price (without tax)"),
  gst: Joi.number().positive().min(0).default(0).label("GST"),
}).unknown();;

export const validateAndExtractCellValuesForServices = async (
  dataArray,
  adminId
) => {
  const extractedData = [];
  const errors = [];

  const serviceNamesObj = {}

  for (let i = 1; i < dataArray.length; i++) {
    const dataRow = dataArray[i];
    const serviceData = {
      adminId: adminId,
      createdAt: new Date(),
    };

    for (const mapping of excelAndDbServiceFieldsMapping) {
      const excelHeader = mapping.excelName;
      const columnIndex = dataArray[0].indexOf(excelHeader);

      if (columnIndex !== -1) {
        serviceData[mapping.dbField] = dataRow[columnIndex];
      }
    }

    try {
      await serviceSchema.validateAsync(serviceData, { abortEarly: false });
      const serviceNameIndex = dataArray[0].indexOf("Service Name");
      if (serviceNameIndex !== -1) {
        const serviceName = dataRow[serviceNameIndex];
        const existingService = await checkServiceExists(serviceName, adminId);

        if (existingService) {
          errors.push({
            row: i + 1,
            field: "Service Name",
            message: `Service with the name "${serviceName}" already exists.`,
          });
        }

        const duplicateServiceNameInExcel = serviceNamesObj[serviceName];
        if(duplicateServiceNameInExcel){
          errors.push({
            row: i + 1,
            field: "Service Name",
            message: `Service with the name "${serviceName}" already exists in the excel.`,
          });
        }
        extractedData.push(serviceData);
      }
      serviceNamesObj[dataRow[serviceNameIndex]] = true;
    } catch (validationError) {
      if (validationError.details) {
        const rowErrors = validationError.details.map((error) => ({
          row: i + 1,
          field: error.context.label,
          message: error.message,
        }));
        errors.push(...rowErrors);
      }
    }
  }

  return { extractedData, errors };
};
