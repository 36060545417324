import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import TableAction from "containers/table-action";

//for calc selling price with tax
const calcPriceWT = (priceWOT, tax) => {
  return (
    parseFloat(priceWOT) +
    parseFloat(parseFloat(priceWOT) * (parseFloat(tax) / 100))
  ).toFixed(2);
};

export const serviceRows_ = (
  serviceArray,
  editHandler,
  deleteHandler,
  viewHandler
) => {
  const rows_ = serviceArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Added)": (
        <SuiTypography variant="button" fontWeight="medium">
          {moment(item?.createdAt).local().format("DD/MM/YYYY h:mm a")}
        </SuiTypography>
      ),

      "Service Name": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.serviceName}
        </SuiTypography>
      ),

      "Service Description": (
        <Tooltip title={item?.description} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.description?.length > 30
              ? `${item?.description?.slice(0, 30)}...`
              : item?.description}
          </SuiTypography>
        </Tooltip>
      ),

      "Selling price WT": (
        <SuiTypography variant="button" fontWeight="medium">
          {calcPriceWT(item.sellingPriceWOT, item.gst) ?? "N/A"}
        </SuiTypography>
      ),

      "Selling price WOT": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.sellingPriceWOT ?? "N/A"}
        </SuiTypography>
      ),

      "Tax(%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.gst}
        </SuiTypography>
      ),

      "Tax amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {parseFloat(
            parseFloat(item.sellingPriceWOT) * (parseFloat(item?.gst) / 100)
          ).toFixed(2)}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item, "service")}
          viewHandler={() => viewHandler(item, "service")}
          deleteHandler={() => deleteHandler(item.id, item.serviceName)}
        />
      ),
    };
  });
  return rows_;
};
