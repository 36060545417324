import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

export const physicalActivityTrackerRows = (
  physicalActivityArray,
  editHandler,
  viewHandler,
  deleteHandler
) => {
  const rows = physicalActivityArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      Date: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.timestamp)}
        </SuiTypography>
      ),

      "Current Lifestyle": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.lifestyle ? item?.lifestyle : "N/A"}
        </SuiTypography>
      ),

      "Physical Activities": (
        <Tooltip title={item?.physicalActivities} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.physicalActivities?.length > 20
              ? `${item?.physicalActivities?.slice(0, 20)}...`
              : item?.physicalActivities}
          </SuiTypography>
        </Tooltip>
      ),

      "Gym Routine": (
        <Tooltip title={item?.gymRoutine} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.gymRoutine?.length > 20
              ? `${item?.gymRoutine?.slice(0, 20)}...`
              : item?.gymRoutine}
          </SuiTypography>
        </Tooltip>
      ),

      "Smoke Cigarettes": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.smokeCigarettes}
        </SuiTypography>
      ),

      "Chew Tobacco": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.chewTobacco}
        </SuiTypography>
      ),

      Alcohol: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.drinkAlcohol ? item?.drinkAlcohol : "N/A"}
        </SuiTypography>
      ),

      "Awake in Late Night": (
        <Tooltip title={item?.awakeLateNight} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.awakeLateNight?.length > 20
              ? `${item?.awakeLateNight?.slice(0, 20)}...`
              : item?.awakeLateNight}
          </SuiTypography>
        </Tooltip>
      ),

      "Snack You Eat Late Night": (
        <Tooltip title={item?.snackAtLateNight} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.snackAtLateNight?.length > 20
              ? `${item?.snackAtLateNight?.slice(0, 20)}...`
              : item?.snackAtLateNight}
          </SuiTypography>
        </Tooltip>
      ),

      "Hours of Sleep": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.hourOfSleep ? item?.hourOfSleep : "N/A"}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item, index, "physicalActivity")}
        />
      ),
    };
  });

  return rows;
};
