// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SuiAvatar from "components/SuiAvatar";
import { Button, Grid } from "@mui/material";

function ProfileInfoCard({
  title,
  description,
  info,
  social,
  action,
  profileImage,
  qr,
  signature,
  logo,
  referenceCode,
  referredBy,
}) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  const renderOther = () => (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <SuiBox
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Profile <br /> Image:
            </SuiTypography>
            <SuiBox display="flex" flexDirection="column" alignItems="flex-end">
              {profileImage && (
                <Tooltip title={"Delete profile image"} placement="top">
                  <Icon
                    sx={({
                      typography: { size, fontWeightBold },
                      palette: { dark },
                    }) => ({
                      fontSize: `${size.lg} !important`,
                      cursor: "pointer",
                      color: "red",
                    })}
                    onClick={action.deleteProfileImage}
                  >
                    {"delete"}
                  </Icon>
                </Tooltip>
              )}
              <SuiAvatar
                src={profileImage ? profileImage : ""}
                alt="profile-image"
                variant="rounded"
                size="lg"
                shadow="sm"
                mt={1}
              />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={3}>
          <SuiBox
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Logo <br /> Image:
            </SuiTypography>
            <SuiBox display="flex" flexDirection="column" alignItems="flex-end">
              {logo && (
                <Tooltip title={"Delete logo image"} placement="top">
                  <Icon
                    sx={({
                      typography: { size, fontWeightBold },
                      palette: { dark },
                    }) => ({
                      fontSize: `${size.lg} !important`,
                      cursor: "pointer",
                      color: "red",
                    })}
                    onClick={action.deleteLogoImage}
                  >
                    {"delete"}
                  </Icon>
                </Tooltip>
              )}
              <SuiAvatar
                src={logo ? logo : ""}
                alt="profile-image"
                variant="rounded"
                size="lg"
                shadow="sm"
              />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={3}>
          <SuiBox
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Signature <br /> Image:
            </SuiTypography>
            <SuiBox display="flex" flexDirection="column" alignItems="flex-end">
              {signature && (
                <Tooltip title={"Delete signature image"} placement="top">
                  <Icon
                    sx={({
                      typography: { size, fontWeightBold },
                      palette: { dark },
                    }) => ({
                      fontSize: `${size.lg} !important`,
                      cursor: "pointer",
                      color: "red",
                    })}
                    onClick={action.deleteSignatureImage}
                  >
                    {"delete"}
                  </Icon>
                </Tooltip>
              )}
              <SuiAvatar
                src={signature ? signature : ""}
                alt="signature-image"
                variant="rounded"
                size="lg"
                shadow="sm"
              />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={3}>
          <SuiBox
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              QR <br /> Image:
            </SuiTypography>
            <SuiBox display="flex" flexDirection="column" alignItems="flex-end">
              {qr && (
                <Tooltip title={"Delete QR image"} placement="top">
                  <Icon
                    sx={({
                      typography: { size, fontWeightBold },
                      palette: { dark },
                    }) => ({
                      fontSize: `${size.lg} !important`,
                      cursor: "pointer",
                      color: "red",
                    })}
                    onClick={action.deleteQRImage}
                  >
                    {"delete"}
                  </Icon>
                </Tooltip>
              )}
              <SuiAvatar
                src={qr ? qr : ""}
                alt="qrimage"
                variant="rounded"
                size="lg"
                shadow="sm"
              />
            </SuiBox>
          </SuiBox>
        </Grid>
      </Grid>

      <SuiBox display="flex" py={1} pr={2} mt={2}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Referred By: &nbsp;
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{referredBy}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" py={1} pr={2}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Your Reference Code: &nbsp;
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{referenceCode}
        </SuiTypography>
      </SuiBox>
      <SuiBox style={{ marginTop: "-1.2rem" }}>
        <SuiTypography
          variant="button"
          fontWeight="regular"
          color="text"
          fontSize={"8px"}
        >
          You can earn 5% referal bonus
        </SuiTypography>
      </SuiBox>
    </>
  );

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <SuiBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </SuiBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SuiTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SuiTypography>
        <SuiTypography
          component={Button}
          onClick={action.handler}
          variant="body2"
          color="secondary"
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>
          {renderItems}
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              social: &nbsp;
            </SuiTypography>
            {renderSocial}
          </SuiBox>
          {renderOther()}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    // route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
