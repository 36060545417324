import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createUserWithEmailAndPassword,
  getAuth,
  deleteUser,
} from "firebase/auth";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { sendWelcomeEmailToEmployee } from "firebaseConfig";
import { deleteEmployeeAuth } from "firebaseConfig";
import moment from "moment";
import { toast } from "react-toastify";

export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const auth = getAuth();
      const state = thunkAPI.getState();
      const { logo, name } = state.user;

      await createUserWithEmailAndPassword(
        auth,
        formdata.email,
        formdata.password
      );

      const employeeCollectionRef = collection(firestore, "employees");
      const attendanceRef = collection(firestore, "attendance");

      let storeFormData = {
        name: formdata?.name,
        email: formdata?.email,
        password: formdata.password,
        phoneNumber: formdata?.phoneNumber,
        accessModules: formdata?.accessModules,
        adminId: uid,
        createdAt: new Date(),
        current_role: formdata?.current_role,
      };

      let docId;

      if (formdata?.current_role === "full") {
        await addDoc(employeeCollectionRef, { ...storeFormData }).then(
          (docRef) => {
            docId = docRef.id;
          }
        );

        const employeeAttendanceDocRef = doc(attendanceRef, docId);
        setDoc(employeeAttendanceDocRef, {
          adminId: uid,
        });

        await sendWelcomeEmailToEmployee({
          userEmail: formdata?.email,
          password: formdata?.password,
          clinicName: name,
          employeeName: formdata?.name,
          logo: logo,
        })
          .then(() => {
            toast.success("Email sent successfully!");
          })
          .catch((err) => {
            toast.error("Something went wrong!");
          });
      } else {
        await addDoc(employeeCollectionRef, {
          ...storeFormData,
          timing: formdata?.timing,
        }).then((docRef) => {
          docId = docRef.id;
        });

        const employeeAttendanceDocRef = doc(attendanceRef, docId);
        setDoc(employeeAttendanceDocRef, {
          adminId: uid,
        });

        await sendWelcomeEmailToEmployee({
          userEmail: formdata?.email,
          password: formdata?.password,
          clinicName: name,
          employeeName: formdata?.name,
          logo: logo,
        })
          .then(() => {
            toast.success("Email sent successfully!");
          })
          .catch((err) => {
            toast.error("Something went wrong!");
          });
      }

      return { ...formdata, createdAt: new Date(), id: docId };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getEmployees = createAsyncThunk(
  "employee/getEmployees",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "employees"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async ({ uid, formdata, id }, thunkAPI) => {
    try {
      const r = query(
        collection(firestore, "employees"),
        where("email", "==", formdata.email),
        where("adminId", "==", uid)
      );
      const querySnapshot1 = await getDocs(r);
      let checkEmail = false;

      const q = query(
        collection(firestore, "employees"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      let checkPhone = false;

      if (!querySnapshot1.empty) {
        querySnapshot1.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkEmail = true;
          }
        });
        if (checkEmail === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Employee with this email already exists, choose different email",
          });
        }
      }

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (doc.id !== formdata.id) {
            checkPhone = true;
          }
        });
        if (checkPhone === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Employee with this Phone Number already exists, choose different Phone Number",
          });
        }
      }

      if (formdata?.current_role === "full") {
        await updateDoc(doc(firestore, "employees", id), {
          ...formdata,
          updatedAt: moment().format("YYYY-MM-DD HH:mm"),
          timing: [
            {
              from: moment("09:00", "h:mm").format("HH:mm"),
              to: moment("13:00", "h:mm").format("HH:mm"),
            },
          ],
        });
      } else {
        await updateDoc(doc(firestore, "employees", id), {
          ...formdata,
          updatedAt: moment().format("YYYY-MM-DD HH:mm"),
        });
      }

      return { ...formdata, updatedAt: moment().format("YYYY-MM-DD HH:mm") };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployees",
  async ({ id }, thunkAPI) => {
    try {
      const docSnapshot = await getDoc(doc(firestore, "employees", id));

      if (docSnapshot.exists) {
        const employeeData = docSnapshot.data();

        await deleteEmployeeAuth(employeeData?.email);
        await deleteDoc(doc(firestore, "employees", id));
        await deleteDoc(doc(firestore, "attendance", id));

        return { id };
      } else {
        return null;
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const addEmployeeAttendance = createAsyncThunk(
  "employee/addEmployeeAttendance",
  async ({ formdata, adminId }, thunkAPI) => {
    try {
      const attendanceRef = collection(firestore, "attendance");
      const currentDate = moment().format("DD/MM/YYYY");
      const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");

      let newForm;

      let slotData = [];

      if (!formdata?.employeeId) {
        formdata?.map((item) => {
          slotData?.push({
            presence: item?.presence,
            role: item?.role,
            slotTiming: item?.slotTiming,
            updatedAt: dateTime,
          });
        });
      }

      if (formdata?.employeeId) {
        newForm = {
          presence: formdata?.presence,
          role: formdata?.role,
          updatedAt: dateTime,
        };
      } else {
        newForm = slotData;
      }

      // Use employeeId as the document ID
      const employeeAttendanceDocRef = doc(
        attendanceRef,
        formdata?.employeeId || formdata?.[0]?.employeeId
      );

      const attendanceDocSnapshot = await getDoc(employeeAttendanceDocRef);
      if (attendanceDocSnapshot.exists()) {
        // Document already exists, update it
        await setDoc(employeeAttendanceDocRef, {
          ...attendanceDocSnapshot.data(),
          [currentDate]: newForm,
          adminId: adminId,
        });
      } else {
        // Document doesn't exist, create a new one
        await setDoc(employeeAttendanceDocRef, {
          adminId: adminId,
          [currentDate]: newForm,
        });
      }
      thunkAPI.dispatch(getEmployeesAttendance({ uid: adminId }));

      return {
        [currentDate]: newForm,
        id: formdata?.employeeId || formdata?.[0]?.employeeId,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const editEmployeeAttendance = createAsyncThunk(
  "employee/editEmployeeAttendance",
  async ({ data, id }, thunkAPI) => {
    try {
      const attendanceRef = collection(firestore, "attendance");
      const employeeAttendanceDocRef = doc(attendanceRef, id);
      const attendanceDocSnapshot = await getDoc(employeeAttendanceDocRef);

      if (attendanceDocSnapshot.exists()) {
        const rawData = attendanceDocSnapshot?.data();

        await setDoc(doc(firestore, "attendance", id), {
          ...rawData,
          [data?.date]: data?.slots,
        });
      }
      return { ...data, id: id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getEmployeesAttendance = createAsyncThunk(
  "employee/getEmployeesAttendance",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "attendance"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: [],
    attendance: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },
  },
  extraReducers: {
    [getEmployees.fulfilled]: (state, { payload }) => {
      state.employees = payload ?? [];
      state.isSuccess = true;
      state.isFetching = false;
    },
    [getEmployees.pending]: (state) => {
      state.isFetching = true;
    },
    [getEmployees.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
    [addEmployee.fulfilled]: (state, { payload }) => {
      state.employees = [...state.employees, payload];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully Added Employee";
      return state;
    },
    [addEmployee.pending]: (state) => {
      state.isFetching = true;
    },
    [addEmployee.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [updateEmployee.fulfilled]: (state, { payload }) => {
      state.employees = state.employees.map((item) => {
        if (payload.id === item.id) {
          return {
            ...payload,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
      return state;
    },
    [updateEmployee.pending]: (state) => {
      state.isFetching = true;
    },
    [updateEmployee.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [editEmployeeAttendance.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Attendance updated Successfully!!";
      return state;
    },
    [editEmployeeAttendance.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
    },
    [editEmployeeAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [deleteEmployee.fulfilled]: (state, { payload }) => {
      state.employees = state.employees.filter((item) => {
        if (item.id !== payload.id) {
          return { ...item };
        }
        return null;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully deleted!!";
    },
    [deleteEmployee.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteEmployee.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [addEmployeeAttendance.fulfilled]: (state, { payload }) => {
      state.attendance = state.attendance?.map((item) => {
        if (item?.id === payload?.id) {
          return { ...item, ...payload };
        } else {
          return { ...item };
        }
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Today's attendance updated!!";
      return state;
    },
    [addEmployeeAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [addEmployeeAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Something went wrong, retry";
    },

    [getEmployeesAttendance.fulfilled]: (state, { payload }) => {
      state.attendance = payload ?? [];
      state.isSuccess = true;
      state.isFetching = false;
    },
    [getEmployeesAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [getEmployeesAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const { clearState } = employeeSlice.actions;

export const employeeSelector = (state) => state.employee;
