import React from "react";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ModalRoot from "containers/modal/ModalRoot";
import { Grid, Icon } from "@mui/material";
import SuiInput from "components/SuiInput";
import { globaleDateTimeFormatter } from "utils/helper";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";
import { calculateProductFinalPrice } from "utils/common";
import { calculateProductTotalBalance } from "utils/common";

function Modal({
  open,
  item,
  handleCloseModal,
  mode,
  viewHandler,
  editHandler,
  setViewModal,
  setNewSellModal,
}) {
  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={"view"}
          title="Client details"
          subtitle={`Product details for ${updateFirstLetter(item?.name)}`}
        />

        <Grid item xs={12}>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setViewModal(false);
                editHandler(item, "product");
                setNewSellModal(true);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(item)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseModal}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Client Name
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.name ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Customer ID
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.customerId ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Selling Date/Time
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={globaleDateTimeFormatter(item?.purchasedAt) ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Product
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.productName}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Family Name
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.familyName}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Email
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.email}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Phone Number
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.phoneNumber}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                MRP
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.mrp}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Selling Price WOT
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.sellingPriceWOT}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Discount (INR)
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.discount}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                GST(%)
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.gst}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Selling Price WT
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={calculateProductFinalPrice(
                  item?.quantity,
                  item?.sellingPriceWOT,
                  item?.discount,
                  item?.gst
                )}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Quantity
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.quantity}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Amount Paid
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.amountPaid}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={`INR ${calculateProductTotalBalance(
                  item?.sellingPriceWOT,
                  item?.quantity,
                  item?.discount,
                  item?.gst,
                  item?.amountPaid
                )}`}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
