import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import SubscriptionCard from "examples/Cards/InfoCards/SubscriptionCard";
import HelpCard from "examples/Cards/InfoCards/Help";

import ReferralEarning from "examples/Cards/InfoCards/ReferralEarning";
import RedeemHistory from "examples/Cards/InfoCards/RedeemHistory";
import ChangePassword from "examples/Cards/InfoCards/ChangePassword";

import Header from "./sub-components/header";
import EditProfileModal from "./sub-components/modal";
import AddEditTnC from "./sub-components/termsAndConditions";
import DietChartTemplates from "./sub-components/dietChartTemplates";

import fitifyProfile from "assets/images/fitify-nutrition.webp";
import Logomain from "assets/images/logo.png";

import { userSelector, userDetails } from "store/features/authSlice";
import { useSelector, useDispatch } from "react-redux";

import ThreeDots from "containers/Loader";
import { timestampConversion } from "utils/common";
import moment from "moment";
import { deleteImage } from "store/features/authSlice";
import DietChartSettings from "./sub-components/dietChartSettings";
import GSTNumber from "./sub-components/gstNumber";

function Profile() {
  const [editProfileModal, setEditProfileModal] = useState(false);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const {
    name,
    info,
    profile,
    uid,
    logo,
    qr,
    signature,
    address,
    phoneNumber,
    email,
    fb,
    instagram,
    twitter,
    referrals,
    code,
    referredBy,
    payment,
    activeSubscription,
  } = useSelector(userSelector);

  useEffect(() => {
    if (uid) {
      dispatch(userDetails({ uid }));
    }
  }, [uid, dispatch]);

  const deleteQRImage = () => {
    dispatch(deleteImage({ uid, image: "qr" }));
  };
  const deleteSignatureImage = () => {
    dispatch(deleteImage({ uid, image: "signature" }));
  };
  const deleteLogoImage = () => {
    dispatch(deleteImage({ uid, image: "logo" }));
  };
  const deleteProfileImage = () => {
    dispatch(deleteImage({ uid, image: "profile" }));
  };

  const fetchNextRenewDate = () => {
    // Check if payment exists and activeSubscription.startDate has seconds property
    const hasActivation = activeSubscription;
    const hasSeconds = activeSubscription?.startDate?.seconds;

    // Calculate the next renew date based on the conditions
    let nextRenewDate = "N/A";
    if (hasActivation && hasSeconds) {
      // Convert activeSubscription.startDate to a Moment.js object
      const startDate = moment(
        timestampConversion(activeSubscription?.startDate)
      );
      // Add the monthOfSubscription to the startDate and format the date
      nextRenewDate = startDate
        .add(activeSubscription?.monthOfSubscription, "months")
        .add(activeSubscription?.daysLeftFromLastSubscription, "days")
        .local()
        .format("MMM/DD/YYYY");
    } else if (activeSubscription?.startDate) {
      // Use the original startDate and add the monthOfSubscription and days
      nextRenewDate = moment(activeSubscription?.startDate)
        .add(activeSubscription?.monthOfSubscription, "months")
        .add(activeSubscription?.daysLeftFromLastSubscription, "days")
        .local()
        .format("MMM/DD/YYYY");
    }
    return nextRenewDate;
  };

  const fetchAmountPaid = () => {
    const hasPayment = payment;
    const lastPaymentAmount = payment
      ? parseFloat(payment[payment?.length - 1]?.amount)
      : null;
    const amountPaid =
      hasPayment && lastPaymentAmount ? "INR " + lastPaymentAmount : "-";

    return amountPaid;
  };

  const fetchDateOfSubscription = () => {
    const hasActivation = activeSubscription;
    const hasSeconds = activeSubscription?.startDate?.seconds;
    const startDate = activeSubscription?.startDate;

    let dateOfSubscription = "N/A";

    if (hasActivation && hasSeconds && startDate) {
      dateOfSubscription = moment(timestampConversion(startDate))
        .local()
        .format("MMM/DD/YYYY");
    } else if (startDate) {
      dateOfSubscription = moment(startDate).local().format("MMM/DD/YYYY");
    }

    return dateOfSubscription;
  };

  const fetchReferAmount = () => {
    const hasPayment = payment;
    const lastPaymentReferAmount = payment
      ? parseFloat(payment[payment?.length - 1]?.referAmount)
      : null;
    const referAmount =
      hasPayment && lastPaymentReferAmount
        ? "INR " + lastPaymentReferAmount
        : "N/A";

    return referAmount;
  };

  return (
    <DashboardLayout>
      <Header name={name} profile={profile} />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              description={
                info ??
                "Hi there! we would love to have some nice bio about you."
              }
              info={{
                fullName: name,
                mobile: "+91 " + phoneNumber,
                email: email,
                location: address ?? "N/A",
              }}
              social={[
                {
                  link: `https://${fb}`,
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: `https://${twitter}`,
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: `https://${instagram}`,
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              profileImage={profile ?? fitifyProfile}
              logo={logo ?? Logomain}
              qr={qr}
              signature={signature}
              referredBy={referredBy ?? "N/A"}
              referenceCode={code}
              action={{
                handler: () => {
                  setEditProfileModal(true);
                },
                tooltip: "Edit Profile",
                deleteQRImage: deleteQRImage,
                deleteSignatureImage: deleteSignatureImage,
                deleteProfileImage: deleteProfileImage,
                deleteLogoImage: deleteLogoImage,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <SubscriptionCard
              title="Subscription Information"
              description="This is the Subscription you have taken to use this
                                software from Creware Technologies Pvt Ltd. 
                                Your subscription amount and date(s) are shown
                                below."
              personal={{
                name,
                phoneNumber: "+91 " + phoneNumber,
                email: email,
              }}
              referrals={referrals}
              uid={uid}
              logo={logo ?? Logomain}
              info={{
                nextRenewableDate: fetchNextRenewDate(),
                amountPaid: fetchAmountPaid(),
                dateOfSubscription: fetchDateOfSubscription(),
                subscriptionType: activeSubscription?.plan ?? "N/A",
                referAmount: fetchReferAmount(),
              }}
              activeSubscription={activeSubscription}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <HelpCard title="Help?" />
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={1} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4}>
            <ReferralEarning
              title="Available Referral Earnings"
              description="Below is the list of your referrals, these are the
                            users (Dieticians/Nutritionist) who have used your referral code to 
                            register. You shall be getting the benefit of referral amount on your next renewal by clicking the Redeem link."
              info={referrals ?? []}
              uid={uid}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <RedeemHistory title="Redeem History" info={referrals ?? []} />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <ChangePassword title="Change Password" setLoader={setLoader} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <DietChartSettings />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <GSTNumber uid={uid} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <DietChartTemplates />
          </Grid>
          <Grid item xs={24} md={12} xl={8}>
            <AddEditTnC />
          </Grid>
        </Grid>
      </SuiBox>
      {loader && <ThreeDots />}

      <EditProfileModal
        open={editProfileModal}
        setOpenModal={setEditProfileModal}
        loader={loader}
        setLoader={setLoader}
        handleCloseModal={() => {
          setEditProfileModal(false);
        }}
      />
    </DashboardLayout>
  );
}

export default Profile;
