import React from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SuiTypography from "components/SuiTypography";

function AppointmentSearch({
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  excelDownload,
}) {
  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mt={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            From Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            To Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox my={1} mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
        <SuiBox my={1} mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            style={{
              color: "#3A416F",
            }}
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={1} mr={2.5}>
        <SuiButton
          component={Button}
          color="dark"
          variant="gradient"
          style={{
            color: "#3A416F",
          }}
          onClick={() => {
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default AppointmentSearch;
