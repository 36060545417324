import SuiTypography from "components/SuiTypography";
import TableAction from "containers/table-action";
import { CircularProgress } from "@mui/material";
import {
  calculateProductTotalBalance,
  calculateProductFinalPrice,
} from "utils/common";
import { globaleDateTimeFormatter } from "utils/helper";
import SuiBox from "components/SuiBox";

export const productRow = (
  productArray,
  navigate,
  setInvoiceDataHandler,
  viewHandler,
  loading,
  formdata,
  setFormdata
) => {
  const createInvoiceClickHandler = async (item, index) => {
    setFormdata({
      ...formdata,
      modal: true,
      ...item,
      index,
    });
  };

  const rows = productArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/clients/${item.clientId}`);
          }}
        >
          {item.name}
        </SuiTypography>
      ),

      "Customer ID": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.customerId ?? "N/A"}
        </SuiTypography>
      ),

      "Selling Date/Time": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.purchasedAt)}
        </SuiTypography>
      ),

      Product: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.productName}
        </SuiTypography>
      ),

      Email: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      Brand: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.brand}
        </SuiTypography>
      ),

      "Total Amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {calculateProductFinalPrice(
            item.quantity,
            item.sellingPriceWOT,
            item.discount,
            item.gst
          )}
        </SuiTypography>
      ),

      Discount: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.discount}
        </SuiTypography>
      ),

      Quantity: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.quantity}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.amountPaid ?? 0}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          style={{ paddingLeft: 16, color: "red" }}
          variant="button"
          fontWeight="medium"
        >
          INR{" "}
          {calculateProductTotalBalance(
            item.sellingPriceWOT,
            item.quantity,
            item.discount,
            item.gst,
            item.amountPaid
          )}
        </SuiTypography>
      ),

      Action: (
        <SuiBox style={{ display: "flex", alignItems: "center" }}>
          <TableAction
            style={{ marginLeft: "7px" }}
            viewHandler={() => viewHandler(item, "product")}
          />
          <SuiTypography
            variant="button"
            fontWeight="medium"
            style={{
              color: "#17C1E8",
              cursor: "pointer",
              paddingLeft: 16,
            }}
            onClick={() => createInvoiceClickHandler(item, index)}
          >
            Create invoice{" "}
            {loading === index && (
              <CircularProgress color="success" size={"1rem"} />
            )}
          </SuiTypography>
          <SuiTypography
            variant="button"
            fontWeight="medium"
            style={{
              color: "#17C1E8",
              cursor: "pointer",
              paddingLeft: 16,
            }}
            onClick={() => {
              setInvoiceDataHandler(
                item.name,
                item.productName,
                item.id,
                "products",
                item.clientId
              );
            }}
          >
            Invoice history
          </SuiTypography>
        </SuiBox>
      ),
    };
  });
  return rows;
};
