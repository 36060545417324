import React, { useState } from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";

import "react-datepicker/dist/react-datepicker.css";
import SuiTypography from "components/SuiTypography";
import { isAllOptionInserted } from "utils/common";

function RecipeSearch({
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  searchTags,
  excelDownload,
}) {
  const [foodType_, setfoodType] = useState(null); //for selecting status
  const [foodCategory_, setFoodCategory_] = useState(null);

  if (!isAllOptionInserted(searchTags)) {
    searchTags?.unshift({
      value: "all",
      label: "All",
    });
  }

  const [foodCategory] = useState([
    { label: "All", value: "all", key: 0 },
    { label: "Vegetables", value: "Vegetables", key: 1 },
    { label: "Fruits", value: "Fruits", key: 2 },
    { label: "Grains", value: "Grains", key: 3 },
    { label: "Protein Foods", value: "Protein Foods", key: 4 },
    { label: "Dairy", value: "Dairy", key: 5 },
    { label: "Oils & Solid Fats", value: "Oils & Solid Fats", key: 6 },
    { label: "Added Sugars", value: "Added Sugars", key: 7 },
    { label: "Beverages", value: "Beverages", key: 8 },
    { label: "Egg", value: "Egg", key: 9 },
    { label: "Non Veg", value: "Non Veg", key: 10 },
    { label: "Sea Food", value: "Sea Food", key: 11 },
    { label: "Dietary Fibre", value: "Dietary Fibre", key: 12 },
    { label: "Vitamins Rich", value: "Vitamins Rich", key: 13 },
    { label: "Minerals Rich", value: "Minerals Rich", key: 14 },
    { label: "Carbs Rich", value: "Carbs Rich", key: 15 },
    { label: "Others", value: "Others", key: 16 },
  ]);

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose food type
          </SuiTypography>
          <Createable
            options={searchTags ?? []}
            value={foodType_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose food type"
            onChange={(e) => {
              setfoodType(e);
              setFilterQuery({
                ...filterQuery,
                foodType: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose food category
          </SuiTypography>
          <Createable
            options={foodCategory ?? []}
            value={foodCategory_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose food category"
            onChange={(e) => {
              setFoodCategory_(e);
              setFilterQuery({
                ...filterQuery,
                category: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
        <SuiBox my={1} mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={2} mr={2.5}>
        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setfoodType(null);
            setFoodCategory_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default RecipeSearch;
