import React, { useState, useEffect } from 'react'
import { AccountRows } from './AccountRows'
import AccountStat from './AccountStat'
import AdminClientInfoCard from 'containers/clientDetails/sub-components/card/adminClientCard';
import { Card } from '@mui/material';


function AccountHistory({ history, title, addHandler, editHandler, viewHandler }) {

    const [rows, setRows] = useState([])

    const columns = [
        { name: 'S.no', align: "left", width: "30px" },
        { name: "Start Date", align: "left", width: "150px" },
        { name: "Completion Date", align: "left", minWidth: '150px' },
        { name: "Service Taken", align: "left", minWidth: '120px' },
        // { name: "Total Amount", align: "left", minWidth: '150px' },
        // { name: "Amount Paid", align: "left", minWidth: '120px' },
        // { name: "Discount", align: "left", minWidth: '120px' },
        // { name: "Balance", align: "left" },
        // { name: "Service Instance", align: "left", minWidth: '150px' },
        { name: "Action", align: "left", minWidth: '120px' },
    ]

    useEffect(() => {
        setRows(AccountRows(history, editHandler, viewHandler))
    }, [history, editHandler, viewHandler])

    return (
        <Card style={{ backgroundColor: '#3A416F' }}>
            <AdminClientInfoCard
                title={title}
                onClickBtnHandler={addHandler}
                statComponent={<AccountStat
                    accounts={history} />}
                columns={columns}
                rows={rows}
                darkMode={true} />
        </Card>
    )
}

export default AccountHistory;