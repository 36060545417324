import React, { useState } from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SuiTypography from "components/SuiTypography";
import { isAllOptionInserted } from "utils/common";

function LeadSearch({
  setFilterQuery,
  filterQuery,
  serviceOptions,
  filterHandler,
  resetHandler,
  excelDownload,
}) {
  const [statusOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "New",
      label: "New",
    },
    {
      value: "In Discussion",
      label: "In Discussion",
    },
    {
      value: "Converted",
      label: "Converted",
    },
    {
      value: "Dropped Off",
      label: "Dropped Off",
    },
  ]);

  if (!isAllOptionInserted(serviceOptions)) {
    serviceOptions?.unshift({
      value: "all",
      label: "All",
    });
  }

  const [statusOpt_, setStatusOpt_] = useState(null); //for selecting status
  const [serviceOpt_, setServiceOpt_] = useState(null);

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mt={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            To Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            From Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose Service
          </SuiTypography>
          <Createable
            options={serviceOptions ?? []}
            value={serviceOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose Service"
            onChange={(e) => {
              setServiceOpt_(e);
              setFilterQuery({
                ...filterQuery,
                service: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Status
          </SuiTypography>
          <Createable
            options={statusOptions ?? []}
            value={statusOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 150, height: "1.800rem" }}
            placeholder="Status"
            onChange={(e) => {
              setStatusOpt_(e);
              setFilterQuery({
                ...filterQuery,
                status: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mb={1.5} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            style={{
              color: "#3A416F",
            }}
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
        <SuiBox my={1} mb={1.5} mr={2.5}>
          <SuiButton
            color="dark"
            component={Button}
            variant="gradient"
            style={{
              color: "#3A416F",
            }}
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={2} mr={2.5}>
        <SuiButton
          component={Button}
          color="dark"
          variant="gradient"
          style={{
            color: "#3A416F",
          }}
          onClick={() => {
            setServiceOpt_(null);
            setStatusOpt_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default LeadSearch;
