import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress , Button } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Card from "@mui/material/Card";
import { termsAndConditionDefault } from "constant";
import { userSelector , updateTermsAndConditions, getTermsAndConditions } from "store/features/authSlice";


const AddEditTnC = () => {
  const dispatch = useDispatch();

  const { uid, termsAndConditions } = useSelector(userSelector);
  const [tAndCText, setTAndCText] = useState(termsAndConditions??termsAndConditionDefault);
  const [tAndCTextError, setTAndCTextError] = useState(false);
  const [loader, setLoader] = useState(false)
  const [isEdit, setIsEdit] = useState(false);

  useEffect(()=>{
    dispatch(getTermsAndConditions({uid}))
  },[dispatch, uid])

  const handleSubmitTAndC = () => {
    if (tAndCText === "") {
      setTAndCTextError(true);

      return false;
    }
    setLoader(true)
    dispatch(updateTermsAndConditions({ newTandC: tAndCText, uid })).then(() =>{
        dispatch(getTermsAndConditions({uid}))
        setIsEdit(false)
        setLoader(false)
    }
    );
  };

  return (
      <Card sx={{ height: "100%" }}>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding={2}
          gap={1}
        >
          <SuiTypography mb={1} variant="h6">
            Terms and Condition
          </SuiTypography>
          {isEdit ? (
            <SuiInput
              type="text"
              name="T&C"
              multiline
              rows={7}
              placeholder="Enter T & C"
              error={tAndCTextError}
              value={tAndCText ?? ""}
              onChange={(e) => {
                setTAndCTextError(false);
                setTAndCText(e.target.value);
              }}
            />
          ) : (
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {termsAndConditions??termsAndConditionDefault}
            </SuiTypography>
          )}
          {isEdit ? (
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={handleSubmitTAndC}
              fullWidth
            >
              Submit&nbsp;{loader && <CircularProgress color="white" size={'1rem'}/>}
            </SuiButton>
          ) : (
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={() => setIsEdit(true)}
              fullWidth
            >
              Edit T & C
            </SuiButton>
          )}
        </SuiBox>
      </Card>
  );
};

export default AddEditTnC;
