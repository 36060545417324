import React, { useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import { globalStyles } from "styles";
import { TailSpin } from "react-loader-spinner";
import { clearState } from "store/features/clients";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function AdminClientInfoCard({
  title,
  onClickBtnHandler,
  columns,
  rows,
  statComponent,
  darkMode,
  oneTimeEntry,
  isSuccess,
  isError,
  isFetching,
  loader,
  setLoader,
  message,
}) {
  let textColor;
  let backgroundColor;
  if (darkMode) {
    textColor = "white";
    backgroundColor = "#3A416F";
  } else {
    textColor = "#3A416F";
    backgroundColor = "white";
  }

  const AddButton = () => {
    return onClickBtnHandler ? (
      <SuiButton
        component={Button}
        color={!darkMode ? "dark" : "white"}
        variant={!darkMode ? "gradient" : "contained"}
        onClick={onClickBtnHandler}
        width={180}
        style={{
          ...styles.addButton,
          color: backgroundColor,
        }}
      >
        Add Data
      </SuiButton>
    ) : null;
  };

  return (
    <SuiBox>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        px={3}
        pt={3}
        pb={1}
      >
        <SuiTypography variant="h6" color={textColor}>
          {title}
        </SuiTypography>
        <SuiBox style={styles.headerRight}>
          <AddButton />
        </SuiBox>
      </SuiBox>
      {statComponent}
      <SuiBox sx={globalStyles.tableOverlay}>
        {loader ? (
          <TailSpin color="#17c1e8" height={25} width={25} />
        ) : rows.length ? (
          <Table
            columns={columns}
            rows={rows}
            bgColor={backgroundColor}
            color={textColor}
            maxRows={2}
          />
        ) : (
          <SuiBox py={8}>
            <NoData color={textColor} />
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default AdminClientInfoCard;

/**----------------------styling-----------------------**/

const styles = {
  headerRight: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },

  addButton: {
    marginRight: 25,
    marginBottom: 10,
    height: 40,
    width: 230,
    color: "#3A416F",
    padding: 10,
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
