import React, { useState, useEffect, memo } from "react";
import SuiBox from "components/SuiBox";
import { Grid } from "@mui/material";
import { calcPriceWT } from "utils/common";
import PhysicalActivityTracker from "containers/clientDetails/customer/physicalActivityTracker";
// import DietLifestyleAssessment from 'containers/clientDetails/customer/dietLifestyleAssessment';
import BiochemicalHealthRecords from "containers/clientDetails/customer/biochemical";
import MeasurementDataVisualizer from "containers/clientDetails/customer/measurement";
import VitalDataVisualizer from "containers/clientDetails/customer/vital";
import DietRecallSummary from "containers/clientDetails/customer/dietrecall";
import ImageNowVsEarlier from "containers/clientDetails/customer/ImageNowVsEarlier";
import DailyIntakeForm from "containers/clientDetails/customer/dailyIntakeForm";
import DietChart from "containers/clientDetails/customer/dietchart";
import ProductPurchases from "containers/clientDetails/customer/productPurchases";
import Profile from "containers/clientDetails/customer/profile";

function Client({
  client,
  logo,
  services,
  // dietaryArray,
  physicalArray,
  biochemicalArray,
  measurementArray,
  vitalArray,
  dietRecallArray,
  dailyIntakeArray,
  imageArray,
  selectedClient,
  productArray,
  componentKey,
  setDeleteModal,
  setSelectedItem,
}) {
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    let serviceOptionsArray = services.map((item) => {
      return {
        label: item.serviceName,
        value: item.serviceName,
        totalAmount: calcPriceWT(item.sellingPriceWOT, item.gst),
        key: item.id,
      };
    });
    setServiceOptions(serviceOptionsArray);
  }, [services]);

  useEffect(() => {}, [imageArray]);

  const renderComponent = () => {
    switch (componentKey) {
      case 0:
        return <Profile client={client} serviceOptions={serviceOptions} />;

      case 1:
        return (
          <BiochemicalHealthRecords
            clientId={client?.id}
            biochemicals={biochemicalArray}
            deleteHandler={(item, index, persona) => {
              setSelectedItem({
                id: client?.id,
                persona,
                ID: client?.id,
                selectedItemIndex: index,
              });
              setDeleteModal(true);
            }}
          />
        );
      case 2:
        return (
          <PhysicalActivityTracker
            clientId={client?.id}
            physicalActs={physicalArray}
            deleteHandler={(item, index, persona) => {
              setSelectedItem({
                id: client?.id,
                persona,
                ID: client?.id,
                selectedItemIndex: index,
              });
              setDeleteModal(true);
            }}
          />
        );
      case 3:
        return (
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MeasurementDataVisualizer
                  measurements={measurementArray}
                  clientId={client?.id}
                  deleteHandler={(item, index, persona) => {
                    setSelectedItem({
                      id: client?.id,
                      persona,
                      ID: item?.id,
                      selectedItemIndex: index,
                    });
                    setDeleteModal(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <VitalDataVisualizer
                  vitals={vitalArray}
                  clientId={client?.id}
                  deleteHandler={(item, index, persona) => {
                    setSelectedItem({
                      id: client?.id,
                      persona,
                      ID: item?.id,
                      selectedItemIndex: index,
                    });
                    setDeleteModal(true);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        );
      case 4:
        return (
          <DietRecallSummary
            dietRecall={dietRecallArray ?? []}
            clientId={client?.id}
            deleteHandler={(item, index, persona) => {
              setSelectedItem({
                id: client?.id,
                persona,
                ID: item?.id,
                selectedItemIndex: index,
              });
              setDeleteModal(true);
            }}
          />
        );
      case 5:
        return (
          <DailyIntakeForm
            dailyIntakeArray={dailyIntakeArray}
            clientId={client?.id}
            deleteHandler={(item, index, persona) => {
              setSelectedItem({
                id: client?.id,
                persona,
                ID: item?.id,
                selectedItemIndex: index,
              });
              setDeleteModal(true);
            }}
          />
        );
      case 6:
        return (
          <ImageNowVsEarlier
            images={imageArray}
            clientId={client?.id}
            title="Image Now and Earlier"
            deleteHandler={(item, index, persona) => {
              setSelectedItem({
                id: client?.id,
                persona,
                ID: item?.image,
                selectedItemIndex: index,
              });
              setDeleteModal(true);
            }}
          />
        );
      case 7:
        return (
          <DietChart
            title="Diet Chart List"
            clientId={client?.id}
            selectedClient={selectedClient}
            adminLogo={logo}
          />
        );
      case 8:
        return (
          <ProductPurchases
            title="Product Purchase History"
            productArray={productArray}
          />
        );
      default:
        return null;
    }
  };

  return renderComponent();
}

export default memo(Client);
