import React from "react";
import CreatableSelect from "react-select/creatable";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function CreatableSingle({
  options,
  placeholder,
  style,
  inputStyle,
  onChange,
  value,
  disabled,
  loading,
}) {
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: "0.5rem",
      fontSize: 14,
      ...style,
      border: state.isFocused
        ? "1px solid #35d1f5"
        : state.isDisabled && "0.0625rem solid #d2d6da",
      boxShadow: state.isFocused && "0 0 0 1px #35d1f5",
      backgroundColor: "white",
      "&:hover": {
        borderColor: state.isFocused ? "#35d1f5" : "none",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: 14,
        whiteSpace: "break-spaces",
      };
    },
    input: (styles) => ({
      ...styles,
      height: "1.800rem",
      ...inputStyle,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem !important",
      paddingBottom: 4,
      color: "#8392ab",
      paddingLeft: 4,
      paddingTop: 4,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: 13,
      color: "#495057!important",
    }),
  };

  return (
    <SuiBox>
      <CreatableSelect
        className="basic-single"
        classNamePrefix="select"
        onChange={onChange}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
        isClearable={false}
        isDisabled={disabled}
        isSearchable={true}
        options={options}
        isLoading={loading ?? false}
      />
      <SuiTypography variant="caption" style={{ opacity: 0.5, fontSize: 10 }}>
        Press enter after typing or choose from list
      </SuiTypography>
    </SuiBox>
  );
}

export default CreatableSingle;
