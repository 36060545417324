import SuiTypography from "components/SuiTypography";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";


const calculateBalance = (totalAmount, discount, amountPaid) =>{
    if(totalAmount){
        return parseFloat(totalAmount) - parseFloat(discount??0) -  parseFloat(amountPaid)
    }
}
export const AccountRows = (history, editHandler, viewHandler) => {
    const rows_ = history.map((item)=> {
        return {
                "Start Date": 
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium" 
                        style={{paddingLeft: 14}}>
                        { globaleDateTimeFormatter(item?.startDate, 'date') }
                    </SuiTypography>),

                "Completion Date":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {globaleDateTimeFormatter(item?.completionDate, 'date')}
                    </SuiTypography>),

                "Service Taken":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.serviceName}
                    </SuiTypography>),

                "Total Amount":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.totalAmount}
                    </SuiTypography>),

                "Amount Paid":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.amountPaid?.toFixed(2)}
                    </SuiTypography>),
                
                "Discount":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.discount?.toFixed(2)}
                    </SuiTypography>),


                "Balance":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium"
                        style={{ color: 'red' }}>
                        INR {calculateBalance(item.totalAmount, item.discount, item.amountPaid)?.toFixed(2)}
                    </SuiTypography>),

                "Service Instance":  
                    (<SuiTypography 
                        variant="button" 
                        fontWeight="medium">
                        {item?.serviceInstance??'N/A'}
                    </SuiTypography>),

                "Action":  
                    (
                        <TableAction
                            style={{ padding: "3px", marginLeft: "5px" }}
                            updateHandler={() => editHandler(item)}
                            viewHandler={() => viewHandler(item, "payment")}
                        />
                    )
            }
        })
    return rows_
}