import React, { useEffect, useState } from "react";
// @mui material components

// Fontawesome icons
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import SuiTypography from "components/SuiTypography";

function ProductSearch({
  productOptions,
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  excelDownload,
}) {
  const [statusOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "Available",
      label: "Available",
    },
    {
      value: "Sold out",
      label: "Sold out",
    },
  ]);

  useEffect(() => {
    productOptions.unshift({ value: "all", label: "All" });
  }, [productOptions]);

  const [productOpt_, setProductOpt_] = useState(null); //for selecting products
  const [statusOpt_, setStatusOpt_] = useState(null); //for selecting status

  return (
    <SuiBox display="flex" flexWrap="wrap" alignItems="end" p={3} pb={0}>
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose Product
          </SuiTypography>
          <Createable
            options={productOptions ?? []}
            value={productOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose Product"
            onChange={(e) => {
              setProductOpt_(e);
              setFilterQuery({
                ...filterQuery,
                product: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Status
          </SuiTypography>
          <Createable
            options={statusOptions ?? []}
            value={statusOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 150, height: "1.800rem" }}
            placeholder="Status"
            onChange={(e) => {
              setStatusOpt_(e);
              setFilterQuery({
                ...filterQuery,
                status: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
        {/* Download icon for product inventory listing*/}
        <SuiBox my={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mr={2.5} mb={2}>
        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setStatusOpt_(null);
            setProductOpt_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default ProductSearch;
