import React, { useState, useEffect } from "react";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { formatNumberInIndianFormat } from "utils/common";

const calculateBalance = (totalAmount, discount, amountPaid) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid)
    );
  }
};

function ServiceStat({ service }) {
  const [paid, setPaid] = useState(0);
  const [balance, setBalance] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  useEffect(() => {
    let balance_ = 0;
    let total_ = 0;
    let paid_ = 0;
    let discount_ = 0;
    service.map((item) => {
      paid_ += parseFloat(item.amountPaid);
      discount_ += parseFloat(item.discount);
      total_ += parseFloat(item.totalAmount);
      balance_ += parseFloat(
        calculateBalance(item.totalAmount, item.discount, item.amountPaid)
      );
      return item;
    });

    setTotalDiscount(discount_.toFixed(2) ?? 0);
    setPaid(paid_.toFixed(2));
    setBalance(balance_.toFixed(2));
    setTotalAmount(total_.toFixed(2));
  }, [service]);

  return (
    <Grid container mb={2}>
      <Grid item xs={10} md={4} lg={3} mb={1}>
        <SuiBox
          p={1}
          mx={2}
          style={{
            borderRadius: 8,
            display: "flex",
            background: "#F8F9FA",
            minWidth: "max-content",
          }}
        >
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Total Amount:
          </SuiTypography>

          <SuiTypography variant="h9" fontWeight="medium">
            {formatNumberInIndianFormat(totalAmount)}
          </SuiTypography>
        </SuiBox>
      </Grid>

      <Grid item xs={10} md={4} lg={3} mb={1}>
        <SuiBox
          p={1}
          mx={2}
          style={{
            borderRadius: 8,
            display: "flex",
            background: "#F8F9FA",
            minWidth: "max-content",
          }}
        >
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Total Discount:
          </SuiTypography>

          <SuiTypography variant="h9" fontWeight="medium">
            {formatNumberInIndianFormat(totalDiscount)}
          </SuiTypography>
        </SuiBox>
      </Grid>

      <Grid item xs={10} md={4} lg={3} mb={1}>
        <SuiBox
          p={1}
          mx={2}
          style={{
            borderRadius: 8,
            display: "flex",
            background: "#F8F9FA",
            minWidth: "max-content",
          }}
        >
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Amount Paid:
          </SuiTypography>

          <SuiTypography variant="h9" fontWeight="medium">
            {formatNumberInIndianFormat(paid)}
          </SuiTypography>
        </SuiBox>
      </Grid>

      <Grid item xs={10} md={4} lg={3}>
        <SuiBox
          p={1}
          mx={2}
          style={{
            borderRadius: 8,
            display: "flex",
            background: "#F8F9FA",
            minWidth: "max-content",
          }}
        >
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Balance:
          </SuiTypography>

          <SuiTypography
            variant="h9"
            fontWeight="medium"
            style={{ color: "red" }}
          >
            {formatNumberInIndianFormat(balance)}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );
}

export default ServiceStat;
