import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "@mui/material/Card";
import * as XLSX from "xlsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";
import ClientSearch from "./Searchbar";
import NoData from "containers/DataNotAvailBox";
import { ClientRows_ } from "./ClientRows.js";
import { calcPriceWT } from "utils/common";
import moment from "moment";
import Searchbar from "containers/Searchbar";
import { useNavigate } from "react-router-dom";
import {
  timestampConversion,
  calculateProductTotalBalance,
} from "utils/common";
import { useDispatch } from "react-redux";
import { clearFilterState } from "store/features/clients";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort";

function ClientTable({
  clients,
  loader,
  products,
  onSelectHandler,
  editHandler,
  deleteHandler,
  familyArray,
  filterFamilyMember,
  filterState,
  viewHandler,
}) {
  const [productOptions, setProductOptions] = useState([]); //filtering wrt clients

  const [rows, setRows] = useState([]); // rows for client table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [clientArray, setclientArray] = useState([]); //
  let navigate = useNavigate(); //for navigation
  const dispatch = useDispatch();

  // sorting states
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  // options for dropdown of product filter
  useEffect(() => {
    let productArray = products.map((item, index) => {
      return {
        value: item?.productName,
        label: item?.productName,
        key: index,
      };
    });
    setProductOptions(productArray);
  }, [products]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time", align: "left", sort: true, sortKey: "purchasedAt" },
    {
      name: "Client Name",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "name",
    },
    {
      name: "Family Name",
      align: "left",
      minWidth: "130px",
      sort: true,
      sortKey: "familyName",
    },
    { name: "Customer Id", align: "left", minWidth: "105px" },
    {
      name: "Phone",
      align: "left",
      minWidth: "130px",
      sort: true,
      sortKey: "phoneNumber",
    },
    // { name: "Email", align: "left", minWidth: '170px', sort: true, sortKey: "email" },
    // { name: "MRP", align: "left", minWidth: '100px' },
    // { name: "Selling Price WOT", align: "left", minWidth: '150px' },
    // { name: "GST(%)", align: "left", minWidth: '120px' },
    // { name: "Selling Price WT", align: "left", minWidth: '150px' },
    // { name: "Quantity", align: "left", minWidth: '120px' },
    // { name: "Discount (INR)", align: "left", minWidth: '120px' },
    // { name: "Amount Paid", align: "left", minWidth: '120px' },
    // { name: "Balance", align: "left", minWidth: '120px' },
    { name: "Product", align: "left", minWidth: "150px" },
    { name: "Action", align: "left", minWidth: "100px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(clientArray, sortConfig);

    setRows(
      ClientRows_(
        sortedRows,
        navigate,
        onSelectHandler,
        editHandler,
        deleteHandler,
        filterFamilyMember,
        viewHandler
      )
    );
  }, [
    clientArray,
    sortConfig,
    onSelectHandler,
    deleteHandler,
    editHandler,
    navigate,
    filterFamilyMember,
    viewHandler,
  ]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    product: null,
    familyName: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  const calculateBalance = (totalAmount, discount, amountPaid, quantity) => {
    if (totalAmount) {
      let val =
        (parseFloat(totalAmount) - parseFloat(discount ?? 0)) *
          parseInt(quantity) -
        parseFloat(amountPaid);
      if (isNaN(val)) {
        return 0;
      }
      return val;
    }
    return 0;
  };

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = clients.filter((item) => {
      if (filterQuery.status) {
        if (filterQuery.status === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.status === "Inactive") {
          if (
            calculateBalance(
              calcPriceWT(item.sellingPriceWOT, item.gst),
              item.discount,
              item.amountPaid,
              item.quantity
            ) === 0
          ) {
            return false;
          }
        } else if (filterQuery.status === "Active") {
          if (
            calculateBalance(
              calcPriceWT(item.sellingPriceWOT, item.gst),
              item.discount,
              item.amountPaid,
              item.quantity
            ) !== 0
          ) {
            return false;
          }
        }
      }

      if (filterQuery.product) {
        if (filterQuery.product === "all") {
          setFilterQuery({ ...filterQuery, product: null });
        } else if (filterQuery.product !== item["productName"]) {
          return false;
        }
      }

      if (filterQuery.familyName) {
        if (filterQuery.familyName === "all") {
          setFilterQuery({ ...filterQuery, familyName: null });
        } else if (filterQuery.familyName !== item["familyName"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setclientArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      status: null,
      product: null,
      familyName: null,
      toDate: null,
      fromDate: null,
    });
    setclientArray(clients);
  }, [clients]);

  //filtering on the basis of tile click handler
  const filterOnTileClick = useCallback(
    (callback) => {
      let timestamp;

      const date = new Date();
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

      setFilterQuery((e) => {
        e["fromDate"] = firstDayOfMonth;
        e["toDate"] = date;
        return e;
      });

      let filterData = clients.filter((item) => {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);

        if (
          new Date(firstDayOfMonth) >=
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }

        if (moment.utc(moment(date).add(24, "hours")).isBefore(timestamp)) {
          return false;
        }

        return item;
      });

      if (searchVal) {
        let filteredProduct = filterData.filter((s) => {
          let name = s?.name;
          return name.toLowerCase().includes(searchVal.toLowerCase());
        });
        setclientArray((e) => filteredProduct);
      } else {
        setclientArray(filterData);
      }

      setTimeout(callback, 100);
      dispatch(clearFilterState());
    },
    [dispatch, clients, searchVal]
  );

  //use for filter according to tile
  useEffect(() => {
    if (filterState.filter) {
      if (filterState?.state === "PRODUCT" && clients.length > 0) {
        filterOnTileClick(() => {
          const element = document.getElementById("product");
          if (element) {
            //will scroll smoothly to the top of the product section
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      } else {
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [
    clients,
    resetHandler,
    setsearchVal,
    filterOnTileClick,
    filterState.state,
    filterState.filter,
  ]);

  const redirectState = useRef(filterState.redirect);
  useEffect(() => {
    if (searchVal) {
      resetHandler();
      let filteredProduct = clients.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setclientArray((e) => filteredProduct);
    } else {
      if (!redirectState.current) {
        redirectState.current = false;
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [searchVal, resetHandler, setclientArray, dispatch, clients]);

  const excelDownload = () => {
    let updatedData = clients.map((item, index) => {
      let time1 = item?.purchasedAt?.seconds
        ? moment(timestampConversion(item?.purchasedAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.purchasedAt).local().format("MMMM Do YYYY, h:mm a");
      return [
        index + 1,
        time1,
        item.name,
        item.familyName ?? "N/A",
        item.customerId ?? "N/A",
        item.phoneNumber,
        item.email,
        item.mrp,
        item.sellingPriceWOT,
        item.gst,
        (item.sellingPriceWOT * item.gst) / 100 + item.sellingPriceWOT,
        item.quantity,
        item.discount,
        item.amountPaid,
        calculateProductTotalBalance(
          item.sellingPriceWOT,
          item.quantity,
          item.discount,
          item.gst,
          item.amountPaid
        ),
        item.productName,
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME",
        "CLIENT NAME",
        "FAMILY NAME",
        "CUSTOMER ID",
        "PHONE",
        "EMAIL",
        "MRP",
        "SELLING PRICE WOT",
        "GST",
        "SELLING PRICE WT",
        "QUANTITY",
        "DISCOUNT",
        "AMOUNT PAID",
        "BALANCE",
        "PRODUCT",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Client_List_Products.xlsx");
  };

  return (
    <SuiBox mb={3} id="product">
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          gap={3}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 1 }}
          >
            Below are the list of clients who have purchased your products, you
            can manage them all.
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />
          </SuiBox>
        </SuiBox>

        <ClientSearch
          productOptions={productOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
          familyArray={familyArray}
        />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ClientTable;
