// oneRmCalculator.js
import React, { useState } from 'react';
import {
    Container,
    Paper,
    Grid,
} from '@mui/material';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiInput from 'components/SuiInput';
import { toast } from 'react-toastify';

const OneRMCalculator = () => {
    const [weight, setWeight] = useState('50');
    const [reps, setReps] = useState('1'); // Number of repetitions
    const [oneRm, setOneRm] = useState(null);

    const calculateoneRm = () => {
        if (weight && reps) {
            const basicCalculation = parseFloat(weight) * (1 + parseFloat(reps) / 30);
            setOneRm(basicCalculation.toFixed(2));
        } else {
            setOneRm(null);
            toast.error('Please fill all the fields');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
                <SuiTypography variant="h4" mb={2}>1Rm Calculator</SuiTypography>
                <form>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Weight (in Kgs)
                            </SuiTypography>
                            <SuiInput
                                type="number"
                                name="weight"
                                placeholder="Enter weight"
                                value={weight}
                                onChange={(e) => {
                                    setWeight(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Repetitions
                            </SuiTypography>
                            <SuiInput
                                type="number"
                                name="reps"
                                placeholder="Enter repetitions"
                                value={reps}
                                onChange={(e) => {
                                    setReps(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <SuiButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        onClick={calculateoneRm}
                        style={{ padding: '10px 0' }}
                    >
                        Calculate One Rep Max
                    </SuiButton>
                </form>
                {oneRm !== null && (
                    <SuiTypography variant="h6" style={{ marginTop: '20px' }}>
                        Estimated One Rep Max: {oneRm} Kgs
                    </SuiTypography>
                )}
            </Paper>
        </Container>
    );
};

export default OneRMCalculator;
