import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import {
  formatDatev3,
  calculatePriceAfterGST,
  calculateProductFinalPrice,
  calculateProductTotalBalance,
  createInvoiceId,
  extractUsername,
} from "utils/common";
import styles from "./style";
import facebookIcon from "assets/images/fb.png";
import instagramIcon from "assets/images/insta.png";
import twitterIcon from "assets/images/twitter.png";
import { calculateServiceFinalPrice } from "utils/common";
import { getDiscountedPrice } from "utils/common";
import { getTaxPrice } from "utils/common";

const Header = ({ user }) => {
  return (
    <View style={styles.header}>
      <View style={styles.details}>
        <Image style={styles.logoImage} src={user.logo} />
      </View>

      <Text style={styles.title}> INVOICE</Text>
    </View>
  );
};

const HeaderContent = ({ user }) => {
  return (
    <View style={styles.headerContent}>
      <View>
        <Text style={styles.company}>{user.name}</Text>
        <Text style={{ ...styles.company, ...styles.address }}>
          {user.address}
        </Text>
        <Text style={styles.company}>{user.phoneNumber}</Text>
        <Text style={styles.company}>{user.email}</Text>
        <Text style={styles.company}>
          GSTIN: {user.gstNumber ? user.gstNumber : "-"}
        </Text>
      </View>
      <View>
        <View style={styles.headerRow}>
          <Text style={styles.invoice}>INVOICE #</Text>
          <Text style={styles.date}>DATE</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={{ ...styles.invoice, ...styles.padding }}>
            {createInvoiceId()}
          </Text>
          <Text style={{ ...styles.date, ...styles.padding }}>
            {formatDatev3(new Date())}
          </Text>
        </View>
      </View>
    </View>
  );
};

const CustomerInfo = ({ bill }) => {
  return (
    <View style={styles.mainHeader}>
      <View>
        <View style={styles.headerRow}>
          <Text style={styles.text}>BILL TO</Text>
        </View>
        <View>
          <Text style={styles.padding}>{bill.name}</Text>
          <Text style={styles.padding}>{bill.address}</Text>
          <Text style={styles.padding}>{bill.phoneNumber}</Text>
          <Text style={styles.padding}>{bill.email}</Text>
          {bill.checkGst && (
            <>
              <Text style={styles.padding}>
                GST Treatment: {bill.gstTreatment.value}
              </Text>
              <Text style={{ ...styles.padding, marginBottom: 15 }}>
                GSTIN: {bill.gstNumber}
              </Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const ServiceRowv1 = ({ bill }) => {
  let gst = bill?.gstType?.value
    ? bill?.gstType?.value !== "IGST"
      ? bill?.cgst + bill?.sgst
      : bill?.igst
    : 0;
  const discountedAmt = getDiscountedPrice(bill.packageAmount, bill.discount);
  const gstAmt = discountedAmt * (gst / 100);
  const totalAmt = bill.packageAmount + gstAmt;

  return (
    <View style={styles.mainDataRow}>
      <View
        style={{
          ...styles.desc,
          ...styles.borderRightTop,
          width: bill.checkGst ? "30%" : "40%",
        }}
      >
        <Text>{bill.serviceName}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "15%" : "15%",
        }}
      >
        <Text>{bill?.hsnCode?.value ?? "N/A"}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "15%",
        }}
      >
        <Text>
          {formatDatev3(bill.startDate)} -{formatDatev3(bill.completionDate)}
        </Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "15%",
        }}
      >
        <Text>
          {bill?.packageAmount
            ? bill?.packageAmount?.toFixed(2)
            : bill?.totalAmount?.toFixed(2)}
        </Text>
      </View>

      {bill?.checkGst && (
        <View
          style={{
            ...styles.amount,
            ...styles.borderRightTop,
            width: "25%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              borderRight: "1px dotted black",
              width: "50%",
            }}
          >
            {gst}
          </Text>

          <Text style={{ textAlign: "center", width: "50%" }}>
            {gstAmt.toFixed(2)}
          </Text>
        </View>
      )}

      <View
        style={{
          ...styles.amount,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "20%",
        }}
      >
        <Text>{totalAmt.toFixed(2)}</Text>
      </View>
    </View>
  );
};

const ServiceRowv2 = ({ bill }) => {
  return (
    <View style={styles.mainDataRow}>
      <Text style={{ ...styles.desc, ...styles.borderRightTop }}>Discount</Text>
      <Text style={{ ...styles.datesection, ...styles.borderRightTop }}></Text>
      <Text style={{ ...styles.unit, ...styles.borderRightTop }}>
        {bill.discount === 0 ? "-" : bill.discount}
      </Text>
      <Text style={{ ...styles.amount, ...styles.borderRightTop }}>
        {bill.discount === 0 ? "-" : bill.discount}
      </Text>
    </View>
  );
};

const ProductRowv1 = ({ bill }) => {
  const parts = bill?.unit?.split("(");

  let gst = bill?.gstType?.value
    ? bill?.gstType?.value !== "IGST"
      ? bill?.cgst + bill?.sgst
      : bill?.igst
    : 0;
  const discountedAmt = getDiscountedPrice(bill.mrp, bill.discount);
  const gstAmt = discountedAmt * (gst / 100);
  const totalAmt = bill.mrp + gstAmt;

  return (
    <View style={styles.mainDataRow}>
      <View
        style={{
          ...styles.desc,
          ...styles.borderRightTop,
          width: bill.checkGst ? "30%" : "40%",
        }}
      >
        <Text>{bill.productName}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "15%" : "15%",
        }}
      >
        <Text>{bill?.hsnCode?.value ?? "N/A"}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "15%",
        }}
      >
        <Text>
          {bill.quantity
            ? `${bill.quantity} \n ${parts ? parts?.[0]?.trim() : ""}`
            : "-"}
        </Text>
      </View>

      <View
        style={{
          ...styles.unit,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "10%",
        }}
      >
        <Text>{bill.sellingPriceWOT}</Text>
      </View>

      {bill?.checkGst && (
        <View
          style={{
            ...styles.amount,
            ...styles.borderRightTop,
            width: "25%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              borderRight: "1px dotted black",
              width: "50%",
            }}
          >
            {bill.gst}
          </Text>

          <Text style={{ textAlign: "center", width: "50%" }}>
            {gstAmt.toFixed(2)}
          </Text>
        </View>
      )}

      <View
        style={{
          ...styles.amount,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "20%",
        }}
      >
        <Text>{totalAmt.toFixed(2) * bill.quantity}</Text>
      </View>
    </View>
  );
};

const ProductRowv2 = ({ bill }) => {
  return (
    <View style={styles.mainDataRow}>
      <Text style={{ ...styles.desc, ...styles.borderRightTop, width: "40%" }}>
        Discount
      </Text>
      <Text style={{ ...styles.qty, ...styles.borderRightTop, width: "20%" }}>
        {" "}
        -{" "}
      </Text>
      <Text style={{ ...styles.unit, ...styles.borderRightTop, width: "20%" }}>
        {bill.discount === 0 ? "-" : bill.discount}
      </Text>
      <Text
        style={{ ...styles.amount, ...styles.borderRightTop, width: "20%" }}
      >
        {parseFloat(bill.discount) * parseInt(bill.quantity)}
      </Text>
    </View>
  );
};

const ConsultationRowv1 = ({ bill }) => {
  let gst = bill?.gstType?.value
    ? bill?.gstType?.value !== "IGST"
      ? bill?.cgst + bill?.sgst
      : bill?.igst
    : 0;
  const discountedAmt = getDiscountedPrice(bill.packageAmount, bill.discount);
  const gstAmt = discountedAmt * (gst / 100);
  const totalAmt = bill.packageAmount + gstAmt;

  return (
    <View style={styles.mainDataRow}>
      <View
        style={{
          ...styles.desc,
          ...styles.borderRightTop,
          width: bill.checkGst ? "30%" : "40%",
        }}
      >
        <Text>{bill.serviceName}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "15%" : "15%",
        }}
      >
        <Text>{bill?.hsnCode?.value ?? "N/A"}</Text>
      </View>

      <View
        style={{
          ...styles.qty,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "15%",
        }}
      >
        <Text>{bill.quantity ? `${bill.quantity}}` : "-"}</Text>
      </View>

      <View
        style={{
          ...styles.unit,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "10%",
        }}
      >
        <Text>
          {bill.packageAmount ? bill.packageAmount : bill.totalAmount}
        </Text>
      </View>

      {bill?.checkGst && (
        <View
          style={{
            ...styles.amount,
            ...styles.borderRightTop,
            width: "25%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              borderRight: "1px dotted black",
              width: "50%",
            }}
          >
            {gst}
          </Text>

          <Text style={{ textAlign: "center", width: "50%" }}>
            {gstAmt.toFixed(2)}
          </Text>
        </View>
      )}

      <View
        style={{
          ...styles.amount,
          ...styles.borderRightTop,
          width: bill.checkGst ? "10%" : "20%",
        }}
      >
        <Text> {totalAmt.toFixed(2)}</Text>
      </View>
    </View>
  );
};

const ConsultationRowv2 = ({ bill }) => {
  return (
    <View style={styles.mainDataRow}>
      <Text style={{ ...styles.desc, ...styles.borderRightTop, width: "40%" }}>
        Discount
      </Text>
      <Text style={{ ...styles.qty, ...styles.borderRightTop, width: "20%" }}>
        {" "}
        -{" "}
      </Text>
      <Text style={{ ...styles.unit, ...styles.borderRightTop, width: "20%" }}>
        {"-"}
      </Text>
      <Text
        style={{ ...styles.amount, ...styles.borderRightTop, width: "20%" }}
      >
        {parseFloat(bill.discount)}
      </Text>
    </View>
  );
};

const ProductServiceSection = ({ bill, type }) => {
  if (type === "consultation") {
    return (
      <>
        <View style={styles.mainRow}>
          <Text
            style={{ ...styles.desc, width: bill.checkGst ? "30%" : "40%" }}
          >
            Items & Description
          </Text>
          <Text
            style={{ ...styles.desc, width: bill.checkGst ? "15%" : "15%" }}
          >
            HSN/SAC
          </Text>
          <Text style={{ ...styles.qty, width: bill.checkGst ? "10%" : "15%" }}>
            Qty
          </Text>
          <Text
            style={{ ...styles.unit, width: bill.checkGst ? "10%" : "10%" }}
          >
            Price
          </Text>

          {bill?.checkGst && (
            <View style={{ ...styles.unit, width: "25%" }}>
              <Text style={{ textAlign: "center" }}>{bill.gstType.value}</Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    ...styles.unit,
                    width: "50%",
                    borderRight: "1px dotted black",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  %
                </Text>
                <Text
                  style={{
                    ...styles.unit,
                    width: "50%",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  Amt
                </Text>
              </View>
            </View>
          )}

          <Text
            style={{
              ...styles.amount,
              ...styles.amountHeader,
              width: bill.checkGst ? "10%" : "20%",
            }}
          >
            Amount
          </Text>
        </View>

        {/* <View style={styles.mainRow}>
          <Text style={{ ...styles.desc, width: "40%" }}>DESCRIPTION</Text>
          <Text style={{ ...styles.qty, width: "20%" }}>QTY</Text>
          <Text style={{ ...styles.unit, width: "20%" }}>UNIT PRICE</Text>
          <Text style={{ ...styles.amount, width: "20%" }}>AMOUNT</Text>
        </View> */}

        <ConsultationRowv1 bill={bill} />
        <ConsultationRowv2 bill={bill} />
      </>
    );
  }
  return (
    <View style={styles.main}>
      {!bill.productName ? (
        <>
          <View style={styles.mainRow}>
            <Text
              style={{ ...styles.desc, width: bill.checkGst ? "30%" : "40%" }}
            >
              Items & Description
            </Text>
            <Text
              style={{ ...styles.desc, width: bill.checkGst ? "15%" : "15%" }}
            >
              HSN/SAC
            </Text>
            <Text
              style={{ ...styles.qty, width: bill.checkGst ? "10%" : "15%" }}
            >
              Start - End Date
            </Text>

            <Text
              style={{ ...styles.unit, width: bill.checkGst ? "10%" : "10%" }}
            >
              Price
            </Text>

            {bill?.checkGst && (
              <View style={{ ...styles.unit, width: "25%" }}>
                <Text style={{ textAlign: "center" }}>
                  {bill.gstType.value}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      ...styles.unit,
                      width: "50%",
                      borderRight: "1px dotted black",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    %
                  </Text>
                  <Text
                    style={{
                      ...styles.unit,
                      width: "50%",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    Amt
                  </Text>
                </View>
              </View>
            )}

            <Text
              style={{
                ...styles.amount,
                width: bill.checkGst ? "10%" : "20%",
                ...styles.amountHeader,
              }}
            >
              Amount
            </Text>
          </View>

          <ServiceRowv1 bill={bill} />
          <ServiceRowv2 bill={bill} />
        </>
      ) : (
        <>
          <View style={styles.mainRow}>
            <Text
              style={{ ...styles.desc, width: bill.checkGst ? "30%" : "40%" }}
            >
              Items & Description
            </Text>
            <Text
              style={{ ...styles.desc, width: bill.checkGst ? "15%" : "15%" }}
            >
              HSN/SAC
            </Text>
            <Text
              style={{ ...styles.qty, width: bill.checkGst ? "10%" : "15%" }}
            >
              Qty
            </Text>
            <Text
              style={{ ...styles.unit, width: bill.checkGst ? "10%" : "10%" }}
            >
              Price
            </Text>

            {bill?.checkGst && (
              <View style={{ ...styles.unit, width: "25%" }}>
                <Text style={{ textAlign: "center" }}>
                  {bill.gstType.value}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      ...styles.unit,
                      width: "50%",
                      borderRight: "1px dotted black",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    %
                  </Text>
                  <Text
                    style={{
                      ...styles.unit,
                      width: "50%",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    Amt
                  </Text>
                </View>
              </View>
            )}

            <Text
              style={{
                ...styles.amount,
                width: bill.checkGst ? "10%" : "20%",
                ...styles.amountHeader,
              }}
            >
              Amount
            </Text>
          </View>

          <ProductRowv1 bill={bill} />
          <ProductRowv2 bill={bill} />
        </>
      )}
    </View>
  );
};

const InvoiceAmount = ({ bill }) => {
  let gst = bill?.gstType?.value
    ? bill?.gstType?.value !== "IGST"
      ? bill?.cgst + bill?.sgst
      : bill?.igst
    : 0;
  const discountedAmt = getDiscountedPrice(
    bill.packageAmount ? bill.packageAmount : bill.mrp,
    bill.discount
  );
  let updatedBalanceAmt;
  if (bill.balanceAmt === 0) {
    updatedBalanceAmt = "0";
  } else {
    updatedBalanceAmt = bill.balanceAmt;
  }
  return (
    <View style={styles.invoiceAmount}>
      <View style={styles.blank} />
      <View style={styles.summaryTitle}>
        <Text>Subtotal</Text>
        {bill?.checkGst && bill.gstType.value === "IGST" && (
          <Text>IGST ({bill.igst}%)</Text>
        )}

        {bill?.checkGst && bill.gstType.value !== "IGST" && (
          <Text>CGST ({bill.cgst}%)</Text>
        )}

        {bill?.checkGst && bill.gstType.value !== "IGST" && (
          <Text>SGST ({bill.sgst}%)</Text>
        )}
        <Text>Total Tax Rate</Text>
        <Text>Total Tax Amt</Text>

        <Text style={styles.total}>Total</Text>
        <Text>Amount Paid</Text>
        <Text style={styles.balance}>Balance</Text>
      </View>

      <View style={styles.summaryValue}>
        {bill.productName && (
          <Text>
            {(parseFloat(bill.sellingPriceWOT) - parseFloat(bill.discount)) *
              parseInt(bill.quantity)}
          </Text>
        )}
        {bill.serviceName && (
          <Text>
            {bill?.packageAmount
              ? bill?.packageAmount - parseFloat(bill.discount)
              : bill.totalAmount?.toFixed(2) - parseFloat(bill.discount)}
          </Text>
        )}

        {bill.serviceName &&
          bill.checkGst &&
          bill?.gstType?.value === "IGST" && (
            <Text>{getTaxPrice(discountedAmt, bill.igst)?.toFixed(2)}</Text>
          )}

        {bill.serviceName &&
          bill.checkGst &&
          bill?.gstType?.value !== "IGST" && (
            <Text>{getTaxPrice(discountedAmt, bill.cgst)?.toFixed(2)}</Text>
          )}

        {bill.serviceName &&
          bill.checkGst &&
          bill?.gstType?.value !== "IGST" && (
            <Text>{getTaxPrice(discountedAmt, bill.sgst)?.toFixed(2)}</Text>
          )}

        {bill.serviceName && <Text>{gst ? gst : 0}%</Text>}

        {bill.serviceName && (
          <Text>{gst ? getTaxPrice(discountedAmt, gst)?.toFixed(2) : 0.0}</Text>
        )}

        {bill.productName &&
          bill.checkGst &&
          bill?.gstType?.value === "IGST" && (
            <Text>
              {(getTaxPrice(discountedAmt, bill.igst) * bill.quantity)?.toFixed(
                2
              )}
            </Text>
          )}

        {bill.productName &&
          bill.checkGst &&
          bill?.gstType?.value !== "IGST" && (
            <Text>
              <Text>
                {(
                  getTaxPrice(discountedAmt, bill.cgst) * bill.quantity
                )?.toFixed(2)}
              </Text>
            </Text>
          )}
        {bill.productName &&
          bill.checkGst &&
          bill?.gstType?.value !== "IGST" && (
            <Text>
              <Text>
                {(
                  getTaxPrice(discountedAmt, bill.sgst) * bill.quantity
                )?.toFixed(2)}
              </Text>
            </Text>
          )}

        {bill.productName && <Text>{bill.gst}%</Text>}

        {bill.productName && (
          <Text>{gst ? getTaxPrice(discountedAmt, gst)?.toFixed(2) : 0.0}</Text>
        )}

        <Text style={styles.totalVal}>
          {bill.productName &&
            calculateProductFinalPrice(
              bill.quantity,
              bill.sellingPriceWOT,
              bill.discount ?? 0,
              bill.gst
            )}
        </Text>

        <Text style={styles.totalVal}>
          {bill.serviceName &&
            (bill.startDate
              ? calculateServiceFinalPrice(
                  bill.totalAmount,
                  bill.discount ?? 0,
                  gst ? gst : 0
                )
              : bill.packageAmount
              ? bill.totalAmount
              : bill.totalAmount - bill.discount
            ).toFixed(2)}
        </Text>

        <Text style={styles.totalVal}>
          {parseFloat(bill.amountPaid)?.toFixed(2) ?? 0}
        </Text>

        <Text style={styles.totalVal}>
          ₹{" "}
          {updatedBalanceAmt
            ? parseFloat(updatedBalanceAmt)?.toFixed(2)
            : bill.productName
            ? calculateProductTotalBalance(
                bill.sellingPriceWOT,
                bill.quantity,
                bill.discount,
                bill.gst,
                bill.amountPaid
              )
            : (
                parseFloat(bill.totalAmount) -
                parseFloat(bill.discount ?? 0) -
                parseFloat(bill.amountPaid ?? 0)
              )?.toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

const Socials = ({ user, note }) => {
  // Extract usernames from social media URLs
  const facebookUsername = user.fb ? extractUsername(user.fb) : "";
  const twitterUsername = user.twitter ? extractUsername(user.twitter) : "";
  const instagramUsername = user.instagram
    ? extractUsername(user.instagram)
    : "";
  return (
    <View style={styles.footer}>
      {note && note !== "" && (
        <View style={styles.termText}>
          <Text style={styles.termsText1}>Terms & conditions: </Text>
          <Text style={styles.termsText2}>{note}</Text>
        </View>
      )}
      <Text style={styles.footerText}>Thank you for your business!</Text>
      <View style={styles.socialMedia}>
        {user.fb && (
          <View style={styles.socialMedia}>
            <Image src={facebookIcon} style={styles.icon} />
            <Text style={styles.username}>@{facebookUsername}</Text>
          </View>
        )}
        {user.twitter && (
          <View style={styles.socialMedia}>
            <Image src={twitterIcon} style={styles.icon} />
            <Text style={styles.username}>@{twitterUsername}</Text>
          </View>
        )}
        {user.instagram && (
          <View style={styles.socialMedia}>
            <Image src={instagramIcon} style={styles.icon} />
            <Text style={styles.username}>@{instagramUsername}</Text>
          </View>
        )}
      </View>

      {user?.signature && user.signature !== "" && (
        <View style={styles.termSig}>
          <View>
            <Image style={styles.signature} src={user.signature} />
            <Text style={styles.termsText1}>Authority Signature</Text>
          </View>
        </View>
      )}
    </View>
  );
};

const Footer = ({ user }) => {
  return (
    <View style={styles.contact}>
      <Text>If you have questions about this invoice, please contact</Text>
      <Text style={styles.details}>
        {user.name}, {user.phoneNumber}, {user.email}
      </Text>
      {user.qr && user.qr !== "" && <Image style={styles.qr} src={user.qr} />}
    </View>
  );
};

// Create Document Component
const MyDocument = ({ user, bill, note, type }) => {
  return (
    <Document>
      <Page style={styles.page} size="A3">
        <Header user={user} />
        <HeaderContent user={user} />
        <CustomerInfo bill={bill} />
        <ProductServiceSection bill={bill} type={type} />
        <InvoiceAmount bill={bill} />
        <Footer user={user} />
        <Socials user={user} note={note} />
      </Page>
    </Document>
  );
};

export default MyDocument;
