import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { EditSoldProduct } from "store/features/clients";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { calculateProductTotalBalance } from "utils/common";
import { calculateProductFinalPrice } from "utils/common";
import CustomButton from "containers/CustomButton";

function Modal({
  open,
  handleCloseModal,
  selectedItem,
  clientId,
  isSuccess,
  isError,
  message,
  viewHandler,
  setOpenModal,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      sellingPriceWOT: null,
      discount: 0,
      gst: 0,
      amountPaid: 0,
      qty: null,
      timestamp: new Date(),
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      sellingPriceWOT: false,
      discount: false,
      amountPaid: false,
      qty: false,
      gst: false,
      timestamp: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for handling selected items
  useEffect(() => {
    setFormdata({
      ...defaultHandler,
      ...selectedItem,
      sellingPriceWOT: selectedItem?.mrp,
      qty: selectedItem?.quantity,
      timestamp: selectedItem?.purchasedAt?.seconds
        ? timestampConversion(selectedItem?.purchasedAt)._d
        : selectedItem?.purchasedAt,
    });
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const [loader, setLoader] = useState(false);
  const submitHandler = async (e) => {
    if (!formdata.sellingPriceWOT || formdata.sellingPriceWOT === "") {
      toast.error("Please fill Selling Price WOT field");
      setErrorMessage({
        ...errorMessage,
        sellingPriceWOT: true,
      });
      return;
    }

    if (
      isNaN(formdata.discount) ||
      formdata.discount === null ||
      formdata.discount === ""
    ) {
      toast.error("Please fill Discount field");

      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (isNaN(formdata.gst) || formdata.gst === null || formdata.gst === "") {
      toast.error("Please fill GST field");
      setErrorMessage({
        ...errorMessage,
        gst: true,
      });
      return;
    }

    if (!formdata.qty) {
      toast.error("Please fill QTY. field");
      setErrorMessage({
        ...errorMessage,
        qty: true,
      });
      return;
    }

    if (!formdata.amountPaid && formdata.amountPaid !== 0) {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (parseInt(formdata.qty) === 0) {
      toast.error("Please fill QTY. field");
      setErrorMessage({
        ...errorMessage,
        qty: true,
      });
      return;
    }

    if (!formdata.timestamp) {
      toast.error("Please fill Date/Time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    setLoader(true);
    dispatch(EditSoldProduct({ formdata, clientId }));
  };

  //for calc selling price with tax
  const calcSellingPrice = () => {
    return calculateProductFinalPrice(
      formdata.quantity,
      formdata.sellingPriceWOT,
      formdata.discount,
      formdata.gst
    );
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setTimeout(() => {
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    defaultHandler,
    message,
    setLoader,
  ]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="60%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            {selectedItem?.productName}
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            Provide sold product details
          </SuiTypography>
        </SuiBox>

        <Grid item xs={12}>
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setOpenModal(false);
                viewHandler(selectedItem, "product");
              }}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography variant="h6">MRP</SuiTypography>

                <SuiTypography variant="caption">{formdata.mrp}</SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  Selling Price WOT
                </SuiTypography>

                <SuiInput
                  type="number"
                  error={errorMessage?.sellingPriceWOT}
                  placeholder=""
                  name="sellingPriceWOT"
                  value={formdata.sellingPriceWOT ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  GST(%)
                </SuiTypography>

                <SuiInput
                  type="number"
                  placeholder=""
                  name="gst"
                  error={errorMessage?.gst}
                  value={formdata?.gst ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      gst: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Discount/product
                </SuiTypography>

                <SuiInput
                  type="number"
                  placeholder=""
                  name="discount"
                  error={errorMessage?.discount}
                  value={formdata?.discount ?? 0}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    if (e.target.value < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        [e.target.name]: 0,
                      });
                      return;
                    }

                    if (e.target.value > parseFloat(calcSellingPrice())) {
                      toast.info("Can't exceed");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });

                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  QTY.
                </SuiTypography>

                <SuiInput
                  type="number"
                  placeholder=""
                  name="qty"
                  error={errorMessage?.qty}
                  value={formdata?.qty ?? ""}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (formdata.availableQuantity < val) {
                      toast.info(
                        `Available quantity are only ${formdata.availableQuantity}, can't exceed`
                      );
                      setFormdata({
                        ...formdata,
                        qty: 0,
                      });
                      return;
                    }

                    if (val < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        qty: 0,
                      });
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseInt(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Selling Price WT
                </SuiTypography>
                <SuiTypography variant="caption">
                  {!isNaN(calcSellingPrice())
                    ? "INR " + calcSellingPrice()
                    : "N/A"}
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="amountPaid"
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+(\.\d{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (e.target.value < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        [e.target.name]: 0,
                      });
                      return;
                    }
                    if (
                      parseFloat(e.target.value) >
                      calculateProductFinalPrice(
                        formdata.qty,
                        formdata.sellingPriceWOT,
                        formdata.discount,
                        formdata.gst
                      )
                    ) {
                      toast.info("Can't exceed the total amount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });

                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Balance
                </SuiTypography>
                <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                  {"INR "}
                  {isNaN(
                    calculateProductTotalBalance(
                      formdata.sellingPriceWOT,
                      formdata.qty,
                      formdata.discount,
                      formdata.gst,
                      formdata.amountPaid
                    )
                  )
                    ? "-"
                    : calculateProductTotalBalance(
                        formdata.sellingPriceWOT,
                        formdata.qty,
                        formdata.discount,
                        formdata.gst,
                        formdata.amountPaid
                      ) ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Date/time
                </SuiTypography>
                <SuiInput
                  type="datetime-local"
                  name="timestamp"
                  placeholder=""
                  style={{ paddingRight: "1px" }}
                  error={errorMessage?.timestamp}
                  value={
                    formdata.timestamp
                      ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                      : moment().format("YYYY-MM-DDTkk:mm")
                  }
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <Divider />

        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={"Edit product"}
              loader={loader}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
