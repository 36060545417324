import React, { useEffect, useState } from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { isAllOptionInserted } from "utils/common";
import SuiTypography from "components/SuiTypography";

function ProductSearch({
  productOptions,
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  excelDownload,
  familyArray,
}) {
  const [statusOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "Active",
      label: "Paid",
    },
    {
      value: "Inactive",
      label: "Not paid",
    },
  ]);

  if (!isAllOptionInserted(familyArray)) {
    familyArray?.unshift({
      value: "all",
      label: "All",
    });
  }

  if (!isAllOptionInserted(productOptions)) {
    productOptions?.unshift({
      value: "all",
      label: "All",
    });
  }

  const [productOpt_, setProductOpt_] = useState(null); //for selecting products
  const [statusOpt_, setStatusOpt_] = useState(null); //for selecting status
  const [familyNameOpt_, setFamilyOpt_] = useState([]); //for selecting family member

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            From Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            To Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Family Name
          </SuiTypography>
          <Createable
            options={familyArray ?? []}
            value={familyNameOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 100, height: "1.800rem" }}
            placeholder="Family Name"
            onChange={(e) => {
              setFamilyOpt_(e);
              setFilterQuery({
                ...filterQuery,
                familyName: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Payment Status
          </SuiTypography>
          <Createable
            options={statusOptions ?? []}
            value={statusOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 100, height: "1.800rem" }}
            placeholder="Payment status"
            onChange={(e) => {
              setStatusOpt_(e);
              setFilterQuery({
                ...filterQuery,
                status: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose Product
          </SuiTypography>
          <Createable
            options={productOptions ?? []}
            value={productOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose Product"
            onChange={(e) => {
              setProductOpt_(e);
              setFilterQuery({
                ...filterQuery,
                product: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={2} mr={2.5} display="flex">
        <SuiBox mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>

        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setStatusOpt_(null);
            setProductOpt_(null);
            setFamilyOpt_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default ProductSearch;
