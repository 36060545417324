import React from 'react'
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import Grid from "@mui/material/Grid";


function CustomChart({ title, data, desc }) {

    return (
        <Grid item xs={12} lg={12}>
            <DefaultLineChart
                title={title}
                // description={
                //     <SuiBox display="flex" alignItems="center">
                //         <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                //             <Icon className="font-bold">arrow_upward</Icon>
                //         </SuiBox>
                //         <SuiTypography variant="button" color="text" fontWeight="medium">
                //             {desc}
                //         </SuiTypography>
                //     </SuiBox>
                // }
                height="22.7rem"
                chart={data}
            />
        </Grid>
    )
}

export default CustomChart