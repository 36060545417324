import React, { useState, useEffect, useCallback } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Table from "examples/Tables/Table";
import moment from "moment";
import NoData from "containers/DataNotAvailBox";
import { dietChartRow } from "./components/DietRow/rows.js";
import AccountHistory from "./components/Account";
import Profile from "./components/Profile";
import Grid from "@mui/material/Grid";
import { clearDietState } from "store/features/dietchart";
import { userSelector } from "store/features/authSlice";
import { generatePDFDocument } from "utils/pdf";
import DietChartRecipeSearch from "./components/DietRow/search";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getDietChartSettings } from "store/features/authSlice";

function DietChartTable({
  selectedClient,

  isSuccess,
  isFetching,
  editHandler,
  deleteHandler,
  setNewPaymentModal,
  setSelectedItem,
  dietChartArray,
  dispatch,
  setMode,
  viewHandler,
}) {
  const { uid, dietChartSettings } = useSelector(userSelector);
  useEffect(() => {
    dispatch(getDietChartSettings({ uid }));
  }, [dispatch, uid]);
  const [rows, setRows] = useState([]); // rows for recipe table
  const [loader, setLoader] = useState(false);

  //for setting diet chart
  const [dietChartArrayv1, setDietChartArrayv1] = useState([]);
  useEffect(() => {
    let dietChartArrayv2 = dietChartArray.slice();
    setDietChartArrayv1(dietChartArrayv2);
  }, [dietChartArray]);

  //clear diet state
  useEffect(() => {
    return () => {
      dispatch(clearDietState());
    };
  }, [dispatch]);

  const { logo } = useSelector(userSelector);
  const [chartArray, setChartArray] = useState([]);

  const columns = [
    {
      name: "Mail & Download",
      align: "left",
      minWidth: "190px",
      component: (
        <SuiBox
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            opacity: 1,
          }}
        >
          {chartArray.length > 0 ? (
            <p
              style={{
                fontSize: "0.625rem",
                fontWeight: 700,
                color: "#17c1e8",
                cursor: "pointer",
              }}
              onClick={() => {
                generatePDFDocument(
                  dietChartSettings,
                  chartArray,
                  selectedClient,
                  logo,
                  setLoader
                );
              }}
            >
              MAIL & DOWNLOAD NOW
            </p>
          ) : (
            <p
              style={{
                color: "#8392ab",
                fontSize: "0.625rem",
                fontWeight: 700,
                cursor: "not-allowed",
                display: "flex",
              }}
            >
              MAIL & DOWNLOAD NOW
              {loader && <TailSpin color="#17c1e8" height={20} width={20} />}
            </p>
          )}
        </SuiBox>
      ),
    },
    { name: "Date/Time (Added)", align: "left", minWidth: "200px" },
    { name: "Week Plan", align: "left", minWidth: "100px" },
    { name: "Chart Type", align: "left", minWidth: "100px" },

    { name: "Diet Date for Client", align: "left", minWidth: "150px" },
    // { name: "Early Morning", align: "left", minWidth: "200px" },
    // { name: "Breakfast", align: "left", minWidth: "200px" },
    // { name: "Mid Morning", align: "left", minWidth: "200px" },
    // { name: "Brunch", align: "left", minWidth: "200px" },
    // { name: "Mid Meal", align: "left", minWidth: "200px" },
    // { name: "Lunch", align: "left", minWidth: "200px" },
    // { name: "Post Lunch", align: "left", minWidth: "200px" },

    // { name: "Evening", align: "left", minWidth: "200px" },
    // { name: "Before Dinner", align: "left", minWidth: "200px" },
    // { name: "Dinner", align: "left", minWidth: "200px" },

    // { name: "Post Dinner", align: "left", minWidth: "200px" },
    // { name: "Late Night", align: "left", minWidth: "200px" },
    // { name: "Notes", align: "left", minWidth: "200px" },

    { name: "Calories", align: "left", minWidth: "120px" },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  // const selectHandler =  useCallback((id, value,)=>{
  //     let chartArr = []
  //     if(value){
  //         chartArr = dietChartArrayv1.map((item)=>{
  //             if(id === item.dietChartId){
  //                 return {
  //                     ...item,
  //                     selected: true
  //                 }
  //             }
  //             return item
  //         })
  //     } else {
  //         chartArr = dietChartArrayv1.map((item)=>{
  //             if(id === item.dietChartId){
  //                 return {
  //                     ...item,
  //                     selected: false
  //                 }
  //             }
  //             return item
  //         })
  //     }

  //     setDietChartArrayv1(chartArr)
  // },[dietChartArrayv1])

  const selectHandler = useCallback(
    (item, value, index) => {
      let chartArr = dietChartArrayv1.slice();
      const chartObj = {
        ...item,
        selected: value,
      };

      chartArr[index] = chartObj;

      setDietChartArrayv1([...chartArr]);
    },
    [dietChartArrayv1]
  );

  useEffect(() => {
    setRows(
      dietChartRow(
        dietChartArrayv1 ?? [],
        editHandler,
        deleteHandler,
        setChartArray,
        selectHandler,
        viewHandler
      )
    );
  }, [
    dietChartArrayv1,
    editHandler,
    deleteHandler,
    selectHandler,
    viewHandler,
  ]);

  const [filterQuery, setFilterQuery] = useState({
    chartType: null,
    weekPlan: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  const filterHandler = () => {
    let filterData = dietChartArray.filter((item) => {
      if (filterQuery.weekPlan) {
        if (filterQuery.weekPlan === "all") {
          setFilterQuery({ ...filterQuery, weekPlan: null });
        } else if (filterQuery.weekPlan !== item["weekPlan"]) {
          return false;
        }
      }

      if (filterQuery.chartType) {
        if (filterQuery.chartType === "all") {
          setFilterQuery({ ...filterQuery, chartType: null });
        } else if (filterQuery.chartType !== item["chartType"]) {
          return false;
        }
      }
      let createdAt;
      createdAt = item["date"];
      if (filterQuery.fromDate) {
        if (new Date(filterQuery.fromDate) > new Date(createdAt)) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        if (moment.utc(moment(filterQuery.toDate)).isBefore(createdAt)) {
          return false;
        }
      }

      return item;
    });
    setDietChartArrayv1(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      weekPlan: null,
      chartType: null,
      fromDate: null,
      toDate: null,
    });
    setDietChartArrayv1(dietChartArray);
    setChartArray([]);
  }, [dietChartArray]);

  useEffect(() => {
    if (isSuccess) {
      setChartArray([]);
    }
  }, [isSuccess]);

  return (
    <SuiBox mb={3}>
      <SuiBox py={3}>
        <SuiBox mb={3} px={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={5}>
              <Profile
                name={selectedClient?.name}
                address={selectedClient?.address}
                phoneNumber={selectedClient?.phoneNumber}
                email={selectedClient?.email}
                gender={selectedClient?.gender}
                age={selectedClient?.age}
                leadFrom={selectedClient?.leadFrom}
                occupation={selectedClient?.occupation}
                customerId={selectedClient?.customerId ?? "N/A"}
                foodPreference={selectedClient?.preference}
                referredBy={selectedClient?.referredBy}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <AccountHistory
                history={selectedClient?.services ?? []}
                title="Service, Payment & Balance History"
                isSuccess={isSuccess}
                viewHandler={viewHandler}
                addHandler={() => {
                  setMode("create");
                  setNewPaymentModal(true);
                }}
                editHandler={(item) => {
                  setMode("edit");
                  setSelectedItem(item);
                  setNewPaymentModal(true);
                }}
                isFetching={isFetching}
                loader={loader}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <DietChartRecipeSearch
        setFilterQuery={setFilterQuery}
        filterHandler={filterHandler}
        filterQuery={filterQuery}
        resetHandler={resetHandler}
      />

      <SuiBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        {rows.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>

      {chartArray.length > 0 ? (
        <div
          style={{
            position: "sticky",
            width: "100%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "end",
            bottom: "2.6rem",
            padding: "1rem",
          }}
        >
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={() => {
              generatePDFDocument(
                dietChartSettings,
                chartArray,
                selectedClient,
                logo,
                setLoader
              );
            }}
          >
            Mail & Download Diet Chart
          </SuiButton>
        </div>
      ) : null}
    </SuiBox>
  );
}

export default DietChartTable;
