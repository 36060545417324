import { excelAndDbProductFieldsMapping } from "constant";
import { toast } from "react-toastify";
import { checkProductExists } from "services/offering";
import * as yup from "yup";

const productSchema = yup.object().shape({
  productName: yup.string().required("Product Name is required"),
  brand: yup.string().required("Brand is required"),
  description: yup.string().required("Description is required"),
  costPriceWOT: yup
    .number()
    .required("Cost Price (without tax) is required")
    .positive("Cost Price must be a positive number")
    .min(0, "Cost Price must be greater than or equal to 0"),
  gst: yup
    .number()
    .required("GST is required")
    .positive("GST must be a positive number")
    .min(0, "GST must be greater than or equal to 0"),
  mrp: yup
    .number()
    .required("MRP is required")
    .positive("MRP must be a positive number")
    .min(0, "MRP must be greater than or equal to 0"),
  availableQuantity: yup
    .number()
    .required("Available Quantity is required")
    .positive("Available Quantity must be a positive number")
    .min(0, "Available Quantity must be greater than or equal to 0"),
}).unknown();;

/**---------------Excel validations ---------------- */

export const validateExcelHeadersForProducts = (dataArray) => {
  let headerFieldCount = 0;

  if (dataArray.length === 0) {
    toast.error("Excel file is corrupted, please download a new one!!");
    return false;
  }

  for (const mapping of excelAndDbProductFieldsMapping) {
    const excelHeader = mapping.excelName;
    const columnIndex = dataArray[0].indexOf(excelHeader);

    if (columnIndex !== -1) {
      headerFieldCount++;
    }
  }

  if (headerFieldCount !== excelAndDbProductFieldsMapping.length) {
    toast.error("Excel file is corrupted, please download a new one!!");
    return false;
  }

  if (dataArray.length === 1) {
    toast.error("File is empty, please add products!!");
    return false;
  }

  return true;
};

export const validateAndExtractCellValuesForProducts = async (
  dataArray,
  adminId
) => {
  const extractedData = [];
  const errors = [];
  const productNamesObj = {}

  for (let i = 1; i < dataArray.length; i++) {
    const dataRow = dataArray[i];
    const productData = {
      adminId: adminId,
      createdAt: new Date(),
    };

    productData.productName = dataRow[dataArray[0].indexOf("Product Name")];
    productData.brand = dataRow[dataArray[0].indexOf("Brand")];
    productData.description = dataRow[dataArray[0].indexOf("Description")];
    productData.costPriceWOT = parseFloat(
      dataRow[dataArray[0].indexOf("Cost Price (without tax)")]
    );
    productData.gst = parseFloat(dataRow[dataArray[0].indexOf("GST")]);
    productData.mrp = parseFloat(dataRow[dataArray[0].indexOf("MRP")]);
    productData.availableQuantity = parseFloat(
      dataRow[dataArray[0].indexOf("Available Quantity")]
    );

    try {
      await productSchema.validate(productData, { abortEarly: false });

      const productNameIndex = dataArray[0].indexOf("Product Name");
      if (productNameIndex !== -1) {
        const productName = dataRow[productNameIndex];
        const existingProduct = await checkProductExists(productName, adminId);

        if (existingProduct) {
          errors.push({
            row: i + 1,
            field: "Product Name",
            message: `Product with the name "${productName}" already exists.`,
          });
        }
        const isDuplicateProductNameInExcel = productNamesObj[productName];
        if(isDuplicateProductNameInExcel){
          errors.push({
            row: i + 1,
            field: "Product Name",
            message: `Product with the name "${productName}" already exists in the excel.`,
          });
        } 
        extractedData.push(productData);
      }
      productNamesObj[dataRow[productNameIndex]] = true;
    } catch (validationError) {
      if (validationError.name === "ValidationError") {
        const rowErrors = validationError.inner.map((error) => ({
          row: i + 1,
          field: error.path,
          message: error.message,
        }));
        errors.push(...rowErrors);
      }
    }
  }
  return { extractedData, errors };
};
