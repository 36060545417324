export const style = {
    searchbar : {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    close: {
        position: 'absolute',
        right: 5,
        top: '20%',
        bottom: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'pointer',
        zIndex: '-1 !important'
    }
}