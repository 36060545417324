import React from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import { style } from "./style.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

function RadioInput({
  defaultValue,
  options,
  value,
  handleChange,
  style_,
  disabled,
}) {
  return (
    <SuiBox ml={0.5} style={{ ...style_ }}>
      <RadioGroup
        aria-label="radio-input"
        name="radio-input"
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio color="secondary" size="small" disabled={disabled} />
            }
            label={
              <SuiTypography variant="h6" style={{ marginRight: 10 }}>
                {option.label}
              </SuiTypography>
            }
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </SuiBox>
  );
}

export default RadioInput;
