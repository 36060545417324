import React from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


function CustomeDatepicker({ selectedDate, onChangeDate, includeDates, placeholder }) {
    return (
        <DatePicker
            selected={selectedDate}
            onChange={onChangeDate}
            includeDates={[ ...includeDates.map((item) => item.date) ]}
            placeholderText={placeholder}
            dateFormat="dd/MM/yyyy"
            className="date-picker-custom-v1"
        />
    )
}

export default CustomeDatepicker