import React, { useState, useEffect } from 'react'
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { calculateProductTotalBalance } from 'utils/common'


function ProductPurchaseStat({ products }) {

    const [paid, setPaid] = useState(0)
    const [balance, setBalance] = useState(0)
    useEffect(() => {
        let balanceAmount = 0;
        let paidAmount = 0;
        products.map((item) => {
            paidAmount += parseFloat(item.amountPaid ?? 0)
            balanceAmount += parseFloat(calculateProductTotalBalance(item.sellingPriceWOT,
                item.quantity, item.discount, item.gst, item.amountPaid))
            return item
        })
        setPaid(paidAmount?.toFixed(2))
        setBalance(balanceAmount?.toFixed(2))
    }, [products])

    return (
        <Grid container mb={2}>
            <Grid item xs={10} md={5} lg={3} mb={1}>
                <SuiBox p={1} mx={2} mr={2} style={styles.tab}>
                    <SuiTypography
                        variant="h8"
                        mr={1}
                        fontWeight="medium">
                        Total Amount Paid:
                    </SuiTypography>
                    <SuiTypography
                        variant="h9"
                        fontWeight="medium">
                        {paid}
                    </SuiTypography>
                </SuiBox>
            </Grid>

            <Grid item xs={8} lg={2.5}>
                <SuiBox p={1} mx={2} style={styles.tab}>
                    <SuiTypography
                        variant="h8"
                        mr={1}
                        fontWeight="medium">
                        Balance:
                    </SuiTypography>
                    <SuiTypography
                        variant="h9"
                        fontWeight="medium"
                        style={{ color: 'red' }}>
                        {balance}
                    </SuiTypography>
                </SuiBox>
            </Grid>

        </Grid>
    )
}

export default ProductPurchaseStat;

/**----------------- styling ----------------- */

const styles = {
    tab: {
        borderRadius: 8,
        display: 'flex',
        background: "#F8F9FA"
    }

}

