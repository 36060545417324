
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SuiTypography from "components/SuiTypography";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CalorieCalculator from "./sub-components/calorie-calculator";
import { useState } from "react";
import BodyFatCalculator from "./sub-components/bodyfat-calculator";
import BMRCalculator from "./sub-components/bmr-calculator";
import OneRMCalculator from "./sub-components/one-rm-calculator";
import MacroCalculator from "./sub-components/macro-calculator";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <SuiBox sx={{ p: 3 }}>{children}</SuiBox>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ClientDetail() {

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox py={3}>
                <Card>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={3}>
                            <SuiBox
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                                pl={2.5}
                                mb={-1}
                                mt={3}
                            >
                                <SuiTypography variant="h6">Calculators</SuiTypography>
                            </SuiBox>
                            <Divider />
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={tabValue}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{
                                    borderRight: 1,
                                    borderColor: "divider",
                                    alignItems: "left",
                                    background: "transparent",
                                    minHeight: "100%",
                                    borderRadius: 0,
                                    "&.MuiTabs-flexContainer": {
                                        alignItems: "flex-start !important",
                                    },
                                    "&.MuiTabs-flexContainerVertical": {
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start !important",
                                    },
                                }}
                            >
                                <Tab
                                    label="Calorie Calculator"
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#17C1E8",
                                            color: "white !important",
                                        },
                                        "&.MuiTab-root": {
                                            justifyContent: "flex-start",
                                            paddingLeft: "10px !important",
                                            textAlign: "left !important",
                                        },
                                    }}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label="Body Fat Calculator"
                                    {...a11yProps(1)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#17C1E8",
                                            color: "white !important",
                                        },
                                        "&.MuiTab-root": {
                                            justifyContent: "flex-start",
                                            paddingLeft: "10px !important",
                                        },
                                    }}
                                />
                                <Tab
                                    label="BMR Calculator"
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#17C1E8",
                                            color: "white !important",
                                        },
                                        "&.MuiTab-root": {
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            paddingLeft: "10px !important",
                                            textAlign: "left !important",
                                        },
                                    }}
                                    {...a11yProps(2)}
                                />

                                <Tab
                                    label="1RM Calculator"
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#17C1E8",
                                            color: "white !important",
                                        },
                                        "&.MuiTab-root": {
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            paddingLeft: "10px !important",
                                        },
                                    }}
                                    {...a11yProps(3)}
                                />

                                <Tab
                                    label="Macro Calculator"
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#17C1E8",
                                            color: "white !important",
                                        },
                                        "&.MuiTab-root": {
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            paddingLeft: "10px !important",
                                        },
                                    }}
                                    {...a11yProps(4)}
                                />
                            </Tabs>
                        </Grid>

                        <Grid item xs={12} md={9} lg={9}>
                            <TabPanel value={tabValue} index={0}>
                                <CalorieCalculator />
                            </TabPanel>

                            <TabPanel value={tabValue} index={1}>
                                <BodyFatCalculator />
                            </TabPanel>
                            
                            <TabPanel value={tabValue} index={2}>
                                <BMRCalculator />
                            </TabPanel>

                            <TabPanel value={tabValue} index={3}>
                                <OneRMCalculator />
                            </TabPanel>

                            <TabPanel value={tabValue} index={4}>
                                <MacroCalculator />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Card>
            </SuiBox>


        </DashboardLayout>
    );
}

export default ClientDetail;

