import { StyleSheet, Font } from "@react-pdf/renderer";
import poppinBlack from "../../../assets/fonts/Poppins-Black.ttf";
import poppinLight from "../../../assets/fonts/Poppins-Light.ttf";
import poppinRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinSemiBold from "../../../assets/fonts/Poppins-SemiBold.ttf";
import poppinMedium from "../../../assets/fonts/Poppins-Medium.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: poppinLight,
      fontWeight: 300,
    },

    {
      src: poppinRegular,
      fontWeight: 400,
    },

    {
      src: poppinMedium,
      fontWeight: 500,
    },

    {
      src: poppinBlack,
    },

    {
      src: poppinSemiBold,
      fontWeight: 600,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 10,
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontFamily: "Poppins",
    marginTop: 15, 
  },

  logoImage: {
    width: 120, 
    height: 30, 
    objectFit: "contain",
    marginBottom: 5,
  },

  qr: {
    width: 120, 
    height: 120, 
    objectFit: "contain",
    marginBottom: 5,
  },

  signature: {
    width: 120, 
    height: 120, 
    objectFit: "contain",
  },

  company: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 12, 
    marginBottom: 0,
    paddingLeft: 6,
  },

  address: {
    width: 300,
  },

  title: {
    fontSize: 24, 
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#057AC1",
  },

  mainHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  headerRow: {
    flexDirection: "row",
    backgroundColor: "#057AC1",
    padding: 3,
    paddingLeft: 6,
    marginBottom: 3,
    color: "white",
  },

  headerContent: {
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 15, 
  },

  text: {
    fontFamily: "Poppins",
    fontSize: 12, 
    fontWeight: 500,
  },

  dataRow: {
    flexDirection: "row",
  },

  invoice: {
    width: 120,
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 12, 
    fontWeight: 500,
  },

  date: {
    width: 120,
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 12, 
    fontWeight: 500,
  },

  terms: {
    width: 120,
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 12, 
    fontWeight: 500,
  },

  termSig: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "absolute",
    bottom: -30,
    marginTop: 15, 
  },

  termText: {
    flexDirection: "row",
    marginBottom: 5,
    maxWidth: "90%",
  },

  termsText1: {
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 500,
    padding: 5,
    maxWidth: "80%",
  },

  termsText2: {
    fontFamily: "Poppins",
    fontSize: 9, 
    fontWeight: 400,
    padding: 5,
    maxWidth: "80%",
  },

  padding: {
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: 11, 
    marginBottom: 0,
    paddingLeft: 6,
  },

  main: {
    marginTop: 25, 
  },

  mainRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#057AC1",
    padding: 3,
    paddingLeft: 6,
    color: "white",
    width: "100%",
  },

  desc: {
    width: "40%",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 500,
  },

  datesection: {
    width: "30%",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 500,
  },

  qty: {
    width: "30%",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 500,
  },

  unit: {
    width: "15%",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 500,
  },

  amount: {
    width: "15%",
  },

  amountHeader: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 11,
  },
  mainDataRow: {
    width: "100%",
    flexDirection: "row",
  },

  borderRightTop: {
    borderRight: "1px dotted black",
    borderBottom: "1px dotted #838181",
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: 11, 
    paddingLeft: 6,
  },

  invoiceAmount: {
    flexDirection: "row",
    width: "100%",
  },

  blank: {
    width: "65%",
  },

  summaryTitle: {
    width: "25%",
    padding: 7, // Slightly increased padding
    backgroundColor: "#7ABAE0",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 400,
  },

  summaryValue: {
    width: "30%",
    padding: 7, // Slightly increased padding
    backgroundColor: "#7CC7F3",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 400,
  },

  total: {
    fontFamily: "Poppins",
    fontSize: 13, 
    fontWeight: 500,
    color: "#094C75",
  },

  balance: {
    fontFamily: "Poppins",
    fontSize: 13, 
    fontWeight: 500,
    color: "red",
  },

  totalVal: {
    fontFamily: "Poppins",
    fontSize: 13, 
    fontWeight: 500,
  },

  contact: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 15, 
    justifyContent: "space-between",
    fontFamily: "Poppins",
    fontSize: 11, 
    fontWeight: 400,
  },

  details: {
    fontWeight: 500,
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  footerText: {
    fontFamily: "Poppins",
    fontSize: 11, 
    marginBottom: 5,
  },

  
  socialMedia: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 12, 
  },

  icon: {
    width: 12, 
    height: 12, 
    marginRight: 3, 
  },

  socialIcon: {
    width: 12, 
    height: 12, 
    marginRight: 3, 
  },

  username: {
    fontFamily: "Poppins",
    fontSize: 11, 
    marginLeft: 1,
  },
});

export default styles;
