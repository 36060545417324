// CalorieCalculator.js
import React, { useState } from 'react';
import {
    Container,
    Paper,
    Grid,
} from '@mui/material';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiInput from 'components/SuiInput';
import Createable from 'containers/Select';
import { genderOptions } from 'constant';
import { toast } from 'react-toastify';

const CalorieCalculator = () => {
    const [weight, setWeight] = useState('50');
    const [height, setHeight] = useState('170');
    const [age, setAge] = useState('25');
    const [gender, setGender] = useState("");
    const [calories, setCalories] = useState(null);


    const calculateCalories = () => {
        if (weight && height && age && gender) {
            let basicCalculation;

            if (gender.value === 'Male' || gender.value === 'Other') {
                basicCalculation = 10 * weight + 6.25 * height - 5 * age + 5;
            } else if (gender.value === 'Female') {
                basicCalculation = 10 * weight + 6.25 * height - 5 * age - 161;
            } else {
                basicCalculation = 0;
            }

            setCalories(basicCalculation);
        } else {
            setCalories(null);
            toast.error('Please fill all the fields');
        }
    };


    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
                <SuiTypography variant="h4" mb={2}>Calorie Calculator</SuiTypography>
                <form>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Weight (in Kgs)
                            </SuiTypography>
                            <SuiInput
                                type="number"
                                name="weight"
                                placeholder="Enter weight"
                                value={weight}
                                onChange={(e) => {
                                    setWeight(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Height (in cm)
                            </SuiTypography>
                            <SuiInput
                                type="number"
                                name="height"
                                placeholder="Enter height"
                                value={height}
                                onChange={(e) => {
                                    setHeight(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Age
                            </SuiTypography>
                            <SuiInput
                                type="number"
                                name="age"
                                placeholder="Enter age"
                                value={age}
                                onChange={(e) => {
                                    setAge(e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SuiTypography mb={1} variant="h6">
                                Gender
                            </SuiTypography>
                            <Createable
                                options={genderOptions}
                                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                                inputStyle={{
                                    width: 190,
                                    height: "1.850rem",
                                    borderRadius: "0.5rem",
                                }}
                                onChange={(e) => {
                                    setGender(e);
                                }}

                                value={gender}
                            />
                        </Grid>

                    </Grid>
                    <SuiButton
                        fullWidth
                        variant="contained"
                        color="dark"
                        onClick={calculateCalories}
                        style={{ padding: '10px 0' }}
                    >
                        Calculate Calories
                    </SuiButton>
                </form>
                {calories !== null && (
                    <SuiTypography variant="h6" style={{ marginTop: '20px' }}>
                        Estimated Calories: {calories}
                    </SuiTypography>
                )}
            </Paper>
        </Container>
    );
};

export default CalorieCalculator;
