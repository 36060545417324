import { toast } from "react-toastify";

export async function submitDataHandler({
    setLoader, selectedItem, addSubmitHandler, editSubmitHandler,  
    setSelectedClient, key,
    handleCloseModal, setSelectedItem, setFormdata, 
    setErrorMessage, defaultHandler, errorDefault
}) {
    try {
        setLoader(true)
        let data;
        if (selectedItem) {
            data = await editSubmitHandler()
            setSelectedClient((prevClientState) => {
                const updatedClientState = { ...prevClientState };
                updatedClientState[key] = [ ...data ];
                return updatedClientState;
            });
            toast.success('Successfully updated')
        } else {
            data = await addSubmitHandler()
            setSelectedClient((prevClientState) => {
                const updatedClientState = { ...prevClientState };
                updatedClientState[key] = key in updatedClientState ? [ ...updatedClientState[key], data ] : [ data ];
                return updatedClientState;
            });
            toast.success('Successfully added')
        }
        handleCloseModal()

        setTimeout(() => {
            setSelectedItem(null)
            setLoader(false)
            setFormdata({
                ...defaultHandler
            })
            setErrorMessage({
                ...errorDefault
            })
        }, 500);

    } catch (e) {
        toast.error(e?.message ?? 'Something went wrong, retry')
        setLoader(false)
    }
}