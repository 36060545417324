import React, { useState, useEffect } from "react";
import { updateClientData } from "services/customer";
import { toast } from "react-toastify";
import { useCallback } from "react";
import PhysicalModal from "containers/clientDetails/sub-components/modal/physical";
import { physicalActivityTrackerRows } from "containers/clientDetails/sub-components/common/physicalActivityTrackerRows";
import SwipableModal from "containers/Customer/modal";
import { physicalActivityTableColumns } from "utils/tablesHeaderContent";
import ClientInfoCard from "containers/clientDetails/sub-components/card/clientCard";
import { headerDesc } from "utils/helper";

function PhysicalActivityTracker({ clientId, physicalActs, deleteHandler }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([...physicalActivityTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [physicalActData, setPhysicalActData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("create");

  useEffect(() => {
    if (physicalActs.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setPhysicalActData(physicalActs);
  }, [physicalActs]);

  useEffect(() => {
    if (disableButton && physicalActData.length > 0) {
    }
  }, [physicalActData, disableButton]);

  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setOpenAddModal(true);
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("view");
    setOpenAddModal(true);
  }, []);

  useEffect(() => {
    setRows(
      physicalActivityTrackerRows(
        physicalActData,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [physicalActData, editHandler, viewHandler]);

  const addHandler = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "physical";

      await updateClientData(clientId, fieldKey, physicalActData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  const Modal = () => (
    <SwipableModal
      open={openAddModal}
      setOpenModal={setOpenAddModal}
      handleCloseModal={handleCloseModal}
      mode={mode}
      setMode={setMode}
      {...headerDesc(mode, "Physical details", selectedData)}
    >
      <PhysicalModal
        id={clientId}
        persona="client"
        mode={mode}
        open={openAddModal}
        selectedItem={selectedData}
        setMainData={setPhysicalActData}
        handleCloseModal={handleCloseModal}
      />
    </SwipableModal>
  );

  return (
    <ClientInfoCard
      title={"Physical Activity"}
      rows={rows}
      columns={columns}
      disableButton={disableButton}
      onSubmitHandler={submitHandler}
      loader={loader}
      onBtnClickHandler={addHandler}
      Modal={Modal}
    />
  );
}

export default PhysicalActivityTracker;
