import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
    const {  name } = company;
    const { size } = typography;

    return (
        <SuiBox
            display="flex"
            style={{ 
                position: 'fixed',
                bottom: 0,
                paddingTop: 5,
                paddingBottom: 5,
                background: 'white',
                width: '100%'
            }}
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
            px={1.5}>
            <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
                px={1.5}>
                Copyright &copy; {new Date().getFullYear()}, 

                powered by
                <Link href= "https://creware.asia/" target="_blank">
                    <SuiTypography variant="button" fontWeight="medium" style={{ color: 'red' }}>
                       &nbsp;{name}&nbsp;
                    </SuiTypography>
                </Link>
              </SuiBox>
        </SuiBox>
    );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
    company: { href: "", name: "Creware" },
};

// Typechecking props for the Footer
Footer.propTypes = {
    company: PropTypes.objectOf(PropTypes.string),
};

export default Footer;
