import React, { useState, useEffect, useMemo } from "react";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import CustomButton from "containers/CustomButton";
import expenseValidationSchema from "validations/expense";
import { expenseCategoryOptions } from "constant";
import SelectBar from "containers/SelectCreateable";
import { calculateTotal } from "utils/helper";
import { AddExpense } from "services/expense";
import { updateExpense } from "services/expense";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";

function Modal({
  open,
  selectedItem,
  setSelectedItem,
  adminId,
  setOpenModal,
  updateExpenseRow,
  mode,
  viewHandler,
  editHandler,
}) {
  const defaultHandler = useMemo(() => {
    return {
      expenseName: null,
      expenseCategory: null,
      dateOfExpense: moment().format("YYYY-MM-DD"),
      description: null,
      expenseAmount: 0,
      tax: 18,
    };
  }, []);

  const defaultErrorState = useMemo(() => {
    return {
      expenseName: false,
      expenseCategory: false,
      dateOfExpense: false,
      description: false,
      expenseAmount: false,
      tax: false,
    };
  }, []);

  const [errorMessage, setErrorMessage] = useState(defaultErrorState);

  const [loader, setLoader] = useState(false);
  const [formdata, setFormdata] = useState(defaultHandler);

  useEffect(() => {
    if (selectedItem && open) {
      const categoryIndex = expenseCategoryOptions.findIndex(
        (item) => item.value === selectedItem?.expenseCategory
      );

      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        expenseCategory: expenseCategoryOptions[categoryIndex],
        dateOfExpense: moment(selectedItem.dateOfExpense).format("YYYY-MM-DD"),
      });
    }
  }, [selectedItem, defaultHandler, open]);

  const submitHandler = async (e) => {
    setLoader(true);
    try {
      const expenseCategory = formdata?.expenseCategory?.value;
      const data = await expenseValidationSchema.validateAsync(
        { ...formdata, expenseCategory: expenseCategory ?? null },
        { abortEarly: true, stripUnknown: true }
      );
      if (selectedItem) {
        await updateExpense({
          ...data,
          expenseCategory: formdata?.expenseCategory?.value,
          id: selectedItem.id,
        });
        const updatedExpense = {
          ...data,
          expenseCategory: formdata?.expenseCategory?.value,
        };
        updateExpenseRow(updatedExpense, formdata.id);
        toast.success("Expense updated successfully");
      } else {
        const docId = await AddExpense(data, adminId);
        const newExpense = {
          ...data,
          id: docId,
          expenseCategory: data?.expenseCategory,
        };
        updateExpenseRow(newExpense);
        toast.success("Expense added successfully");
      }
      handleCloseModal();
    } catch (error) {
      if (error.name === "ValidationError") {
        toast.error(error?.message);
        error.details.forEach((detail) => {
          setErrorMessage({
            ...errorMessage,
            [detail.path[0]]: true,
          });
        });
      } else {
        toast.error("Something went wrong");
      }
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setFormdata(defaultHandler);
      setErrorMessage(defaultErrorState);
      setSelectedItem(null);
    }, 500);
  };

  const handleChange = (e) => {
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: false,
    });
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const handleExpenseAmountChange = (e) => {
    if (e.target.value < 0) {
      return false;
    }
    let numberRegex = /^(?:\d*\.?\d{0,2}|)$/;
    let val = e.target.value;
    let match = numberRegex.test(val);

    if (!match) {
      return false;
    }

    setErrorMessage({
      ...errorMessage,
      [e.target.name]: false,
    });

    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Expense"
          subtitle={`Expense details of ${updateFirstLetter(
            formdata?.expenseName
          )}`}
        />

        <div>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                editHandler(selectedItem);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseModal}
          >
            close
          </Icon>
        </div>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Expense Name
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  readOnly={mode === "view"}
                  name="expenseName"
                  error={errorMessage?.expenseName}
                  value={formdata.expenseName ?? ""}
                  onChange={handleChange}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Expense Category
                </SuiTypography>

                <SelectBar
                  disabled={mode === "view"}
                  options={expenseCategoryOptions ?? []}
                  error={errorMessage?.expenseCategory}
                  value={formdata?.expenseCategory ?? ""}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose category"
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      expenseCategory: false,
                    });
                    setFormdata({
                      ...formdata,
                      expenseCategory: e,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Date Of Expense
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="date"
                  name="dateOfExpense"
                  readOnly={mode === "view"}
                  placeholder=""
                  error={errorMessage?.dateOfExpense}
                  style={{ paddingRight: "1px" }}
                  value={formdata.dateOfExpense ?? ""}
                  onChange={handleChange}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Description
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="textbox"
                  name="description"
                  readOnly={mode === "view"}
                  rows={6}
                  multiline
                  error={errorMessage?.description}
                  value={formdata.description ?? ""}
                  onChange={handleChange}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox mt={3} lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Amount
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="number"
                      readOnly={mode === "view"}
                      min="0"
                      name="expenseAmount"
                      error={errorMessage?.expenseAmount}
                      value={formdata.expenseAmount}
                      onChange={(e) => {
                        handleExpenseAmountChange(e);
                      }}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1} mt={3}>
                    <SuiTypography mb={1} variant="h6">
                      Total Amount
                    </SuiTypography>
                    <SuiTypography mb={1} variant="h7">
                      INR {calculateTotal(formdata.expenseAmount, formdata.tax)}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1} mt={3}>
                <SuiTypography mb={1} variant="h6">
                  Tax (%)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  readOnly={mode === "view"}
                  min="0"
                  name="tax"
                  placeholder=""
                  error={errorMessage?.tax}
                  value={formdata.tax ?? ""}
                  onChange={(e) => {
                    handleExpenseAmountChange(e);
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <Divider />

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={!open}
                title={selectedItem ? "Update Expense" : "Add Expense"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
