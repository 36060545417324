import React, { useState, useEffect } from "react";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { measurementRecords } from "containers/clientDetails/sub-components/common/measurementRecords";
import {
  measurementsTableColumns,
  basicMeasurementKeys,
} from "utils/tablesHeaderContent";
import DataVisualizer from "containers/clientDetails/sub-components/DataVisualizer";
import MeasurementModal from "containers/clientDetails/sub-components/modal/measurement";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import { useCallback } from "react";
import { headerDesc } from "utils/helper";

function MeasurementDataVisualizer({
  id,
  measurements,
  title,
  setSelectedClient,
  deleteHandler,
}) {
  const [rows, setRows] = useState([]);
  const [chartSelected, setChartSelected] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [newMeasurementModal, setNewMeasurementModal] = useState(false);
  const columns = [...measurementsTableColumns];
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  useEffect(() => {
    let measurementObj = {};
    const labelArray = [...basicMeasurementKeys];
    labelArray.forEach((item) => {
      measurementObj[item.value] = {
        label: item.value,
        color: item.color,
        data: [],
      };
    });

    let labels = [];
    let measurementArray = measurements.slice();
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    measurementArray.sort(function (a, b) {
      const createdAt1 = convertTimestamp(a?.timestamp);
      const createdAt2 = convertTimestamp(b?.timestamp);
      return createdAt1 - createdAt2;
    });

    measurementArray.forEach((item) => {
      const timestamp = convertTimestamp(item.timestamp);
      const formattedTimestamp = moment.utc(timestamp).format("DD/MM/YYYY");
      labels.push(formattedTimestamp);

      labelArray.forEach((label) => {
        if (item[label.value]) {
          measurementObj[label.value]["data"].push(
            parseFloat(item[label.value] ?? 0)
          );
        }
        return label;
      });
    });

    setChartData({
      labels: labels,
      datasets: Object.keys(measurementObj).map((key) => {
        return {
          label: key,
          color: measurementObj[key]["color"],
          data: measurementObj[key]["data"],
        };
      }),
    });
  }, [measurements]);

  const addHandler = useCallback(() => {
    setNewMeasurementModal(true);
    setMode("create");
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewMeasurementModal(true);
    setMode("edit");
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setNewMeasurementModal(true);
    setMode("view");
  }, []);

  useEffect(() => {
    // Sort vitals array by createdAt
    const sortedData = measurements?.sort((a, b) => {
      const timestampA = a?.createdAt.seconds
        ? a?.createdAt
        : { seconds: a?.createdAt.getTime() / 1000, nanoseconds: 0 };
      const timestampB = b?.createdAt.seconds
        ? b?.createdAt
        : { seconds: b?.createdAt.getTime() / 1000, nanoseconds: 0 };

      return timestampB.seconds - timestampA.seconds;
    });
    setRows(
      measurementRecords(sortedData, editHandler, viewHandler, deleteHandler)
    );
  }, [measurements, editHandler, viewHandler]);

  return (
    <>
      <DataVisualizer
        rows={rows}
        columns={columns}
        setChartSelected={setChartSelected}
        chartTitle={"Measurements"}
        chartData={chartData}
        chartDescription={"Your measurement comparison at every instance"}
        chartSelected={chartSelected}
        addHandler={addHandler}
        title={title}
      />
      <DefaultModal
        open={newMeasurementModal}
        {...headerDesc(mode, "Measurement details", selectedItem)}
        handleCloseModal={() => {
          setNewMeasurementModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <MeasurementModal
          id={id}
          persona="admin"
          mode={mode}
          open={newMeasurementModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewMeasurementModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default MeasurementDataVisualizer;
