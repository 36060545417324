import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { AddMeasurement, EditMeasurement } from "services/clientdetails";
import { timestampConversion } from "utils/common";
import moment from "moment";
import CustomButton from "containers/CustomButton";
import { submitDataHandler } from "../utils";
import { updateRow } from "utils/common";
import { v4 as uuidv4 } from "uuid";

function MeasurementModal({
  open,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      neck: null,
      chest: null,
      tummy: null,
      waist: null,
      hip: null,
      thigh: null,
      arm: null,
      weight: null,
      height: null,
      timestamp: new Date(),
      consultantionNotes: null,
      clientNotes: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      neck: false,
      chest: false,
      tummy: false,
      waist: false,
      hip: false,
      thigh: false,
      arm: false,
      weight: false,
      timestamp: false,
      height: false,
      consultantionNotes: false,
      clientNotes: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        timestamp: selectedItem?.timestamp?.seconds
          ? timestampConversion(selectedItem?.timestamp)._d
          : selectedItem?.timestamp,
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    // if(!formdata.neck){
    //     toast.error("Please fill Neck field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         neck: true
    //     })
    //     return;
    // }

    // if(!formdata.chest){
    //     toast.error("Please fill Chest field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         chest: true
    //     })
    //     return;
    // }

    // if(!formdata.tummy){
    //     toast.error("Please enter Tummy field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         tummy: true
    //     })
    //     return;
    // }

    // if(!formdata.waist){
    //     toast.error("Please fill Waist field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         waist: true
    //     })
    //     return;
    // }

    // if(!formdata.hip){
    //     toast.error("Please fill Hip field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         hip: true
    //     })
    //     return;
    // }

    // if(!formdata.thigh){
    //     toast.error("Please fill Thigh field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         thigh: true
    //     })
    //     return;
    // }

    // if(!formdata.arm){
    //     toast.error("Please fill Arm field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         arm: true
    //     })
    //     return;
    // }

    if (!formdata.timestamp) {
      toast.error("Please fill date/time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (!formdata.consultantionNotes) {
      toast.error("Please fill Consultation Notes field");
      setErrorMessage({
        ...errorMessage,
        consultantionNotes: true,
      });
      return;
    }

    if (!formdata.clientNotes) {
      toast.error("Please fill Client Notes field");
      setErrorMessage({
        ...errorMessage,
        clientNotes: true,
      });
      return;
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () => await EditMeasurement({ formdata, id }),
        addSubmitHandler: async () => await AddMeasurement({ formdata, id }),
        setSelectedClient,
        key: "measurement",
        handleCloseModal,
        setSelectedItem,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault,
      });
    } else if (persona === "client") {
      const docId = uuidv4();

      setMainData((data) => {
        if (selectedItem) {
          const updatedData = updateRow(selectedItem.id, formdata, data);
          data = updatedData;
        } else {
          data = [{ id: docId, ...formdata, createdAt: new Date() }, ...data];
        }
        return data;
      });
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (!open) {
      setFormdata({ ...defaultHandler });
    }
  }, [open, defaultHandler]);

  return (
    <SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Neck (Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="neck"
                  readOnly={mode === "view"}
                  error={errorMessage?.neck}
                  value={formdata.neck ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Chest (Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="chest"
                  readOnly={mode === "view"}
                  error={errorMessage?.chest}
                  value={formdata.chest ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Tummy(Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="tummy"
                  readOnly={mode === "view"}
                  error={errorMessage?.tummy}
                  value={formdata.tummy ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Waist (Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="waist"
                  readOnly={mode === "view"}
                  error={errorMessage?.waist}
                  value={formdata.waist ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Hip (Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="hip"
                  readOnly={mode === "view"}
                  error={errorMessage?.hip}
                  value={formdata.hip ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={6} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Thigh (Inches)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="thigh"
                  readOnly={mode === "view"}
                  error={errorMessage?.thigh}
                  value={formdata.thigh ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Arm (Inches)
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="number"
                      name="arm"
                      readOnly={mode === "view"}
                      error={errorMessage?.arm}
                      value={formdata.arm ?? ""}
                      onChange={(e) => {
                        let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                        let val = e.target.value;
                        let match_ = val.match(numberRegex);
                        if (!match_ && val) {
                          return false;
                        }
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={12} mt={2.5}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Date/time
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="datetime-local"
                      name="timestamp"
                      readOnly={mode === "view"}
                      placeholder=""
                      error={errorMessage?.timestamp}
                      style={{ paddingRight: "1px" }}
                      value={
                        formdata.timestamp
                          ? moment(formdata.timestamp).format(
                              "YYYY-MM-DDTkk:mm"
                            )
                          : moment().format("YYYY-MM-DDTkk:mm")
                      }
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Consultation Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="consultantionNotes"
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  readOnly={mode === "view"}
                  error={errorMessage?.consultantionNotes}
                  value={formdata.consultantionNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="clientNotes"
                  readOnly={mode === "view"}
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  error={errorMessage?.clientNotes}
                  value={formdata.clientNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Weight (kgs)
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="number"
                      name="weight"
                      readOnly={mode === "view"}
                      error={errorMessage?.weight}
                      value={formdata.weight ?? ""}
                      onChange={(e) => {
                        let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                        let val = e.target.value;
                        let match_ = val.match(numberRegex);
                        if (!match_ && val) {
                          return false;
                        }
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Height (cm)
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="number"
                      name="height"
                      readOnly={mode === "view"}
                      error={errorMessage?.height}
                      value={formdata.height ?? ""}
                      onChange={(e) => {
                        let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                        let val = e.target.value;
                        let match_ = val.match(numberRegex);
                        if (!match_ && val) {
                          return false;
                        }

                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default MeasurementModal;
