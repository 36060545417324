import SuiTypography from "components/SuiTypography";
import { globaleDateTimeFormatter } from "utils/helper";


export const BalanceRows = (balance, navigate) => {

    const rows = balance.map((item) => {
        return {

            "Client Name":  
                (<SuiTypography 
                    variant="button" 
                    fontWeight="medium"
                    style={{
                        color: "#17C1E8",
                        cursor: 'pointer',
                        paddingLeft: 14 
                    }}
                    onClick={() => {
                        navigate(`/clients/${item.clientId}`);
                    }}>
                    {item?.name}
                </SuiTypography>),
                
            "Start Date":
                (<SuiTypography
                    variant="button"
                    fontWeight="medium" >
                        {globaleDateTimeFormatter(item?.startDate, 'date')}
                </SuiTypography>),

            "Mobile number": 
                (<SuiTypography
                    variant="button"
                    fontWeight="medium">
                    {item?.phoneNumber}
                </SuiTypography>),

            "Amount":  
                (<SuiTypography 
                    variant="button" 
                    fontWeight="medium"
                    style={{ color: 'red', paddingLeft: 16 }}>
                    INR {item?.balance}
                </SuiTypography>), 
        }
    })

    return rows;
}