import SuiTypography from "components/SuiTypography";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import SuiBox from "components/SuiBox";
import TableAction from "containers/table-action";

//for calc selling price with tax
const calcPriceWT = (priceWOT, tax) => {
  return (
    parseFloat(priceWOT) +
    parseFloat(parseFloat(priceWOT) * (parseFloat(tax) / 100))
  ).toFixed(2);
};

export const ProductRows_ = (
  productArray,
  onSellHandler,
  editHandler,
  deleteHandler,
  viewHandler
) => {
  const rows_ = productArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Last Updated)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.updatedAt
            ? moment(item?.updatedAt).local().format("DD/MM/YYYY h:mm a")
            : moment(item?.createdAt).local().format("DD/MM/YYYY h:mm a")}
        </SuiTypography>
      ),
      "Product": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.productName}
        </SuiTypography>
      ),
      Brand: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.brand}
        </SuiTypography>
      ),

      "Product description": (
        <Tooltip title={item?.description} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.description?.length > 20
              ? `${item?.description?.slice(0, 20)}...`
              : item?.description}
          </SuiTypography>
        </Tooltip>
      ),

      "Cost price WT": (
        <SuiTypography variant="button" fontWeight="medium">
          {calcPriceWT(item.costPriceWOT, item.gst) ?? "N/A"}
        </SuiTypography>
      ),

      "Cost price WOT": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.costPriceWOT ?? "N/A"}
        </SuiTypography>
      ),

      MRP: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.mrp ?? "N/A"}
        </SuiTypography>
      ),

      "Tax(%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.gst}
        </SuiTypography>
      ),

      "Tax amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {parseFloat(
            parseFloat(item.costPriceWOT) * (parseFloat(item?.gst) / 100)
          ).toFixed(2)}
        </SuiTypography>
      ),

      Status: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: item?.availableQuantity !== 0 ? "#34673C" : "#673434",
          }}
        >
          {item?.availableQuantity === 0 ? (
            <span style={{ color: "#673434" }}>Sold Out</span>
          ) : (
            <span style={{ color: "#34673C#" }}>Available</span>
          )}
        </SuiTypography>
      ),

      "Available Quantity": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.availableQuantity}
        </SuiTypography>
      ),

      Action: (
        <SuiBox
          style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}
        >
          <SuiTypography
            variant="button"
            fontWeight="medium"
            onClick={() => onSellHandler(item)}
            style={{
              color: "#17C1E8",
              cursor: "pointer",
            }}
          >
            Sell
          </SuiTypography>

          <TableAction
            style={{ marginLeft: "5px" }}
            updateHandler={() => editHandler(item, "product")}
            viewHandler={() => viewHandler(item, "product")}
            deleteHandler={() => deleteHandler(item.id, item.productName)}
          />
        </SuiBox>
      ),
    };
  });
  return rows_;
};
