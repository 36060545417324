import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { globaleDateTimeFormatter } from "utils/helper";


export const DietChartRows = (charts, onClickHandler, navigate) => {

    const rows = charts.map((item) => {
        return {

            "Client Name":  
                (<SuiTypography 
                    variant="button" 
                    fontWeight="medium"
                    style={{
                        color: "#17C1E8",
                        cursor: 'pointer',
                        paddingLeft: 14 
                    }}
                    onClick={() => {
                        navigate(`/clients/${item.id}`);
                    }}>
                    {item?.name}
                </SuiTypography>),
                
            "Date":
                (<SuiTypography
                    variant="button"
                    fontWeight="medium" >
                    {globaleDateTimeFormatter(item?.date, 'date')}
                </SuiTypography>),

            "Action": (
                <SuiBox style={{  display: 'flex' }}>
                    <SuiTypography
                        variant="button"
                        fontWeight="medium"
                        onClick={() => {
                            navigate(`/dietchart?id=${item.id}`);
                        }}
                        style={{
                            color: "#17C1E8",
                            cursor: 'pointer',
                            paddingLeft: 16,
                            textDecoration: 'underline'
                        }}>
                        Create
                    </SuiTypography>
                </SuiBox>),
        }
    })

    return rows;
}