import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

const leadStatusStyle = (key) => {
  switch (key) {
    case "New":
      return "#3498db"; // Blue

    case "In Discussion":
      return "#f39c12"; // Orange

    case "Converted":
      return "#2ecc71"; // Green

    default:
      return "#e74c3c"; // Red
  }
};

export const leadRows_ = (leadArray, viewHandler, updateHandler) => {
  const rows_ = leadArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Added)": (
        <SuiTypography variant="button" fontWeight="medium" style={{}}>
          {globaleDateTimeFormatter(item?.createdAt)}
        </SuiTypography>
      ),

      Name: (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {item?.clientName}
        </SuiTypography>
      ),

      "Ph. No.": (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {item?.phoneNumber}
        </SuiTypography>
      ),

      Email: (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      "Customer Notes": (
        <Tooltip title={item?.customerNotes} placement="top">
          <SuiTypography variant="button" fontWeight="medium" style={{}}>
            {item?.customerNotes?.length > 20
              ? `${item?.customerNotes?.slice(0, 20)}...`
              : item?.customerNotes}
          </SuiTypography>
        </Tooltip>
      ),

      "Consultant Notes (Latest)": (
        <Tooltip
          title={
            item?.consultantNotes?.length > 0
              ? item?.consultantNotes[item?.consultantNotes?.length - 1]
                  ?.consultantNote
              : "N/A"
          }
          placement="top"
        >
          <SuiTypography variant="button" fontWeight="medium" style={{}}>
            {item?.consultantNotes?.length > 0
              ? item?.consultantNotes[item?.consultantNotes?.length - 1]
                  .consultantNote?.length > 20
                ? `${item?.consultantNotes[
                    item?.consultantNotes?.length - 1
                  ].consultantNote?.slice(0, 20)}...`
                : item?.consultantNotes[item?.consultantNotes?.length - 1]
                    .consultantNote
              : "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      Status: (
        <SuiTypography
          variant="button"
          style={{ color: leadStatusStyle(item?.status) }}
          fontWeight="medium"
        >
          {item?.status}
        </SuiTypography>
      ),

      "Service Interested In": (
        <SuiTypography style={{}} variant="button" fontWeight="medium">
          {item?.service}
        </SuiTypography>
      ),

      Source: (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {item?.source}
        </SuiTypography>
      ),

      "Referred by": (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {item?.referredBy ?? "N/A"}
        </SuiTypography>
      ),

      "Next follow up date": (
        <SuiTypography variant="button" style={{}} fontWeight="medium">
          {globaleDateTimeFormatter(item?.followUpDate, "date")}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          iconStyle={{}}
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => updateHandler(item)}
          viewHandler={() => viewHandler(item)}
        />
      ),
    };
  });
  return rows_;
};
