import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Createable from "containers/Select";
import { useSelector } from "react-redux";
import { userSelector } from "store/features/authSlice";

const GST = ({
  formdata,
  setFormdata,
  errorMessage,
  setErrorMessage,
  mode,
  gstTreatment,
}) => {
  const { gstTreatmentsList } = useSelector(userSelector);
  const [gstTreatmentListOptions, setTreatmentsList] = useState([]);

  const gstTypeOptions = [
    { label: "CGST & SGST", value: "CGST & SGST" },
    { label: "IGST", value: "IGST" },
  ];

  useEffect(() => {
    if (Array.isArray(gstTreatmentsList)) {
      const updated = gstTreatmentsList.map((item) => ({
        label: `${item.name} - ${item.description}`,
        value: `${item.name} - ${item.description}`,
      }));
      setTreatmentsList(updated);
    } else {
      setTreatmentsList([]);
    }
  }, [gstTreatmentsList]);

  return (
    <SuiBox>
      <Grid container spacing={3}>
        {gstTreatment && (
          <Grid item xs={12} lg={4}>
            <SuiBox mr={2}>
              <SuiTypography mb={1} variant="h6">
                GST Treatment
              </SuiTypography>

              <Createable
                options={gstTreatmentListOptions ?? []}
                error={errorMessage?.gstTreatment}
                value={formdata?.gstTreatment}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Choose gst treatment"
                onChange={(e) => {
                  setErrorMessage({ ...errorMessage, gstTreatment: false });
                  setFormdata({
                    ...formdata,
                    gstTreatment: e,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        )}

        <Grid item xs={12} lg={4}>
          <SuiTypography mb={1} variant="h6">
            GST Number
          </SuiTypography>
          <SuiInput
            value={formdata?.gstNumber}
            disabled={mode === "view"}
            type="text"
            name="gstNumber"
            onChange={(e) => {
              setFormdata({ ...formdata, gstNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SuiTypography mb={1} variant="h6">
            GST Type
          </SuiTypography>
          <Createable
            disabled={mode === "view"}
            options={gstTypeOptions ?? []}
            error={errorMessage?.gstType}
            value={formdata?.gstType}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose gst type"
            onChange={(e) => {
              setErrorMessage({ ...errorMessage, gstType: false });
              setFormdata({
                ...formdata,
                gstType: e,
                sgst: 0,
                cgst: 0,
                igst: 0,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          {formdata?.gstType?.value !== "IGST" ? (
            <Grid container>
              <Grid item xs={6}>
                <SuiTypography mb={1} variant="h6">
                  SGST%
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  value={formdata?.sgst}
                  type="number"
                  name="sgst"
                  onChange={(e) => {
                    if (+e.target.value < 0) {
                      return;
                    }
                    setFormdata({ ...formdata, sgst: +e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SuiTypography mb={1} variant="h6">
                  CGST%
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  value={formdata?.cgst}
                  type="number"
                  name="cgst"
                  onChange={(e) => {
                    if (+e.target.value < 0) {
                      return;
                    }
                    setFormdata({ ...formdata, cgst: +e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <SuiBox>
              <SuiTypography mb={1} variant="h6">
                IGST%
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                value={formdata?.igst}
                type="number"
                name="igst"
                onChange={(e) => {
                  if (+e.target.value < 0) {
                    return;
                  }
                  setFormdata({ ...formdata, igst: +e.target.value });
                }}
              />
            </SuiBox>
          )}
        </Grid>
        <Grid item xs={4}>
          <SuiBox>
            <SuiTypography mb={1} variant="h6">
              GST Comments
            </SuiTypography>
            <SuiInput
              disabled={mode === "view"}
              value={formdata?.gstComment}
              type="text"
              name="gstComments"
              onChange={(e) => {
                setFormdata({ ...formdata, gstComment: e.target.value });
              }}
            />
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default GST;
