import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import NoData from "containers/DataNotAvailBox";
import Searchbar from "containers/Searchbar";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { AttendanceRow } from "./rows";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addEmployeeAttendance } from "store/features/employee";
import { useNavigate } from "react-router-dom";
import { timestampConversion } from "utils/common";

const EmployeeAttendance = ({
  attendanceList,
  isSuccess,
  isFetching,
  loader,
  editHandler,
  deleteHandler,
  viewHandler,
  adminId,
}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate(); //for navigation

  const defaultFormData = {
    attendance: [],
  };

  const [searchVal, setsearchVal] = useState("");
  const [attendanceArray, setAttendanceArray] = useState([]);
  const [rows, setRows] = useState([]);
  const [formdata, setFormdata] = useState(defaultFormData);

  useEffect(() => {
    setAttendanceArray(attendanceList);

    if (attendanceList) {
      let searchNewAttendance = [];
      if (!searchVal) {
        searchNewAttendance = attendanceList;
      } else {
        searchNewAttendance = attendanceList.filter((s) => {
          let name = s?.name;
          return name.toLowerCase().includes(searchVal.toLowerCase());
        });
      }


      setFormdata((prevFormData) => {
        const newAttendance = searchNewAttendance.map((item) => {
          const { current_role, id, timing, attendance } = item;

          if (current_role === "full") {
            return {
              presence: attendance ? attendance.presence : "",
              createdAt: attendance ? attendance.createdAt : "",
              role: current_role,
              employeeId: id,
            };
          } else if (current_role === "part") {
            const slotTiming = [];

            if (!attendance) {
              timing.forEach((e) => {
                slotTiming.push({
                  slotTiming: `${e.from} - ${e.to}`,
                  presence: "",
                  createdAt: "",
                  role: current_role,
                  employeeId: id,
                });
              });
            } else if (attendance.length === timing.length) {
              attendance.forEach((e) => {
                slotTiming.push({
                  slotTiming: e.slotTiming,
                  presence: e.presence,
                  createdAt: "",
                  role: current_role,
                  employeeId: id,
                });
              });
            } else {
              timing.forEach((e) => {
                slotTiming.push({
                  slotTiming: `${e.from} - ${e.to}`,
                  presence: "",
                  createdAt: "",
                  role: current_role,
                  employeeId: id,
                });
              });
            }

            return slotTiming;
          }
        });

        return {
          ...prevFormData,
          attendance: newAttendance,
        };
      });
    }
  }, [attendanceList, searchVal]);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    attendanceList.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.createdAt);
      const b_timestamp = convertTimestamp(b?.createdAt);
      return b_timestamp - a_timestamp;
    });
  }, [attendanceList]);

  const toggleOptions = [
    { label: "P", value: "p" },
    { label: "A", value: "a" },
  ];

  const toggleHandler = (option, index, slotIndex) => {
    let attendance;
    attendance = formdata?.attendance?.map((item, i) => {
      if (index === i && !item?.length) {
        return {
          ...item,
          presence: option.target.value,
          createdAt: moment().format("YYYY-MM-DD HH:mm"),
        };
      } else if (index === i && item?.length) {
        let newSlotData = formdata?.attendance[index]?.map(
          (item_, indexing) => {
            if (indexing === slotIndex) {
              return {
                ...item_,
                presence: option.target.value,
                createdAt: moment().format("YYYY-MM-DD HH:mm"),
              };
            } else {
              return item_;
            }
          }
        );

        return newSlotData;
      } else {
        return item;
      }
    });

    setFormdata({ ...formdata, attendance });
    dispatch(
      addEmployeeAttendance({ formdata: attendance[index], adminId: adminId })
    );
  };

  useEffect(() => {}, [
    attendanceArray,
    editHandler,
    deleteHandler,
    viewHandler,
    formdata?.attendance,
  ]);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let sortedAttendanceArray = attendanceArray.slice();

    sortedAttendanceArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.createdAt);
      const b_timestamp = convertTimestamp(b?.createdAt);
      return b_timestamp - a_timestamp;
    });

    setRows(
      AttendanceRow(
        sortedAttendanceArray,
        navigate,
        editHandler,
        deleteHandler,
        toggleHandler,
        toggleOptions,
        "small",
        formdata?.attendance
      )
    );
  }, [
    attendanceArray,
    editHandler,
    deleteHandler,
    viewHandler,
    formdata?.attendance,
  ]);

  useEffect(() => {
    if (!searchVal) {
      setAttendanceArray(attendanceList);
    } else {
      let filteredAttendance = attendanceList.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setAttendanceArray((e) => filteredAttendance);
    }
  }, [searchVal, isSuccess, isFetching, attendanceList]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time (Marked)", align: "left" },
    { name: "Employee Name", align: "left", minWidth: "100px" },
    { name: "Job Role", align: "left", minWidth: "100px" },
    { name: "Email", align: "left", minWidth: "100px" },
    { name: "Action", align: "left", minWidth: "110px" },
  ];

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 0.75 }}
          >
            Kindly ensure the attendance of the listed employees is updated for
            today.
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Employee Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />
          </SuiBox>
        </SuiBox>

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table columns={columns} rows={rows} maxRows={5} />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default EmployeeAttendance;
