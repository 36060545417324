import React, { useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import { Card, Icon } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import CustomizableAccordions from "components/Accordian";
import { useDispatch, useSelector } from "react-redux";
import { faqSelector, fetchFaqs } from "store/features/faqSlice";
import { TailSpin } from "react-loader-spinner";

const Faq = () => {
  const dispatch = useDispatch();
  const { faqData, isError, isSuccess, isFetching } = useSelector(faqSelector);

  const sectionOrder = [
    "dashboard",
    "profile",
    "offerings",
    "clients",
    "recipe",
    "billings",
    "expenses",
    "dietchart",
    "leads",
    "appointments",
    "employees",
    "calculators",
  ];

  let orderFaqData = [];

  if (faqData) {
    orderFaqData = sectionOrder
      ?.map((item) => faqData.find((el) => el.title === item))
      .filter(Boolean);
  }

  const [loader, setLoader] = useState(false);
  const [currentTab, setCurrentTab] = useState([{}]);

  useEffect(() => {
    if (currentTab) {
      const headEl = document?.getElementById("head");
      headEl?.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentTab]);

  useEffect(() => {
    if (isSuccess) {
      setLoader(false);
    }
    if (isError) {
      setLoader(false);
    }
    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, dispatch, isError, isFetching]);

  useEffect(() => {
    if (faqData) {
      setCurrentTab(faqData?.[0]?.subtitles);
    }
  }, [faqData]);

  useEffect(() => {
    dispatch(fetchFaqs());
  }, []);

  return (
    <DashboardLayout id="head">
      <DashboardNavbar />
      <SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox>
              <Card style={{ overflow: "inherit" }}>
                <SuiBox p={3}>
                  <SuiBox>
                    <SuiTypography display="flex" alignItems="center">
                      <SuiTypography variant="h6">FAQ's</SuiTypography>
                      <span
                        style={{
                          paddingLeft: "6px",
                          fontSize: "14px",
                          height: "16px",
                          width: "14px",
                        }}
                      >
                        <Tooltip
                          title={
                            "Below are the list of answers to common questions"
                          }
                          placement="top"
                          style={{
                            color: "#67748e",
                            height: "16px",
                            width: "14px",
                          }}
                        >
                          <Icon color="inherit">{"help"}</Icon>
                        </Tooltip>
                      </span>
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox pt={2}>
                    <SuiTypography
                      variant="head1"
                      fontWeight="bold"
                      fontSize={20}
                      color={"dark"}
                    >
                      Select Topic
                    </SuiTypography>
                  </SuiBox>

                  {loader ? (
                    <SuiBox
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 30,
                      }}
                    >
                      <TailSpin color="#17c1e8" height={60} width={60} />
                    </SuiBox>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        xl={3}
                        sx={{ borderRight: "2px solid #e0f7fa" }}
                      >
                        <SuiBox>
                          <CustomizableAccordions
                            panels={orderFaqData}
                            setCurrentTab={setCurrentTab}
                          />
                        </SuiBox>
                      </Grid>
                      <Grid
                        item
                        xl={9}
                        sm={7}
                        xs={12}
                        sx={{ borderRight: "2px solid #e0f7fa" }}
                      >
                        {currentTab?.[0] && (
                          <CustomizableAccordions
                            panels={currentTab[0]?.questions}
                            subtitle={currentTab[0]?.subtitle}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
};

export default Faq;
