// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { timestampConversion } from "utils/common";
import moment from "moment";

function RedeemHistory({ title, description, info }) {
  const totalAmountTillNow = () => {
    let amount = 0;
    info.map((item) => {
      if (!item.redeemed) {
        amount += item.amount;
      }
      return null;
    });
    return amount;
  };

  const totalAmountAdjusted = () => {
    let amount = 0;
    info.map((item) => {
      if (item.redeemed) {
        amount += item.amount;
      }
      return null;
    });
    return amount;
  };

  const getFormattedRedeemedDate = (item) => {
    if (item.redeemedDate) {
      const date = item.redeemedDate.seconds
        ? moment(timestampConversion(item.redeemedDate)).local()
        : moment(item.redeemedDate).local();

      return date.format("MM/DD/YYYY, h:mm A");
    }
    return "N/A";
  };

  const renderCard = () => {
    return info.length > 0 ? (
      <SuiBox
        p={1}
        style={{
          border: "1px solid #D2D6DA",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
          borderRadius: "16px",
          height: "200px",
          overflowY: "auto",
        }}
      >
        <SuiBox display="flex" py={1}>
          <SuiTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Redemption Date & Amount &nbsp;
          </SuiTypography>
        </SuiBox>

        {info.map((item, key) => {
          if (item.redeemed) {
            return (
              <SuiBox key={key} display="flex" py={1} pr={0}>
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {getFormattedRedeemedDate(item)}:
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  style={{minWidth:"max-content"}}
                >
                  &nbsp;{item?.amount} Rs/-
                </SuiTypography>
              </SuiBox>
            );
          }
          return null;
        })}
      </SuiBox>
    ) : (
      <SuiBox
        p={3}
        style={{
          border: "1px solid #D2D6DA",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
          borderRadius: "16px",
          height: "200px",
          overflowY: "auto",
        }}
      >
        <SuiTypography
          color="text"
          variant="caption"
          textTransform="capitalize"
        >
          No data found
        </SuiTypography>
      </SuiBox>
    );
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SuiTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SuiTypography>
      </SuiBox>
      
      <SuiBox p={2}>
        <SuiBox display="flex" py={1}>
          <SuiTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Total Redeemed Amount Till Now: &nbsp;
          </SuiTypography>
          <SuiTypography
            style={{
              color: "red",
              minWidth:"max-content"
            }}
            variant="button"
            fontWeight="regular"
            color="text"
          >
            &nbsp;Rs {totalAmountAdjusted()}/-
          </SuiTypography>
        </SuiBox>

        <SuiBox display="flex" py={1}>
          <SuiTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Amount To Be Adjusted: &nbsp;
          </SuiTypography>
          <SuiTypography
            style={{
              color: "#82D616",
            }}
            variant="button"
            fontWeight="regular"
            color="text"
          >
            Rs {totalAmountTillNow()}/-
          </SuiTypography>
        </SuiBox>

        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>{renderCard()}</SuiBox>
      </SuiBox>
    </Card>
  );
}

export default RedeemHistory;
