import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ImageNowVsEarlierRow } from "./ImageNowVsEarlierRow";
import UploadHandler from "./UploadHandler";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { globalStyles } from "styles";

function ImageNowVsEarlier({ images, title, clientId, deleteHandler }) {
  const [imageArray, setImageArray] = useState([]);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (images.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setImageArray(images);
  }, [images]);

  return (
    <SuiBox mb={1}>
      <SuiTypography variant="h5" mb={2} style={{ color: "#17C1E8" }}>
        {title}
      </SuiTypography>

      <UploadHandler
        clientId={clientId}
        imageArray={imageArray}
        setImageArray={setImageArray}
        setDisableButton={setDisableButton}
      />

      <SuiBox sx={globalStyles.tableOverlay}>
        <Grid container spacing={3} mt={2}>
          {ImageNowVsEarlierRow(imageArray, deleteHandler)}
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default ImageNowVsEarlier;
