import { Card, Icon, Tooltip } from "@mui/material";
import Table from "examples/Tables/Table";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import NoData from "containers/DataNotAvailBox";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { RenewablesRow } from "./row";
import { useNavigate } from "react-router-dom";

export default function Renewables({ title, tooltip, loader, renewables }) {
  const [rows, setRows] = useState([]);

  const columns = [
    { name: "Client Name", align: "left", minWidth: "150px" },
    { name: "Service Name", align: "left" },
    { name: "Start Date", align: "left" },
    { name: "Completion Date", align: "left", minWidth: "150px" },
  ];

  let navigate = useNavigate(); //for navigation
  useEffect(() => {
    setRows(RenewablesRow(renewables, navigate));
  }, [renewables, navigate]);

  return (
    <SuiBox mb={3} style={{ height: "100%" }}>
      <Card style={{ overflow: "inherit", height: "100%" }}>
        <SuiBox style={{ overflow: "hidden", borderRadius: 20 }}>
          <SuiTypography display="flex" alignItems="center" px={2.5} pt={2.5}>
            <SuiTypography variant="h6">{title}</SuiTypography>
            <span
              style={{
                paddingLeft: "10px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={tooltip}
                placement="top"
                style={{ color: "#67748e", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>

          <SuiBox
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            style={{
              maxHeight: 185,
              minHeight: rows.length === 0 ? 185 : 0,
            }}
          >
            {!loader ? (
              <SuiBox
                id="tableBox"
                style={{
                  overflow: "scroll",
                  overflowX: "auto",
                  width: "100%",
                  borderRadius: "1rem",
                }}
                maxHeight={185}
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {rows.length ? (
                  <Table columns={columns} rows={rows} />
                ) : (
                  <SuiBox
                    height={185}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoData message={"No renewables for upgrade"} />
                  </SuiBox>
                )}
              </SuiBox>
            ) : (
              <SuiBox
                style={{ display: "flex", justifyContent: "center" }}
                mb={4}
              >
                <TailSpin color="#17c1e8" height={40} width={40} />
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}
