import {
    collection, getDocs, doc, getDoc 
} from "firebase/firestore";
import { firestore } from 'firebaseConfig'


export const fetchSubscriptionPlans = async () => {
    try {
        let subs = [];
        let querySnapshot = await getDocs(collection(firestore, "subscription"));
        querySnapshot.forEach((doc) => {
            subs.push({
                ...doc.data(),
                value: doc.data().months, 
                label: `${doc.data().plan} (${doc.data().months} months, ${doc.data().paymentAmount} Rs)`
            });
        });
        return subs;
    } catch (error) {
        throw error
    }
}


export const userDetails = async (uid) => {
    try {
        let data;

        const roleRef = doc(firestore, 'profile', uid)
        const snapshot = await getDoc(roleRef);
        data = { ...snapshot.data() }

        return { ...data };

    } catch (e) {
        throw(e);
    }
}

