import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import Table from "examples/Tables/Table";
import ServiceSearch from "./Searchbar";
import NoData from "containers/DataNotAvailBox";
import { ConsultationRows } from "./rows.js";
import { TailSpin } from "react-loader-spinner";

function ConsultationTable({
  services,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  editHandler,
  deleteHandler,
  viewHandler,
}) {
  const [serviceOptions, setserviceOptions] = useState([]); //filtering wrt services

  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
      };
    });
    setserviceOptions(serviceArray);
  }, [services]);

  const [rows, setRows] = useState([]); // rows for service table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [serviceArray, setserviceArray] = useState([]); //

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time (Added)", align: "left", minWidth: "140px" },
    { name: "Consultation Service Name", align: "left", minWidth: "190px" },
    {
      name: "Consultation Service Description",
      align: "left",
      minWidth: "250px",
    },
    // { name: "Selling price WT", align: "left", minWidth: '140px' },
    { name: "Selling price WOT", align: "left", minWidth: "140px" },
    { name: "Tax(%)", align: "left" },
    // { name: "Tax amount", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    setRows(
      ConsultationRows(serviceArray, editHandler, deleteHandler, viewHandler)
    );
  }, [serviceArray, editHandler, deleteHandler, viewHandler]);

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    service: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let filterData = services.filter((item) => {
      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.service !== item["serviceName"]) {
          return false;
        }
      }

      return item;
    });
    setserviceArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      status: null,
      service: null,
    });
    setserviceArray(services);
  }, [services]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setserviceArray(services);
    } else {
      resetHandler();
      let filteredservice = services.filter((s) => {
        let name = s?.serviceName;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setserviceArray((e) => filteredservice);
    }
  }, [searchVal, isSuccess, isFetching, services, resetHandler]);

  const excelDownload = () => {
    let updatedData = services.map((item, index) => {
      return [
        index + 1,
        item.serviceName,
        item.createdAt.toString(),
        item.description,
        (item.sellingPriceWOT * item.gst) / 100 + item.sellingPriceWOT,
        item.sellingPriceWOT,
        item.gst,
        (item.sellingPriceWOT * item.gst) / 100,
      ];
    });
    let excelData = [
      [
        "id",
        "CONSULTATION SERVICE NAME",
        "DATE/TIME (ADDED)",
        "CONSULTATION SERVICE DESCRIPTION",
        "SELLING PRICE WT",
        "SELLING PRICE WOT",
        "TAX(%)",
        "TAX AMOUNT",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Consultation_Services_Listing.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          gap={3}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 1 }}
          >
            Below are your consultation services, you can add, delete, update
            the consultation services.
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addHandler}
              width={180}
              style={{
                marginRight: 25,
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add new consultation service
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <ServiceSearch
          serviceOptions={serviceOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
        />
        {!loader ? (
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {rows.length ? <Table columns={columns} rows={rows} /> : <NoData />}
          </SuiBox>
        ) : (
          <SuiBox style={{ display: "flex", justifyContent: "center" }} mb={4}>
            <TailSpin color="#17c1e8" height={60} width={60} />
          </SuiBox>
        )}
      </Card>
    </SuiBox>
  );
}

export default ConsultationTable;
