import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import CustomButton from "containers/CustomButton";
import { Button, Icon, Radio } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { updateFirstLetter } from "utils/helper";
import ModalHeader from "containers/modal-header";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "firebaseConfig";
import {
  validateEmail,
  validatePassword,
} from "utils/common";
import Createable from "containers/Select";
import { addEmployee } from "store/features/employee";
import SuiTypography from "components/SuiTypography";
import { updateEmployee } from "store/features/employee";
import RadioInput from "containers/Radio";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SuiButton from "components/SuiButton";
import { InputOption } from "utils/common";

function AddEmployeeModal({
  open,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  uid,
  isFetching,
  isSuccess,
  isError,
  setLoader,
  message,
  mode,
  editHandler,
  viewHandler,
  intlPhoneNumberArray,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      name: null,
      phoneNumber: null,
      email: null,
      password: null,
      accessModules: null,
      current_role: "full",
      timing: [
        {
          from: moment("09:00", "h:mm").format("HH:mm"),
          to: moment("13:00", "h:mm").format("HH:mm"),
        },
      ],
      countryCode: {
        label: "(+91)     India",
        value: "+91",
        key: "IN",
        phoneLength: 10,
      },
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      name: false,
      phoneNumber: false,
      email: false,
      password: false,
      accessModules: false,
      current_role: null,
    };
  }, []);
  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);
  const [selectedAccessDefault, setSelectedAccessDefault] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [accessModulesDefault, setAccessModulesDefault] = useState([{}]);
  const [slotIndexing, setSlotIndexing] = useState([0]);

  useEffect(() => {
    const accessDefaultValues =
      selectedItem?.accessModules?.map((item) => {
        return {
          value: item,
          label: item ? updateFirstLetter(item) : "",
        };
      }) ?? [];

    setAccessModulesDefault(accessDefaultValues);
    if (selectedItem?.timing) {
      setSlotIndexing(
        Array.from({ length: selectedItem?.timing?.length }, (_, i) => i)
      );
    }
  }, [setAccessModulesDefault, selectedItem]);

  useEffect(() => {
    const accessDefaultValues =
      formdata?.accessModules?.map((item) => {
        return {
          value: item,
          label: item ? updateFirstLetter(item) : "",
        };
      }) ?? [];
    setSelectedAccessDefault(accessDefaultValues);
  }, [formdata]);

  const allOptions = [
    { value: "dashboard", label: "Dashboard" },
    { value: "offering", label: "Offering" },
    { value: "clients", label: "Clients" },
    { value: "recipe", label: "Food or Recipe" },
    { value: "billing", label: "Billings & Expenses" },
    { value: "dietchart", label: "Dietchart" },
    { value: "leads", label: "Leads & Appointments" },
    { value: "calculators", label: "Calculators" },
  ];

  //for handling selected items
  useEffect(() => {
    if (open) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
      });
      setSelectedItem(selectedItem ?? []);
    }
  }, [selectedItem, defaultHandler, open]);

  //for submitting data
  const submitHandler = async () => {
    if (!formdata.name) {
      toast.info("Enter employee name");
      setErrorMessage({
        ...errorMessage,
        name: true,
      });
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (mode === "create") {
      if (formdata.password) {
        const { isValid, message } = validatePassword(formdata.password);
        if (!isValid) {
          toast.info(message);
          return;
        }
      } else {
        toast.info("Please enter Password");
        setErrorMessage({
          ...errorMessage,
          password: true,
        });
        return;
      }
    }

    if (!formdata?.accessModules?.length) {
      toast.info("Choose employee access");
      return;
    }

    if (mode === "create") {
      let q = query(
        collection(firestore, "employees"),
        where("email", "==", formdata.email)
      );
      let querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        toast.error(
          "Employee with this Email already exists, choose different Email"
        );

        return;
      }

      q = query(
        collection(firestore, "employees"),
        where("phoneNumber", "==", formdata.phoneNumber)
      );
      querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        toast.error("Phone number already exists!");

        return;
      }
    }

    if (mode === "create") {
      dispatch(addEmployee({ formdata: formdata, uid: uid }));
    } else {
      dispatch(
        updateEmployee({
          formdata: formdata,
          id: selectedItem?.id,
          uid: uid,
        })
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setSlotIndexing([0]);
      setTimeout(() => {
        if (selectedItem) {
          setSelectedItem(null);
        }
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    setSelectedItem,
    selectedItem,
    defaultHandler,
    message,
    setLoader,
  ]);

  

  const addMoreSlots = () => {
    setSlotIndexing((e) => {
      const val = e.length > 0 ? e[e.length - 1] : 1;
      e = [...e, val + 1];
      return e;
    });

    setFormdata({
      ...formdata,
      timing: [
        ...formdata?.timing,
        {
          from: moment("09:00", "h:mm").format("HH:mm"),
          to: moment("13:00", "h:mm").format("HH:mm"),
        },
      ],
    });
  };

  const clearAllSolts = () => {
    setSlotIndexing([0]);
    setFormdata({ ...formdata, timing: defaultHandler?.timing });
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="60%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Employee"
          subtitle={`Employee details for ${updateFirstLetter(formdata?.name)}`}
        />

        <Grid item xs={12}>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => editHandler(selectedItem)}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setSlotIndexing([0]);
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
                if (selectedItem) {
                  setSelectedItem(null);
                }
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />
      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SuiBox>
                  <SuiTypography mb={1} variant="h6">
                    Employee Name
                  </SuiTypography>
                  <SuiInput
                    disabled={mode === "view"}
                    placeholder="Enter employee Name"
                    type="text"
                    name="name"
                    autoComplete="off"
                    error={errorMessage?.name}
                    value={formdata?.name ?? ""}
                    onChange={(e) => {
                      let numberRegex = /^[a-zA-Z ]*$/;
                      let val = e.target.value;
                      let match_ = val.match(numberRegex);
                      if (!match_ && val) {
                        return false;
                      }
                      setErrorMessage({
                        ...errorMessage,
                        [e.target.name]: false,
                      });
                      setFormdata({
                        ...formdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </SuiBox>
              </Grid>
              {!(mode === "edit") && (
                <Grid item xs={12} lg={6}>
                  <SuiBox mb={2}>
                    <SuiTypography mb={1} variant="h6">
                      Employee Email
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      placeholder="Enter employee email"
                      type="email"
                      name="email"
                      error={errorMessage?.email}
                      value={formdata?.email ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <SuiBox>
                  <SuiTypography mb={1} variant="h6">
                    Country Code
                  </SuiTypography>
                  <Createable
                    disabled={mode === "view"}
                    options={intlPhoneNumberArray}
                    style={{ borderRadius: "0.5rem", fontSize: 14 }}
                    inputStyle={{
                      width: 190,
                      height: "1.900rem",
                      borderRadius: "0.5rem",
                    }}
                    onChange={(e) => {
                      setFormdata({
                        ...formdata,
                        countryCode: e,
                        phoneNumber: null,
                      });
                    }}
                    value={formdata.countryCode}
                  />
                </SuiBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SuiBox mb={2}>
                  <SuiTypography mb={1} variant="h6">
                    Phone Number
                  </SuiTypography>
                  <SuiInput
                    disabled={mode === "view"}
                    placeholder="Enter phone Number"
                    type="text"
                    name="phoneNumber"
                    error={errorMessage?.phoneNumber}
                    value={formdata?.phoneNumber ?? ""}
                    onChange={(e) => {
                      let numberRegex = /^\d+$/;
                      let val = e.target.value;
                      let match_ = val.match(numberRegex);
                      if (!match_ && val) {
                        return false;
                      }

                      if (
                        formdata.countryCode.key === "IN"
                          ? val.length > 10
                          : val.length > formdata?.countryCode?.phoneLength
                      ) {
                        toast.info("Invalid phone number");
                        return;
                      }
                      setErrorMessage({
                        ...errorMessage,
                        [e.target.name]: false,
                      });
                      setFormdata({
                        ...formdata,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </SuiBox>
              </Grid>

              {mode === "create" && (
                <Grid item xs={12} lg={6}>
                  <SuiBox style={{ position: "relative" }}>
                    <SuiTypography mb={1} variant="h6">
                      Password
                    </SuiTypography>
                    <SuiInput
                      placeholder="Enter password"
                      type={showPass ? "text" : "password"}
                      name="password"
                      error={errorMessage?.password}
                      value={formdata?.password ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <Icon
                      sx={({
                        typography: { size, fontWeightBold },
                        palette: { dark },
                      }) => ({
                        fontSize: `${size.lg} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 6,
                        position: "absolute",
                        right: 10,
                        top: -3,
                      })}
                      onClick={() => {
                        setShowPass((e) => !e);
                      }}
                    >
                      {!showPass ? "visibility" : "visibility_off"}
                    </Icon>
                  </SuiBox>
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <SuiBox mb={2}>
                  <SuiTypography mb={1} variant="h6">
                    Employee Accessibility
                  </SuiTypography>
                  {/* accessModulesDefault[0] I have done this because the default values are not working without this */}
                  {accessModulesDefault[0] && (
                    <Createable
                      disabled={mode === "view"}
                      defaultValue={accessModulesDefault}
                      components={{
                        Option: InputOption,
                      }}
                      options={allOptions ?? []}
                      style={{
                        borderRadius: "0.5rem",
                        fontSize: 14,
                        zIndex: 999,
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      inputStyle={{
                        width: 190,
                        height: "1.800rem",
                        borderRadius: "0.5rem",
                      }}
                      placeholder="Choose Employee Access"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setFormdata({
                            ...formdata,
                            accessModules: options.map((opt) => opt.value),
                          });
                        }
                      }}
                    />
                  )}
                  {mode === "create" && (
                    <Createable
                      components={{
                        Option: InputOption,
                      }}
                      value={selectedAccessDefault}
                      options={allOptions ?? []}
                      style={{
                        borderRadius: "0.5rem",
                        fontSize: 14,
                        zIndex: 999,
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      inputStyle={{
                        width: 190,
                        height: "1.800rem",
                        borderRadius: "0.5rem",
                      }}
                      placeholder="Choose Employee Access"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setFormdata({
                            ...formdata,
                            accessModules: options.map((opt) => opt.value),
                          });
                        }
                      }}
                    />
                  )}
                </SuiBox>
              </Grid>

              <Grid item xs={12} lg={12}>
                <SuiBox mb={2}>
                  <RadioInput
                    disabled={mode === "view"}
                    defaultValue={formdata?.current_role}
                    value={formdata?.current_role}
                    options={[
                      { label: "Full Time", value: "full" },
                      { label: "Part Time", value: "part" },
                    ]}
                    handleChange={(option) =>
                      setFormdata({
                        ...formdata,
                        current_role: option.target.value,
                      })
                    }
                  />
                  {formdata?.current_role === "part" && (
                    <Grid container spacing={3}>
                      {slotIndexing?.map((item) => (
                        <>
                          <Grid item xs={12} lg={6}>
                            <SuiTypography mb={1} mr={1} variant="h6">
                              Slot {item + 1}:
                            </SuiTypography>
                            <SuiBox
                              style={{
                                display: "flex",
                                justifyContent: "flex-between",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                              mt={1}
                            >
                              <SuiBox
                                mb={0}
                                mr={2.5}
                                display="flex"
                                alignItems="center"
                              >
                                <input
                                  type="time"
                                  className="timePicker"
                                  disabled={mode === "view"}
                                  style={{
                                    color: "#495057",
                                    width: 106,
                                    paddingLeft: "0.4rem",
                                  }}
                                  onChange={(val) => {
                                    let convertedDate = moment(
                                      val.target.value,
                                      "HH:mm"
                                    ).format("HH:mm");

                                    setFormdata((prevFormData) => {
                                      const updatedTiming =
                                        prevFormData.timing.map((e, index) => {
                                          if (index === item) {
                                            return {
                                              from: convertedDate,
                                              to: e?.to,
                                            };
                                          } else {
                                            return e;
                                          }
                                        });

                                      return {
                                        ...prevFormData,
                                        timing: updatedTiming,
                                      };
                                    });
                                  }}
                                  value={formdata?.timing[item]?.["from"]}
                                />
                              </SuiBox>

                              <SuiBox mb={0} mr={2.5}>
                                <input
                                  type="time"
                                  className="timePicker"
                                  disabled={mode === "view"}
                                  style={{
                                    color: "#495057",
                                    width: 106,
                                    paddingLeft: "0.4rem",
                                  }}
                                  onChange={(val) => {
                                    let convertedDate = moment(
                                      val.target.value,
                                      "HH:mm"
                                    ).format("HH:mm");

                                    setFormdata((prevFormData) => {
                                      const updatedTiming =
                                        prevFormData.timing.map((e, index) => {
                                          if (index === item) {
                                            return {
                                              from: e?.from,
                                              to: convertedDate,
                                            };
                                          } else {
                                            return e;
                                          }
                                        });

                                      return {
                                        ...prevFormData,
                                        timing: updatedTiming,
                                      };
                                    });
                                  }}
                                  defaultValue={formdata?.timing[item]?.["to"]}
                                />
                              </SuiBox>
                            </SuiBox>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  )}

                  {formdata?.current_role === "part" && !(mode === "view") && (
                    <SuiBox
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "flex-start",
                        border: "none",
                        flexWrap: "wrap",
                      }}
                      mt={3}
                    >
                      <SuiTypography variant="overline" ml={1} mr={3}>
                        Total slots: {slotIndexing?.length}
                      </SuiTypography>
                      <SuiBox mr={2} mb={1}>
                        <SuiButton
                          component={Button}
                          color="dark"
                          variant="outlined"
                          onClick={addMoreSlots}
                        >
                          Add Slot
                        </SuiButton>
                      </SuiBox>

                      <SuiBox mr={2}>
                        <SuiButton
                          component={Button}
                          color="dark"
                          variant="outlined"
                          onClick={clearAllSolts}
                        >
                          Clear Slots
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={isFetching || !open}
                title={mode === "edit" ? "Edit Employee" : "Add Employee"}
                loader={isFetching}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default AddEmployeeModal;
