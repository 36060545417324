import { useCallback, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import LeadTable from "./sub-components/lead";

import LeadModal from "./sub-components/models/LeadModel";

import { useDispatch, useSelector } from "react-redux";
import { LeadSelector, getLeads, clearState } from "store/features/leads";

import { toast } from "react-toastify";

import { userSelector } from "store/features/authSlice";
import {
  DashboardSelector,
  getServiceInventory,
} from "store/features/dashboard";
import { ClientSelector } from "store/features/clients";
import { getClients } from "store/features/clients";
import { sortByDate } from "utils/helper";
import { getIntlPhoneNumber } from "store/features/authSlice";

function Dashboard() {
  const { intlPhoneNumber, uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const [MiniCardData, setMiniCardData] = useState([]);
  const { leads, isError, isSuccess, message, isFetching } =
    useSelector(LeadSelector);
  const { services } = useSelector(DashboardSelector);
  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation
  const { clients } = useSelector(ClientSelector);
  const [newLeadModal, setNewLeadModal] = useState(false);
  const [mode, setMode] = useState("view");
  const [intlPhoneNumberArray, setIntlPhoneNumberArray] = useState([]);

  //create or edit [create, edit, view
  const dispatch = useDispatch();

  //gets inventory and client lists
  useEffect(() => {
    dispatch(getLeads({ uid }));
    dispatch(getServiceInventory({ uid }));
    dispatch(getClients({ uid }));
    dispatch(getIntlPhoneNumber());
  }, [dispatch, uid]);

  const [leadStatusStat, setLeadStatusStat] = useState({
    new: 0,
    inDiscussion: 0,
    converted: 0,
    dropped: 0,
  });
  useEffect(() => {
    let statusCount = {
      newStatus: 0,
      inDiscussion: 0,
      converted: 0,
      dropped: 0,
    };

    leads.map((item) => {
      switch (item.status) {
        case "New":
          statusCount.newStatus++;
          break;

        case "In Discussion":
          statusCount.inDiscussion++;
          break;

        case "Converted":
          statusCount.converted++;
          break;

        default:
          statusCount.dropped++;
          break;
      }
      return item;
    });

    setLeadStatusStat(statusCount);
  }, [leads]);

  const updateLeadCountHandler = async () => {};

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      setLoader(false);
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching]);

  useEffect(() => {
    if (intlPhoneNumber) {
      const updateIntlNumber = intlPhoneNumber.data.map((item, index) => {
        return {
          label: `${
            item.dial_code.length === 2
              ? `(${item.dial_code})`.padEnd(10)
              : item.dial_code.length === 3
              ? `(${item.dial_code})`.padEnd(9)
              : item.dial_code.length === 4
              ? `(${item.dial_code})`.padEnd(8)
              : `(${item.dial_code})`
          } ${
            item?.name?.length > 15
              ? `${item?.name?.slice(0, 15)}...`
              : item?.name
          }`,
          value: item.dial_code,
          key: item.code,
          phoneLength: item.phoneLength ?? 10,
        };
      });

      setIntlPhoneNumberArray(updateIntlNumber);
    }
  }, [intlPhoneNumber]);

  useEffect(() => {
    let MiniCardData_ = [
      {
        title: "New Leads",
        count: leadStatusStat.newStatus,
        bullet: true,
        icon: {
          color: "info",
          component: "person_2",
          para: "Total new active leads",
        },
      },
      {
        title: "In Discussion Leads",
        count: leadStatusStat.inDiscussion,
        bullet: true,

        icon: {
          color: "info",
          component: "paid",
          para: "Total leads in discussion, follow up with them",
        },
      },
      {
        title: "Converted Leads",
        count: leadStatusStat.converted,
        bullet: true,

        icon: {
          color: "info",
          component: "task_alt",
          para: "These are the leads that got converted",
        },
      },
      {
        title: "Dropped Off Leads",
        count: <span style={{ color: "red" }}>{leadStatusStat.dropped}</span>,
        bullet: true,

        icon: {
          color: "info",
          component: "inventory_2",
          para: "These are the leads that didn't got converted",
        },
      },
    ];
    setMiniCardData(MiniCardData_);
  }, [leadStatusStat]);
  const [leadArray1, setLeadArray1] = useState([]);
  useEffect(() => {
    let leads1 = sortByDate(leads);
    setLeadArray1(leads1);
  }, [leads]);

  const onViewHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("view");
      setNewLeadModal(true);
    },
    [setMode, setNewLeadModal, setSelectedItem]
  );

  const onUpdateHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("edit");
      setNewLeadModal(true);
    },
    [setMode, setNewLeadModal, setSelectedItem]
  );

  const onAddHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewLeadModal(true);
  }, [setMode, setNewLeadModal, setSelectedItem]);

  return (
    <>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            {MiniCardData.map((item, index) => (
              <Grid item xs={12} sm={6} xl={3} key={index}>
                <MiniStatisticsCard
                  title={{ text: item?.title }}
                  count={item?.count}
                  bullet={true}
                  fontSize={18}
                  percentage={{
                    color: item?.percent?.color,
                    text: item?.percent?.text,
                  }}
                  icon={{
                    color: item?.icon?.color,
                    component: item?.icon?.component,
                    para: item?.icon?.para,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </SuiBox>

        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LeadTable
                leads={leadArray1}
                services={services}
                title="Leads"
                loader={loader}
                viewHandler={onViewHandler}
                updateHandler={onUpdateHandler}
                addHandler={onAddHandler}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <LeadModal
        mode={mode}
        open={newLeadModal}
        setOpenModal={setNewLeadModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        uid={uid}
        clients={clients}
        services={services}
        handleCloseModal={() => {
          setNewLeadModal(false);
        }}
        updateLeadCountHandler={updateLeadCountHandler}
        viewHandler={onViewHandler}
        editHandler={onUpdateHandler}
        intlPhoneNumberArray={intlPhoneNumberArray}
      />
    </>
  );
}

export default Dashboard;
