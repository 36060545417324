import SuiTypography from "components/SuiTypography";
import TableAction from "containers/table-action";
import { calculateServiceUnpaidBalance } from "utils/common";
import { globaleDateTimeFormatter } from "utils/helper";

export const AccountRows = (history, editHandler, viewHandler) => {
  const rows = history.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14, color: "white" }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Start Date": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "white" }}
        >
          {globaleDateTimeFormatter(item?.startDate, "date")}
        </SuiTypography>
      ),

      "Completion Date": (
        <SuiTypography
          variant="button"
          style={{
            color: "white",
          }}
          fontWeight="medium"
        >
          {globaleDateTimeFormatter(item?.completionDate, "date")}
        </SuiTypography>
      ),

      "Service Taken": (
        <SuiTypography
          variant="button"
          style={{
            color: "white",
          }}
          fontWeight="medium"
        >
          {item?.serviceName}
        </SuiTypography>
      ),

      "Total Amount": (
        <SuiTypography
          variant="button"
          style={{
            color: "white",
          }}
          fontWeight="medium"
        >
          {item?.totalAmount}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography
          variant="button"
          style={{
            color: "white",
          }}
          fontWeight="medium"
        >
          {item?.amountPaid}
        </SuiTypography>
      ),

      Discount: (
        <SuiTypography
          variant="button"
          style={{
            color: "white",
          }}
          fontWeight="medium"
        >
          {item?.discount}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR{" "}
          {calculateServiceUnpaidBalance(
            item.totalAmount,
            item.discount,
            item.amountPaid
          )}
        </SuiTypography>
      ),

      "Service Instance": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "white",
          }}
        >
          {item?.serviceInstance ?? "N/A"}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          iconStyle={{ color: "white" }}
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item, 'payment')}
        />
      ),
    };
  });
  return rows;
};
