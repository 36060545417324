import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userSlice } from "./features/authSlice";
import { DashboardSlice } from "./features/dashboard";
import { ClientSlice } from "./features/clients";
import { LeadSlice } from "./features/leads";
import { RecipeSlice } from "./features/recipes";
import { DietChartSlice } from "./features/dietchart";

import { StatSlice } from "./features/statistics.js";
import { employeeSlice } from "./features/employee";
import { faqSlice } from "./features/faqSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, userSlice.reducer);

export const store = configureStore({
  reducer: {
    user: persistedAuthReducer,
    dashboard: DashboardSlice.reducer,
    client: ClientSlice.reducer,
    lead: LeadSlice.reducer,
    recipes: RecipeSlice.reducer,
    dietchartList: DietChartSlice.reducer,
    Stat: StatSlice.reducer,
    employee: employeeSlice.reducer,
    faq: faqSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
