import { useState, useCallback, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import { Button } from "@mui/material";
import { loadScript } from "utils/common";
import { renewSubscription } from "store/features/authSlice";
import Logomain from "assets/images/creware.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "containers/pdf/Invoice/invoice";
import Createable from "containers/Select";
import { fetchSubscriptionPlans } from "services/auth";
import { userSelector } from "store/features/authSlice";

function SubscriptionCard({
  title,
  description,
  info,
  referrals,
  personal,
  uid,
}) {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  //for updating subscription
  const registerHandler = async (payResponse, referralList) => {
    setLoader(true);
    await dispatch(
      renewSubscription({ payResponse, uid, referralList, subscription })
    );
    setLoader(false);
  };

  const displayRazorpay = async () => {
    if (!subscription || !subscription.paymentAmount) {
      toast.error("Select subscription plan");
      return;
    }
    let referAmount = 0;
    let referralList = referrals?.map((item) => {
      if (!item.used) {
        if (referAmount !== parseFloat(subscription.paymentAmount)) {
          referAmount += item.amount;
          if (referAmount > parseFloat(subscription.paymentAmount)) {
            let amt = {
              amount: referAmount - parseFloat(subscription.paymentAmount),
              ...item,
            };
            referAmount = parseFloat(subscription.paymentAmount);
            return amt;
          }
          return { ...item, used: true };
        }
      }
      return item;
    });

    let amount = 0;

    if (parseFloat(subscription.paymentAmount) === parseFloat(referAmount)) {
      amount = 1 * 100;
    } else {
      amount =
        parseFloat(subscription.paymentAmount) * 100 -
        parseFloat(referAmount) * 100;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("Razorpay SDK failed to load, retry");
      return;
    } else {
      var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: amount,
        currency: "INR",
        name: "Fitify",
        description: "Subscription renew transaction",
        image: Logomain,
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: personal.name,
          email: personal.email,
          contact: personal.phoneNumber,
        },
        handler: function (response) {
          response = {
            ...response,
            subscriptionAmount: parseFloat(subscription.paymentAmount),
            amount: amount / 100,
            referAmount: parseFloat(referAmount),
          };
          registerHandler(response, referralList ?? []);
        },
      };
    }

    var paymentObj = new window.Razorpay(options);
    paymentObj.open();
  };

  const {
    nextRenewableDate,
    amountPaid,
    subscriptionType,
    dateOfSubscription,
  } = info;

  const renderCard = () => (
    <SuiBox
      p={2}
      style={{
        border: "1px solid #D2D6DA",
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
      }}
    >
      <SuiBox display="flex" py={1}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          style={{ width: "60%" }}
        >
          Subscription Type: &nbsp;
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{subscriptionType}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" py={1}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          style={{ width: "60%" }}
        >
          Amount Paid: &nbsp;
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{amountPaid}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" py={1}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          style={{ width: "60%" }}
        >
          Date of Subscription: &nbsp;
        </SuiTypography>
        <SuiTypography
          variant="button"
          fontWeight="regular"
          color="text"
          style={{ width: "40%" }}
        >
          &nbsp;{dateOfSubscription}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" py={1}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          style={{ width: "60%" }}
        >
          Next Renewal Date: &nbsp;
        </SuiTypography>
        <SuiTypography
          variant="button"
          fontWeight="regular"
          color="text"
          style={{ width: "40%" }}
        >
          &nbsp;{nextRenewableDate}
        </SuiTypography>
      </SuiBox>

      <SuiBox display="flex" py={1}>
        <SuiTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          style={{ width: "60%" }}
        >
          Download Invoice: &nbsp;
        </SuiTypography>
        <PDFDownloadLink
          style={{ marginTop: -7 }}
          document={<MyDoc user={user} subscription={info} />}
          fileName="invoice.pdf"
        >
          {({ blob, url, loading, error }) => (
            <SuiTypography
              style={{
                color: "#17C1E8",
                textDecoration: "underline",
              }}
              variant="button"
              fontWeight="regular"
              color="text"
            >
              Download
            </SuiTypography>
          )}
        </PDFDownloadLink>
      </SuiBox>
    </SuiBox>
  );

  const fetchSubscriptions = useCallback(async () => {
    try {
      setLoader(true);
      const subs = await fetchSubscriptionPlans();
      setSubscriptionTypev1(subs);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!!");
    }
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const [subscriptionTypev1, setSubscriptionTypev1] = useState([]);
  const [subscription, setSubscription] = useState();

  const onSelectSubscrition = (value) => {
    setSubscription(value);
  };

  return (
    <Card sx={{ height: "100%" }} style={{ overflow: "inherit" }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SuiTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>{renderCard()}</SuiBox>

        <SuiBox mt={2}>
          <Createable
            options={subscriptionTypev1 ?? []}
            autoComplete="off"
            value={subscription}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose next Subscription plan"
            onChange={(e) => {
              onSelectSubscrition(e);
            }}
          />
        </SuiBox>

        <SuiBox mt={4} display="flex" justifyContent="center">
          <SuiButton
            style={{
              width: "60%",
            }}
            width="50%"
            component={Button}
            color="dark"
            variant="gradient"
            onClick={displayRazorpay}
            disabled={false}
            fullWidth
          >
            {loader ? "Renewing . . ." : "Pay & Renew"}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default SubscriptionCard;
