import React, { useState } from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { isAllOptionInserted } from "utils/common";
import SuiTypography from "components/SuiTypography";

function RecipeSearch({
  setFilterQuery,
  filterQuery,
  serviceOptions,
  filterHandler,
  resetHandler,
  excelDownload,
}) {
  const [service, setService] = useState(null); //for selecting service

  if (!isAllOptionInserted(serviceOptions)) {
    serviceOptions?.unshift({
      value: "all",
      label: "All",
    });
  }

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mb={1} mr={2.5}>
          {" "}
          <SuiTypography mb={1} variant="h6">
            From Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          {" "}
          <SuiTypography mb={1} variant="h6">
            To Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose Service
          </SuiTypography>
          <Createable
            options={serviceOptions ?? []}
            value={service}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose Service"
            onChange={(e) => {
              setService(e);
              setFilterQuery({
                ...filterQuery,
                service: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
        <SuiBox mb={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={2} mr={2.5}>
        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setService(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default RecipeSearch;
