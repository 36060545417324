import React, { useMemo, useEffect, useCallback, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import RecipeChart from "pages/dietchart/sub-components/modal/diet/recipeChart";
import { getUserRecipes } from "services/dietchart";
import {
  userSelector,
  saveNewDietChartTemplate,
  getSavedDietChartTemplates,
  clearState,
} from "store/features/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SaveIcon from "@mui/icons-material/Save";
import Createable from "containers/Select";
import { getWeeksArray } from "utils/common";
import { Icon } from "@mui/material";
import { isError } from "joi";

const chartTypes = [
  { value: "Weekly", label: "Weekly", key: 1 },
  { value: "Plateau", label: "Plateau", key: 2 },
  { value: "Detox", label: "Detox", key: 3 },
];

const AddTemplate = (props) => {
  const dispatch = useDispatch();
  const { open } = props;
  const { uid, dietChartTemplates, isSuccess, isFetching } =
    useSelector(userSelector);

  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [recipeList, setRecipeList] = useState([]);
  const [scrollToTop, setScrollToTop] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateNameError, setTemplateNameError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState("");
  const [chartType, setChartType] = useState({});
  const [weekPlan, setWeekPlan] = useState({});
  const [saveIsClicked, setSaveIsClicked] = useState(false);


  const [error, setError] = useState({
    notes: false,
    chartType: false,
    weekPlan: false,
  });

  useEffect(() => {
    if (saveIsClicked) {
      if (isSuccess) {
        setChartType({});
        setDietPlan({ ...dietChartObj });
        setNote("");
        setWeekPlan({});
        setTemplateName("");
        setSelectedItem(null);
        props.setOpenAddTemplateDrawer(false);
        props.setOpenAddTemplateDrawer(false);
        dispatch(clearState());
      }

      if (isFetching && saveIsClicked) {
        setLoader(true);
      } else {
        setLoader(false);
      }
    }
    if (!saveIsClicked) {
      setLoader(false);
    }
  }, [isSuccess, isFetching, saveIsClicked]);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  let dietChartObj = useMemo(() => {
    return {
      earlyMorning: [],
      breakfast: [],
      midMorning: [],
      brunch: [],
      midMeal: [],
      lunch: [],
      postLunch: [],
      evening: [],
      beforeDinner: [],
      dinner: [],
      postDinner: [],
      lateNight: [],
    };
  }, []);
  const [dietPlan, setDietPlan] = useState({ ...dietChartObj });

  useEffect(() => {
    dispatch(getSavedDietChartTemplates({ uid }));
  }, [dispatch, uid]);

  useEffect(() => {
    const gridEl = document.getElementById("grid");
    if (gridEl) {
      gridEl.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToTop]);

  useEffect(() => {
    const recipeArray = recipes.map((item, index) => {
      return {
        label: item.name,
        value: item.name,
        key: index,
        id: item?.id,
        name: { label: item.name, value: item.name },
        description: item?.description,
        preparation: item?.preparation,
        calories: item?.calories,
        foodType: item?.foodType,
        ingredients: item?.ingredients,
        nutritions: item?.nutritions,
        howToTake: item?.howToTake,
        weight: item?.weight,
        foodCategory: { value: item?.foodCategory, label: item?.foodCategory },
        qty: item?.qty,
        unit: { value: item.unit, label: item.unit },
      };
    });

    setRecipeList(recipeArray);
  }, [recipes]);
  const defaultHandlerv1 = useMemo(() => {
    return {
      id: null,
      name: null,
      description: null,
      preparation: null,
      calories: null,
      foodType: null,
      ingredients: null,
      nutritions: null,
      howToTake: null,
      weight: null,
      foodCategory: null,
      qty: null,
      unit: null,
      time: null,
    };
  }, []);

  const timeline = useMemo(
    () => [
      { title: "Early Morning", id: "earlyMorning" },
      { title: "Breakfast", id: "breakfast" },
      { title: "Mid Morning", id: "midMorning" },
      { title: "Brunch", id: "brunch" },
      { title: "Mid Meal", id: "midMeal" },
      { title: "Lunch", id: "lunch" },
      { title: "Post Lunch", id: "postLunch" },
      { title: "Evening", id: "evening" },
      { title: "Before Dinner", id: "beforeDinner" },
      { title: "Dinner", id: "dinner" },
      { title: "Post Dinner", id: "postDinner" },
      { title: "Late Night", id: "lateNight" },
    ],
    []
  );
  const getRecipesHandler = useCallback(async (id) => {
    try {
      setRecipeLoader(true);
      const data = await getUserRecipes(id);
      setRecipes(data);
      setRecipeLoader(false);
    } catch (err) {
      setRecipeLoader(false);
      toast.error("Something went wrong!!!");
    }
  }, []);
  useEffect(() => {
    getRecipesHandler(uid);
  }, [getRecipesHandler, uid]);

 

  const handleSaveTemplate = () => {
    setSaveIsClicked(true);
    const validDietPlan = Object.values(dietPlan).some((item) => {
      return item.length > 0;
    });
    if (templateName === "") {
      setTemplateNameError(true);
      toast.error("Enter template name");
      return false;
    }
    if (note === "") {
      setError((pre) => ({ ...pre, notes: true }));
      toast.error("Add notes");
      return false;
    }
    if (!weekPlan.value) {
      toast.error("Add week plan");

      return false;
    }
    if (!chartType.value) {
      toast.error("Add chart type");

      return false;
    }

    let keys = Object.keys(dietPlan);

    for (let i = 0; i < keys.length; i++) {
      if (
        dietPlan[keys[i]] &&
        keys[i] !== "date" &&
        keys[i] !== "notes" &&
        keys[i] !== "id" &&
        keys[i] !== "selected" &&
        keys[i] !== "date" &&
        keys[i] !== "clientName" &&
        keys[i] !== "dietChartId" &&
        keys[i] !== "clientId" &&
        keys[i] !== "createdAt" &&
        keys[i] !== "weekPlan" &&
        keys[i] !== "chartType"
      ) {
        let recipe = dietPlan[keys[i]];
        let recipeId = keys[i];
        const recipeIds = [
          "earlyMorning",
          "breakfast",
          "midMorning",
          "brunch",
          "midMeal",
          "lunch",
          "postLunch",
          "evening",
          "beforeDinner",
          "dinner",
          "postDinner",
          "lateNight",
        ];
        const recipeIds1 = [
          "Early Morning",
          "Breakfast",
          "Mid Morning",
          "Brunch",
          "Mid Meal",
          "Lunch",
          "Post Lunch",
          "Evening",
          "Before Dinner",
          "Dinner",
          "Post Dinner",
          "Late Night",
        ];

        for (let j = 0; j < recipe.length; j++) {
          if (
            recipe[j].name ||
            recipe[j].description ||
            recipe[j].preparation ||
            recipe[j].howToTake ||
            recipe[j].nutritions ||
            recipe[j].ingredients ||
            recipe[j].foodType ||
            recipe[j].calories ||
            recipe[j].weight ||
            recipe[j].foodCategory ||
            recipe[j].unit ||
            recipe[j].qty
          ) {
            if (!recipe[j].name && recipeId === recipeIds[i]) {
              toast.error(
                `Please enter a Recipe Name field for ${recipeIds1[i]}`
              );

              return;
            }

            if (!recipe[j].description && recipeId === recipeIds[i]) {
              toast.error(
                `Please enter a Recipe Descripiton field for ${recipeIds1[i]}`
              );

              return;
            }

            if (!recipe[j].preparation && recipeId === recipeIds[i]) {
              toast.error(
                `Please fill How to Prepare field for ${recipeIds1[i]}`
              );

              return;
            }

            if (!recipe[j].howToTake && recipeId === recipeIds[i]) {
              toast.error(`Please fill How to Take field for ${recipeIds1[i]}`);
              return;
            }

            if (
              (!recipe[j].ingredients || recipe[j].ingredients.length === 0) &&
              recipeId === recipeIds[i]
            ) {
              toast.error(`Please fill Ingredients field for ${recipeIds1[i]}`);
              return;
            }

            if (
              (!recipe[j].foodType || recipe[j].foodType.length === 0) &&
              recipeId === recipeIds[i]
            ) {
              toast.error(`Please fill Food Type field for ${recipeIds1[i]}`);
              return;
            }

            if (!recipe[j].qty && recipeId === recipeIds[i]) {
              toast.error(`Please enter Qty. field for ${recipeIds1[i]}`);
              return;
            }

            if (!recipe[j].unit && recipeId === recipeIds[i]) {
              toast.error(`Please fill Unit field for ${recipeIds1[i]}`);
              return;
            }

            if (!recipe[j].foodCategory && recipeId === recipeIds[i]) {
              toast.error(
                `Please fill Food Category field for ${recipeIds1[i]}`
              );
              return;
            }

            if (!recipe[j].time && recipeId === recipeIds[i]) {
              toast.error(`Please fill Time field for ${recipeIds1[i]}`);
              return;
            }
          } else if (recipeId === recipeIds[i]) {
            toast.error(
              `Please fill all added recipe fields for ${recipeIds1[i]}`
            );

            return;
          }
        }
      }
    }
    if (!validDietPlan) {
      toast.error("Add diet plan");

      return false;
    }
    setTemplateNameError(false);

    const payload = {
      templateName: templateName,
      chartType: chartType,
      weekPlan: weekPlan,
      note: note,
      dietPlan: dietPlan,
      uid: uid,
      setSaveIsClicked: setSaveIsClicked,
    };
    dispatch(saveNewDietChartTemplate(payload));
  };

  return (
    <>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
      >
        <SuiBox>
          <SuiTypography variant="h5">Add Template </SuiTypography>
        </SuiBox>
        <Icon
          sx={{
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          }}
          onClick={() => {
            setChartType({});
            setDietPlan({ ...dietChartObj });
            setNote("");
            setWeekPlan({});
            setTemplateName("");
            setSelectedItem(null);
            props.setOpenAddTemplateDrawer(false);
          }}
        >
          close
        </Icon>
      </SuiBox>
      <SuiBox
        style={{
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <SuiBox lineHeight={1} mt={3} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Template Name
          </SuiTypography>
          <SuiInput
            size="medium"
            type="text"
            label="Template Name"
            placeholder="Enter Template Name"
            name="templateName"
            value={templateName}
            onChange={(e) => {
              setTemplateNameError(false);
              setTemplateName(e.target.value);
            }}
            error={templateNameError}
            helperText={templateNameError ? "Enter a template name" : ""}
          />
        </SuiBox>
        <SuiBox lineHeight={1} mt={3} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Week Plan
          </SuiTypography>
          <Createable
            error={error.weekPlan}
            options={getWeeksArray() ?? []}
            value={weekPlan.value ? weekPlan : "Add week plan"}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Add week plan"
            onChange={(e) => {
              setError((pre) => ({ ...pre, weekPlan: false }));
              setWeekPlan(e);
            }}
          />
        </SuiBox>

        <SuiBox lineHeight={1} mt={3}>
          <SuiTypography mb={1} variant="h6">
            Chart Type
          </SuiTypography>
          <Createable
            error={error.chartType}
            options={chartTypes ?? []}
            value={chartType.value ? chartType : ""}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Add chart type"
            onChange={(e) => {
              setError((pre) => ({ ...pre, chartType: false }));

              setChartType(e);
            }}
          />
        </SuiBox>
      </SuiBox>
      <Grid container spacing={3}>
        {timeline.map((item, index) => (
          <Grid item xs={12} lg={12} key={index}>
            <RecipeChart
              recipeList={recipeList}
              readOnly={false}
              defaultHandler={defaultHandlerv1}
              title={item.title}
              chartId={item.id}
              dietChart={dietPlan[item.id]}
              setDietPlan={setDietPlan}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              open={open}
              recipeLoader={recipeLoader}
              setScrollToTop={setScrollToTop}
            />
          </Grid>
        ))}
      </Grid>
      <SuiBox lineHeight={1} mt={3} mb={3} style={{ width: "50%" }}>
        <SuiTypography mb={1} variant="h6">
          Some Notes for Client
        </SuiTypography>
        <SuiInput
          type="text"
          name="notes"
          multiline
          rows={7}
          error={error?.notes}
          value={note}
          onChange={(e) => {
            setError((pre) => ({ ...pre, notes: false }));
            setNote(e.target.value);
          }}
        />
      </SuiBox>
      <SuiBox lineHeight={1} mb={5}>
        <SuiButton onClick={handleSaveTemplate} mb={1} color="dark">
          Save Template&nbsp;{" "}
          {loader ? (
            <CircularProgress color="success" size={"0.9rem"} />
          ) : (
            <SaveIcon />
          )}
        </SuiButton>
      </SuiBox>
    </>
  );
};

export default AddTemplate;
