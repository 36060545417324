import { toast } from "react-toastify";

export const validateExcelHeaders = (dataArray, fieldsMapping) => {
  let headerFieldCount = 0;
  if (dataArray.length === 0) {
    toast.error("Excel file is corrupted, plz download a new one!!");
    return false;
  }

  for (const mapping of fieldsMapping) {
    const excelHeader = mapping.excelName;
    const columnIndex = dataArray[0].indexOf(excelHeader);

    if (columnIndex !== -1) {
      headerFieldCount++;
    }
  }

  if (headerFieldCount !== fieldsMapping.length) {
    toast.error("Excel file is corrupted, plz download a new one!!");
    return false;
  }

  if (dataArray.length === 1) {
    toast.error("File is empty, plz add data!!");
    return false;
  }

  return true;
};
