import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import {
    formatDatev3, createInvoiceId,
    extractUsername
} from 'utils/common'
import styles from './style'
import facebookIcon from "assets/images/fb.png";
import instagramIcon from "assets/images/insta.png";
import twitterIcon from "assets/images/twitter.png";

const Header = ({ user }) => {
    return (
        <View style={styles.header}>
            <View style={styles.details}>
                <Image style={styles.logoImage} src={user.logo} />
            </View>

            <Text style={styles.title}>INVOICE</Text>
        </View>
    )
}

const HeaderContent = ({ user }) => {
    return (
        <View style={styles.headerContent}>
            <View>
                <Text style={styles.company}>{user.name}</Text>
                <Text style={{ ...styles.company, ...styles.address }}>{user.address}</Text>
                <Text style={styles.company}>{user.phoneNumber}</Text>
                <Text style={styles.company}>{user.email}</Text>
            </View>
            <View>
                <View style={styles.headerRow}>
                    <Text style={styles.invoice}>INVOICE #</Text>
                    <Text style={styles.date}>DATE</Text>
                </View>
                <View style={styles.dataRow}>
                    <Text style={{ ...styles.invoice, ...styles.padding }}>{createInvoiceId()}</Text>
                    <Text style={{ ...styles.date, ...styles.padding }}>{formatDatev3(new Date())}</Text>
                </View>
            </View>
        </View>
    )
}


const SubscriptionContent = ({ subscription }) => {
    return (
        <View style={styles.mainDataRow}>
            <View style={{ ...styles.desc, ...styles.borderRightTop, width: '20%' }}>
                <Text>{subscription.subscriptionType}</Text>
            </View>

            <View style={{ ...styles.qty, ...styles.borderRightTop, width: '20%' }}>
                <Text>{subscription.amountPaid}</Text>
            </View>

            <View style={{ ...styles.unit, ...styles.borderRightTop, width: '30%' }}>
                <Text>{subscription.referAmount}</Text>
            </View>

            <View style={{ ...styles.amount, ...styles.borderRightTop, width: '30%' }}>
                <Text>{subscription.dateOfSubscription}</Text>
            </View>
            <View style={{ ...styles.amount, ...styles.borderRightTop, width: '20%' }}>
                <Text>{subscription.nextRenewableDate}</Text>
            </View>
        </View>
    )
}




const ProductServiceSection = ({ subscription }) => {

    return (<>
        <View style={styles.mainRow}>
            <Text style={{ ...styles.desc, width: '20%' }}>SUBSCRIPTION TYPE</Text>
            <Text style={{ ...styles.desc, width: '20%' }}>AMOUNT PAID</Text>
            <Text style={{ ...styles.desc, width: '30%' }}>REDEEMED AMOUNT</Text>
            <Text style={{ ...styles.desc, width: '30%' }}>DATE OF SUBSCRIPTION</Text>
            <Text style={{ ...styles.desc, width: '20%' }}>NEXT RENEWAL DATE</Text>
        </View>

        <SubscriptionContent subscription={subscription} />
    </>)
}



const Socials = ({ user }) => {
    // Extract usernames from social media URLs
    const facebookUsername = user.fb ? extractUsername(user.fb) : '';
    const twitterUsername = user.twitter ? extractUsername(user.twitter) : '';
    const instagramUsername = user.instagram ? extractUsername(user.instagram) : '';
    return (
        <View style={styles.footer}>
            {/* <View style={styles.termText}>
                <Text style={styles.termsText1}>Terms & conditions: </Text>
                <Text style={styles.termsText2}>{note}</Text>
            </View> */}
            <Text style={styles.footerText}>Thank you for your business!</Text>
            <View style={styles.socialMedia}>
                {user.fb && (
                    <View style={styles.socialMedia}>
                        <Image src={facebookIcon} style={styles.icon} />
                        <Text style={styles.username}>@{facebookUsername}</Text>
                    </View>
                )}
                {user.twitter && (
                    <View style={styles.socialMedia}>
                        <Image src={twitterIcon} style={styles.icon} />
                        <Text style={styles.username}>@{twitterUsername}</Text>
                    </View>
                )}
                {user.instagram && (
                    <View style={styles.socialMedia}>
                        <Image src={instagramIcon} style={styles.icon} />
                        <Text style={styles.username}>@{instagramUsername}</Text>
                    </View>
                )}
            </View>

            {(user?.signature && user.signature !== "") &&
                (
                    <View style={styles.termSig}>
                        <View>
                            <Image style={styles.signature} src={user.signature} />
                            <Text style={styles.termsText1}>Authority Signature</Text>
                        </View>
                    </View>
                )}

        </View>
    )
}

const Footer = ({ user }) => {
    return (
        <View style={styles.contact}>
            <Text>If you have questions about this invoice, please contact</Text>
            <Text style={styles.details}>{user.name}, {user.phoneNumber}, {user.email}</Text>
            {(user.qr && user.qr !== "") && <Image style={styles.qr} src={user.qr} />}
        </View>
    )
}

// Create Document Component
const MyDocument = ({ user, subscription }) => {
    return (
        <Document>
            <Page style={styles.page} size="A3">
                <Header user={user} />
                <HeaderContent user={user} />
                <ProductServiceSection subscription={subscription} />
                <Footer user={user} />
                <Socials user={user} />
            </Page>
        </Document>
    )
};

export default MyDocument