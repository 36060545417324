import React, { useState, useMemo } from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'
import { uploadImages } from 'services/clientdetails'
import '../../sub-components/common/imageStyling.css';
import CustomButton from 'containers/CustomButton';
const InputFile = styled('input')({
    display: 'none',
});


function UploadHandler({ uid, setLoader, loader, setSelectedClient }) {

    const [formdata, setFormdata] = useState({ image: "", date: "" })
    //error handler
    const errorDefault = useMemo(() => {
        return {
            image: false,
            date: false,
        }
    }, []);
    const [errorMessage, setErrorMessage] = useState(errorDefault)

    const handleImage = (evt) => {
        let tgt = evt.target;
        let files = tgt.files;

        // FileReader support
        if (FileReader && files && files.length) {
            let fr = new FileReader();
            fr.onload = function () {
                setFormdata({
                    ...formdata,
                    [evt.target.name]: files[0],
                    [`${evt.target.name}_location`]: fr.result
                })
            }
            fr.readAsDataURL(files[0]);
        }
    }

    //for submitting data
    const submitHandler = async (e) => {
        if (!formdata.image) {
            toast.error("Please Choose the Image field")
            setErrorMessage({
                ...errorMessage,
                image: true
            })
            return;
        }
        const today = new Date();
        if (new Date(today) < new Date(formdata.date)) {
            toast.error("Please Choose the Date till Current Day")
            setErrorMessage({
                ...errorMessage,
                image: true
            })
            return;
        }

        if (!formdata.date) {
            toast.error("Please fill Image Date field")
            setErrorMessage({
                ...errorMessage,
                date: true
            })
            return;
        }
        try {
            setLoader(true)
            const data = await uploadImages({ formdata, uid })

            setSelectedClient((prevClientState) => {
                const updatedClientState = { ...prevClientState };
                updatedClientState['imageNowVsEarlier'] = [ ...data ];
                return updatedClientState;
            });
            setFormdata({ image: "", date: "" })
            toast.success("Successfully submitted")
            setLoader(false)
        } catch (e) {
            setLoader(false)
            toast.error(e?.message ?? "Something went wrong, retry")
        }
    }

    return (
        <SuiBox
            display="flex" flexWrap="wrap"
            alignItems="center" >
            <SuiBox my={1} mr={2.5} mt={1}>
                <label htmlFor="image-1" style={styles.pointer}>
                    <InputFile
                        id="image-1"
                        type="file"
                        name="image"
                        
                        width="100px"
                        accept="image/*"
                        error={errorMessage?.image}
                        onChange={handleImage} />
                    <SuiBox py={1} pr={2}>
                        <SuiTypography
                            fontWeight="bold"
                            textTransform="capitalize"
                            variant="h6" mb={1} >
                            Choose Image &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        </SuiTypography>
                        <SuiAvatar
                            alt="image"
                            style={styles.avatar}
                        >Choose Image </SuiAvatar>
                    </SuiBox>
                </label>
            </SuiBox>

            <SuiBox lineHeight={1} style={styles.ml}>
                <SuiTypography mb={1} variant="h6">Image Date</SuiTypography>
                <SuiInput
                    type="date"
                    name="date"
                    style={styles.dateInput}
                    error={errorMessage?.date}
                    value={formdata.date ?? ''}
                    onChange={(e) => {
                        setErrorMessage({
                            ...errorMessage,
                            [e.target.name]: false
                        })
                        setFormdata({
                            ...formdata,
                            [e.target.name]: e.target.value
                        })
                    }} />
            </SuiBox>

            <SuiBox mb={2} style={styles.btn} display="flex" alignItems="center" >
                <CustomButton
                    color="dark"
                    variant="gradient"
                    onClick={submitHandler}
                    fullWidth
                    width={180}
                    disabled={loader}
                    title={'Upload Image'}
                    loader={loader}
                />
                {formdata.image &&
                    <img className="uploadedimage"
                        src={URL.createObjectURL(formdata.image)} alt="Uploaded" />}
            </SuiBox>

        </SuiBox>
    )
}

export default UploadHandler;


/**-------------------- styling ----------------- */
const styles = {
    ml: {
        marginLeft: "-20px"
    },

    dateInput: {
        width: "297px !important",
        height: "32px !important"
    },

    avatar: {
        width: 222,
        height: 40,
        borderRadius: 8,
        color: "#3A416F",
        fontSize: "12px",
        border: "1px solid #D2D6DA"
    },

    btn: {
        marginTop: "50px",
        marginLeft: "20px"
    },

    pointer: {
        cursor: 'pointer'
    }
}