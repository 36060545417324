import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 10
    },

    header: {
        flexDirection: 'row',
        alignItem: 'center',
        fontFamily: 'Poppins',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 25,
        marginBottom: 30
    },

    logoView:{
        width: '65%',
        flexDirection: 'column'
    },

    headerText: {
        fontSize: 12,
        color: '#06376B',

    },

    logo:{
        width: '35%',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    logoName: {
        color: '#06376B',
        fontWeight: 'bold', 
        fontSize: 16,
        fontFamily: 'Lato Bold'
    },

    logoImage:{
        width: '130px',
        height: '30px',
        objectFit: 'contain'
    },

    dietView: {
        paddingLeft: 12,
        paddingRight: 12,
        marginBottom: 70,
        fontFamily: 'Poppins',
        fontWeight: 400
    },

    section_1: {
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItem: 'center'
    },

    section_2: {
        border: '1px solid #838181',
        flexDirection: 'row',
        marginBottom: 10,
    },

    table: {
        flexDirection: 'column',
    },

    tableRow: {
        flexDirection: 'row',
    },

    tableColHead: {
        width: '20%',
        padding: 3,
        fontSize: 9,
        paddingLeft: 8,
        border: '1px solid #787676',
        borderTop: 'none',
    },

    tableColDesc: {
        width: '80%',
        fontSize: 9,
        padding: 3,
        border: '1px solid #787676',
        borderLeft: 'none',
        borderTop: 'none'
    },

    tag: {
        backgroundColor: '#17AD37',
        color: 'white',
        padding: 3,
        marginRight: 4,
        borderRadius: 3,
    }

});
