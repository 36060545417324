import SuiTypography from "components/SuiTypography";
import ModalImage from "react-modal-image";
import "../../sub-components/common/imageStyling.css";
import { formatDateField } from "utils/common";
import SuiBox from "components/SuiBox";
import { Grid, Icon } from "@mui/material";

export const ImageNowVsEarlierRow = (imageArray, deleteHandler) => {
  const rows = imageArray.map((item, index) => (
    <Grid key={index} item xs={12} sm={6} lg={4}>
      <SuiBox
        p={2}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        position="relative"
        alignItems="center"
        style={{
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
        }}
      >
        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${20} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            position: "absolute",
            right: 10,
            top: 0,
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => deleteHandler(item, index, "imageNowVsEarlier")}
        >
          close
        </Icon>

        <div className="Imagesize">
          <ModalImage
            small={
              typeof item.image === "string"
                ? item.image
                : URL.createObjectURL(item.image)
            }
            large={
              typeof item.image === "string"
                ? item.image
                : URL.createObjectURL(item.image)
            }
            alt="Hello Fitify!"
            style={{
              width: "146px",
              height: "255px",
              margin: "auto",
              paddingBottom: "20px",
            }}
          />
        </div>
        <SuiTypography mt={1} variant="button" fontWeight="medium">
          {formatDateField(item?.date)}
        </SuiTypography>
      </SuiBox>
    </Grid>
  ));

  return rows;
};
