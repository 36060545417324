import { useState, useEffect } from 'react'
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";


import { Button } from "@mui/material";


import { useDispatch, useSelector } from "react-redux";
import { resetPassword, userSelector, clearState  } from "store/features/authSlice";
import { toast } from 'react-toastify';
import { validatePassword } from 'utils/common'


function ChangePassword({ title, setLoader }) {


    const [ loaderv1, setLoaderv1 ] = useState(false)

    //for handling error
    const [ errorMessage, setErrorMessage ] = useState({
        newPassword: false,
        oldPassword: false
    })

    //for handling message for contact
    const [ formdata, setFormdata ] = useState({
        newPassword: null,
        oldPassword: null
    })

    const dispatch =  useDispatch()
    const { isSuccess, isError, message, email } = useSelector(userSelector);

    useEffect(()=>{
        if(isError){
            setLoader(false)
            dispatch(clearState())
        }

        if(isSuccess){
            setLoader(false)
            dispatch(clearState())
        }

    },[isError, message, dispatch, isSuccess, setLoader])

    const resetHandler = async () => {

        if(!formdata.oldPassword){
            toast.info("Enter your old password!!")
            setErrorMessage({
                ...errorMessage,
                'oldPassword': true
            })
            return
        }

        if(formdata.newPassword){
            const { isValid, message } = validatePassword(formdata.newPassword)
            if(!isValid){
                toast.info(message)
                setErrorMessage({
                    ...errorMessage,
                    'newPassword': true
                })
                return;
            }
        } else {
            toast.info("Plz enter new password")
            setErrorMessage({
                ...errorMessage,
                'newPassword': true
            })
            return;
        }

        if(formdata.oldPassword === formdata.newPassword){
            toast.info("Your new password can't be same as old password!!")
            return
        }
        setLoader(true)
        setLoaderv1(true)

        await dispatch(resetPassword({ newPassword: formdata.newPassword, oldPassword: formdata.oldPassword, email }))
        setLoaderv1(false)
    }


    return (
        <Card sx={{ height: "100%" }}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </SuiTypography>
            </SuiBox>
            <SuiBox p={2}>

                <SuiBox mt={3} lineHeight={1} width={"80%"}>
                    <SuiTypography mb={1} variant="button">Existing Password</SuiTypography>
                    <SuiInput 
                        type="password" 
                        name="oldPassword"
                        autoComplete="false"
                        error={errorMessage?.oldPassword}
                        value={formdata.oldPassword??''}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }} />
                </SuiBox>

                <SuiBox mt={3} lineHeight={1} width={"80%"}>
                    <SuiTypography mb={1} variant="button">New Password</SuiTypography>
                    <SuiInput 
                        type="password" 
                        name="newPassword"
                        autoComplete="false"
                        error={errorMessage?.newPassword}
                        value={formdata.newPassword??''}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }} />
                </SuiBox>

                <SuiBox mt={4} display="flex" justifyContent='center'>
                    <SuiButton
                        style={{
                            width: '50%'
                        }}
                        component={Button}
                        color="dark"
                        variant="gradient"
                        onClick={resetHandler}
                        disabled={loaderv1}
                        fullWidth>
                            {loaderv1? 'Updating . . .': 'Update Password'}
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </Card>
    );
}

export default ChangePassword;
