import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  addDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

//List of recipes
export const getrecipes = createAsyncThunk(
  "Recipe/getrecipes",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "recipe"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let timestamp = null;
        let updatedAt = null;
        if (doc.data().createdAt) {
          let createdAt = doc.data().createdAt;
          timestamp =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          timestamp = moment.utc(timestamp);
        }

        if (doc.data().updatedAt) {
          updatedAt = doc.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        data.push({
          ...doc.data(),
          createdAt: timestamp,
          id: doc.id,
          updatedAt,
        });
      });

      data.sort(function (a, b) {
        let createdAt1 = a.createdAt._d;
        createdAt1 = createdAt1.seconds
          ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
          : createdAt1;

        let createdAt2 = b.createdAt._d;
        createdAt2 = createdAt2.seconds
          ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
          : createdAt2;
        return new Date(createdAt2) - new Date(createdAt1);
      });

      return [...data];
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Posting Recipe
export const AddRecipe = createAsyncThunk(
  "Recipe/AddRecipe",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "recipe"),
        where("name", "==", formdata.name),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Recipe with this name already exists, choose different name",
        });
      }

      let data = {
        name: formdata.name,
        description: formdata.description,
        preparation: formdata.preparation,
        calories: formdata.calories,
        weight: formdata.weight,
        foodType: formdata.foodType,
        ingredients: formdata.ingredients,
        nutritions: formdata.nutritions ?? [],
        howToTake: formdata.howToTake,
        unit: formdata.unit.value,
        qty: formdata.qty,
        foodCategory: formdata.foodCategory.value,
        createdAt: new Date(),
        adminId: uid,
      };

      if (
        isNaN(formdata.calories) ||
        formdata.calories === "" ||
        formdata.calories === null
      ) {
        delete data.calories;
      }

      if (
        isNaN(formdata.calories) ||
        formdata.weight === "" ||
        formdata.weight === null
      ) {
        delete data.weight;
      }

      const recipeRef = await addDoc(collection(firestore, "recipe"), {
        ...data,
      });

      return {
        ...formdata,
        foodCategory: formdata.foodCategory.value,
        unit: formdata.unit.value,
        id: recipeRef.id,
        createdAt: new Date(),
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add multiple recipes
export const AddMultipleRecipes = createAsyncThunk(
  "Recipe/AddMultipleRecipes",
  async ({ recipes, adminId }, thunkAPI) => {
    recipes?.map((item) => {
      item["nutritions"] = item["nutritions"].map((item) => {
        // Create a string in the format 'type-grams'

        const parts = item?.split(/[:\-]+/);

        if (item.includes("-") || item.includes(":")) {
          // Filter out empty strings from the split
          const filteredParts = parts.filter((part) => part.trim() !== "");

          if (filteredParts.length === 2) {
            const nutrientType = filteredParts[0]?.trim();
            const grams = filteredParts[1];

            return {
              type: nutrientType,
              grams: parseFloat(grams),
            };
          }
        }
      });
    });

    const addedRecipes = [];
    try {
      const batchSize = 10;
      const batches = [];
      const writeBatches = [];
      const writeBatchSize = 500;
      for (let i = 0; i < recipes.length; i += batchSize) {
        const batch = recipes.slice(i, i + batchSize).map((ele) => ele.name);
        batches.push(batch);
      }

      for (let i = 0; i < recipes.length; i += writeBatchSize) {
        const batch = recipes.slice(i, i + writeBatchSize);
        writeBatches.push(batch);
      }

      const queries = [];
      const existingNames = [];

      for (const batch of batches) {
        const recipeQuery = query(
          collection(firestore, "recipe"),
          where("name", "in", batch),
          where("adminId", "==", adminId)
        );

        queries.push(recipeQuery);
      }

      await Promise.all(
        queries.map(async (q) => {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            existingNames.push(doc.data().name);
          });
        })
      );

      if (existingNames.length > 0) {
        return thunkAPI.rejectWithValue({
          message:
            "Recipe with this name already exists, choose different name",
        });
      }
      for (const batch of writeBatches) {
        const batchWrite = writeBatch(firestore);
        for (const recipe of batch) {
          const recipeId = uuidv4();
          const newRecipeRef = doc(firestore, "recipe", recipeId);
          addedRecipes.push({
            ...recipe,
            id: recipeId,
          });
          batchWrite.set(newRecipeRef, { ...recipe });
        }
        await batchWrite.commit();
      }

      return addedRecipes;
    } catch (e) {
      console.error("error", e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit Recipe
export const EditRecipe = createAsyncThunk(
  "Recipe/EditRecipe",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "recipe"),
        where("name", "==", formdata.name),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      let check = false;
      querySnapshot.forEach((doc) => {
        if (doc.id !== formdata.id) {
          check = true;
        }
      });
      if (check) {
        return thunkAPI.rejectWithValue({
          message:
            "Recipe with this name already exists, choose different name",
        });
      }

      const ref = doc(firestore, "recipe", formdata.id);
      let data = {
        name: formdata.name,
        description: formdata.description,
        preparation: formdata.preparation,
        foodType: formdata.foodType,
        ingredients: formdata.ingredients,
        nutritions: formdata.nutritions ?? [],
        calories: formdata.calories,
        weight: formdata.weight,
        howToTake: formdata.howToTake,
        foodCategory: formdata.foodCategory.value,
        unit: formdata.unit.value,
        qty: formdata.qty,
        updatedAt: new Date(),
      };

      if (
        isNaN(formdata.calories) ||
        formdata.calories === "" ||
        formdata.calories === null
      ) {
        delete data.calories;
      }

      if (
        isNaN(formdata.calories) ||
        formdata.weight === "" ||
        formdata.weight === null
      ) {
        delete data.weight;
      }

      await updateDoc(ref, {
        ...data,
      });

      return {
        id: formdata.id,
        name: formdata.name,
        description: formdata.description,
        preparation: formdata.preparation,
        calories: formdata.calories,
        weight: formdata.weight,
        foodType: formdata.foodType,
        ingredients: formdata.ingredients ?? [],
        nutritions: formdata.nutritions,
        howToTake: formdata.howToTake,
        unit: formdata.unit.value,
        qty: formdata.qty,
        foodCategory: formdata.foodCategory.value,
        updatedAt: new Date(),
        createdAt: formdata?.createdAt,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//delete doc
export const deleteDocument = createAsyncThunk(
  "Recipe/deleteDocument",
  async ({ id }, thunkAPI) => {
    try {
      await deleteDoc(doc(firestore, "recipe", id));

      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const RecipeSlice = createSlice({
  name: "Recipe",
  initialState: {
    recipes: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },
  },
  extraReducers: {
    [getrecipes.fulfilled]: (state, { payload }) => {
      state.recipes = payload ?? [];
      state.isSuccess = true;
      state.isFetching = false;
    },
    [getrecipes.pending]: (state) => {
      state.isFetching = true;
    },
    [getrecipes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditRecipe.fulfilled]: (state, { payload }) => {
      state.recipes = state.recipes.map((item) => {
        if (item.id === payload.id) {
          item = { ...payload };
        }
        return { ...item };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Recipe updated successfully";
    },

    [EditRecipe.pending]: (state) => {
      state.isFetching = true;
    },
    [EditRecipe.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    // AddMultipleRecipes

    [AddMultipleRecipes.fulfilled]: (state, { payload }) => {
      state.recipes = [...payload, ...state.recipes];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Recipes added successfully";
    },
    [AddMultipleRecipes.pending]: (state) => {
      state.isFetching = false;
    },
    [AddMultipleRecipes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddRecipe.fulfilled]: (state, { payload }) => {
      state.recipes = [payload, ...state.recipes];
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Recipe added successfully";
    },
    [AddRecipe.pending]: (state) => {
      state.isFetching = true;
    },
    [AddRecipe.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [deleteDocument.fulfilled]: (state, { payload }) => {
      state.recipes = state.recipes.filter((item) => {
        if (item.id !== payload.id) {
          return { ...item };
        }
        return null;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully deleted!!";
    },
    [deleteDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const { clearState } = RecipeSlice.actions;

export const recipeSelector = (state) => state.recipes;
