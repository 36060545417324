import { Card, Icon, Tooltip } from "@mui/material";
import Table from "examples/Tables/Table";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import NoData from "containers/DataNotAvailBox";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { AppointmentRows } from "./row";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { fetchAppointments } from "services/dashboard";

function Appointments({ title, tooltip, uid }) {
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);

  const [appointments, setAppointments] = useState([]);

  const getAppointments = useCallback(async () => {
    try {
      setLoader(true);
      const data = await fetchAppointments({ uid });
      setAppointments(data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
      toast.error("Something went wrong");
    }
  }, [uid]);

  useEffect(() => {
    if (uid) {
      getAppointments();
    }
  }, [getAppointments, uid]);

  const columns = [
    { name: "Client Name", align: "left", minWidth: "150px" },
    { name: "Date/Time", align: "left" },
    { name: "Mobile number", align: "left", minWidth: "150px" },
  ];

  const onClickHandler = useCallback(() => {
    toast.info("Feature comming soon!!");
  }, []);

  let navigate = useNavigate(); //for navigation
  useEffect(() => {
    setRows(AppointmentRows(appointments, onClickHandler, navigate));
  }, [appointments, onClickHandler, navigate]);

  return (
    <SuiBox mb={3} style={{ height: "100%" }}>
      <Card style={{ overflow: "inherit", height: "100%" }}>
        <SuiBox style={{ overflow: "hidden", borderRadius: 20 }}>
          <SuiTypography px={2.5} pt={2.5}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SuiTypography variant="h6">{title}</SuiTypography>
              <span style={{ fontSize: "14px", height: "16px", width: "14px" }}>
                <Tooltip
                  title={tooltip}
                  placement="top"
                  style={{ color: "#67748e", height: "16px", width: "14px" }}
                >
                  <Icon color="inherit">{"help"}</Icon>
                </Tooltip>
              </span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SuiTypography
                variant="h6"
                onClick={() => {
                  navigate(`/leads?appointcreate=1`);
                }}
                style={{
                  color: "#34673C",
                  fontSize: 14,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Create Appointment
              </SuiTypography>
            </div>
          </SuiTypography>

          <SuiBox
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            style={{
              maxHeight: 185,
              minHeight: rows.length === 0 ? 185 : 0,
            }}
          >
            {!loader ? (
              <SuiBox
                id="tableBox"
                style={{
                  overflow: "scroll",
                  overflowX: "auto",
                  width: "100%",
                  borderRadius: "1rem",
                }}
                maxHeight={185}
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {rows.length ? (
                  <Table columns={columns} rows={rows} />
                ) : (
                  <SuiBox
                    height={185}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoData message={"No Appointments today"} />
                  </SuiBox>
                )}
              </SuiBox>
            ) : (
              <SuiBox
                style={{ display: "flex", justifyContent: "center" }}
                mb={4}
              >
                <TailSpin color="#17c1e8" height={40} width={40} />
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Appointments;
