import React, { useState, useEffect } from 'react'
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";


const calculateBalance = (totalAmount, discount, amountPaid) =>{
    if(totalAmount){
        return parseFloat(totalAmount) - parseFloat(discount??0) -  parseFloat(amountPaid)
    }
}

function AccountStat({ accounts }) {

    const [ paid, setPaid ] = useState(0)
    const [ balance, setBalance ] = useState(0)
    useEffect(()=>{
        let balance_ = 0;
        let paid_ = 0;
        accounts.map((item)=>{
            paid_ += parseFloat(item.amountPaid)
            balance_ += calculateBalance(item.totalAmount, item.discount, item.amountPaid)
            return item
        })
        setPaid(paid_?.toFixed(2))
        setBalance(balance_?.toFixed(2))
    },[accounts])

    return (
        <Grid container my={2}>

            <Grid item xs={12} md={12} lg={6}>
                <SuiBox p={1} mx={2}  style={{ borderRadius: 8, display: 'flex', background: "#F8F9FA" }}>
                    <SuiTypography 
                        variant="h8" 
                        mr={1}
                        fontWeight="medium">
                        Total Amount Paid: 
                    </SuiTypography>

                    <SuiTypography 
                        variant="h9" 
                        fontWeight="medium">
                        {paid}
                    </SuiTypography>
                </SuiBox>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
                <SuiBox p={1}  style={{ borderRadius: 8, display: 'flex', background: "#F8F9FA" }}>
                    <SuiTypography 
                        variant="h8" 
                        mr={1}
                        fontWeight="medium">
                        Balance: 
                    </SuiTypography>

                    <SuiTypography 
                        variant="h9" 
                        fontWeight="medium"
                        style={{ color: 'red' }}>
                        {balance}
                    </SuiTypography>
                </SuiBox>
            </Grid>

        </Grid>
    )
}

export default AccountStat