import React, { useEffect, useState } from "react";
// @mui material components

// Fontawesome icons
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import SuiTypography from "components/SuiTypography";

function ServiceSearch({
  serviceOptions,
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  excelDownload,
}) {
  const [serviceOpt_, setserviceOpt_] = useState(null); //for selecting services

  useEffect(() => {
    serviceOptions.unshift({ value: "all", label: "All" });
  }, [serviceOptions]);
  return (
    <SuiBox
      display="flex"
      justifyContent="start"
      flexWrap="wrap"
      alignItems="end"
      p={3}
      pb={0}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose services
          </SuiTypography>
          <Createable
            options={serviceOptions ?? []}
            value={serviceOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose service"
            onChange={(e) => {
              setserviceOpt_(e);
              setFilterQuery({
                ...filterQuery,
                service: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>

        <SuiBox mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={1} mr={2.5}>
        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setserviceOpt_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default ServiceSearch;
