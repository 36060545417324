import React, { useState, useEffect, useMemo, useCallback } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import Grid from "@mui/material/Grid";
import { EditPayment } from "store/features/dietchart";

import CustomButton from "containers/CustomButton";
import { getServiceInventory } from "services/dietchart";
import ModalHeader from "containers/modal-header";

function Modal({
  open,
  setOpenModal,
  handleCloseModal,
  id,
  uid,
  selectedItem,
  isFetching,
  isSuccess,
  isError,
  message,
  mode,
  editHandler,
  viewHandler,
}) {
  const [services, setServices] = useState([]);
  const [serviceLoader, setServiceLoader] = useState(false);
  const getServices = useCallback(async (id) => {
    try {
      setServiceLoader(true);
      let data = await getServiceInventory(id);
      setServices(data);
      setServiceLoader(false);
    } catch (e) {
      toast.error("Something went wrong!!!");
      setServiceLoader(false);
    }
  }, []);
  useEffect(() => {
    if (open) {
      getServices(uid);
    } else {
      setServices([]);
    }
  }, [open, getServices, uid]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      startDate: null,
      completionDate: null,
      service: null,
      discount: 0,
      amountPaid: 0,
      serviceInstance: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      startDate: false,
      completionDate: false,
      service: false,
      discount: false,
      amountPaid: false,
      serviceInstance: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  useEffect(() => {
    if (selectedItem && open) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        serviceInstance: {
          value: selectedItem?.serviceInstance,
          label: selectedItem?.serviceInstance,
        },
        service: {
          value: selectedItem.serviceId,
          label: selectedItem.serviceName,
          totalAmount: selectedItem.totalAmount,
        },
      });
    }
  }, [selectedItem, defaultHandler, open]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.startDate) {
      toast.error("Please fill Start date field");
      setErrorMessage({
        ...errorMessage,
        startDate: true,
      });
      return;
    }

    if (!formdata.completionDate) {
      toast.error("Please fill Completion date field");
      setErrorMessage({
        ...errorMessage,
        completionDate: true,
      });
      return;
    }

    if (!formdata.service) {
      toast.error("Please select Service");
      setErrorMessage({
        ...errorMessage,
        service: true,
      });
      return;
    }

    if (
      formdata.discount === null ||
      formdata.discount === "" ||
      isNaN(formdata.discount)
    ) {
      toast.error("Please fill discount field");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (
      formdata.amountPaid === null ||
      isNaN(formdata.amountPaid) ||
      formdata.amountPaid === ""
    ) {
      toast.error("Please fill amount paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (!formdata.serviceInstance) {
      toast.error("Please select service instance field");
      setErrorMessage({
        ...errorMessage,
        serviceInstance: true,
      });
      return;
    }
    if (selectedItem) {
      dispatch(EditPayment({ formdata, id: id }));
      return;
    } else {
      handleCloseModal();
      toast.info("Something went wrong");
    }
  };

  useEffect(() => {
    if (isSuccess && open) {
      handleCloseModal();
      setTimeout(() => {
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    defaultHandler,
    message,
    open,
  ]);

  const [serviceInstanceOptions] = useState([
    { value: "First Time", label: "First Time", key: 1 },
    { value: "Extension", label: "Extension", key: 2 },
    { value: "Returning", label: "Returning", key: 3 },
  ]);

  const calculateBalance = () => {
    if (formdata.totalAmount) {
      let val =
        parseFloat(formdata.totalAmount) -
        parseFloat(formdata.discount ?? 0) -
        parseFloat(formdata.amountPaid);
      return val?.toFixed(2);
    }
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader mode={mode} title="Service, Payment & Balance History" />

        <Grid item>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                editHandler(selectedItem, "payment");
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem, "payment")}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseModal}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Start Date
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="date"
                  name="startDate"
                  readOnly={mode === "view"}
                  error={errorMessage?.startDate}
                  value={formdata.startDate ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Completion Date
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="date"
                  name="completionDate"
                  readOnly={mode === "view"}
                  error={errorMessage?.completionDate}
                  value={formdata.completionDate ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Taken
                </SuiTypography>
                <Createable
                  options={services}
                  loading={serviceLoader}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      service: e,
                      totalAmount: e.totalAmount,
                    });
                  }}
                  value={formdata.service}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Total Amount
                </SuiTypography>
                <SuiTypography mb={1} variant="h7">
                  INR {formdata?.service?.totalAmount ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Discount
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="discount"
                  readOnly={mode === "view"}
                  error={errorMessage?.discount}
                  value={formdata.discount ?? ""}
                  onChange={(e) => {
                    if (formdata.service === null) {
                      toast.info("Select package");
                      return;
                    }

                    if (e.target.value < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        [e.target.name]: 0,
                      });
                      return;
                    }

                    if (e.target.value > parseFloat(formdata.totalAmount)) {
                      toast.info("Can' exceed total amount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="amountPaid"
                  readOnly={mode === "view"}
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                INR{" "}
                {isNaN(calculateBalance()) ? "-" : calculateBalance() ?? "-"}
              </SuiTypography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Instance
                </SuiTypography>
                <Createable
                  options={serviceInstanceOptions}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      serviceInstance: e,
                    });
                  }}
                  value={formdata.serviceInstance}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                width={180}
                fullWidth
                style={{
                  marginBottom: 10,
                  height: 40,
                  width: 230,
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                loader={isFetching}
                disabled={isFetching || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
