import SuiTypography from "components/SuiTypography";
import { calculateProductTotalBalance } from "utils/common";
import { calculateProductFinalPrice } from "utils/common";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

export const ProductPurchasesRow = (
  products,
  editHandler,
  deleteHandler,
  clientId,
  viewHandler
) => {
  const rows = products?.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Purchased (Date/Time)": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.purchasedAt)}
        </SuiTypography>
      ),

      Product: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.productName}
        </SuiTypography>
      ),

      Quantity: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.quantity ?? "N/A"}
        </SuiTypography>
      ),

      MRP: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.mrp ?? "N/A"}
        </SuiTypography>
      ),

      "Selling Price WOT": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.sellingPriceWOT ?? "N/A"}
        </SuiTypography>
      ),

      "Discount (INR)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.discount ?? "N/A"}
        </SuiTypography>
      ),

      "GST(%)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.gst}
        </SuiTypography>
      ),

      "Selling Price WT": (
        <SuiTypography variant="button" fontWeight="medium">
          {calculateProductFinalPrice(
            item.quantity,
            item.sellingPriceWOT,
            item.discount,
            item.gst
          )}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography variant="button" fontWeight="medium">
          {isNaN(item?.amountPaid) ? 0 : item.amountPaid}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR{" "}
          {calculateProductTotalBalance(
            item.sellingPriceWOT,
            item.quantity,
            item.discount,
            item.gst,
            item.amountPaid
          )}
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item, 'product')}
          deleteHandler={() => {
            deleteHandler(clientId, item.productName, item.id);
          }}
        />
      ),
    };
  });

  return rows;
};
