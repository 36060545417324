import React from "react";
import Card from "@mui/material/Card";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { TailSpin } from "react-loader-spinner";
import { WhatsApp } from "@mui/icons-material";

export const profileGridItem = (key, value) => {
  return (
    <SuiBox style={styles.display} mt={1}>
      <SuiTypography variant="h8" mr={1} fontWeight="medium">
        {key}:
      </SuiTypography>
      <SuiTypography variant="h9" fontWeight="medium">
        {value ?? "N/A"}
      </SuiTypography>
      {key === "Ph. No" && (
        <IconButton
          size="small"
          color="inherit"
          style={{ padding: "3px", marginLeft: "5px" }}
          onClick={() => {
            window.open(`https://wa.me/${value}`, "_blank");
          }}
        >
          <WhatsApp style={{ color: "#075e54" }} />
        </IconButton>
      )}
    </SuiBox>
  );
};

function Profile({
  name,
  address,
  phoneNumber,
  familyName,
  email,
  gender,
  age,
  leadFrom,
  status,
  foodPreference,
  customerId,
  occupation,
  referredBy,
  editHandler,
  profileEditLoader,
}) {
  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox style={styles.main}>
          <SuiBox style={styles.container}>
            <SuiTypography variant="h8" mr={1} fontWeight="medium">
              Name:
            </SuiTypography>

            <SuiTypography variant="h9" fontWeight="medium">
              {name}
            </SuiTypography>

            <SuiTypography
              ml={3}
              variant="h9"
              style={{
                fontStyle: "italic",
                fontWeight: "600",
                color: status === "Inactive" ? "red" : "#82D616",
              }}
              fontWeight="medium"
            >
              {status}
            </SuiTypography>
          </SuiBox>

          {profileEditLoader ? (
            <TailSpin color="#17c1e8" height={25} width={25} />
          ) : (
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                editHandler();
              }}
            >
              <Icon className={"text-white"}>{"edit"}</Icon>
            </IconButton>
          )}
        </SuiBox>

        {profileGridItem("Family Name", familyName)}
        {profileGridItem("Ph. No", phoneNumber)}
        {profileGridItem("Email", email)}

        <SuiBox style={styles.display} mt={1}>
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Address:
          </SuiTypography>

          <Tooltip title={address ?? ""} placement="top">
            <SuiTypography variant="h9" fontWeight="medium">
              {address?.length > 30 ? `${address?.slice(0, 30)}...` : address}
            </SuiTypography>
          </Tooltip>
        </SuiBox>

        <Grid container>
          <Grid item xs={12} md={6}>
            {profileGridItem("Gender", gender)}
          </Grid>
          <Grid item xs={12} md={6}>
            {profileGridItem("Age", age)}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={7}>
            {profileGridItem("Lead from", leadFrom)}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {profileGridItem("Occupation", occupation)}
          </Grid>

          <Grid item xs={12}>
            {profileGridItem("Food preference", foodPreference)}
          </Grid>

          <Grid item xs={12}>
            {profileGridItem("Customer ID", customerId)}
          </Grid>
        </Grid>

        {profileGridItem("Referred By", referredBy)}
      </SuiBox>
    </Card>
  );
}

export default Profile;

/**----------------- styling -------------- */

const styles = {
  display: {
    display: "flex",
  },

  main: {
    display: "flex",
    justifyContent: "space-between",
  },

  container: {
    display: "flex",
    alignItems: "center",
  },
};
