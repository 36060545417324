import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Card from "@mui/material/Card";
import { Button, CircularProgress } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  userSelector,
  updateDietChartSettings,
  getDietChartSettings,
} from "store/features/authSlice";

const DietChartSettings = () => {
  const dispatch = useDispatch();
  const { uid, dietChartSettings } = useSelector(userSelector);
  const [showTimeCalorie, setShowTimeCalorie] = useState(
    dietChartSettings?.showTimeCalorie ?? true
  );
  const [showDayCalorie, setShowDayCalorie] = useState(
    dietChartSettings?.showDayCalorie ?? true
  );
  const [showCalorieColumn, setShowCalorieColumn] = useState(
    dietChartSettings?.showCalorieColumn ?? true
  );
  const [loadingSave, setLoadingSave] = useState(false);
  useEffect(() => {
    dispatch(getDietChartSettings({ uid }));
  }, [dispatch, uid]);
  useEffect(() => {
    if (dietChartSettings) {
      setShowCalorieColumn(dietChartSettings?.showCalorieColumn);
      setShowDayCalorie(dietChartSettings?.showDayCalorie);
      setShowTimeCalorie(dietChartSettings?.showTimeCalorie);
    }
  }, [dietChartSettings]);

  const handleSaveSettings = async () => {
    setLoadingSave(true);
    const payload = {
      showTimeCalorie: showTimeCalorie,
      showDayCalorie: showDayCalorie,
      showCalorieColumn: showCalorieColumn,
    };
    await dispatch(
      updateDietChartSettings({
        newDietChartSettings: payload,
        uid,
      })
    );
    await dispatch(getDietChartSettings({ uid }));
    setLoadingSave(false);
  };
  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={2}
        gap={1}
      >
        <SuiTypography mb={1} variant="h6">
          Diet Chart Settings
        </SuiTypography>
        <SuiBox>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDayCalorie}
                  onChange={() => {
                    setShowDayCalorie((pre) => !pre);
                  }}
                />
              }
              label="Show Total Calories for the day"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCalorieColumn}
                  onChange={() => {
                    setShowCalorieColumn((pre) => !pre);
                  }}
                />
              }
              label="Show Calorie column in diet chart"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTimeCalorie}
                  onChange={() => {
                    setShowTimeCalorie((pre) => !pre);
                  }}
                />
              }
              label="Show Total Calories for the time"
            />
          </FormGroup>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={handleSaveSettings}
            fullWidth
          >
            {loadingSave ? (
              <>
                Save &nbsp;
                <CircularProgress color="white" size={"1rem"} />
              </>
            ) : (
              "Save"
            )}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

export default DietChartSettings;
