import React from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";


function TagItem({ val }) {
    return (
        <SuiBox
            style={{
                background: 'rgba(23, 173, 55, 0.8)',
                borderRadius: '4px',
                padding: 3,
                margin: 3,
                textAlign: 'center',
                paddingLeft: 9,
                paddingRight: 9,
                maxWidth: 150
            }}>
                <SuiTypography 
                    variant="button" 
                    fontWeight="medium" color="white">{val}
                </SuiTypography>
        </SuiBox>
    )
}

export default TagItem