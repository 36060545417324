import React, { useState, useEffect } from "react";
import { updateClientData } from "services/customer";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { biochemicalHealthRecordsRows } from "containers/clientDetails/sub-components/common/biochemicalHealthRecordsRows";
import { biochemicalTableColumns } from "utils/tablesHeaderContent";
import BiochemicalModal from "containers/clientDetails/sub-components/modal/biochemical";
import SwipableModal from "containers/Customer/modal";
import ClientInfoCard from "containers/clientDetails/sub-components/card/clientCard";
import { headerDesc } from "utils/helper";

function BiochemicalHealthRecords({ clientId, biochemicals, deleteHandler }) {
  const [rows, setRows] = useState([]);
  const [columns] = useState([...biochemicalTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [biochemicalData, setBiochemicalData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("create");

  useEffect(() => {
    if (biochemicals.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setBiochemicalData(biochemicals);
  }, [biochemicals]);

  useEffect(() => {
    if (disableButton && biochemicalData.length > 0) {
    }
  }, [biochemicalData, disableButton]);

  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setOpenAddModal(true);
    setMode("edit");
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setOpenAddModal(true);
    setMode("view");
  }, []);

  useEffect(() => {
    setRows(
      biochemicalHealthRecordsRows(
        biochemicalData,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [biochemicalData, editHandler, viewHandler]);

  const addHandler = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "biochemical";
      await updateClientData(clientId, fieldKey, biochemicalData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  const Modal = () => (
    <SwipableModal
      open={openAddModal}
      setOpenModal={setOpenAddModal}
      mode={mode}
      setMode={setMode}
      handleCloseModal={handleCloseModal}
      {...headerDesc(mode, "Dietary, Lifestyle & Biochemical", selectedData)}
    >
      <BiochemicalModal
        id={clientId}
        mode={mode}
        persona="client"
        open={openAddModal}
        selectedItem={selectedData}
        setMainData={setBiochemicalData}
        handleCloseModal={handleCloseModal}
      />
    </SwipableModal>
  );

  return (
    <ClientInfoCard
      title={"Dietary, Lifestyle & Biochemical"}
      rows={rows}
      columns={columns}
      disableButton={disableButton}
      onSubmitHandler={submitHandler}
      loader={loader}
      onBtnClickHandler={addHandler}
      Modal={Modal}
    />
  );
}

export default BiochemicalHealthRecords;
