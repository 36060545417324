import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Tooltip from "@mui/material/Tooltip";
import { Grid, Icon } from "@mui/material";
import Leads from "pages/lead/index";
import Appointments from "pages/appointment/index";
import { useLocation } from "react-router-dom";

const LeadsAppointments = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("appointcreate") || ""; // 'q' is assumed to be the parameter name

    // Update the searchQuery state
    setSearchQuery(query);
  }, [location.search]);

  useEffect(() => {
    if (searchQuery === "1") {
      setTabIndex(1);
    }
  }, [searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Leads</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"Below is the list and details of leads"}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">help</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Appointments</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"Below is the list and details of appointments"}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">help</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
          </TabList>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Leads />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Appointments />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
        </Tabs>
      </SuiBox>
    </DashboardLayout>
  );
};

export default LeadsAppointments;
