import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomButton from "containers/CustomButton";
import { AddService, EditService } from "store/features/dashboard";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";
import GST from "components/GST/GST";
import { userSelector } from "store/features/authSlice";
import Createable from "containers/Select";

function Modal({
  open,
  setOpenModal,
  handleCloseModal,
  uid,
  selectedItem,
  setSelectedItem,
  updateServiceCountHandler,
  isFetching,
  isSuccess,
  isError,
  message,
  mode,
  editHandler,
  viewHandler,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      serviceName: null,
      description: null,
      sellingPriceWOT: null,
      gst: 0,
      checkGst: false,
      gstType: { label: "CGST & SGST", value: "CGST & SGST" },
      igst: 0,
      sgst: 0,
      cgst: 0,
      gstComment: null,
      gstNumber: null,
      hsnCode: null,
    };
  }, []);

  //default modal fields value
  const errorDefault = useMemo(() => {
    return {
      serviceName: false,
      description: false,
      sellingPriceWOT: false,
      gst: false,
    };
  }, []);

  const dispatch = useDispatch();
  const [hsnCodeOptions, setHsnCodeOptions] = useState([]);
  const { hsnCode: hsnCodeData } = useSelector(userSelector);
  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);

  useEffect(() => {
    const allHSnSac = [...hsnCodeData?.data.hsn, ...hsnCodeData?.data.sac];
    const updated = allHSnSac.map((item) => {
      return { label: `${item.hsn} - ${item.description}`, value: item.hsn };
    });
    setHsnCodeOptions(updated);
  }, [hsnCodeData]);

  useEffect(() => {
    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        gst:
          formdata.gstType.value === "IGST"
            ? formdata?.igst ?? 0
            : formdata?.sgst + formdata?.cgst ?? 0,
      });
    }
  }, [
    formdata?.gstType?.value,
    formdata.checkGst,
    formdata?.igst,
    formdata?.sgst,
    formdata?.cgst,
  ]);

  //for handling selected items
  useEffect(() => {
    setFormdata({
      ...defaultHandler,
      ...selectedItem,
    });
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    const gstNumberRegex =
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const gstNumberMatch = formdata?.gstNumber?.match(gstNumberRegex);
    if (!formdata.serviceName) {
      toast.error("Please enter a Service Name");
      setErrorMessage({
        ...errorMessage,
        serviceName: true,
      });
      return;
    }

    if (!formdata.sellingPriceWOT) {
      toast.error("Please fill Selling Price WOT field");
      setErrorMessage({
        ...errorMessage,
        sellingPriceWOT: true,
      });
      return;
    }

    if (isNaN(formdata.gst) || formdata.gst === null || formdata.gst === "") {
      toast.error("Please fill GST field");
      setErrorMessage({
        ...errorMessage,
        gst: true,
      });
      return;
    }

    if (!formdata.description) {
      toast.error("Please fill Service Description field");
      setErrorMessage({
        ...errorMessage,
        description: true,
      });
      return;
    }

    if (formdata?.checkGst) {
      if (
        !formdata?.gstNumber?.length ||
        formdata?.gstNumber === undefined ||
        formdata?.gstNumber === null
      ) {
        return toast.error("Please fill GST number!!");
      } else if (!gstNumberMatch) {
        return toast.error("Invalid GST number!!");
      }

      if (formdata?.gstType.value === "IGST") {
        if (!formdata.igst) {
          toast.error("Please fill IGST field");
          setErrorMessage({
            ...errorMessage,
            igst: true,
          });
          return;
        }
      } else {
        if (!formdata.sgst) {
          toast.error("Please fill SGST field");
          setErrorMessage({
            ...errorMessage,
            sgst: true,
          });
          return;
        }

        if (!formdata.cgst) {
          toast.error("Please fill CGST field");
          setErrorMessage({
            ...errorMessage,
            cgst: true,
          });
          return;
        }
      }
    }

    if (selectedItem) {
      dispatch(EditService({ formdata, uid }));
      return;
    }
    dispatch(AddService({ formdata, uid }));
  };

  //for calc selling price with tax
  const calcSellingPrice = () => {
    return (
      parseFloat(formdata?.sellingPriceWOT) +
      parseFloat(formdata?.sellingPriceWOT * (formdata?.gst / 100))
    ).toFixed(2);
  };

  useEffect(() => {
    if (isSuccess && open) {
      handleCloseModal();
      setTimeout(() => {
        setSelectedItem(null);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    setSelectedItem,
    updateServiceCountHandler,
    defaultHandler,
    message,
    open,
  ]);

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="65%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Service"
          subtitle={`Service details of created for ${updateFirstLetter(
            formdata?.serviceName
          )}`}
        />

        <Grid item xs={12}>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => editHandler(selectedItem)}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                if (selectedItem) {
                  setSelectedItem(null);
                }

                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Name
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="serviceName"
                  readOnly={mode === "view"}
                  error={errorMessage?.serviceName}
                  value={formdata.serviceName ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Selling Price WOT
                </SuiTypography>

                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  error={errorMessage?.sellingPriceWOT}
                  placeholder=""
                  readOnly={mode === "view"}
                  name="sellingPriceWOT"
                  value={formdata.sellingPriceWOT ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3}>
                <SuiTypography variant="h6">Selling Price WT</SuiTypography>
                <SuiTypography variant="caption">
                  {!isNaN(calcSellingPrice())
                    ? "INR " + calcSellingPrice()
                    : "N/A"}
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  HSN/SAC
                </SuiTypography>
                <Createable
                  disabled={mode === "view"}
                  options={hsnCodeOptions ?? []}
                  error={errorMessage?.hsnCode}
                  value={formdata?.hsnCode}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose HSN/SAC"
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      hsnCode: false,
                    });

                    setFormdata({
                      ...formdata,
                      hsnCode: e,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Description
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="description"
                  placeholder="description ..."
                  multiline
                  readOnly={mode === "view"}
                  rows={6}
                  error={errorMessage?.description}
                  value={formdata.description ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={mode === "view"}
                checked={formdata?.checkGst}
                onChange={() => {
                  setFormdata({
                    ...formdata,
                    checkGst: !formdata?.checkGst,
                  });
                }}
              />
            }
            label="GST"
            sx={{ marginLeft: 0, width: "fit-content" }}
          />
        </SuiBox>

        {formdata?.checkGst && (
          <SuiBox mt={3}>
            <GST
              formdata={formdata}
              setFormdata={setFormdata}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              mode={mode}
            />
          </SuiBox>
        )}

        <Divider />

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                disabled={isFetching || !open}
                fullWidth
                width={180}
                title={selectedItem ? "Edit" : "Add"}
                Service
                loader={isFetching}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
