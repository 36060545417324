import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import moment from "moment";
import ExpenseSearch from "./searchbar";
import NoData from "containers/DataNotAvailBox";
import { expenseRows } from "./rows.js";

import { TailSpin } from "react-loader-spinner";
import { excelDownloadFile } from "utils/helper";

function ExpenseTable({
  expenses,
  title,
  loader,
  addHandler,
  editHandler,
  deleteHandler,
  viewHandler,
}) {
  const [rows, setRows] = useState([]);
  const [searchVal, setsearchVal] = useState("");
  const [expenseArray, setExpenseArray] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    fromDate: null,
    toDate: null,
  });
  const columns = [
    { name: "S.No", align: "left", minWidth: "60px" },
    { name: "Date/Time (Added)", align: "left", minWidth: "150px" },
    { name: "Expense Name", align: "left", minWidth: "120px" },
    // { name: "Expense Category", align: "left", minWidth: "150px" },
    { name: "Date Of Expense", align: "left", minWidth: "120px" },
    { name: "Description", align: "left", minWidth: "150px" },
    { name: "Expense Amount", align: "left", minWidth: "120px" },
    // { name: "Tax (%)", align: "left", minWidth: "150px" },
    // { name: "Total Amount", align: "left", minWidth: "150px" },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    expenseArray.sort((a, b) =>
      moment(b.createdAt, "DD/MM/YYYY hh:mm A").diff(
        moment(a.createdAt, "DD/MM/YYYY hh:mm A")
      )
    );

    setRows(expenseRows(expenseArray, editHandler, deleteHandler, viewHandler));
  }, [expenseArray, editHandler, deleteHandler, viewHandler]);

  const resetSearchValue = () => {
    setsearchVal("");
  };

  const applyDateFilters = (item) => {
    const { fromDate, toDate } = filterQuery;

    if (fromDate && moment(fromDate).isAfter(moment(item.dateOfExpense))) {
      return false;
    }

    if (
      toDate &&
      moment(toDate).add(24, "hours").isSameOrBefore(item.dateOfExpense)
    ) {
      return false;
    }

    return true;
  };

  const filterHandler = () => {
    resetSearchValue();
    const filteredData = expenses.filter((item) => applyDateFilters(item));
    setExpenseArray(filteredData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      toDate: null,
      fromDate: null,
    });
    setExpenseArray(expenses);
  }, [expenses]);

  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setExpenseArray(expenses);
    } else {
      resetHandler();
      let filteredExpense = expenses.filter((s) => {
        let name = s?.expenseName ?? "";
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setExpenseArray((e) => filteredExpense);
    }
  }, [searchVal, expenses, resetHandler]);

  const prepareExcelData = (expenses) => {
    return expenses.map((item, index) => {
      const expenseName = item.expenseName ?? "N/A";
      const expenseCategory = item.expenseCategory ?? "N/A";
      const dateOfExpense = moment(item.dateOfExpense).format("MMMM Do YYYY");
      const description = item.description ?? "N/A";
      const expenseAmount = item.expenseAmount ?? "N/A";
      const tax = item.tax ?? "N/A";

      return [
        index + 1,
        expenseName,
        expenseCategory,
        dateOfExpense,
        description,
        expenseAmount,
        tax,
      ];
    });
  };

  const excelDownload = () => {
    const headerRow = [
      "id",
      "Expense Name",
      "Expense Category",
      "Date Of Expense",
      "Description",
      "Expense Amount",
      "Tax Amount",
    ];
    const dataRows = prepareExcelData(expenses);
    const excelData = [headerRow, ...dataRows];
    excelDownloadFile(excelData, "ExpensesSheet", "expenses.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6">{title}</SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={"List of expenses, you can follow up"}
                placement="top"
                style={{ color: "white", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Expense Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiButton
              component={Button}
              onClick={addHandler}
              color="dark"
              variant="gradient"
              width={180}
              style={{
                marginRight: 25,
                marginBottom: 10,
                height: 40,
                width: 230,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Add new expense
            </SuiButton>
          </SuiBox>
        </SuiBox>

        <ExpenseSearch
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
        />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ExpenseTable;
