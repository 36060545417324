import React, { useState, useEffect } from 'react'
import Card from "@mui/material/Card";
import ProductPurchaseStat from 'containers/clientDetails/sub-components/common/productStat';
import AdminClientInfoCard from 'containers/clientDetails/sub-components/card/adminClientCard';
import { ProductPurchasesRow } from 'containers/clientDetails/sub-components/common/productPurchasesRow';
import { productTableColumns } from 'utils/tablesHeaderContent';


function ProductPurchases({ title, productArray }) {

    const [rows, setRows] = useState([]) 
    const columns = [...productTableColumns.slice(0, -1)]

    useEffect(() => {
        setRows(ProductPurchasesRow(productArray ))
    }, [ productArray ])

    return (
        <Card>
            <AdminClientInfoCard
                title={title}
                statComponent={<ProductPurchaseStat products={productArray??[]} />}
                columns={columns}
                rows={rows} />
        </Card>
    )
}

export default ProductPurchases;