import React from 'react'
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { TailSpin } from  'react-loader-spinner'
import Button from "@mui/material/Button";


function CustomButton({ color, variant, onClick, style, width, title, loader, disabled, ...args }) {
    return (
        <SuiBox>
            <SuiButton
                {...args}
                component={Button}   
                color={color}
                variant={variant}
                onClick={onClick}
                width={width}
                disabled={disabled}
                style={style}>
                { title }
                { loader && <TailSpin color="#17c1e8" height={20} width={20} />}

            </SuiButton>
        </SuiBox>
    )
}

export default CustomButton


