import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

export const measurementRecords = (
  measurementArray,
  editHandler,
  viewHandler,
  deleteHandler
) => {
  const rows = measurementArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      Date: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.timestamp)}
        </SuiTypography>
      ),

      Weight: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.weight ? item.weight : "N/A"}
        </SuiTypography>
      ),

      // Neck: (
      //   <SuiTypography variant="button" fontWeight="medium">
      //     {item?.neck ? item.neck : "N/A"}
      //   </SuiTypography>
      // ),

      Chest: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.chest ? item.chest : "N/A"}
        </SuiTypography>
      ),

      Tummy: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.tummy ? item.tummy : "N/A"}
        </SuiTypography>
      ),

      Waist: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.waist ? item.waist : "N/A"}
        </SuiTypography>
      ),

      Hip: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.hip ? item.hip : "N/A"}
        </SuiTypography>
      ),

      Thigh: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.thigh ? item.thigh : "N/A"}
        </SuiTypography>
      ),

      Arm: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.arm ? item.arm : "N/A"}
        </SuiTypography>
      ),

      "Weight (kgs)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.weight ?? "N/A"}
        </SuiTypography>
      ),

      "Height (cm)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.height ?? "N/A"}
        </SuiTypography>
      ),

      "Consultant Comments": (
        <Tooltip title={item?.consultantionNotes} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.consultantionNotes?.length > 20
              ? `${item?.consultantionNotes?.slice(0, 20)}...`
              : item?.consultantionNotes}
          </SuiTypography>
        </Tooltip>
      ),

      "Client Comments": (
        <Tooltip title={item?.clientNotes} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.clientNotes?.length > 20
              ? `${item?.clientNotes?.slice(0, 20)}...`
              : item?.clientNotes}
          </SuiTypography>
        </Tooltip>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item, index, "measurement")}
        />
      ),
    };
  });
  return rows;
};
