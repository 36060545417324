import SuiTypography from "components/SuiTypography";
import { leadStatusStyle } from "utils/common";


export const LeadRows = (leads, onClickHandler, navigate) => {

    const rows = leads.map((item) => {
        return {

            "Client Name":  
                (<SuiTypography 
                    variant="button" 
                    fontWeight="medium"
                    style={{
                        paddingLeft: 14 
                    }}>
                    {item?.clientName}
                </SuiTypography>),
   
            "Status":  
                (<SuiTypography 
                    variant="button"
                    style={{ color: leadStatusStyle(item?.status) }}
                    fontWeight="medium">
                    {item?.status}                   
                </SuiTypography>),

            "Mobile number": 
                (<SuiTypography
                    variant="button"
                    fontWeight="medium"
                    paddingLeft={2} >
                    {item?.phoneNumber}
                </SuiTypography>)
        }
    })

    return rows;
}