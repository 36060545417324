import SuiTypography from "components/SuiTypography";
import moment from 'moment'
import { timestampConversion } from "utils/common";


export const AppointmentRows = (appointments, onClickHandler, navigate) => {

    const rows = appointments.map((item) => {
        return {

            "Client Name":  
                (<SuiTypography 
                    variant="button" 
                    fontWeight="medium"
                    style={{
                        // color: "#17C1E8",
                        cursor: 'pointer',
                        fontSize: 14,
                        paddingLeft: 14 
                    }}
                    onClick={() => {
                        // navigate(`/clients/${item.id}`);
                    }}>
                    {item?.clientName}
                </SuiTypography>),
                
            "Date/Time":
                (<SuiTypography
                    variant="button"
                    fontWeight="medium" >
                    {item?.appointmentDate?.seconds?moment(timestampConversion(item?.appointmentDate)).local().format('DD/MM/YYYY, h:mm a'):
                        moment(item?.appointmentDate).local().format('DD/MM/YYYY, h:mm a')}
                </SuiTypography>),

            "Mobile number": 
                (<SuiTypography
                    variant="button"
                    fontWeight="medium"
                    paddingLeft={2} >
                    {item?.phoneNumber}
                </SuiTypography>)
        }
    })

    return rows;
}