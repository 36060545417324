import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  addDoc,
  arrayRemove,
  writeBatch,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { sendWelcomeEmailToCustomer } from "firebaseConfig";
import { firestore, uploadFile } from "firebaseConfig";
import moment from "moment";
import { sortArrayByName } from "utils/common";
import { v4 as uuidv4 } from "uuid";

//for getting selected client details
export const getSelectedClient = createAsyncThunk(
  "Client/getSelectedClient",
  async ({ id }, thunkAPI) => {
    try {
      let data = [];

      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      if (snapshot.exists()) {
        data = snapshot.data();

        let updatedAt;
        let createdAt;
        if (snapshot.data().createdAt) {
          createdAt = snapshot.data().createdAt;
          createdAt =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          createdAt = moment.utc(createdAt);
        }

        if (snapshot.data().updatedAt) {
          updatedAt = snapshot.data().updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }
        return { ...data, updatedAt, createdAt, id: id };
      } else {
        return thunkAPI.rejectWithValue({ message: "Client not found!!!" });
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getClients = createAsyncThunk(
  "Client/getClients",
  async ({ uid }, thunkAPI) => {
    try {
      let data = [];
      const q = query(
        collection(firestore, "client"),
        where("adminId", "==", uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let timestamp = null;
        let updatedAt = null;
        if (doc.data().createdAt) {
          let createdAt = doc.data()?.createdAt;
          timestamp =
            createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
          timestamp = moment.utc(timestamp);
        }

        if (doc.data().updatedAt) {
          updatedAt = doc.data()?.updatedAt;
          updatedAt =
            updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
          updatedAt = moment.utc(updatedAt);
        }

        let client = doc.data();
        delete client?.dietary;
        delete client?.physical;
        delete client?.measurement;
        delete client?.vital;
        delete client?.payment;
        delete client?.biochemical;
        delete client?.dietPlan;
        data.push({
          ...client,
          createdAt: timestamp,
          id: doc.id,
          updatedAt,
        });
      });
      sortArrayByName(data);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UpdateBasicInfoHandler = createAsyncThunk(
  "Client/UpdateBasicInfoHandler",
  async ({ formdata, uid, stepVal }, thunkAPI) => {
    const step = [...stepVal];
    step[0] = 1;

    try {
      let customerCount = 0;
      if (formdata.customerId === undefined) {
        const qv1 = query(
          collection(firestore, "client"),
          where("adminId", "==", uid)
        );
        const querySnapshotv1 = await getDocs(qv1);
        querySnapshotv1.forEach((doc) => {
          let cusId = doc.data().customerId;
          if (cusId) {
            if (cusId > customerCount) {
              customerCount = cusId;
            }
          }
        });
      }

      let personal = {
        name: formdata.clientName,
        customerId: customerCount + 1,
        phoneNumber: formdata.phoneNumber,
        countryCode: formdata?.countryCode,
        email: formdata.email,
        services: [],
        createdAt: new Date(),
        adminId: uid,
        step: step,
      };

      const q = query(
        collection(firestore, "client"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", uid)
      );
      const querySnapshot = await getDocs(q);
      const p = query(
        collection(firestore, "client"),
        where("name", "==", formdata.clientName),
        where("adminId", "==", uid)
      );
      const querySnapshot1 = await getDocs(p);
      const r = query(
        collection(firestore, "client"),
        where("email", "==", formdata.email),
        where("adminId", "==", uid)
      );
      const querySnapshot2 = await getDocs(r);
      if (!querySnapshot.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Client with this Phone Number already exists, choose different Phone Number",
        });
      }
      if (!querySnapshot1.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Client with this Name already exists, choose different Name",
        });
      }
      if (!querySnapshot2.empty) {
        return thunkAPI.rejectWithValue({
          message:
            "Client with this Email already exists, choose different Email",
        });
      }

      const clientRef = await addDoc(collection(firestore, "client"), {
        ...personal,
        status: "Active",
      });

      await setDoc(doc(firestore, "clientdetail", clientRef.id), {});

      return { ...personal, id: clientRef.id, status: "Active" };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const UpdatePersonalInfoHandler = createAsyncThunk(
  "Client/UpdatePersonalInfoHandler",
  async ({ formdata, stepVal }, thunkAPI) => {
    try {
      const step = [...stepVal];
      step[1] = 1;
      let personal = {
        familyName: formdata.familyName.value,
        address: formdata.address,
        gender: formdata.gender.value,
        dob: formdata.dob,
        anniversary: formdata?.anniversary ?? "",
        occupation: formdata.occupation,
        age: formdata.age,
        leadFrom: formdata.leadFrom.value,
        preference: formdata.preference.value,
        referredBy: formdata.referredBy,
      };

      if (formdata?.clientId) {
        const ref = doc(firestore, "client", formdata.clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          await updateDoc(doc(firestore, "client", formdata.clientId), {
            ...personal,
            step: step,
          });
          return { ...personal, id: formdata.clientId };
        } else {
          return thunkAPI.rejectWithValue({ message: "Client not found" });
        }
      }
      return thunkAPI.rejectWithValue({ message: "Something went wrong!!" });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const UpdatePlanInfoHandler = createAsyncThunk(
  "Client/UpdatePlanInfoHandler",
  async ({ formdata, stepVal }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const { logo, name } = state.user;
      const step = [...stepVal];
      step[2] = 1;
      let service = {
        serviceId: formdata.package.value,
        serviceName: formdata.package.label,
        totalAmount: parseFloat(formdata.packageAmount),
        purchasedAt: new Date(),
        startDate: formdata.startDate,
        completionDate: formdata.completionDate,
        serviceInstance: formdata.serviceInstance.value,
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        paymentMode: formdata.paymentMode.value,
        complementaryService: formdata.complementaryService,
        assignDietitian: formdata?.assignDietitian,
        packageAmount: formdata?.packageAmount,
        hsnCode: formdata?.hsnCode,
        balanceAmt:
          parseFloat(formdata.totalAmount) - parseFloat(formdata.amountPaid),
      };

      if (formdata?.checkGst) {
        service.checkGst = formdata.checkGst;
        service.gstType = formdata.gstType;
        service.gstNumber = formdata.gstNumber;
        service.gstTreatment = formdata.gstTreatment;
        service.gstComment = formdata.gstComment;

        // Add GST type specific properties
        if (formdata.gstType?.value === "IGST") {
          service.igst = formdata.igst;
        } else {
          service.sgst = formdata.sgst;
          service.cgst = formdata.cgst;
        }
      } else {
        service.checkGst = false;
        service.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
        service.igst = 0;
        service.sgst = 0;
        service.cgst = 0;
        service.gstComment = null;
        service.gstNumber = null;
      }

      if (formdata?.clientId) {
        const ref = doc(firestore, "client", formdata.clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          const existingData = snapshot.data();
          const docId = uuidv4();

          await updateDoc(doc(firestore, "client", formdata.clientId), {
            step: step,
            notes: formdata.notes ?? "",
            services: arrayUnion({
              id: docId,
              ...service,
            }),
          });

          await sendWelcomeEmailToCustomer({
            userEmail: existingData.email,
            customerName: existingData.name,
            name: name,
            logo: logo,
          })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
          return {
            service: { ...service, id: docId },
            id: formdata.clientId,
            notes: formdata.notes ?? "",
          };
        } else {
          return thunkAPI.rejectWithValue({ message: "Client not found" });
        }
      }
      return thunkAPI.rejectWithValue({ message: "Something went wrong!!" });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Selling service to client
export const SellService = createAsyncThunk(
  "Client/SellService",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      //for customer unique id
      let customerCount = 0;
      if (formdata.customerId === undefined) {
        const qv1 = query(
          collection(firestore, "client"),
          where("adminId", "==", uid)
        );
        const querySnapshotv1 = await getDocs(qv1);
        querySnapshotv1.forEach((doc) => {
          let cusId = doc.data().customerId;
          if (cusId) {
            if (cusId > customerCount) {
              customerCount = cusId;
            }
          }
        });
      }

      let personal = {
        name: formdata.clientName,
        customerId: formdata.customerId ?? customerCount + 1,
        phoneNumber: formdata.phoneNumber,
        familyName: formdata.familyName.value,
        email: formdata.email,
        createdAt: new Date(),
        adminId: uid,
        address: formdata.address,
        gender: formdata.gender.value,
        dob: formdata.dob,
        anniversary: formdata?.anniversary ?? "",
        occupation: formdata.occupation,
        age: formdata.age,
        leadFrom: formdata.leadFrom.value,
        preference: formdata.preference.value,
        referredBy: formdata.referredBy,
      };

      let service = {
        serviceId: formdata.package.value,
        serviceName: formdata.package.label,
        totalAmount: parseFloat(formdata.totalAmount),
        purchasedAt: new Date(),
        startDate: formdata.startDate,
        completionDate: formdata.completionDate,
        serviceInstance: formdata.serviceInstance.value,
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        paymentMode: formdata.paymentMode.value,
        complementaryService: formdata.complementaryService,
      };

      if (formdata?.clientId) {
        const ref = doc(firestore, "client", formdata.clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          const docId = uuidv4();

          await updateDoc(doc(firestore, "client", formdata.clientId), {
            ...personal,
            services: arrayUnion({
              id: docId,
              ...service,
            }),
          });
          return {
            ...personal,
            service: { ...service, id: docId },
            id: formdata.clientId,
          };
        } else {
          return thunkAPI.rejectWithValue({ message: "Client not found" });
        }
      } else {
        const q = query(
          collection(firestore, "client"),
          where("phoneNumber", "==", formdata.phoneNumber),
          where("adminId", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        const p = query(
          collection(firestore, "client"),
          where("name", "==", formdata.clientName),
          where("adminId", "==", uid)
        );
        const querySnapshot1 = await getDocs(p);
        const r = query(
          collection(firestore, "client"),
          where("email", "==", formdata.email),
          where("adminId", "==", uid)
        );
        const querySnapshot2 = await getDocs(r);
        if (!querySnapshot.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Phone Number already exists, choose different Phone Number",
          });
        }
        if (!querySnapshot1.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Name already exists, choose different Name",
          });
        }
        if (!querySnapshot2.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Email already exists, choose different Email",
          });
        }

        const docId = uuidv4();

        const clientRef = await addDoc(collection(firestore, "client"), {
          ...personal,
          status: "Active",
          services: [
            {
              id: docId,
              ...service,
            },
          ],
        });

        return {
          ...personal,
          services: [{ ...service, id: docId }],
          id: clientRef.id,
          status: "Active",
          new: true,
        };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Selling consultation to client
export const SellConsultation = createAsyncThunk(
  "Client/SellConsultation",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      //for customer unique id
      let customerCount = 0;
      if (formdata.customerId === undefined) {
        const qv1 = query(
          collection(firestore, "client"),
          where("adminId", "==", uid)
        );
        const querySnapshotv1 = await getDocs(qv1);
        querySnapshotv1.forEach((doc) => {
          let cusId = doc.data().customerId;
          if (cusId) {
            if (cusId > customerCount) {
              customerCount = cusId;
            }
          }
        });
      }

      let personal = {
        name: formdata.clientName,
        customerId: formdata.customerId ?? customerCount + 1,
        phoneNumber: formdata.phoneNumber,
        familyName: formdata.familyName.value,
        email: formdata.email ? formdata.email : "N/A",
        createdAt: new Date(),
        adminId: uid,
        address: formdata.address ? formdata.address : "N/A",
        gender: formdata.gender.value,
        dob: formdata.dob,
        anniversary: formdata?.anniversary ?? "",
        occupation: formdata.occupation ? formdata.occupation : "N/A",
        age: formdata.age,
        leadFrom: formdata.leadFrom.value ? formdata.leadFrom.value : "N/A",
        preference: formdata.preference.value,
        referredBy: formdata.referredBy ? formdata.referredBy : "N/A",
      };

      let service = {
        serviceId: formdata.package.value,
        serviceName: formdata.package.label,
        totalAmount: parseFloat(formdata.package.totalAmount),
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        paymentMode: formdata.paymentMode.value,
        createdAt: new Date(),
        assignDietitian: formdata.assignDietitian,
        packageAmount: parseFloat(formdata?.packageAmount),
        hsnCode: formdata?.hsnCode ? formdata?.hsnCode : "N/A",
        balanceAmt: parseFloat(formdata?.balance),
      };

      if (formdata?.checkGst) {
        service.checkGst = formdata.checkGst;
        service.gstType = formdata.gstType;
        service.gstNumber = formdata.gstNumber;
        service.gstTreatment = formdata.gstTreatment;
        service.gstComment = formdata.gstComment;

        // Add GST type specific properties
        if (formdata.gstType?.value === "IGST") {
          service.igst = formdata.igst;
        } else {
          service.sgst = formdata.sgst;
          service.cgst = formdata.cgst;
        }
      } else {
        service.checkGst = false;
        service.gstType = { label: "CGST & SGST", value: "CGST & SGST" };
        service.igst = 0;
        service.sgst = 0;
        service.cgst = 0;
        service.gstComment = null;
        service.gstNumber = null;
      }

      if (formdata?.clientId) {
        const ref = doc(firestore, "client", formdata.clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          const docId = uuidv4();

          await updateDoc(doc(firestore, "client", formdata.clientId), {
            ...personal,
            consultation: arrayUnion({
              id: docId,
              ...service,
            }),
          });
          return {
            ...personal,
            service: { ...service, id: docId },
            id: formdata.clientId,
          };
        } else {
          return thunkAPI.rejectWithValue({ message: "Client not found" });
        }
      } else {
        const q = query(
          collection(firestore, "client"),
          where("phoneNumber", "==", formdata.phoneNumber),
          where("adminId", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        const p = query(
          collection(firestore, "client"),
          where("name", "==", formdata.clientName),
          where("adminId", "==", uid)
        );
        const querySnapshot1 = await getDocs(p);
        const r = query(
          collection(firestore, "client"),
          where("email", "==", formdata.email),
          where("adminId", "==", uid)
        );
        const querySnapshot2 = await getDocs(r);
        if (!querySnapshot.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Phone Number already exists, choose different Phone Number",
          });
        }
        if (!querySnapshot1.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Name already exists, choose different Name",
          });
        }
        if (!querySnapshot2.empty && formdata.email !== "N/A") {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Email already exists, choose different Email",
          });
        }

        let id = uuidv4();
        const clientRef = await addDoc(collection(firestore, "client"), {
          ...personal,
          status: "Active",
          consultation: [
            {
              id,
              ...service,
            },
          ],
        });

        return {
          ...personal,
          services: [{ ...service, id }],
          id: clientRef.id,
          status: "Active",
          new: true,
        };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit product
export const EditClient = createAsyncThunk(
  "Client/EditClient",
  async ({ formdata }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "client"),
        where("phoneNumber", "==", formdata.phoneNumber),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot = await getDocs(q);
      let checkName = false;

      const r = query(
        collection(firestore, "client"),
        where("email", "==", formdata.email),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshot2 = await getDocs(r);
      let checkName2 = false;

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const existingData = doc.data();
          if (
            existingData.customerId !== formdata.customerId &&
            existingData.phoneNumber === formdata.phoneNumber
          ) {
            checkName = true;
          }
        });
        if (checkName === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Phone Number already exists, choose different Phone Number",
          });
        }
      }

      if (!querySnapshot2.empty) {
        querySnapshot2.forEach((doc) => {
          const existingData = doc.data();

          if (
            existingData.customerId !== formdata.customerId &&
            existingData.email === formdata.email
          ) {
            checkName2 = true;
          }
        });
        if (checkName2 === true) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this Email already exists, choose different Email",
          });
        }
      }

      const ref = doc(firestore, "client", formdata.id);
      const clientSnapshot = await getDoc(ref);

      let clientExistServices = [];

      if (clientSnapshot.exists()) {
        const clientData = clientSnapshot.data();
        clientExistServices?.push(...clientData?.services);
      }

      //for customer unique id
      let customerCount = 0;
      if (formdata.customerId === undefined) {
        const qv1 = query(
          collection(firestore, "client"),
          where("adminId", "==", formdata.adminId)
        );
        const querySnapshotv1 = await getDocs(qv1);
        querySnapshotv1.forEach((doc) => {
          let cusId = doc.data().customerId;
          if (cusId !== undefined) {
            if (parseInt(cusId) > customerCount) {
              customerCount = cusId;
            }
          }
        });
      }

      const updatedService = clientExistServices.map((service) => {
        if (service?.id === formdata?.serviceNo) {
          return { ...service, assignDietitian: formdata?.assignDietitian };
        } else {
          return service;
        }
      });

      const updatedData = {
        name: formdata.clientName,
        customerId: formdata.customerId ?? customerCount + 1,
        phoneNumber: formdata.phoneNumber,
        email: formdata.email,
        updatedAt: new Date(),
        address: formdata.address,
        gender: formdata.gender.value,
        dob: formdata.dob,
        anniversary: formdata.anniversary ?? "",
        occupation: formdata.occupation,
        age: formdata.age,
        leadFrom: formdata.leadFrom.value,
        preference: formdata.preference.value,
        referredBy: formdata.referredBy,
        status: formdata.status.value,
        notes: formdata.notes ?? "",
        services: updatedService,
        countryCode: formdata.countryCode,
      };

      if (formdata.familyName && formdata.familyName.value) {
        await updateDoc(ref, {
          ...updatedData,
          familyName: formdata.familyName.value,
        });

        return {
          ...updatedData,
          familyName: formdata.familyName.value,
          id: formdata.id,
        };
      } else {
        await updateDoc(ref, {
          ...updatedData,
        });

        return { ...updatedData, id: formdata.id };
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//delete doc
export const deleteDocument = createAsyncThunk(
  "Client/deleteDocument",
  async ({ persona, id, clientId, selectedItemIndex }, thunkAPI) => {
    try {
      let data, updatedArray;

      const assessmentsRef = doc(firestore, "clientdetail", clientId);
      const snapshot = await getDoc(assessmentsRef);

      if (persona === "service") {
        const ref = doc(firestore, "client", clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          data = snapshot.data();
          updatedArray = data.services;
          updatedArray = updatedArray.filter((item) => {
            if (item.id !== id) {
              return item;
            }
            return null;
          });
          await updateDoc(doc(firestore, "client", clientId), {
            services: updatedArray,
          });
        }
      }

      if (persona === "product") {
        const ref = doc(firestore, "client", clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          data = snapshot.data();
          updatedArray = data.products;
          updatedArray = updatedArray.filter((item) => {
            if (item.id !== id) {
              return item;
            }
            return null;
          });
        }

        updateDoc(doc(firestore, "client", clientId), {
          products: updatedArray,
        });
      }

      if (persona === "consultation") {
        const ref = doc(firestore, "client", clientId);

        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
          data = snapshot.data();
          updatedArray = data.consultation;
          updatedArray = updatedArray.filter((item) => {
            if (item.id !== id) {
              return item;
            }
            return null;
          });
        }
        updateDoc(doc(firestore, "client", clientId), {
          consultation: updatedArray,
        });
      }

      if (persona === "deleteBiochem") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.biochemical?.filter((item, index) => {
            if (item.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            biochemical: updatedArray,
          });
        }
      }

      if (persona === "physicalActivity") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.physical?.filter((item, index) => {
            if (item?.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            physical: updatedArray,
          });
        }
      }

      if (persona === "measurement") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.measurement?.filter((item, index) => {
            if (item.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            measurement: updatedArray,
          });
        }
      }

      if (persona === "vital") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.vital?.filter((item, index) => {
            if (item.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            vital: updatedArray,
          });
        }
      }

      if (persona === "dietRecall") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.dietRecall?.filter((item, index) => {
            if (item.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            dietRecall: updatedArray,
          });
        }
      }

      if (persona === "dailyIntake") {
        if (snapshot.exists()) {
          data = snapshot.data();
          const updated = data?.dailyIntake?.filter((item, index) => {
            if (item.id === id) {
              return false;
            } else {
              return item;
            }
          });

          updatedArray = updated;

          updateDoc(assessmentsRef, {
            ...data,
            dailyIntake: updatedArray,
          });
        }
      }

      if (persona === "imageNowVsEarlier") {
        if (snapshot.exists()) {
          data = snapshot.data();

          const storage = getStorage();

          const imageRef = ref(storage, id);

          deleteObject(imageRef).then(() => {
            const updated = data?.imageNowVsEarlier?.filter((item, index) => {
              if (item.image === id) {
                return false;
              } else {
                return item;
              }
            });

            updatedArray = updated;

            updateDoc(assessmentsRef, {
              ...data,
              imageNowVsEarlier: updatedArray,
            });
          });
        }
      }
      return { clientId, updatedArray, persona };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add dietary of client
export const AddDietary = createAsyncThunk(
  "Client/AddDietary",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      let id_;
      if (snapshot.exists()) {
        if (data.dietary) {
          id_ = data.dietary.length + 1;
          await updateDoc(ref, {
            dietary: arrayUnion({
              ...formdata,
              id: id_,
              createdAt: new Date(),
            }),
          });
        } else {
          id_ = 1;
          await updateDoc(ref, {
            dietary: [{ ...formdata, id: id_, createdAt: new Date() }],
          });
        }
      }

      return { id: id, data: { ...formdata, id: id_, createdAt: new Date() } };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit dietary of client
export const EditDietary = createAsyncThunk(
  "Client/EditDietary",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let dietaryArray = data?.dietary?.map((item) => {
        if (formdata.id === item.id) {
          return { ...formdata, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        dietary: dietaryArray,
      });
      return { data: dietaryArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Remove dietary of client
export const RemoveDietary = createAsyncThunk(
  "Client/RemoveDietary",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      await updateDoc(ref, {
        dietary: arrayRemove(formdata),
      });

      return { formdata };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add physical of client
export const AddPhysical = createAsyncThunk(
  "Client/AddPhysical",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let physicalData = {
        drinkAlcohol: formdata.drinkAlcohol.value,
        chewTobacco: formdata.chewTobacco.value,
        smokeCigarettes: formdata.smokeCigarettes.value,
        lifestyle: formdata.lifestyle.value,

        physicalActivities: formdata.physicalActivities,

        awakeLateNight: formdata.awakeLateNight,
        snackAtLateNight: formdata.snackAtLateNight,
        gymRoutine: formdata.gymRoutine,
        hourOfSleep: formdata.hourOfSleep,
        createdAt: new Date(),
        timestamp: formdata.timestamp,
      };

      const snapshot = await getDoc(ref);
      let id_;
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data.physical) {
          id_ = data.physical.length + 1;
          await updateDoc(ref, {
            physical: arrayUnion({ ...physicalData, id: id_ }),
          });
        } else {
          id_ = 1;
          await updateDoc(ref, {
            physical: [{ ...physicalData, id: 1 }],
          });
        }
      }

      return { id: id, data: { ...physicalData, id: id_ } };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit dietary of client
export const EditPhysical = createAsyncThunk(
  "Client/EditPhysical",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let physicalData = {
        drinkAlcohol: formdata.drinkAlcohol.value,
        chewTobacco: formdata.chewTobacco.value,
        smokeCigarettes: formdata.smokeCigarettes.value,
        lifestyle: formdata.lifestyle.value,

        physicalActivities: formdata.physicalActivities,

        awakeLateNight: formdata.awakeLateNight,
        snackAtLateNight: formdata.snackAtLateNight,
        gymRoutine: formdata.gymRoutine,
        hourOfSleep: formdata.hourOfSleep,
        timestamp: formdata.timestamp,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let physicalArray = data?.physical?.map((item) => {
        if (formdata.id === item.id) {
          return { ...physicalData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        physical: physicalArray,
      });

      return { data: physicalArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add biochemical of client
export const AddBiochemical = createAsyncThunk(
  "Client/AddBiochemical",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let biochemicalData = {
        ...formdata,
        rashesOnSkin: formdata.rashesOnSkin.value,
        obesityInFamily: formdata.obesityInFamily.value,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      let id_;
      if (snapshot.exists()) {
        if (data.biochemical) {
          id_ = data.biochemical.length + 1;
          await updateDoc(ref, {
            biochemical: arrayUnion({ ...biochemicalData, id: id_ }),
          });
        } else {
          id_ = 1;
          await updateDoc(ref, {
            biochemical: [{ ...biochemicalData, id: 1 }],
          });
        }
      }

      return { id: id, data: { ...biochemicalData, id: id_ } };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit biochemical of client
export const EditBiochemical = createAsyncThunk(
  "Client/EditBiochemical",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let biochemicalData = {
        ...formdata,
        rashesOnSkin: formdata.rashesOnSkin.value,
        obesityInFamily: formdata.obesityInFamily.value,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let biochemicalArray = data?.biochemical?.map((item) => {
        if (formdata.id === item.id) {
          return { ...biochemicalData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        biochemical: biochemicalArray,
      });

      return { data: biochemicalArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add measurement of client
export const AddMeasurement = createAsyncThunk(
  "Client/AddMeasurement",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let measurementData = {
        ...formdata,
        createdAt: new Date(),
      };
      let id_;

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data.measurement) {
          id_ = data.measurement.length + 1;
          await updateDoc(ref, {
            measurement: arrayUnion({ ...measurementData, id: id_ }),
          });
        } else {
          id_ = 1;
          await updateDoc(ref, {
            measurement: [{ ...measurementData, id: 1 }],
          });
        }
      }

      return { id: id, data: { ...measurementData, id: id_ } };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit measurement of client
export const EditMeasurement = createAsyncThunk(
  "Client/EditMeasurement",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let measurementData = {
        ...formdata,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let measurementArray = data?.measurement?.map((item) => {
        if (formdata.id === item.id) {
          return { ...measurementData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        measurement: measurementArray,
      });

      return { data: measurementArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add vital of client
export const AddVital = createAsyncThunk(
  "Client/AddVital",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let vitalData = {
        ...formdata,
        createdAt: new Date(),
      };

      let id_;

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data.vital) {
          let id_ = data.vital.length + 1;
          await updateDoc(ref, {
            vital: arrayUnion({ ...vitalData, id: id_ }),
          });
        } else {
          id_ = 1;
          await updateDoc(ref, {
            vital: [{ ...vitalData, id: 1 }],
          });
        }
      }

      return { id: id, data: { ...vitalData, id: id_ } };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit vital of client
export const EditVital = createAsyncThunk(
  "Client/EditVital",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let vitalData = {
        ...formdata,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let vitalArray = data?.vital?.map((item) => {
        if (formdata.id === item.id) {
          return { ...vitalData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        vital: vitalArray,
      });

      return { data: vitalArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add payment of client
export const AddPayment = createAsyncThunk(
  "Client/AddPayment",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let paymentData = {
        serviceId: formdata.service.value,
        serviceName: formdata.service.label,
        totalAmount: parseFloat(formdata.totalAmount),
        purchasedAt: new Date(),
        startDate: formdata.startDate,
        completionDate: formdata.completionDate,
        serviceInstance: formdata.serviceInstance.value,
        paymentMode: formdata.paymentMode.value,
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        complementaryService: formdata.complementaryService,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data?.services && data?.services?.length > 0) {
          await updateDoc(ref, {
            services: arrayUnion({
              ...paymentData,
              id: data.services.length + 1,
            }),
          });

          return { id: id, data: { ...paymentData } };
        } else {
          await updateDoc(ref, {
            services: [{ ...paymentData, id: 1 }],
          });

          return { id: id, data: { ...paymentData }, new: true };
        }
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit payment of client
export const EditPayment = createAsyncThunk(
  "Client/EditPayment",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      let paymentData = {
        serviceId: formdata.service.value,
        serviceName: formdata.service.label,
        totalAmount: parseFloat(formdata.totalAmount),
        purchasedAt: new Date(),
        startDate: formdata.startDate,
        completionDate: formdata.completionDate,
        paymentMode: formdata.paymentMode.value,
        serviceInstance: formdata.serviceInstance.value,
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        complementaryService: formdata.complementaryService,
      };
      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let serviceArray = data?.services?.map((item) => {
        if (formdata.id === item.id) {
          return { ...item, ...paymentData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        services: serviceArray,
      });

      return { data: serviceArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add diet recall of client
export const AddDietRecall = createAsyncThunk(
  "Client/AddDietRecall",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data?.dietRecall && data?.dietRecall?.length > 0) {
          await updateDoc(ref, {
            dietRecall: arrayUnion({
              ...formdata,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
              id: data.dietRecall.length + 1,
            }),
          });

          return {
            id: id,
            data: {
              ...formdata,
              createdAt: new Date(),
              id: data.dietRecall.length + 1,
            },
          };
        } else {
          await updateDoc(ref, {
            dietRecall: [
              {
                ...formdata,
                id: 1,
                frequencyOfEating: formdata.frequencyOfEating.value,
                dietType: formdata.dietType.value,
                createdAt: new Date(),
              },
            ],
          });

          return {
            id: id,
            data: {
              ...formdata,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
              id: 1,
            },
          };
        }
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit diet recall of client
export const EditDietRecall = createAsyncThunk(
  "Client/EditDietRecall",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let dietRecallArray = data?.dietRecall?.map((item) => {
        if (formdata.id === item.id) {
          return {
            ...item,
            ...formdata,
            frequencyOfEating: formdata.frequencyOfEating.value,
            dietType: formdata.dietType.value,
            updatedAt: new Date(),
          };
        }
        return item;
      });

      await updateDoc(ref, {
        dietRecall: dietRecallArray,
      });

      return { data: dietRecallArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Add daily intake of client
export const AddDailyIntake = createAsyncThunk(
  "Client/AddDailyIntake",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();
      if (snapshot.exists()) {
        if (data?.dailyIntake && data?.dailyIntake?.length > 0) {
          await updateDoc(ref, {
            dailyIntake: arrayUnion({
              ...formdata,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
              id: data.dietRecall.length + 1,
            }),
          });

          return {
            id: id,
            data: {
              ...formdata,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
              id: data.dietRecall.length + 1,
            },
          };
        } else {
          await updateDoc(ref, {
            dailyIntake: [
              {
                ...formdata,
                id: 1,
                frequencyOfEating: formdata.frequencyOfEating.value,
                dietType: formdata.dietType.value,
                createdAt: new Date(),
              },
            ],
          });

          return {
            id: id,
            data: {
              ...formdata,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
              id: 1,
            },
          };
        }
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Edit diet recall of client
export const EditDailyIntake = createAsyncThunk(
  "Client/EditDailyIntake",
  async ({ formdata, id }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", id);

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let dailyIntakeArray = data?.dailyIntake?.map((item) => {
        if (formdata.id === item.id) {
          return {
            ...item,
            ...formdata,
            frequencyOfEating: formdata.frequencyOfEating.value,
            dietType: formdata.dietType.value,
            updatedAt: new Date(),
          };
        }
        return item;
      });

      await updateDoc(ref, {
        dailyIntake: dailyIntakeArray,
      });

      return { data: dailyIntakeArray, id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//edit sold product
export const EditSoldProduct = createAsyncThunk(
  "Dashboard/EditSoldProduct",
  async ({ formdata, clientId }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", clientId);
      let productData = {
        id: formdata?.id,
        sellingPriceWOT: parseFloat(formdata.sellingPriceWOT),
        gst: parseFloat(formdata.gst),
        quantity: parseInt(formdata.qty),
        mrp: parseFloat(formdata.mrp),
        amountPaid: parseFloat(formdata.amountPaid),
        discount: parseFloat(formdata.discount ?? 0),
        purchasedAt: formdata.timestamp,
      };

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      let productArray = data?.products?.map((item) => {
        if (formdata?.productId === item.productId) {
          return { ...item, ...productData, updatedAt: new Date() };
        }
        return item;
      });

      await updateDoc(ref, {
        products: productArray,
      });

      return { data: productArray, id: clientId };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//Selling product
export const SellProduct = createAsyncThunk(
  "Client/SellProduct",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      // Get a new write batch
      const batch = writeBatch(firestore);
      let productObj;

      const productId = uuidv4();

      if (formdata?.clientId) {
        const ref = doc(firestore, "client", formdata.clientId);
        await getDoc(ref);

        productObj = {
          id: productId,
          productId: formdata.id,
          sellingPriceWOT: parseFloat(formdata.sellingPriceWOT),
          discount: parseFloat(formdata.discount),
          gst: parseFloat(formdata.gst),
          quantity: parseInt(formdata.qty),
          mrp: parseFloat(formdata.mrp),
          brand: formdata.brand,
          amountPaid: formdata.amountPaid,
          productName: formdata.productName,
          purchasedAt: formdata.timestamp,
          unit: formdata?.unit?.value,
          hsnCode: formdata?.hsnCode,
          balance: formdata.balance,
        };

        if (formdata?.checkGst) {
          productObj = {
            ...productObj,
            checkGst: formdata?.checkGst,
            gstType: formdata?.gstType,
            gstNumber: formdata?.gstNumber,
            gstTreatment: formdata?.gstTreatment,
            gstComment: formdata?.gstComment,
          };

          if (formdata?.gstType?.value === "IGST") {
            productObj = { ...productObj, igst: formdata?.igst };
          } else {
            productObj = {
              ...productObj,
              sgst: formdata?.sgst,
              cgst: formdata?.cgst,
            };
          }
        }

        //for customer unique id
        if (formdata.customerId === undefined) {
          const qv1 = query(
            collection(firestore, "client"),
            where("adminId", "==", uid)
          );
          const querySnapshotv1 = await getDocs(qv1);
          let customerCount = 0;
          querySnapshotv1.forEach((doc) => {
            let cusId = doc.data().customerId;
            if (cusId) {
              if (cusId > customerCount) {
                customerCount = cusId;
              }
            }
          });

          batch.update(doc(firestore, "client", formdata.clientId), {
            customerId: customerCount + 1,
            products: arrayUnion(productObj),
          });
        } else {
          batch.update(doc(firestore, "client", formdata.clientId), {
            products: arrayUnion(productObj),
          });
        }

        const productRef = doc(firestore, "product", formdata.id);

        batch.update(productRef, {
          availableQuantity: formdata.availableQuantity - formdata.qty,
        });

        // Commit the batch
        await batch.commit();
      } else {
        const q = query(
          collection(firestore, "client"),
          where("name", "==", formdata.clientName),
          where("adminId", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          return thunkAPI.rejectWithValue({
            message:
              "Client with this name already exists, choose different name",
          });
        }

        //for customer unique id
        const qv1 = query(
          collection(firestore, "client"),
          where("adminId", "==", uid)
        );
        const querySnapshotv1 = await getDocs(qv1);

        let customerCount = 0;
        querySnapshotv1.forEach((doc) => {
          let cusId = doc.data().customerId;
          if (cusId) {
            if (cusId > customerCount) {
              customerCount = cusId;
            }
          }
        });

        productObj = {
          id: 1,
          productId: formdata.id,
          sellingPriceWOT: parseFloat(formdata.sellingPriceWOT),
          discount: parseFloat(formdata.discount),
          gst: parseFloat(formdata.gst),
          quantity: parseInt(formdata.qty),
          mrp: parseFloat(formdata.mrp),
          productName: formdata.productName,
          purchasedAt: formdata.timestamp,
          balance: formdata.balance,
        };

        await addDoc(collection(firestore, "client"), {
          name: formdata.clientName,
          customerId: customerCount + 1,
          phoneNumber: formdata.phoneNumber,
          email: formdata.email,
          createdAt: new Date(),
          adminId: uid,
          status: "Active",
          products: [productObj],
        });

        const ref = doc(firestore, "product", formdata.id);

        await updateDoc(ref, {
          availableQuantity: formdata.availableQuantity - formdata.qty,
        });
      }

      return { id: formdata.clientId, productObj };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//edit sold product
export const uploadImages = createAsyncThunk(
  "Client/uploadImages",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", uid);

      if (!formdata.image) {
        return thunkAPI.rejectWithValue({ message: "Please select an image!" });
      }

      const image_ = await uploadFile(formdata.image, "imageNowVsEarlier");

      let data_ = {
        image: image_,
        date: formdata.date,
      };

      let imageArray = [];

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      if (!data.imageNowVsEarlier || data.imageNowVsEarlier.length === 0) {
        data_ = {
          imageId: 1,
          ...data_,
          createdAt: new Date(),
        };

        imageArray = [data_];
      } else {
        data_ = {
          imageId: data.imageNowVsEarlier.length + 1,
          ...data_,
          createdAt: new Date(),
        };

        imageArray = [data_, ...data?.imageNowVsEarlier];
      }

      await updateDoc(ref, {
        imageNowVsEarlier: imageArray,
      });

      return { data: imageArray, id: uid };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//for uploading custom diet pdf
export const uploadCustomDietChart = createAsyncThunk(
  "Client/uploadCustomDietChart",
  async ({ formdata, uid }, thunkAPI) => {
    try {
      const ref = doc(firestore, "client", uid);

      if (!formdata.dietPdf) {
        return thunkAPI.rejectWithValue({ message: "Please select an pdf!" });
      }

      const dietPdf_ = await uploadFile(formdata.dietPdf, "customDietPdf");

      let data_ = {
        dietPdf: dietPdf_,
        date: formdata.date,
        description: formdata.description,
      };

      let pdfArray = [];

      const snapshot = await getDoc(ref);
      let data = snapshot.data();

      if (!data.customDietPdf || data.customDietPdf.length === 0) {
        data_ = {
          id: 1,
          ...data_,
          createdAt: new Date(),
        };

        pdfArray = [data_];
      } else {
        data_ = {
          id: data.customDietPdf.length + 1,
          ...data_,
          createdAt: new Date(),
        };

        pdfArray = [data_, ...data?.customDietPdf];
      }

      await updateDoc(ref, {
        customDietPdf: pdfArray,
      });

      return { data: pdfArray, id: uid };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//for getting family list name
export const getClientFamilies = createAsyncThunk(
  "Client/getClientFamilies",
  async ({ uid }, thunkAPI) => {
    try {
      const q = query(
        collection(firestore, "client"),
        where("adminId", "==", uid)
      );
      let familyObj = {},
        familyArray = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().familyName && !familyObj[doc.data().familyName]) {
          familyObj[doc.data().familyName] = 1;
          familyArray.push({
            label: doc.data().familyName,
            value: doc.data().familyName,
          });
        }
      });

      return familyArray;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const ClientSlice = createSlice({
  name: "Client",
  initialState: {
    clients: [],
    families: [],
    filterState: {
      filter: false,
      state: null,
      redirect: false,
    },
    selectedClient: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    message: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.message = null;
      return state;
    },

    updateFilterState: (state, { payload }) => {
      state.filterState = {
        filter: true,
        state: payload.state,
        redirect: payload.redirect ?? false,
      };
    },

    clearFilterState: (state) => {
      state.filterState = {
        ...state.filterState,
        filter: false,
        redirect: false,
      };
      return state;
    },
  },
  extraReducers: {
    [getClientFamilies.fulfilled]: (state, { payload }) => {
      state.families = payload;
      state.isFetching = false;
    },
    [getClientFamilies.pending]: (state) => {
      state.isFetching = true;
    },
    [getClientFamilies.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.message = payload?.message || "Server Error";
    },

    [getSelectedClient.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selectedClient = payload;
      state.isFetching = false;
    },
    [getSelectedClient.pending]: (state) => {
      state.isFetching = true;
    },
    [getSelectedClient.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [getClients.fulfilled]: (state, { payload }) => {
      state.clients = payload;
      state.isSuccess = true;
      state.isFetching = false;
    },
    [getClients.pending]: (state) => {
      state.isFetching = true;
    },
    [getClients.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [SellConsultation.fulfilled]: (state, { payload }) => {
      if (payload?.new) {
        state.clients = [payload, ...state.clients];
      } else {
        state.clients = state.clients.map((item) => {
          if (item.id === payload.id) {
            if (item?.consultation?.length > 0) {
              return {
                ...item,
                ...payload,
                familyName: item.familyName,
                consultation: [...item.consultation, { ...payload.service }],
              };
            } else {
              return {
                ...item,
                ...payload,
                familyName: item.familyName,
                consultation: [{ ...payload.service }],
              };
            }
          }
          return { ...item };
        });
      }
      state.isFetching = false;
      state.isSuccess = true;
      state.message = !payload?.new
        ? "Consultation Service added successfully!!"
        : "Client added successfully!!";
    },

    [SellConsultation.pending]: (state) => {
      state.isFetching = true;
    },

    [SellConsultation.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [UpdateBasicInfoHandler.fulfilled]: (state, { payload }) => {
      state.clients = [payload, ...state.clients];
      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Client added successfully!!";
    },

    [UpdateBasicInfoHandler.pending]: (state) => {
      state.isFetching = true;
    },

    [UpdateBasicInfoHandler.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [UpdatePersonalInfoHandler.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (item.id === payload.id) {
          const updatedInfo = { ...item, ...payload };
          return { ...updatedInfo, familyName: item.familyName };
        }
        return { ...item };
      });
      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Client updated successfully!!";
    },

    [UpdatePersonalInfoHandler.pending]: (state) => {
      state.isFetching = true;
    },

    [UpdatePersonalInfoHandler.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [UpdatePlanInfoHandler.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (item.id === payload.id) {
          const updatedServices =
            item?.services?.length > 0
              ? [...item.services, { ...payload.service }]
              : [{ ...payload.service }];

          return {
            ...item,
            services: updatedServices,
            notes: payload.notes ?? item.notes,
          };
        }
        return { ...item };
      });
      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Service updated successfully!!";
    },

    [UpdatePlanInfoHandler.pending]: (state) => {
      state.isFetching = true;
    },

    [UpdatePlanInfoHandler.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [SellService.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (item.id === payload.id) {
          const updatedServices =
            item?.services?.length > 0
              ? [...item.services, { ...payload.service }]
              : [{ ...payload.service }];

          return {
            ...item,
            ...payload,
            familyName: item.familyName,
            services: updatedServices,
          };
        }
        return { ...item };
      });

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Service added successfully!!";
    },
    [SellService.pending]: (state) => {
      state.isFetching = true;
    },
    [SellService.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [SellProduct.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (item.id === payload.id) {
          if (item?.products?.length > 0) {
            return {
              ...item,
              products: [...item.products, { ...payload.productObj }],
            };
          } else {
            return { ...item, products: [{ ...payload.productObj }] };
          }
        }
        return { ...item };
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Product sold successfully";
    },
    [SellProduct.pending]: (state) => {
      state.isFetching = true;
    },
    [SellProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditClient.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
            updatedAt: payload.updatedAt,
          };
        }
        return { ...item };
      });

      state.selectedClient = {
        ...state.selectedClient,
        ...payload,
      };

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully updated!!";
    },
    [EditClient.pending]: (state) => {
      state.isFetching = true;
    },
    [EditClient.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [deleteDocument.fulfilled]: (state, { payload }) => {
      if (payload.persona === "product") {
        state.clients = state.clients.map((item) => {
          if (item.id === payload.clientId) {
            item.products = payload.updatedArray;
          }
          return item;
        });

        state.selectedClient = {
          ...state.selectedClient,
          products: [...payload.updatedArray],
        };
      }

      if (payload.persona === "service") {
        state.clients = state.clients.map((item) => {
          if (item.id === payload.clientId) {
            item.services = payload.updatedArray;
          }
          return item;
        });
      }

      if (payload.persona === "consultation") {
        state.clients = state.clients.map((item) => {
          if (item.id === payload.clientId) {
            item.consultation = payload.updatedArray;
          }
          return item;
        });
      }

      if (payload.persona === "deleteBiochem") {
        state.clients = state.clients.map((item) => {
          return item;
        });
      }

      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully deleted!!";
    },
    [deleteDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddDietary.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.dietary && item.dietary.length > 0) {
            return {
              ...item,
              dietary: [{ ...payload.data }, ...item.dietary],
            };
          } else {
            return {
              ...item,
              dietary: [payload.data],
            };
          }
        }
        return item;
      });

      if (
        state.selectedClient?.dietary &&
        state.selectedClient?.dietary.length > 0
      ) {
        state.selectedClient = {
          ...state.selectedClient,
          dietary: [payload.data, ...state.selectedClient.dietary],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          dietary: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added";
    },
    [AddDietary.pending]: (state) => {
      state.isFetching = true;
    },
    [AddDietary.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditDietary.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        dietary: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            dietary: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditDietary.pending]: (state) => {
      state.isFetching = true;
    },
    [EditDietary.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddPhysical.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.physical && item.physical.length > 0) {
            return {
              ...item,
              physical: [payload.data, ...item.physical],
            };
          } else {
            return {
              ...item,
              physical: [payload.data],
            };
          }
        }
        return item;
      });

      if (
        state.selectedClient?.physical &&
        state.selectedClient?.physical.length > 0
      ) {
        state.selectedClient = {
          ...state.selectedClient,
          physical: [payload.data, ...state.selectedClient.physical],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          physical: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },
    [AddPhysical.pending]: (state) => {
      state.isFetching = true;
    },
    [AddPhysical.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditPhysical.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        physical: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            physical: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditPhysical.pending]: (state) => {
      state.isFetching = true;
    },
    [EditPhysical.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddBiochemical.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.biochemical && item.biochemical.length > 0) {
            return {
              ...item,
              biochemical: [payload.data, ...item.biochemical],
            };
          } else {
            return {
              ...item,
              biochemical: [payload.data],
            };
          }
        }
        return item;
      });

      if (
        state.selectedClient?.biochemical &&
        state.selectedClient?.biochemical?.length > 0
      ) {
        state.selectedClient = {
          ...state.selectedClient,
          biochemical: [payload.data, ...state.selectedClient.biochemical],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          biochemical: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },
    [AddBiochemical.pending]: (state) => {
      state.isFetching = true;
    },
    [AddBiochemical.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditBiochemical.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        biochemical: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            biochemical: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditBiochemical.pending]: (state) => {
      state.isFetching = true;
    },
    [EditBiochemical.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddMeasurement.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.measurement) {
            return {
              ...item,
              measurement: [payload.data, ...item.measurement],
            };
          } else {
            return {
              ...item,
              measurement: [payload.data],
            };
          }
        }
        return item;
      });

      if (state.selectedClient?.measurement?.length > 0) {
        state.selectedClient = {
          ...state.selectedClient,
          measurement: [payload.data, ...state.selectedClient.measurement],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          measurement: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },
    [AddMeasurement.pending]: (state) => {
      state.isFetching = true;
    },
    [AddMeasurement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditMeasurement.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        measurement: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            measurement: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditMeasurement.pending]: (state) => {
      state.isFetching = true;
    },
    [EditMeasurement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddVital.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.vital) {
            return {
              ...item,
              vital: [payload.data, ...item.vital],
            };
          } else {
            return {
              ...item,
              vital: [payload.data],
            };
          }
        }
        return item;
      });

      if (state.selectedClient?.vital?.length > 0) {
        state.selectedClient = {
          ...state.selectedClient,
          vital: [payload.data, ...state.selectedClient.vital],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          vital: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },
    [AddVital.pending]: (state) => {
      state.isFetching = true;
    },
    [AddVital.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditVital.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        vital: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            vital: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditVital.pending]: (state) => {
      state.isFetching = true;
    },
    [EditVital.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddPayment.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            services: [payload.data, ...item.services],
          };
        }
        return item;
      });

      if (state.selectedClient?.services?.length > 0) {
        state.selectedClient = {
          ...state.selectedClient,
          services: [payload.data, ...state.selectedClient.services],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          services: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },
    [AddPayment.pending]: (state) => {
      state.isFetching = true;
    },
    [AddPayment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditPayment.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        services: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            services: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditPayment.pending]: (state) => {
      state.isFetching = true;
    },
    [EditPayment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditSoldProduct.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        products: payload.data,
      };

      state.clients = state.clients?.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            products: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditSoldProduct.pending]: (state) => {
      state.isFetching = true;
    },

    [EditSoldProduct.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddDietRecall.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.dietRecall) {
            return {
              ...item,
              dietRecall: [payload.data, ...item.dietRecall],
            };
          } else {
            return {
              ...item,
              dietRecall: [payload.data],
            };
          }
        }
        return item;
      });

      if (state.selectedClient?.dietRecall?.length > 0) {
        state.selectedClient = {
          ...state.selectedClient,
          dietRecall: [payload.data, ...state.selectedClient.dietRecall],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          dietRecall: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },

    [AddDietRecall.pending]: (state) => {
      state.isFetching = true;
    },

    [AddDietRecall.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditDietRecall.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        dietRecall: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            dietRecall: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditDietRecall.pending]: (state) => {
      state.isFetching = true;
    },

    [EditDietRecall.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [AddDailyIntake.fulfilled]: (state, { payload }) => {
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          if (item.dailyIntake) {
            return {
              ...item,
              dailyIntake: [payload.data, ...item.dailyIntake],
            };
          } else {
            return {
              ...item,
              dailyIntake: [payload.data],
            };
          }
        }
        return item;
      });

      if (state.selectedClient?.dailyIntake?.length > 0) {
        state.selectedClient = {
          ...state.selectedClient,
          dailyIntake: [payload.data, ...state.selectedClient.dailyIntake],
        };
      } else {
        state.selectedClient = {
          ...state.selectedClient,
          dailyIntake: [payload.data],
        };
      }

      state.isFetching = false;
      state.isSuccess = true;
      state.message = "Successfully added!!";
    },

    [AddDailyIntake.pending]: (state) => {
      state.isFetching = true;
    },

    [AddDailyIntake.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [EditDailyIntake.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        dailyIntake: payload.data,
      };
      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            dailyIntake: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully updated!!";
    },
    [EditDailyIntake.pending]: (state) => {
      state.isFetching = true;
    },

    [EditDailyIntake.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [uploadImages.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        imageNowVsEarlier: payload.data,
      };

      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            imageNowVsEarlier: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully added!!";
    },

    [uploadImages.pending]: (state) => {
      state.isFetching = true;
    },

    [uploadImages.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },

    [uploadCustomDietChart.fulfilled]: (state, { payload }) => {
      state.selectedClient = {
        ...state.selectedClient,
        customDietPdf: payload.data,
      };

      state.clients = state.clients.map((item) => {
        if (payload.id === item.id) {
          return {
            ...item,
            customDietPdf: payload.data,
          };
        }
        return item;
      });
      state.isSuccess = true;
      state.isFetching = false;
      state.message = "Successfully added!!";
    },

    [uploadCustomDietChart.pending]: (state) => {
      state.isFetching = true;
    },

    [uploadCustomDietChart.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.message = payload?.message || "Server Error";
    },
  },
});

export const { clearState, updateFilterState, clearFilterState } =
  ClientSlice.actions;

export const ClientSelector = (state) => state.client;
