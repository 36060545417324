import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Button } from "@mui/material";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

import { useDispatch, useSelector } from "react-redux";
import { logout, userSelector } from "store/features/authSlice";
import { auth } from "firebaseConfig";
import { signOut } from "firebase/auth";

function DashboardNavbar({ absolute, light, isMini, status, title }) {
  const { isEmployee } = useSelector(userSelector);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const { name } = useSelector(userSelector);
  let navigate = useNavigate(); //for navigation

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
         The event listener that's calling the handleTransparentNavbar function when 
        scrolling the window.
        */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const dispatch_ = useDispatch();
  const logoutHandler = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      dispatch_(logout());
    } catch (error) {
      // Handle error if needed
    }
  };

  const capitalizeName = (name) => {
    if (name) {
      return name[0].toUpperCase() + name.slice(1);
    } else {
      return "Hello user";
    }
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={status === "client" ? title : route[route.length - 1]}
            route={route}
            light={light}
          />
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SuiBox
              color={light ? "white" : "inherit"}
              style={{ display: "flex", alignItems: "center", zIndex: 100 }}
            >
              <SuiTypography
                component={Button}
                variant="h8"
                onClick={() => navigate(!isEmployee && "/profile")}
                style={{
                  marginRight: 15,
                  fontSize: 12,
                  cursor: isEmployee ? "auto" : "pointer",
                }}
              >
                {capitalizeName(name)}
              </SuiTypography>

              <SuiTypography
                component={Button}
                variant="h8"
                onClick={logoutHandler}
                style={{ color: "red", fontSize: 12 }}
              >
                Logout
              </SuiTypography>

              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
