import SuiAlert from "components/SuiAlert";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { components } from "react-select";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (num) => {
  return true;
};

export const validateGST = (gst) => {
  return String(gst).match(
    /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
  );
};

export function validatePassword(password) {
  var MinLength = 6;
  var MaxLength = 15;

  let meetsLengthRequirements =
    password.length >= MinLength && password.length <= MaxLength;
  if (!meetsLengthRequirements) {
    return {
      isValid: false,
      message: "Password needs to be in between 6-15 characters!!",
    };
  }
  var hasUpperCasevarter = false;
  var hasLowerCasevarter = false;
  var hasDecimalDigit = false;
  var hasSpecialChar = false;

  if (meetsLengthRequirements) {
    for (var i = 0, len = password.length; i < len; i++) {
      var char = password.charAt(i);
      if (!isNaN(+char * 1)) {
        hasDecimalDigit = true;
      }
      if (
        char === "@" ||
        char === "$" ||
        char === "#" ||
        char === "&" ||
        char === "%" ||
        char === "!"
      ) {
        hasSpecialChar = true;
      } else {
        if (char === char.toUpperCase()) {
          hasUpperCasevarter = true;
        }
        if (char === char.toLowerCase()) {
          hasLowerCasevarter = true;
        }
      }
    }
  }

  if (!hasUpperCasevarter) {
    return {
      isValid: false,
      message: "Password does not contain Uppercase Character!!",
    };
  }

  if (!hasLowerCasevarter) {
    return {
      isValid: false,
      message: "Password does not contain Lowercase Character!!",
    };
  }

  if (!hasDecimalDigit) {
    return {
      isValid: false,
      message: "Password does not contain Digit!!",
    };
  }
  if (!hasSpecialChar) {
    return {
      isValid: false,
      message: "Password does not contain Special Character!!",
    };
  }

  return { isValid: true, message: null };
}

export const getDiscountedPrice = (price, discount) => {
  return parseFloat(price) - parseFloat(discount);
};

export const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} style={{ marginRight: 10 }} />
      {children}
    </components.Option>
  );
};

export const getTaxPrice = (amount, gstPercentage) => {
  return amount * (gstPercentage / 100);
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result.split(",")[1]; // Get only the base64 string part
      resolve(base64data);
    };
    reader.onerror = (error) => reject(error);
  });
};

//calculating up/down% of current month wrt last month
export const getUpDownStat = (lastMonth, currentMonth) => {
  if (lastMonth > currentMonth) {
    let v_ = lastMonth - currentMonth;
    v_ = (v_ / lastMonth) * 100;
    return { text: `-${v_.toFixed(2)}%`, color: "error" };
  }

  if (lastMonth < currentMonth) {
    let v_ = currentMonth - lastMonth;
    if (lastMonth === 0) {
      v_ = v_ * 100;
    } else {
      v_ = (v_ / lastMonth) * 100;
    }
    return { text: `+${parseInt(v_)}%`, color: "success" };
  }

  if (lastMonth === currentMonth) {
    return { text: `0%`, color: "success" };
  }
};

//for getting index of value in array
export const floorSearch = (arr, x) => {
  let n = arr.length;

  // If last element is smaller than x
  if (x >= arr[n - 1].productId) return n - 1;

  // If first element is greater than x
  if (x < arr[0].productId) return -1;

  // Linearly search for the first element
  // greater than x
  for (let i = 1; i < n; i++) if (arr[i].productId > x) return i - 1;

  return -1;
};

//for calc selling price with tax
export const calcPriceWT = (priceWOT, tax) => {
  return (
    parseFloat(priceWOT) +
    parseFloat(parseFloat(priceWOT) * (parseFloat(tax) / 100))
  ).toFixed(2);
};

//for converting timestamp
export const timestampConversion = (time) => {
  let createdAt;
  createdAt = time;
  createdAt = createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
  createdAt = moment.utc(createdAt);
  return createdAt;
};

export const timestampConversionv1 = (time) => {
  let createdAt;
  createdAt = time;
  createdAt = createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
  return createdAt;
};

export const formatDateField = (date) => {
  let options = { year: "numeric", month: "long", day: "numeric" };
  let today = new Date(date);

  return today.toLocaleDateString("en-US", options);
};

export const formatDateFieldv1 = (date) => {
  let today = moment(new Date(date)).format("MMM Do YY");
  return today;
};

export const formatDateFieldv2 = (date) => {
  let today = moment(new Date(date)).format("Do MMM YY");
  return today;
};

// - 02/02/2023
export const formatDatev3 = (date) => {
  let formattedDate = moment(date).format("DD/MM/YYYY");
  return formattedDate;
};

export const shortFormatDateField = (date) => {
  var options = { year: "numeric", month: "short", day: "numeric" };
  var today = new Date(date);

  return today.toLocaleDateString("en-US", options);
};

export const generatePDF = (doc) => {
  doc.setFont("helvetica", "bold");
  doc.text("DIET PLAN - RAJESH (22/12/2022)", 105, 15, null, null, "center");

  doc.setFont("courier", "normal");
  doc.text("Early Morning", 15, 30);
  doc.setFontSize(11);

  doc.text("Recipe Name - Spinach Corn Sandwich", 20, 40);
  doc.text("Recipe Description - Description about Recipe", 20, 49);
  doc.text("Food type - Party", 20, 58);
  doc.text("Ingredients - Salt, Spinach", 20, 67);
  doc.text("Calories - 3000", 20, 76);
  doc.text("Nutrition Values - Fat:13g, Carbs: 36g", 20, 85);
  doc.text("Category - Fitify", 20, 94);

  var splitText = doc.splitTextToSize(
    "How to prepare - Description about Recipe  123 Description about Recipe Description about Recipe  123 123Description about Recipe  123Description about Recipe  123Description about Recipe  123 \n \nHow to take -  Description about Recipe  123 Description about Recipe Description about Recipe  123 123Description about Recipe  123Description about Recipe  123Description about Recipe  123",
    170
  );
  // var pageHeight = doc.internal.pageSize.height;
  var y = 103;
  for (var i = 0; i < splitText.length; i++) {
    if (y > 375) {
      y = 20;
      doc.addPage();
    }
    doc.text(20, y, splitText[i]);
    y = y + 5;
  }

  doc.save("dietplan-rajesh.pdf");
};

export const referralCodeGenerator = (name) => {
  let res = "";
  for (let i = 0; i < name.length; i++) {
    if (name[i] !== " ") {
      res += name[i];
    }
  }
  return res + new Date().getFullYear();
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

//get the list of names
export function getNames(name) {
  let namesList = [];
  if (name) {
    namesList = [
      name,
      name.toUpperCase(),
      name.toLowerCase(),
      name[0].toUpperCase() + name.slice(1),
      name[0].toUpperCase() + name.slice(1).toLowerCase(),
    ];

    let len = 0;
    let name1_ = "";
    while (len < name.length) {
      if (name[len] === " ") {
        name1_ = name1_.concat(" ");
        if (len + 1 < name.length) {
          name1_ = name1_.concat(name[len + 1].toUpperCase());
          len += 2;
        } else {
          len++;
        }
      } else {
        name1_ = name1_.concat(name[len]);
        len++;
      }
    }

    let name2_ = name[0].toUpperCase() + name.slice(1);
    let name2 = "";
    len = 0;
    while (len < name2_.length) {
      if (name2_[len] === " ") {
        name2 = name2.concat(" ");
        if (len + 1 < name2_.length) {
          name2 = name2.concat(name2_[len + 1].toUpperCase());
          len += 2;
        } else {
          len++;
        }
      } else {
        name2 = name2.concat(name2_[len]);
        len++;
      }
    }
    namesList = [...namesList, name2];
  }

  return namesList;
}

//get last name from full name
export function takeLastName(fullName) {
  let nameArray = fullName.split(" ");
  let lastName = nameArray[nameArray.length - 1];
  return lastName;
}

export function calculatePriceAfterGST(sellingPriceWOT, gst) {
  let value = (
    parseFloat(sellingPriceWOT ?? 0) *
    (parseFloat(gst ?? 0) / 100)
  ).toFixed(2);
  return parseFloat(value);
}

//calculating products price after discount and gst
export function calculateProductFinalPrice(
  quantity,
  sellingPriceWOT,
  discount,
  tax
) {
  let priceAfterDiscount =
    parseFloat(sellingPriceWOT ?? 0) - parseFloat(discount ?? 0);
  let priceAfterGst = calculatePriceAfterGST(priceAfterDiscount, tax);
  let balancePerProduct = priceAfterDiscount + priceAfterGst;
  let totalPrice = parseFloat(balancePerProduct * quantity).toFixed(2);
  return totalPrice;
}

export function calculateServiceFinalPrice(sellingPriceWOT, discount, tax) {
  let priceAfterDiscount =
    parseFloat(sellingPriceWOT ?? 0) - parseFloat(discount ?? 0);
  let priceAfterGst = calculatePriceAfterGST(priceAfterDiscount, tax);

  let balancePerProduct = priceAfterDiscount + priceAfterGst;

  return balancePerProduct;
}

//calculating total balance
export function calculateProductTotalBalance(
  priceWOT,
  quantity,
  discount,
  tax,
  amountPaid
) {
  let priceAfterDiscount =
    parseFloat(priceWOT ?? 0) - parseFloat(discount ?? 0);
  let priceAfterGst = calculatePriceAfterGST(priceAfterDiscount, tax);
  let balancePerProduct = priceAfterDiscount + priceAfterGst;

  let totalBalance = parseFloat(
    balancePerProduct * quantity - parseFloat(amountPaid ?? 0)
  ).toFixed(2);
  return totalBalance;
}

//sorting array of object by name property
export function sortArrayByName(arr) {
  arr.sort(function (a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

//create invoice id based on timestamp
export function createInvoiceId() {
  let currentDate = new Date();
  let timestamp = currentDate.getTime();
  timestamp = timestamp.toString();
  let invoiceId = "INV-" + timestamp;
  return invoiceId;
}

//Week1 - Week 100
export function getWeeksArray(allOption) {
  const weeks = [];

  if (allOption) {
    weeks.unshift({ value: "all", label: "All", key: 0 });
  }

  for (let i = 1; i <= 100; i++) {
    weeks.push({
      label: `Week ${i}`,
      value: `Week ${i}`,
      key: i,
    });
  }

  return weeks;
}

export const leadStatusStyle = (key) => {
  switch (key) {
    case "New":
      return "#9DDA11";

    case "In Discussion":
      return "#EA7306";

    case "Converted":
      return "#82D616";

    default:
      return "#DA1D1D";
  }
};

export const calculateBalance = (totalAmount, discount, amountPaid) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid ?? 0)
    ).toFixed(2);
  }
};

export function formatNumberInIndianFormat(number) {
  if (number) number = parseFloat(number);
  return number.toLocaleString("en-IN");
}

// Function to extract username from social media URL
export function extractUsername(url) {
  // Remove any leading or trailing slashes
  url = url.replace(/(^\/|\/$)/g, "");

  // Extract the username from the URL
  const parts = url.split("/");
  return parts[parts.length - 1];
}

export const generateFileName = (service) => {
  const currentDate = new Date().toISOString().split("T")[0];
  return `${service}_invoice_${currentDate}`;
};

export function convertTo24HourFormat(time) {
  if (time) {
    const [timeStr, period] = time.split(" ");
    let [hours, minutes] = timeStr.split(":");

    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period) {
      if (period.toLowerCase() === "pm" && hours !== 12) {
        hours += 12;
      } else if (period.toLowerCase() === "am" && hours === 12) {
        hours = 0;
      }

      const hoursStr = hours.toString().padStart(2, "0");
      const minutesStr = minutes.toString().padStart(2, "0");
      return `${hoursStr}:${minutesStr}`;
    }
  }

  return time;
}

export const updateRow = (id, updatedData, tableData) => {
  const rowIndex = tableData.findIndex((row) => row.id === id);

  if (rowIndex !== -1) {
    const updatedTableData = [...tableData];

    updatedTableData[rowIndex] = {
      ...updatedTableData[rowIndex],
      ...updatedData,
    };

    return updatedTableData;
  }
};

export const calculateServiceUnpaidBalance = (
  totalAmount,
  discount,
  amountPaid
) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid)
    ).toFixed(2);
  }
  return 0;
};

export function formatDbTimestamp(timestamp) {
  if (timestamp?.seconds) {
    return moment(timestampConversion(timestamp))
      .local()
      .format("MMMM Do YYYY, h:mm a");
  } else {
    return moment(timestamp).local().format("MMMM Do YYYY, h:mm a");
  }
}

export const copyToClipboard = async (
  textToCopy,
  alertMessage,
  errorMessage
) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(textToCopy);
      toast.success(alertMessage);
    } else {
      toast?.error(errorMessage);
    }
  } catch (error) {
    console.error("Unable to copy to clipboard", error);
  }
};

export const clearInputValueByDocId = (elementId) => {
  document.getElementById(elementId).value = "";
};

export const sortingWithTimestamp = (a, b) => {
  const getTimestamp = (obj) => {
    if (obj.timestamp) {
      return obj.timestamp.seconds * 1000 + obj.timestamp.nanoseconds / 1000000;
    } else if (obj.createdAt) {
      return obj.createdAt.seconds * 1000 + obj.createdAt.nanoseconds / 1000000;
    } else {
      return Infinity; // Return a very large number for objects without timestamps
    }
  };

  const timestampA = getTimestamp(a);
  const timestampB = getTimestamp(b);

  return timestampB - timestampA;
};
export const isAllOptionInserted = (array) =>
  array.some((item) => item.value === "all");
