import React from 'react'
import { TailSpin } from  'react-loader-spinner'
import { style } from './style.js'


const Loader = props => (
    <div style={style.loaderDiv}>
        <TailSpin color="#17c1e8" height={80} width={80} />
    </div>
)

export default Loader