import { doc, getDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";

export const getSelectedEmployeeDetails = async ({ id }) => {
  try {
    let data = [];

    const ref = doc(firestore, "employees", id);

    const snapshot = await getDoc(ref);

    if (snapshot.exists()) {
      data = snapshot.data();

      return { ...data, id: id };
    }
  } catch (e) {
    throw e;
  }
};
export const getSelectedEmployeeAttendance = async ({ id }) => {
  try {
    let data = [];

    const ref = doc(firestore, "attendance", id);

    const snapshot = await getDoc(ref);

    if (snapshot.exists()) {
      data = snapshot.data();

      return { ...data, id: id };
    }
  } catch (e) {
    throw e;
  }
};
