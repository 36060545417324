
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";




function FamilyDetail() {

    
    return (
        <DashboardLayout>
           

        </DashboardLayout>
    );
}

export default FamilyDetail;
