import React, { useState, useMemo } from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'
import '../../sub-components/common/imageStyling.css';
import CustomButton from 'containers/CustomButton';
import { Card, useMediaQuery } from '@mui/material';
import { saveImages } from 'services/customer';

const InputFile = styled('input')({
    display: 'none',
});


function UploadHandler({ clientId, imageArray, setImageArray, setDisableButton }) {

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [formdata, setFormdata] = useState({ image: "", date: "" })
    const [loader, setLoader] = useState(false)
    //error handler
    const errorDefault = useMemo(() => {
        return {
            image: false,
            date: false,
        }
    }, []);
    const [errorMessage, setErrorMessage] = useState(errorDefault)


    const handleImage = (evt) => {
        let tgt = evt.target, files = tgt.files;
        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                setFormdata({
                    ...formdata,
                    [evt.target.name]: files[0],
                    [`${evt.target.name}_location`]: fr.result
                })
            }
            fr.readAsDataURL(files[0]);
        }
    }

    //for submitting data
    const submitHandler = async (e) => {
        if (!formdata.image) {
            toast.error("Please Choose the Image field")
            setErrorMessage({
                ...errorMessage,
                image: true
            })
            return;
        }
        const today = new Date();
        if (new Date(today) < new Date(formdata.date)) {
            toast.error("Please Choose the Date till Current Day")
            setErrorMessage({
                ...errorMessage,
                image: true
            })
            return;
        }

        if (!formdata.date) {
            toast.error("Please fill Image Date field")
            setErrorMessage({
                ...errorMessage,
                date: true
            })
            return;
        }
        try {
            setImageArray((e) => {
                e = [
                    ...e,
                    {
                        imageId: e.length + 1,
                        ...formdata,
                    }
                ]
                return e;
            })
            setFormdata({ image: "", date: "" })
        } catch (e) {
            toast.error("Something went wrong, retry")
        }
    }

    const saveHandler = async () => {
        try {
            setLoader(true)
            await saveImages(clientId, imageArray)
            setLoader(false)
            setDisableButton(true)
            toast.success("Successfully uploaded images!")

        } catch (error) {
            toast.error("Something went wrong!")
            setLoader(false)
        }
    }


    return (
        <Card>
            <SuiBox display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} flexWrap="wrap" alignItems={isSmallScreen ? 'centre' : 'center'} p={2}>
                <SuiBox my={1} mt={1}>
                    <label htmlFor="image-1" style={{ cursor: 'pointer' }}>
                        <InputFile
                            id="image-1"
                            type="file"
                            name="image"
                            accept="image/*"
                            error={errorMessage?.image}
                            onChange={handleImage} />
                        <SuiBox py={1} pr={2}>
                            <SuiTypography fontWeight="bold" textTransform="capitalize" variant="h6" mb={1} >
                                Choose Image &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                            </SuiTypography>
                            <SuiAvatar
                                alt="image"
                                style={{ width: "auto", height: 40, borderRadius: 8, color: "#3A416F", fontSize: "12px", border: "1px solid #D2D6DA" }}
                            >Choose Image </SuiAvatar>
                        </SuiBox>
                    </label>
                </SuiBox>

                <SuiBox display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems={isSmallScreen ? 'centre' : 'end'}>
                    <SuiBox lineHeight={1} mr={2} >
                        <SuiTypography mb={1} variant="h6">Image Date</SuiTypography>
                        <SuiInput
                            type="date"
                            name="date"
                            style={{ width: "222px", height: "32px" }}
                            error={errorMessage?.date}
                            value={formdata.date ?? ''}
                            onChange={(e) => {
                                setErrorMessage({
                                    ...errorMessage,
                                    [e.target.name]: false
                                })
                                setFormdata({
                                    ...formdata,
                                    [e.target.name]: e.target.value
                                })
                            }} />
                    </SuiBox>
                    <SuiBox mr={2} mt={2}>
                        <CustomButton
                            color="dark"
                            variant="gradient"
                            onClick={submitHandler}
                            fullWidth
                            width={180}
                            title={'Add Image'}
                        />

                    </SuiBox>
                    {imageArray.length > 0 && <SuiBox mr={2} mt={2}>
                        <CustomButton
                            color="dark"
                            variant="gradient"
                            onClick={saveHandler}
                            fullWidth
                            width={180}
                            disabled={loader}
                            loader={loader}
                            title={'Save and continue'}
                        />
                    </SuiBox>}
                </SuiBox>

                {formdata.image &&
                    <img className="uploadedimage"
                        src={URL.createObjectURL(formdata.image)} alt="Uploaded" />}

            </SuiBox>
        </Card>
    )
}

export default UploadHandler
