import { useState } from 'react'

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import curved0 from "assets/images/curved-images/curved0.jpg";
import { userSelector } from 'store/features/authSlice';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { contactSupport } from 'firebaseConfig';


function HelpCard({ title, description, info}) {

    const { name, email } = useSelector(userSelector);

    const [ loader, setLoader ] = useState(false)
    //for handling error
    const [ errorMessage, setErrorMessage ] = useState({
        msg: false
    })

    //for handling message for contact
    const [ formdata, setFormdata ] = useState({
        msg: ""
    })

    const messageHandler = async () => {
        if(!formdata.msg){
            setErrorMessage({
                msg:true
            })
            toast.error("Message field is empty")
            return
        }
        setLoader(true)
        try {
            await contactSupport({
                message: formdata.msg, name, email
            })
            toast.success("Sent successfully")
            setFormdata({ msg: "" })
            setLoader(false)
        } catch(err){
            setLoader(false)
            toast.error(err.message ?? "Something went wrong!!")
        }
    }

    const documentation = () => (
        <SuiBox>
            <Grid container>
                <Grid item xs={12} lg={12}
                    px={3}
                    py={4}
                    style={{
                        backgroundImage: `url(${curved0})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover', 
                        borderRadius: 14
                    }}>
                    <SuiBox
                        style={{
                            background: 'white',
                            width: 40,
                            height: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 6
                        }}>
                        <Icon
                            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                                fontSize: `${size.xl} !important`,
                                stroke: dark.main,
                                strokeWidth: "2px",
                                cursor: "pointer",
                                background: "transparent"
                            })}>
                            star_border
                        </Icon>
                    </SuiBox>
                    <SuiBox mt={3}>
                        <SuiTypography variant="h8" color="white">Need Help?</SuiTypography>
                        <SuiTypography variant="h7" color="white">Please check our docs</SuiTypography>                
                    </SuiBox>

                    <SuiBox mt={3} display="flex" justifyContent='center'>
                        <SuiButton
                            style={{
                                width: '100%',
                                color: "#3A416F",
                            }}
                            component={Button}
                            onClick={()=> null}
                            fullWidth>
                                Documentation
                        </SuiButton>
                    </SuiBox>
                </Grid>
            </Grid>
        </SuiBox>
    );


    return (
        <Card sx={{ height: "100%" }}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </SuiTypography>
            </SuiBox>
            <SuiBox p={2}>
                <SuiBox>
                    {documentation()}
                </SuiBox>

                <SuiBox mt={3} lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">Contact Support</SuiTypography>
                    <SuiInput 
                        type="text" 
                        name="msg"
                        multiline
                        rows={7}
                        error={errorMessage?.msg}
                        value={formdata.msg??''}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }} />
                </SuiBox>

                <SuiBox mt={4} display="flex" justifyContent='center'>
                    <SuiButton
                        style={{
                            width: '60%'
                        }}
                        width="50%"
                        component={Button}
                        color="dark"
                        variant="gradient"
                        onClick={messageHandler}
                        disabled={loader}
                        fullWidth>
                            {loader? 'Sending . . .': 'Send Email'}
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </Card>
    );
}

export default HelpCard;
