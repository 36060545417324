import { createSlice } from "@reduxjs/toolkit";

export const StatSlice = createSlice({
    name: "Stat",
    initialState: {
        currentMonthCustomer : null,
        lastMonthCustomer: null,
        currentMonthClaims: null,
        lastMonthClaims: null,
        lastMonthSales: null,
        currentMonthSales: null,
        lastMonthStores: null,
        currentMonthStores: null,
        
        customerCount: null,
        storeCount: null,
        isFetching: false,
        isSuccess: false,
        isError: false,
        message: null,

        lastMonthProductSales: null,
        currentMonthProductSales: null,

        lastMonthServiceSales: null,
        currentMonthServiceSales: null,

        balance: null,

        lastMonthClients: null, 
        currentMonthClients: null,
        clientCount: null,
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.message = null;
            return state;
        },


        updateProductSellCount: ( state, { payload } ) => {
            state.currentMonthProductSaleCount += payload.qty
        },

        updateNewClientCount: ( state, { payload } ) => {
            state.currentMonthClients = state?.currentMonthClients + 1
            state.clientCount = state?.clientCount + 1
        },

        statisticState: (state, { payload }) => {
            state.lastMonthSales = payload?.lastMonthSales ?? state?.lastMonthSales 
            state.currentMonthSales = payload?.currentMonthSales ?? state?.currentMonthSales
            state.lastMonthClaims = payload?.lastMonthClaims ?? state?.lastMonthClaims 
            state.currentMonthClaims = payload?.currentMonthClaims ?? state?.currentMonthClaims
            state.lastMonthCustomer = payload?.lastMonthCustomer ?? state?.lastMonthCustomer 
            state.currentMonthCustomer = payload?.currentMonthCustomer ?? state?.currentMonthCustomer
            state.lastMonthStores = payload?.lastMonthStores ?? state.lastMonthStores
            state.currentMonthStores =  payload?.currentMonthStores ?? state.currentMonthStores
            state.customerCount = payload?.customerCount ?? state?.customerCount
            state.storeCount = payload?.storeCount ?? state?.storeCount
           
            state.totalUniqueProductCounts = payload?.totalUniqueProductCounts ?? state?.totalUniqueProductCounts
            state.lastMonthProducts = payload?.lastMonthProducts ?? state?.lastMonthProducts
            state.currentMonthProducts = payload?.currentMonthProducts ?? state?.currentMonthProducts

   

            state.lastMonthClients = payload?.lastMonthClients ?? state?.lastMonthClients
            state.currentMonthClients = payload?.currentMonthClients ?? state?.currentMonthClients
            state.clientCount = payload?.clientCount ?? state?.clientCount

            state.balance = payload?.balance ?? state?.balance

            state.lastMonthServiceSaleCount = payload?.lastMonthServiceSaleCount 
            state.currentMonthServiceSaleCount = payload?.currentMonthServiceSaleCount 
            state.currentMonthProductSaleCount = payload?.currentMonthProductSaleCount
            state.lastMonthProductSaleCount = payload?.lastMonthProductSaleCount
        },

    },
});

export const { clearState, statisticState, updateProductSellCount, updateNewClientCount } = StatSlice.actions;

export const StatSelector = (state) => state.Stat;
