import Joi from "joi";

const validationSchema = Joi.object({
  expenseName: Joi.string().required().max(50).messages({
    "string.base": "Expense Name must be a string",
    "string.empty": "Expense Name is required",
    "string.max": "Expense Name must be at most {#limit} characters",
  }),
  expenseCategory: Joi.string().required().max(50).messages({
    "string.base": "Expense Category must be a string",
    "string.empty": "Expense Category is required",
    "string.max": "Expense Category must be at most {#limit} characters",
  }),
  dateOfExpense: Joi.date().required().messages({
    "date.base": "Date of Expense must be a valid date",
    "any.required": "Date of Expense is required",
  }),
  description: Joi.string().required().max(200).messages({
    "string.base": "Description must be a string",
    "string.empty": "Description is required",
    "string.max": "Description must be at most {#limit} characters",
  }),
  expenseAmount: Joi.number().required().positive().messages({
    "number.base": "Expense amount must be a number",
    "number.empty": "Expense amount is required",
    "number.positive": "Expense amount must be a positive number",
  }),
  tax: Joi.number().allow(null, '').optional().positive().messages({
    "number.base": "Tax must be a number",
    "number.empty": "Tax is required",
    "number.positive": "Tax must be a positive number",
  }),
});

export default validationSchema;
