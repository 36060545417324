import React, { useState, useEffect, useCallback } from "react";
import { dailyIntakeAndRecallTableColumns } from "utils/tablesHeaderContent";
import { dailyIntakeAndRecallRow } from "containers/clientDetails/sub-components/common/dailyIntakeAndRecallTableRows";
import AdminClientInfoCard from "containers/clientDetails/sub-components/card/adminClientCard";
import DefaultModal from "containers/clientDetails/sub-components/modalContainer/default";
import RecallModal from "containers/clientDetails/sub-components/modal/dietrecall";
import { headerDesc } from "utils/helper";
import { timestampConversion } from "utils/common";
import moment from "moment";

function DietRecallTable({
  id,
  setSelectedClient,
  dietRecall,
  title,
  deleteHandler,
}) {
  const [rows, setRows] = useState([]);
  const columns = [...dailyIntakeAndRecallTableColumns];
  const [newRecallModal, setNewRecallModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState("view");

  const addHandler = useCallback(() => {
    setNewRecallModal(true);
    setSelectedItem(null);
    setMode("create");
  }, []);

  const editHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("edit");
    setNewRecallModal(true);
  }, []);

  const viewHandler = useCallback((item) => {
    setSelectedItem(item);
    setMode("view");
    setNewRecallModal(true);
  }, []);

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let dietRecallArray = dietRecall.slice();

    dietRecallArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.createdAt);
      const b_timestamp = convertTimestamp(b?.createdAt);
      return b_timestamp - a_timestamp;
    });

    setRows(
      dailyIntakeAndRecallRow(
        dietRecallArray,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [dietRecall, editHandler, viewHandler]);

  return (
    <>
      <AdminClientInfoCard
        title={title}
        onClickBtnHandler={addHandler}
        columns={columns}
        rows={rows}
        oneTimeEntry={true}
      />
      <DefaultModal
        open={newRecallModal}
        {...headerDesc(mode, "Manage Diet Recall", selectedItem)}
        title={"Manage Diet Recall"}
        description={`You can ${selectedItem ? "edit" : "add"} details below.`}
        handleCloseModal={() => {
          setNewRecallModal(false);
          setSelectedItem(null);
        }}
        mode={mode}
        setMode={setMode}
      >
        <RecallModal
          id={id}
          persona="admin"
          mode={mode}
          open={newRecallModal}
          setSelectedClient={setSelectedClient}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleCloseModal={() => {
            setNewRecallModal(false);
            setSelectedItem(null);
          }}
        />
      </DefaultModal>
    </>
  );
}

export default DietRecallTable;
