import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import NoData from "containers/DataNotAvailBox";
import UploadBar from "./uploadBar";
import Tooltip from "@mui/material/Tooltip";
import { formatDateField } from "utils/common";
import PdfPng from "assets/images/pdf.png";
import { extractPDFfilename } from "utils/pdf";

function CustomDietPdf({ title, setSelectedClient, clientId, customDietPdf }) {
  const [customDietPdfArray, setCustomDietPdfArray] = useState([]);
  useEffect(() => {
    let customDietPdf1 = customDietPdf.slice();

    const customDietpdf2 = customDietPdf1?.map((item) => {
      const fileName = extractPDFfilename(item?.dietPdf);
      return { ...item, name: fileName };
    });

    customDietpdf2.sort((a, b) => new Date(b.date) - new Date(a.date));
    setCustomDietPdfArray(customDietpdf2);
  }, [customDietPdf]);

  const [loader, setLoader] = useState(false);

  return (
    <SuiBox mb={3}>
      <Card>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
        >
          <SuiTypography variant="h6">{title}</SuiTypography>
        </SuiBox>

        <UploadBar
          uid={clientId}
          setSelectedClient={setSelectedClient}
          loader={loader}
          setLoader={setLoader}
        />

        <SuiBox mt={4} display="flex" flexWrap="wrap" alignItems="center">
          {customDietPdf.length === 0 ? (
            <SuiBox display="flex" justifyContent="center" width="100%">
              <NoData />
            </SuiBox>
          ) : (
            customDietPdfArray.map((item, index) => (
              <Tooltip title={item?.description} placement="top" key={index}>
                <SuiBox p={2}>
                  <a
                    style={styles.anchor}
                    rel="noreferrer"
                    href={item.dietPdf}
                    target="_blank"
                    download
                  >
                    <img
                      src={PdfPng}
                      style={styles.image}
                      alt="PDF Thumbnail"
                    />

                    <SuiTypography
                      variant="button"
                      fontWeight="medium"
                      marginTop={2}
                    >
                      {item?.name?.length > 30
                        ? `${item?.name?.slice(0, 15)}...`
                        : item?.name}
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="medium">
                      {formatDateField(item?.date)}
                    </SuiTypography>
                  </a>
                </SuiBox>
              </Tooltip>
            ))
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default CustomDietPdf;

/**----------------------- styling ------------------ */

const styles = {
  image: {
    width: 100,
    height: 140,
  },

  anchor: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
  },
};
