
export const keyChanger = (key) => {
    switch (key) {
        case 'earlyMorning': return 'Early Morning'
        
        case 'breakfast': return 'Breakfast'
        
        case 'midMorning': return 'Mid Morning'
        
        case 'brunch' : return 'Brunch'

        case 'midMeal' : return 'Mid Meal'
            
        case 'lunch': return 'Lunch'
        
        case 'postLunch': return 'Post Lunch'
        
        case 'evening': return 'Evening'
        
        case 'beforeDinner':  return 'Before Dinner'
        
        case 'dinner': return 'Dinner'
        
        case 'postDinner': return 'Post Dinner'
        
        case 'lateNight': return 'Late Night'
    
        default:
            break;
    }
}


export const timerChart = (key) => {
    switch (key) {
        case 'earlyMorning': return '6:30'
        
        case 'breakfast': return '7:30'
        
        case 'midMorning': return '9:30'
        
        case 'brunch' : return '10:30'

        case 'midMeal' : return '11:30'
            
        case 'lunch': return '01:30'
        
        case 'postLunch': return '03:30'
        
        case 'evening': return '05:30'
        
        case 'beforeDinner':  return '07:30'
        
        case 'dinner': return '09:30'
        
        case 'postDinner': return '11:30'
        
        case 'lateNight': return '00:30'
    
        default:
            break;
    }
}


export const chartHandler = ( table ) => {
    let chartObj = {
        'earlyMorning': null,
        'breakfast': null,
        'midMorning': null,
        'brunch': null,
        'midMeal': null,
        'lunch': null,
        'postLunch': null,
        'evening': null,
        'beforeDinner': null,
        'dinner': null,
        'postDinner': null,
        'lateNight': null,
    }
    
    table.map((ele)=> {
        chartObj[ele.timing] = ele.list
        
        return ele
    })

    Object.keys(chartObj).forEach((key) => {
        if(!chartObj[key]){
            delete chartObj[key]
        }
        return key
    })
    return chartObj
}



export const dietRecallHandler = ( table ) => {
    let chartObj = {
        'earlyMorning': null,
        'breakfast': null,
        'midMorning': null,
        'brunch': null,
        'midMeal': null,
        'lunch': null,
        'postLunch': null,
        'evening': null,
        'beforeDinner': null,
        'dinner': null,
        'postDinner': null,
        'lateNight': null,
    }


    Object.keys(chartObj).forEach((key)=>{
        if(table[key]){
            chartObj[key] = table[key]
        } else {
            delete chartObj[key]
        }
        return key
    })

    return chartObj
}


export function onTimeChange(val) {
    if(!val){
        val = '15:00'
    }
    let timeSplit = val.split(':')
    let hours, minutes, meridian;

    hours = timeSplit[0];
    minutes = timeSplit[1];

    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours === 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }

    return hours + ':' + minutes + ' ' + meridian;
  }