import React, { useState } from "react";
import { Container, Paper, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Createable from "containers/Select";
import { genderOptions } from "constant";
import { toast } from "react-toastify";

const BodyFatCalculator = () => {
  const [height, setHeight] = useState(178);
  const [weight, setWeight] = useState(70);
  const [age, setAge] = useState(25);
  const [neck, setNeck] = useState(50);
  const [waist, setWaist] = useState(96);
  const [hip, setHip] = useState(92);
  const [gender, setGender] = useState({
    value: "Male",
    label: "Male",
    key: 1,
  });

  const [bodyFat, setBodyFat] = useState(null);

  const calculateBodyFat = () => {
    // Assuming all inputs are valid and not empty

    const waistNeckRatio = parseFloat(waist) - parseFloat(neck);
    const log10Ratio = Math.log10(waistNeckRatio);
    const log10Height = Math.log10(height);

    let bodyFatPercentage;
    if (gender.value === "Male") {
      bodyFatPercentage = 86.01 * log10Ratio - 70.041 * log10Height + 36.76;
      bodyFatPercentage = bodyFatPercentage - 6.5;
    } else if (gender.value === "Female" || gender.value === "Other") {
      // Assuming you have the formula for females based on hip circumference here
      const waistHipNeckRatio =
        parseFloat(waist) + parseFloat(hip) - parseFloat(neck);
      const log10Ratio = Math.log10(waistHipNeckRatio);
      bodyFatPercentage = 163.205 * log10Ratio - 97.684 * log10Height - 78.387;
      bodyFatPercentage = bodyFatPercentage - 25.89;
    }

    setBodyFat(bodyFatPercentage.toFixed(2)); // Assuming 2 decimal places
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={2} style={{ padding: "20px", marginTop: "20px" }}>
        <SuiTypography variant="h4" mb={2}>
          Body Fat Calculator
        </SuiTypography>
        <form>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Height (in cm)
              </SuiTypography>
              <SuiInput
                type="number"
                name="height"
                placeholder="Enter height"
                value={height}
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Weight (in Kgs)
              </SuiTypography>
              <SuiInput
                type="number"
                name="weight"
                placeholder="Enter weight"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Age
              </SuiTypography>
              <SuiInput
                type="number"
                name="age"
                placeholder="Enter age"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Neck (in cm)
              </SuiTypography>
              <SuiInput
                type="number"
                name="neck"
                placeholder="Enter neck size"
                value={neck}
                onChange={(e) => {
                  setNeck(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Waist (in cm)
              </SuiTypography>
              <SuiInput
                type="number"
                name="waist"
                placeholder="Enter waist size"
                value={waist}
                onChange={(e) => {
                  setWaist(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Gender
              </SuiTypography>
              <Createable
                options={genderOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setGender(e);
                }}
                value={gender}
              />
            </Grid>

            {!(gender?.value === "Male") ? (
              <Grid item xs={12} sm={6}>
                <SuiTypography mb={1} variant="h6">
                  Hip
                </SuiTypography>

                <SuiInput
                  type="number"
                  name="hip"
                  placeholder="Enter waist size"
                  value={hip}
                  onChange={(e) => {
                    setHip(e.target.value);
                  }}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <SuiButton
            fullWidth
            variant="contained"
            color="dark"
            onClick={calculateBodyFat}
            style={{ padding: "10px 0" }}
          >
            Calculate Body Fat
          </SuiButton>
        </form>
        {bodyFat !== null && (
          <SuiTypography variant="h6" style={{ marginTop: "20px" }}>
            Estimated Body Fat: {bodyFat}%
          </SuiTypography>
        )}
      </Paper>
    </Container>
  );
};

export default BodyFatCalculator;
