import { useState } from 'react'
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { timestampConversion } from 'utils/common'
import moment from 'moment'
import { updateRedeems } from 'store/features/authSlice'


function ReferralEarning({ title, description, info, uid, dispatch }) {

    const totalAmount = () => {
        let amount = 0
        info.map((item) => {
            if (!item.redeemed) {
                amount += item.amount
            }
            return null;
        })
        return amount
    }

    const [loader, setLoader] = useState(false)
    const redeemHandler = async (index) => {
        setLoader(true)
        await dispatch(updateRedeems({ referrals: info, uid }))
        setLoader(false)
    }

    const getFormattedDate = (date) => {
        if (date) {
            const formattedDate = date.seconds
                ? moment(timestampConversion(date)).local()
                : moment(date).local();

            return formattedDate.format('MMMM Do YYYY');
        }
        return 'N/A';
    };

    const renderCard = () => {
        return info.length > 0 ?
            (
                <SuiBox
                    p={1}
                    style={{
                        border: '1px solid #D2D6DA',
                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05)',
                        borderRadius: '16px',
                        height: '200px',
                        overflowY: 'auto'
                    }}>

                    {
                        info.map((item, key) => (
                            <SuiBox key={key} display="flex" py={1}>
                                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                    {item.name}
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" color="text">
                                    &nbsp;(
                                    {getFormattedDate(item.date)} {item?.amount} Rs/-{' '}
                                    {!item?.redeemed ? (
                                        <span style={{ color: '#55900A' }}>Available</span>
                                    ) : (
                                        <span style={{ color: '#EA0606' }}>Redeemed</span>
                                    )}
                                    )
                                </SuiTypography>
                            </SuiBox>
                        ))
                    }
                </SuiBox>
            ) : (
                <SuiBox
                    p={3}
                    style={{
                        border: '1px solid #D2D6DA',
                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05)',
                        borderRadius: '16px',
                        height: '200px',
                        overflowY: 'auto'
                    }}>
                    <SuiTypography color="text" variant="caption" textTransform="capitalize">
                        No one has used your referral code yet, promote your referral code  and get 5% commission
                    </SuiTypography>
                </SuiBox>
            )
    };

    return (
        <Card sx={{ height: "100%" }}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </SuiTypography>
                <SuiTypography variant="h7" fontWeight="medium" textTransform="capitalize">
                    Rs {totalAmount()}/-
                </SuiTypography>
            </SuiBox>
            <SuiBox p={2}>
                <SuiTypography style={{ color: totalAmount() > 0 ? "#17C1E8" : 'grey', textDecoration: totalAmount() > 0 ? "underline" : null, cursor: "pointer" }}
                    variant="h7"
                    fontWeight="medium"
                    textTransform="capitalize"
                    onClick={totalAmount() > 0 ? redeemHandler : () => null}>
                    {loader ? 'Redeeming . . . ' : 'Redeem'}
                </SuiTypography>
                <SuiBox mb={2} lineHeight={1}>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                        {description}
                    </SuiTypography>
                </SuiBox>
                <SuiBox opacity={0.3}>
                    <Divider />
                </SuiBox>
                <SuiBox>
                    {renderCard()}
                </SuiBox>
            </SuiBox>
        </Card>
    );
}

export default ReferralEarning;