import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import Grid from "@mui/material/Grid";

import { calcPriceWT } from "utils/common";
import CustomButton from "containers/CustomButton";
import { EditPaymentConsultation } from "services/clientdetails";
import { updateFirstLetter } from "utils/helper";

function Modal({
  open,
  setSelectedClient,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  services,
  viewHandler,
  setEditConsultationClient,
  mode,
  employees,
}) {
  //for service options list
  const [serviceOptions, setServiceOptions] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);

  useEffect(() => {
    let serviceOptionsArray = services.map((item) => {
      return {
        label: item.serviceName,
        value: item.serviceName,
        totalAmount: calcPriceWT(item.sellingPriceWOT, item.gst),
        key: item.id,
      };
    });
    setServiceOptions(serviceOptionsArray);
  }, [services]);

  useEffect(() => {
    const employeeArray_ = employees?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: updateFirstLetter(item?.name),
        employeeName: item?.name,
        employeePhoneNumber: item?.phoneNumber,
        employeeEmail: item?.email,
      };
    });

    setEmployeeArray(employeeArray_);
  }, [employees]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      service: null,
      discount: 0,
      amountPaid: 0,
      paymentMode: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      service: false,
      discount: false,
      amountPaid: false,
      paymentMode: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        paymentMode: {
          value: selectedItem?.paymentMode,
          label: selectedItem?.paymentMode,
        },
        service: {
          value: selectedItem.serviceId,
          label: selectedItem.serviceName,
          totalAmount: selectedItem.totalAmount,
        },
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.service) {
      toast.error("Please select Service Taken field");
      setErrorMessage({
        ...errorMessage,
        service: true,
      });
      return;
    }

    if (formdata.discount === null || formdata.discount === "") {
      toast.error("Please fill Discount field");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata.amountPaid === null || formdata.amountPaid === "") {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (!formdata.paymentMode) {
      toast.error("Please select Payment Mode field");
      setErrorMessage({
        ...errorMessage,
        paymentMode: true,
      });
      return;
    }

    setLoader(true);
    try {
      let data;
      if (selectedItem) {
        data = await EditPaymentConsultation({ formdata, id: id });
        setSelectedClient((e) => {
          const data_ = e.map((ele) => {
            if (ele.serviceNo === data.id) {
              return {
                ...ele,
                ...data,
              };
            }
            return ele;
          });

          return [...data_];
        });
        toast.success("Successfully edited consultation service");
      }

      handleCloseModal();

      setTimeout(() => {
        setSelectedItem(null);
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 500);
    } catch (e) {
      setLoader(false);
      toast.error(e?.message ?? "Something went wrong retry");
    }
  };

  const [paymentOptions] = useState([
    { value: "Cash", label: "Cash", key: 1 },
    { value: "Online", label: "Online", key: 2 },
  ]);

  const calculateBalance = () => {
    if (formdata.totalAmount) {
      return (
        parseFloat(formdata.totalAmount) -
        parseFloat(formdata.discount ?? 0) -
        parseFloat(formdata.amountPaid)
      ).toFixed(2);
    }
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            {selectedItem ? "Edit" : "Add"} Service, Payment & Balance History{" "}
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            You can {selectedItem ? "edit" : "add"} details below.
          </SuiTypography>
        </SuiBox>

        <Grid item xs={12}>
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setEditConsultationClient(false);
                viewHandler(selectedItem, "consultation");
              }}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Taken
                </SuiTypography>
                <Createable
                  options={serviceOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      service: e,
                      totalAmount: e.totalAmount,
                    });
                  }}
                  value={formdata.service}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Total Amount
                </SuiTypography>
                <SuiTypography mb={1} variant="h7">
                  INR {formdata?.service?.totalAmount ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Discount (In INR)
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="discount"
                  error={errorMessage?.discount}
                  value={formdata.discount ?? ""}
                  onChange={(e) => {
                    if (formdata.service === null) {
                      toast.info("Select package");
                      return;
                    }

                    if (e.target.value < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        [e.target.name]: 0,
                      });
                      return;
                    }

                    if (e.target.value > parseFloat(formdata.totalAmount)) {
                      toast.info("Can' exceed total amount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="amountPaid"
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                INR{" "}
                {isNaN(calculateBalance()) ? "-" : calculateBalance() ?? "-"}
              </SuiTypography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Payment Mode
                </SuiTypography>
                <Createable
                  options={paymentOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      paymentMode: e,
                    });
                  }}
                  value={formdata.paymentMode}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={6}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Assign Dietitian
                </SuiTypography>
                <Createable
                  value={formdata?.assignDietitian}
                  options={employeeArray ?? []}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    zIndex: 999,
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose Employee Access"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      setFormdata({
                        ...formdata,
                        assignDietitian: options.map((opt) => {
                          return { value: opt.value, label: opt.label };
                        }),
                      });
                    }
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={selectedItem ? "Edit Data" : "Add Data"}
              loader={loader}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
