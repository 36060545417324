/**-------------diet and lifestyle assessment -------------- */

export const dietLifestyleAssessmentTableColumns = [
    { name: 'S.no', align: "left", width: "30px" },
    { name: "Date", align: "left", },
    { name: "Doctor Name", align: "left", minWidth: '130px' },
    { name: "BP", align: "left", minWidth: '120px' },
    { name: "Blood Sugar", align: "left", minWidth: '120px' },
    // { name: "PP", align: "left", minWidth: '120px' },
    // { name: "HBAIC", align: "left", minWidth: '120px' },
    // { name: "Cholestrol", align: "left", minWidth: '120px' },
    // { name: "HDL", align: "left", minWidth: '120px' },
    // { name: "LDL", align: "left", minWidth: '150px' },
    // { name: "VLDL", align: "left", minWidth: '120px' },
    // { name: "SGOT", align: "left", minWidth: '120px' },
    // { name: "SGPT", align: "left", minWidth: '120px' },
    // { name: "HB", align: "left", minWidth: '120px' },
    // { name: "Vitamin", align: "left", minWidth: '120px' },
    // { name: "D3", align: "left", minWidth: '120px' },
    // { name: "Vitamin B12", align: "left", minWidth: '120px' },
    // { name: "Calcium", align: "left", minWidth: '120px' },
    // { name: "Uric Acid", align: "left", minWidth: '120px' },
    // { name: "Last Period Date", align: "left", minWidth: '150px' },
    { name: "Action", align: "left", minWidth: '120px' },
]


/**----------------------- biochemical ---------------------------- */

export const biochemicalTableColumns = [
    { name: 'S.no', align: "left", width: "30px" },
    { name: "Date", align: "left", },
    { name: "Ongoing Medication", align: "left", minWidth: '170px' },
    { name: "Operated?", align: "left", minWidth: '160px' },
    // { name: "Allergic From", align: "left", minWidth: '170px' },
    // { name: "Suffering From", align: "left", minWidth: '170px' },
    // { name: "Duration & Medication", align: "left", minWidth: '170px' },
    // { name: "Rashes or Allergies on Skin?", align: "left", minWidth: '200px' },
    // { name: "Obesity Runs in Family?", align: "left", minWidth: '200px' },
    // { name: "Any Other Condition?", align: "left", minWidth: '180px' },
    { name: "Action", align: "left", minWidth: '120px' },
]

/**----------------------- daily intake form ---------------------------- */

export const dailyIntakeAndRecallTableColumns = [
    { name: "Date/Time (Added)", align: "left", minWidth: "180px" },
    { name: "Follows special diet?", align: "left", minWidth: "158px" },
    { name: "Frequency Of Eating", align: "left", minWidth: "150px" },
    { name: "Water Intake", align: "left", minWidth: "107px" },
    // { name: "Early Morning", align: "left", minWidth: "200px" },
    // { name: "Breakfast", align: "left", minWidth: "200px" },
    // { name: "Mid Morning", align: "left", minWidth: "200px" },
    // { name: "Brunch", align: "left", minWidth: "200px" },
    // { name: "Mid Meal", align: "left", minWidth: "200px" },
    // { name: "Lunch", align: "left", minWidth: "200px" },
    // { name: "Post Lunch", align: "left", minWidth: "200px" },
    // { name: "Evening", align: "left", minWidth: "200px" },
    // { name: "Before Dinner", align: "left", minWidth: "200px" },
    // { name: "Dinner", align: "left", minWidth: "200px" },
    // { name: "Post Dinner", align: "left", minWidth: "200px" },
    // { name: "Late Night", align: "left", minWidth: "200px" },
    { name: "Action", align: "left", minWidth: '100px' },
]


/**-------------------- measurement ------------------- */

export const measurementsTableColumns = [
    { name: 'S.no', align: "left", width: "30px" },
    { name: "Date", align: "left", },
    { name: "Weight", align: "left", minWidth: '70px' },
    // { name: "Neck", align: "left", minWidth: '70px' },
    { name: "Chest", align: "left", minWidth: '70px' },
    { name: "Tummy", align: "left", minWidth: '70px' },
    { name: "Waist", align: "left", minWidth: '70px' },
    { name: "Hip", align: "left", minWidth: '70px' },
    // { name: "Thigh", align: "left", minWidth: '70px' },
    // { name: "Arm", align: "left", minWidth: '120px' },
    // { name: "Weight (kgs)", align: "left", minWidth: '120px' },
    // { name: "Height (cm)", align: "left", minWidth: '120px' },
    // { name: "Consultant Comments", align: "left", minWidth: '190px' },
    // { name: "Client Comments", align: "left", minWidth: '190px' },
    { name: "Action", align: "left", minWidth: '120px' },
]

export const basicMeasurementKeys = [
    { value: 'neck', color: 'primary' },
    { value: 'chest', color: 'dark' },
    { value: 'tummy', color: 'success' },
    { value: 'waist', color: 'info' },
    { value: 'hip', color: 'warning' },
    { value: 'weight', color: 'error' },
    { value: 'thigh', color: 'black' },
    { value: 'height', color: 'text' },
    { value: 'arm', color: 'light' }
]

/**--------------------------- physical activity -------------------- */

export const physicalActivityTableColumns = [
    { name: 'S.no', align: "left", width: "30px"},
    { name: "Date", align: "left", },
    { name: "Current Lifestyle", align: "left", minWidth: '140px' },
    // { name: "Physical Activities", align: "left", minWidth: '150px' },
    // { name: "Gym Routine", align: "left", minWidth: '200px' },
    // { name: "Smoke Cigarettes", align: "left", minWidth: '150px' },
    // { name: "Chew Tobacco", align: "left", minWidth: '150px' },
    { name: "Alcohol", align: "left",  minWidth: '110px' },
    // { name: "Awake in Late Night", align: "left", minWidth: '200px'},
    // { name: "Snack You Eat Late Night", align: "left", minWidth: '200px'},
    { name: "Hours of Sleep", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: '100px' },
] 

/*----------------------- vitals -----------------------*/

export const vitalsTableColumns = [
    { name: 'S.no', align: "left", width: "30px" },
    { name: "Date", align: "left", },
    { name: "Fat (%)", align: "left", minWidth: '70px' },
    { name: "VFat (%)", align: "left", minWidth: '70px' },
    { name: "Muscle Mass (%)", align: "left", minWidth: '120px' },
    { name: "Body Age", align: "left", minWidth: '70px' },
    // { name: "RM", align: "left", minWidth: '70px' },
    // { name: "BMI", align: "left", minWidth: '120px' },
    // { name: "Consultant Comments", align: "left", minWidth: '190px' },
    // { name: "Client Comments", align: "left", minWidth: '190px' },
    { name: "Action", align: "left", minWidth: '120px' },

]

export const basicVitalKeys =  [
    { value: 'fat', color: 'primary' },
    { value: 'vfat', color: 'dark' },
    { value: 'muscleMass', color: 'success' },
    { value: 'bodyAge', color: 'info' },
    { value: 'rm', color: 'warning' },
    { value: 'bmi', color: 'error' },
]


/**------------------------ products ------------------------- */

export const productTableColumns = [
    { name: 'S.no', align: "left", width: "30px" },
    { name: "Purchased (Date/Time)", align: "left", },
    { name: "Product", align: "left", minWidth: '150px' },
    { name: "MRP", align: "left", minWidth: '70px' },
    { name: "Selling Price WOT", align: "left", minWidth: '150px' },
    // { name: "Discount (INR)", align: "left", minWidth: '120px' },
    { name: "GST(%)", align: "left", minWidth: '60px' },
    { name: "Selling Price WT", align: "left", minWidth: '150px' },
    // { name: "Quantity", align: "left", minWidth: '80px' },
    // { name: "Amount Paid", align: "left", minWidth: '120px' },
    // { name: "Balance", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: '120px' },
]