
import React, { useState } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

import { toast } from 'react-toastify'
import Createable from 'containers/Select'
import Grid from "@mui/material/Grid";
import CustomButton from 'containers/CustomButton'
import SwipableModal from 'containers/Customer/modal';
import { updatePlanInfo } from "services/customer";


function AddPlanModal({ open, clientId, setOpenModal, serviceOptions,
    setStepVal, stepVal, handleCloseModal, setClientData, }) {

    const [formdata, setFormdata] = useState({
        package: null,
        discount: 0,
        totalAmount: null,
        amountPaid: 0,
        serviceInstance: null,
        paymentMode: null,
        startDate: null,
        completionDate: null,
        complementaryService: null,
    })

    const [errorMessage, setErrorMessage] = useState({
        package: false,

        discount: false,
        amountPaid: false,

        serviceInstance: false,
        paymentMode: false,
        startDate: false,
        completionDate: false,

        complementaryService: false,
    })

    const calculateBalance = () => {
        if (formdata.totalAmount) {
            return (parseFloat(formdata.totalAmount) - parseFloat(formdata.discount ?? 0) - parseFloat(formdata.amountPaid)).toFixed(2)
        }
    }

    //for submitting data
    const [loader, setLoader] = useState(false)

    const submitHandler = async (e) => {

        if (!formdata.package) {
            toast.error("Please select Choose Package field")
            setErrorMessage({
                ...errorMessage,
                package: true
            })
            return;
        }

        if (formdata.discount === null || formdata.discount === "") {
            toast.error("Please fill Discount field")
            setErrorMessage({
                ...errorMessage,
                discount: true
            })
            return;
        }

        if (formdata.discount < 0) {
            toast.info("Discount value can't be negative")
            setErrorMessage({
                ...errorMessage,
                discount: true
            })
            return
        }

        if (formdata.amountPaid === null || formdata.amountPaid === "") {
            toast.error("Please fill Amount Paid field")
            setErrorMessage({
                ...errorMessage,
                amountPaid: true
            })
            return;
        }

        if (formdata.amountPaid < 0) {
            toast.info("Amount Paid value can't be negative")
            setErrorMessage({
                ...errorMessage,
                amountPaid: true
            })
            return;
        }

        if (!formdata.serviceInstance) {
            toast.error("Please select Service Instance field")
            setErrorMessage({
                ...errorMessage,
                serviceInstance: true
            })
            return;
        }

        if (!formdata.paymentMode) {
            toast.error("Please select Payment Mode field")
            setErrorMessage({
                ...errorMessage,
                paymentMode: true
            })
            return;
        }

        if (!formdata.startDate) {
            toast.error("Please fill Start Date field")
            setErrorMessage({
                ...errorMessage,
                startDate: true
            })
            return;
        }

        if (!formdata.completionDate) {
            toast.error("Please fill Completion Date field")
            setErrorMessage({
                ...errorMessage,
                completionDate: true
            })
            return;
        }

        if (!formdata.complementaryService) {
            toast.error("Please fill Complementary Service field")
            setErrorMessage({
                ...errorMessage,
                complementaryService: true
            })
            return;
        }

        if (new Date(formdata.startDate) > new Date(formdata.completionDate)) {
            toast.info("Completion Date should be greater than Start Date")
            setErrorMessage({
                ...errorMessage,
                completionDate: true
            })
            return;
        }

        try {
            setLoader(true)
            const data = await updatePlanInfo(formdata, clientId, stepVal, 2)
            setClientData((e) => {
                e = { ...e, services: [{...data}] }
                return e
            })
            toast.success("Successfully updated")
            setStepVal((e) => {
                e[2] = 1;
                return e;
            })
            handleCloseModal()
            setLoader(false)
        } catch (error) {
            console.log(error)
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    const [serviceInstanceOptions,] = useState([
        { value: 'First Time', label: 'First Time', key: 1 },
        { value: 'Extension', label: 'Extension', key: 2 },
        { value: 'Returning', label: 'Returning', key: 3 }
    ])

    const [paymentOptions,] = useState([
        { value: 'Cash', label: 'Cash', key: 1 },
        { value: 'Online', label: 'Online', key: 2 }
    ])


    return (
        <SwipableModal
            open={open}
            setOpenModal={setOpenModal}
            handleCloseModal={handleCloseModal}
            title="Update profile"
            subtitle="You can add your details below.">

            <SuiBox pt={1.25} pb={3} px={3}>
                <SuiBox mt={1}>
                    <SuiBox mt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Choose Package</SuiTypography>
                                    <Createable
                                        options={serviceOptions}
                                        style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                        inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                        onChange={(e) => {
                                            setFormdata({
                                                ...formdata,
                                                'package': e,
                                                totalAmount: e.totalAmount
                                            })
                                        }}
                                        value={formdata?.package} />
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Discount Amount</SuiTypography>
                                    <SuiInput
                                        type="number"
                                        name="discount"
                                        error={errorMessage?.discount}
                                        value={formdata?.discount ?? ''}
                                        onChange={(e) => {
                                            let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                                            let val = e.target.value
                                            let match_ = val.match(numberRegex)
                                            if (!match_ && val) {
                                                return false
                                            }
                                            if (formdata.package === null) {
                                                toast.info('Select package')
                                                return
                                            }

                                            if (e.target.value > parseFloat(formdata.totalAmount)) {
                                                toast.info("Can' exceed total amount")
                                                return
                                            }

                                            setErrorMessage({
                                                ...errorMessage,
                                                [e.target.name]: false
                                            })
                                            setFormdata({
                                                ...formdata,
                                                [e.target.name]: e.target.value
                                            })
                                        }} />
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Total Amount</SuiTypography>
                                    <SuiTypography mb={1} variant="h7">INR {formdata?.package?.totalAmount ?? '-'}</SuiTypography>
                                </SuiBox>
                            </Grid>
                        </Grid>
                    </SuiBox>

                    <SuiBox mt={3}>
                        <Grid container spacing={3}>



                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Amount Paid</SuiTypography>
                                    <SuiInput
                                        type="number"
                                        name="amountPaid"
                                        error={errorMessage?.amountPaid}
                                        value={formdata.amountPaid ?? ''}
                                        onChange={(e) => {
                                            let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                                            let val = e.target.value
                                            let match_ = val.match(numberRegex)
                                            if (!match_ && val) {
                                                return false
                                            }
                                            if (formdata.package === null) {
                                                toast.info('Select package')
                                                return
                                            }


                                            if (e.target.value > parseFloat(formdata.totalAmount) - parseFloat(formdata.discount ?? 0)) {
                                                toast.info("Can' exceed total amount after discount")
                                                return
                                            }

                                            setErrorMessage({
                                                ...errorMessage,
                                                [e.target.name]: false
                                            })
                                            setFormdata({
                                                ...formdata,
                                                [e.target.name]: e.target.value
                                            })
                                        }} />
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Payment Mode</SuiTypography>
                                    <Createable
                                        options={paymentOptions}
                                        style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                        inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                        onChange={(e) => {
                                            setFormdata({
                                                ...formdata,
                                                'paymentMode': e
                                            })
                                        }}
                                        value={formdata.paymentMode} />
                                </SuiBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Balance</SuiTypography>
                                    <SuiTypography mb={1} variant="h7" style={{ color: 'red' }}>INR {isNaN(calculateBalance()) ? '-' : calculateBalance() ?? '-'}</SuiTypography>
                                </SuiBox>
                            </Grid>
                        </Grid>
                    </SuiBox>


                    <SuiBox mt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Service Instance</SuiTypography>
                                    <Createable
                                        options={serviceInstanceOptions}
                                        style={{ borderRadius: '0.5rem', fontSize: 14 }}
                                        inputStyle={{ width: 190, height: '1.850rem', borderRadius: '0.5rem' }}
                                        onChange={(e) => {
                                            setFormdata({
                                                ...formdata,
                                                'serviceInstance': e
                                            })
                                        }}
                                        value={formdata.serviceInstance} />
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Start Date</SuiTypography>
                                    <SuiInput
                                        type="date"
                                        name="startDate"
                                        error={errorMessage?.startDate}
                                        value={formdata.startDate ?? ''}
                                        onChange={(e) => {
                                            setErrorMessage({
                                                ...errorMessage,
                                                [e.target.name]: false
                                            })
                                            setFormdata({
                                                ...formdata,
                                                [e.target.name]: e.target.value
                                            })
                                        }} />
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <SuiBox lineHeight={1}>
                                    <SuiTypography mb={1} variant="h6">Completion Date</SuiTypography>
                                    <SuiInput
                                        type="date"
                                        name="completionDate"
                                        error={errorMessage?.completionDate}
                                        value={formdata.completionDate ?? ''}
                                        onChange={(e) => {

                                            if (!formdata.startDate) {
                                                toast.info("First fill start date")
                                                return
                                            }

                                            if (new Date(formdata.startDate) > new Date(e.target.value)) {
                                                toast.info("Completion date should be greater than start date")
                                                return
                                            }
                                            setErrorMessage({
                                                ...errorMessage,
                                                [e.target.name]: false
                                            })
                                            setFormdata({
                                                ...formdata,
                                                [e.target.name]: e.target.value
                                            })
                                        }} />
                                </SuiBox>
                            </Grid>
                        </Grid>
                    </SuiBox>

                    <SuiBox mt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SuiBox>
                                    <SuiTypography mb={1} variant="h6">Complementary Service?</SuiTypography>
                                    <SuiInput
                                        type="text"
                                        name="complementaryService"
                                        placeholder="Some other service ..."
                                        rows={6}
                                        multiline
                                        error={errorMessage?.complementaryService}
                                        value={formdata.complementaryService ?? ''}
                                        onChange={(e) => {
                                            setErrorMessage({
                                                ...errorMessage,
                                                [e.target.name]: false
                                            })
                                            setFormdata({
                                                ...formdata,
                                                [e.target.name]: e.target.value
                                            })
                                        }} />
                                </SuiBox>
                            </Grid>
                        </Grid>
                    </SuiBox>
                </SuiBox>

                <SuiBox mt={3} mb={2} display="flex" justifyContent="center">

                    <CustomButton
                        color="dark"
                        variant="gradient"
                        onClick={submitHandler}
                        fullWidth
                        disabled={loader || !open}
                        title={'Edit Client'}
                        loader={loader}
                    />
                </SuiBox>
            </SuiBox>
        </SwipableModal>
    )
}

export default AddPlanModal;