import SuiTypography from "components/SuiTypography";
import { CustomWidthTooltip } from "containers/Tooltip";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

export const employeeRow = (
  employeeArray,
  editHandler,
  deleteHandler,
  viewHandler
) => {
  const rows = employeeArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Added)": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item.createdAt)}
        </SuiTypography>
      ),
      "Date/Time (Modified)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.updatedAt ? globaleDateTimeFormatter(item?.updatedAt) : "N/A"}
        </SuiTypography>
      ),

      "Employee Name": (
        <CustomWidthTooltip title={item?.name ?? ""} placement="bottom">
          <SuiTypography
            variant="button"
            fontWeight="medium"
            style={{ textTransform: "capitalize" }}
          >
            {item?.name && item?.name?.length > 20
              ? `${item?.name?.slice(0, 20)}...`
              : item?.name}
          </SuiTypography>
        </CustomWidthTooltip>
      ),

      "Job Role": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ textTransform: "capitalize" }}
        >
          {item?.current_role === "part" ? "Part Time" : "Full Time"}
        </SuiTypography>
      ),

      Email: (
        <CustomWidthTooltip title={item?.email ?? ""} placement="bottom">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.email?.length > 40
              ? `${item?.email?.slice(0, 40)}...`
              : item?.email}
          </SuiTypography>
        </CustomWidthTooltip>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item.id, item.name)}
        />
      ),
    };
  });
  return rows;
};
