import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
// import { useCallback } from 'react'
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable, TableSortLabel } from "@mui/material";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TablePagination from "@mui/material/TablePagination";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, width: "100%" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Table({
  columns,
  rows,
  bgColor,
  color,
  maxRows,
  searchFlag,
  handleSort,
  sortConfig,
  bgBlue,
}) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(
    ({ name, align, width, minWidth, sort, sortKey }, key) => {
      let pl;
      let pr;

      if (key === 0 || key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      const isSortable = sort !== undefined;

      return (
        <SuiBox
          key={name}
          component="th"
          width={width || "auto"}
          minWidth={minWidth ?? 0}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {isSortable ? (
            <TableSortLabel
              active={isSortable}
              direction={
                sortConfig.key === name ? sortConfig.direction : "desc"
              }
              onClick={() => handleSort(name, sortKey)}
              sx={{
                color: "inherit !important",
                "& svg": {
                  width: "1.3em !important",
                  height: "1.3em !important",
                  color: "inherit !important",
                },
              }}
            >
              {name.toUpperCase()}
            </TableSortLabel>
          ) : (
            name.toUpperCase()
          )}
        </SuiBox>
      );
    }
  );

  const rootRef = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(maxRows ?? 15);

  const [renderRows, setRenderRows] = useState([]);
  useEffect(() => {
    const renderRows_ = rows.map((row, key) => {
      const rowKey = `row-${key}`;

      const tableRow = columns.map(({ name, align }) => {
        let template;

        if (Array.isArray(row[name])) {
          template = (
            <SuiBox
              key={uuidv4()}
              style={{ color: "white" }}
              component="td"
              p={1}
              borderBottom={
                row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
              }
            >
              <SuiBox display="flex" alignItems="center" py={0.5} px={1}>
                <SuiBox mr={2}>
                  <SuiAvatar
                    src={row[name][0]}
                    name={row[name][1]}
                    variant="rounded"
                    size="sm"
                  />
                </SuiBox>
                <SuiTypography
                  variant="button"
                  fontWeight="medium"
                  sx={{ width: "max-content" }}
                >
                  {row[name][1]}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          );
        } else {
          template = (
            <SuiBox
              key={uuidv4()}
              component="td"
              p={1}
              textAlign={align}
              borderBottom={
                row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
              }
            >
              <SuiTypography
                variant="button"
                fontWeight="regular"
                color="primary"
                sx={{
                  display: "inline-block",
                  color: "white",
                  width: "max-content",
                }}
              >
                {row[name]}
              </SuiTypography>
            </SuiBox>
          );
        }

        return template;
      });

      return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });
    setRenderRows(renderRows_);
  }, [rows, borderWidth, columns, light.main]);

  useEffect(() => {
    setRowsPerPage(maxRows ?? 15);
  }, [rows, setPage, setRowsPerPage, maxRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const checkPage = useCallback(() => {
    if (rows.length <= page * rowsPerPage) {
      setPage(0);
    }
  }, [rows.length, page, rowsPerPage]);

  useEffect(() => {
    checkPage();
  }, [rows.length, checkPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return useMemo(
    () => (
      <>
        <TableContainer
          ref={rootRef}
          id="tableId"
          style={{ background: bgColor }}
          sx={{ maxHeight: 500, borderRadius: 0 }}
        >
          <MuiTable
            stickyHeader
            aria-label="tableId"
            style={{ background: bgColor }}
          >
            <SuiBox
              component="thead"
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 1,
              }}
            >
              <TableRow>{renderColumns}</TableRow>
            </SuiBox>
            <TableBody>
              {renderRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        {renderRows.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[10]}
            count={renderRows.length}
            component="div"
            style={{
              color: color ?? "black",
              display: "inline-flex",
              background: bgBlue ? "inherit" : "white",
              width: "100%",
            }}
            showFirstButton
            showLastButton
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    ),
    [renderColumns, renderRows, rowsPerPage, page, bgColor, color]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
