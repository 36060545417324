import React, { useState, useEffect, useCallback } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import VitalChart from "containers/Chart";
import moment from "moment";
import { timestampConversion } from "utils/common";
import { updateClientData } from "services/customer";
import CustomButton from "containers/CustomButton";
import { toast } from "react-toastify";
import { globalStyles } from "styles";
import { vitalRows } from "containers/clientDetails/sub-components/common/vitalRows";
import SwipableModal from "containers/Customer/modal";
import VitalModal from "containers/clientDetails/sub-components/modal/vital";
import { vitalsTableColumns } from "utils/tablesHeaderContent";
import { basicVitalKeys } from "utils/tablesHeaderContent";
import { headerDesc } from "utils/helper";

function VitalDataVisualizer({ clientId, vitals, deleteHandler }) {
  const [vitalData, setVitalData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([...vitalsTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [chartSelected, setChartSelected] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("view");

  const data = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  };

  useEffect(() => {
    if (vitals.length === 0) {
      setDisableButton(false);
      const newColumn = { name: "Action", align: "left", minWidth: "120px" };
    } else {
      setDisableButton(true);
    }
    setVitalData(vitals);
  }, [vitals]);

  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("edit");
    setOpenAddModal(true);
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("view");
    setOpenAddModal(true);
  }, []);

  useEffect(() => {
    setRows(vitalRows(vitalData, editHandler, viewHandler, deleteHandler));
  }, [vitalData, editHandler, viewHandler]);

  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    let vitalObj = {};

    const labelArray = [...basicVitalKeys];

    labelArray.forEach((item) => {
      vitalObj[item.value] = {
        label: item.value,
        color: item.color,
        data: [],
      };
    });

    let labels = [];

    let vitalArray = vitalData.slice();

    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    vitalArray.sort(function (a, b) {
      const createdAt1 = convertTimestamp(a?.timestamp);
      const createdAt2 = convertTimestamp(b?.timestamp);
      return createdAt1 - createdAt2;
    });

    vitalArray.forEach((item) => {
      const timestamp = convertTimestamp(item.timestamp);
      const formattedTimestamp = moment.utc(timestamp).format("DD/MM/YYYY");
      labels.push(formattedTimestamp);

      labelArray.forEach((label) => {
        if (item[label.value]) {
          vitalObj[label.value]["data"].push(
            parseFloat(item[label.value] ?? 0)
          );
        }
      });
    });

    setChartData({
      labels: labels,
      datasets: Object.keys(vitalObj).map((key) => {
        return {
          label: key,
          color: vitalObj[key]["color"],
          data: vitalObj[key]["data"],
        };
      }),
    });
  }, [vitalData]);

  const addHandler = () => {
    setMode("create");
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "vital";
      await updateClientData(clientId, fieldKey, vitalData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <SuiBox overflow="hidden" minWidth="250px">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        wrap
        mb={2}
      >
        <SuiTypography variant="h5" style={{ color: "#17C1E8" }}>
          Vital
        </SuiTypography>
        <SuiBox display="flex" wrap={true}>
          {rows.length > 0 ? (
            <SuiBox mr={2} style={styles.iconContainer}>
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => {
                  setChartSelected(false);
                }}
              >
                <Icon className={"text-white"}>table_rows</Icon>
              </IconButton>
              |
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => {
                  setChartSelected(true);
                }}
              >
                <Icon className={"text-white"}>show_chart</Icon>
              </IconButton>
            </SuiBox>
          ) : (
            <div />
          )}
          {
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addHandler}
            >
              Add Data
            </SuiButton>
          }
        </SuiBox>
      </SuiBox>
      <SuiBox sx={globalStyles.tableOverflow}>
        {chartSelected ? (
          <VitalChart
            data={chartData}
            title="Vitals"
            desc="Your vital comparison at every instance"
          />
        ) : rows.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>

      {rows.length > 0 ? (
        <SuiBox style={styles.btn}>
          <CustomButton
            color="dark"
            variant="gradient"
            onClick={submitHandler}
            fullWidth
            disabled={loader}
            loader={loader}
            title={"Save and continue"}
          />
        </SuiBox>
      ) : null}

      <SwipableModal
        open={openAddModal}
        setOpenModal={setOpenAddModal}
        handleCloseModal={handleCloseModal}
        mode={mode}
        setMode={setMode}
        {...headerDesc(mode, "Vital details", selectedData)}
      >
        <VitalModal
          id={clientId}
          persona="client"
          mode={mode}
          open={openAddModal}
          selectedItem={selectedData}
          setMainData={setVitalData}
          handleCloseModal={handleCloseModal}
        />
      </SwipableModal>
    </SuiBox>
  );
}

export default VitalDataVisualizer;

/**------------------------ styling ----------------------- */

const styles = {
  btn: {
    position: "sticky",
    width: "100%",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "end",
    marginTop: 40,
    bottom: 0,
  },

  iconContainer: {
    background: "#DBE2F0",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
  },
};
