import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import NoData from "containers/DataNotAvailBox";
import { employeeRow } from "./rows";
import { TailSpin } from "react-loader-spinner";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import moment from "moment";
import { timestampConversion } from "utils/common";

const InputFile = styled("input")({
  display: "none",
});

function EmployeeTable({
  employees,
  adminId,
  title,
  isSuccess,
  isFetching,
  loader,
  addHandler,
  editHandler,
  deleteHandler,
  viewHandler,
}) {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [employeeArray, setEmployeesArray] = useState([]);
  const [searchVal, setsearchVal] = useState("");

  useEffect(() => {
    if (!searchVal) {
      setEmployeesArray(employees);
    } else {
      let filteredEmployee = employees.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setEmployeesArray((e) => filteredEmployee);
    }
  }, [searchVal, isSuccess, isFetching, employees]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time (Added)", align: "left" },
    { name: "Date/Time (Modified)", align: "left" },
    { name: "Employee Name", align: "left", minWidth: "100px" },
    { name: "Job Role", align: "left", minWidth: "100px" },
    { name: "Email", align: "left", minWidth: "100px" },
    { name: "Action", align: "left", minWidth: "110px" },
  ];

  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };

    let employeesArray = employeeArray.slice();

    employeesArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.createdAt);
      const b_timestamp = convertTimestamp(b?.createdAt);
      return b_timestamp - a_timestamp;
    });

    setRows(
      employeeRow(employeesArray, editHandler, deleteHandler, viewHandler)
    );
  }, [employeeArray, editHandler, deleteHandler, viewHandler]);

  useEffect(() => {
    setEmployeesArray(employees);
  }, [employees]);

  return (
    <>
      <SuiBox mb={3}>
        <Card style={{ overflow: "inherit" }}>
          <SuiBox
            className="search-div"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
            p={3}
            pb={0}
          >
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color={"dark"}
              sx={{ mb: 0.75 }}
            >
              Below are the employees you have generated.
            </SuiTypography>
            <SuiBox
              className="search-box-div"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              <Searchbar
                placeholder={"Search Employee Name"}
                searchVal={searchVal}
                searchHandler={(e) => {
                  setsearchVal((_) => e);
                }}
                style_={{ marginRight: 25, marginBottom: 10 }}
                clearHandler={() => {
                  setsearchVal("");
                }}
              />

              <SuiBox mr={1}>
                <SuiButton
                  component={Button}
                  color="dark"
                  variant="gradient"
                  onClick={addHandler}
                  width={180}
                  style={styles.addEmployeeButton}
                >
                  Add New Employee
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          {/* <EmployeeSearch
            setFilterQuery={setFilterQuery}
            filterHandler={filterHandler}
            filterQuery={filterQuery}
            resetHandler={resetHandler}
            searchTags={searchTags}
          /> */}

          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {loader ? (
              <SuiBox
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <TailSpin color="#17c1e8" height={60} width={60} />
              </SuiBox>
            ) : rows.length ? (
              <Table columns={columns} rows={rows} maxRows={8} />
            ) : (
              <NoData />
            )}
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default EmployeeTable;

/**-----------styles------------- */

const styles = {
  addEmployeeButton: {
    marginRight: 25,
    marginBottom: 10,
    height: 40,
    width: 230,
    color: "#3A416F",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
