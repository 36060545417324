import React, { useState, useMemo } from 'react'
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiInput from "components/SuiInput";
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'
import { uploadCustomDietChart } from 'services/clientdetails'
import CustomButton from 'containers/CustomButton';


const InputFile = styled('input')({
    display: 'none',
});


function UploadBar({ uid, loader, setLoader, setSelectedClient }) {

    const defaultHandler = useMemo(() => { 
        return {
            dietPdf: null,
            date: null,
            description: null
        }}, []);

    const errorHandler = {
        dietPdf: false,
        date: false,
        description: false,
    }

    const [ formdata, setFormdata ] = useState({ ...defaultHandler })
    const [ errorMessage, setErrorMessage ] = useState({ ...errorHandler })


    //for taking diet pdf from system
    function handleDietPdf(evt){

        let tgt = evt.target,
        files = tgt.files;

        setFormdata({
            ...formdata,
            [evt.target.name]: files[0],
        })
    }


    //for submitting data
    const submitHandler = async (e) => {
        if(!formdata.dietPdf){
            toast.error("Please Choose the Diet Chart PDF field")
            setErrorMessage({
                ...errorMessage,
                pdf: true
            })
            return;
        }

        if(!formdata.date){
            toast.error("Please fill Date field")
            setErrorMessage({
                ...errorMessage,
                date: true
            })
            return;
        }

        if(!formdata.description){
            toast.error("Please fill description field")
            setErrorMessage({
                ...errorMessage,
                description: true
            })
            return;
        }

        try{
            setLoader(true)
            const data = await uploadCustomDietChart({ formdata, uid })
            setSelectedClient((ee) => {
                ee['customDietPdf'] = [ ...data ]
                return { ...ee }
            })
            setFormdata({ ...defaultHandler })
            toast.success("Successfully submitted")
            setLoader(false)
        } catch(e){
            setLoader(false)
            toast.error(e?.message??"Something went wrong, retry")
        }
    }

        
    return (
        <SuiBox px={3} display="flex" justifyContent="space-between"  flexWrap="wrap" alignItems="flex-end">

            <SuiBox display="flex" flexWrap="wrap" alignItems="flex-end">
                <SuiBox mr={1} position="relative" my={1}>
                    <label htmlFor="dietPdf-1" style={{ cursor: 'pointer' }}>
                        <InputFile
                            id="dietPdf-1" 
                            type="file"
                            name="dietPdf"
                            accept="application/pdf, application/vnd.ms-excel"
                            onChange={handleDietPdf}/>
                        <SuiBox  pr={2}>
                            <SuiTypography mb={1} variant="h6">Choose Diet Chart PDF</SuiTypography>

                            <SuiAvatar
                                alt="pdf"
                                style={{width:222,height:40,borderRadius:8,color:"#3A416F",fontSize:"12px",border:"1px solid #D2D6DA"}}
                            >Choose Diet Chart </SuiAvatar>

                        </SuiBox>
                        {formdata.dietPdf && 
                                <SuiTypography position="absolute" mt={1} variant="caption">({formdata.dietPdf?.name})</SuiTypography>}
                    </label>

                </SuiBox>

                <SuiBox mr={3} width={200} my={1}>
                    <SuiTypography mb={1} variant="h6">Diet Chart Date</SuiTypography>
                    <SuiInput 
                        type="date" 
                        name="date"
                        error={errorMessage?.date}
                        value={formdata.date??''}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }} />
                </SuiBox>

                <SuiBox width={300} my={1}>
                    <SuiInput 
                        type="text" 
                        name="description"
                        placeholder="Something about this diet chart"
                        error={errorMessage?.description}
                        value={formdata.description??''}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }} />
                </SuiBox>
            </SuiBox>      

            <SuiBox mb={1}>
                <CustomButton
                    color="dark"
                    variant="gradient"
                    onClick={submitHandler}
                    fullWidth
                    width={180}
                    disabled={loader}
                    title={'Upload Diet Chart'}
                    loader={loader}
                />
            </SuiBox>
        </SuiBox>
    )
}


export default UploadBar
