import React, { useState } from "react";
import { Container, Paper, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Createable from "containers/Select";
import { genderOptions } from "constant";
import { activitiesOptions } from "constant";
import { toast } from "react-toastify";

const MacroCalculator = () => {
  const [weight, setWeight] = useState(65);
  const [height, setHeight] = useState(180);
  const [age, setAge] = useState(25);
  const [gender, setGender] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [macros, setMacros] = useState(null);

  function calculateDailyCalorieNeeds(bmr, activityLevel) {
    switch (activityLevel) {
      case 1:
        return bmr * 1.2;
      case 2:
        return bmr * 1.375;
      case 3:
        return bmr * 1.55;
      case 4:
        return bmr * 1.725;
      case 5:
        return bmr * 1.9;
      default:
        return bmr;
    }
  }

  const calculateMacros = () => {
    if (!weight || !height || !age || !gender || !activityLevel) {
      toast.error("Please fill all the fields");
      return;
    }

    const weightNum = parseFloat(weight);
    const heightNum = parseFloat(height);
    const ageNum = parseFloat(age);

    if (isNaN(weightNum) || isNaN(heightNum) || isNaN(ageNum)) {
      toast.error(
        "Please enter valid numeric values for weight, height, and age"
      );
      return;
    }

    let BMR =
      gender?.value === "Male"
        ? 10 * weightNum + 6.25 * heightNum - 5 * ageNum + 5
        : 10 * weightNum + 6.25 * heightNum - 5 * ageNum - 161;

    BMR = calculateDailyCalorieNeeds(BMR, activityLevel?.key);
    
    const proteinBMR = activityLevel?.key === 1 ? 1 : 1.25;
    
    const protein = 1.2 * weightNum * proteinBMR;
    const fat = ((0.255 * BMR) / 9) * proteinBMR;
    const carbs = ((BMR - protein * 4 - fat * 9) / 4) * proteinBMR;

    setMacros({
      protein: protein.toFixed(2),
      fat: fat.toFixed(2),
      carbs: carbs.toFixed(2),
    });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ minHeight: "450px" }}>
      <Paper
        elevation={2}
        style={{
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <SuiTypography variant="h4" mb={2}>
          Macro Calculator
        </SuiTypography>
        <form>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Weight (in Kgs)
              </SuiTypography>
              <SuiInput
                type="number"
                name="weight"
                placeholder="Enter weight"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Height (in cm)
              </SuiTypography>
              <SuiInput
                type="number"
                name="height"
                placeholder="Enter height"
                value={height}
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Age
              </SuiTypography>
              <SuiInput
                type="number"
                name="age"
                placeholder="Enter age"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SuiTypography mb={1} variant="h6">
                Activity Level
              </SuiTypography>
              <Createable
                options={activitiesOptions}
                style={{
                  borderRadius: "0.5rem",
                  fontSize: 14,
                }}
                inputStyle={{
                  width: "100%",
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setActivityLevel(e);
                }}
                value={activityLevel}
              />
            </Grid>
            <Grid item xs={12}>
              <SuiTypography mb={1} variant="h6">
                Gender
              </SuiTypography>
              <Createable
                options={genderOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setGender(e);
                }}
                value={gender}
              />
            </Grid>
          </Grid>
          <SuiButton
            fullWidth
            variant="contained"
            color="dark"
            onClick={calculateMacros}
            style={{ padding: "10px 0" }}
          >
            Calculate Macros
          </SuiButton>
        </form>

        {macros !== null && (
          <div style={{ marginTop: "20px" }}>
            <SuiTypography variant="h6">Estimated Macros:</SuiTypography>
            <p>Protein: {macros.protein} grams/day</p>
            <p>Fat: {macros.fat} grams/day</p>
            <p>Carbs: {macros.carbs} grams/day</p>
          </div>
        )}
      </Paper>
    </Container>
  );
};

export default MacroCalculator;
