import React from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";


function Modal({ open, setOpenModal, handleCloseModal, familyMemberArray }) {
    
    let navigate = useNavigate(); //for navigation

    return (
        <ModalRoot variant="permanent" ownerState={{  openConfigurator: open }} width="25%">
            <SuiBox
                style={{ whiteSpace: 'normal !important'}}
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}>
                <SuiBox>
                    <SuiTypography variant="h5">{familyMemberArray.name} Family Members</SuiTypography>
                </SuiBox>

                <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseModal}>
                    close
                </Icon>
            </SuiBox>

            <Divider />

            <SuiBox pt={1.25} pb={3} px={3}>

                {
                    familyMemberArray?.list.map((item, index)=> (
                        <SuiBox key={index}>
                            <SuiTypography 
                                variant="body2" 
                                color="text"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={()=>{
                                    navigate(`/clients/${item.value}`);  
                                }}>
                                {item.label}
                            </SuiTypography>
                        </SuiBox>
                    ))
                }

            
            </SuiBox>
        </ModalRoot>
    );
}

export default Modal;
