import React, { useEffect, useRef, useState } from "react";
// @mui material components

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SuiTypography from "components/SuiTypography";
import { isAllOptionInserted } from "utils/common";

function ProductSearch({
  serviceOptions,
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
  excelDownload,
  familyArray,
}) {
  const insertedRef = useRef(false);

  const [statusOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ]);

  if (!isAllOptionInserted(familyArray)) {
    familyArray?.unshift({
      value: "all",
      label: "All",
    });
  }

  if (!isAllOptionInserted(serviceOptions)) {
    serviceOptions?.unshift({
      value: "all",
      label: "All",
    });
  }

  const [serviceOpt_, setServiceOpt_] = useState(null); //for selecting products
  const [statusOpt_, setStatusOpt_] = useState(null); //for selecting status
  const [serviceIns_, setServiceIns_] = useState(null); //for selecting service type
  const [familyNameOpt_, setFamilyOpt_] = useState([]); //for selecting family member

  const [serviceInstanceOptions] = useState([
    { value: "all", label: "All", key: 0 },
    { value: "First Time", label: "First Time", key: 1 },
    { value: "Extension", label: "Extension", key: 2 },
    { value: "Returning", label: "Returning", key: 3 },
  ]);

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      style={{ padding: "0 24px" }}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            From Date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox mb={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            To Date
          </SuiTypography>
          <DatePicker
            closeOnScroll={true}
            isClearable
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Status
          </SuiTypography>
          <Createable
            options={statusOptions ?? []}
            value={statusOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 50, height: "1.800rem" }}
            placeholder="Status"
            onChange={(e) => {
              setStatusOpt_(e);
              setFilterQuery({
                ...filterQuery,
                status: e.value,
              });
            }}
          />
        </SuiBox>
        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Family Name
          </SuiTypography>
          <Createable
            options={familyArray ?? []}
            value={familyNameOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{ width: 80, height: "1.800rem" }}
            placeholder="Family Name"
            onChange={(e) => {
              setFamilyOpt_(e);
              setFilterQuery({
                ...filterQuery,
                familyName: e.value,
              });
            }}
          />
        </SuiBox>
        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            Choose Service
          </SuiTypography>
          <Createable
            options={serviceOptions ?? []}
            value={serviceOpt_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 120,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Choose Service"
            onChange={(e) => {
              setServiceOpt_(e);
              setFilterQuery({
                ...filterQuery,
                service: e.value,
              });
            }}
          />
        </SuiBox>
        <SuiBox my={1} mr={1}>
          <SuiTypography mb={1} variant="h6">
            Service Type
          </SuiTypography>
          <Createable
            options={serviceInstanceOptions ?? []}
            value={serviceIns_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 60,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Service Type"
            onChange={(e) => {
              setServiceIns_(e);
              setFilterQuery({
                ...filterQuery,
                serviceInstance: e.value,
              });
            }}
          />
        </SuiBox>
        <SuiBox my={1} mb={1}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <SuiBox my={1} mb={1} mr={1}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={() => {
              setStatusOpt_(null);
              setServiceOpt_(null);
              setServiceIns_(null);
              setFamilyOpt_(null);
              resetHandler();
            }}
          >
            Reset
          </SuiButton>
        </SuiBox>
        <SuiBox my={1} mb={1}>
          <SuiButton
            component={Button}
            color="dark"
            variant="outlined"
            onClick={excelDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default ProductSearch;
