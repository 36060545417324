import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import TagItem from "containers/TagItem";
import SuiBox from "components/SuiBox";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

function renderTags(tags) {
  if (Array.isArray(tags) && tags.length > 0) {
    return tags.map((val, key) => <TagItem key={key} val={val} />);
  }
  return "N/A";
}

export const biochemicalHealthRecordsRows = (
  biochemicalArray,
  editHandler,
  viewHandler,
  deleteHandler
) => {
  const rows = biochemicalArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      Date: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.timestamp)}
        </SuiTypography>
      ),

      "Ongoing Medication": (
        <Tooltip title={item?.ongoingMedication} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item.ongoingMedication
              ? item?.ongoingMedication?.length > 20
                ? `${item?.ongoingMedication?.slice(0, 20)}...`
                : item?.ongoingMedication
              : "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      "Operated?": (
        <Tooltip title={item?.operated} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item.operated
              ? item?.operated?.length > 20
                ? `${item?.operated?.slice(0, 20)}...`
                : item?.operated
              : "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      "Allergic From": (
        <SuiBox style={{ display: "flex", width: "300px", flexWrap: "wrap" }}>
          {renderTags(item?.sufferingRegularlyFrom) ?? "N/A"}
        </SuiBox>
      ),

      "Suffering From": (
        <SuiBox style={{ display: "flex", width: "300px", flexWrap: "wrap" }}>
          {renderTags(item?.sufferingFrom) ?? "N/A"}
        </SuiBox>
      ),

      "Duration & Medication": (
        <Tooltip title={item?.durationMediction} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.durationMediction?.length > 20
              ? `${item?.durationMediction?.slice(0, 20)}...`
              : item?.durationMediction ?? "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      "Rashes or Allergies on Skin?": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.rashesOnSkin ?? "N/A"}
        </SuiTypography>
      ),

      "Obesity Runs in Family?": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.obesityInFamily ?? "N/A"}
        </SuiTypography>
      ),

      "Any Other Condition?": (
        <Tooltip title={item?.anyOtherCondition} placement="top">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.anyOtherCondition?.length > 20
              ? `${item?.anyOtherCondition?.slice(0, 20)}...`
              : item?.anyOtherCondition ?? "N/A"}
          </SuiTypography>
        </Tooltip>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() => deleteHandler(item, index, "deleteBiochem")}
        />
      ),
    };
  });
  return rows;
};
