import { StyleSheet, Font } from '@react-pdf/renderer';
import poppinBlack from '../../../assets/fonts/Poppins-Black.ttf'
import poppinLight from '../../../assets/fonts/Poppins-Light.ttf'
import poppinRegular from '../../../assets/fonts/Poppins-Regular.ttf'
import poppinSemiBold from '../../../assets/fonts/Poppins-SemiBold.ttf'
import poppinMedium from '../../../assets/fonts/Poppins-Medium.ttf'


Font.register({
  family: 'Poppins',
  fonts: [

    {
      src: poppinLight,
      fontWeight: 300,
    },

    {
      src: poppinRegular,
      fontWeight: 400
    },

    {
      src: poppinMedium,
      fontWeight: 500,
    },

    {
      src: poppinBlack,
    },

    {
      src: poppinSemiBold,
      fontWeight: 600
    },

  ],
});


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 10
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: 'Poppins',
    marginTop: 20,
  },

  logoImage: {
    width: 200,
    height: 60,
    objectFit: 'contain',
    marginBottom: 5,
  },

  qr: {
    width: 200,
    height: 200,
    objectFit: 'contain',
    marginBottom: 5,
  },

  signature: {
    width: 150,
    height: 150,
    objectFit: 'contain',
  },

  company: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 0,
    paddingLeft: 6,
  },

  address: {
    width: 300,
  },

  title: {
    fontSize: 50,
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: "#057AC1"
  },

  mainHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#057AC1',
    padding: 3,
    paddingLeft: 6,
    marginBottom: 3,
    color: 'white'
  },

  headerContent: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 30,
  },

  text: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500
  },

  dataRow: {
    flexDirection: 'row'
  },

  invoice: {
    width: 150,
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500
  },

  date: {
    width: 170,
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500
  },

  terms: {
    width: 170,
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500
  },

  termSig: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: -30,
    marginTop: 20
  },

  termText: {
    flexDirection: 'row',
    marginBottom: 5
  },

  termsText1: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontWeight: 500
  },

  termsText2: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontWeight: 400
  },

  padding: {
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 14,
    marginBottom: 0,
    paddingLeft: 6,
  },

  main: {
    marginTop: 40
  },

  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#057AC1',
    padding: 3,
    paddingLeft: 6,
    color: 'white',
    width: '100%',
    marginTop: 50
  },

  desc: {
    width: '40%',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500
  },

  datesection: {
    width: '30%',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
  },

  qty: {
    width: '30%',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
  },

  unit: {
    width: '15%',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
  },

  amount: {
    width: '15%',
  },

  mainDataRow: {
    width: '100%',
    flexDirection: 'row',
  },

  borderRightTop: {
    borderRight: '1px dotted black',
    borderBottom: '1px dotted #838181',
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 14,
    paddingLeft: 6,
  },

  invoiceAmount: {
    flexDirection: 'row',
    width: '100%'
  },

  blank: {
    width: '65%'
  },

  summaryTitle: {
    width: '25%',
    padding: 10,
    backgroundColor: '#7ABAE0',

    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 400
  },

  summaryValue: {
    width: '30%',
    padding: 10,
    backgroundColor: '#7CC7F3',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 400
  },

  total: {
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 500,
    color: '#094C75'
  },

  balance: {
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 500,
    color: 'red'
  },

  totalVal: {
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 500
  },

  contact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    justifyContent: 'space-between',
    fontFamily: 'Poppins',
    fontSize: 15,
    fontWeight: 400
  },

  details: {
    fontWeight: 500
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  footerText: {
    fontFamily: 'Poppins',
    fontSize: 12,
    marginBottom: 5,
  },

  socialMedia: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },

  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },

  socialIcon: {
    width: 18,
    height: 18,
    marginRight: 5,
  },

  username: {
    fontFamily: 'Poppins',
    fontSize: 12,
    marginLeft: 1,
  },
});


export default styles