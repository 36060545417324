import React, { useEffect, useState } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import DatePicker from "react-datepicker";
import { getWeeksArray } from "utils/common";
import SuiTypography from "components/SuiTypography";

function DietChartRecipeSearch({
  setFilterQuery,
  filterQuery,
  filterHandler,
  resetHandler,
}) {
  const [chartTypes] = useState([
    {
      value: "all",
      label: "All",
      key: 0,
    },
    { value: "Weekly", label: "Weekly", key: 1 },
    { value: "Plateau", label: "Plateau", key: 2 },
    { value: "Detox", label: "Detox", key: 3 },
  ]);

  const [weekPlan_, setWeekPlan_] = useState(null); //for selecting week plan
  const [chartType_, setChartType_] = useState(null); //for selecting chart type

  return (
    <SuiBox
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="end"
      p={3}
    >
      <SuiBox
        mb={1}
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "end",
          flexWrap: "wrap",
        }}
      >
        <SuiBox my={1} mr={2}>
          <SuiTypography mb={1} variant="h6">
            Week Plan
          </SuiTypography>
          <Createable
            options={getWeeksArray("allOption") ?? []}
            value={weekPlan_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Week plan"
            onChange={(e) => {
              setWeekPlan_(e);
              setFilterQuery({
                ...filterQuery,
                weekPlan: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2}>
          <SuiTypography mb={1} variant="h6">
            Chart type
          </SuiTypography>
          <Createable
            options={chartTypes ?? []}
            value={chartType_}
            style={{ borderRadius: "0.5rem", fontSize: 14 }}
            inputStyle={{
              width: 190,
              height: "1.800rem",
              borderRadius: "0.5rem",
            }}
            placeholder="Chart type"
            onChange={(e) => {
              setChartType_(e);
              setFilterQuery({
                ...filterQuery,
                chartType: e.value,
              });
            }}
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            From date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.fromDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                fromDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="From date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiTypography mb={1} variant="h6">
            To date
          </SuiTypography>
          <DatePicker
            isClearable
            closeOnScroll={true}
            selected={filterQuery?.toDate}
            onChange={(date) => {
              setFilterQuery({
                ...filterQuery,
                toDate: date,
              });
            }}
            className="date-picker-custom-v2"
            placeholderText="To date"
          />
        </SuiBox>

        <SuiBox my={1} mr={2.5}>
          <SuiButton
            component={Button}
            color="dark"
            variant="gradient"
            onClick={filterHandler}
          >
            Search
          </SuiButton>
        </SuiBox>
      </SuiBox>

      <SuiBox mb={2} mr={2}>
        <SuiButton
          component={Button}
          color="dark"
          variant="outlined"
          onClick={() => {
            setChartType_(null);
            setWeekPlan_(null);
            resetHandler();
          }}
        >
          Reset
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

export default DietChartRecipeSearch;
