import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import SuiButton from "components/SuiButton";
import DatePicker from "react-datepicker";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ExpenseBalanceChart from "./chart/Chart";
import moment from "moment";
import Createable from "containers/Select";

const EarningExpenseTracker = ({
  loader,
  title,
  setChartYear,
  chartYear,
  yearArray,
  data,
  totalExpense,
  balance,
  tag,
  totalIncomings,
}) => {
  const [MiniCardData, setMiniCardData] = useState([]);

  useEffect(() => {
    yearArray.sort((a, b) => {
      return b.value - a.value;
    });
  }, [yearArray]);

  useEffect(() => {
    let MiniCardData_ = [
      {
        title: "Yearly incomings",
        count: totalIncomings?.toLocaleString() ?? 0,
        bullet: true,
        icon: {
          color: "info",
          component: "person_2",
          para: "Total Earnings",
        },
      },
      {
        title: "Yearly Expenses",
        count: (totalExpense && totalExpense?.toLocaleString()) ?? 0,
        bullet: true,

        icon: {
          color: "info",
          component: "paid",
          para: "Total Expenses",
        },
      },
      {
        title: "Yearly Balance",
        count: balance ? balance?.toLocaleString() : 0,
        bullet: true,

        icon: {
          color: "info",
          component: "task_alt",
          para: "Total Balance",
        },
      },
    ];
    setMiniCardData(MiniCardData_);
  }, [balance, totalExpense, totalIncomings]);

  return (
    <Grid item xs={12} xl={12}>
      <SuiBox mb={3}>
        <Card style={{ overflow: "inherit" }}>
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={3}
            pt={3}
            pb={0}
          >
            <SuiTypography variant="h6">{title}</SuiTypography>

            <Createable
              options={yearArray}
              style={{ borderRadius: "0.5rem", fontSize: 8 }}
              inputStyle={{
                width: 20,
                height: "1.0rem",
                borderRadius: "0.5rem",
              }}
              onChange={(e) => {
                setChartYear({
                  ...chartYear,
                  [tag]: e.value,
                });
              }}
              value={{ value: chartYear[tag], label: chartYear[tag] }}
            />
          </Grid>

          <Grid container spacing={3} px={3} pb={3}>
            {MiniCardData.map((item, index) => (
              <Grid item xs={12} sm={6} xl={4} key={index}>
                <MiniStatisticsCard
                  title={{ text: item?.title }}
                  count={item?.count}
                  bullet={true}
                  fontSize={18}
                  percentage={{
                    color: item?.percent?.color,
                    text: item?.percent?.text,
                  }}
                  icon={{
                    color: item?.icon?.color,
                    component: item?.icon?.component,
                    para: item?.icon?.para,
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <SuiBox xs={12} px={3} pb={3}>
            <ExpenseBalanceChart
              title={"Earning, Expens & Balance"}
              data={data}
              loader={loader}
            />
          </SuiBox>
        </Card>
      </SuiBox>
    </Grid>
  );
};

export default EarningExpenseTracker;
