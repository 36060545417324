import React from 'react'
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';


function Profile({
    name,
    address,
    phoneNumber,
    email,
    gender,
    age,
    leadFrom,
    occupation,
    referredBy,
    customerId,
    foodPreference
}) {
    return (
        <SuiBox p={3} style={{ boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.05)' }}>
            <SuiBox style={{ display: 'flex' }}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Name:
                </SuiTypography>

                <SuiTypography
                    variant="h9"
                    fontWeight="medium">
                    {name}
                </SuiTypography>

            </SuiBox>

            <SuiBox style={{ display: 'flex' }} mt={1}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Ph. No:
                </SuiTypography>

                <>
                    <SuiTypography
                        variant="h9"
                        fontWeight="medium">
                        {phoneNumber}
                    </SuiTypography>
                    <IconButton
                        size="small"
                        style={{ padding: "3px", marginLeft: "5px" }}
                        onClick={() => {
                            window.open(`https://wa.me/${phoneNumber}`, "_blank");
                        }}
                    >
                        <WhatsApp style={{ color: "#075e54" }} />
                    </IconButton>
                </>

            </SuiBox>

            <SuiBox style={{ display: 'flex' }} mt={1}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Email:
                </SuiTypography>

                <SuiTypography
                    variant="h9"
                    fontWeight="medium">
                    {email}
                </SuiTypography>

            </SuiBox>

            <SuiBox style={{ display: 'flex' }} mt={1}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Address:
                </SuiTypography>

                <Tooltip title={address ?? ""} placement="top">
                    <SuiTypography
                        variant="h9"
                        fontWeight="medium">
                        {address?.length > 20 ?
                            `${address?.slice(0, 20)}...` :
                            address}

                    </SuiTypography>
                </Tooltip>

            </SuiBox>

            {/* <SuiBox style={{ display: 'flex' }} my={1}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Gender:
                </SuiTypography>

                <SuiTypography
                    variant="h9"
                    fontWeight="medium">
                    {gender}
                </SuiTypography>

            </SuiBox> */}

            <Grid container>
                <Grid item xs={12} md={6}>
                    <SuiBox style={{ display: 'flex' }} my={1}>
                        <SuiTypography
                            variant="h8"
                            mr={1}
                            fontWeight="medium">
                            Gender:
                        </SuiTypography>

                        <SuiTypography
                            variant="h9"
                            fontWeight="medium">
                            {gender ?? "N/A"}
                        </SuiTypography>

                    </SuiBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SuiBox style={{ display: 'flex' }} my={1}>
                        <SuiTypography
                            variant="h8"
                            mr={1}
                            fontWeight="medium">
                            Age:
                        </SuiTypography>

                        <SuiTypography
                            variant="h9"
                            fontWeight="medium">
                            {age ?? "N/A"}
                        </SuiTypography>
                    </SuiBox>
                </Grid>
            </Grid>
            <SuiBox style={{ display: 'flex' }}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Lead from:
                </SuiTypography>

                <SuiTypography
                    variant="h9"
                    fontWeight="medium">
                    {leadFrom ?? "N/A"}
                </SuiTypography>

            </SuiBox>


            <Grid container mt={1}>
                <Grid item xs={12}>
                    <SuiBox style={{ display: 'flex' }}>
                        <SuiTypography
                            variant="h8"
                            mr={1}
                            fontWeight="medium">
                            Occupation:
                        </SuiTypography>

                        <SuiTypography
                            variant="h9"
                            fontWeight="medium">
                            {occupation ?? "N/A"}
                        </SuiTypography>

                    </SuiBox>
                </Grid>

                <Grid item xs={12} mt={1}>
                    <SuiBox style={{ display: 'flex' }}>
                        <SuiTypography
                            variant="h8"
                            mr={1}
                            fontWeight="medium">
                            Food preference:
                        </SuiTypography>

                        <SuiTypography
                            variant="h9"
                            fontWeight="medium">
                            {foodPreference ?? "N/A"}
                        </SuiTypography>
                    </SuiBox>
                </Grid>

                <Grid item xs={12} mt={1}>
                    <SuiBox style={{ display: 'flex' }}>
                        <SuiTypography
                            variant="h8"
                            mr={1}
                            fontWeight="medium">
                            Customer ID:
                        </SuiTypography>

                        <SuiTypography
                            variant="h9"
                            fontWeight="medium">
                            {customerId ?? "N/A"}
                        </SuiTypography>
                    </SuiBox>
                </Grid>
            </Grid>

            <SuiBox style={{ display: 'flex' }} mt={1}>
                <SuiTypography
                    variant="h8"
                    mr={1}
                    fontWeight="medium">
                    Referred By:
                </SuiTypography>

                <SuiTypography
                    variant="h9"
                    fontWeight="medium">
                    {referredBy ?? "N/A"}
                </SuiTypography>

            </SuiBox>

        </SuiBox>
    )
}

export default Profile