import React from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import CustomButton from "containers/CustomButton";
import { globalStyles } from "styles";

function ClientInfoCard({
  title,
  rows,
  columns,
  disableButton,
  onSubmitHandler,
  loader,
  onBtnClickHandler,
  Modal,
}) {
  return (
    <SuiBox overflow="hidden" minWidth="250px">
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <SuiTypography variant="h5" style={styles.textColor}>
          {title}
        </SuiTypography>
        <SuiButton
          component={Button}
          color="dark"
          variant="gradient"
          onClick={onBtnClickHandler}
        >
          Add Data
        </SuiButton>
      </SuiBox>

      <SuiBox sx={globalStyles.tableOverlay}>
        {rows.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>

      {rows.length > 0 ? (
        <SuiBox style={styles.btn}>
          <CustomButton
            color="dark"
            variant="gradient"
            onClick={onSubmitHandler}
            fullWidth
            disabled={loader}
            loader={loader}
            title={"Save and continue"}
          />
        </SuiBox>
      ) : null}
      <Modal />
    </SuiBox>
  );
}

export default ClientInfoCard;

/** ------------- styling ------------------ */

const styles = {
  btn: {
    position: "sticky",
    width: "100%",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "end",
    marginTop: 40,
    bottom: 0,
  },

  textColor: {
    color: "#17C1E8",
  },
};
