import {
  excelAndDbRecipeFieldsMapping,
  STRING_FIELD_MAX_LENGTH,
  TAG_FIELD_MAX_LENGTH,
} from "constant";
import { toast } from "react-toastify";
import { getErrorMessage } from "utils/helper";

/**---------------Excel validations ---------------- */

export const validateExcelHeaders = (dataArray) => {
  let headerFieldCount = 0;
  if (dataArray.length === 0) {
    toast.error("Excel file is corrupted, plz download a new one!!");
    return false;
  }

  for (const mapping of excelAndDbRecipeFieldsMapping) {
    const excelHeader = mapping.excelName;
    const columnIndex = dataArray[0].indexOf(excelHeader);

    if (columnIndex !== -1) {
      headerFieldCount++;
    }
  }

  if (headerFieldCount !== excelAndDbRecipeFieldsMapping.length) {
    toast.error("Excel file is corrupted, plz download a new one!!");
    return false;
  }

  if (dataArray.length === 1) {
    toast.error("File is empty, plz add recipes!!");
    return false;
  }

  return true;
};

export const validateAndExtractCellValues = (dataArray, adminId) => {
  const extractedData = [];
  let rowErrors = {};

  for (let i = 1; i < dataArray.length; i++) {
    const dataRow = dataArray[i];
    const recipeData = {
      adminId: adminId,
      createdAt: new Date(),
    };
    const rowErrorMessages = [];

    for (const mapping of excelAndDbRecipeFieldsMapping) {
      const excelHeader = mapping.excelName;
      const dbField = mapping.dbField;
      const columnIndex = dataArray[0].indexOf(excelHeader);

      if (columnIndex !== -1) {
        let cellValue = dataRow[columnIndex];

        if (dbField in STRING_FIELD_MAX_LENGTH) {
          const maxLength = STRING_FIELD_MAX_LENGTH[dbField];
          if (typeof cellValue === "number" && !isNaN(cellValue)) {
            cellValue = cellValue.toString();
          }
          if (!cellValue || cellValue.trim() === "") {
            rowErrorMessages.push(
              getErrorMessage("INVALID_FIELD", excelHeader)
            );
          } else if (cellValue.length > maxLength) {
            rowErrorMessages.push(
              getErrorMessage("EXCEEDS_MAX_LENGTH", excelHeader)
            );
          } else {
            recipeData[dbField] = cellValue;
          }
        } else if (dbField in TAG_FIELD_MAX_LENGTH) {
          if (typeof cellValue === "number" && !isNaN(cellValue)) {
            cellValue = cellValue.toString();
          }
          if (!cellValue || cellValue.trim() === "") {
            rowErrorMessages.push(
              getErrorMessage("INVALID_FIELD", excelHeader)
            );
          } else {
            const maxLength = TAG_FIELD_MAX_LENGTH[dbField];
            const tagsArray = cellValue.split(",").map((tag) => tag.trim());

            if (tagsArray.some((tag) => tag.length > maxLength)) {
              rowErrorMessages.push(
                getErrorMessage(
                  "EXCEEDS_MAX_LENGTH",
                  `Tag in ${excelHeader}`,
                  i + 1
                )
              );
            } else {
              recipeData[dbField] = tagsArray;
            }
          }
        } else if (
          dbField === "calories" ||
          dbField === "weight" ||
          dbField === "qty"
        ) {
          const numericValue = parseFloat(cellValue);
          if (isNaN(numericValue) || numericValue <= 0) {
            rowErrorMessages.push(
              getErrorMessage("INVALID_NUMERICs", excelHeader)
            );
          } else {
            recipeData[dbField] = numericValue;
          }
        }

        if (dbField === "nutritions") {
          if (!/^(\w+\s*:\s*\w+\s*,\s*)*\w+\s*:\s*\w+\s*$/.test(cellValue)) {
            rowErrorMessages.push(
              getErrorMessage("INVALID_NUTRITION_FORMAT", excelHeader)
            );
          } else {
            const nutritionComponents = cellValue
              .split(",")
              ?.map((item) => item.trim());

            recipeData[dbField] = [...nutritionComponents];
          }
        }
      }
    }

    if (
      Object.keys(recipeData).length ===
      excelAndDbRecipeFieldsMapping.length + 2
    ) {
      extractedData.push(recipeData);
    } else if (rowErrorMessages.length === 0) {
      rowErrorMessages.push(getErrorMessage("INCOMPLETE_DATA"));
    }

    if (rowErrorMessages.length > 0) {
      rowErrors["rowNumber"] = i + 1;
      rowErrors["errorMessages"] = rowErrorMessages;
      break;
    }
  }

  return { extractedData, rowErrors };
};
