import { upcommingRenewableDays } from "constant";
import { ANNIVERSARY } from "constant";
import { BIRTHDAY } from "constant";
import {
    collection,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import { sendWishes } from "firebaseConfig";
import { firestore } from 'firebaseConfig';
import { calculateBalance } from "utils/common";


export const fetchRenewables = async ({ uid }) => {
    try {
        const currentDate = new Date();
        const daysAhead = new Date();
        daysAhead.setDate(currentDate.getDate() + upcommingRenewableDays);

        const query_ = query(collection(firestore, "client"), where('adminId', "==", uid));
        const querySnapshot = await getDocs(query_);
        const data = [];

        querySnapshot.forEach((doc) => {

            let { services, name, phoneNumber } = doc.data();

            if (services && services.length > 0) {
                services.filter(service => {
                    const completionDate = new Date(service.completionDate);
                    if (completionDate <= daysAhead && completionDate > currentDate) {
                        data.push({
                            id: doc.id,
                            clientName: name, 
                            phoneNumber: phoneNumber,
                            completionDate: service.completionDate,
                            startDate: service.startDate,
                            serviceName: service.serviceName
                        })
                    }
                    return null;
                });
            }
        });
        return data
    } catch (error) {
        console.log(error)
        throw error;
    }
}

//for getting today's birthdays
export const fetchBirthdaysAndAnniversaries = async ({ uid }) => {
    try {

        // Get today's date
        const today = new Date();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if required
        const day = today.getDate().toString().padStart(2, '0'); //Add leading zero if required

        // Format today's date in "MM-DD" format
        const todayFormatted = `${month}-${day}`;
        let data = [];

        // console.log(today, todayFormatted)

        const q = query(collection(firestore, "client"), where('adminId', "==", uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const dob = doc.data()?.dob;
            const anniversary = doc.data()?.anniversary;

            if (dob) {
                const dobParts = dob.split("-");
                const dobMonth = dobParts[1];
                const dobDay = dobParts[2];
                const dobFormattedDate = `${dobMonth}-${dobDay}`;
                if (todayFormatted === dobFormattedDate) {
                    let { name, dob, email } = doc.data();
                    data.push({
                        id: doc.id, name,
                        dob, occasion: BIRTHDAY, email
                    })
                }
            }

            if (anniversary) {
                const anniversaryParts = anniversary.split("-");
                const anniversaryMonth = anniversaryParts[1];
                const anniversaryDay = anniversaryParts[2];
                const anniversaryFormattedDate = `${anniversaryMonth}-${anniversaryDay}`;
                if (todayFormatted === anniversaryFormattedDate) {
                    let { name, anniversary, email } = doc.data();
                    data.push({
                        id: doc.id, name,
                        anniversary, occasion: ANNIVERSARY, email
                    })
                }
            }
        });
        return data

    } catch (e) {
        throw (e);
    }
}


//for getting today's follow up leads
export const fetchFollowUpLeads = async ({ uid }) => {
    try {

        const today = new Date();
        const todayFormatted = today.toISOString().split("T")[0]; // Convert to "YYYY-MM-DD" format

        let data = [];

        // console.log(today, todayFormatted)

        const q = query(collection(firestore, "lead"), where('adminId', "==", uid),
            where('followUpDate', "==", todayFormatted));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            let { clientName, phoneNumber, status } = doc.data();
            // console.log(doc.data())
            data.push({ id: doc.id, clientName, phoneNumber, status })

        });
        return data

    } catch (e) {
        throw (e);
    }
}



//for getting today's follow up leads
export const fetchBalances = async ({ uid }) => {
    try {
        const q = query(collection(firestore, "client"), where('adminId', "==", uid));

        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((doc) => {

            const services = doc.data()?.services

            if (services && services.length > 0) {
                services.map((service) => {
                    const balance = calculateBalance(service.totalAmount,
                        service.discount, service.amountPaid)

                    if (balance > 0) {
                        data.push({
                            name: doc.data()?.name,
                            clientId: doc?.id,
                            phoneNumber: doc.data()?.phoneNumber,
                            balance,
                            startDate: service?.startDate
                        })
                    }


                    return service
                })
            }
        });
        return data
    } catch (e) {
        throw (e);
    }
}


//for getting today's Anniversary
export const fetchAnniversary = async ({ uid }) => {
    try {

        // Get today's date
        const today = new Date();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if required
        const day = today.getDate().toString().padStart(2, '0'); // Add leading zero if required

        // Format today's date in "MM-DD" format
        const todayFormatted = `${month}-${day}`;
        let data = [];

        // console.log(today, todayFormatted)

        const q = query(collection(firestore, "client"), where('adminId', "==", uid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const anniversary = doc.data()?.anniversary;
            if (anniversary) {
                const parts = anniversary.split("-");
                const month = parts[1];
                const day = parts[2];
                const formattedDate = `${month}-${day}`;
                if (todayFormatted === formattedDate) {
                    let { name, anniversary, email } = doc.data();
                    data.push({ id: doc.id, name, anniversary, email })
                }
            }
        });
        return data

    } catch (e) {
        throw (e);
    }
}


export const fetchDietChartReminder = async ({ uid }) => {
    try {

        // Get tomorrow's date
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

        // Get the day after tomorrow's date
        const dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        const dayAfterTomorrowFormatted = dayAfterTomorrow.toISOString().split("T")[0];

        let data = [];

        const q = query(collection(firestore, "client"), where('adminId', "==", uid));

        const querySnapshot = await getDocs(q);
        let clients = {}
        querySnapshot.forEach((doc) => {
            const services = doc.data()?.services

            if (services && services.length > 0) {
                for (let i = 0; i < services.length; i++) {

                    let { startDate, completionDate } = services[i];

                    startDate = new Date(startDate);
                    completionDate = new Date(completionDate);
                    const currentDate = new Date();

                    if (currentDate >= startDate && currentDate <= completionDate) {
                        clients[doc.id] = { name: doc.data()?.name }
                        clients[doc.id][tomorrowFormatted] = false
                        clients[doc.id][dayAfterTomorrowFormatted] = false
                        break;
                    }
                }
            }
        });

        const clientIds = Object.keys(clients);

        // Split the clientIds array into chunks of 10
        const chunks = [];
        const chunkSize = 10;
        for (let i = 0; i < clientIds.length; i += chunkSize) {
            chunks.push(clientIds.slice(i, i + chunkSize));
        }

        // const queries = chunks.map((chunk) => {

        //     return firestore.collection("dietplan")
        //         .where("date", "not-in", [tomorrowFormatted, dayAfterTomorrowFormatted])
        //         .where("clientId", "in", chunk)
        //         .get();

        // });


        const queriesv1 = chunks.map(async (chunk) => {
            let q = query(collection(firestore, "dietplan"),
                where("date", "==", tomorrowFormatted),
                where("clientId", "in", chunk));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                const id = doc.data().clientId
                clients[id][tomorrowFormatted] = true;
            });
        });

        const queriesv2 = chunks.map(async (chunk) => {
            let q = query(collection(firestore, "dietplan"),
                where("date", "==", dayAfterTomorrowFormatted),
                where("clientId", "in", chunk));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                const id = doc.data().clientId
                clients[id][dayAfterTomorrowFormatted] = true;

            });
        });

        await Promise.all([...queriesv1, ...queriesv2])


        Object.keys(clients).forEach((key) => {
            if (!clients[key][tomorrowFormatted]) {
                data.push({ id: key, name: clients[key]['name'], date: tomorrowFormatted })
            }
            if (!clients[key][dayAfterTomorrowFormatted] && clients[key][tomorrowFormatted]) {
                data.push({ id: key, name: clients[key]['name'], date: dayAfterTomorrowFormatted })
            }
        })

        return data

    } catch (e) {
        throw (e);
    }
}



//for getting today's Appointments
export const fetchAppointments = async ({ uid }) => {
    try {

        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Get tomorrow's date
        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let data = [];

        const q = query(collection(firestore, "appointment"), where('adminId', "==", uid));

        const querySnapshot = await getDocs(q);

        const filteredDocs = querySnapshot.docs.filter((doc) => {
            const createdAt = doc.data().appointmentDate.toDate();
            return createdAt >= today && createdAt < tomorrow;
        });

        filteredDocs.forEach((doc) => {
            let { clientName, phoneNumber, appointmentDate } = doc.data();
            data.push({ id: doc.id, clientName, phoneNumber, appointmentDate })
        });

        return data

    } catch (e) {
        console.log(e)
        throw (e);
    }
}


//send wishes
export const sendWishesHandler = async ({ name, email, message, occasion }) => {
    try {
        // Call the Cloud Function
        const result = await sendWishes({
            clientName: name,
            clientEmail: email,
            occasion: occasion.toLowerCase(),
            message: message
        });

        return result.data

    } catch (error) {
        console.error('Error sending wishes:', error);
        throw error;
    }
}