import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function MiniStatisticsCard({ bgColor, title, count, percentage, icon, direction, bullet, fontSize, onClickHandler }) {
  return (
    <Card style={{ cursor: 'pointer' }} onClick={onClickHandler}>
      <SuiBox bgColor={bgColor} variant="gradient" >
        <SuiBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <SuiBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SuiBox>
              </Grid>
            ) : null}
            <Grid item xs={10}>
              <SuiBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <SuiBox style={{display:"flex", alignItems: 'center'}}>
                  <SuiTypography
                    variant="head"
                    color={bgColor === "white" ? "text" : "white"}
                    opacity={bgColor === "white" ? 1 : 0.7}
                    textTransform="capitalize"
                    fontWeight={title.fontWeight}
                    fontSize={11}
                  >
                    {title.text}
                   
                  </SuiTypography>
                  <span style={{paddingLeft:"6px",fontSize:"14px",height:"16px",width:"14px"}}>
                  <Tooltip title={icon.para}  placement="top" style={{color:'#67748e',height:"16px",width:"14px"}}>
                    <Icon color="inherit"  >
                        {"help"}
                    </Icon> 
                  </Tooltip> 
                  </span>
                </SuiBox> 
                <SuiTypography
                  variant="head1"
                  fontWeight="bold"
                  fontSize={fontSize}
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {count}{" "}
                  <SuiTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                    {!bullet && title.text !== "Balance To Be Collected" &&
                      <SuiTypography variant="button" color={percentage.color} fontWeight="bold" style={{ fontSize: 9 }}>
                        (From last month)
                      </SuiTypography>
                    }
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            </Grid>
            {/* {direction === "right" ? (
              <Grid item xs={4}>
                <SuiBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SuiBox>
              </Grid>
            ) : null} */}
          </Grid>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
