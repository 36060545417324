import { useState, useMemo, useEffect, useCallback } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePhone,
  validatePassword,
  loadScript,
} from "utils/common";
import {
  userSelector,
  clearState,
  registerUser,
} from "store/features/authSlice";
import { useNavigate } from "react-router-dom";
import Logomain from "assets/images/creware.png";
import {
  collection,
  getDocs,
  query,
  where,
  arrayUnion,
  updateDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";

import { Icon } from "@mui/material";
import Createable from "containers/Select";
import { fetchSubscriptionPlans } from "services/auth";
import { getIntlPhoneNumber } from "store/features/authSlice";

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [agreement, setAgremment] = useState(true);
  const [intlPhoneNumberArray, setIntlPhoneNumberArray] = useState([]);
  const { intlPhoneNumber, isSuccess, isError, isFetching, message } =
    useSelector(userSelector);

  useEffect(() => {
    if (intlPhoneNumber) {
      const updateIntlNumber = intlPhoneNumber.data.map((item, index) => {
        return {
          label: `${
            item.dial_code.length === 2
              ? `(${item.dial_code})`.padEnd(10)
              : item.dial_code.length === 3
              ? `(${item.dial_code})`.padEnd(9)
              : item.dial_code.length === 4
              ? `(${item.dial_code})`.padEnd(8)
              : `(${item.dial_code})`
          } ${
            item?.name?.length > 15
              ? `${item?.name?.slice(0, 15)}...`
              : item?.name
          }`,
          value: item.dial_code,
          key: item.code,
          phoneLength: item.phoneLength,
        };
      });

      setIntlPhoneNumberArray(updateIntlNumber);
    }
  }, [intlPhoneNumber]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success(message);
      navigate("/login");
      dispatch(clearState());
    }
  }, [isError, message, dispatch, isSuccess, navigate]);

  useEffect(() => {
    dispatch(getIntlPhoneNumber());
  }, []);

  //default fields value
  const defaultHandler = useMemo(() => {
    return {
      name: null,
      phoneNumber: null,
      email: null,
      password: null,
      referenceCode: null,
      countryCode: {
        label: "(+91)     India",
        value: "+91",
        key: "IN",
        phoneLength: 10,
      },
    };
  }, []);

  //default error fields value
  const errorDefault = useMemo(() => {
    return {
      name: false,
      phoneNumber: false,
      email: false,
      password: false,
      referenceCode: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for handling agreement
  const handleSetAgremment = () => setAgremment(!agreement);

  //for registering
  // const [ refProfileCode, setRefProfileCode ] = useState(null)
  const registerHandler = async (
    payResponse,
    paymentData,
    referenceDetails
  ) => {
    if (referenceDetails !== null) {
      let ref = doc(firestore, "profile", referenceDetails.ref);
      await updateDoc(ref, {
        referrals: arrayUnion({
          name: formdata.name,
          date: new Date(),
          amount: paymentData.referralAmount,
          redeemed: false,
        }),
      });
    }

    let referredBy = referenceDetails?.name ?? null;
    dispatch(registerUser({ formdata, payResponse, referredBy, paymentData }));
  };

  const [loader, setLoader] = useState(false);
  const displayRazorpay = async () => {
    let paymentData = subscription;
    let referral = false;
    let referenceDetails = null;
    try {
      setLoader(true);

      let q = query(
        collection(firestore, "profile"),
        where("name", "==", formdata.name)
      );
      let querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        toast.error(
          "Client with this Name already exists, choose different Name"
        );
        setLoader(false);
        return;
      }

      q = query(
        collection(firestore, "profile"),
        where("email", "==", formdata.email)
      );
      querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        toast.error(
          "Client with this Email already exists, choose different Email"
        );
        setLoader(false);
        return;
      }

      if (formdata.referenceCode) {
        q = query(
          collection(firestore, "profile"),
          where("code", "==", formdata.referenceCode)
        );
        // let profileRef;
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          referral = true;
          let docRef = doc.id;
          referenceDetails = { ref: docRef, name: doc.data().name };
        });
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      toast.error("Something went wrong, retry!!");
      return;
    }

    if (!formdata.name) {
      toast.info("Enter name");
      setErrorMessage({
        ...errorMessage,
        name: true,
      });
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (formdata.password) {
      const { isValid, message } = validatePassword(formdata.password);
      if (!isValid) {
        toast.info(message);
        return;
      }
    } else {
      toast.info("Please enter Password");
      setErrorMessage({
        ...errorMessage,
        password: true,
      });
      return;
    }

    if (!subscription) {
      toast.info("Choose a subscription plan");
      return;
    }

    if (!agreement) {
      toast.info("Can't proceed without agreeing with agreement");
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("Razorpay SDK failed to load, retry");
      return;
    } else {
      var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: referral
          ? parseFloat(paymentData.paymentAmount) * 100 -
            parseFloat(paymentData.referralAmount) * 100
          : parseFloat(paymentData.paymentAmount) * 100,
        currency: "INR",
        name: "Fitify",
        description: "Sign up transaction",
        image: Logomain,
        prefill: {
          name: formdata.name,
          email: formdata.email,
          contact: formdata.phoneNumber,
        },
        handler: function (response) {
          response = {
            ...response,
            subscriptionAmount: parseFloat(paymentData.paymentAmount),
            amount: referral
              ? parseFloat(paymentData.paymentAmount) -
                parseFloat(paymentData.referralAmount)
              : parseFloat(paymentData.paymentAmount),
          };
          registerHandler(response, paymentData, referenceDetails);
        },
      };
    }
    var paymentObj = new window.Razorpay(options);
    paymentObj.open();
  };

  const [showPass, setShowPass] = useState(false);

  const fetchSubscriptions = useCallback(async () => {
    try {
      let subs = [];
      setLoader(true);
      subs = await fetchSubscriptionPlans();
      setSubscriptionType(subs);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong, retry");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const [subscriptionType, setSubscriptionType] = useState([]);
  const [subscription, setSubscription] = useState();

  const onSelectSubscrition = (value) => {
    setSubscription(value);
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Register or login to Fitify and enter to the world of better tomorrow for your business."
      image={curved6}
    >
      <Card style={{ overflow: "inherit" }}>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Register to Fitify
          </SuiTypography>
        </SuiBox>

        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput
                placeholder="Name"
                type="text"
                name="name"
                autoComplete="off"
                error={errorMessage?.name}
                value={formdata?.name ?? ""}
                onChange={(e) => {
                  let numberRegex = /^[a-zA-Z ]*$/;
                  let val = e.target.value;
                  let match_ = val.match(numberRegex);
                  if (!match_ && val) {
                    return false;
                  }
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>

            <SuiBox mb={2}>
              <SuiInput
                placeholder="Email"
                type="email"
                name="email"
                error={errorMessage?.email}
                value={formdata?.email ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>

            <SuiBox mb={2}>
              <Createable
                defaultMenuIsOpen
                isOpen
                options={intlPhoneNumberArray}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.900rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    countryCode: e,
                    phoneNumber: null,
                  });
                }}
                value={formdata.countryCode}
              />
            </SuiBox>

            <SuiBox mb={2}>
              <SuiInput
                placeholder="Phone Number"
                type="text"
                name="phoneNumber"
                error={errorMessage?.phoneNumber}
                value={formdata?.phoneNumber ?? ""}
                onChange={(e) => {
                  let numberRegex = /^\d+$/;
                  let val = e.target.value;
                  let match_ = val.match(numberRegex);
                  if (!match_ && val) {
                    return false;
                  }

                  if (
                    formdata.countryCode.key === "IN"
                      ? val.length > 10
                      : val.length > formdata?.countryCode?.phoneLength
                  ) {
                    toast.info("Invalid phone number");
                    return;
                  }

                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>

            <SuiBox mb={2} style={{ display: "flex", position: "relative" }}>
              <SuiInput
                placeholder="Password"
                type={showPass ? "text" : "password"}
                name="password"
                error={errorMessage?.password}
                value={formdata?.password ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
              <Icon
                sx={({
                  typography: { size, fontWeightBold },
                  palette: { dark },
                }) => ({
                  fontSize: `${size.lg} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  strokeWidth: "2px",
                  cursor: "pointer",
                  mt: 2,
                  position: "absolute",
                  right: 10,
                  top: -3,
                })}
                onClick={() => {
                  setShowPass((e) => !e);
                }}
              >
                {!showPass ? "visibility" : "visibility_off"}
              </Icon>
            </SuiBox>

            <SuiBox mb={2}>
              <Createable
                options={subscriptionType ?? []}
                value={subscription}
                style={{ borderRadius: "0.5rem", fontSize: 14, zIndex: 999 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Subscription plan"
                onChange={(e) => {
                  onSelectSubscrition(e);
                }}
              />
            </SuiBox>

            <SuiBox mb={2}>
              <SuiInput
                placeholder="Reference Code (if any)"
                type="text"
                name="referenceCode"
                error={errorMessage?.referenceCode}
                value={formdata?.referenceCode ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>

            <SuiBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SuiTypography>
              <SuiTypography
                component="a"
                href="https://creware.asia/terms-and-conditions/"
                target="_blank"
                variant="button"
                fontWeight="bold"
              >
                Terms and Conditions
              </SuiTypography>
            </SuiBox>

            <SuiBox mt={4} mb={1}>
              <SuiButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={displayRazorpay}
                disabled={isFetching || loader}
              >
                {isFetching || loader ? "Loading . . ." : "sign up"}
              </SuiButton>
            </SuiBox>

            <SuiBox mt={3} textAlign="center">
              <SuiTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
