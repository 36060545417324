import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import React from "react";
import { formatDateField } from "utils/common";
import { calculateBalance } from "utils/common";
import { Button, Icon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function PlanDetails({ activeService, stepVal, onHandler }) {
  return (
    <SuiBox my={2}>
      <SuiBox
        style={{
          border: "1px solid #D2D6DA",
          borderRadius: 5,
          position: "relative",
        }}
        p={1.5}
      >
        <SuiTypography variant="h5">Plan details</SuiTypography>
        {stepVal && stepVal[2] === 0 && (
          <SuiTypography
            component={Button}
            onClick={onHandler}
            variant="body2"
            color="secondary"
            style={{ position: "absolute", right: 0, top: 10 }}
          >
            <Tooltip title={"update personal info"} placement="top">
              {/* <Icon>edit</Icon> */}
            </Tooltip>
          </SuiTypography>
        )}

        <SuiBox mt={3}>
          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Package</SuiTypography>
            <SuiTypography variant="body3">
              {activeService?.serviceName ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Total Amount</SuiTypography>
            <SuiTypography variant="body3">
              INR {activeService?.totalAmount ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Discount</SuiTypography>
            <SuiTypography variant="body3">
              INR {activeService?.discount ?? 0}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Balance</SuiTypography>
            <SuiTypography variant="body3">
              INR{" "}
              {calculateBalance(
                activeService?.totalAmount ?? 0,
                activeService?.discount ?? 0,
                activeService?.amountPaid ?? 0
              )}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Service Instance</SuiTypography>
            <SuiTypography variant="body3">
              {activeService?.serviceInstance ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Payment Mode</SuiTypography>
            <SuiTypography variant="body3">
              {activeService?.paymentMode ?? "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Start Date</SuiTypography>
            <SuiTypography variant="body3">
              {activeService?.startDate
                ? formatDateField(activeService?.startDate)
                : "N/A"}
            </SuiTypography>
          </SuiBox>

          <SuiBox display="flex" justifyContent="space-between" mt={1}>
            <SuiTypography variant="subtitle3">Completion Date</SuiTypography>
            <SuiTypography variant="body3">
              {activeService?.completionDate
                ? formatDateField(activeService?.completionDate)
                : "N/A"}
            </SuiTypography>
          </SuiBox>
        </SuiBox>

        {(!stepVal || stepVal[2] === 1) && (
          <SuiTypography
            variant="caption"
            style={{ display: "block" }}
            mt={1.5}
          >
            Please contact to your fellow nutrition/dietitians to change your
            plan details
          </SuiTypography>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default PlanDetails;
