import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import RecipeTable from "./sub-components/recipes";

import RecipeModal from "./sub-components/modal/AddRecipe";

import { useDispatch, useSelector } from "react-redux";
import {
  recipeSelector,
  getrecipes,
  deleteDocument,
  clearState,
} from "store/features/recipes";

import { toast } from "react-toastify";
import { userSelector } from "store/features/authSlice";
import DeleteModal from "containers/DeleteModal";

function Recipe() {
  const { uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const { recipes, isError, isSuccess, message, isFetching } =
    useSelector(recipeSelector);
  const [selectedItem, setSelectedItem] = useState(null); //for selecting item

  const dispatch = useDispatch();

  //gets recipes lists
  useEffect(() => {
    dispatch(getrecipes({ uid }));
  }, [dispatch, uid]);

  //for deleting recipe
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = (id) => {
    dispatch(deleteDocument({ id }));
  };

  const onDeleteSuccess = useCallback(() => {
    if (deleteModal) {
      setDeleteModal(false);
    }
  }, [deleteModal]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      setLoader(false);
      onDeleteSuccess();
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching, onDeleteSuccess]);

  const [newRecipeModal, setNewRecipeModal] = useState(false);
  const [mode, setMode] = useState("view"); //create or edit [create, edit, view]
  const onViewHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("view");
      setNewRecipeModal(true);
    },
    [setMode, setNewRecipeModal, setSelectedItem]
  );

  const onUpdateHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("edit");
      setNewRecipeModal(true);
    },
    [setMode, setNewRecipeModal, setSelectedItem]
  );

  const onAddHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewRecipeModal(true);
  }, [setMode, setNewRecipeModal, setSelectedItem]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RecipeTable
                recipes={recipes}
                title="Food or Recipe List"
                viewHandler={onViewHandler}
                editHandler={onUpdateHandler}
                addHandler={onAddHandler}
                deleteHandler={(id, name) => {
                  setSelectedItem({ id, name });
                  setDeleteModal(true);
                }}
                adminId={uid}
                loader={loader}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <RecipeModal
        mode={mode}
        open={newRecipeModal}
        setOpenModal={setNewRecipeModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        uid={uid}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        editHandler={onUpdateHandler}
        viewHandler={onViewHandler}
        handleCloseModal={() => {
          setNewRecipeModal(false);
        }}
      />

      {deleteModal && (
        <DeleteModal
          isFetching={isFetching}
          onDeleteHandler={deleteHandler}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </DashboardLayout>
  );
}

export default Recipe;
