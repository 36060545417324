import SuiTypography from "components/SuiTypography";
import ModalImage from "react-modal-image";
import "../../sub-components/common/imageStyling.css";
import { formatDateField } from "utils/common";
import { Icon } from "@mui/material";

export const ImageNowVsEarlierRow = (imageArray, deleteHandler) => {
  const rows = imageArray.map((item, index) => (
    <div className="imageContainer" key={item.image}>
      <Icon
        sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
          fontSize: `${20} !important`,
          fontWeight: `${fontWeightBold} !important`,
          stroke: dark.main,
          position: "absolute",
          strokeWidth: "2px",
          right: 0,
          top: 0,
          cursor: "pointer",
          mt: 0,
        })}
        onClick={() => deleteHandler(item, index, "imageNowVsEarlier")}
      >
        close
      </Icon>
      <div className="Imagesize">
        <ModalImage
          small={item.image}
          large={item.image}
          alt="Hello Fitify!"
          style={styles.modal}
        />
      </div>
      <SuiTypography variant="button" fontWeight="medium">
        {formatDateField(item?.date)}
      </SuiTypography>
    </div>
  ));

  return rows;
};

/**------------------ styling ----------------- */

const styles = {
  modal: {
    width: "146px",
    height: "255px",
    margin: "auto",
    paddingBottom: "20px",
  },
};
