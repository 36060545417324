import { useState, useEffect } from "react";
import theme from "assets/theme";
import { Provider, useSelector, useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";

import routes from "routes";
import brand from "assets/images/logo.png";
import { useSoftUIController, setMiniSidenav } from "context";
import Sidenav from "examples/Sidenav";
import { userSelector } from "./store/features/authSlice";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import Footer from "examples/Footer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Profile from "pages/profile";
import Login from "pages/authentication/login";
import Register from "pages/authentication/register";
import ForgotPassword from "pages/authentication/forgotpassword";
import Dashboard from "pages/dashboard";
import Client from "pages/client";
import ClientDetail from "pages/clientdetail";
import FamilyDetail from "pages/familydetail";
import Lead from "pages/Leads&Appointments/Leads&Appointments";
import Recipes from "pages/recipe";
import Billing from "pages/billing";
import DietChart from "pages/dietchart";
import Offering from "pages/Offering";
import Appointment from "pages/appointment";
// import Customer from 'pages/customer';
import NotFound from "pages/404";
import "./App.css";
import ClientCustomer from "pages/customer";
import Expense from "pages/expense";
import Calculators from "pages/calculator";
import Employee from "pages/employee";
import Faq from "pages/faq";
import AttendanceHistory from "pages/employee/sub-components/attendanceHistory";

const generateRoute = (
  path,
  Component,
  condition = true,
  fallbackPath = "/login"
) => (
  <Route
    path={path}
    element={condition ? <Component /> : <Navigate to={fallbackPath} replace />}
  />
);

const SharedRoutes = () => {
  const { auth, logo, accessModules, isEmployee } = useSelector(userSelector);

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const dispatch_ = useDispatch();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    const authValue = getAuth();
    onAuthStateChanged(authValue, (user) => {
      if (!user) {
        localStorage.clear();
      }
    });
  }, [dispatch_]);

  return (
    <>
      {auth && (
        <Sidenav
          color={sidenavColor}
          brand={logo ?? brand}
          routes={routes(accessModules, isEmployee)}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}

      <Routes>
        {generateRoute("/login", Login, !auth, "/dashboard")}
        {generateRoute("/register", Register, !auth, "/dashboard")}
        {generateRoute("/forgot-password", ForgotPassword, !auth, "/dashboard")}

        {!isEmployee
          ? generateRoute("/profile", Profile, auth, "/login")
          : accessModules?.includes("profile") &&
            generateRoute("/profile", Profile, auth, "/login")}

        {!isEmployee
          ? generateRoute("/dashboard", Dashboard, auth, "/login")
          : accessModules?.includes("dashboard") &&
            generateRoute("/dashboard", Dashboard, auth, "/login")}

        {!isEmployee
          ? generateRoute("/offering", Offering, auth, "/login")
          : accessModules?.includes("offering") &&
            generateRoute("/offering", Offering, auth, "/login")}

        {!isEmployee
          ? generateRoute("/clients", Client, auth, "/login")
          : accessModules?.includes("clients") &&
            generateRoute("/clients", Client, auth, "/login")}

        {generateRoute("/clients/:id", ClientDetail, auth, "/login")}

        {generateRoute("/family/:id", FamilyDetail, auth, "/login")}

        {!isEmployee
          ? generateRoute("/recipe", Recipes, auth, "/login")
          : accessModules?.includes("recipe") &&
            generateRoute("/recipe", Recipes, auth, "/login")}

        {!isEmployee
          ? generateRoute("/leads", Lead, auth, "/login")
          : accessModules?.includes("leads") &&
            generateRoute("/leads", Lead, auth, "/login")}

        {generateRoute("/appointments", Appointment, auth, "/login")}

        {!isEmployee
          ? generateRoute("/billing", Billing, auth, "/login")
          : accessModules?.includes("billing") &&
            generateRoute("/billing", Billing, auth, "/login")}

        {!isEmployee
          ? generateRoute("/dietchart", DietChart, auth, "/login")
          : accessModules?.includes("dietchart") &&
            generateRoute("/dietchart", DietChart, auth, "/login")}

        {!isEmployee
          ? generateRoute("/calculators", Calculators, auth, "/login")
          : accessModules?.includes("calculators") &&
            generateRoute("/calculators", Calculators, auth, "/login")}

        {!isEmployee && generateRoute("/employee", Employee, auth, "/login")}

        {!isEmployee &&
          generateRoute(
            "/employee/:employeeId",
            AttendanceHistory,
            auth,
            "/login"
          )}

        {!isEmployee && generateRoute("/faq", Faq, auth, "/login")}

        {generateRoute("/expense", Expense, auth, "/login")}

        {generateRoute(
          "/customer/:adminId/:clientId",
          ClientCustomer,
          !auth,
          "/login"
        )}

        {generateRoute("/notfound", NotFound, !auth)}

        {generateRoute(
          "*",
          () => (
            <Navigate
              to={
                !isEmployee
                  ? "/dashboard"
                  : accessModules?.includes("dashboard")
                  ? "/dashboard"
                  : accessModules?.[0]
              }
              replace
            />
          ),
          auth,
          "/login"
        )}
      </Routes>
    </>
  );
};

function App() {
  let persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={<>Loading</>} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router basename="/">
            <main>
              <SharedRoutes />
              <ToastContainer
                style={{
                  fontSize: 17,
                  fontWeight: 500,
                  zIndex: 10000,
                }}
              />
              <Footer />
            </main>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
