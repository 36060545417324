import SuiTypography from "components/SuiTypography";
import { CustomWidthTooltip } from "containers/Tooltip";
import { globaleDateTimeFormatter } from "utils/helper";
import TableAction from "containers/table-action";

export const AttendanceRow = (
  attendanceArray,
  navigate,
  editHandler,
  deleteHandler,
  toggleHandler,
  toggleOptions,
  size,
  toggleValues
) => {
  const rows = attendanceArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Date/Time (Marked)": (
        <SuiTypography variant="button" fontWeight="medium">
          {Array.isArray(item?.attendance)
            ? item?.attendance?.[0]?.updatedAt
              ? item?.attendance?.[0]?.updatedAt
              : "Not Marked"
            : item?.attendance?.updatedAt
            ? item?.attendance?.updatedAt
            : "Not Marked"}
        </SuiTypography>
      ),
      "Date/Time (Modified)": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.updatedAt ? globaleDateTimeFormatter(item?.updatedAt) : "N/A"}
        </SuiTypography>
      ),

      "Employee Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
            textTransform: "capitalize",
          }}
          onClick={() => {
            navigate(`/employee/${item?.id}`);
          }}
        >
          {item?.name && item?.name?.length > 20
            ? `${item?.name?.slice(0, 20)}...`
            : item?.name}
        </SuiTypography>
      ),

      "Job Role": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ textTransform: "capitalize" }}
        >
          {item?.current_role === "part" ? "Part Time" : "Full Time"}
        </SuiTypography>
      ),

      Email: (
        <CustomWidthTooltip title={item?.email ?? ""} placement="bottom">
          <SuiTypography variant="button" fontWeight="medium">
            {item?.email?.length > 40
              ? `${item?.email?.slice(0, 40)}...`
              : item?.email}
          </SuiTypography>
        </CustomWidthTooltip>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          toggleHandler={(option, index, slotIndex) =>
            toggleHandler(option, index, slotIndex)
          }
          options={toggleOptions}
          size={size}
          value={toggleValues[index]?.presence}
          defaultValues={toggleValues[index]?.presence}
          toggleValues={toggleValues}
          index={index}
        />
      ),
    };
  });
  return rows;
};
