import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SuiInput from 'components/SuiInput';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

const NutritionModal = ({ open, onClose, onAddNutrition }) => {
    const [grams, setGrams] = useState('');

    const handleAdd = () => {
        if (grams) {
            onAddNutrition(grams);
            setGrams('');
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <SuiTypography variant="h6" mb={2}>
                    Add Nutrition Value
                </SuiTypography>
                <SuiInput
                    placeholder="Enter the value"
                    type="number"
                    value={grams}
                    onChange={(e) => setGrams(e.target.value)}
                />
                <Box mt={2} display="flex" justifyContent="center">
                    <SuiButton
                        component={Button}
                        color="dark"
                        variant="gradient"
                        onClick={handleAdd}>
                        Add Nutrition Value
                    </SuiButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default NutritionModal;
