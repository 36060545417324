import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import Grid from "@mui/material/Grid";

import { calcPriceWT } from "utils/common";
import CustomButton from "containers/CustomButton";
import { EditPayment } from "services/clientdetails";
import { AddPayment } from "services/clientdetails";
import ModalHeader from "containers/modal-header";

function PaymentModal({
  open,
  setSelectedClient,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  services,
  mode,
  setMode,
}) {
  //for service options list
  const [serviceOptions, setServiceOptions] = useState([]);
  useEffect(() => {
    let serviceOptionsArray = services.map((item) => {
      return {
        label: item.serviceName,
        value: item.serviceName,
        totalAmount: calcPriceWT(item.sellingPriceWOT, item.gst),
        key: item.id,
      };
    });
    setServiceOptions(serviceOptionsArray);
  }, [services]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      startDate: null,
      completionDate: null,
      service: null,
      discount: 0,
      amountPaid: 0,
      serviceInstance: null,
      paymentMode: null,
      complementaryService: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      startDate: false,
      completionDate: false,
      service: false,
      discount: false,
      amountPaid: false,
      serviceInstance: false,
      paymentMode: false,
      complementaryService: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        serviceInstance: {
          value: selectedItem?.serviceInstance,
          label: selectedItem?.serviceInstance,
        },
        paymentMode: {
          value: selectedItem?.paymentMode,
          label: selectedItem?.paymentMode,
        },
        service: {
          value: selectedItem.serviceId,
          label: selectedItem.serviceName,
          totalAmount: selectedItem.totalAmount,
        },
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.startDate) {
      toast.error("Please fill Start Date field");
      setErrorMessage({
        ...errorMessage,
        startDate: true,
      });
      return;
    }

    if (!formdata.completionDate) {
      toast.error("Please fill Completion Date field");
      setErrorMessage({
        ...errorMessage,
        completionDate: true,
      });
      return;
    }

    if (new Date(formdata.startDate) > new Date(formdata.completionDate)) {
      toast.info("Completion Date should be greater than Start Date");
      setErrorMessage({
        ...errorMessage,
        completionDate: true,
      });
      return;
    }

    if (!formdata.service) {
      toast.error("Please select Service Taken field");
      setErrorMessage({
        ...errorMessage,
        service: true,
      });
      return;
    }

    if (formdata.discount === null || formdata.discount === "") {
      toast.error("Please fill Discount field");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata.amountPaid === null || formdata.amountPaid === "") {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (!formdata.serviceInstance) {
      toast.error("Please select Service Instance field");
      setErrorMessage({
        ...errorMessage,
        serviceInstance: true,
      });
      return;
    }

    if (!formdata.paymentMode) {
      toast.error("Please select Payment Mode field");
      setErrorMessage({
        ...errorMessage,
        paymentMode: true,
      });
      return;
    }

    if (!formdata.complementaryService) {
      toast.error("Please fill Complementary Service field");
      setErrorMessage({
        ...errorMessage,
        complementaryService: true,
      });
      return;
    }

    setLoader(true);
    try {
      let data;
      if (selectedItem) {
        data = await EditPayment({ formdata, id: id });
        setSelectedClient((e) => {
          e = {
            ...e,
            services: data,
          };
          return e;
        });
        toast.success("Successfully updated client");
      } else {
        data = await AddPayment({ formdata, id: id });
        setSelectedClient((e) => {
          e = {
            ...e,
            services: e.services ? [data, ...e.services] : [data],
          };
          return e;
        });
        toast.success("Successfully added service");
      }

      handleCloseModal();

      setTimeout(() => {
        setSelectedItem(null);
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 500);
    } catch (e) {
      setLoader(false);
      toast.error(e?.message ?? "Something went wrong retry");
    }
  };

  const [serviceInstanceOptions] = useState([
    { value: "First Time", label: "First Time", key: 1 },
    { value: "Extension", label: "Extension", key: 2 },
    { value: "Returning", label: "Returning", key: 3 },
  ]);

  const [paymentOptions] = useState([
    { value: "Cash", label: "Cash", key: 1 },
    { value: "Online", label: "Online", key: 2 },
  ]);

  const calculateBalance = () => {
    if (formdata.totalAmount) {
      return (
        parseFloat(formdata.totalAmount) -
        parseFloat(formdata.discount ?? 0) -
        parseFloat(formdata.amountPaid)
      ).toFixed(2);
    }
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader mode={mode} title="Service, Payment & Balance History" />

        <Grid item>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setMode("edit");
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setMode("view");
              }}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Start Date
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="date"
                  name="startDate"
                  readOnly={mode === "view"}
                  error={errorMessage?.startDate}
                  value={formdata.startDate ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Completion Date
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="date"
                  name="completionDate"
                  readOnly={mode === "view"}
                  error={errorMessage?.completionDate}
                  value={formdata.completionDate ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Taken
                </SuiTypography>
                <Createable
                  options={serviceOptions}
                  disabled={mode === "view"}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      service: e,
                      totalAmount: e.totalAmount,
                    });
                  }}
                  value={formdata.service}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Total Amount
                </SuiTypography>
                <SuiTypography mb={1} variant="h7">
                  INR {formdata?.service?.totalAmount ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Discount (In INR)
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="discount"
                  readOnly={mode === "view"}
                  error={errorMessage?.discount}
                  value={formdata.discount ?? ""}
                  onChange={(e) => {
                    if (formdata.service === null) {
                      toast.info("Select package");
                      return;
                    }

                    if (e.target.value < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        [e.target.name]: 0,
                      });
                      return;
                    }

                    if (e.target.value > parseFloat(formdata.totalAmount)) {
                      toast.info("Can' exceed total amount");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="number"
                  name="amountPaid"
                  readOnly={mode === "view"}
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                INR{" "}
                {isNaN(calculateBalance()) ? "-" : calculateBalance() ?? "-"}
              </SuiTypography>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Instance
                </SuiTypography>
                <Createable
                  disabled={mode === "view"}
                  options={serviceInstanceOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      serviceInstance: e,
                    });
                  }}
                  value={formdata.serviceInstance}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Payment Mode
                </SuiTypography>
                <Createable
                  disabled={mode === "view"}
                  options={paymentOptions}
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      paymentMode: e,
                    });
                  }}
                  value={formdata.paymentMode}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Complementary Service?
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  readOnly={mode === "view"}
                  name="complementaryService"
                  placeholder="Some other service ..."
                  rows={6}
                  multiline
                  error={errorMessage?.complementaryService}
                  value={formdata.complementaryService ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default PaymentModal;
