import {
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { v4 as uuidv4 } from "uuid";

export const checkProductExists = async (productName, adminId) => {
  const productQuery = query(
    collection(firestore, "product"),
    where("productName", "==", productName),
    where("adminId", "==", adminId)
  );

  const querySnapshot = await getDocs(productQuery);
  return !querySnapshot.empty;
};

export const addMultipleProducts = async (products) => {
  const addedProducts = [];
  try {
    const batchSize = 10;
    const batches = [];
    const writeBatches = [];
    const writeBatchSize = 500;

    for (let i = 0; i < products.length; i += batchSize) {
      const batch = products
        .slice(i, i + batchSize)
        .map((ele) => ele.productName);
      batches.push(batch);
    }

    for (let i = 0; i < products.length; i += writeBatchSize) {
      const batch = products.slice(i, i + writeBatchSize);
      writeBatches.push(batch);
    }

    for (const batch of writeBatches) {
      const batchWrite = writeBatch(firestore);
      for (const product of batch) {
        const productId = uuidv4();
        const newProductRef = doc(firestore, "product", productId);
        addedProducts.push({
          ...product,
          id: productId,
        });
        batchWrite.set(newProductRef, { ...product });
      }
      await batchWrite.commit();
    }

    return addedProducts;
  } catch (e) {
    throw e;
  }
};

export const checkServiceExists = async (serviceName, adminId) => {
  const serviceQuery = query(
    collection(firestore, "service"),
    where("serviceName", "==", serviceName),
    where("adminId", "==", adminId)
  );

  const querySnapshot = await getDocs(serviceQuery);
  return !querySnapshot.empty;
};

export const addMultipleServices = async (services) => {
  const addedServices = [];
  try {
    const batchSize = 10;
    const batches = [];
    const writeBatches = [];
    const writeBatchSize = 500;

    for (let i = 0; i < services.length; i += batchSize) {
      const batch = services
        .slice(i, i + batchSize)
        .map((ele) => ele.serviceName);
      batches.push(batch);
    }

    for (let i = 0; i < services.length; i += writeBatchSize) {
      const batch = services.slice(i, i + writeBatchSize);
      writeBatches.push(batch);
    }

    for (const batch of writeBatches) {
      const batchWrite = writeBatch(firestore);
      for (const service of batch) {
        const serviceId = uuidv4();
        const newServiceRef = doc(firestore, "service", serviceId);
        addedServices.push({
          ...service,
          id: serviceId,
        });
        batchWrite.set(newServiceRef, { ...service });
      }
      await batchWrite.commit();
    }

    return addedServices;
  } catch (e) {
    throw e;
  }
};

export const addMultipleClients = async (clients) => {
  const addedClients = [];
  try {
    const writeBatches = [];
    const writeBatchSize = 500;

    for (let i = 0; i < clients.length; i += writeBatchSize) {
      const batch = clients.slice(i, i + writeBatchSize);
      writeBatches.push(batch);
    }

    for (const batch of writeBatches) {
      const batchWrite = writeBatch(firestore);
      for (const client of batch) {
        const clientId = uuidv4();
        const newClientRef = doc(firestore, "client", clientId);
        addedClients.push({
          ...client,
          ...client.services[0],
          serviceNo: client.services[0].id,
          id: clientId,
          serviceName: client.services[0].serviceName,
        });
        // delete client.services[0].serviceName;
        batchWrite.set(newClientRef, { ...client });
      }
      await batchWrite.commit();
    }

    return addedClients;
  } catch (e) {
    throw e;
  }
};
