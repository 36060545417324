import React, { useEffect } from 'react'
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Grid from "@mui/material/Grid";


function DashboardChart({ title, data, text, yearArray, tag, setChartYear, chartYear, loader }) {

    useEffect(()=>{
        yearArray.sort((a, b)=>{
            return b.value - a.value
        })
    },[yearArray])

    return (
        <Grid item xs={12} lg={6} xl={6}>
            <GradientLineChart
                loader={loader}
                title={title}
                para={text}
                yearArray={yearArray}
                tag={tag}
                setChartYear={setChartYear}
                chartYear={chartYear}
                height="10.25rem"
                chart={data}
            />        
        </Grid>
    )
}

export default DashboardChart