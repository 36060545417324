import { useCallback, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

import AppointmentTable from "./sub-components/appointments";
import { sortByDate } from "utils/helper";

import AppointmentModel from "./sub-components/models/AppointmentModel";
import { useDispatch, useSelector } from "react-redux";
import {
  LeadSelector,
  clearState,
  getAppointments,
} from "store/features/leads";
import { toast } from "react-toastify";
import { userSelector } from "store/features/authSlice";
import { useLocation } from "react-router-dom";

function Appointment() {
  const { intlPhoneNumber, uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const { appointments, isError, isSuccess, message, isFetching } =
    useSelector(LeadSelector);
  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation
  const dispatch = useDispatch();
  const [appointmentArray, setAppointmentArray] = useState([]);
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const location = useLocation();
  const [isAppointDashboard, setIsAppointDashboard] = useState("");
  const [mode, setMode] = useState("view"); //create or edit [create, edit, view]
  const [intlPhoneNumberArray, setIntlPhoneNumberArray] = useState([]);

  useEffect(() => {
    if (intlPhoneNumber) {
      const updateIntlNumber = intlPhoneNumber.data.map((item, index) => {
        return {
          label: `${
            item.dial_code.length === 2
              ? `(${item.dial_code})`.padEnd(10)
              : item.dial_code.length === 3
              ? `(${item.dial_code})`.padEnd(9)
              : item.dial_code.length === 4
              ? `(${item.dial_code})`.padEnd(8)
              : `(${item.dial_code})`
          } ${
            item?.name?.length > 15
              ? `${item?.name?.slice(0, 15)}...`
              : item?.name
          }`,
          value: item.dial_code,
          key: item.code,
          phoneLength: item.phoneLength ?? 10,
        };
      });

      setIntlPhoneNumberArray(updateIntlNumber);
    }
  }, [intlPhoneNumber]);

  //gets client list
  useEffect(() => {
    dispatch(getAppointments({ uid }));
  }, [dispatch, uid]);

  useEffect(() => {
    const handleSuccess = () => {
      toast.success(message);
      setLoader(false);
      dispatch(clearState());
    };

    const handleError = () => {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    };

    const handleFetching = () => {
      setLoader(true);
    };

    if (isSuccess) {
      handleSuccess();
    } else if (isError) {
      handleError();
    } else if (isFetching) {
      handleFetching();
    } else {
      setLoader(false);
    }
  }, [isSuccess, isError, isFetching, message, dispatch]);

  useEffect(() => {
    const appointments1 = sortByDate(appointments);
    setAppointmentArray(appointments1);
  }, [appointments]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("create");

    if (id) {
      setNewAppointmentModal(true);
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isAppointmentCreate = searchParams.get("appointcreate");
    setIsAppointDashboard(isAppointmentCreate);
  }, [location?.search]);

  useEffect(() => {
    if (isAppointDashboard === "1") {
      setMode("create");
      setNewAppointmentModal(true);
    }
  }, [isAppointDashboard]);

  const onViewHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("view");
      setNewAppointmentModal(true);
    },
    [setMode, setNewAppointmentModal, setSelectedItem]
  );

  const onUpdateHandler = useCallback(
    (item) => {
      setSelectedItem(item);
      setMode("edit");
      setNewAppointmentModal(true);
    },
    [setMode, setNewAppointmentModal, setSelectedItem]
  );

  const onAddHandler = useCallback(() => {
    setSelectedItem(null);
    setMode("create");
    setNewAppointmentModal(true);
  }, [setMode, setNewAppointmentModal, setSelectedItem]);

  return (
    <>
      {/* <DashboardNavbar /> */}
      <SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppointmentTable
                appointments={appointmentArray}
                title="Appointments"
                loader={loader}
                viewHandler={onViewHandler}
                updateHandler={onUpdateHandler}
                addHandler={onAddHandler}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <AppointmentModel
        mode={mode}
        open={newAppointmentModal}
        setOpenModal={setNewAppointmentModal}
        isSuccess={isSuccess}
        isError={isError}
        isFetching={isFetching}
        loader={loader}
        setLoader={setLoader}
        message={message}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        appointmentArray={appointmentArray}
        uid={uid}
        handleCloseModal={() => {
          setNewAppointmentModal(false);
        }}
        editHandler={onUpdateHandler}
        viewHandler={onViewHandler}
        intlPhoneNumberArray={intlPhoneNumberArray}
      />
    </>
  );
}

export default Appointment;
