import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  limit,
  arrayUnion,
  where,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import { firestore, uploadFile } from "firebaseConfig";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

//for getting family list name
export const getClientFamilies = async ({ uid }) => {
  try {
    const q = query(
      collection(firestore, "client"),
      where("adminId", "==", uid),
      limit(20)
    );
    let familyObj = {},
      familyArray = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().familyName && !familyObj[doc.data().familyName]) {
        familyObj[doc.data().familyName] = 1;
        familyArray.push({
          label: doc.data().familyName,
          value: doc.data().familyName,
        });
      }
    });
    return familyArray;
  } catch (e) {
    throw e;
  }
};

//Edit product
export const EditClient = async ({ formdata }) => {
  try {
    const q = query(
      collection(firestore, "client"),
      where("phoneNumber", "==", formdata.phoneNumber),
      where("adminId", "==", formdata.adminId)
    );
    const querySnapshot = await getDocs(q);
    let checkName = false;
    const p = query(
      collection(firestore, "client"),
      where("name", "==", formdata.clientName),
      where("adminId", "==", formdata.adminId)
    );
    const querySnapshot1 = await getDocs(p);
    let checkName1 = false;

    const r = query(
      collection(firestore, "client"),
      where("email", "==", formdata.email),
      where("adminId", "==", formdata.adminId)
    );
    const querySnapshot2 = await getDocs(r);
    let checkName2 = false;

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        if (doc.id !== formdata.id) {
          checkName = true;
        }
      });
      if (checkName === true) {
        throw new Error(
          "Client with this Phone Number already exists, choose different Phone Number"
        );
      }
    }
    if (!querySnapshot1.empty) {
      querySnapshot1.forEach((doc) => {
        if (doc.id !== formdata.id) {
          checkName1 = true;
        }
      });
      if (checkName1 === true) {
        throw new Error(
          "Client with this Name already exists, choose different Name"
        );
      }
    }
    if (!querySnapshot2.empty) {
      querySnapshot2.forEach((doc) => {
        if (doc.id !== formdata.id) {
          checkName2 = true;
        }
      });
      if (checkName2 === true) {
        throw new Error(
          "Client with this Email already exists, choose different Email"
        );
      }
    }

    const ref = doc(firestore, "client", formdata.id);

    //for customer unique id
    let customerCount = 0;
    if (formdata.customerId === undefined) {
      const qv1 = query(
        collection(firestore, "client"),
        where("adminId", "==", formdata.adminId)
      );
      const querySnapshotv1 = await getDocs(qv1);
      querySnapshotv1.forEach((doc) => {
        let cusId = doc.data().customerId;
        if (cusId !== undefined) {
          if (parseInt(cusId) > customerCount) {
            customerCount = cusId;
          }
        }
      });
    }

    const updatedData = {
      name: formdata.clientName,
      customerId: formdata.customerId ?? customerCount + 1,
      phoneNumber: formdata.phoneNumber,
      email: formdata.email,
      updatedAt: new Date(),
      address: formdata.address,
      gender: formdata.gender.value,
      dob: formdata.dob,
      anniversary: formdata.anniversary ?? "",
      occupation: formdata.occupation,
      age: formdata.age,
      leadFrom: formdata.leadFrom.value,
      preference: formdata.preference.value,
      referredBy: formdata.referredBy,
      status: formdata.status.value,
      notes: formdata.notes ?? "",
    };

    if (formdata.familyName && formdata.familyName.value) {
      await updateDoc(ref, {
        ...updatedData,
        familyName: formdata.familyName.value,
      });

      return {
        ...updatedData,
        familyName: formdata.familyName.value,
        id: formdata.id,
      };
    } else {
      await updateDoc(ref, {
        ...updatedData,
      });

      return { ...updatedData, id: formdata.id };
    }
  } catch (e) {
    throw e;
  }
};

//for getting selected client details
export const getSelectedClient = async ({ id }) => {
  try {
    let data = [];

    const ref = doc(firestore, "client", id);

    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      data = snapshot.data();

      let updatedAt;
      let createdAt;
      if (snapshot.data().createdAt) {
        createdAt = snapshot.data().createdAt;
        createdAt =
          createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
        createdAt = moment.utc(createdAt);
      }

      if (snapshot.data().updatedAt) {
        updatedAt = snapshot.data().updatedAt;
        updatedAt =
          updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
        updatedAt = moment.utc(updatedAt);
      }
      return { ...data, updatedAt, createdAt, id: id };
    } else {
      throw new Error("Client not found!!!");
    }
  } catch (e) {
    throw e;
  }
};

export const getSelectedClientDetails = async ({ id }) => {
  try {
    let data = [];

    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);

    if (snapshot.exists()) {
      data = snapshot.data();

      // Sort the dietary array based on createdAt in descending order
      if (data.measurement && Array.isArray(data.measurement)) {
        data.measurement.sort(
          (a, b) => b.createdAt.seconds - a.createdAt.seconds
        );
      }
      if (data.vital && Array.isArray(data.vital)) {
        data.vital.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      }

      return { ...data, id: id };
    } else {
      await setDoc(doc(firestore, "clientdetail", id), {});

      return {};
    }
  } catch (e) {
    throw e;
  }
};

//GET service list
export const getServiceInventory = async ({ uid }) => {
  try {
    let data = [];
    const q = query(
      collection(firestore, "service"),
      where("adminId", "==", uid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let timestamp = null;
      let updatedAt = null;
      if (doc.data().createdAt) {
        let createdAt = doc.data().createdAt;
        timestamp =
          createdAt?.nanoseconds / 1000000 + createdAt?.seconds * 1000;
        timestamp = moment.utc(timestamp);
      }

      if (doc.data().updatedAt) {
        updatedAt = doc.data().updatedAt;
        updatedAt =
          updatedAt?.nanoseconds / 1000000 + updatedAt?.seconds * 1000;
        updatedAt = moment.utc(updatedAt);
      }

      data.push({
        ...doc.data(),
        createdAt: timestamp,
        id: doc.id,
        updatedAt,
      });
    });

    data.sort(function (a, b) {
      let createdAt1 = a.createdAt._d;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.createdAt._d;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    return [...data];
  } catch (e) {
    throw e;
  }
};

//Add payment of client
export const AddPayment = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "client", id);

    let paymentData = {
      serviceId: formdata.service.value,
      serviceName: formdata.service.label,
      totalAmount: parseFloat(formdata.totalAmount),
      purchasedAt: new Date(),
      startDate: formdata.startDate,
      completionDate: formdata.completionDate,
      serviceInstance: formdata.serviceInstance.value,
      paymentMode: formdata.paymentMode.value,
      amountPaid: parseFloat(formdata.amountPaid),
      discount: parseFloat(formdata.discount ?? 0),
      complementaryService: formdata.complementaryService,
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();

    if (snapshot.exists()) {
      if (data?.services && data?.services?.length > 0) {
        await updateDoc(ref, {
          services: arrayUnion({ ...paymentData, id: docId }),
        });

        return { ...paymentData, id: docId };
      } else {
        await updateDoc(ref, {
          services: [{ ...paymentData, id: docId }],
        });

        return { ...paymentData, id: docId };
      }
    } else {
      throw new Error("Client not found!!!");
    }
  } catch (e) {
    throw e;
  }
};

//Edit payment of client
export const EditPayment = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "client", id);

    let paymentData = {
      serviceId: formdata.service.value,
      serviceName: formdata.service.label,
      totalAmount: parseFloat(formdata.totalAmount),
      purchasedAt: new Date(),
      startDate: formdata.startDate,
      completionDate: formdata.completionDate,
      paymentMode: formdata.paymentMode.value,
      serviceInstance: formdata.serviceInstance.value,
      amountPaid: parseFloat(formdata.amountPaid),
      discount: parseFloat(formdata.discount ?? 0),
      complementaryService: formdata.complementaryService,
    };
    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let serviceArray = data?.services?.map((item) => {
      if (formdata.id === item.id) {
        return { ...item, ...paymentData, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      services: serviceArray,
    });

    return serviceArray;
  } catch (e) {
    throw e;
  }
};

//Add payment of client
export const AddPaymentConsultation = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "client", id);

    let paymentData = {
      serviceId: formdata.service.value,
      serviceName: formdata.service.label,
      totalAmount: parseFloat(formdata.totalAmount),
      createdAt: new Date(),
      paymentMode: formdata.paymentMode.value,
      amountPaid: parseFloat(formdata.amountPaid),
      discount: parseFloat(formdata.discount ?? 0),
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();

    if (snapshot.exists()) {
      if (data?.consultation && data?.consultation?.length > 0) {
        await updateDoc(ref, {
          consultation: arrayUnion({ ...paymentData, id: docId }),
        });

        return { ...paymentData, id: docId };
      } else {
        await updateDoc(ref, {
          consultation: [{ ...paymentData, id: docId }],
        });

        return { ...paymentData, id: docId };
      }
    } else {
      throw new Error("Client not found!!!");
    }
  } catch (e) {
    throw e;
  }
};

//Edit payment of client
export const EditPaymentConsultation = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "client", id);
    let paymentData = {
      serviceId: formdata.service.value,
      serviceName: formdata.service.label,
      totalAmount: parseFloat(formdata.totalAmount),
      createdAt: new Date(),
      paymentMode: formdata.paymentMode.value,
      amountPaid: parseFloat(formdata.amountPaid),
      discount: parseFloat(formdata.discount ?? 0),
      assignDietitian: formdata?.assignDietitian,
    };
    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let service = {};

    let serviceArray = data?.consultation?.map((item) => {
      if (formdata.serviceNo === item.id) {
        service = { ...item, ...paymentData, updatedAt: new Date() };
        return service;
      }
      return item;
    });

    await updateDoc(ref, {
      consultation: serviceArray,
    });

    return { ...service };
  } catch (e) {
    throw e;
  }
};

//Add dietary of client
export const AddDietary = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);
    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();
    if (snapshot.exists()) {
      if (data.dietary) {
        await updateDoc(ref, {
          dietary: arrayUnion({
            ...formdata,
            id: docId,
            createdAt: new Date(),
          }),
        });
      } else {
        await updateDoc(ref, {
          dietary: [{ ...formdata, id: docId, createdAt: new Date() }],
        });
      }
    }

    return { ...formdata, id: docId, createdAt: new Date() };
  } catch (e) {
    throw e;
  }
};

//Edit dietary of client
export const EditDietary = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let dietaryArray = data?.dietary?.map((item) => {
      if (formdata.id === item.id) {
        return { ...formdata, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      dietary: dietaryArray,
    });

    return dietaryArray ?? [];
  } catch (e) {
    throw e;
  }
};

//Remove dietary of client
export const RemoveDietary = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    await updateDoc(ref, {
      dietary: arrayRemove(formdata),
    });

    return { formdata };
  } catch (e) {
    throw e;
  }
};

//Add physical of client
export const AddPhysical = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let physicalData = {
      drinkAlcohol: formdata.drinkAlcohol?.value ?? "",
      chewTobacco: formdata.chewTobacco?.value ?? "",
      smokeCigarettes: formdata.smokeCigarettes?.value ?? "",
      lifestyle: formdata.lifestyle?.value ?? "",
      dailyActivity: formdata.dailyActivity.value ?? "",
      weightLossType: formdata.weightLossType.value ?? "",
      difficultyInWeightLoss: formdata.difficultyInWeightLoss.value ?? "",

      physicalActivities: formdata?.physicalActivities ?? "",

      awakeLateNight: formdata?.awakeLateNight ?? "",
      snackAtLateNight: formdata?.snackAtLateNight ?? "",
      gymRoutine: formdata?.gymRoutine ?? "",
      hourOfSleep: formdata?.hourOfSleep ?? "",
      createdAt: new Date(),
      timestamp: formdata?.timestamp ?? "",
      id: uuidv4(),
    };

    const snapshot = await getDoc(ref);

    let data = snapshot.data();
    if (snapshot.exists()) {
      if (data.physical) {
        await updateDoc(ref, {
          physical: arrayUnion({ ...physicalData }),
        });
      } else {
        await updateDoc(ref, {
          physical: [{ ...physicalData }],
        });
      }
    }

    return { ...physicalData };
  } catch (e) {
    throw e;
  }
};

//Edit physical data of client
export const EditPhysical = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let physicalData = {
      id: formdata.id ?? uuidv4(),
      drinkAlcohol: formdata.drinkAlcohol.value,
      dailyActivity: formdata.dailyActivity.value,
      weightLossType: formdata.weightLossType.value,
      difficultyInWeightLoss: formdata.difficultyInWeightLoss.value,
      chewTobacco: formdata.chewTobacco.value,
      smokeCigarettes: formdata.smokeCigarettes.value,
      lifestyle: formdata.lifestyle.value,
      physicalActivities: formdata.physicalActivities,
      awakeLateNight: formdata.awakeLateNight,
      snackAtLateNight: formdata.snackAtLateNight,
      gymRoutine: formdata.gymRoutine,
      hourOfSleep: formdata.hourOfSleep,
      timestamp: formdata.timestamp,
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let physicalArray = data?.physical?.map((item) => {
      if (formdata.id === item.id) {
        return { ...physicalData, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      physical: physicalArray,
    });

    return physicalArray ?? [];
  } catch (e) {
    throw e;
  }
};

//Add biochemical of client
export const AddBiochemical_Dietry = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let biochemicalData = {
      ...formdata,
      allergicFrom: formdata.allergicFrom ?? [],
      sufferingFrom: formdata?.sufferingFrom ?? [],
      id: uuidv4(),
      rashesOnSkin: formdata.rashesOnSkin?.value ?? "",
      obesityInFamily: formdata.obesityInFamily?.value ?? "",
      smoke: formdata.smoke?.value ?? "",
      alcohol: formdata.alcohol?.value ?? "",
      heartiestMeal: formdata.heartiestMeal?.value ?? "",
      skippedMeal: formdata.skippedMeal?.value ?? "",
      fastFoodConsumption: formdata.fastFoodConsumption?.value ?? "",
      softDrinks: formdata.softDrinks?.value ?? "",
      isFastingObserve: formdata.isFastingObserve?.value ?? "",
      foodsYouEnjoy: formdata.foodsYouEnjoy ?? [],
      observeFasting:
        formdata?.isFastingObserve?.value === "Yes"
          ? formdata.observeFasting
          : "",
      isLooseWeightTried: formdata.isLooseWeightTried?.value ?? "",
      looseWeightProgramme:
        formdata?.isLooseWeightTried?.value === "Yes"
          ? formdata.looseWeightProgramme
          : "",
      weightLost: formdata.weightLost,
      weightTimePeriod: formdata.weightTimePeriod,
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    if (snapshot.exists()) {
      if (data.biochemical) {
        await updateDoc(ref, {
          biochemical: arrayUnion({ ...biochemicalData }),
        });
      } else {
        await updateDoc(ref, {
          biochemical: [{ ...biochemicalData }],
        });
      }
    }

    return { ...biochemicalData };
  } catch (e) {
    throw e;
  }
};

//Edit biochemical of client
export const EditBiochemical_Dietry = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let biochemicalData = {
      ...formdata,
      rashesOnSkin: formdata.rashesOnSkin?.value ?? "",
      obesityInFamily: formdata.obesityInFamily?.value ?? "",
      smoke: formdata.smoke?.value ?? "",
      alcohol: formdata.alcohol?.value ?? "",
      heartiestMeal: formdata.heartiestMeal?.value ?? "",
      skippedMeal: formdata.skippedMeal?.value ?? "",
      fastFoodConsumption: formdata.fastFoodConsumption?.value ?? "",
      softDrinks: formdata.softDrinks?.value ?? "",
      isFastingObserve: formdata.isFastingObserve?.value ?? "",
      foodsYouEnjoy: formdata.foodsYouEnjoy ?? [],

      observeFasting:
        formdata?.isFastingObserve?.value === "Yes"
          ? formdata.observeFasting
          : "",
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let biochemicalArray = data?.biochemical?.map((item) => {
      if (formdata.id === item.id) {
        return { ...biochemicalData, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      biochemical: biochemicalArray,
    });

    return biochemicalArray ?? [];
  } catch (e) {
    throw e;
  }
};

//Add measurement of client
export const AddMeasurement = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let measurementData = {
      ...formdata,
      id: uuidv4(),
      weight: isNaN(formdata.weight) ? "" : formdata.weight,
      createdAt: new Date(),
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    if (snapshot.exists()) {
      if (data.measurement) {
        await updateDoc(ref, {
          measurement: arrayUnion({ ...measurementData }),
        });
      } else {
        await updateDoc(ref, {
          measurement: [{ ...measurementData }],
        });
      }
    }

    return { ...measurementData };
  } catch (e) {
    throw e;
  }
};

//Edit measurement of client
export const EditMeasurement = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let measurementData = {
      ...formdata,
      weight: isNaN(formdata.weight) ? "" : formdata.weight,
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let measurementArray = data?.measurement?.map((item) => {
      if (formdata.id === item.id) {
        return { ...measurementData, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      measurement: measurementArray,
    });

    return measurementArray ?? [];
  } catch (e) {
    throw e;
  }
};

//Add vital of client
export const AddVital = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let vitalData = {
      ...formdata,
      id: uuidv4(),
      createdAt: new Date(),
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    if (snapshot.exists()) {
      if (data.vital) {
        await updateDoc(ref, {
          vital: arrayUnion({ ...vitalData }),
        });
      } else {
        await updateDoc(ref, {
          vital: [{ ...vitalData }],
        });
      }
    }

    return { ...vitalData };
  } catch (e) {
    throw e;
  }
};

//Edit vital of client
export const EditVital = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    let vitalData = {
      ...formdata,
    };

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let vitalArray = data?.vital?.map((item) => {
      if (formdata.id === item.id) {
        return { ...vitalData, updatedAt: new Date() };
      }
      return item;
    });

    await updateDoc(ref, {
      vital: vitalArray,
    });

    return vitalArray;
  } catch (e) {
    throw e;
  }
};

//Add diet recall of client
export const AddDietRecall = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();
    if (snapshot.exists()) {
      if (data?.dietRecall && data?.dietRecall?.length > 0) {
        await updateDoc(ref, {
          dietRecall: arrayUnion({
            ...formdata,
            frequencyOfEating: formdata.frequencyOfEating.value,
            dietType: formdata.dietType.value,
            createdAt: new Date(),
            id: docId,
          }),
        });

        return {
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          createdAt: new Date(),
          id: docId,
        };
      } else {
        await updateDoc(ref, {
          dietRecall: [
            {
              ...formdata,
              id: docId,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
            },
          ],
        });

        return {
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          createdAt: new Date(),
          id: docId,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

//Edit diet recall of client
export const EditDietRecall = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let dietRecallArray = data?.dietRecall?.map((item) => {
      if (formdata.id === item.id) {
        return {
          ...item,
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          updatedAt: new Date(),
        };
      }
      return item;
    });

    await updateDoc(ref, {
      dietRecall: dietRecallArray,
    });

    return dietRecallArray ?? [];
  } catch (e) {
    throw e;
  }
};

//Add daily intake of client
export const AddDailyIntake = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    const docId = uuidv4();
    if (snapshot.exists()) {
      if (data?.dailyIntake && data?.dailyIntake?.length > 0) {
        await updateDoc(ref, {
          dailyIntake: arrayUnion({
            ...formdata,
            frequencyOfEating: formdata.frequencyOfEating.value,
            dietType: formdata.dietType.value,
            createdAt: new Date(),
            id: docId,
          }),
        });

        return {
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          createdAt: new Date(),
          id: docId,
        };
      } else {
        await updateDoc(ref, {
          dailyIntake: [
            {
              ...formdata,
              id: docId,
              frequencyOfEating: formdata.frequencyOfEating.value,
              dietType: formdata.dietType.value,
              createdAt: new Date(),
            },
          ],
        });

        return {
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          createdAt: new Date(),
          id: docId,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

//Edit diet recall of client
export const EditDailyIntake = async ({ formdata, id }) => {
  try {
    const ref = doc(firestore, "clientdetail", id);

    const snapshot = await getDoc(ref);
    let data = snapshot.data();

    let dailyIntakeArray = data?.dailyIntake?.map((item) => {
      if (formdata.id === item.id) {
        return {
          ...item,
          ...formdata,
          frequencyOfEating: formdata.frequencyOfEating.value,
          dietType: formdata.dietType.value,
          updatedAt: new Date(),
        };
      }
      return item;
    });

    await updateDoc(ref, {
      dailyIntake: dailyIntakeArray,
    });

    return dailyIntakeArray ?? [];
  } catch (e) {
    throw e;
  }
};

export const uploadImages = async ({ formdata, uid }) => {
  try {
    const ref = doc(firestore, "clientdetail", uid);

    if (!formdata.image) {
      throw new Error({ message: "Please select an image!" });
    }

    const image_ = await uploadFile(formdata.image, "imageNowVsEarlier");

    let data_ = {
      image: image_,
      date: formdata.date,
    };

    let imageArray = [];

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();

    if (!data.imageNowVsEarlier || data.imageNowVsEarlier.length === 0) {
      data_ = {
        imageId: docId,
        ...data_,
        createdAt: new Date(),
      };

      imageArray = [data_];
    } else {
      data_ = {
        imageId: docId,
        ...data_,
        createdAt: new Date(),
      };

      imageArray = [data_, ...data?.imageNowVsEarlier];
    }

    await updateDoc(ref, {
      imageNowVsEarlier: imageArray,
    });

    return imageArray ?? [];
  } catch (e) {
    throw e;
  }
};

//for uploading custom diet pdf
export const uploadCustomDietChart = async ({ formdata, uid }) => {
  try {
    const ref = doc(firestore, "clientdetail", uid);

    if (!formdata.dietPdf) {
      throw new Error({ message: "Please select an pdf!" });
    }

    const dietPdf_ = await uploadFile(formdata.dietPdf, "customDietPdf");

    let data_ = {
      dietPdf: dietPdf_,
      date: formdata.date,
      description: formdata.description,
    };

    let pdfArray = [];

    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    const docId = uuidv4();

    if (!data.customDietPdf || data.customDietPdf.length === 0) {
      data_ = {
        id: docId,
        ...data_,
        createdAt: new Date(),
      };

      pdfArray = [data_];
    } else {
      data_ = {
        id: docId,
        ...data_,
        createdAt: new Date(),
      };

      pdfArray = [data_, ...data?.customDietPdf];
    }

    await updateDoc(ref, {
      customDietPdf: pdfArray,
    });

    return pdfArray ?? [];
  } catch (e) {
    throw e;
  }
};
