import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import CustomButton from "containers/CustomButton";
import { Autocomplete, Chip, Icon, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Createable from "containers/Select";

import { AddRecipe, EditRecipe } from "store/features/recipes";
import MultipleTags from "containers/Tags";
import { updateFirstLetter } from "utils/helper";
import ModalHeader from "containers/modal-header";
import NutritionModal from "components/NutritionValueModal";

function Modal({
  open,
  handleCloseModal,
  selectedItem,
  setSelectedItem,
  uid,
  isFetching,
  isSuccess,
  isError,
  setLoader,
  message,
  mode,
  editHandler,
  viewHandler,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      name: null,
      description: null,
      preparation: null,
      calories: null,
      weight: null,
      foodType: null,
      ingredients: null,
      nutritions: null,
      howToTake: null,
      qty: null,
      unit: null,
      foodCategory: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      name: false,
      description: false,
      preparation: false,
      calories: false,
      weight: false,
      foodType: false,
      ingredients: false,
      nutritions: false,
      howToTake: false,
      qty: false,
      unit: false,
      foodCategory: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for handling selected items
  useEffect(() => {
    if (open) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        foodCategory: {
          label: selectedItem?.foodCategory,
          value: selectedItem?.foodCategory,
        },
        unit: { label: selectedItem?.unit, value: selectedItem?.unit },
      });
      setSelectedOptions(selectedItem?.nutritions ?? []);
    }
  }, [selectedItem, defaultHandler, open]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.name) {
      toast.error("Please enter a Recipe Name");
      setErrorMessage({
        ...errorMessage,
        name: true,
      });
      return;
    }

    if (!formdata.description) {
      toast.error("Please fill Recipe Description field");
      setErrorMessage({
        ...errorMessage,
        description: true,
      });
      return;
    }

    if (!formdata.preparation) {
      toast.error("Please fill How to Prepare field");
      setErrorMessage({
        ...errorMessage,
        preparation: true,
      });
      return;
    }

    // if(!formdata.calories){
    //     toast.error("Please fill Calories field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         calories: true
    //     })
    //     return;
    // }

    // if(!formdata.weight){
    //     toast.error("Please fill Weight field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         weight: true
    //     })
    //     return;
    // }

    if (!formdata.foodType || formdata.foodType?.length === 0) {
      toast.error("Please fill Food Type field");
      setErrorMessage({
        ...errorMessage,
        foodType: true,
      });
      return;
    }

    if (!formdata.ingredients || formdata.ingredients?.length === 0) {
      toast.error("Please fill Ingredients field");
      setErrorMessage({
        ...errorMessage,
        ingredients: true,
      });
      return;
    }

    // if(!formdata.nutritions || formdata.nutritions?.length === 0 ){
    //     toast.error("Please fill Nutrition Value field")
    //     setErrorMessage({
    //         ...errorMessage,
    //         nutritions: true
    //     })
    //     return;
    // }

    if (!formdata.howToTake) {
      toast.error("Please fill How to Take field");
      setErrorMessage({
        ...errorMessage,
        howToTake: true,
      });
      return;
    }

    if (!formdata.foodCategory.value) {
      toast.error("Please fill Food Category field");
      setErrorMessage({
        ...errorMessage,
        foodCategory: true,
      });
      return;
    }

    if (formdata.qty === "" || formdata.qty === null) {
      toast.error("Please fill QTY. field");
      setErrorMessage({
        ...errorMessage,
        qty: true,
      });
      return;
    }

    if (!formdata.unit.value) {
      toast.error("Please fill Unit field");
      setErrorMessage({
        ...errorMessage,
        unit: true,
      });
      return;
    }
    setLoader(true);

    if (selectedItem) {
      dispatch(EditRecipe({ formdata, uid }));
      return;
    }

    dispatch(AddRecipe({ formdata, uid }));
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      setTimeout(() => {
        if (selectedItem) {
          setSelectedItem(null);
        }
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    }
    if (isError) {
      setLoader(false);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    setSelectedItem,
    selectedItem,
    defaultHandler,
    message,
    setLoader,
  ]);

  const [foodCategory] = useState([
    { label: "Vegetables", value: "Vegetables", key: 1 },
    { label: "Fruits", value: "Fruits", key: 2 },
    { label: "Grains", value: "Grains", key: 3 },
    { label: "Protein Foods", value: "Protein Foods", key: 4 },
    { label: "Dairy", value: "Dairy", key: 5 },
    { label: "Oils & Solid Fats", value: "Oils & Solid Fats", key: 6 },
    { label: "Added Sugars", value: "Added Sugars", key: 7 },
    { label: "Beverages", value: "Beverages", key: 8 },
    { label: "Egg", value: "Egg", key: 9 },
    { label: "Non Veg", value: "Non Veg", key: 10 },
    { label: "Sea Food", value: "Sea Food", key: 11 },
    { label: "Dietary Fiber", value: "Dietary Fiber", key: 12 },
    { label: "Vitamins Rich", value: "Vitamins Rich", key: 13 },
    { label: "Minerals Rich", value: "Minerals Rich", key: 14 },
    { label: "Carbs Rich", value: "Carbs Rich", key: 15 },
    { label: "Others", value: "Others", key: 16 },
  ]);

  const [UnitState] = useState([
    { label: "Litre", value: "Litre", key: 0 },
    { label: "Gms", value: "Gms", key: 1 },
    { label: "Ml", value: "Ml", key: 2 },
    { label: "Table Spoon", value: "Table Spoon", key: 3 },
    { label: "Tablet", value: "Tablet", key: 4 },
    { label: "Softgel", value: "Softgel", key: 4 },
    { label: "Katori", value: "Katori", key: 4 },
    { label: "Tea Cup", value: "Tea Cup", key: 4 },
    { label: "Tea Spoon", value: "Tea Spoon", key: 4 },
    { label: "Kernel", value: "Kernel", key: 4 },
    { label: "Bowl", value: "Bowl", key: 4 },
    { label: "Scoop", value: "Scoop", key: 4 },
    { label: "Piece", value: "Piece", key: 4 },
    { label: "Nuts", value: "Nuts", key: 4 },
    { label: "Servings", value: "Servings", key: 4 },
    { label: "Glass", value: "Glass", key: 4 },
    { label: "Small", value: "Small", key: 4 },
    { label: "Big", value: "Big", key: 4 },
    { label: "Medium", value: "Medium", key: 4 },
  ]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };

  const handleAddNutrition = (grams) => {
    const existingIndex = selectedOptions.findIndex(
      (item) => item.type === selectedType
    );

    if (existingIndex !== -1) {
      const updatedOptions = [...selectedOptions];
      updatedOptions[existingIndex].grams = grams;
      setSelectedOptions(updatedOptions);
      setFormdata(selectedOptions);
    } else {
      setSelectedOptions([...selectedOptions, { type: selectedType, grams }]);
      setFormdata((prevData) => ({
        ...prevData,
        nutritions: [...selectedOptions, { type: selectedType, grams }],
      }));
    }

    setModalOpen(false);
  };

  const handleRemoveNutrition = (type) => {
    const updatedOptions = selectedOptions.filter((item) => item.type !== type);
    setSelectedOptions(updatedOptions);
    setFormdata((prevData) => ({ ...prevData, nutritions: updatedOptions }));
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={mode}
          title="Recipe"
          subtitle={`Recipe details for ${updateFirstLetter(formdata?.name)}`}
        />
        <Grid item xs={12}>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => editHandler(selectedItem)}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(selectedItem)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={() => {
              handleCloseModal();
              setTimeout(() => {
                setFormdata({
                  ...defaultHandler,
                });
                setErrorMessage({
                  ...errorDefault,
                });
                if (selectedItem) {
                  setSelectedItem(null);
                }
              }, 500);
            }}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Recipe Name
                    </SuiTypography>
                    <SuiInput
                      type="text"
                      disabled={mode === "view"}
                      name="name"
                      readOnly={mode === "view"}
                      autoComplete="off"
                      error={errorMessage?.name}
                      value={formdata.name ?? ""}
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={12} mt={0.8}>
                  <Grid container>
                    <Grid item xs={5} mr={2}>
                      <SuiBox lineHeight={1}>
                        <SuiTypography mb={1} variant="h6">
                          Calories
                        </SuiTypography>
                        <SuiInput
                          disabled={mode === "view"}
                          type="number"
                          name="calories"
                          readOnly={mode === "view"}
                          error={errorMessage?.calories}
                          value={formdata.calories ?? ""}
                          onChange={(e) => {
                            let numberRegex =
                              /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                            let val = e.target.value;
                            let match_ = val.match(numberRegex);
                            if (!match_ && val) {
                              return false;
                            }
                            setErrorMessage({
                              ...errorMessage,
                              [e.target.name]: false,
                            });
                            setFormdata({
                              ...formdata,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={5}>
                      {/* <SuiBox lineHeight={1}>
                        <SuiTypography mb={1} variant="h6">
                          Weight (in gms)
                        </SuiTypography>
                        <SuiInput
                          type="number"
                          readOnly={mode === "view"}
                          name="weight"
                          error={errorMessage?.weight}
                          value={formdata.weight ?? ""}
                          onChange={(e) => {
                            let numberRegex =
                              /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                            let val = e.target.value;
                            let match_ = val.match(numberRegex);
                            if (!match_ && val) {
                              return false;
                            }
                            setErrorMessage({
                              ...errorMessage,
                              [e.target.name]: false,
                            });
                            setFormdata({
                              ...formdata,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </SuiBox> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Recipe Description
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="description"
                  placeholder="Description about Recipe"
                  multiline
                  readOnly={mode === "view"}
                  rows={7}
                  error={errorMessage?.description}
                  value={formdata.description ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  How to Prepare
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="preparation"
                  multiline
                  readOnly={mode === "view"}
                  rows={7}
                  error={errorMessage?.preparation}
                  value={formdata.preparation ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Food Type
                </SuiTypography>
                <MultipleTags
                  placeholder="Food type"
                  disabled={mode === "view"}
                  valList={formdata.foodType}
                  freeTextHanlder={(val) => {
                    setFormdata({
                      ...formdata,
                      foodType: val,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Ingredients
                </SuiTypography>
                <MultipleTags
                  placeholder="ingredients"
                  disabled={mode === "view"}
                  valList={formdata.ingredients}
                  freeTextHanlder={(val) => {
                    setFormdata({
                      ...formdata,
                      ingredients: val,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Nutrition Value
                </SuiTypography>

                <Autocomplete
                  multiple
                  clearIcon={null}
                  id="nutrition-input"
                  sx={{
                    display: "flex",
                    borderBottom: "none",
                    "& input": {
                      width: 200,
                      bgcolor: "background.paper",
                      borderBottom: "none",
                    },
                  }}
                  options={["Protein", "Fat", "Carbohydrate", "Sugar"]}
                  getOptionLabel={(option) => option}
                  value={selectedOptions.map((option) => option.type)}
                  onChange={(event, values) => {
                    setModalOpen(true);
                    setSelectedType(values[values.length - 1]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Nutrition"
                      sx={{
                        "& .MuiAutocomplete-inputRoot": {
                          overflowX: "auto",
                          display: "flex !important",
                          alignItems: "flex-start !important",
                          flexWrap: "wrap !important",
                          height: "80px !important",

                          "&::after": {
                            borderBottom: "none !important",
                          },
                          "&::before": {
                            borderBottom: "none !important",
                          },
                          "&:hover": {
                            // borderBottom: 'none !important',
                            background: "red",
                          },
                        },
                      }}
                      variant="filled"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option}
                        label={`${option}: ${selectedOptions[index]?.grams}g`}
                        {...getTagProps({ index })}
                        onDelete={() => {
                          handleRemoveNutrition(option);
                        }}
                      />
                    ))
                  }
                />
                <NutritionModal
                  open={modalOpen}
                  onClose={() => {
                    setModalOpen(false);
                  }}
                  onAddNutrition={handleAddNutrition}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  How to Take
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="howToTake"
                  placeholder="How to intake this recipe"
                  multiline
                  readOnly={mode === "view"}
                  rows={7}
                  error={errorMessage?.howToTake}
                  value={formdata.howToTake ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Food Category
                    </SuiTypography>
                    <Createable
                      options={foodCategory ?? []}
                      disabled={mode === "view"}
                      error={errorMessage.foodCategory}
                      value={formdata.foodCategory}
                      style={{ borderRadius: "0.5rem", fontSize: 14 }}
                      inputStyle={{
                        width: 190,
                        height: "1.800rem",
                        borderRadius: "0.5rem",
                      }}
                      placeholder="Choose food category"
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          foodCategory: false,
                        });

                        setFormdata({
                          ...formdata,
                          foodCategory: e,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SuiBox lineHeight={1}>
                        <SuiTypography mb={1} variant="h6">
                          {`Qty (${
                            formdata.unit?.value === "Litre"
                              ? "litre"
                              : formdata.unit?.value === "Ml"
                              ? "ml"
                              : "gms"
                          })`}
                        </SuiTypography>
                        <SuiInput
                          disabled={mode === "view"}
                          type="number"
                          name="qty"
                          readOnly={mode === "view"}
                          error={errorMessage?.qty}
                          value={formdata.qty ?? ""}
                          onChange={(e) => {
                            setErrorMessage({
                              ...errorMessage,
                              [e.target.name]: false,
                            });
                            setFormdata({
                              ...formdata,
                              [e.target.name]: e.target.value.replace(/\D/g),
                            });
                          }}
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SuiBox lineHeight={1} style={{ width: "120px" }}>
                        <SuiTypography mb={1} variant="h6">
                          Unit
                        </SuiTypography>
                        <Createable
                          options={UnitState ?? []}
                          disabled={mode === "view"}
                          error={errorMessage.unit}
                          value={formdata.unit}
                          style={{ borderRadius: "0.5rem", fontSize: 14 }}
                          inputStyle={{
                            width: 190,
                            height: "1.800rem",
                            borderRadius: "0.5rem",
                          }}
                          placeholder="Choose unit"
                          onChange={(e) => {
                            setErrorMessage({
                              ...errorMessage,
                              unit: false,
                            });

                            setFormdata({
                              ...formdata,
                              unit: e,
                            });
                          }}
                        />
                      </SuiBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        <Divider />

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={isFetching || !open}
                title={selectedItem ? "Edit Recipe" : "Add Recipe"}
                loader={isFetching}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
