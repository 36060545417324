import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ImageNowVsEarlierRow } from "./ImageNowVsEarlierRow";
import UploadHandler from "./UploadHandler";
import { globalStyles } from "styles";

function ImageNowVsEarlier({
  images,
  title,
  uid,
  setSelectedClient,
  deleteHandler,
}) {
  const [loader, setLoader] = useState(false);

  return (
    <SuiBox mb={1}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        pb={3}
      >
        <SuiTypography variant="h6">{title}</SuiTypography>
        <SuiBox />
      </SuiBox>

      <UploadHandler
        uid={uid}
        loader={loader}
        setLoader={setLoader}
        setSelectedClient={setSelectedClient}
      />

      <SuiBox sx={globalStyles.tableOverlay}>
        <SuiBox
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {ImageNowVsEarlierRow(images, deleteHandler)}
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default ImageNowVsEarlier;
