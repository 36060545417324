import React from "react";
import { Font, Page, Text, View, Document, Image } from "@react-pdf/renderer";
import poppinBlack from "../../assets/fonts/Poppins-Black.ttf";
import poppinLight from "../../assets/fonts/Poppins-Light.ttf";
import poppinRegular from "../../assets/fonts/Poppins-Regular.ttf";
import { formatDateFieldv1, formatDateFieldv2 } from "utils/common";
import Logomain from "assets/images/logo.png";
import { styles } from "./style.js";
import {
  chartHandler,
  keyChanger,
  dietRecallHandler,
  onTimeChange,
} from "./utils";
import { getMinTimestampObject } from "utils/pdf";
import { getMaxTimestampObject } from "utils/pdf";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: poppinRegular,
      fontWeight: 400,
    },
    {
      src: poppinBlack,
    },
    {
      src: poppinLight,
      fontWeight: "normal",
    },
  ],
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

//total calories
const totalCalories = (chartCalories) => {
  let calories = 0;
  chartCalories.map((item, index) => {
    item.list.map((ele) => {
      if (!isNaN(ele?.calories)) {
        calories += parseFloat(ele.calories);
      }
      return ele;
    });
    return item;
  });

  return parseFloat(calories)?.toFixed(2);
};

const totalCaloriesv1 = (chartCalories) => {
  let calories = 0;
  chartCalories.map((item, index) => {
    if (!isNaN(item?.calories)) {
      calories += parseFloat(item.calories);
    }
    return item;
  });

  return parseFloat(calories).toFixed(2);
};

// Create Document Component
const MyDocument = ({ dietchart, profile, selectedClient, dietChartSettings}) => {
  const minWeight = getMinTimestampObject(selectedClient.measurement)?.weight;
  const maxWeight = getMaxTimestampObject(selectedClient.measurement)?.weight;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoView}>
            <Text style={styles.logoName}>Diet chart </Text>
          </View>
          <View style={styles.logo}>
            <Image style={styles.logoImage} src={profile ?? Logomain} />
          </View>
        </View>

        <View style={{ padding: 10, flexDirection: "row", flexWrap: "wrap" }}>
          <View style={{ width: "52%", marginRight: "2.5%" }}>
            <Text
              style={{
                color: "#3D8909",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: "Lato Bold",
              }}
            >
              Customer Information
            </Text>

            <View
              style={{
                marginTop: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Name:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.name}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Gender:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.gender ?? "N/A"}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Age:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.age ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Ph No.:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.phoneNumber ?? "N/A"}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Email:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.email ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Occupation:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.occupation ?? "N/A"}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Height:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.measurement &&
                    selectedClient.measurement?.length > 0 &&
                    selectedClient.measurement[
                      selectedClient.measurement.length - 1
                    ].height
                      ? selectedClient.measurement[
                          selectedClient.measurement.length - 1
                        ].height + " cm"
                      : "N/A"}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Food:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >
                    {selectedClient.preference ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View
                style={{ display: "flex", flexDirection: "row", width: "120%" }}
              >
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",

                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Starting Weight:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >{`${minWeight ? minWeight : "N/A"} Kgs`}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Last Taken Weight:{" "}
                  </Text>
                  <Text
                    style={{
                      color: "#06376B",
                      fontSize: 12,
                      fontFamily: "Lato",
                    }}
                  >{`${maxWeight ? maxWeight : "N/A"} Kgs`}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "44%" }}>
            <Text
              style={{
                color: "#3D8909",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: "Lato Bold",
              }}
            >
              Chart Details
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={{
                    color: "#06376B",
                    fontWeight: "bold",
                    fontSize: 12,
                    fontFamily: "Lato Bold",
                  }}
                >
                  Week Plan:{" "}
                </Text>
                <Text
                  style={{
                    color: "#06376B",
                    fontSize: 12,
                  }}
                >
                  {dietchart[0].weekPlan ?? "N/A"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={{
                    color: "#06376B",
                    fontSize: 12,
                    fontWeight: "bold",
                    fontFamily: "Lato Bold",
                  }}
                >
                  Chart Type:{" "}
                </Text>
                <Text
                  style={{
                    color: "#06376B",
                    fontSize: 12,
                  }}
                >
                  {dietchart[0].chartType ?? "N/A"}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: "#06376B",
                  fontSize: 12,
                  fontWeight: "bold",
                  fontFamily: "Lato Bold",
                }}
              >
                Chart Duration:{" "}
              </Text>
              <Text style={styles.headerText}>
                {dietchart?.length > 1
                  ? `${formatDateFieldv1(
                      dietchart[0]?.date
                    )} to ${formatDateFieldv1(
                      dietchart[dietchart?.length - 1]?.date
                    )}`
                  : formatDateFieldv1(dietchart[0]?.date)}{" "}
              </Text>
            </View>

            {selectedClient.services && selectedClient.services?.length > 0 && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: "#06376B",
                    fontSize: 12,
                    fontWeight: "bold",
                    fontFamily: "Lato Bold",
                  }}
                >
                  Package Details:{" "}
                </Text>
                <Text style={styles.headerText}>
                  {selectedClient.services[selectedClient.services.length - 1]
                    ?.serviceName ?? "N/A"}
                </Text>
              </View>
            )}
          </View>
        </View>

        <View style={{ borderBottom: "2px solid #3D8909", marginBottom: 15 }} />

        {selectedClient?.biochemical &&
          selectedClient?.biochemical?.length > 0 && (
            <View
              style={{
                display: "flex",
                padding: 10,
                marginBottom: 15,
                flexWrap: "wrap",
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  marginBottom: 15,
                  color: "#3D8909",
                  fontWeight: "bold",
                  fontFamily: "Lato Bold",
                }}
              >
                Medical History
              </Text>

              {selectedClient.biochemical[selectedClient.biochemical.length - 1]
                .sufferingFrom && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#06376B",
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Suffering From:{" "}
                  </Text>
                  {selectedClient.biochemical[
                    selectedClient.biochemical.length - 1
                  ].sufferingFrom.map((suff, ind) => (
                    <Text style={{ fontSize: 12, color: "#06376B" }} key={ind}>
                      {ind !== 0 && ", "}
                      {suff}
                    </Text>
                  ))}
                </View>
              )}
              {selectedClient.biochemical[selectedClient.biochemical.length - 1]
                .allergicFrom && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    marginTop: 10,
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#06376B",
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Food Allergy:{" "}
                  </Text>
                  {selectedClient.biochemical[
                    selectedClient.biochemical.length - 1
                  ].allergicFrom.map((suff, ind) => (
                    <Text style={{ fontSize: 12, color: "#06376B" }} key={ind}>
                      {ind !== 0 && ", "}
                      {suff}
                    </Text>
                  ))}
                </View>
              )}
            </View>
          )}

        {selectedClient.biochemical &&
          selectedClient?.biochemical?.length > 0 && (
            <View
              style={{ borderBottom: "2px solid #3D8909", marginBottom: 15 }}
            />
          )}

        {selectedClient.dietRecall &&
          selectedClient?.dietRecall?.length > 0 && (
            <View style={{ padding: 10, marginBottom: 10 }}>
              <Text
                style={{
                  fontSize: 14,
                  marginBottom: 15,
                  color: "#3D8909",
                  fontWeight: "bold",
                  fontFamily: "Lato Bold",
                }}
              >
                Diet Recall
              </Text>

              {(selectedClient.dietRecall[0]?.beforeDinner?.clientAte ||
                selectedClient.dietRecall[0]?.earlyMorning?.clientAte ||
                selectedClient.dietRecall[0]?.midMorning?.clientAte ||
                selectedClient.dietRecall[0]?.breakfast?.clientAte ||
                selectedClient.dietRecall[0]?.brunch?.clientAte ||
                selectedClient.dietRecall[0]?.midMeal?.clientAte ||
                selectedClient.dietRecall[0]?.lunch?.clientAte ||
                selectedClient.dietRecall[0]?.postLunch?.clientAte ||
                selectedClient.dietRecall[0]?.evening?.clientAte ||
                selectedClient.dietRecall[0]?.dinner?.clientAte ||
                selectedClient.dietRecall[0]?.postDinner?.clientAte ||
                selectedClient.dietRecall[0]?.lateNight?.clientAte) && (
                <View
                  style={{
                    border: "1.5px solid #0E0E0E",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      width: "15%",
                      padding: 7,
                      fontSize: 12,
                      color: "#06376B",
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                    }}
                  >
                    Time
                  </Text>
                  <View
                    style={{
                      width: "85%",
                      borderLeft: "1.5px solid #0E0E0E",
                      padding: 7,
                      display: "flex",
                      flexDirection: "row",
                      alignItem: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#06376B",
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                      }}
                    >
                      Meal
                    </Text>
                  </View>
                </View>
              )}

              {selectedClient?.dietRecall?.length > 0 &&
                Object.keys(
                  dietRecallHandler(selectedClient.dietRecall[0])
                ).map((ele, i) => (
                  <View
                    style={{
                      border: "1.5px solid #0E0E0E",
                      borderTop: "none",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    key={i}
                  >
                    <Text
                      style={{
                        width: "15%",
                        padding: 7,
                        fontSize: 10,
                        color: "#06376B",
                      }}
                    >
                      {onTimeChange(selectedClient.dietRecall[0][ele].time)}
                    </Text>
                    <View
                      style={{
                        width: "85%",
                        borderLeft: "1.5px solid #0E0E0E",
                        padding: 7,
                        display: "flex",
                        flexDirection: "row",
                        alignItem: "center",
                      }}
                    >
                      <Text style={{ fontSize: 10, color: "#06376B" }}>
                        {keyChanger(ele)}:{" "}
                        {selectedClient.dietRecall[0][ele].clientAte}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          )}

        {selectedClient.dietRecall &&
          selectedClient?.dietRecall?.length > 0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 30,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {selectedClient.dietRecall[0].waterIntake && (
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                      color: "#06376B",
                      fontSize: 12,
                      marginRight: 4,
                    }}
                  >
                    Daily Intake of Water:
                  </Text>
                  <Text
                    style={{ fontSize: 12, color: "#06376B", marginTop: 1 }}
                  >
                    {selectedClient.dietRecall[0].waterIntake} Lts
                  </Text>
                </View>
              )}

              {selectedClient.dietRecall[0].frequencyOfEating && (
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                      color: "#06376B",
                      fontSize: 12,
                      marginRight: 4,
                    }}
                  >
                    Frequency of Eating Out:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: 12, color: "#06376B", marginTop: 1 }}
                  >
                    {selectedClient.dietRecall[0].frequencyOfEating}
                  </Text>
                </View>
              )}

              {selectedClient.dietRecall[0].dietType && (
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Lato Bold",
                      color: "#06376B",
                      fontSize: 12,
                      marginRight: 4,
                    }}
                  >
                    Follow Special Diet?:{" "}
                  </Text>
                  <Text
                    style={{ fontSize: 12, color: "#06376B", marginTop: 1 }}
                  >
                    {selectedClient.dietRecall[0].dietType}
                  </Text>
                </View>
              )}
            </View>
          )}

        {selectedClient.dietRecall &&
          selectedClient?.dietRecall?.length > 0 && (
            <View
              style={{ borderBottom: "2px solid #3D8909", marginBottom: 25 }}
            />
          )}

        {dietchart.map((item, index) => (
          <View key={index} style={styles.dietView}>
            <View style={styles.section_1}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  fontFamily: "Lato Bold",
                  color: "#3D8909",
                }}
              >
                {formatDateFieldv2(item.date)} :{" "}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "#06376B",
                  marginRight: 5,
                }}
              >
                {item.weekPlan}, {item.chartType}.
              </Text>
             {dietChartSettings.showDayCalorie && <View>
             <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "Lato Bold",
                  color: "#06376B",
                  fontSize: 12,
                  marginRight: 5,
                }}
              >
                Total Calories for the day:
              </Text>
              <Text style={{ fontSize: 12, color: "#06376B" }}>
                {totalCalories(item.table)} Calories
              </Text>
             </View>}
            </View>

            {Object.keys(chartHandler(item.table)).map((ele, i) => (
              <View key={i} style={{ marginBottom: 5 }} wrap={false}>
                <View
                  style={{
                    ...styles.section_2,
                    marginTop: 10,
                    marginBottom: 0,
                    border: "1.5px solid #0E0E0E",
                  }}
                >
                  {dietChartSettings?.showTimeCalorie ? <View
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                        fontSize: 12,
                        color: "#06376B",
                        padding: 7,
                      }}
                    >
                      {keyChanger(ele)}
                    </Text>

                    <Text
                      style={{
                        fontSize: 12,
                        marginLeft: 3,
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                        color: "#06376B",
                        padding: 7,
                        paddingLeft: 0,
                      }}
                    >
                      {" "}
                      | {totalCaloriesv1(chartHandler(item.table)[ele])} Cal
                    </Text>
                  </View>: <View
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  ></View>}

                  <View
                    style={{
                      width: "40%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        borderLeft: "1.5px solid #0E0E0E",
                        width: "50%",
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                        fontSize: 10,
                        padding: 7,
                        paddingLeft: 10,
                        color: "#06376B",
                      }}
                    >
                      Ingredients
                    </Text>
                    <Text
                      style={{
                        borderLeft: "1.5px solid #0E0E0E",
                        width: "30%",
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                        fontSize: 10,
                        padding: 7,
                        paddingLeft: 10,
                        color: "#06376B",
                      }}
                    >
                      Portion
                    </Text>
                    {dietChartSettings?.showCalorieColumn && <Text
                      style={{
                        width: "20%",
                        borderLeft: "1.5px solid #0E0E0E",
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                        fontSize: 10,
                        padding: 7,
                        color: "#06376B",
                      }}
                    >
                      Cal
                    </Text>}
                  </View>
                </View>

                {chartHandler(item.table)[ele]?.map((ele_, ind_) => (
                  <View
                    style={{ display: "flex", flexDirection: "row" }}
                    key={ind_}
                  >
                    <View
                      style={{
                        width: "60%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        border: "1.5px solid #0E0E0E",
                        borderRight: "none",
                        borderTop: "none",
                      }}
                    >
                      <Text
                        style={{
                          width: "20%",
                          fontSize: 10,
                          padding: 7,
                          borderRight: "1.5px solid #0E0E0E",
                          fontFamily: "Lato",
                          color: "#06376B",
                        }}
                      >
                        {ele_.time}
                      </Text>

                      <View
                        style={{
                          width: "75%",
                          display: "flex",
                          flexDirection: "column",
                          padding: 7,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            flexWrap: "wrap",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Lato Bold",
                              textDecoration: "underline",
                              color: "#06376B",
                            }}
                          >
                            {ele_.name}{" "}
                          </Text>

                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Lato",
                              color: "#06376B",
                            }}
                          >
                            ,{" "}
                          </Text>
                          <View>
                            <Text
                              style={{
                                fontSize: 8,
                                fontFamily: "Lato",
                                color: "#06376B",
                              }}
                            >
                              (
                              {ele_.nutritions.map((nutrition, ii) => (
                                <Text
                                  key={ii}
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Lato",
                                    color: "#06376B",
                                  }}
                                >
                                  {ii !== 0 && ", "}
                                  {nutrition.type}: {nutrition.grams}
                                </Text>
                              ))}
                              )
                            </Text>
                          </View>
                        </View>

                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Lato",
                            color: "#06376B",
                          }}
                        >
                          {ele_.preparation}
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Lato",
                            color: "#06376B",
                          }}
                        >
                          {ele_.howToTake}
                        </Text>
                        {/* <Text style={{ fontSize: 10, fontFamily: 'Lato', color: '#06376B' }}>Food Category: {ele_.foodCategory}</Text>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Lato', color: '#06376B' }}>Food Type: {ele_.foodType.map((type, i)=> <Text style={{ fontSize: 12, fontFamily: 'Lato', color: '#06376B' }} key={i}>{type}, </Text>)}</Text> */}
                      </View>
                    </View>

                    <View
                      style={{
                        width: "40%",
                        display: "flex",
                        flexDirection: "row",
                        border: "1.5px solid #0E0E0E",
                        borderLeft: "none",
                        borderTop: "none",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          borderLeft: "1.5px solid #0E0E0E",
                          padding: 7,
                        }}
                      >
                        {ele_.ingredients.map((ingredient, ii) => (
                          <Text
                            key={ii}
                            style={{
                              fontFamily: "Lato",
                              fontSize: 10,
                              color: "#06376B",
                            }}
                          >
                            {ii !== 0 ? ", " : ""}
                            {ingredient}
                          </Text>
                        ))}
                      </View>
                      <Text
                        style={{
                          width: "30%",
                          fontFamily: "Lato",
                          borderLeft: "1.5px solid #0E0E0E",
                          fontSize: 10,
                          color: "#06376B",
                          padding: 7,
                        }}
                      >
                        {ele_.qty} {ele_.unit}
                      </Text>
                      {dietChartSettings?.showCalorieColumn && <Text
                        style={{
                          width: "20%",
                          fontFamily: "Lato",
                          borderLeft: "1.5px solid #0E0E0E",
                          fontSize: 10,
                          paddingLeft: 6,
                          color: "#06376B",
                          padding: 7,
                        }}
                      >
                        {isNaN(ele_?.calories)
                          ? "N/A"
                          : parseFloat(ele_.calories)?.toFixed(2)}
                      </Text>}
                    </View>
                  </View>
                ))}
              </View>
            ))}

            {item.notes && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItem: "baseline",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#06376B",
                    fontWeight: "bold",
                    fontFamily: "Lato Bold",
                  }}
                >
                  Notes:{" "}
                </Text>

                <Text style={{ fontSize: 12, color: "#06376B" }}>
                  {item.notes}
                </Text>
              </View>
            )}

            <View
              style={{ borderBottom: "2px solid #3D8909", marginTop: 15 }}
            />
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default MyDocument;
