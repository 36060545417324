import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { updateClientData } from "services/customer";
import { toast } from "react-toastify";
import { dailyIntakeAndRecallRow } from "containers/clientDetails/sub-components/common/dailyIntakeAndRecallTableRows";
import ClientInfoCard from "containers/clientDetails/sub-components/card/clientCard";
import RecallModal from "containers/clientDetails/sub-components/modal/dietrecall";
import { dailyIntakeAndRecallTableColumns } from "utils/tablesHeaderContent";
import SwipableModal from "containers/Customer/modal";
import { headerDesc } from "utils/helper";

function DietRecallSummary({ clientId, dietRecall, deleteHandler }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([...dailyIntakeAndRecallTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [dietRecallData, setDietRecallData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("create");

  useEffect(() => {
    if (dietRecall.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setDietRecallData(dietRecall);
  }, [dietRecall]);

  const [selectedData, setSelectedData] = useState(null);
  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setOpenAddModal(true);
    setMode("edit");
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("view");
    setOpenAddModal(true);
  }, []);

  useEffect(() => {
    setRows(
      dailyIntakeAndRecallRow(
        dietRecallData,
        editHandler,
        viewHandler,
        deleteHandler
      )
    );
  }, [dietRecallData, editHandler, viewHandler]);

  const addHandler = () => {
    setMode("create");
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "dietRecall";
      await updateClientData(clientId, fieldKey, dietRecallData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  const Modal = () => (
    <SwipableModal
      open={openAddModal}
      setOpenModal={setOpenAddModal}
      handleCloseModal={handleCloseModal}
      mode={mode}
      setMode={setMode}
      {...headerDesc(mode, "Manage Diet Recall", selectedData)}
    >
      <RecallModal
        id={clientId}
        persona="client"
        mode={mode}
        open={openAddModal}
        selectedItem={selectedData}
        setMainData={setDietRecallData}
        handleCloseModal={handleCloseModal}
      />
    </SwipableModal>
  );

  return (
    <>
      <ClientInfoCard
        title="Manage Diet Recall"
        rows={rows}
        columns={columns}
        disableButton={disableButton}
        onSubmitHandler={submitHandler}
        loader={loader}
        onBtnClickHandler={addHandler}
        Modal={Modal}
      />
    </>
  );
}

export default DietRecallSummary;
