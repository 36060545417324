import { useState, useMemo, useEffect } from "react";


// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";


import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { validateEmail  } from 'utils/common'
import { userSelector, clearState, forgotPassUser } from 'store/features/authSlice';
import { useNavigate } from "react-router-dom";


function ForgotPassword() {

    const dispatch =  useDispatch()
    const { isSuccess, isError, isFetching, message } = useSelector(userSelector);

    useEffect(()=>{
        if(isError){
            toast.error(message)
            dispatch(clearState())
        }

        if(isSuccess){
            toast.success(message)
            dispatch(clearState())
        }

    },[isError, message, dispatch, isSuccess])

    //default fields value
    const defaultHandler = useMemo(() => {
        return {
            email: null,
        }}, []);

    
    //default error fields value
    const errorDefault = useMemo(() => {
        return {
            email: false,
        }}, []);


    const [ formdata, setFormdata ] = useState(defaultHandler)
    const [ errorMessage, setErrorMessage ] =  useState(errorDefault)
    
  //for registering
    const submitHandler = () => {
        if(formdata.email){
            if(!validateEmail(formdata.email)){
                toast.info("Enter a valid email")
                setErrorMessage({
                    ...errorMessage,
                    'email': true
                })
                return;
            }
        } else {
            toast.info("Plz enter email")
            setErrorMessage({
                ...errorMessage,
                'email': true
            })
            return;
        }

        dispatch(forgotPassUser({ formdata }))
    }
    const navigate = useNavigate()


    return (
        <BasicLayout
            title="Forgot Password?"
            description="Provide your registered email address, we will send the new password on your registered email address."
            image={curved6}>
        <Card>
            <SuiBox p={3} mb={1} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                    Forgot Password?
                </SuiTypography>

                <SuiTypography
                    onClick={()=> navigate("/login")} 
                    style={{ color: "#17C1E8", cursor: 'pointer' }}
                    component="label" variant="button" fontWeight="bold">
                    &nbsp;&nbsp;Back to login page
                </SuiTypography>
            </SuiBox>

            <SuiBox pt={2} pb={3} px={3}>
            <SuiBox component="form" role="form">

                <SuiBox mb={2}>
                    <SuiInput 
                        type="email"
                        name="email" 
                        placeholder="Email"
                        error={errorMessage?.email}
                        value={formdata?.email??""}
                        onChange={(e)=>{
                            setErrorMessage({
                                ...errorMessage,
                                [e.target.name]: false
                            })
                            setFormdata({
                                ...formdata,
                                [e.target.name]: e.target.value
                            })
                        }}
                         />
                </SuiBox>

                <SuiBox mt={4} mb={1} display="flex" justifyContent="center">
                    <SuiButton 
                        variant="gradient" 
                        color="dark" 
                        style={{ width: "60%"}}
                        onClick={submitHandler}
                        disabled={isFetching}>
                        {isFetching? "Submitting . . .": "submit"}
                    </SuiButton>
                </SuiBox>

            </SuiBox>
            </SuiBox>
        </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;