import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "@mui/material/Card";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import Table from "examples/Tables/Table";
import ClientSearch from "./Searchbar";
import NoData from "containers/DataNotAvailBox";
import { ClientRows_ } from "./ClientRows.js";
import moment from "moment";
import Searchbar from "containers/Searchbar";
import * as XLSX from "xlsx";
import { timestampConversion } from "utils/common";
import { clearFilterState } from "store/features/clients";
import { useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort";
import { validateExcelHeaders } from "validations";
import { validateClient } from "validations/client";
import { downloadErrorsAsTxt } from "utils/helper";
import { toast } from "react-toastify";
import { downloadFileFromStorage } from "firebaseConfig";
import { styled } from "@mui/material/styles";
import { excelAndDbClientFieldsMapping } from "constant";
import { addMultipleClients } from "services/offering";
import { clearInputValueByDocId } from "utils/common";

const InputFile = styled("input")({
  display: "none",
});

function ClientTable({
  clients,
  loader,
  services,
  filterState,
  addHandler,
  editHandler,
  deleteHandler,
  familyArray,
  filterFamilyMember,
  adminId,
  viewHandler,
}) {
  const [rows, setRows] = useState([]); // rows for client table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [clientArray, setclientArray] = useState([]); //
  let navigate = useNavigate(); //for navigation

  const [serviceOptions, setServiceOptions] = useState([]); //filtering wrt services
  const dispatch = useDispatch(); //for redux dispatch

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  // options for dropdown of service filter
  useEffect(() => {
    let serviceArray = services.map((item, index) => {
      return {
        value: item?.serviceName,
        label: item?.serviceName,
        totalAmount:
          (item?.sellingPriceWOT * item.gst) / 100 + item?.sellingPriceWOT,
        key: index,
        id: item?.id,
      };
    });
    setServiceOptions(serviceArray);
  }, [services]);

  const columns = [
    { name: "S.no", align: "left", width: "20px" },

    { name: "Date/Time", align: "left", sort: true, sortKey: "purchasedAt" },
    {
      name: "Client Name",
      align: "left",
      minWidth: "125px",
      sort: true,
      sortKey: "name",
    },
    {
      name: "Family Name",
      align: "left",
      minWidth: "125px",
      sort: true,
      sortKey: "familyName",
    },

    { name: "Customer Id", align: "left", minWidth: "105px" },

    {
      name: "Phone",
      align: "left",
      minWidth: "120px",
      sort: true,
      sortKey: "phoneNumber",
    },
    {
      name: "Email",
      align: "left",
      minWidth: "115px",
      sort: true,
      sortKey: "email",
    },
    // { name: "Address", align: "left", minWidth: "120px" },
    // { name: "Gender", align: "left", minWidth: "120px" },
    // { name: "DOB", align: "left" },
    // { name: "Anniversary", align: "left" },
    // { name: "Age", align: "left" },
    // { name: "Occupation", align: "left", minWidth: "160px" },
    // { name: "Referred By", align: "left", minWidth: "120px" },
    // { name: "Food preference", align: "left", minWidth: "160px" },
    // { name: "Lead From", align: "left", minWidth: "120px" },
    // { name: "Commencement Date", align: "left", minWidth: "160px" },

    // { name: "Package", align: "left", minWidth: "120px" },
    // { name: "Completion Date", align: "left", minWidth: "150px" },
    // { name: "Total amount", align: "left", minWidth: "120px" },
    // { name: "Amount Paid", align: "left", minWidth: "120px" },
    // { name: "Discount", align: "left", minWidth: "120px" },

    // { name: "Balance", align: "left", minWidth: "120px" },
    // { name: "Payment Mode", align: "left", minWidth: "150px" },
    // { name: "Complementary Service", align: "left", minWidth: "180px" },

    // { name: "Service Type", align: "left", minWidth: "150px" },
    // { name: "Status", align: "left", minWidth: "120px" },
    { name: "Action", align: "left", minWidth: "105px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(clientArray, sortConfig);

    setRows(
      ClientRows_(
        sortedRows,
        navigate,
        editHandler,
        deleteHandler,
        filterFamilyMember,
        viewHandler
      )
    );
  }, [
    clientArray,
    sortConfig,
    navigate,
    editHandler,
    deleteHandler,
    filterFamilyMember,
    viewHandler,
  ]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    status: null,
    package: null,
    familyName: null,
    serviceInstance: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  //filtering on the basis of tile click handler
  const filterOnTileClick = useCallback(() => {
    let timestamp;

    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    setFilterQuery((e) => {
      e["fromDate"] = firstDayOfMonth;
      e["toDate"] = date;
      return e;
    });

    let filterData = clients.filter((item) => {
      timestamp = item.purchasedAt;
      timestamp = timestamp.seconds
        ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
        : timestamp;
      timestamp = moment.utc(timestamp);

      if (
        new Date(firstDayOfMonth) >=
        new Date(moment(timestamp).format("YYYY-MM-DD"))
      ) {
        return false;
      }

      if (moment.utc(moment(date).add(24, "hours")).isBefore(timestamp)) {
        return false;
      }

      return item;
    });

    if (searchVal) {
      let filteredProduct = filterData.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setclientArray((e) => filteredProduct);
    } else {
      setclientArray(filterData);
    }

    dispatch(clearFilterState());
  }, [dispatch, clients, searchVal]);

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = clients.filter((item) => {
      if (filterQuery.status) {
        if (filterQuery.status === "all") {
          setFilterQuery({ ...filterQuery, status: null });
        } else if (filterQuery.status !== item["status"]) {
          return false;
        }
      }

      if (filterQuery.service) {
        if (filterQuery.service === "all") {
          setFilterQuery({ ...filterQuery, service: null });
        } else if (filterQuery.service !== item["serviceName"]) {
          return false;
        }
      }

      if (filterQuery.familyName) {
        if (filterQuery.familyName === "all") {
          setFilterQuery({ ...filterQuery, familyName: null });
        } else if (filterQuery.familyName !== item["familyName"]) {
          return false;
        }
      }

      if (filterQuery.serviceInstance) {
        if (filterQuery.serviceInstance === "all") {
          setFilterQuery({ ...filterQuery, serviceInstance: null });
        } else if (filterQuery.serviceInstance !== item["serviceInstance"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setclientArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      status: null,
      package: null,
      familyName: null,
      toDate: null,
      fromDate: null,
      serviceInstance: null,
    });
    setclientArray(clients);
  }, [clients]);

  //use for filter according to tile
  useEffect(() => {
    if (filterState.filter && clients.length > 0) {
      if (filterState?.state === "SERVICE") {
        filterOnTileClick(() => {
          const element = document.getElementById("product");
          if (element) {
            //will scroll smoothly to the top of the product section
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      } else {
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [
    clients,
    resetHandler,
    setsearchVal,
    filterOnTileClick,
    filterState.state,
    filterState.filter,
  ]);

  //for searching

  const redirectState = useRef(filterState.redirect);
  useEffect(() => {
    if (searchVal) {
      resetHandler();
      let filteredProduct = clients.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setclientArray((e) => filteredProduct);
    } else {
      if (!redirectState.current) {
        redirectState.current = false;
        resetHandler();
        setclientArray(clients);
      }
    }
  }, [searchVal, resetHandler, setclientArray, dispatch, clients]);

  const excelDownload = () => {
    let updatedData = clients.map((item, index) => {
      let time1 = item?.purchasedAt?.seconds
        ? moment(timestampConversion(item?.purchasedAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.purchasedAt).local().format("MMMM Do YYYY, h:mm a");
      return [
        index + 1,
        time1,
        item.name,
        item.familyName ?? "N/A",
        item.customerId ?? "N/A",
        item.phoneNumber,
        item.email,
        item.address,
        item.gender === null ? "N/A" : item.gender,
        item.dob,
        item.age,
        item.occupation,
        item.referredBy,
        item.preference === null ? "N/A" : item.preference,
        item.leadFrom === null ? "N/A" : item.leadFrom,
        item.startDate,
        item.serviceName,
        item.completionDate,
        item.totalAmount,
        item.amountPaid,
        item.discount,
        item.totalAmount - (item.amountPaid + item.discount),
        item.paymentMode,
        item.complementaryService,
        item.serviceInstance,
        item.status,
      ];
    });
    let excelData = [
      [
        "id",
        "DATE/TIME",
        "CLIENT NAME",
        "FAMILY NAME",
        "CUSTOMER ID",
        "PHONE",
        "EMAIL",
        "ADDRESS",
        "GENDER",
        "DOB",
        "AGE",
        "OCCUPATION",
        "REFERRED BY",
        "FOOD PREFERENC",
        "LEAD FROM",
        "COMMENCEMENT DATE",
        "PACKAGE",
        "COMPLETION DATE",
        "TOTAL AMOUNT",
        "AMOUNT PAID",
        "DISCOUNT",
        "BALANCE",
        "PAYMENT MODE",
        "COMPLEMENTARY SERVICE",
        "SERVICE TYPE",
        "STATUS",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Client_List_Services.xlsx");
  };

  const [excelUploadLoader, setExcelUploadLoader] = useState(false);

  const handleExcelUpload = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    f && setExcelUploadLoader(true);

    var reader = new FileReader();
    reader.onload = async function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });
      if (!validateExcelHeaders(dataParse, excelAndDbClientFieldsMapping)) {
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");
        return;
      }
      const { extractedData, errors } = await validateClient(
        dataParse,
        clientArray,
        serviceOptions,
        adminId
      );
      if (errors && errors.length > 0) {
        downloadErrorsAsTxt(errors, "service_errors.txt");
        toast.error(
          "Please check the errors in the downloaded file and try again!!"
        );
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");
        return;
      }

      try {
        const newClient = await addMultipleClients(extractedData);
        toast.success("Clients added successfully!!");
        setclientArray((e) => [...newClient, ...e]);
        setExcelUploadLoader(false);
        clearInputValueByDocId("recipe-excel-file");
      } catch (error) {
        toast.error("Something went wrong!!");
        clearInputValueByDocId("recipe-excel-file");
      }
      setExcelUploadLoader(false);
    };
    f && reader?.readAsBinaryString(f);
  };

  const downloadExcelFile = async () => {
    try {
      await downloadFileFromStorage("templates/clients.xlsx", "clients.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={1}
        >
          <SuiTypography display="flex" alignItems="center"></SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              width: "80%",
              display: "flex",
              gap: 8,
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />

            <SuiBox mr={1}>
              <SuiButton
                component={Button}
                color="dark"
                variant="gradient"
                onClick={addHandler}
                width={180}
                style={{
                  marginRight: 25,
                  marginBottom: 10,
                  height: 40,
                  width: 230,
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Add Client
              </SuiButton>
            </SuiBox>

            <SuiBox mr={1}>
              <label htmlFor="recipe-excel-file" style={{ cursor: "pointer" }}>
                <InputFile
                  id="recipe-excel-file"
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleExcelUpload}
                />
                <SuiButton
                  ml={2}
                  component={SuiTypography}
                  color="dark"
                  variant="gradient"
                  onClick={(e) => e.stopPropagation()}
                >
                  Bulk Upload Clients
                  {excelUploadLoader && (
                    <TailSpin color="#17c1e8" height={17} width={17} />
                  )}
                </SuiButton>
              </label>
            </SuiBox>

            <SuiBox>
              <SuiButton
                component={Button}
                color="dark"
                variant="gradient"
                onClick={downloadExcelFile}
                width={180}
                style={styles.addProductButton}
              >
                Download excel template
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mt: 1 }}
          >
            Below are the list of clients who have taken your services, you can
            manage them all.
          </SuiTypography>
        </SuiBox>
        <ClientSearch
          serviceOptions={serviceOptions}
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          excelDownload={excelDownload}
          familyArray={familyArray}
        />
        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ClientTable;

/**-----------styles------------- */

const styles = {
  addProductButton: {
    marginRight: 25,
    marginBottom: 10,
    height: 40,
    width: 230,
    color: "#3A416F",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
