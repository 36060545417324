export const CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND";

export const BIRTHDAY = "Birthday";
export const ANNIVERSARY = "Anniversary";

export const upcommingRenewableDays = 30;

export const excelAndDbRecipeFieldsMapping = [
  { excelName: "Recipe name", dbField: "name" },
  { excelName: "Recipe Description", dbField: "description" },
  { excelName: "How to prepare", dbField: "preparation" },
  { excelName: "Calories", dbField: "calories" },
  { excelName: "Weight (in gms)", dbField: "weight" },
  { excelName: "Food type", dbField: "foodType" },
  { excelName: "Ingredients", dbField: "ingredients" },
  { excelName: "Nutrition (type: value, type: value)", dbField: "nutritions" },
  { excelName: "How to take", dbField: "howToTake" },
  { excelName: "Food category", dbField: "foodCategory" },
  { excelName: "Quantity", dbField: "qty" },
  { excelName: "Unit", dbField: "unit" },
];

export const STRING_FIELD_MAX_LENGTH = {
  name: 100,
  description: 500,
  preparation: 1000,
  howToTake: 500,
  foodCategory: 150,
  unit: 100,
};

export const TAG_FIELD_MAX_LENGTH = {
  foodType: 100,
  ingredients: 100,
  nutritions: 100,
};

export const ERROR_MESSAGES = {
  INVALID_FIELD: (fieldName) => `Invalid ${fieldName}`,
  EXCEEDS_MAX_LENGTH: (fieldName) => `${fieldName} exceeds maximum length`,
  INVALID_NUMERIC: (fieldName) => `Invalid numeric value for ${fieldName}`,
  INCOMPLETE_DATA: () => "Incomplete data",
  INVALID_NUTRITION_FORMAT: () => "Invalid nutrition format",
};

// product
export const excelAndDbProductFieldsMapping = [
  { excelName: "Product Name", dbField: "productName" },
  { excelName: "Brand", dbField: "brand" },
  { excelName: "Description", dbField: "description" },
  { excelName: "Cost Price (without tax)", dbField: "costPriceWOT" },
  { excelName: "GST", dbField: "gst" },
  { excelName: "MRP", dbField: "mrp" },
  { excelName: "Available Quantity", dbField: "availableQuantity" },
];

// service
export const excelAndDbServiceFieldsMapping = [
  { excelName: "Service Name", dbField: "serviceName" },
  { excelName: "Description", dbField: "description" },
  {
    excelName: "Selling Price (without tax)",
    dbField: "sellingPriceWOT",
  },
  { excelName: "GST", dbField: "gst" },
];

// client
export const excelAndDbClientFieldsMapping = [
  { excelName: "Client Name", dbField: "clientName" },
  { excelName: "Family Name", dbField: "familyName" },
  { excelName: "Phone Number", dbField: "phoneNumber" },
  { excelName: "Email", dbField: "email" },
  { excelName: "Address", dbField: "address" },
  { excelName: "Gender", dbField: "gender" },
  { excelName: "Date of Birth (MM/DD/YYYY)", dbField: "dob" },
  { excelName: "Anniversary (MM/DD/YYYY)", dbField: "anniversary" },
  { excelName: "Occupation", dbField: "occupation" },
  { excelName: "Lead From", dbField: "leadFrom" },
  { excelName: "Preference", dbField: "preference" },
  { excelName: "Referred By", dbField: "referredBy" },
  { excelName: "Package", dbField: "package" },
  { excelName: "Discount", dbField: "discount" },
  { excelName: "Amount Paid", dbField: "amountPaid" },
  { excelName: "Service Instance", dbField: "serviceInstance" },
  { excelName: "Payment Mode", dbField: "paymentMode" },
  { excelName: "Start Date (MM/DD/YYYY)", dbField: "startDate" },
  { excelName: "Completion Date (MM/DD/YYYY)", dbField: "completionDate" },
];

export const PRODUCT_STRING_FIELD_MAX_LENGTH = {
  productName: 100,
  brand: 100,
  description: 500,
};

export const obesityOptions = [
  { value: "Yes", label: "Yes", key: 1 },
  { value: "No", label: "No", key: 2 },
];

export const heatiestMealOptions = [
  { value: "Breakfast", label: "Breakfast", key: 1 },
  { value: "Mid-Morning", label: "Mid-Morning", key: 2 },
  { value: "Lunch", label: "Lunch", key: 3 },
  { value: "Dinner", label: "Dinner", key: 4 },
  { value: "None of these", label: "None of these", key: 5 },
];
export const foodsYouEnjoyOptions = [
  { value: "Sweets", label: "Sweets", key: 1 },
  { value: "Fried Foods", label: "Fried Foods", key: 2 },
  { value: "Desserts", label: "Desserts", key: 3 },
  { value: "White Meat", label: "White Meat", key: 4 },
  { value: "Red Meat", label: "Red Meat", key: 5 },
  { value: "Ice-cream", label: "Ice-cream", key: 6 },
  { value: "Chocolates", label: "Chocolates", key: 7 },
  { value: "Cakes & Pastries", label: "Cakes & Pastries", key: 8 },
  { value: "Dry Fruits", label: "Dry Fruits", key: 9 },
  { value: "Pickles & Papads", label: "Pickles & Papads", key: 10 },
  { value: "Cold Drinks", label: "Cold Drinks", key: 11 },
  { value: "Salads", label: "Salads", key: 12 },
  { value: "Fruits", label: "Fruits", key: 13 },
  { value: "Vegetables", label: "Vegetables", key: 14 },
  { value: "Tea & Coffee", label: "Tea & Coffee", key: 15 },
  { value: "Cream & Butter", label: "Cream & Butter", key: 16 },
];

export const fastFoodOptions = [
  { value: "Daily", label: "Daily", key: 2 },
  { value: "Weekly", label: "Weekly", key: 2 },
  { value: "Fortnightly", label: "Fortnightly", key: 2 },
  { value: "Occasionally", label: "Occasionally", key: 2 },
  { value: "None at all", label: "None at all", key: 2 },
];

export const rashesOptions = [
  { value: "Yes", label: "Yes", key: 1 },
  { value: "No", label: "No", key: 2 },
];
export const smokeOption = [
  { value: "Yes", label: "Yes", key: 1 },
  { value: "No", label: "No", key: 2 },
];
export const alcoholOption = [
  { value: "Yes", label: "Yes", key: 1 },
  { value: "No", label: "No", key: 2 },
];

export const activitiesOptions = [
  { value: "Sedentary", label: "Sedentary (little to no exercise)", key: 1 },
  {
    value: "Lightly active",
    label: "Lightly active (light exercise/sports 1-3 days/week)",
    key: 2,
  },
  {
    value: "Moderately active",
    label: "Moderately active (moderate exercise/sports 3-5 days/week)",
    key: 3,
  },
  {
    value: "Very active",
    label: "Very active (hard exercise/sports 6-7 days a week)",
    key: 4,
  },
  {
    value: "Extra active",
    label:
      "Extra active (very hard exercise/sports & physical job or 2x training)",
    key: 5,
  },
];

export const daySegments = [
  { title: "Early Morning", id: "earlyMorning" },
  { title: "Breakfast", id: "breakfast" },
  { title: "Mid Morning", id: "midMorning" },
  { title: "Brunch", id: "brunch" },
  { title: "Mid Meal", id: "midMeal" },
  { title: "Lunch", id: "lunch" },
  { title: "Post Lunch", id: "postLunch" },
  { title: "Evening", id: "evening" },
  { title: "Before Dinner", id: "beforeDinner" },
  { title: "Dinner", id: "dinner" },
  { title: "Post Dinner", id: "postDinner" },
  { title: "Late Night", id: "lateNight" },
];

export const frequencyOfEatingOptions = [
  { label: "Sometimes", value: "Sometimes", key: 1 },
  { label: "Regularly", value: "Regularly", key: 2 },
  { label: "Occassionally", value: "Occassionally", key: 3 },
  { label: "Very Often", value: "Very Often", key: 4 },
  { label: "Not At All", value: "Not At All", key: 5 },
];

export const dietTypeOptions = [
  { label: "GM", value: "GM", key: 1 },
  { label: "Liquid Diet", value: "Liquid Diet", key: 2 },
  { label: "Other Crash Course", value: "Other Crash Course", key: 3 },
];

export const expenseCategoryOptions = [
  { label: "Food", value: "Food", key: 1 },
  { label: "Staff", value: "Staff", key: 2 },
  { label: "Miscellaneous", value: "Miscellaneous", key: 3 },
  { label: "Rent", value: "Rent", key: 4 },
  { label: "Utilities", value: "Utilities", key: 5 },
  { label: "Equipment", value: "Equipment", key: 6 },
  { label: "Marketing", value: "Marketing", key: 7 },
  { label: "Training & Education", value: "Training & Education", key: 8 },
  { label: "Insurance", value: "Insurance", key: 9 },
  { label: "Office Supplies", value: "Office Supplies", key: 10 },
];

export const genderOptions = [
  { value: "Male", label: "Male", key: 1 },
  { value: "Female", label: "Female", key: 2 },
  { value: "Other", label: "Other", key: 3 },
];

export const preferenceOptions = [
  { value: "Veg", label: "Veg", key: 1 },
  { value: "Non-veg", label: "Non-veg", key: 2 },
  { value: "Eggetarian", label: "Eggetarian", key: 3 },
];

export const serviceInstanceOptions = [
  { value: "First Time", label: "First Time", key: 1 },
  { value: "Extension", label: "Extension", key: 2 },
  { value: "Returning", label: "Returning", key: 3 },
];

export const paymentOptions = [
  { value: "Cash", label: "Cash", key: 1 },
  { value: "Online", label: "Online", key: 2 },
];

export const leadOptions = [
  { value: "Facebook", label: "Facebook", key: 1 },
  { value: "Instagram", label: "Instagram", key: 2 },
  { value: "Twitter", label: "Twitter", key: 3 },
  { value: "Friend", label: "Friend", key: 4 },
  { label: "Walk In", value: "Walk In", key: 5 },
  { label: "Google Ads", value: "Google Ads", key: 6 },
  { label: "BNI", value: "BNI", key: 7 },
  { label: "News Paper", value: "News Paper", key: 8 },
  { label: "FM", value: "FM", key: 9 },
  { label: "Events", value: "Events", key: 10 },
  { label: "Referral", value: "Referral", key: 11 },
  { label: "Whatsapp", value: "Whatsapp", key: 12 },
  { label: "Board", value: "Board", key: 13 },
  { label: "Pamphlet", value: "Pamphlet", key: 14 },
];
export const termsAndConditionDefault = `
*The service period starts from the day of ENROLLMENT and not from the day of using services.\n *You cannot hold the diet program/package once started.\n
*Upgrades: A program once enrolled in, can ONLY be upgraded within the first week from the day of enrollment. Downgrades are not allowed.\n
*Refunds: Once you have paid for a program the no refund and no transfer policy applies due to the nature of services involved no refund and no transfer is provided under any circumstances.\n

*Subject to Jaipur jurisdiction only
`;
