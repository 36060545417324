import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { globaleDateTimeFormatter } from "utils/helper";

export const AnniversariesAndBirthdaysRows = (
  anniversariesAndBirthdays,
  onClickHandler,
  navigate
) => {
  const rows = anniversariesAndBirthdays.map((item) => {
    return {
      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
            paddingLeft: 14,
          }}
          onClick={() => {
            navigate(`/clients/${item.id}`);
          }}
        >
          {item?.name}
        </SuiTypography>
      ),

      Date: (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(
            item?.occasion === "Anniversary" ? item?.anniversary : item?.dob,
            "date"
          )}
        </SuiTypography>
      ),

      Occasion: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.occasion}
        </SuiTypography>
      ),

      Action: (
        <SuiBox style={{ display: "flex" }}>
          <SuiTypography
            variant="button"
            fontWeight="medium"
            onClick={() => onClickHandler(item, item?.occasion)}
            style={{
              color: "#17C1E8",
              cursor: "pointer",
              paddingLeft: 16,
              textDecoration: "underline",
            }}
          >
            Wish
          </SuiTypography>
        </SuiBox>
      ),
    };
  });

  return rows;
};
