import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Button } from "@mui/material";
import { Icon } from "@mui/material";
import { toast } from 'react-toastify'
import { validateEmail, validatePhone, } from 'utils/common'
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';


import { userSelector, updateProfile, clearState } from 'store/features/authSlice'
import { useDispatch, useSelector } from 'react-redux';

import fitifyProfile from "assets/images/fitify-nutrition.webp";
import Logomain from "assets/images/logo.png"


const InputFile = styled('input')({
    display: 'none',
});

function Modal({ open, handleCloseModal, setLoader }) {

    const { uid, name, info, profile,
        logo, qr, signature, address, phoneNumber, email, fb, instagram,
        twitter, isSuccess, isError, isFetching, message } = useSelector(userSelector)

    useEffect(() => {
        setFormdata({
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            address: address,
            info: info,
            fb: fb,
            instagram: instagram,
            twitter: twitter,
            qr: qr,
            signature: signature
        })
    }, [name, email, info, profile, logo, qr, signature, address, phoneNumber, fb, instagram, twitter])


    //default modal fields value
    const defaultHandler = useMemo(() => {
        return {
            name: null,
            email: null,
            phoneNumber: null,
            address: null,
            info: null,
            fb: null,
            instagram: null,
            twitter: null,
            qr: null,
            signature: null
        }
    }, []);


    //error handler
    const errorDefault = useMemo(() => {
        return {
            name: false,
            email: false,
            phoneNumber: false,
            address: false,

            info: false,
            fb: false,
            instagram: false,
            twitter: false,
            qr: false,
            signature: false
        }
    }, []);

    const dispatch = useDispatch()

    const [formdata, setFormdata] = useState(defaultHandler)

    const [errorMessage, setErrorMessage] = useState(errorDefault)

    //for submitting data
    const submitHandler = async (e) => {

        if (!formdata.name) {
            toast.error("Please fill Name field")
            setErrorMessage({
                ...errorMessage,
                name: true
            })
            return;
        }

        if (formdata.email) {
            if (!validateEmail(formdata.email)) {
                toast.info("Enter a valid email")
                setErrorMessage({
                    ...errorMessage,
                    'email': true
                })
                return;
            }
        } else {
            toast.info("Plz enter email")
            setErrorMessage({
                ...errorMessage,
                'email': true
            })
            return;
        }

        if (formdata.phoneNumber) {
            if (!validatePhone(formdata.phoneNumber)) {
                toast.info("Enter a valid phone number")
                setErrorMessage({
                    ...errorMessage,
                    'phoneNumber': true
                })
                return;
            }
        } else {
            toast.info("Plz enter phone number")
            setErrorMessage({
                ...errorMessage,
                'phoneNumber': true
            })
            return;
        }


        if (!formdata.address) {
            toast.error("Please fill Address field")
            setErrorMessage({
                ...errorMessage,
                address: true
            })
            return;
        }

        if (!formdata.info) {
            toast.error("Please fill Info field")
            setErrorMessage({
                ...errorMessage,
                info: true
            })
            return;
        }

        if (!formdata.fb) {
            toast.error("Please fill FB url field")
            setErrorMessage({
                ...errorMessage,
                fb: true
            })
            return;
        }

        if (!formdata.instagram) {
            toast.error("Please fill Instagram field")
            setErrorMessage({
                ...errorMessage,
                instagram: true
            })
            return;
        }

        if (!formdata.twitter) {
            toast.error("Please fill Twitter field")
            setErrorMessage({
                ...errorMessage,
                twitter: true
            })
            return;
        }

        setLoader(true)
        dispatch(updateProfile({
            formdata, uid, email,
            profile_: profile, logo_: logo, qr_: qr, signature_: signature 
        }))
    }


    useEffect(() => {

        if (isSuccess) {
            handleCloseModal()
            dispatch(clearState())
            setLoader(false)
            toast.success(message)
        }
        if (isError) {
            toast.error(message)
            setLoader(false)
            dispatch(clearState())
        }
    }, [isSuccess, dispatch, isError,
        message, handleCloseModal, setLoader])


    const handleImage = (evt) => {

        const allowedFormats = ['png', 'jpg', 'jpeg'];

        function checkImageFormat(file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
            return allowedFormats.includes(fileExtension);
        }

        var tgt = evt.target || window.event.srcElement,
            files = tgt.files;

        if (checkImageFormat(files[0])) {

            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    setFormdata({
                        ...formdata,
                        [evt.target.name]: files[0],
                        [`${evt.target.name}_location`]: fr.result
                    })
                }
                fr.readAsDataURL(files[0]);
            }
        } else {
            toast.info('Only PNG, JPG, and JPEG formats are allowed for image uploads.');
        }
    }


    return (
        <ModalRoot variant="permanent" ownerState={{ openConfigurator: open }} width="60%">
            <SuiBox
                style={{ whiteSpace: 'normal !important' }}
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}>
                <SuiBox>
                    <SuiTypography variant="h5">Edit Profile </SuiTypography>
                    <SuiTypography variant="body2" color="text">
                        Provide your details & update.
                    </SuiTypography>
                </SuiBox>

                <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={() => {
                        handleCloseModal()
                        setTimeout(() => {
                            setFormdata({
                                ...formdata,
                                'profile_location': null,
                                "logo_location": null
                            })
                            setErrorMessage({
                                ...errorDefault
                            })
                        }, 500);
                    }}>
                    close
                </Icon>
            </SuiBox>

            <Divider />

            <SuiBox pt={1.25} pb={3} px={3}>

                <SuiBox mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Full Name</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="name"
                                    placeholder="full name ..."
                                    error={errorMessage?.name}
                                    value={formdata.name ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Mobile</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="mobile number ..."
                                    error={errorMessage?.phoneNumber}
                                    value={formdata.phoneNumber ?? ''}
                                    onChange={(e) => {
                                        let numberRegex = /^\d+$/;
                                        let val = e.target.value
                                        let match_ = val.match(numberRegex)
                                        if (!match_ && val) {
                                            return false
                                        }

                                        if (val.length > 10) {
                                            toast.info("Invalid phone number")
                                            return
                                        }

                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Email</SuiTypography>
                                <SuiInput
                                    type="email"
                                    name="email"
                                    placeholder="email ..."
                                    error={errorMessage?.email}
                                    value={formdata.email ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Address</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="address"
                                    placeholder="address ..."
                                    multiline
                                    rows={6}
                                    error={errorMessage?.address}
                                    value={formdata.address ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Profile Information</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="info"
                                    placeholder="profile information ..."
                                    multiline
                                    rows={6}
                                    error={errorMessage?.info}
                                    value={formdata.info ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">FB URL</SuiTypography>
                                <SuiInput
                                    type="url"
                                    name="fb"
                                    placeholder="facebook url ..."
                                    error={errorMessage?.fb}
                                    value={formdata.fb ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>


                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Twitter URL</SuiTypography>
                                <SuiInput
                                    type="url"
                                    name="twitter"
                                    placeholder="twitter url ..."
                                    error={errorMessage?.twitter}
                                    value={formdata.twitter ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1} >
                                <SuiTypography mb={1} variant="h6">Instagram URL</SuiTypography>
                                <SuiInput
                                    type="url"
                                    name="instagram"
                                    placeholder="instagram url ..."
                                    error={errorMessage?.instagram}
                                    value={formdata.instagram ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <label htmlFor="profile-image" style={{ cursor: 'pointer' }}>
                                <InputFile
                                    id="profile-image"
                                    type="file"
                                    name="profile"
                                    accept="image/*"
                                    onChange={handleImage} />
                                <SuiBox display="flex" py={1} pr={2}>
                                    <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Profile Image: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    </SuiTypography>
                                    <SuiAvatar
                                        src={formdata?.profile_location ?? profile ?? fitifyProfile}
                                        alt="profile-image"
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </SuiBox>
                            </label>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <label htmlFor="logo-image" style={{ cursor: 'pointer' }}>
                                <InputFile
                                    id="logo-image"
                                    type="file"
                                    name="logo"
                                    accept="image/*"
                                    onChange={handleImage} />
                                <SuiBox display="flex" py={1} pr={2}>
                                    <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Logo Image: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    </SuiTypography>
                                    <SuiAvatar
                                        src={formdata?.logo_location ?? logo ?? Logomain}
                                        alt="profile-image"
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </SuiBox>
                            </label>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <label htmlFor="qr-image" style={{ cursor: 'pointer' }}>
                                <InputFile
                                    id="qr-image"
                                    type="file"
                                    name="qr"
                                    accept="image/*"
                                    onChange={handleImage} />
                                <SuiBox display="flex" py={1} pr={2}>
                                    <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        QR Image: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    </SuiTypography>
                                    <SuiAvatar
                                        src={formdata?.qr_location ?? qr}
                                        alt="qr-image"
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </SuiBox>
                            </label>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <label htmlFor="signature-image" style={{ cursor: 'pointer' }}>
                                <InputFile
                                    id="signature-image"
                                    type="file"
                                    name="signature"
                                    accept="image/*"
                                    onChange={handleImage} />
                                <SuiBox display="flex" py={1} pr={2}>
                                    <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                        Signature Image: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    </SuiTypography>
                                    <SuiAvatar
                                        src={formdata?.signature_location ?? signature}
                                        alt="signature-image"
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </SuiBox>
                            </label>
                        </Grid>
                    </Grid>
                </SuiBox>


                <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
                    <SuiBox mb={2} width="32%">
                        <SuiButton
                            component={Button}
                            color="dark"
                            variant="gradient"
                            onClick={submitHandler}
                            disabled={isFetching}
                            fullWidth>
                            {isFetching ? 'Updating . . .' : "Update"}
                        </SuiButton>
                    </SuiBox>
                </SuiBox>



            </SuiBox>
        </ModalRoot>
    );
}

export default Modal;
