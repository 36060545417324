import SuiTypography from "components/SuiTypography";
import Tooltip from "@mui/material/Tooltip";
import TagItem from "containers/TagItem";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";

export const dailyIntakeAndRecallRow = (
  data,
  editHandler,
  viewHandler,
  deleteHandler,
  dailyIntake
) => {
  const rows = data.map((item, index) => {
    return {
      "Date/Time (Added)": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 16 }}
        >
          {globaleDateTimeFormatter(item?.createdAt)}
        </SuiTypography>
      ),

      "Follows special diet?": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.dietType ?? "N/A"}
        </SuiTypography>
      ),

      "Frequency Of Eating": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.frequencyOfEating ?? "N/A"}
        </SuiTypography>
      ),

      "Water Intake": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.waterIntake ?? "N/A"}
        </SuiTypography>
      ),

      "Early Morning": item?.earlyMorning ? (
        <div>
          <Tooltip title={item?.earlyMorning?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.earlyMorning?.clientAte?.length > 40
                ? `${item?.earlyMorning?.clientAte?.slice(0, 40)}...`
                : item?.earlyMorning?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.earlyMorning?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Breakfast: item?.breakfast ? (
        <div>
          <Tooltip title={item?.breakfast?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.breakfast?.clientAte?.length > 40
                ? `${item?.breakfast?.clientAte?.slice(0, 40)}...`
                : item?.breakfast?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.breakfast?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Mid Morning": item?.midMorning ? (
        <div>
          <Tooltip title={item?.midMorning?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.midMorning?.clientAte?.length > 40
                ? `${item?.midMorning?.clientAte?.slice(0, 40)}...`
                : item?.midMorning?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.midMorning?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Brunch: item?.brunch ? (
        <div>
          <Tooltip title={item?.brunch?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.brunch?.clientAte?.length > 40
                ? `${item?.brunch?.clientAte?.slice(0, 40)}...`
                : item?.brunch?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.brunch?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Mid Meal": item?.midMeal ? (
        <div>
          <Tooltip title={item?.midMeal?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.midMeal?.clientAte?.length > 40
                ? `${item?.midMeal?.clientAte?.slice(0, 40)}...`
                : item?.midMeal?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.midMeal?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Lunch: item?.lunch ? (
        <div>
          <Tooltip title={item?.lunch?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.lunch?.clientAte?.length > 40
                ? `${item?.lunch?.clientAte?.slice(0, 40)}...`
                : item?.lunch?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.lunch?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Post Lunch": item?.postLunch ? (
        <div>
          <Tooltip title={item?.postLunch?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.postLunch?.clientAte?.length > 40
                ? `${item?.postLunch?.clientAte?.slice(0, 40)}...`
                : item?.postLunch?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.postLunch?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Evening: item?.evening ? (
        <div>
          <Tooltip title={item?.evening?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.evening?.clientAte?.length > 40
                ? `${item?.evening?.clientAte?.slice(0, 40)}...`
                : item?.evening?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.evening?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Before Dinner": item?.beforeDinner ? (
        <div>
          <Tooltip title={item?.beforeDinner?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.beforeDinner?.clientAte?.length > 40
                ? `${item?.beforeDinner?.clientAte?.slice(0, 40)}...`
                : item?.beforeDinner?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.beforeDinner?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Dinner: item?.dinner ? (
        <div>
          <Tooltip title={item?.dinner?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.dinner?.clientAte?.length > 40
                ? `${item?.dinner?.clientAte?.slice(0, 40)}...`
                : item?.dinner?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.dinner?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Post Dinner": item?.postDinner ? (
        <div>
          <Tooltip title={item?.postDinner?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.postDinner?.clientAte?.length > 40
                ? `${item?.postDinner?.clientAte?.slice(0, 40)}...`
                : item?.postDinner?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.postDinner?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      "Late Night": item?.lateNight ? (
        <div>
          <Tooltip title={item?.lateNight?.clientAte ?? ""} placement="top">
            <SuiTypography variant="button" fontWeight="medium">
              {item?.lateNight?.clientAte?.length > 40
                ? `${item?.lateNight?.clientAte?.slice(0, 40)}...`
                : item?.lateNight?.clientAte}
            </SuiTypography>
          </Tooltip>
          <TagItem val={item?.lateNight?.time} />
        </div>
      ) : (
        <SuiTypography variant="button" fontWeight="medium">
          N/A
        </SuiTypography>
      ),

      Action: (
        <TableAction
          style={{ padding: "3px", marginLeft: "5px" }}
          updateHandler={() => editHandler(item)}
          viewHandler={() => viewHandler(item)}
          deleteHandler={() =>
            deleteHandler(
              item,
              index,
              dailyIntake ? "dailyIntake" : "dietRecall"
            )
          }
        />
      ),
    };
  });
  return rows;
};
