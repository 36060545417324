import React, { useState, useMemo, } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Icon } from "@mui/material";
import { toast } from 'react-toastify'
import Grid from "@mui/material/Grid";
import { sendWishesHandler } from "services/dashboard";
import { TailSpin } from "react-loader-spinner";


function Modal({ open, handleCloseModal, occasion, client }) {

    const [ loader, setLoader ] = useState(false)


    //default modal fields value
    const defaultHandler = useMemo(() => {
        return {
            message: ""
        }
    }, []);


    //error handler
    const errorDefault = useMemo(() => {
        return {
            message: ""
        }
    }, []);

    const [formdata, setFormdata] = useState(defaultHandler)
    const [errorMessage, setErrorMessage] = useState(errorDefault)

    //for submitting data
    const submitHandler = async (e) => {
        if (!formdata.message) {
            toast.error("Please fill message field")
            setErrorMessage({
                ...errorMessage,
                message: true
            })
            return;
        }
        try {
            setLoader(true)
            await sendWishesHandler({ name: client.name, email: client.email, 
                message: formdata.message, occasion })
            setLoader(false)
            toast.success("Wish sent successfully")
            setFormdata(defaultHandler)
            handleCloseModal()
        } catch(e){
            console.log(e)
            setLoader(false)
            toast.error("Something went wrong, retry!!")
        }

    }

    return (
        <ModalRoot variant="permanent" ownerState={{ openConfigurator: open }} width="30%">
            <SuiBox
                style={{ whiteSpace: 'normal !important' }}
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}>
                <SuiBox>
                    <SuiTypography variant="h5">{ occasion === 'Birthday'?'Send Birthday Wishes':'Send Anniversary Wishes'}</SuiTypography>
                    <SuiTypography variant="body2" color="text">
                        Send custom {occasion} wishes to {client?.name}
                    </SuiTypography>
                </SuiBox>

                <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={() => {
                        handleCloseModal()
                        setTimeout(() => {
                            setFormdata({
                                ...defaultHandler
                            })
                            setErrorMessage({
                                ...errorDefault
                            })
                        }, 500);
                    }}>
                    close
                </Icon>
            </SuiBox>

            <Divider />

            <SuiBox pt={1.25} pb={3} px={3}>

                <SuiBox mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SuiBox lineHeight={1}>
                                <SuiTypography mb={1} variant="h6">Write Message</SuiTypography>
                                <SuiInput
                                    type="text"
                                    name="message"
                                    rows={6}
                                    multiline
                                    error={errorMessage?.message}
                                    value={formdata.message ?? ''}
                                    onChange={(e) => {
                                        setErrorMessage({
                                            ...errorMessage,
                                            [e.target.name]: false
                                        })
                                        setFormdata({
                                            ...formdata,
                                            [e.target.name]: e.target.value
                                        })
                                    }} />
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>

                <SuiBox mt={2} mb={2} display="flex">
                    <SuiBox mb={2} width="32%" display="flex">
                        <SuiTypography 
                            mb={1} 
                            mr={1}
                            onClick={(!loader)?submitHandler:()=>{}}
                            variant="h6"
                            style={{
                                cursor: loader?'not-allowed':'pointer',
                                color: "#34673C",
                                textDecoration: "underline",
                                fontSize: 14
                            }}>Send</SuiTypography>
                            { loader ? <TailSpin color="#17c1e8" height={20} width={20} />: null}
                    </SuiBox>
                </SuiBox>
            </SuiBox>
        </ModalRoot>
    );
}

export default Modal;
