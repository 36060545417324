import React, { useState, useEffect, useCallback } from "react";
import { updateClientData } from "services/customer";
import { toast } from "react-toastify";
import { dailyIntakeAndRecallRow } from "containers/clientDetails/sub-components/common/dailyIntakeAndRecallTableRows";
import SwipableModal from "containers/Customer/modal";
import DailyIntakeModal from "containers/clientDetails/sub-components/modal/dailyIntake";
import { dailyIntakeAndRecallTableColumns } from "utils/tablesHeaderContent";
import { timestampConversion } from "utils/common";
import moment from "moment";
import ClientInfoCard from "containers/clientDetails/sub-components/card/clientCard";
import { headerDesc } from "utils/helper";

function DailyIntakeForm({ clientId, dailyIntakeArray, deleteHandler }) {
  const [dailyIntakeData, setDailyIntakeData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([...dailyIntakeAndRecallTableColumns]);
  const [disableButton, setDisableButton] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState("create");

  useEffect(() => {
    if (dailyIntakeArray.length === 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setDailyIntakeData(dailyIntakeArray);
  }, [dailyIntakeArray]);

  const editHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("edit");
    setOpenAddModal(true);
  }, []);

  const viewHandler = useCallback((data) => {
    setSelectedData(data);
    setMode("view");
    setOpenAddModal(true);
  }, []);

  //sorting according to date
  //and listing daily intake data in table
  useEffect(() => {
    const convertTimestamp = (timestamp) => {
      return timestamp?.seconds
        ? moment(timestampConversion(timestamp))
        : moment(timestamp);
    };
    let dailyIntakeFoodArray = dailyIntakeData.slice();
    dailyIntakeFoodArray.sort((a, b) => {
      const a_timestamp = convertTimestamp(a?.timestamp);
      const b_timestamp = convertTimestamp(b?.timestamp);
      return b_timestamp - a_timestamp;
    });
    setRows(
      dailyIntakeAndRecallRow(
        dailyIntakeFoodArray,
        editHandler,
        viewHandler,
        deleteHandler,
        "dailyIntake"
      )
    );
  }, [dailyIntakeData, editHandler, viewHandler]);

  const addHandler = () => {
    setMode("create");
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setSelectedData(null);
  };

  const submitHandler = async () => {
    try {
      setLoader(true);
      const fieldKey = "dailyIntake";
      await updateClientData(clientId, fieldKey, dailyIntakeData);
      setLoader(false);
      setDisableButton(true);
      toast.success("Data Added successfully");
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong!");
    }
  };

  const Modal = () => (
    <SwipableModal
      open={openAddModal}
      setOpenModal={setOpenAddModal}
      handleCloseModal={handleCloseModal}
      mode={mode}
      setMode={setMode}
      {...headerDesc(mode, "Client's Daily Intake", selectedData)}
    >
      <DailyIntakeModal
        id={clientId}
        mode={mode}
        persona="client"
        open={openAddModal}
        selectedItem={selectedData}
        setMainData={setDailyIntakeData}
        handleCloseModal={handleCloseModal}
      />
    </SwipableModal>
  );

  return (
    <ClientInfoCard
      title={"Client's Daily Intake"}
      rows={rows}
      columns={columns}
      disableButton={disableButton}
      onSubmitHandler={submitHandler}
      loader={loader}
      onBtnClickHandler={addHandler}
      Modal={Modal}
    />
  );
}

export default DailyIntakeForm;
