import React, { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import SuiButton from "components/SuiButton";
import AddTemplate from "./AddTemplate";
import ModalRoot from "containers/modal/ModalRoot";
import CustomButton from "containers/CustomButton";
import { Button } from "@mui/material";

const DietChartTemplates = () => {
  const [openAddTemplateDrawer, setOpenAddTemplateDrawer] = useState(false);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          justifyItems="end"
          alignItems="center"
          padding={2}
          gap={1}
          height="100%"
          paddingBottom={0}
        >
          <SuiTypography mb={1} variant="h6">
            Diet Chart Templates
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          justifyItems="end"
          alignItems="center"
          padding={2}
          paddingTop={0}
          gap={1}
          height="100%"
        >
          <SuiTypography mb={1} variant="p" fontSize={14}>
            Diet chart templates are pre-designed layouts or formats that
            include various categories such as meal timings, food groups,
            portion sizes, and sometimes even nutritional information. These
            templates serve as a guide for individuals looking to plan their
            meals according to a specific diet plan.
          </SuiTypography>
          <SuiBox selfAlign={"end"}>
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={() => setOpenAddTemplateDrawer(true)}
              fullWidth
            >
              Add Template
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
      <ModalRoot
        variant="permanent"
        ownerState={{ openConfigurator: openAddTemplateDrawer }}
        width="90%"
        style={{ opacity: "0 !important" }}
      >
        <AddTemplate
          open={openAddTemplateDrawer}
          setOpenAddTemplateDrawer={setOpenAddTemplateDrawer}
        />
      </ModalRoot>
    </>
  );
};

export default DietChartTemplates;
